define('client/components/insights/insights-search', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['insights-search-container'],
    searchTerm: '',

    suggestedInsights: Ember.computed.uniqBy('insights', 'highlightedQuestion'),
    questions: Ember.computed.mapBy('suggestedInsights', 'highlightedQuestion'),

    onChangeSearchTerm: function onChangeSearchTerm() {},
    onChooseQuestion: function onChooseQuestion() {},
    onChangeMainSearchTerm: function onChangeMainSearchTerm() {},


    actions: {
      chooseInsight: function chooseInsight(insight) {
        this.get('changeMainSearchTerm')('');
        this.get('selectQuestion')(insight.get('question'));
      },
      cancelSearch: function cancelSearch() {
        this.get('onChooseQuestion')('');
        this.get('changeMainSearchTerm')('');
        this.set('searchTerm', '');
        this.get('onChangeSearchTerm')('');
      },
      chooseQuestion: function chooseQuestion(question) {
        var insight = this.get('suggestedInsights').findBy('highlightedQuestion', question);
        var taglessQuestion = insight.get('question');
        this.set('searchTerm', taglessQuestion);
        this.get('onChooseQuestion')(taglessQuestion);
      },
      enterSearchTerm: function enterSearchTerm(term) {
        this.set('searchTerm', term);
        this.get('changeMainSearchTerm')(term);
      },
      updateSearchTerm: function updateSearchTerm(term) {
        this.set('searchTerm', term);
        Ember.run.debounce(this, this.get('onChangeSearchTerm'), term, 500);
      }
    }
  });
});
define('client/components/plan/media/add-broadcasts', ['exports', 'ember-data', 'client/models/profile-link-filter'], function (exports, _emberData, _profileLinkFilter) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ["add-broadcasts"],
    store: Ember.inject.service(),

    profileLinks: null,
    broadcasts: Ember.computed("profileLinks.[]", function () {
      return this.get("profileLinks").filterBy("type", _profileLinkFilter.ProfileLinkFilterTypes.BROADCAST);
    }),

    onNext: function onNext() {},
    onBack: function onBack() {},


    actions: {
      addBroadcast: function addBroadcast(broadcast) {
        var _getProperties = this.getProperties("store", "list"),
            store = _getProperties.store,
            list = _getProperties.list,
            profileLinks = this.get("profileLinks");
        // create profile link


        var promise = store.createRecord("profile-link-filter", {
          profile: list.get("profile"),
          whitelistTag: list,
          broadcastProvider: broadcast,
          type: _profileLinkFilter.ProfileLinkFilterTypes.BROADCAST
        }).save().then(function () {
          return profileLinks.update();
        });
        this.set("promise", _emberData.default.PromiseObject.create({ promise: promise }));
      },
      removeLink: function removeLink(link) {
        var profileLinks = this.get("profileLinks");
        var promise = link.destroyRecord().then(function () {
          return profileLinks.update();
        });
        this.set("promise", _emberData.default.PromiseObject.create({ promise: promise }));
      }
    }
  });
});
define('client/components/dashboard/widgets/tone-card', ['exports', 'client/components/dashboard/widgets/widget-card'], function (exports, _widgetCard) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var PCT_TO_HEIGHT = 1.11;

  exports.default = _widgetCard.default.extend({
    sort: "tone",

    positive: Ember.computed.alias("data.positive"),
    negative: Ember.computed.alias("data.negative"),
    neutral: Ember.computed.alias("data.neutral"),

    isSentimentUnavailable: Ember.computed('positive', 'negative', 'neutral', function () {
      return this.positive === 0 && this.negative === 0 && this.neutral === 0;
    }),

    sum: Ember.computed("positive", "negative", "neutral", "profileService.profile.includeNeutralArticles", function () {
      var _getProperties = this.getProperties("positive", "neutral", "negative"),
          positive = _getProperties.positive,
          negative = _getProperties.negative,
          neutral = _getProperties.neutral,
          includeNeutral = this.get("profileService.profile.includeNeutralArticles");

      return (positive || 0) + (negative || 0) + (includeNeutral ? neutral || 0 : 0);
    }),

    positivePct: Ember.computed("positive", "sum", function () {
      var _getProperties2 = this.getProperties("positive", "sum"),
          positive = _getProperties2.positive,
          sum = _getProperties2.sum;

      if (sum === 0) {
        return 0;
      } else {
        return Math.round((positive || 0) / sum * 100);
      }
    }),

    neutralPct: Ember.computed("neutral", "sum", function () {
      var _getProperties3 = this.getProperties("neutral", "sum"),
          neutral = _getProperties3.neutral,
          sum = _getProperties3.sum;

      if (sum === 0) {
        return 0;
      } else {
        return Math.round((neutral || 0) / sum * 100);
      }
    }),

    negativePct: Ember.computed("negative", "sum", function () {
      var _getProperties4 = this.getProperties("negative", "sum"),
          negative = _getProperties4.negative,
          sum = _getProperties4.sum;

      if (sum === 0) {
        return 0;
      } else {
        return Math.round((negative || 0) / sum * 100);
      }
    }),

    positiveStyle: Ember.computed("positivePct", function () {
      var val = this.get("positivePct");
      return new Ember.String.htmlSafe(val !== null ? "height:" + val * PCT_TO_HEIGHT + "px;" : "");
    }),

    neutralStyle: Ember.computed("neutralPct", function () {
      var val = this.get("neutralPct");
      return new Ember.String.htmlSafe(val !== null ? "height:" + val * PCT_TO_HEIGHT + "px;" : "");
    }),

    negativeStyle: Ember.computed("negativePct", function () {
      var val = this.get("negativePct");
      return new Ember.String.htmlSafe(val !== null ? "height:" + val * PCT_TO_HEIGHT + "px;" : "");
    })

  });
});
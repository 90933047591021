define('client/serializers/article-ml-summary', ['exports', 'active-model-adapter', 'ember-data'], function (exports, _activeModelAdapter, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _activeModelAdapter.ActiveModelSerializer.extend({
    normalizeResponse: function normalizeResponse(store, primaryModelClass, payload, id, requestType) {

      // Restructure the payload to fit Ember Data's expected format
      var normalizedPayload = {
        'article-ml-summary': {
          id: id || 'summary', // Using a static ID since no ID is provided
          summary: payload.data.summary,
          impact_summary: payload.data.impact_summary, // Map to camelCase
          top_five_articles: payload.data.top_five_articles // Map to camelCase
        }
      };
      return this._super(store, primaryModelClass, normalizedPayload, id, requestType);
    }
  });
});
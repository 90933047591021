define('client/utils/check-profile-header', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.checkProfileHeaderData = undefined;


  var checkProfileHeaderData = function checkProfileHeaderData(store, profile_id) {
    if (!profile_id) {
      return null;
    }
    return _emberData.default.PromiseObject.create({
      promise: new Ember.RSVP.Promise(function (resolve, reject) {
        // if the loaded profile is only header data,
        // the adapter will make it reload properly (view shouldReloadRecord)
        // otherwise, findRecord returns the complete profile
        store.findRecord("profile", profile_id, { backgroundReload: false }).then(resolve, reject);
      })
    });
  };

  exports.checkProfileHeaderData = checkProfileHeaderData;
});
define('client/routes/sales', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    titleToken: "Sales",

    beforeModel: function beforeModel() {
      if (!this.get('currentUser.isSuperAdmin')) {
        this.transitionTo("dashboard");
      }
    }
  });
});
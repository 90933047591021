define('client/components/panel-item', ['exports', 'ember-uuid'], function (exports, _emberUuid) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNameBindings: [":panel", ":panel-default"],
    opened: false,
    title: "No Title",

    activePanelItem: Ember.computed.alias('parentView.activePanelItem'),

    isActive: Ember.computed('activePanelItem', function () {
      return Ember.isEqual(this, this.get('activePanelItem'));
    }),

    didInsertElement: function didInsertElement() {
      if (this.get("opened")) {
        this.get('parentView').send('activatePanelItem', this);
      }
    },

    actions: {
      togglePanelItem: function togglePanelItem() {
        if (this.get('isActive')) {
          // for some reason send isn't being properly bubbled up here
          this.get('parentView').send('activatePanelItem', null);
        } else {
          this.get('parentView').send('activatePanelItem', this);
        }
      }
    }
  });
});
define('client/components/profile-selector', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var ProfileChoice = Ember.ObjectProxy.extend({

    class: Ember.computed('content', function () {
      var ret = '';
      if (this.get('currentUser.isAirprAdmin')) {
        if (this.get('content.isCustomer')) {
          ret += 'is-customer ';
        }
        if (this.get('content.isCompetitorOfCustomer')) {
          ret += 'is-competitor-of-customer';
        }
      }
      return ret;
    })
  });

  function profileListProperty(isCompetitorProfile) {
    return Ember.computed('filteredCorporates.[]', function () {
      return this.get('filteredCorporates').filterBy('isCompetitorProfile', isCompetitorProfile).map(function (content) {
        return ProfileChoice.create({ content: content });
      });
    });
  }

  exports.default = Ember.Component.extend({
    tagName: '',
    searchTermRegexObj: null,
    closeOnClick: false,
    searchTerm: null,
    showRecentProfiles: true,
    currentUser: Ember.inject.service('current-user'),
    profileService: Ember.inject.service('profile'),

    filteredCorporates: Ember.computed('currentUser.sortedCorporates.[]', 'searchTermRegexObj', function () {
      var profiles = this.get('currentUser.sortedCorporates'),
          re = this.get('searchTermRegexObj');
      if (re) {
        return profiles.filter(function (profile) {
          return re.test(profile.get('name'));
        });
      } else {
        return this.get('currentUser.isSuperAdmin') ? [] : profiles;
      }
    }),

    profileCompetitors: Ember.computed('profileService.profile.id', 'profileService.profile.competitors.[]', 'currentUser.sortedCorporates.[]', 'searchTermRegexObj', function () {
      if (Ember.isEmpty(this.get('profileService.profile.competitors'))) {

        return [];
      }
      var profiles = this.get('currentUser.sortedCorporates'),
          currentProfileId = this.get('profileService.profile.id'),
          re = this.get('searchTermRegexObj');
      return this.get('profileService.profile.competitors').map(function (profile) {
        if (re && !re.test(profile.get('name'))) {
          return null;
        }
        if (profile.get('id') === currentProfileId) {
          return null;
        }
        var content = profiles.findBy("id", profile.get('id'));
        return ProfileChoice.create({ content: content });
      }).compact();
    }),

    recentProfiles: Ember.computed('storage.recentProfiles.[]', 'currentUser.corporates.[]', function () {
      var _this = this;

      var recentProfileIds = this.get('storage.recentProfiles') || [];
      var resolvedProfiles = recentProfileIds.map(function (id) {
        return _this.get('currentUser.corporates').findBy('id', id);
      });

      return resolvedProfiles;
    }),

    brands: profileListProperty(false),
    competitors: profileListProperty(true),

    setStorageProfile: function setStorageProfile(profile) {
      var recentProfiles = this.get('storage.recentProfiles') || [];
      if (!recentProfiles.includes(profile.get('id'))) {
        recentProfiles.unshift(profile.get('id'));
        this.set('storage.recentProfiles', recentProfiles.slice(0, 5)); //Only keep max 10 recents
      }
    },


    actions: {
      selectProfile: function selectProfile(profile, closeMenu) {
        this.setStorageProfile(profile);
        this.get('profileService').setProfile(profile, { resetSort: true });
        if (closeMenu) {
          closeMenu();
        }
      },
      toggleRecents: function toggleRecents() {
        this.toggleProperty('showRecentProfiles');
      }
    }
  });
});
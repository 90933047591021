define("client/models/research/article", ["exports", "client/models/trend-article"], function (exports, _trendArticle) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var Article = _trendArticle.default.extend({});

  // TODO change to the right endpoint.
  Article.pathForType = "top_articles";

  exports.default = Article;
});
define("client/adapters/profile", ["exports", "client/adapters/v3-application"], function (exports, _v3Application) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _v3Application.default.extend({
    shouldBackgroundReloadRecord: function shouldBackgroundReloadRecord() {
      return false;
    },

    shouldReloadRecord: function shouldReloadRecord(store, snapshot) {
      return snapshot.record.get("header_data");
    }
  });
});
define('client/components/sort-chooser', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var SortOption = Ember.Object.extend({
    name: null,
    isSelected: Ember.computed('value', 'parent.sort', function () {
      return this.get('value') === this.get('parent.sort');
    }),
    sortByRelevanceLabel: Ember.computed('label', 'lowerLabel', function () {
      var sortLabel = this.get('lowerLabel') ? this.get('lowerLabel') : this.get('label');
      return "Sort by relevance instead of " + sortLabel;
    })
  });

  exports.default = Ember.Component.extend({
    content: null,
    classNameBindings: ["sort-chooser"],

    relevanceOverride: null,
    searchTerm: "",

    // The master bound value that determines how everything else gets computed out.
    sort: "",

    // Calculates groups and instantiates sort option objects.
    // Note: Sort option objects can possibly get instantiated more than once,
    //       but currently this is a non-issue.
    groups: Ember.computed("content.sorts", function () {
      var groups = this.get("content.sorts"),
          _this = this;

      if (Ember.isEmpty(groups)) {
        return [];
      }
      return groups.map(function (group) {
        return Ember.ArrayProxy.create({
          content: group.get("options").map(function (option) {
            return SortOption.create({
              parent: _this,
              label: option.label,
              lowerLabel: option.lowerLabel,
              value: option.value,
              disabled: option.disabled
            });
          }, this).compact(),
          name: group.get("group")
        });
      }, this);
    }),

    // Flattens the groups into an array of sort options
    flatSorts: Ember.computed("groups", function () {
      var groups = this.get("groups");
      var sorts = [];
      if (Ember.isPresent(groups)) {
        groups.forEach(function (group) {
          if (Ember.isPresent(group) && Ember.isPresent(group.get('content'))) {
            sorts = sorts.concat(group.get('content'));
          }
        });
      }
      return sorts;
    }),

    // The selected sort object.
    selectedSort: Ember.computed("sort", "flatSorts", function () {
      var flatSorts = this.get('flatSorts'),
          result = flatSorts.findBy('value', this.get('sort'));
      if (!result || !result.label) {
        result = flatSorts.get("firstObject");
        if (!result) {
          return;
        }
      }
      return result;
    }),

    selectedSortLabel: Ember.computed.alias('selectedSort.label'),

    // Filters sort options according to the sort search term.
    searchSorts: Ember.computed("flatSorts", "searchTerm", function () {
      var searchTerm = this.get("searchTerm");
      if (searchTerm == null || searchTerm.length === 0) {
        return null;
      }
      return this.get("flatSorts").filter(function (p) {
        return p.get("label").toLowerCase().indexOf(searchTerm.toLowerCase()) >= 0;
      });
    }),

    selectSort: function selectSort() {},


    actions: {
      selectSort: function selectSort(item) {
        if (!item.disabled) {
          this.selectSort(item.value);
        }
      }
    }
  });
});
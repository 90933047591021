define('client/services/ajax', ['exports', 'ember-ajax/services/ajax'], function (exports, _ajax) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _ajax.default.extend({
    session: Ember.inject.service(),

    contentType: 'application/json; charset=utf-8',

    headers: Ember.computed("session.isDebugES", function () {
      return this.session.get("isDebugES") ? { "is-debug-es": "true" } : {};
    })
  });
});
define('client/components/research/author-info', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ["author-info"],

    isHovering: false,

    authorId: null,
    authorName: null,

    mouseEnter: function mouseEnter() {
      this._willLeave = false;
      this.set('isHovering', true);
    },
    mouseLeave: function mouseLeave() {
      this._willLeave = true;
      Ember.run.later(this, function () {
        if (this._willLeave) {
          this.set('isHovering', false);
        }
      }, 500);
    },
    openAuthorProfile: function openAuthorProfile() {},


    actions: {
      sum: function sum(v1, v2) {
        return v1 + v2;
      }
    }
  });
});
define('client/models/author-reason', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var REASONS = {
    recent_authors: "This author recently wrote about '[search_term]'",
    trending_authors: "This author wrote about '[search_term]' and is trending",
    competitive_authors: "This author wrote about '[search_term]' AND one of your competitors",
    social_authors: "This author wrote about '[search_term]' and was widely shared on social media",
    size_authors: "This author writes about '[search_term]' for a similar size publication",
    same_pub_authors: "This author writes for the same publication"
  };

  exports.default = _emberData.default.Model.extend({
    reason: _emberData.default.attr(),
    term: _emberData.default.attr(),

    reasonText: Ember.computed("reason", "term", function () {
      var reason = this.get("reason");

      if (!reason) {
        return null;
      }

      return REASONS[reason].replace("[search_term]", this.get("term"));
    })
  });
});
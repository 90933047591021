define('client/components/check-box', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['checkbox'],
    classNameBindings: ["disabled", "emphasized"],
    content: false,
    emphasized: false,
    partial: false,
    disabled: false,
    change: function change() {},
    didInsertElement: function didInsertElement() {
      this.setIntermediate();
    },
    didReceiveAttrs: function didReceiveAttrs() {
      this.setIntermediate();
    },


    tooltip: null,
    tooltipPlacement: "right",

    setIntermediate: function setIntermediate() {
      var input = this.$('[data-check-box-input]');
      if (input) {
        input.prop("indeterminate", this.get('partial'));
      }
    },


    isTrue: Ember.computed.equal('content', true),

    actions: {
      toggleCheckbox: function toggleCheckbox(value) {
        if (!this.get('disabled')) {
          this.set('content', value);
          this.change();
        }
      }
    }
  });
});
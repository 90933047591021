define('client/components/intercom-help', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['help-intercom'],
    session: Ember.inject.service(),

    click: function click() {
      if (typeof Intercom !== 'undefined') {
        this.session.showingHelp ? Intercom('hide') : Intercom('show');
        this.session.showingHelp = !this.session.showingHelp;
      } else {
        console.error("Intercom not loaded");
      }
    }
  });
});
define('client/services/ability', ['exports', 'client/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    currentUser: Ember.inject.service("current-user"),
    userPermissions: Ember.computed.alias("currentUser.permissions"),
    restrictedRoutes: Ember.computed("userPermissions", "permissionRoutesMap", function () {
      var permissions = this.userPermissions;
      var routesMap = this.permissionRoutesMap;
      var routes = [];
      if (Ember.isBlank(permissions) && !this.currentUser.get("isAirprAdmin")) {
        routes.push("settings.user-management");
      } else {
        permissions.forEach(function (permissionRecord) {
          var moduleName = permissionRecord.name;
          var permissionType = permissionRecord.permission;
          if (Ember.isBlank(permissionType)) {
            // prevent user management access if the permission is not set explicitly
            // but allow edit access for other set of permissions
            permissionType = moduleName === "settings_user_management" ? "no_access" : "edit";
          }

          if (permissionType === "no_access") {
            var routeName = routesMap[moduleName];
            routes.push(routeName);
          }
        });
      }
      return routes.uniq().compact();
    }),
    // Permission to routes mapping hash to determine the routes that need to
    // be prevented access corresponding to the permission name
    permissionRoutesMap: { "settings_competitor_setup": "settings.competitors.index",
      "settings_company_setup": "settings.company.index",
      "settings_impact_score": "settings.impact-score-settings.index",
      "settings_owned_media_channels": "settings.owned-media-channels.index",
      "settings_crawler_search_terms": "settings.crawler-search-terms.index",
      "settings_term_counts": "settings.term-counts.index",
      "settings_search_seeds": "settings.search-seeds",
      "settings_custom_colors": "settings.custom-colors.index",
      "settings_email_digest": "settings.email-digest.index",
      "settings_user_management": "settings.user-management",
      "settings_user_setup": "settings.user-setup.index",
      "settings_analytics_integration": "settings.analytics-integration.index",
      "settings_social_media_integration": "settings.social-media-integration.index",
      "settings_performance_metrics": "settings.pr-performance-metrics.index",
      "settings_added_articles": "settings.added-articles.index",
      "settings_excluded_articles": "settings.excluded-articles.index",
      "settings_hidden_campaigns": "settings.excluded-campaign-articles.index",
      "settings_excluded_domains": "settings.excluded-domains.index",
      "settings_tag_management": "settings.tag-management.index",
      "settings_export_management": "settings.export-management.index",
      "research_trends_articles": "research.trends",
      "report_create_new": "reports.new.index",
      "global_save_new_campaign": "plan.campaigns.new" },

    getUserPermission: function getUserPermission(moduleName) {
      if (_environment.default.environment === 'test' || this.currentUser.get('isAirprAdmin')) {
        return 'edit';
      }
      var permissionObjects = this.userPermissions.filterBy("name", moduleName);
      var permissionObject = permissionObjects.firstObject || null;
      var permissionValue = permissionObject && permissionObject.permission;
      if (Ember.isPresent(permissionValue)) {
        return permissionValue;
      } else {
        // prevent user management access if the permission is not set explicitly
        // but allow edit access for other set of permissions
        return moduleName === "settings_user_management" ? "no_access" : "edit";
      }
    },
    getRouteName: function getRouteName(moduleName) {
      var permissionRoutesMap = this.permissionRoutesMap;
      return permissionRoutesMap.get(moduleName) || null;
    },
    canAccess: function canAccess(moduleName) {
      var permissionValue = this.getUserPermission(moduleName);
      return permissionValue !== "no_access";
    },
    canManage: function canManage(moduleName) {
      var permissionValue = this.getUserPermission(moduleName);
      return permissionValue === "edit";
    },
    isRestrictedRoute: function isRestrictedRoute(routeName) {
      return this.restrictedRoutes.includes(routeName);
    }
  });
});
define('client/mixins/new-filters-mixin', ['exports', 'client/mixins/volume-filter', 'client/mixins/content-filter', 'client/mixins/channel-filter', 'client/mixins/tag-filter', 'client/mixins/new-location-filter'], function (exports, _volumeFilter, _contentFilter, _channelFilter, _tagFilter, _newLocationFilter) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var NewFiltersMixin = Ember.Mixin.create(_volumeFilter.default, _contentFilter.default, _channelFilter.default, _newLocationFilter.default, _tagFilter.default, {

    influential: Ember.computed.alias('savedFilters.filters.influential'),
    uninfluential: Ember.computed.alias('savedFilters.filters.uninfluential'),
    whitelistTagIds: Ember.computed.alias('savedFilters.filters.whitelist_tag_ids'),
    companyWebsiteLink: Ember.computed.alias('savedFilters.filters.company_website_link'),
    relevanceValue: Ember.computed.alias('savedFilters.filters.relevance'),
    relevance: Ember.computed('relevanceValue', function () {
      return {
        value: this.get('relevanceValue')
      };
    }),

    news: Ember.computed.alias('savedFilters.filters.news'),
    twitter: Ember.computed.alias('savedFilters.filters.twitter'),
    linkedin_pulse: Ember.computed.alias('savedFilters.filters.linkedin_pulse'),
    gplus: Ember.computed.alias('savedFilters.filters.gplus'),
    radio: Ember.computed.alias('savedFilters.filters.radio'),
    television: Ember.computed.alias('savedFilters.filters.television'),
    podcast: Ember.computed.alias('savedFilters.filters.podcast'),
    youtube_bc: Ember.computed.alias('savedFilters.filters.youtube_bc'),
    printPublication: Ember.computed.alias('savedFilters.filters.print_publication'),

    media_earned: Ember.computed.alias('savedFilters.filters.media_earned'),
    media_owned: Ember.computed.alias('savedFilters.filters.media_owned'),
    press_release: Ember.computed.alias('savedFilters.filters.press_release'),

    // TODO(Tony) For some reason I can't find any ember-data state that will let me know
    // when the tag data has come back from the server after the POST and has been loaded
    // into the tag. Thus, I'm forced to do this hack here where I assume that if the
    // user_name is null than we have not finished retrieving this value from the server
    savedTags: Ember.computed('activeProfile.tags.@each.user_name', function () {
      return (this.get('activeProfile.tags') || []).filter(function (tag) {
        return tag.get('user_name');
      });
    }),

    actions: {
      relevanceDidChange: function relevanceDidChange(relevanceValue) {
        this.set('relevanceValue', relevanceValue);
      }
    }
  });

  exports.default = NewFiltersMixin;
});
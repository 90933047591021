define('client/models/author', ['exports', 'ember-data', 'ember-api-actions'], function (exports, _emberData, _emberApiActions) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    authorNotes: _emberData.default.hasMany('author-note', { polymorphic: true }),
    authorStats: _emberData.default.hasMany('author-stat'),
    publishers: _emberData.default.hasMany('publisher'),
    name: _emberData.default.attr('string'),
    lastName: _emberData.default.attr('string'),
    image: _emberData.default.attr('string'),
    authorSentiment: _emberData.default.attr('string'),
    score: _emberData.default.attr('number'),
    authorInfluence: _emberData.default.attr('number'),
    linkedin: _emberData.default.attr('string'),
    twitter: _emberData.default.attr('string'),
    urls: _emberData.default.attr(),
    emails: _emberData.default.attr(),
    editorialTopics: _emberData.default.attr('array'),
    location: _emberData.default.attr(),

    requestEmail: (0, _emberApiActions.memberAction)({ path: 'request_author_email', type: 'POST' }),
    emailRequested: false
  });
});
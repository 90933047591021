define('client/authenticators/application', ['exports', 'ember-simple-auth/authenticators/base'], function (exports, _base) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  function attachSessionWatcher(session) {
    Ember.$(document).ajaxComplete(function (event, _ref) {
      var status = _ref.status,
          responseJSON = _ref.responseJSON;

      if (status === 401 && session.get('isAuthenticated') && responseJSON.error === "/login") {
        session.invalidate();
      }
    });
  }

  exports.default = _base.default.extend({
    store: Ember.inject.service(),
    currentUser: Ember.inject.service(),
    ajax: Ember.inject.service(),
    session: Ember.inject.service(),

    getUser: function getUser(reject) {
      var _this = this;

      var store = this.get('store'),
          currentUser = this.get('currentUser');

      return this.get('ajax').request('/v3/api/users/1').then(function (userData) {
        Ember.run(_this, function () {

          attachSessionWatcher(this.get("session"));

          store.pushPayload('user', userData);
          var user = store.peekAll('user').get('firstObject');
          currentUser.set('content', user);
        });
        return userData.user;
      }, reject);
    },
    authenticate: function authenticate() {
      var reject = function reject() {
        // Redirecting the user to Auth0
        var host = window.Client.server_base_route || window.location.host;
        var redirectTo = window.location.protocol + '//' + host + window.Client.login_route_url;
        document.location = redirectTo;
      };
      return this.getUser(reject);
    },
    restore: function restore() {
      // We can't redirect here because this still gets called in shared reports
      // if there is a session in local storage.

      // After rejecting here, the app will attempt to authenticate the normal way
      // and be redirected if not shared reports.
      return this.getUser(Ember.RSVP.Promise.reject());
    },
    invalidate: function invalidate() {
      // Auth0
      var host = window.Client.server_base_route || window.location.host;
      var redirectTo = window.location.protocol + '//' + host + window.Client.logout_route_url;
      document.location = redirectTo;
    }
  });
});
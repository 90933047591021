define('client/components/article-list/article-license', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({

    article: null,
    showLicense: Ember.computed.equal('article.isFinancialTimes', true)

  });
});
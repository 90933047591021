define('client/mixins/droppable', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var jQuery = window.jQuery;
  // VIEW_TYPE = 'text/plain';

  window.Emberella = {};

  /*
    `Emberella.DroppableMixin` adds drop event handling to a view class.
  
    The mixin will attempt to call a large variety of methods on the bound
    controller depending on the type(s) of item dropped on the view. For example,
    dropping a file on the view will send a `didDropFiles` message to the
    controller along with messages like `didDropRichText` and many others.
  
    Dropping another view onto a drop zone will cause the dropped view's content
    to be examined and transformed into a method call such as `didDropThumbnail`
    or `didDropPerson`.
  
    Similar method calls will be made for `dragEnter`, `dragOver`, and
    `dragLeave` events (e.g. `didDragEnterThumbnail`, `didDragOverThumbnail`,
    `didDragLeaveThumbnail`).
  
    In development, Ember will throw warnings if this mixin sends messages that
    are unhandeld.
  
    This mixin is rough around the edges and is not verified to work
    across browsers.
  
    TODO: Refactor.
    TODO: Improve examples and documentation.
  
    @class DroppableMixin
    @namespace Emberella
   */

  var DroppableMixin = Ember.Mixin.create({

    /*
      @property isDroppable
      @type Boolean
      @default true
      @final
     */
    isDroppable: true,

    /*
      The class name to apply to the element with an item dragged over it.
       @property dragOverClass
      @type String
      @default 'dragover'
     */
    dragOverClass: 'dragover',

    /*
      Handle the DOM's `dragEnter` event. This event is triggered as a dragged
      item crosses into the host view's "airspace."
       @event dragEnter
     */
    dragEnter: function dragEnter(e) {
      // e.stopPropagation();
      // e.preventDefault();

      Ember.run.cancel(this._removeClassTimer);
      this._findScrollableParent();
      if (this.didDragEnter(e) !== false) {
        return this._droppableCallMethodForEvent(e, 'didDragEnter');
      }
    },

    /*
      Handle the DOM's `dragOver` event. This event is triggered as a dragged
      item moves around over the host view.
       @event dragOver
     */
    dragOver: function dragOver(e) {
      var $target;
      e.stopPropagation();
      e.preventDefault();

      Ember.run.cancel(this._removeClassTimer);
      $target = jQuery(e.currentTarget);
      $target.addClass(Ember.get(this, 'dragOverClass'));
      if (this.didDragOver(e) !== false) {
        this._scrollDropTarget(e);
        return this._droppableCallMethodForEvent(e, 'didDragOver');
      }
    },

    /*
      Handle the DOM's `dragLeave` event. This event is triggered as a dragged
      item crosses out of the host view's "airspace."
       @event dragLeave
     */
    dragLeave: function dragLeave(e) {
      // e.stopPropagation();
      // e.preventDefault();
      Ember.run.cancel(this._removeClassTimer);
      this._removeClassTimer = Ember.run.debounce(this, "_removeDragClass", 100);

      if (this.didDragLeave(e) !== false) {
        return this._droppableCallMethodForEvent(e, 'didDragLeave');
      }
    },

    /*
      Handle the DOM's `drop` event.
       @event drop
     */
    drop: function drop(e) {
      // e.stopPropagation();
      // e.preventDefault();
      Ember.run.cancel(this._removeClassTimer);
      this.clearDragOverClass();
      if (this.didDrop(e) !== false) {
        return this._droppableCallMethodForEvent(e, 'didDrop');
      }
    },

    /*
      Remove the `dragOverClass` from all droppable elements.
       @method clearDragOverClass
      @chainable
     */
    clearDragOverClass: function clearDragOverClass() {
      var selector;
      selector = ['.', Ember.get(this, 'dragOverClass')].join('');
      jQuery(selector).removeClass(Ember.get(this, 'dragOverClass'));
      return this;
    },
    _removeDragClass: function _removeDragClass() {
      var _ref;
      return (_ref = this.$()) != null ? _ref.removeClass(Ember.get(this, 'dragOverClass')) : void 0;
    },

    /*
      @private
       Call methods to handle the type(s) of item(s) dropped on the host view.
       @method _droppableCallMethodForEvent
      @return null
     */
    _droppableCallMethodForEvent: function _droppableCallMethodForEvent(e, prefix) {
      var data, files, methodKey, text /*, type, _i, _len, types, viewId*/;
      if (!e) {
        return;
      }
      files = e.dataTransfer.files || [];
      // types = e.dataTransfer.types;
      text = e.dataTransfer.getData("Text");
      if (text) {
        methodKey = this._droppableMethodNameForType('text/plain', prefix);
        // viewId = text;
        data = Ember.get(window.Emberella, '_draggableView');
        if (this === data) {
          return false;
        }
        Ember.tryInvoke(this, methodKey, [e, data, !!files.length]);
      }
      // if (types) {
      //   for (_i = 0, _len = types.length; _i < _len; _i++) {
      //     type = types[_i];
      //     methodKey = this._droppableMethodNameForType(type, prefix);
      //     if (type.toUpperCase() === 'FILES' && files.length > 0) {
      //       data = files;
      //     } else if (type === VIEW_TYPE) {
      //       viewId = e.dataTransfer.getData(VIEW_TYPE);
      //       data = jQuery.trim(viewId) === '' ? get(window.Emberella, '_draggableView') : Ember.View.views[viewId];
      //       if (this === data) {
      //         return false;
      //       }
      //     } else {
      //       data = e.dataTransfer.getData(type);
      //     }
      //     Ember.tryInvoke(this, methodKey, [e, data, !!files.length]);
      //   }
      // }
      return null;
    },

    /*
      @private
       Create a method name for the type of item using a specified prefix.
       @method _droppableMethodNameForType
      @return String
     */
    _droppableMethodNameForType: function _droppableMethodNameForType(type, prefix) {
      var methodKey, part, stringParts, _i, _len;
      methodKey = prefix || '';
      stringParts = type.split(/[^a-z0-9]+/i);
      for (_i = 0, _len = stringParts.length; _i < _len; _i++) {
        part = stringParts[_i];
        methodKey = methodKey + part.charAt(0).toUpperCase() + part.slice(1);
      }
      return methodKey;
    },

    /*
      @private
       Send a message to the bound controller regarding the type of item being
      dragged/dropped and the event being performed to it.
       @method _sendDroppableMessage
      @return null
     */
    _sendDroppableMessage: function _sendDroppableMessage(e, view, prefix) {
      var message;
      if (!view) {
        return;
      }
      e.droppableItem = view && view.get("content");
      e.droppableTarget = this.get('content');
      e.droppableSelection = view && view.get('parentView.selection');
      e.droppableView = view;
      message = prefix + "Message";
      Ember.tryInvoke(this, message, [e]);
      return null;
    },

    /*
      @private
       Find the name of the object being dropped.
       @method _extractDroppableItemName
      @return String
     */
    _extractDroppableItemName: function _extractDroppableItemName(item) {
      if (item && jQuery.isFunction(item.getTypeString)) {
        return item.getTypeString();
      } else {
        return this._nameForType(item.constructor);
      }
    },

    /*
      @private
       Find the name of the object being dropped.
       @method _nameForType
      @return String
     */
    _nameForType: function _nameForType(type) {
      var parts, typeString;
      typeString = type.toString();
      parts = typeString.split('.');
      return parts[parts.length - 1];
    },

    /*
      @private
       Look for a parent view that may require scrolling.
       @method _findScrollableParent
      @return jQuery|Null
     */
    _findScrollableParent: function _findScrollableParent() {
      var $scrollable, $testEl, scrollable;
      $scrollable = false;
      $testEl = this.$();
      while ($scrollable === false) {
        if ($testEl.prop('scrollHeight') > $testEl.prop('clientHeight') || $testEl.length === 0) {
          $scrollable = $testEl;
        }
        $testEl = $testEl.parent();
      }
      scrollable = $scrollable.length ? $scrollable : null;
      Ember.set(this, 'scrollableParent', scrollable);
      return scrollable;
    },

    /*
      @private
       Scroll if the `dragOver` event position is within 20px of the viewable
      portion of the scrolling area.
       @method _scrollDropTarget
      @return null
     */
    _scrollDropTarget: function _scrollDropTarget(e) {
      var $scrollable, adjustment, clientY, scrollableHeight, scrollableOffsetTop;
      clientY = e.originalEvent.clientY;
      adjustment = 0;
      $scrollable = Ember.get(this, 'scrollableParent');
      if (!$scrollable) {
        return;
      }
      scrollableOffsetTop = $scrollable.offset().top;
      scrollableHeight = $scrollable.height();
      if (clientY - scrollableOffsetTop < 20) {
        adjustment = -1 * Math.pow(Math.abs(clientY - scrollableOffsetTop - 20), 1.6);
      }
      if (scrollableOffsetTop + scrollableHeight - clientY < 20) {
        adjustment = Math.pow(Math.abs(scrollableOffsetTop + scrollableHeight - clientY - 20), 1.6);
      }
      $scrollable.prop('scrollTop', $scrollable.prop('scrollTop') + adjustment);
      return null;
    },
    didDragEnterView: function didDragEnterView(e, view) {
      return this._sendDroppableMessage(e, view, 'didDragEnter');
    },
    didDragOverView: function didDragOverView(e, view) {
      return this._sendDroppableMessage(e, view, 'didDragOver');
    },
    didDragLeaveView: function didDragLeaveView(e, view) {
      return this._sendDroppableMessage(e, view, 'didDragLeave');
    },
    didDropView: function didDropView(e, view) {
      return this._sendDroppableMessage(e, view, 'didDrop');
    },
    didDropTextPlain: function didDropTextPlain(e, view) {
      return this._sendDroppableMessage(e, view, 'didDrop');
    },
    didDropFiles: function didDropFiles(e, files, areFiles) {
      var _ref;
      if (areFiles) {
        return (_ref = Ember.get(this, 'controller')) != null ? _ref.send('didDropFiles', Ember.get(this, 'content'), files) : void 0;
      }
    },
    didDragEnter: function didDragEnter() {
      return this;
    },
    didDragOver: function didDragOver() {
      return this;
    },
    didDragLeave: function didDragLeave() {
      return this;
    },
    didDrop: function didDrop() {
      return this;
    }
  });

  exports.default = DroppableMixin;
});
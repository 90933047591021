define('client/mixins/rich-text', ['exports', 'client/utils/rich-text-fonts'], function (exports, _richTextFonts) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var TEXT_NODE = 3;
  var FONT_SIZES = [];
  for (var i = 8; i <= 64; i++) {
    FONT_SIZES.push(i + 'px');
  }

  var FONT_STYLES = Ember.A([_richTextFonts.default.HELVETICA, _richTextFonts.default.TAHOMA, _richTextFonts.default.LUCIDA, _richTextFonts.default.VERDANA, _richTextFonts.default.TIMES_NEW_ROMAN, _richTextFonts.default.GARAMOND, _richTextFonts.default.PALATINO, _richTextFonts.default.COURIER_NEW]);

  var RichTextMixin = Ember.Mixin.create({
    notify: Ember.inject.service(),
    bold: false,
    italic: false,
    strikethrough: false,
    underline: false,
    orderedList: false,

    textColor: '000000',
    textHighlightColor: 'FFFFFF',

    cursorLeft: null,
    cursorTop: null,

    linkUrl: '',

    fontSizes: FONT_SIZES,
    selectedFontSize: '14px',

    fontStyles: FONT_STYLES,
    selectedFontFamily: _richTextFonts.default.HELVETICA.family,
    selectedFont: Ember.computed('selectedFontFamily', {
      get: function get() {
        return this.get('fontStyles').findBy('family', this.get('selectedFontFamily'));
      },
      set: function set(key, selectedFont) {
        this.set('selectedFontFamily', selectedFont.family);
        return selectedFont;
      }
    }),

    editorDocumentBody: Ember.computed(function () {
      return this.get('editor').getDocument().body;
    }).volatile(),

    createEditor: Ember.on("didInsertElement", function () {
      var _this = this;

      if (Ember.isNone(this.get('editor'))) {
        this.initializeEditor();
      }
      if (Ember.isPresent(this.get('editor'))) {
        var editor = this.get('editor');
        editor.blur();
        editor.addEventListener('pathChange', function (event) {
          _this.checkFormats();
        });
        editor.addEventListener('keydown', function (event) {
          _this.checkFormats();
        });
        editor.addEventListener('input', function (event) {
          var body = _this.get('editorDocumentBody');
          _this.set('hasHorizontalLine', !!Ember.$(body).find('hr').length);
        });
        editor.setKeyHandler("meta-b", function (squire, event, range) {
          _this._toggleBold();
          _this.checkFormats();
          event.preventDefault();
        });
      }
    }),

    initializeEditor: function initializeEditor() {
      throw { name: 'NotImplementedError', message: 'Need to implement initializeEditor downstream' };
    },

    isEditingDidChange: Ember.observer('isEditing', function () {
      if (this.get('isEditing')) {
        this.checkFormats();
      } else {
        this.get('editor').moveCursorToStart();
        this.setProperties({
          bold: false,
          italic: false,
          underline: false,
          strikethrough: false,
          orderedList: false,
          unorderedList: false,
          hyperlink: false,
          currentLink: '',
          cursorLeft: null,
          cursorTop: null
        });
      }
    }),

    // need observers to use ember col-pick: https://github.com/yapplabs/ember-colpick
    // TODO: replace with color-picker that uses actions
    textColorDidChange: Ember.observer('textColor', function () {
      Ember.run.debounce(this, 'changeTextColor', 60);
    }),

    changeTextColor: function changeTextColor() {
      this.get('editor').setTextColour('#' + this.get('textColor'));
      this.reapplyStyling();
    },

    textHighlightColorDidChange: Ember.observer('textHighlightColor', function () {
      Ember.run.debounce(this, 'changeTextHighlightColor', 60);
    }),

    changeTextHighlightColor: function changeTextHighlightColor() {
      this.get('editor').setHighlightColour('#' + this.get('textHighlightColor'));
    },

    checkFormats: function checkFormats() {
      var editor = this.get('editor');
      var fontInfo = editor.getFontInfo();
      if (fontInfo.family) {
        var family = fontInfo.family.replace(', sans-serif', '').replace(', serif', '');
        this.set('selectedFontFamily', family);
      }
      if (fontInfo.size) {
        this.set('selectedFontSize', fontInfo.size);
      }
      var properties = {
        bold: this._isBolded(),
        italic: editor.hasFormat('i'),
        underline: editor.hasFormat('u'),
        strikethrough: editor.hasFormat('s'),
        orderedList: editor.hasFormat('ol'),
        unorderedList: editor.hasFormat('ul'),
        hyperlink: editor.hasFormat('a'),
        currentLink: ''
      };
      if (properties.hyperlink) {
        var textBoxPosition = this.$().offset();
        var $anchorTag = this._getClosestAnchorTag();
        var anchorPosition = $anchorTag.position();
        if (anchorPosition) {
          properties.cursorLeft = anchorPosition.left + textBoxPosition.left - 10;
          properties.cursorTop = anchorPosition.top + $anchorTag.height() + textBoxPosition.top + Ember.$(".reports-container").scrollTop();
          properties.currentLink = properties.previousLink = $anchorTag.prop('href');
        }
      }

      this.setProperties(properties);
    },

    //workaround for https://github.com/neilj/Squire/issues/238
    reapplyStyling: function reapplyStyling() {
      Ember.run.schedule('afterRender', this, function () {
        var editor = this.get('editor');
        var fontInfo = editor.getFontInfo();
        if (fontInfo.color) {
          editor.setTextColour(fontInfo.color);
        }
        if (fontInfo.backgroundColor) {
          editor.setHighlightColour(fontInfo.backgroundColor);
        }
        // This ensures the underline color stays the same as the font color
        if (this.get('underline')) {
          this.get('editor').removeUnderline().underline();
        }
      });
    },

    // see https://github.com/neilj/Squire/blob/master/source/Editor.js#L926 for reference
    _isBolded: function _isBolded() {
      var editor = this.get('editor');
      var range = editor.getSelection();
      var element = range.commonAncestorContainer;
      var weight = void 0;
      if (range.collapsed || element.nodeType === TEXT_NODE) {
        if (element.nodeType === TEXT_NODE) {
          element = element.parentNode;
        }
        while (element && !weight) {
          if (element.style && element.style.fontWeight) {
            weight = element.style.fontWeight;
          }
          element = element.parentNode;
        }
      }
      return weight !== "300" && editor.hasFormat('b') || weight === "bold";
    },

    _getClosestAnchorTag: function _getClosestAnchorTag() {
      return Ember.$(this.get('editor').getSelection().commonAncestorContainer).closest('a');
    },

    _setHorizontalLineButton: function _setHorizontalLineButton() {
      var body = this.get('editorDocumentBody');
      this.get('editor').saveUndoState();
      this.set('hasHorizontalLine', !!Ember.$(body).find('hr').length);
    },

    _httpifiedUrl: function _httpifiedUrl(string) {
      //just check for :, so user can use any URI scheme ('mailto:', 'ftp:'. 'chrome:', etc)
      if (string.indexOf(':') > -1) {
        return string;
      } else {
        return 'http://' + string;
      }
    },

    _toggleBold: function _toggleBold() {
      var editor = this.get('editor');
      if (this._isBolded()) {
        editor.removeBold();
        editor.changeFormat(null, {
          tag: 'SPAN',
          attributes: { style: 'font-weight: bold;' }
        });
        editor.changeFormat({
          tag: 'SPAN',
          attributes: { style: 'font-weight: 300;' }
        });
      } else {
        editor.bold();
        editor.changeFormat(null, {
          tag: 'SPAN',
          attributes: { style: 'font-weight: 300;' }
        });
        editor.changeFormat({
          tag: 'SPAN',
          attributes: { style: 'font-weight: bold;' }
        });
      }
    },

    actions: {
      toggleBold: function toggleBold() {
        this._toggleBold();
      },
      toggleItalic: function toggleItalic() {
        var editor = this.get('editor');
        editor.hasFormat('i') ? editor.removeItalic() : editor.italic();
      },
      toggleUnderline: function toggleUnderline() {
        var editor = this.get('editor');
        editor.hasFormat('u') ? editor.removeUnderline() : editor.underline();
      },
      toggleStrikethrough: function toggleStrikethrough() {
        var editor = this.get('editor');
        editor.hasFormat('s') ? editor.removeStrikethrough() : editor.strikethrough();
      },
      toggleOrderedList: function toggleOrderedList() {
        var editor = this.get('editor');
        editor.hasFormat('ol') ? editor.removeList() : editor.makeOrderedList();
      },
      toggleUnorderedList: function toggleUnorderedList() {
        var editor = this.get('editor');
        editor.hasFormat('ul') ? editor.removeList() : editor.makeUnorderedList();
      },
      makeLink: function makeLink() {
        var uri = this._httpifiedUrl(this.get('linkUrl'));
        this.get('editor').makeLink(uri, { target: '_blank' });
        this.set('linkUrl', '');
      },
      removeLink: function removeLink() {
        this.get('editor').saveUndoState();
        this.get('editor').removeLink();
      },
      editCurrentLink: function editCurrentLink() {
        this.get('editor').saveUndoState();
        var uri = this._httpifiedUrl(this.get('currentLink'));
        this._getClosestAnchorTag().prop('href', uri);
      },
      toggleHorizontalLine: function toggleHorizontalLine() {
        var $hr = Ember.$(this.get('editorDocumentBody')).find('hr, .hr-container');
        if ($hr.length) {
          this.get('editor').saveUndoState();
          $hr.remove();
          this.set('hasHorizontalLine', false);
        } else {
          var fontSize = this.get('selectedFontSize');
          var styles = 'font-family:' + this.get('selectedFontFamily') + ';';
          if (this.get('bold')) {
            styles = styles + 'font-weight: bold;';
          }
          this.get('editor').insertHTML('<div style="' + styles + '"><hr style="margin: 3px auto;"><br></div>').setFontSize(fontSize);
          this.set('hasHorizontalLine', true);
        }
      },
      changeAlignment: function changeAlignment(alignment) {
        this.get('editor').setTextAlignment(alignment);
      },
      increaseIndent: function increaseIndent() {
        var editor = this.get('editor');
        if (editor.hasFormat('ul') || editor.hasFormat('ol')) {
          editor.increaseListLevel();
        } else {
          editor.increaseQuoteLevel();
        }
      },
      decreaseIndent: function decreaseIndent() {
        var editor = this.get('editor');
        if (editor.hasFormat('ul') || editor.hasFormat('ol')) {
          editor.decreaseListLevel();
        } else {
          editor.decreaseQuoteLevel();
        }
      },
      changeFontStyle: function changeFontStyle(font) {
        this.set('selectedFont', font);
        Ember.run.schedule('afterRender', this, function () {
          this.get('editor').setFontFace(font.family);
          this.reapplyStyling();
          this.get('editor').focus();
        });
      },
      changeFontSize: function changeFontSize(fontSize) {
        this.set('selectedFontSize', fontSize);
        Ember.run.schedule('afterRender', this, function () {
          this.get('editor').setFontSize(fontSize);
          this.reapplyStyling();
          this.get('editor').focus();
        });
      },
      changeFontColor: function changeFontColor(_index, color) {
        this.set('textColor', color.replace('#', ''));
        this.changeTextColor();
        this.reapplyStyling();
      },
      changeHighlightColor: function changeHighlightColor(_index, color) {
        this.set('textHighlightColor', color.replace('#', ''));
        this.changeTextHighlightColor();
        this.reapplyStyling();
      },
      clearFormatting: function clearFormatting() {
        var selection = this.get('editor').getSelection();
        if (selection.startOffset === selection.endOffset) {
          this.get('notify').info('You must select some text first');
        } else {
          this.get('editor').removeAllFormatting();
        }
      }
    }
  });

  exports.default = RichTextMixin;
});
define('client/components/expanded-article-value-interactions-metric', ['exports', 'client/computed/index', 'client/components/article-metric'], function (exports, _index, _articleMetric) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var ExpandedArticleInteractionsMetricComponent = _articleMetric.default.extend({
    layoutName: 'components/expanded-article-metric',
    classNames: ["metric_interaction"],
    metricTitle: Ember.computed.alias("profile.nameValueInteraction"),
    conversionsTitle: Ember.computed.alias("profile.nameValueInteraction"),
    iconClass: "metric-icon metric-value-interaction",
    visitorCounts: Ember.computed.alias("visitorMetric"),
    visitorCountsForDateRange: Ember.computed.alias("visitorMetricForDateRange"),

    tooltipTitle: Ember.computed('profile.nameCustomer', 'profile.nameValueInteraction', function () {
      var customers = this.get("profile.nameCustomer");
      var totalInteraction = '' + this.get('profile.nameValueInteraction');
      return totalInteraction + ' count is the total number of value interactions taken by ' + customers + ' who came to your site as a result of PR activities. ' + customers + ' may trigger multiple events in one session.';
    }),

    formatter: function formatter(iCount) {
      iCount = iCount || 0;
      return iCount.toLocaleString();
    },

    formattedMetricTotal: Ember.computed('expandedArticleShowsCurrentSelection', 'requestedMetric', function () {
      var iCount = this.get('requestedMetric');
      return this.get('formatter')(iCount);
    }),

    formattedConversions: Ember.computed('expandedArticleShowsCurrentSelection', 'requestedConversions', function () {
      var conversions = this.get('requestedConversions');
      var formatter = this.get('formatter');
      var formattedConversions = (conversions || []).map(function (d) {
        return {
          name: d.name,
          value: formatter(d.value)
        };
      });
      return formattedConversions;
    }),

    requestedConversions: Ember.computed('expandedArticleShowsCurrentSelection', 'conversions', 'conversionsForDateRange', function () {
      return this.get('expandedArticleShowsCurrentSelection') ? this.get('conversionsForDateRange') : this.get('conversions');
    }),

    requestedMetric: Ember.computed('expandedArticleShowsCurrentSelection', 'metric', 'metricForDateRange', function () {
      return this.get('expandedArticleShowsCurrentSelection') ? this.get('metricForDateRange') : this.get('metric');
    })
  });

  exports.default = ExpandedArticleInteractionsMetricComponent;
});
define('client/utils/reports/item', ['exports', 'client/utils/reports/generate-id'], function (exports, _generateId) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var Item = Ember.Object.extend(Ember.Evented, {
    init: function init() {
      var id = this.get("id");
      if (!id) {
        this.set("id", (0, _generateId.default)(this.get('page.report.id')));
      }
    },


    page: null,

    id: null,
    row: null,
    col: null,

    colspan: null,
    rowspan: null,

    content: null,

    config: null,

    touch: function touch() {
      var page = this.get("page");
      page.rendered();
    },
    replaceItemHtml: function replaceItemHtml(newHtml) {
      // get the page html
      var page = this.get("page"),
          html = page.get("renderedHtml");

      var $old = Ember.$(html),
          $parent = $old.find('[data-id=' + this.get("id") + ']').parent();

      if (!html || $parent.length < 1) {
        // no html yet
        page.rendered();
        return;
      }
      // In share and pdf mode, the contenteditable should be marked non-editable
      html.replace(/contenteditable="true"/i, 'contenteditable="false"');

      $parent = $parent.replaceWith(newHtml);

      // wrap it out to extract it again
      $old = Ember.$("<div></div>").append($old);

      page.set("renderedHtml", $old.html());
      page.touch();
    },
    serialize: function serialize() {
      var props = this.getProperties("id", "row", "col", "rowspan", "colspan", "content");
      props.config = this.get("config");
      return props;
    }
  });

  // Item.reopenClass({
  //   deserialize(data){
  //     data.config = Config.findBy("name", data.config);
  //     return Item.create(data);
  //   }
  // });

  exports.default = Item;
});
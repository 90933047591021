define('client/models/report-items/chart-profile-options', ['exports', 'client/models/report-item', 'client/models/report-items/profile-config'], function (exports, _reportItem, _profileConfig) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    prevKnownProfileOptions: null,

    profileFilterList: (0, _reportItem.propWithDefaultValue)("profileFilterList", function () {
      return this.get('profileOptions').slice(0, 4).getEach('nonUniqueIdentifier');
    }),

    mainProfileConfig: Ember.computed('profile.id', 'profile_id', 'campaign_id', 'searchTerm', 'mainProfileNameOverride', function () {
      return _profileConfig.default.create({
        profileId: this.get('profile_id') || this.get('profile.id'),
        campaignId: this.get('campaign_id') || this.get('campaign.id'),
        store: this.get('store') || this.get('profile.store'),
        nameOverride: this.get('mainProfileNameOverride'),
        isActive: true,
        isMainConfig: true,
        item: this
      });
    }),

    profileOptions: Ember.computed.alias('otherProfiles'),
    allProfiles: Ember.computed('mainProfileConfig', 'otherProfiles.[]', function () {
      var otherProfiles = (this.get('otherProfiles') || Ember.A()).slice(); //Shallow Copy array
      otherProfiles.unshiftObject(this.get('mainProfileConfig'));
      return otherProfiles;
    }),

    allProfileUINames: Ember.computed.mapBy('allProfiles', 'displayName')
  });
});
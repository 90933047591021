define('client/components/plan/campaigns/campaign-volume', ['exports', 'client/utils/campaign-helper'], function (exports, _campaignHelper) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['campaign-volume', 'campaign-creation-navigation'],
    prompt: 'What volume of coverage do you want to track for this campaign?',
    selectedVolumeCoverage: null,

    whitelistTags: null,

    init: function init() {
      this._super.apply(this, arguments);
      if (!this.get('selectedVolumeCoverage')) {
        this.set('selectedVolumeCoverage', _campaignHelper.VOLUME_OPTIONS[0]);
      }
    },
    onSelectedVolumeCoverage: function onSelectedVolumeCoverage() {},
    onNext: function onNext() {},
    onPrevious: function onPrevious() {},


    actions: {
      onNext: function onNext() {
        this.onSelectedVolumeCoverage(this.get('selectedVolumeCoverage'), this.get('whitelistTags'));
        this.onNext();
      },
      onBack: function onBack() {
        this.onPrevious();
      },
      updatedWhitelistTags: function updatedWhitelistTags(tags) {
        this.set('whitelistTags', tags);
      }
    }
  });
});
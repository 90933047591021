define("client/components/reports/chart-column", ["exports", "client/components/reports/chart-base-widget"], function (exports, _chartBaseWidget) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _chartBaseWidget.default.extend({
    classNames: ["report-chart-item"],

    canAddOtherProfiles: true
  });
});
define('client/mixins/filter-route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var FilterRouteMixin = Ember.Mixin.create({
    // The controller the route was fed on setup.
    _main_controller: null,
    // Force refresh on setupController. This is necessary for pages to load properly.
    _invalidate_on_setup: true,
    // Force refresh each time page is changed. This seems unecessary right now
    // because refresh is already being triggered.
    _invalidate_on_activate: false,
    // Close the filter box when deactivating controller.
    _close_filter_on_deactivate: true,

    setupController: function setupController(controller) {
      // Save controller for later use if necessary.
      this.set('_main_controller', controller);
      if (this.get('_invalidate_on_setup')) {
        //console.error({setup_invalidating:controller});
        //We need to schedule the invalidation on the runloop
        //because(probably due to an Ember bug which I could not isolate)
        //the query param values are not available on the controller immediately
        //during the initial setupController call
        Ember.run.schedule('afterRender', function () {
          controller.invalidate();
        });
      }
    },
    activate: function activate() {
      this._super.apply(this, arguments);
      //Ensure data gets reloaded.
      var controller;
      if (this.get('_invalidate_on_activate')) {
        controller = this.get('_main_controller');
        if (controller && controller.invalidate) {
          controller.invalidate();
        }
      }
    },
    deactivate: function deactivate() {
      var controller;
      this._super.apply(this, arguments);
      // Close the filter box.
      if (this.get('_close_filter_on_deactivate')) {
        controller = this.controllerFor('profile');
        if (controller && controller.closeOptions) {
          controller.closeOptions();
        }
      }
    }
  });

  exports.default = FilterRouteMixin;
});
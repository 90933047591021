define('client/components/article-list/article-pov-indicator', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNameBindings: ['expandedView:pov-indicator-full', 'oneLine::pov-indicator'],
    tagName: 'span',
    values: null,
    inline: true,
    oneLine: false,
    onlyReputation: false,
    hmlDefaults: null,
    hmlInlineDefaults: null,
    hmlClasses: null,

    expandedView: Ember.computed.not('inline'),

    valueConverter: function valueConverter(value) {
      return this.get(this.get('inline') ? 'hmlInlineDefaults' : 'hmlDefaults')[value];
    },
    classConverter: function classConverter(value) {
      return this.get('hmlClasses')[value];
    },
    tipConverter: function tipConverter(value, type) {
      if (type === 'reputation') {
        if (this.get('onlyReputation')) {
          if (value === 'h') {
            return 'Influence of the publication & strength of search impact is high.';
          }
          if (value === 'm') {
            return 'Influence of the publication & strength of search impact is medium.';
          }
          if (value === 'l') {
            return 'Influence of the publication & strength of search impact is low.';
          }
        } else {
          if (value === 'h') {
            return 'High: Article is published in the top 30% (High) of all publications based on publication authority';
          }
          if (value === 'm') {
            return 'Medium: Article is published in the middle 40% (Medium) of all publications based on publication authority';
          }
          if (value === 'l') {
            return 'Low: Article is published in the bottom 30% (Low) of all publications based on publication authority';
          }
        }
      }
      if (type === 'relevance') {
        if (value === 'h') {
          return 'High: Article is in the top 30% (High) of all articles specific to the brand based on relevance';
        }
        if (value === 'm') {
          return 'Medium: Article is in the middle 40% (Medium) of all articles specific to the brand based on relevance';
        }
        if (value === 'l') {
          return 'Low: Article is in the bottom 30% (Low) of all articles specific to the brand based on relevance';
        }
      }
      if (type === 'repitition') {
        if (value === 'h') {
          return 'High: Article has above average social sharing for this publication.';
        }
        if (value === 'm') {
          return 'Medium: Article has average social sharing for this publication.';
        }
        if (value === 'l') {
          return 'Low: Article has lower than average social sharing for this publication.';
        }
      }
    },


    reputationValue: Ember.computed('values.reputation', function () {
      return this.valueConverter(this.get('values.reputation'));
    }),
    relevanceValue: Ember.computed('values.relevance', function () {
      return this.valueConverter(this.get('values.relevance'));
    }),
    repititionValue: Ember.computed('values.reception', function () {
      return this.valueConverter(this.get('values.reception') || 'm');
    }),
    reputationClass: Ember.computed('values.reputation', function () {
      return this.classConverter(this.get('values.reputation'));
    }),
    relevanceClass: Ember.computed('values.relevance', function () {
      return this.classConverter(this.get('values.relevance'));
    }),
    repititionClass: Ember.computed('values.reception', function () {
      return this.classConverter(this.get('values.reception') || 'm');
    }),
    reputationTooltip: Ember.computed('values.reputation', function () {
      return this.tipConverter(this.get('values.reputation'), 'reputation');
    }),
    relevanceTooltip: Ember.computed('values.relevance', function () {
      return this.tipConverter(this.get('values.relevance'), 'relevance');
    }),
    repititionTooltip: Ember.computed('values.reception', function () {
      return this.tipConverter(this.get('values.reception') || 'm', 'repitition');
    }),

    init: function init() {
      this._super.apply(this, arguments);
      this.setProperties({
        hmlDefaults: { h: 'High', m: 'Med', l: 'Low' },
        hmlInlineDefaults: { h: 'H', m: 'M', l: 'L' },
        hmlClasses: { h: 'high', m: 'medium', l: 'low' }
      });
    }
  });
});
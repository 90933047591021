define('client/services/reports', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    IptcTopics: Ember.inject.service(),
    IptcData: Ember.inject.service(),

    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);
      Ember.$.ajaxPrefilter(function (xhrOptions, originalOptions, xhr) {
        var hex = _this.get("sharedHex");
        if (hex) {
          xhr.setRequestHeader('Authorization', "Token " + hex);
        }
      });

      this.get("IptcTopics").iptcTopicNames().then(function (data) {
        _this.get("IptcData").cacheData(data);
      }).catch(function (error) {
        _this.get("IptcData").cacheData({ all_iptc_topics: {} });
      });
    },


    isEditText: false,
    isEdit: false,
    selectedItem: null,
    isReadOnly: false,
    isViewingShare: false,

    // this is used to show the article list on shared reports
    chartApiHash: null,
    chartDimension: null,
    chartApiFirstSelection: null,

    sharedHex: null,
    editingReportTitle: false,
    currentPage: null,
    currentPageNumber: Ember.computed('currentPage.page', function () {
      return this.get('currentPage.page');
    }),

    stopEditing: function stopEditing() {
      Ember.$('.connected-hover').removeClass('connected-hover');
      Ember.$('body').off('keyup.' + this.get('selectedItem.elementId'));
      this.setProperties({
        isEdit: false,
        isEditText: false,
        selectedItem: null,
        editingReportTitle: false
      });
    },

    startEditing: function startEditing(item) {
      // close previous
      var previousEdit = this.get("selectedItem");
      if (previousEdit && item !== previousEdit) {
        previousEdit.send("display");
      }

      // edit new one
      this.setProperties({
        isEditing: true,
        isEdit: true,
        selectedItem: item
      });
    },

    onTitlePage: Ember.computed('currentPageNumber', function () {
      return this.get('currentPageNumber') === 0;
    }),

    notOnTitlePage: Ember.computed.not('onTitlePage')
  });
});
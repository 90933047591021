define('client/components/plan/campaigns/campaign-save', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    store: Ember.inject.service(),

    classNames: ['campaign-name', 'campaign-creation-navigation'],

    prompt: Ember.computed('hasSaved', function () {
      return this.get('hasSaved') ? 'Campaign is saved' : 'Saving the campaign…';
    }),

    selectedCampaignName: null,
    isSaving: false,
    isSaved: false,

    onSave: function onSave() {},
    onPrevious: function onPrevious() {},
    init: function init() {
      this._super.apply(this, arguments);
      this.set('isSaving', true);
      this.onSave();
    },


    hasSaved: Ember.computed('isSaved', 'error', function () {
      return this.get('isSaved') && !this.get('error');
    }),

    actions: {
      onPrevious: function onPrevious() {
        this.onPrevious();
      }
    }
  });
});
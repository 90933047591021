define("client/models/excluded", ["exports", "ember-data"], function (exports, _emberData) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var Excluded = _emberData.default.Model.extend({

    profile: _emberData.default.belongsTo("profile"),
    entity_id: _emberData.default.attr("string"),
    summary: _emberData.default.attr("string"),
    url: _emberData.default.attr("string"),
    timestamp: _emberData.default.attr("isodate"),
    date: _emberData.default.attr("date"),
    created_at: _emberData.default.attr("date"),
    user_spam_flag: _emberData.default.attr('boolean', { defaultValue: false }),
    user_relevance_flag: _emberData.default.attr('boolean', { defaultValue: true }),
    // Required attrs for backend to process where attr names trigger backend specific logic
    spam: _emberData.default.attr("boolean", { defaultValue: false }),
    relevance: _emberData.default.attr("boolean", { defaultValue: true })

  });
  Excluded.pathForType = "excluded";

  exports.default = Excluded;
});
define('client/initializers/modal', ['exports', 'ember-modal-service/models/modal'], function (exports, _modal) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.initialize = initialize;
  function initialize() /* container, application */{
    _modal.default.reopen({
      fullname: Ember.computed('name', function () {
        var name = this.get('name');

        if (Ember.isBlank(name)) {
          throw new Error('Modal must have a name.');
        }

        return name;
      })
    });
  }

  exports.default = {
    name: 'modal',
    initialize: initialize
  };
});
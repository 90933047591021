define('client/controllers/reports/new/create', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    application: Ember.inject.controller(),
    user: Ember.computed.alias("currentUser"),
    modal: Ember.inject.service(),
    store: Ember.inject.service(),
    defaultProfile: Ember.computed("user.defaultProfile", "user.corporates", function () {
      var profile = this.get("user.defaultProfile"),
          corporates = this.get("user.corporates");
      if (profile.get("id")) {
        return profile;
      }
      return corporates.findBy("onboarding_complete_admin", true) || corporates.get("firstObject");
    }),

    report: null,

    createReport: function createReport() {
      var store = this.get("store"),
          report = store.createRecord("report", {
        name: "",
        profileId: this.get("defaultProfile.id"),
        startDate: this.get("defaultProfile.startDate"),
        endDate: new Date(),
        createdById: this.get("user.id"),
        userId: this.get("user.id"),
        timezone: this.get('user.timezone'),
        initialFilterSet: this.get("defaultProfile.defaultFilterSet"),
        isPublic: false
      });
      this.set("report", report);
    },


    actions: {
      reportCreated: function reportCreated(savedReport) {
        this.transitionToRoute("reports.show", savedReport);
      },
      cancel: function cancel() {
        window.history.back();
      }
    }
  });
});
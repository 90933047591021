define('client/components/popover-trigger-button', ['exports', 'client/components/popover-trigger'], function (exports, _popoverTrigger) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var PopoverTriggerButtonComponent = _popoverTrigger.default.extend({

    classNames: ['popover-trigger', 'btn', 'btn-default'],

    clicked: function clicked() {},


    click: function click() {
      this.toggle();
      if (this.get('clickTarget')) {
        this.get('clickTarget').send(this.get('clicked'));
      } else {
        this.clicked();
      }
    }
  });

  exports.default = PopoverTriggerButtonComponent;
});
define('client/initializers/inject-notify-service', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.initialize = initialize;
  function initialize(application) {
    application.inject('route', 'notifyService', 'service:notify');
    application.inject('controller', 'notifyService', 'service:notify');
    application.inject('view', 'notifyService', 'service:notify');
    application.inject('component', 'notifyService', 'service:notify');
  }

  exports.default = {
    name: 'inject-notify-service',
    initialize: initialize
  };
});
define('client/instance-initializers/error-tracker', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.initialize = initialize;
  function initialize(application) {

    function handleError(error) {
      if (Ember.testing) {
        throw error;
      }
      if (window.Honeybadger) {
        Honeybadger.notify(error);
      }

      console.error(error);
    }

    Ember.onerror = function (error) {
      handleError(error);
    };

    Ember.RSVP.on('error', function (error) {
      if (Ember.isNone(error)) {
        return;
      }
      // Ember 2.X seems to not catch `TransitionAborted` errors caused by regular redirects. We don't want these errors to show up in NewRelic so we have to filter them ourselfs.
      // Once the issue https://github.com/emberjs/ember.js/issues/12505 is resolved we can remove this ignored error.
      var errorName = Ember.get(error, 'name');
      if (errorName === 'TransitionAborted') {
        return;
      }

      handleError(error);
    });
  }

  exports.default = {
    initialize: initialize,
    name: 'error-tracker'
  };
});
define('client/routes/reports/show/page', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    activate: function activate() {
      var report = this.modelFor("reports.show");
      // Fixing the cover page pdf on every open of the report for now
      Ember.run.schedule("afterRender", this, function () {
        var firstPage = report.get('pages.firstObject');
        var firstItem = firstPage.get('items.firstObject');
        if (Ember.isPresent(firstItem)) {
          firstItem.touch();
        }
      });
    },
    model: function model(params) {
      var report = this.modelFor("reports.show"),
          pages = report.get("pages"),
          page = pages.findBy("page", +params.page);
      return page;
    },
    redirect: function redirect(page, transition) {
      var report = page.get("report");
      var pageCount = report.get("pages.length");
      var requestedPage = +transition.params['reports.show.page'].page;
      if (requestedPage >= pageCount) {
        this.transitionTo('reports.show.page', report, pageCount - 1);
      }
    },
    setupController: function setupController(controller, page) {
      this._super(controller, page);
      this.get('reportService').stopEditing();
      this.set('reportService.currentPage', page);
      // use the rendered event to catch the page html
      page.on("render-html", controller, "saveRenderedHtml");

      // check if the page is already all loaded
      Ember.run.schedule("afterRender", function () {
        Ember.RSVP.all(page.get("items").mapBy("info").concat()).then(function () {
          page.trigger("render-html", page);
        });
      });
    }
  });
});
define('client/components/plan/pitch/add-multi-to-list', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ["pitch-add-to-list"],

    store: Ember.inject.service(),

    profile: Ember.computed.alias("profileService.profile"),
    pitchLists: Ember.computed.alias("profile.pitchLists"),
    pitchListsSortKey: null,
    sortedPitchLists: Ember.computed.sort("pitchLists", "pitchListsSortKey"),
    authors: null,

    newList: null,

    selectedPitchLists: null,

    didInsertElement: function didInsertElement() {
      if (this.get("pitchLists.length") < 1) {
        this.send("createList");
      }
    },
    saveAuthorsToPitchLists: function saveAuthorsToPitchLists() {
      var authors = this.get("authors"),
          selectedPitchLists = this.get("selectedPitchLists"),
          promises = [];
      promises.addObjects(selectedPitchLists.map(function (list) {
        list.get("authors").addObjects(authors);
        list.save();
      }));
      return Ember.RSVP.all(promises);
    },
    onDone: function onDone() {},
    init: function init() {
      this._super.apply(this, arguments);
      this.set('selectedPitchLists', this.get('selectedPitchLists') || []);
      this.set('pitchListsSortKey', this.get('pitchListsSortKey') || ["name:asc"]);
    },


    actions: {
      saveToList: function saveToList() {
        var pitchListLength = this.get("pitchLists.length");
        if (pitchListLength === 0) {
          this.send("createList");
        }
        this.set("showModal", true);
      },
      createList: function createList() {
        var newList = this.get("store").createRecord("pitch-list", {
          profile: this.get("profileService.profile")
        });
        this.set("newList", newList);
      },
      onCancelCreate: function onCancelCreate() {
        this.get("newList").destroyRecord();
        this.set("newList", null);
        this.send("close");
      },
      listCreated: function listCreated(list) {
        var _this = this;

        var authors = list.get("authors");
        authors.addObjects(this.get("authors"));
        list.save().then(function () {
          _this.set("newList", null);
          _this.send("close");
        });
      },
      addPitchList: function addPitchList(list) {
        var selectedPitchLists = this.get("selectedPitchLists");
        if (selectedPitchLists.indexOf(list) < 0) {
          selectedPitchLists.addObject(list);
        } else {
          selectedPitchLists.removeObject(list);
        }
      },
      save: function save() {
        var _this2 = this;

        this.saveAuthorsToPitchLists().then(function () {
          _this2.send("close");
        });
      },
      close: function close() {
        this.get("onDone")();
      }
    }

  });
});
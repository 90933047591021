define('client/components/research/search-suggestion', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['search-suggestion'],

    searchTerm: null,

    isPhraseSearch: Ember.computed("searchTerm", function () {
      if (this.searchTerm) {
        return !!this.searchTerm.match(/^".*"$/);
      }
      return false;
    }),
    isKeywordSearch: Ember.computed("isPhraseSearch", function () {
      if (this.searchTerm) {
        return !this.isPhraseSearch;
      }
      return false;
    }),

    changeTerm: function changeTerm() {},


    actions: {
      alterSearch: function alterSearch(type) {
        // remove all "
        var term = this.searchTerm.replace(/"/g, '');
        if (type === "keyword") {
          this.changeTerm(term, true);
        } else if (type === "phrase") {
          this.changeTerm('"' + term + '"', true);
        }
      }
    }

  });
});
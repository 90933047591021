define('client/components/dashboard/widgets/owned-media-readership-card', ['exports', 'client/components/dashboard/widgets/widget-card', 'client/utils/date-format'], function (exports, _widgetCard, _dateFormat2) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _widgetCard.default.extend({
    sort: "readership",

    infoText: Ember.computed("profileService.{profile}", function () {
      var readershipName = this.get("profileService.profile.nameReadership") || "";
      var readershipTitleCase = readershipName.charAt(0).toUpperCase() + readershipName.substr(1).toLowerCase();
      return Ember.String.htmlSafe("<p>Owned Media " + readershipTitleCase + " represents the the number of unique visitors consuming content published on your company's owned media channel(s).</p>");
    })

  });
});
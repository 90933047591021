define('client/components/layout-grid', ['exports', 'client/mixins/droppable'], function (exports, _droppable) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var deepArrayCopy = function deepArrayCopy(array) {
    array = array.slice(0);
    for (var i = 0; i < array.length; i++) {
      array[i] = Ember.merge({}, array[i]);
    }
    return array;
  };

  var findAt = function findAt(grid, to, not) {
    return grid.find(function (i) {
      if (not && not.elementId === i.elementId) {
        return false;
      }

      return i.row <= to.row && to.row < i.row + i.rowspan && i.col <= to.col && to.col < i.col + i.colspan || to.row <= i.row && i.row < to.row + to.rowspan && to.col <= i.col && i.col < to.col + to.colspan || i.col <= to.col && to.col < i.col + i.colspan && to.row <= i.row && i.row < to.row + to.rowspan || i.row <= to.row && to.row < i.row + i.rowspan && to.col <= i.col && i.col < to.col + to.colspan;
    });
  };

  exports.default = Ember.Component.extend(_droppable.default, {
    classNameBindings: [":layout-grid", "isError:layout-grid-error"],

    gutter: 8,

    rows: 4,
    cols: 4,

    onNewItem: function onNewItem() {},
    onMoveItem: function onMoveItem() {},
    allChildViews: function allChildViews() {
      var views = [];
      var _this = this;
      this.$().children().each(function () {
        //TODO: dont use view-registry
        views.push(Ember.getOwner(_this).lookup('-view-registry:main')[Ember.$(this).attr("id")]);
      });
      return views;
    },
    serializeGrid: function serializeGrid() {
      return this.allChildViews().map(function (v) {
        if (v.$().hasClass("hidden-item")) {
          return null;
        }
        return v.getProperties(["elementId", "col", "row", "colspan", "rowspan"]);
      }).compact();
    },
    applyGrid: function applyGrid(grid) {
      var views = this.allChildViews();
      grid.forEach(function (i) {
        var view = views.findBy('elementId', i.elementId);
        view.setProperties({
          col: i.col,
          row: i.row,
          colspan: i.colspan,
          rowspan: i.rowspan
        });
      });
      this._grid = null;
    },
    canDrop: function canDrop(row, col, rowspan, colspan) {
      var to = { row: row, col: col, rowspan: rowspan, colspan: colspan },
          grid = this.serializeGrid();

      // check if possible
      return !findAt(grid, to);
    },
    clearFor: function clearFor(view, newRowspan, newColspan) {
      var from = view.getProperties(["row", "col", "rowspan", "colspan", "elementId"]),
          to = Ember.merge({}, from),
          maxRows = this.get("rows"),
          maxCols = this.get("cols");
      var grid = this.serializeGrid();

      // new size
      to.rowspan = newRowspan;
      to.colspan = newColspan;

      // move it to accomodate the new spans and try again
      if (to.rowspan + to.row > maxRows) {
        to.row = maxRows - to.rowspan;
      }

      if (to.colspan + to.col > maxCols) {
        to.col = maxCols - to.colspan;
      }

      // check if possible
      while (true) {
        grid = this.checkCollision(deepArrayCopy(grid), from, to);
        if (grid) {
          if (!findAt(grid, to, from)) {
            // area is cleared, return the new grid
            return grid;
          }
          // else continue moving stuff around.
        } else {
          // can't move anymore
          return false;
        }
      }
    },
    checkCollision: function checkCollision(grid, from, to) {
      if (from.row === to.row && from.col === to.col && from.rowspan === to.rowspan && from.colspan === to.colspan) {
        return grid;
      }
      // check if to is empty
      var found = findAt(grid, to, from);
      var maxRows = this.get("rows");

      if (found && found.elementId !== from.elementId) {
        return false;
      }

      var maxCols = this.get("cols");
      if (to.col < 0 || to.row < 0 || to.col + from.colspan > maxCols || to.row + from.rowspan > maxRows) {
        return false;
      }

      // working, change it to the final position

      found = grid.findBy("elementId", from.elementId); //findAt(grid, from);
      found.row = to.row;
      found.col = to.col;
      found.rowspan = to.rowspan;
      found.colspan = to.colspan;
      return grid;
    },
    didDragOver: function didDragOver(e) {
      var droppable = this.get("isDroppable"),
          childViews = this.allChildViews();
      // leave if not droppable

      if (!droppable) {
        return false;
      }

      Ember.run.cancel(this._dragLeaveTimer);

      e.stopPropagation();
      e.preventDefault();

      var maxRows = this.get("rows"),
          maxCols = this.get("cols"),
          height = this.$().height() / maxRows,
          width = this.$().width() / maxCols,
          gutter = 0,
          offset = this.$().offset();

      var view = window.Emberella._draggableView;

      if (!view) {
        return false;
      }
      var $view = view.$();

      if (!this.previousDrag) {
        this.previousDrag = view.startPoint;
      }

      // calc scale of the report to convert offset
      var report = this.$().closest(".report-content");
      var scale = report.attr("style").match(/transform:scale3d\((\d+\.\d+),/);
      var left = report.attr("style").match(/left: (\d+)/);

      if (scale) {
        scale = +scale[1];
      } else {
        scale = 1;
      }
      if (left) {
        left = left[1];
      } else {
        left = 0;
      }

      var x = e.originalEvent.pageX - offset.left,
          y = e.originalEvent.pageY - offset.top;

      // check direction
      if (e.originalEvent.pageX < this.previousDrag.x) {
        // going left, get left view offset as x
        x = x - (view.startPoint.x - view.startPoint.left * scale);
      } else if (e.originalEvent.pageX > this.previousDrag.x) {
        x = x + $view.width() - (view.startPoint.x - view.startPoint.left * scale);
      }

      if (e.originalEvent.pageY - this.previousDrag.y < -5) {
        // going up, get top view offset as y
        y = y - (view.startPoint.y - view.startPoint.top * scale);
      } else if (e.originalEvent.pageY - this.previousDrag.y > 5) {
        y = y + $view.height() - (view.startPoint.y - view.startPoint.top * scale);
      }

      this.previousDrag = {
        x: e.originalEvent.pageX,
        y: e.originalEvent.pageY
      };

      // check the position and translate that into coords

      var newCol = Math.floor(x / ((gutter + width) * scale)),
          newRow = Math.floor(y / ((gutter + height) * scale));

      // sanitize calculated rows and cols
      if (newCol < 0) {
        newCol = 0;
      }

      if (newRow < 0) {
        newRow = 0;
      }

      if (newCol > maxCols) {
        newCol = maxCols;
      }

      if (newRow > maxRows) {
        newRow = maxRows;
      }

      // check if we are dragging from outside, if so use the hidden
      // item and drag it.

      if (childViews.indexOf(view) < 0) {
        var hiddenItem = childViews.get("lastObject"),
            colspan = view.get("minColspan"),
            rowspan = view.get("minRowspan");
        hiddenItem.setProperties({
          rowspan: rowspan,
          colspan: colspan,
          content: view.get('content'),
          configName: view.get('configName'),
          row: rowspan + newRow > maxRows ? maxRows - rowspan : newRow,
          col: colspan + newCol > maxCols ? maxCols - colspan : newCol,
          componentName: "reports/drag-item" //view.get("content.componentName")
        });
        hiddenItem.$().removeClass("hidden-item");
        view = hiddenItem;

        this._grid = this.serializeGrid();
        var newItem = view.getProperties(["row", "col", "rowspan", "colspan", "elementId"]),
            found = findAt(this._grid, newItem, newItem);

        // there is something there already
        if (found) {
          this._grid = null;
          this.set("isError", true);
          return false;
        }
      }

      if (!this._grid) {
        this._grid = this.serializeGrid();
      }

      var from = view.getProperties(["row", "col", "rowspan", "colspan", "elementId"]);

      // check if newCol, newRow are within the dragged item
      if (newCol > from.col) {
        newCol = newCol - (from.colspan - 1);
        if (newCol < 0) {
          newCol = 0;
        }
      }

      if (newRow > from.row) {
        newRow = newRow - (from.rowspan - 1);
        if (newRow < 0) {
          newRow = 0;
        }
      }

      var to = Ember.merge({}, from);
      to.col = newCol;
      to.row = newRow;
      this._grid = this.checkCollision(deepArrayCopy(this._grid), from, to);

      if (this._grid) {
        this.set("isError", false);
        this.applyGrid(this._grid);
      } else {
        this.set("isError", true);
      }
      return false;
    },
    didDragLeave: function didDragLeave() {
      var droppable = this.get("isDroppable");
      if (!droppable) {
        return false;
      }
      this._dragLeaveTimer = Ember.run.debounce(this, "didDragLeaveDebounced", 200);
    },
    didDragLeaveDebounced: function didDragLeaveDebounced() {
      var droppable = this.get("isDroppable"),
          childViews = this.allChildViews(),
          hiddenItem = childViews.get("lastObject");
      // leave if not droppable
      if (!droppable) {
        return;
      }
      hiddenItem.$().addClass("hidden-item");
      this.set("isError", false);
    },
    didDrop: function didDrop(e) {
      var droppable = this.get("isDroppable"),
          childViews = this.allChildViews(),
          hiddenItem = childViews.get("lastObject"),
          view = window.Emberella._draggableView;
      // leave if not droppable
      if (!droppable || !view) {
        return;
      }

      this.previousDrag = null;

      if (childViews.indexOf(view) < 0) {
        if (!this.get("isError")) {
          this.onNewItem(hiddenItem.getProperties(["content", "configName", "colspan", "rowspan", "row", "col"]));
        }
      } else {
        this.onMoveItem(view.getProperties(["content", "configName", "colspan", "rowspan", "row", "col"]));
      }

      //firefox navigates away from page
      //if we don't prevent it
      e.stopPropagation();
      e.preventDefault();

      hiddenItem.$().addClass("hidden-item");
      this.set("isError", false);
    },


    actions: {
      expandItem: function expandItem(view, newRowspan, newColspan) {
        var grid = this.clearFor(view, newRowspan, newColspan);
        // apply or reject
        if (grid) {
          this.set("isError", false);
          this.applyGrid(grid);
          view.setProperties({
            colspan: newColspan,
            rowspan: newRowspan
          });
          this.onMoveItem(view.getProperties(["content", "configName", "colspan", "rowspan", "row", "col"]));
        } else {
          this.set("isError", true);
          Ember.run.later(this, function () {
            this.set("isError", false);
          }, 1000);
        }
      }
    }

  });
});
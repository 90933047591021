define('client/routes/promote/index', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    beforeModel: function beforeModel() {
      this.controllerFor('promote').set('currentPage', 'articles');
    }
  });
});
define("client/serializers/translation-task", ["exports", "active-model-adapter"], function (exports, _activeModelAdapter) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var TranslationTaskSerializer = _activeModelAdapter.ActiveModelSerializer.extend({
    attrs: {
      article: "profile_entity_relationship_id"
    },
    //todo: remove in ember data 2
    isNewSerializerAPI: true
  });

  exports.default = TranslationTaskSerializer;
});
define('client/mixins/article-sort-dropdown', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({

    sorts: Ember.computed('generalGroup', 'amplificationGroup', 'interactionGroup', 'profile.analytics_profile.analytics_account', 'profile.interaction_metrics', 'sort', function () {
      var groups = [this.get('generalGroup'), this.get('amplificationGroup')];
      if (this.get('profile.analytics_profile.analytics_account') && this.get('profile.interaction_metrics') && Object.keys(this.get('profile.interaction_metrics')).length > 0) {
        groups.push(this.get('interactionGroup'));
      }

      if (!this.containsSortItem(groups)) {
        //if item is not part of selection add an 'Unknown' option and display error message of no articles are returned
        this.get('sortError')();
        groups[0].get('options').push(this.getOptionForCustomTerm('Unknown', this.get('sort')));
      }

      return Ember.A(groups);
    }),

    generalGroup: Ember.computed('profile.metrics', 'profile.analytics_profile.analytics_account', 'isPublicationSearch', 'isUsingCurrentProfile', function () {

      var options = [this.getOptionForTerm("Date", true), this.getOptionForCustomTerm("Power of Voice", 'pov')];

      if (this.get('profile.hasPOV2Sentiment')) {
        options.push(this.getOptionForCustomTerm('Power of Voice (Sentiment)', 'pov_rel_amp_sent'));
      }

      if (this.get("isPublicationSearch")) {
        options.unshift(this.getOptionForCustomTerm("Total coverage", "total_coverage"));
      }

      if (this.get('profile.analytics_profile.analytics_account') && this.get('profile.showCustomer')) {
        options.push(this.getOptionForCustomTerm(this.get("profile.nameCustomer"), "visitors", !this.get('isUsingCurrentProfile')));
      }

      if (this.get('profile.analytics_profile') && this.get('profile.showReadership')) {
        options.push(this.getOptionForCustomTerm(this.get("profile.nameReadership"), "readership"));
      }
      if (this.get('profile.analytics_profile.analytics_account') && this.get('profile.showRevenue')) {
        options.push(this.getOptionForCustomTerm(this.get("profile.nameRevenue"), "revenue"));
      }

      if (this.get('profile.analytics_profile.analytics_account') && this.get('profile.showFormulaicRevenue')) {
        options.push(this.getOptionForCustomTerm(this.get("profile.nameFormulaicRevenue"), "formulaic_revenue"));
      }

      if (this.get('profile.showReach')) {
        options.push(this.getOptionForCustomTerm(this.get("profile.nameReach"), "reach"));
      }

      if (this.get('profile.hasImpactScore')) {
        options.push(this.getOptionForCustomTerm(this.get("profile.nameImpactScore"), "impact_score"));
      }

      return Ember.Object.create({
        options: options,
        group: 'GENERAL'
      });
    }),

    amplificationGroup: Ember.computed(function () {
      return Ember.Object.create({
        options: [this.getOptionForCustomTerm("Total Amplification", "all_amplification"), this.getOptionForTerm("Facebook"), this.getOptionForTerm("Twitter"), this.getOptionForTerm("Pinterest")],
        group: 'AMPLIFICATION'
      });
    }),

    interactionGroup: Ember.computed('profile.interaction_metrics', 'profile.nameInteraction', 'profile.nameTotalInteraction', 'isUsingCurrentProfile', function () {
      var _this = this;

      var interactionMetrics = this.get('profile.interaction_metrics');
      var keys = Object.keys(interactionMetrics);
      var options = keys.map(function (key) {
        return _this.getOptionForMetricTerm("profile.interaction_metrics", key, !_this.get('isUsingCurrentProfile'));
      });
      // TEMP hide sort by total interactions for Adobe
      if (!this.get('profile.hasAllInteractionsDisabled')) {
        options.unshift(this.getOptionForCustomTerm(this.get('profile.nameTotalInteraction'), "all_interaction", !this.get('isUsingCurrentProfile')));
      }
      return Ember.Object.create({
        options: options,
        group: this.get('profile.nameInteraction').toUpperCase()
      });
    }),

    containsSortItem: function containsSortItem(groups) {
      var _this2 = this;

      return !!groups.find(function (group) {
        return group.get('options').findBy('value', _this2.get('sort'));
      });
    },


    getOptionForCustomTerm: function getOptionForCustomTerm(term, value, disabled) {
      var lowerCaseTerm = term === undefined ? term : term.toLowerCase();
      var option = {
        label: term,
        lowerLabel: lowerCaseTerm,
        value: value,
        disabled: !!disabled
      };
      return option;
    },

    getOptionForTerm: function getOptionForTerm(term, lowerLabel, disabled) {
      var option = {
        label: term,
        value: term.toLowerCase(),
        disabled: !!disabled
      };
      if (lowerLabel) {
        option.lowerLabel = term.toLowerCase();
      }
      return option;
    },

    getOptionForMetricTerm: function getOptionForMetricTerm(metric, term, disabled, lowerLabel) {
      var label = this.get(metric)[term];
      var option = {
        label: label,
        value: term,
        disabled: !!disabled
      };
      if (lowerLabel) {
        option.lowerLabel = label.toLowerCase();
      }
      return option;
    },
    sortError: function sortError() {}
  });
});
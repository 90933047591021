define('client/components/article-social', ['exports', 'client/twitter'], function (exports, _twitter) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  //
  // Override the Linkedin implementation to guard against null "b" node
  // This is brittle as this is a private api
  //
  if (window.IN && window.IN.Objects && window.IN.Objects.Callout) {
    window.IN.Objects.Callout.prototype.setContent = function (d) {
      var b = IN.$Id(this.mainNodeId + "-content");
      /*c=IN.$Id(this.mainNodeId+"-container")*/

      if (b) {
        b.innerHTML = d;
      }
    };
  }

  exports.default = Ember.Component.extend({

    classNames: ["article-social-counts"],
    content: null,

    resetSocial: false,

    didInsertElement: function didInsertElement() {
      this.onExpanded();
    },

    onExpanded: Ember.observer("content", function () {
      Ember.run.schedule("afterRender", this, function () {
        // load all social buttons
        // facebook
        if (window.FB) {
          try {
            window.FB.XFBML.parse();
          } catch (e) {
            console.error('Error parsing Facebook XFBML:', e.message, e);
          }
        }

        // twitter
        if (window.twttr) {
          window.twttr.widgets.load();
        }

        // gplus
        if (window.gapi) {
          window.gapi.plusone.go();
        }

        // linkedin
        if (window.IN) {
          window.IN.parse();
        }

        // Pinterest
        if (window.PinUtils) {
          window.PinUtils.build();
        }
      });
    }),

    onTwitterData: Ember.observer("content.twitterData", function () {
      Ember.run.schedule("afterRender", this, function () {
        //Need to guard as on initial page render, the twttr library
        //might not be loaded yet
        if (window.twttr) {
          window.twttr.widgets.load();
        }
      });
    })
  });
});
define('client/helpers/in-array', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Helper.extend({
    compute: function compute(params) {
      if (!Ember.isArray(params[0])) {
        return false;
      }
      if (this._prev !== params[0]) {
        if (this._prev) {
          Ember.removeObserver(this._prev, "length", this, "recompute");
        }
        this._prev = params[0];
        Ember.addObserver(this._prev, "length", this, "recompute");
      }

      return params[0].indexOf(params[1]) > -1;
    }
  });
});
define('client/components/plan/campaigns/media-channels', ['exports', 'client/utils/campaign-helper'], function (exports, _campaignHelper) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({

    classNames: ['media-channels', 'campaign-creation-navigation'],

    prompt: 'Which channels do you want to track for this campaign?',

    isUpdate: false,

    isContinueDisabled: Ember.computed('channels.@each.isSelected', function () {
      var channels = this.get('channels');
      return Ember.isEmpty(channels.filterBy('isSelected', true));
    }),

    channels: Ember.computed('selectedChannels', function () {
      var selectedChannels = this.get('selectedChannels') || Ember.A();
      var checkSelected = Ember.isPresent(selectedChannels);

      var channels = (0, _campaignHelper.getChannelNames)().map(function (channel) {
        var isSelected = true;
        if (checkSelected) {
          isSelected = selectedChannels.includes(channel);
        }
        return Ember.Object.create({ name: channel, isSelected: isSelected });
      }, []);

      return channels;
    }),

    onSelectedMediaChannels: function onSelectedMediaChannels() {},
    onNext: function onNext() {},
    onPrevious: function onPrevious() {},
    setChannels: function setChannels() {
      var selectedChannels = this.get('channels').filterBy('isSelected').map(function (channel) {
        return channel.name;
      });
      this.onSelectedMediaChannels(selectedChannels);
    },


    actions: {
      onNext: function onNext() {
        this.setChannels();
        this.onNext();
      },
      onPrevious: function onPrevious() {
        this.setChannels();
        this.onPrevious();
      }
    }
  });
});
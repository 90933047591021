define('client/initializers/csrf', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.initialize = initialize;
  function initialize() /* container, application */{
    var token = Ember.$('meta[name="csrf-token"]').attr('content');
    if (token) {
      var preFilter = function preFilter(options, originalOptions, jqXHR) {
        return jqXHR.setRequestHeader('X-CSRF-Token', token);
      };
      Ember.$.ajaxPrefilter(preFilter);
    }
  }

  exports.default = {
    name: 'csrf',
    initialize: initialize
  };
});
define('client/components/onboard-person', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var OnboardPersonComponent = Ember.Component.extend({
    classNameBindings: [":onboard-person", "opened:onboard-person_opened"],
    tagName: "li",
    content: null,

    opened: true,
    showSocialAccount: false,
    doNotConnect: false,

    showFacebook: Ember.computed.equal('showSocialAccount', 'facebook'),
    showTwitter: Ember.computed.equal('showSocialAccount', 'twitter'),
    showGPlus: Ember.computed.equal('showSocialAccount', 'gplus'),
    showLinkedin: Ember.computed.equal('showSocialAccount', 'linkedin'),

    validateSocialPage: function validateSocialPage(type) {
      var page = this.get('content.' + type + '_page');
      // Verify URL matches
      if (!Ember.isEmpty(page)) {
        return this.socialURLValidator(type).test(page);
      }
      return false;
    },

    twitterConfigured: Ember.computed('content.twitter_page', function () {
      return this.validateSocialPage('twitter');
    }),

    facebookConfigured: Ember.computed('content.facebook_page', function () {
      return this.validateSocialPage('facebook');
    }),

    gplusConfigured: Ember.computed('content.gplus_page', function () {
      return this.validateSocialPage('gplus');
    }),

    linkedinConfigured: Ember.computed('content.linkedin_page', function () {
      return this.validateSocialPage('linkedin');
    }),

    availablePages: Ember.computed("content.facebook_pages", "content.twitter_pages", "content.gplus_pages", "content.linkedin_pages", "showSocialAccount", function () {
      return this.get("content." + this.get("showSocialAccount") + "_pages");
    }),

    configuredSocial: Ember.computed("content.facebook_page", "content.twitter_page", "content.gplus_page", "content.linkedin_page", "showSocialAccount", function () {
      if (this.get("doNotConnect")) {
        return true;
      }
      var path = "content." + this.get("showSocialAccount"),
          page = this.get(path + "_page"),
          pages = this.get(path + "_pages.length");

      return page != null || pages > 0;
    }),

    caretStyle: Ember.computed("showSocialAccount", function () {
      var index = ["facebook", "twitter", "gplus", "linkedin"].indexOf(this.get("showSocialAccount")),
          left = 16 + index * 53;

      return new Ember.String.htmlSafe('left:' + left + 'px;');
    }),

    socialPage: Ember.computed("content.facebook_page", "content.twitter_page", "content.gplus_page", "content.linkedin_page", "showSocialAccount", {
      get: function get() {
        var path = "content." + this.get("showSocialAccount") + "_page";
        return this.get(path);
      },
      set: function set(k, v) {
        var path = "content." + this.get("showSocialAccount") + "_page";
        if (v) {
          this.set(path, v);
        }
        return this.get(path);
      }
    }),

    socialURLPrefix: function socialURLPrefix(type) {
      switch (type) {
        case "facebook":
          return "http://www.facebook.com/";
        case "twitter":
          return "https://twitter.com/";
        case "gplus":
          return "https://plus.google.com/";
        case "linkedin":
          return "http://www.linkedin.com/in/";
      }
    },

    socialURLValidator: function socialURLValidator(type) {
      switch (type) {
        case "facebook":
        case "twitter":
        case "linkedin":
          //Needs double escape because defined using string
          return new RegExp("^http(s)?:\\/\\/(www\\.)?" + type + "\\.com\\/.+");
        case "gplus":
          //Single escape because defined using /regex/ notation
          return new RegExp(/^http(s)?:\/\/plus\.google\.com\/.+/);
      }
    },

    socialPlaceholder: Ember.computed("showSocialAccount", function () {
      return this.socialURLPrefix(this.get('showSocialAccount'));
    }),

    click: function click(e) {
      e.preventDefault();
      if (!this.get("opened") && !this.get("disabled")) {
        this.send("toggleOpened");
      }
    },

    openSocial: function openSocial(type) {
      var social = type;
      if (this.get("showSocialAccount") === type) {
        social = false;
        Ember.$('body').off('click.onboard-person');
      } else {
        // Wipe out previous page if it was not configured so that we can display the initial choice
        if (!this.get(type + 'Configured')) {
          this.get('content').deauthorize(type);
        }

        // We should be able to click something else and then close the popover
        var that = this;
        Ember.$('body').on('click.onboard-person', function (e) {
          var $popover = that.$('.onboard-person-account.onboard-person-account_opened');
          if (!$popover) {
            // If we have left the page remove the listener and exit
            Ember.$('body').off('click.onboard-person');
            return;
          }
          if (!$popover.is(e.target) && $popover.has(e.target).length === 0) {
            that.set('showSocialAccount', false);
            Ember.$('body').off('click.onboard-person');
          }
        });
      }
      this.set("showSocialAccount", social);
    },

    actions: {
      toggleOpened: function toggleOpened() {
        this.toggleProperty('opened');
      },
      openFacebook: function openFacebook() {
        this.openSocial("facebook");
      },
      openTwitter: function openTwitter() {
        this.openSocial("twitter");
      },
      openLinkedin: function openLinkedin() {
        this.openSocial("linkedin");
      },
      openGPlus: function openGPlus() {
        this.openSocial("gplus");
      },
      manualAccess: function manualAccess() {
        this.set("content." + this.get("showSocialAccount") + "_page", "");
      },
      connectAccess: function connectAccess() {
        this.get("connect")(this.get("showSocialAccount"), this.get("content"));
      },
      remove: function remove(content) {
        this.get('remove')(content);
      },
      deauthorize: function deauthorize(profile, type) {
        this.get('deauthorize')(profile, type);
      }
    }
  });

  exports.default = OnboardPersonComponent;
});
define('client/controllers/settings', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    currentUser: Ember.inject.service('current-user'),
    disableCalendar: true,
    profile: Ember.computed.alias("profileService.profile"),
    showAttributionSettings: Ember.computed("currentUser", function () {
      var role = this.currentUser.get("role");
      return Ember.isBlank(role) || role === "admin" || this.currentUser.get("isAirprAdmin");
    }),
    showProfileSettings: Ember.computed("currentUser", function () {
      return this.currentUser.get("role") !== "view_only" || this.currentUser.get("isAirprAdmin");
    }),
    impactScore: Ember.computed("currentUser", function () {
      return this.get("profileService.profile.hasImpactScore");
    })
  });
});
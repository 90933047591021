define('client/components/research/author-manager', ['exports', 'ember-data', 'client/mixins/models/query-consumer'], function (exports, _emberData, _queryConsumer) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_queryConsumer.default, {
    store: Ember.inject.service(),
    profile: Ember.computed.alias('profileService.profile'),
    authorId: null,
    filters: Ember.computed('term', function () {
      var term = this.get('term');
      return { term: term };
    }),

    term: null,
    listIsLoaded: true,

    isLoadingAuthor: Ember.computed.alias("author.isPending"),

    author: Ember.computed("authorId", {
      get: function get() {
        return this.get('store').findRecord('author', this.get('authorId'));
      },
      set: function set(k, v) {
        this.set("authorId", v.get("id"));
        return v;
      }
    }),

    authorStats: Ember.computed('authorId', 'filters', 'term', 'listIsLoaded', function () {
      if (this.get('listIsLoaded')) {
        var authorId = this.get("authorId") || this.get("author.id");
        if (!authorId) {
          return _emberData.default.PromiseObject.create({ promise: new Ember.RSVP.Promise(function () {}) });
        }
        var query = this.queryByName("research/author-stats");
        return query.load();
      }
    }),

    actions: {
      onSearchTerm: function onSearchTerm(searchTerm) {
        this.set('term', searchTerm);
      }
    }
  });
});
define('client/controllers/plan/neo', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    modal: Ember.inject.service(),
    buckets: Ember.computed.filterBy('profileService.profile.profileMessageBuckets', 'isNew', false),

    iconsBucketId: null,
    editBucketId: null,
    selectedBucketId: null,
    actions: {
      closeNewForm: function closeNewForm() {
        var selectedBucketId = this.get('selectedBucketId');
        if (selectedBucketId) {
          this.transitionToRoute("plan.neo.buckets.show", selectedBucketId);
        } else {
          this.transitionToRoute("plan.neo");
        }
      },
      showIcons: function showIcons(bucketId) {
        this.set('iconsBucketId', bucketId);
      },
      hideIcons: function hideIcons() {
        this.set('iconsBucketId', null);
      },
      enableEdit: function enableEdit(bucketId) {
        this.set('editBucketId', bucketId);
        Ember.run.scheduleOnce('afterRender', this, function () {
          Ember.$('.bucket-name-edit').focus();
        });
      },
      disableEdit: function disableEdit(bucket) {
        bucket.rollbackAttributes();
        this.set('editBucketId', null);
      },
      saveBucketName: function saveBucketName(bucket) {
        var _this = this;

        bucket.save().then(function () {
          _this.set('editBucketId', null);
        });
      },
      onNewSoundbyte: function onNewSoundbyte() {
        this.transitionToRoute('plan.neo.soundbytes.new', {
          queryParams: {
            bucketId: this.get('selectedBucketId')
          }
        });
      },
      deleteBucket: function deleteBucket(bucket) {
        var _this2 = this;

        var bucketId = bucket.id;
        this.get('modal').open('common/ui-confirm', {
          question: 'Are you sure you want to delete: ' + bucket.get('name'),
          ok: "Delete",
          okType: "danger"
        }).then(function () {
          bucket.destroyRecord().then(function () {
            if (_this2.get('selectedBucketId') === bucketId) {
              _this2.transitionToRoute('plan.neo.index');
            }
          });
        });
      }
    }
  });
});
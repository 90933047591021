define('client/utils/debounced', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = debouncedProperty;

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) arr2[i] = arr[i];

      return arr2;
    } else {
      return Array.from(arr);
    }
  }

  var _Ember$run = Ember.run,
      debounce = _Ember$run.debounce,
      join = _Ember$run.join,
      cancel = _Ember$run.cancel;
  function debouncedProperty() {
    var _Ember;

    var args = [].slice.apply(arguments);
    var rate = args.pop();
    var method = args.pop();

    var methodFn = function methodFn(key, value, oldValue) {
      if (!this.get('isDestroyed') && !this.get('isDestroying')) {
        // The reason we use this.set here (and have the custom setter below) is
        // to trigger other computed properties without recomputing the property
        // (notifiyPropertyChange, for example, could end in an infinite loop)
        var newValue = method.call(this, key, value, oldValue);
        this['__' + key] = newValue;
        this.set(key, newValue);
      }
    };

    var get = function get(key, value, oldValue) {
      var destroyKey = '__' + key + 'destroy';
      var nextKey = '__' + key + 'next';
      if (!this[destroyKey]) {
        var _super = this.willDestroy;
        this.willDestroy = function () {
          cancel(this[nextKey]);
          _super();
        };
        this[destroyKey] = true;
      }
      this[nextKey] = debounce(this, methodFn, key, value, oldValue, rate, false);
      return this['__' + key];
    };

    var set = function set(key, value) {
      return value;
    };
    args.push({ get: get, set: set });

    return (_Ember = Ember).computed.apply(_Ember, _toConsumableArray(args));
  }
});
define('client/models/requested-url', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var RequestedUrl = _emberData.default.Model.extend({
    url: _emberData.default.attr('string'),
    searchTerm: _emberData.default.attr('string'),
    profile_id: _emberData.default.attr(),
    blacklisted: _emberData.default.attr('boolean'),
    spam: _emberData.default.attr('boolean'),
    irrelevant: _emberData.default.attr('boolean'),
    notFoundAnywhere: _emberData.default.attr('boolean'),
    outOfGeo: _emberData.default.attr('boolean'),
    outOfDateRange: _emberData.default.attr('boolean')
  });

  RequestedUrl.pathForType = "requested_urls";
  exports.default = RequestedUrl;
});
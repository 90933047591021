define('client/controllers/settings/impact-score-settings/index', ['exports', 'client/mixins/data-table-sort'], function (exports, _dataTableSort) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend(_dataTableSort.default, {

    abilityService: Ember.inject.service('ability'),
    currentUser: Ember.inject.service("current-user"),

    profile: Ember.computed.alias('profileService.profile'),

    canManageImpactScoreSetup: Ember.computed('abilityService', function () {
      return this.abilityService.canManage("settings_impact_score");
    }),

    isNotAirprAdmin: Ember.computed.not("currentUser.isAirprAdmin"),

    buttonDisabled: Ember.computed(function () {
      if (this.currentUser.get('isAirprAdmin') || this.currentUser.get('isSuperAdmin') || this.currentUser.get('isSalesUser') || this.currentUser.get('isEngineer')) {
        return "";
      } else {
        return "disabled";
      }
    }),

    store: Ember.inject.service(),
    profileImpactScoreSetting: Ember.computed('profile', function () {
      return this.store.query("profile-impact-score-setting", { profile_id: this.get("profile.id") });
    }),

    impactScore: Ember.computed("profile", function () {
      return this.get("profileService.profile.hasImpactScore");
    }),

    productNames: Ember.computed('profile', function () {
      var array = [];
      this.store.query("impact-score-product-name", { profile_id: this.get("profile.id") }).then(function (item) {
        item.forEach(function (item) {
          array.pushObject(item.name);
        });
      });
      return array;
    }),

    spokesPeople: Ember.computed('profile', function () {
      var array = [];
      this.store.query("impact-score-spokesperson", { profile_id: this.get("profile.id") }).then(function (item) {
        item.forEach(function (item) {
          array.pushObject(item.name);
        });
      });
      return array;
    }),

    targetMediaLists: Ember.computed('profile.profile-impact-score-setting', function () {
      var array = [];
      this.store.query("impact-score-target-media-list", { profile_id: this.get("profile.id") }).then(function (item) {
        item.forEach(function (item) {
          array.pushObject(item);
        });
      });
      return array;
    }),

    prioritizedUrls: Ember.computed(function () {
      var owner = Ember.getOwner(this);
      var findPagingController = owner.lookup('controller:find-paging');

      findPagingController.setProperties({
        model: [],
        type: "impact-score-prioritized-url",
        store: this.get("store")
      });
      Ember.run.later(this, "_paramsDidChange");
      return findPagingController;
    }),

    _sorts: ["Date", "Url", "Profile"],

    resources: Ember.computed.alias("prioritizedUrls"),

    actions: {
      linkAdded: function linkAdded() {
        this.notifyPropertyChange("prioritizedUrls"); // force reload of list
      }
    }

  });
});
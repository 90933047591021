define('client/components/dashboard/widgets/visitors-card', ['exports', 'client/components/dashboard/widgets/widget-card'], function (exports, _widgetCard) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var VISITOR_TEXT = '<p>The total number of people who came to your\n  website as result of PR coverage.</p> <p> Analyst tracks direct and\n  indirect visitors to your site, both of which are included in this figure.\n  </p>';

  exports.default = _widgetCard.default.extend({
    sort: "visitors",

    infoText: Ember.computed(function () {
      return Ember.String.htmlSafe(VISITOR_TEXT);
    })
  });
});
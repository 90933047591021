define('client/components/influentials-table', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var InfluentialsTableComponent = Ember.Component.extend({

    tagName: 'table',

    classNames: ['table', 'table-striped'],

    isSortReversed: false,

    _previousSortBy: null,

    sortBy: Ember.computed('headers', {
      get: function get() {
        var headers = this.get('headers');
        // Set the current sortBy to whatever we returned previously
        var sortBy = this.get('_previousSortBy');
        if (Ember.isEmpty(headers)) {
          // Don't clear previous, yet as headers is not ready
          return null;
        } else if (!headers.findBy('key', sortBy)) {
          // previous header sortBy is not in this header list,
          // so default to first header
          sortBy = headers.get('firstObject.key');
        }
        // Store the value we are sorting by so we know what it is when headers
        // change
        this.set('_previousSortBy', sortBy); // eslint-disable-line 
        return sortBy;
      },
      set: function set(key, value) {
        this.set('_previousSortBy', value); // eslint-disable-line 
        return value;
      }
    }),

    sortedContent: Ember.computed('sortBy', 'model', 'isSortReversed', function () {
      var sorted = this.get('model').sortBy(this.get('sortBy'));
      if (this.get('isSortReversed')) {
        sorted = sorted.reverse();
      }
      return sorted;
    })

  });

  exports.default = InfluentialsTableComponent;
});
define('client/mixins/tag-filter', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var TagFilterMixin = Ember.Mixin.create({

    actions: {
      toggleTag: function toggleTag(tagId) {
        var filter = 'savedFilters.filters.tag_' + tagId;
        //check for true explicitly here because it could have the value 'not'
        if (this.get(filter) !== true) {
          this.set(filter, true);
        } else {
          this.set(filter, false);
        }
      },
      toggleNotTag: function toggleNotTag(tagId) {
        var filter = 'savedFilters.filters.tag_' + tagId;
        if (this.get(filter) === 'not') {
          this.set(filter, false);
        } else {
          this.set(filter, 'not');
        }
      },
      checkAllTags: function checkAllTags() {
        var _this = this;

        this.get('savedTags').forEach(function (tag) {
          _this.set('savedFilters.filters.tag_' + tag.id, true);
        });
      },
      uncheckAllTags: function uncheckAllTags() {
        var _this2 = this;

        this.get('savedTags').forEach(function (tag) {
          _this2.set('savedFilters.filters.tag_' + tag.id, false);
        });
      }
    }
  });

  exports.default = TagFilterMixin;
});
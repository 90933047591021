define('client/models/dashboard-card', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var DashboardCard = _emberData.default.Model.extend({
    changeTracker: { trackHasMany: true, auto: true },
    first: _emberData.default.attr('boolean'),
    position: _emberData.default.attr('number'),
    cardableReference: _emberData.default.attr('string'),
    dashboard: _emberData.default.belongsTo('dashboard', { inverse: 'dashboardCards' }),
    graphTemplate: _emberData.default.belongsTo('graph-template'),
    articleListTemplate: _emberData.default.belongsTo('article-list-template'),
    dashboardWidgetTemplate: _emberData.default.belongsTo('dashboard-widget-template'),

    cardable: _emberData.default.belongsTo('cardable', { polymorphic: true, async: false, inverse: 'dashboardCard' }),

    next: _emberData.default.belongsTo('dashboard-card', { async: false, inverse: 'previous' }),

    // these are just here to tell EmberData to load the sideloaded cards into the store.
    // These relationships should not be used directly
    previous: _emberData.default.belongsTo('dashboard-card', { inverse: 'next' }),
    oldPrevious: _emberData.default.belongsTo('dashboard-card', { inverse: null }),
    oldNext: _emberData.default.belongsTo('dashboard-card', { inverse: null }),
    firstCard: _emberData.default.belongsTo('dashboard-card', { inverse: null }),

    campaign: Ember.computed.alias('dashboard.campaign'),

    wasMoved: function wasMoved() {
      this.set("moved", true);
      Ember.run.later(this, function () {
        this.set("moved", false);
      }, 500);
    }
  });

  exports.default = DashboardCard;
});
define('client/controllers/sales/index', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var SORT_OPTIONS = [{
    name: "Profile 🔼",
    value: ["profile.name:asc"]
  }, {
    name: "Profile 🔽",
    value: ["profile.name:desc"]
  }, {
    name: "Created by 🔼",
    value: ["createdBy:asc"]
  }, {
    name: "Created by 🔽",
    value: ["createdBy:desc"]
  }, {
    name: "Created at 🔼",
    value: ["createdAt:asc"]
  }, {
    name: "Created at 🔽",
    value: ["createdAt:desc"]
  }, {
    name: "Modified by 🔼",
    value: ["modifiedBy:asc"]
  }, {
    name: "Modified by 🔽",
    value: ["modifiedBy:desc"]
  }, {
    name: "Modified at 🔼",
    value: ["updatedAt:asc"]
  }, {
    name: "Modified at 🔽",
    value: ["updatedAt:desc"]
  }];

  exports.default = Ember.Controller.extend({
    searchTerm: null,

    sortOptions: SORT_OPTIONS,
    sort: Ember.computed(function () {
      return SORT_OPTIONS[0];
    }),

    filteredProfiles: Ember.computed("model", "model.@each.profile", "searchTerm", function () {
      var term = (this.searchTerm || "").toLowerCase();
      if (Ember.isBlank(term)) {
        return this.model;
      }
      return this.model.filter(function (sale) {
        var name = (sale.get("profile.name") || "").toLowerCase();
        return name.indexOf(term) >= 0;
      });
    }),
    displayProfiles: Ember.computed.sort("filteredProfiles", "sort.value"),

    actions: {
      filter: function filter(term) {
        this.set("searchTerm", term);
      },
      sort: function sort(option) {
        this.set("sort", option);
      }
    }
  });
});
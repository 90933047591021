define('client/models/domain', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var Domain = _emberData.default.Model.extend({
    name: _emberData.default.attr('string'),
    subdomain: _emberData.default.attr('string'),
    publication: _emberData.default.attr('string')
  });

  Domain.pathForType = "domains";
  exports.default = Domain;
});
define('client/controllers/reports/share/page', ['exports', 'client/utils/reports/item'], function (exports, _item) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    interactions: true,
    selectedPageNumber: null,

    setPage: function setPage(pageNum) {
      var report = this.get('model.report');
      this.transitionToRoute("reports.share.page", report.get('hexUrl'), pageNum);
      this.set('selectedPageNumber', pageNum);
    },


    actions: {
      previousPage: function previousPage() {
        var newPage = this.get("model.page") - 1;
        if (newPage < 0) {
          return;
        }
        this.setPage(newPage);
      },
      nextPage: function nextPage() {
        var newPage = this.get("model.page") + 1;
        if (newPage >= this.get("model.report.pages.length")) {
          return;
        }
        this.setPage(newPage);
      },
      selectPage: function selectPage(pageNum) {
        this.setPage(pageNum);
      }
    }
  });
});
define("client/components/article-slider", ["exports", "client/components/horizontal-slider"], function (exports, _horizontalSlider) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  // = require ../horizontal-slider

  var ArticleSliderComponent = _horizontalSlider.default.extend({
    classNameBindings: [":article-slider"]
  });

  exports.default = ArticleSliderComponent;
});
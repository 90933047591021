define('client/components/article-list/trends-article-item', ['exports', 'client/components/article-list/article-item'], function (exports, _articleItem) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _articleItem.default.extend({

    classNames: ['trends-article-item'],

    classNameBindings: ['extended:extended'],

    showAuthorInfo: true,

    itemDateFormatted: Ember.computed('content.publishedOn', function () {
      return moment(this.get('content.publishedOn')).format('MMM DD, YYYY');
    }),

    extended: true,

    onStarClick: function onStarClick() {}
  });
});
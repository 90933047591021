define('client/services/search', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend(Ember.Evented, {
    updateSearch: function updateSearch() {
      this.trigger("search:update");
    }
  });
});
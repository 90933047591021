define('client/mixins/graph-legend', ['exports', 'client/mixins/graph-text', 'client/report-settings'], function (exports, _graphText, _reportSettings) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var GraphLegendMixin = Ember.Mixin.create(_graphText.default, {
    // ----------------------------------------------------------------------------
    // Legend settings
    // ----------------------------------------------------------------------------
    // Toggle for whether or not to show the legend
    // if you want to override default legend behavior, override showLegend
    showLegend: true,
    showLegendTotals: false,
    showLegendSpecial: false,

    // The names that will actually go in the legend, every chart should override
    // this because it depends on the data and layout of the chart.
    legendItemNames: [],

    legendItems: Ember.computed('legendNameOrderMap', 'legendItemNames.[]', 'formattedData', function () {
      var legendNameMap = this.get('legendNameOrderMap');
      var data = this.get('formattedData');
      var showLegendTotals = this.get("showLegendTotals");
      var showLegendSpecial = this.get("showLegendSpecial");
      return this.get('legendItemNames').sort(function (a, b) {
        return legendNameMap[a] > legendNameMap[b];
      }).map(function (label, i) {
        // Not finding the color should not be fatal
        var labelFor = data.findBy("label", label),
            fill = labelFor ? labelFor.color : null;
        return {
          label: label,
          fill: fill,
          stroke: fill,
          icon: function icon() {
            return 'square';
          },
          special: showLegendSpecial ? data.filterBy("label", label).reduce(function (total, d) {
            return total + (d.value == 0 ? 0 : 1);
          }, 0) : 0,
          total: showLegendTotals ? data.filterBy("label", label).reduce(function (total, d) {
            return total + d.value;
          }, 0) : 0
        };
      });
    }),

    getTooltipRows: function getTooltipRows(values) {
      var items = this.get('legendItems').filter(function (i) {
        return values.findBy('label', i.label);
      });
      var format = this.get('valueLabelFormatter');
      return {
        rows: items.map(function (i) {
          var value = values.findBy('label', i.label).value;
          return {
            color: i.fill,
            label: i.label,
            value: format(value)
          };
        })
      };
    },


    // ----------------------------------------------------------------------------
    // Layout
    // ----------------------------------------------------------------------------

    // Legend Boundary
    legendLeft: 65,
    legendTop: Ember.computed(function () {
      if (this.get('hasTitle')) {
        return this.get('titleHeight') / (this.get('numLegendRows') + 2);
      } else {
        return 0;
      }
    }).volatile(),
    legendWidth: Ember.computed(function () {
      if (this.get('hasTitle')) {
        var titleLeft = this.get('titleLeft');
        //titleLeft might be 0 (donut chart)
        return titleLeft ? titleLeft - this.get('legendLeft') : this.get('svgWidth');
      } else {
        return this.get('svgWidth');
      }
    }).volatile(),
    legendHeight: Ember.computed(function () {
      return this.get('numLegendRows') * this.get('legendItemHeight');
    }).volatile(),
    legendBottom: Ember.computed(function () {
      return this.get('legendTop') + this.get('legendHeight');
    }).volatile(),
    legendRight: Ember.computed(function () {
      return this.get('legendLeft') + this.get('legendWidth');
    }).volatile(),

    // Legend Items

    // Radius of each legend icon
    legendIconWidth: 10,

    // Padding between left side of legend text and icon
    legendLabelPadLeft: 5,
    // Minimum padding between right side of legend text and next icon
    legendLabelPadRight: 5,

    legendOutsideLabelSpace: Ember.computed('legendIconWidth', 'legendLabelPadLeft', 'legendLabelPadRight', function () {
      return this.get('legendIconWidth') + this.get('legendLabelPadLeft') + this.get('legendLabelPadRight');
    }).volatile(),

    legendItemHeight: 15,
    minLegendItemWidth: Ember.computed.readOnly('legendOutsideLabelSpace'),
    maxLegendItemWidth: Ember.computed(function () {
      return this.get('legendWidth') - this.get('legendLabelPadLeft') - this.get('legendLabelPadRight');
    }).volatile(),

    legendItemWidth: Ember.computed(function () {
      // First, try to see if we've already calculated width, we don't need
      // to recalculate because anytime there is a change to the legend
      // we redraw the whole thing
      var firstItem = this.get('legend').select('.legend-item');
      var itemWidth = !firstItem.empty() ? +firstItem.attr('width') : null;
      if (!isNaN(itemWidth) && itemWidth > 0) {
        return itemWidth;
      }

      // Get the maximum width legend item (items must already be drawn)
      // We draw first and then position after
      // The +1 is a hack to disallow trimming for text that fit correctly
      var legendLabels = this.get('legend').selectAll('.legend-item text');
      if (!legendLabels.empty()) {
        var widths = legendLabels[0].map(function (node) {
          return node.getBBox().width + 1;
        });
        itemWidth = d3.max(widths) + this.get('legendOutsideLabelSpace');
      }

      var minWidth = this.get('minLegendItemWidth');
      var maxWidth = this.get('maxLegendItemWidth');
      if (itemWidth < minWidth) {
        itemWidth = minWidth;
      } else if (itemWidth > maxWidth) {
        itemWidth = maxWidth;
      }
      return itemWidth;
    }).volatile(),

    numLegendItemsPerRow: Ember.computed(function () {
      return Math.floor(this.get('legendWidth') / (this.get('legendItemWidth') + this.get('legendLabelPadRight') + this.get('legendLabelPadLeft')));
    }).volatile(),

    numLegendRows: Ember.computed(function () {
      var num = Math.ceil(this.get('legendItems.length') / this.get('numLegendItemsPerRow'));
      return num ? num : 0;
    }).volatile(),

    // Maximum width of each label before it gets truncated
    legendLabelWidth: Ember.computed(function () {
      return this.get('legendItemWidth') - this.get('legendOutsideLabelSpace');
    }).volatile(),

    // ----------------------------------------------------------------------------
    // Styles
    // ----------------------------------------------------------------------------
    // Place each legend item, breaking across rows
    legendItemAttrs: Ember.computed(function () {
      return {
        'class': 'legend-item',
        'stroke-width': 0
      };
    }).volatile(),

    legendItemTransform: Ember.computed(function () {
      var legendItemWidth = this.get('legendItemWidth');
      var legendItemHeight = this.get('legendItemHeight');
      var numItemsPerRow = this.get('numLegendItemsPerRow');
      return function (d, i) {
        var col = i % numItemsPerRow;
        var row = Math.floor(i / numItemsPerRow);
        var dx = col * legendItemWidth;
        var dy = row * legendItemHeight + legendItemHeight / 2;
        return 'translate(' + dx + ', ' + dy + ')';
      };
    }).volatile(),

    legendLabelAttrs: Ember.computed(function () {
      return {
        x: this.get('legendIconWidth') + this.get('legendLabelPadLeft'),
        y: '.35em'
      };
    }).volatile(),

    // ----------------------------------------------------------------------------
    // Drawing Functions
    // ----------------------------------------------------------------------------
    clearLegend: function clearLegend() {
      this.get('svg').select('.legend-container').remove();
    },

    legend: Ember.computed(function () {
      var legend = this.get('svg').select('.legend-container');
      return legend.empty() ? this.get('svg').append('g').attr('class', 'legend-container') : legend;
    }).volatile(),

    drawLegend: function drawLegend() {
      var _this = this;

      this.clearLegend();

      if (!this.get('showLegend')) {
        return;
      }
      var legend = this.get('legend'),
          showTotals = this.get("showLegendTotals"),
          showTotalsSpecial = this.get("showLegendSpecial"),
          format = this.get('valueLabelFormatter');

      var legendItems = legend.selectAll('.legend-item').data(this.get('legendItems')).enter().append('g').attr(this.get('legendItemAttrs')).on('mouseenter', function (d) {
        return _this.showTooltipIfTruncatedText(d.label);
      }).on('mouseleave', function () {
        return _this.hideTooltip('truncated-text');
      });

      var iconWidth = this.get('legendIconWidth');
      var iconBorderRadius = iconWidth * 0.2;
      legendItems.append('rect').attr({
        'class': 'icon',
        fill: function fill(d) {
          return d.fill;
        },
        rx: iconBorderRadius,
        ry: iconBorderRadius,
        x: 0,
        y: -iconWidth / 2,
        width: iconWidth,
        height: iconWidth
      });

      legendItems.append('text').style('text-anchor', 'start').text(function (d) {
        var addedVal = "";
        if (showTotals && !showTotalsSpecial) {
          addedVal = ' (' + format(d.total) + ')';
        } else if (showTotals && showTotalsSpecial) {
          var syndicatesVal = d.total - d.special;
          addedVal = ' (' + format(d.special) + ' + ' + format(syndicatesVal) + ' Syndicates)';
        }
        return d.label + addedVal;
      }).attr(this.get('legendLabelAttrs'));

      // Now that the legend text has been drawn we can calculate positions
      // based on actual text size
      var legendItemWidth = this.get('legendItemWidth');
      legendItems.attr('width', legendItemWidth).attr('transform', this.get('legendItemTransform'));

      // Let's trim any labels that were beyond text length
      var legendLabelWidth = this.get('legendLabelWidth');
      var labelTrimmer = this.labelTrimmer({
        getLabelSize: function getLabelSize() {
          return legendLabelWidth;
        },
        getLabelText: function getLabelText(d) {
          var addedVal = "";
          var syndicatesVal = d.total - d.special;
          if (showTotals && showTotalsSpecial && syndicatesVal > 0) {
            addedVal = ' (' + format(d.special) + ' + ' + format(syndicatesVal) + ' Syndicates)';
          } else if (showTotals) {
            addedVal = ' (' + format(d.total) + ')';
          }
          return d.label + addedVal;
        }
      });
      legendItems.selectAll('text').call(labelTrimmer);

      legend.attr('transform', 'translate(' + this.get('legendLeft') + ',' + this.get('legendTop') + ')');
    }

  });

  exports.default = GraphLegendMixin;
});
define('client/components/expanded-article-iptc-child-topics', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });

    function _toConsumableArray(arr) {
        if (Array.isArray(arr)) {
            for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
                arr2[i] = arr[i];
            }

            return arr2;
        } else {
            return Array.from(arr);
        }
    }

    var ExpandedArticleIptcChildTopicsComponent = Ember.Component.extend({
        iptcTopicLabels: Ember.computed("iptcTopics", function () {
            var labels = [].concat(_toConsumableArray(this.get("iptcTopics")));
            labels.shift();
            return labels;
        }),

        iptcTopicText: Ember.computed("iptcTopics", function () {
            return this.get("iptcTopics")[0];
        }),

        iptcTextRender: Ember.computed("iptcTopics", function () {
            return this.get("iptcTopics").length > 0;
        })
    });

    exports.default = ExpandedArticleIptcChildTopicsComponent;
});
define('client/components/blog-readership-widget', ['exports', 'client/components/simple-widget', 'client/utils/date-format'], function (exports, _simpleWidget, _dateFormat2) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var BlogWidgetComponent = _simpleWidget.default.extend({

    formatPct: function formatPct(v) {
      return d3.format(",.2%")(v);
    },
    title: Ember.computed("profile.nameReadership", function () {
      var readershipName = this.get("profile.nameReadership") || "";
      return 'Owned Media ' + readershipName;
    }),
    text: Ember.computed("profile", "profile.nameReadership", function () {
      var readershipName = this.get("profile.nameReadership") || "";
      var readershipTitleCase = readershipName.charAt(0).toUpperCase() + readershipName.substr(1).toLowerCase();
      return Ember.String.htmlSafe("<p>Owned Media " + readershipTitleCase + " represents the the number of unique visitors consuming content published on your company's owned media channel(s).</p>");
    }),
    value: Ember.computed.oneWay("content.blog_readership"),
    iconClass: "db-widget-icon-readership",
    action: "toReadership",
    click: function click(e) {
      var $e = Ember.$(e.target);
      if ($e.hasClass("bd")) {
        this.onClick();
      }
    }
  });

  exports.default = BlogWidgetComponent;
});
define('client/components/research/publication-card', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['publication-card'],

    onSelectedPublication: function onSelectedPublication() {},
    onSaveToList: function onSaveToList() {},


    actions: {
      // TODO: Use computed property to handle selection of publication
      openPublication: function openPublication(url) {
        if (url.startsWith('http')) {
          window.open(url, '_blank');
        } else {
          if (url.startsWith('www')) {
            window.open('https://' + url, '_blank');
          } else {
            window.open('https://www.' + url, '_blank');
          }
        }
      },
      selectedPublication: function selectedPublication(publication) {
        this.get('onSelectedPublication')(publication);
      },
      saveToList: function saveToList() {
        this.get('onSaveToList')();
      }
    }

  });
});
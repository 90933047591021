define('client/models/trend-article', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var Article = _emberData.default.Model.extend({

    profile: Ember.computed.alias('profileService.profile'),

    authorId: _emberData.default.attr('string'),
    author: _emberData.default.attr('string'),

    content: _emberData.default.attr("string"),
    domain: _emberData.default.attr("string"),
    extractorType: _emberData.default.attr("string"),
    publishedOn: _emberData.default.attr("isodate"),
    relevance: _emberData.default.attr("number"),
    relevanceDetails: _emberData.default.attr("string"),
    relevanceTfidf: _emberData.default.attr("number"),
    relevanceTfidfPg: _emberData.default.attr("number"),
    title: _emberData.default.attr("string"),
    url: _emberData.default.attr("string"),
    socialCounts: _emberData.default.attr("object"),
    language: _emberData.default.attr(),
    country: _emberData.default.attr(),

    fbTotal: Ember.computed.alias("socialCounts.fb_total"),
    pinterestPins: Ember.computed.alias("socialCounts.pinterest_pins"),
    twitterRetweets: Ember.computed.alias("socialCounts.twitter_retweets"),
    googlePlusones: Ember.computed.alias("socialCounts.google_plusones"),
    totalAmplification: Ember.computed.alias("socialCounts.total_amplification")

  });

  Article.pathForType = "research/trends/articles";

  exports.default = Article;
});
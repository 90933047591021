define('client/models/recent-search', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var MAX_CHAR_TERMS = 68;

  var RecentSearch = _emberData.default.Model.extend({
    value: _emberData.default.attr(),
    name: Ember.computed.alias("value"),
    term: Ember.computed.alias("value"),
    shortTerm: Ember.computed("term", function () {
      var term = this.get("term"),
          l = term.length;
      var t1 = void 0,
          t2 = void 0,
          charsToRemove = void 0;
      if (l > MAX_CHAR_TERMS) {
        charsToRemove = Math.floor(MAX_CHAR_TERMS / 2);
        t1 = term.substring(0, charsToRemove);
        t2 = term.substring(l - charsToRemove);
        return (t1 + " <strong>…</strong> " + t2).htmlSafe();
      }
      return term;
    })
  });
  RecentSearch.pathForType = "recent_searches";

  exports.default = RecentSearch;
});
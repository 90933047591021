define('client/utils/percent-data-points', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.getLargestRemainder = getLargestRemainder;


  //Implementation of https://en.wikipedia.org/wiki/Largest_remainder_method
  function getLargestRemainder(values, desiredSum) {
    if (Ember.isEmpty(values)) {
      return [];
    }
    var diff, i, parts, sum;
    sum = 0;
    parts = values.map(function (item, i) {
      var int;
      int = item | 0;
      sum += int;
      return {
        integer: int,
        decimal: item % 1,
        originalIndex: i
      };
    });
    if (sum !== desiredSum) {
      parts = parts.sortBy('decimal').reverse();
      diff = desiredSum - sum;
      // when original data doesn't add up to desiredSum by far, incrementing the integer would go out of bound
      // return the input values else the numbers would look off
      if (diff > parts.length) {
        return values;
      }
      i = 0;
      while (i < diff) {
        parts[i].integer++;
        i++;
      }
    }
    return parts.sortBy('originalIndex').mapBy('integer');
  }
});
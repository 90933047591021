define('client/routes/analyze/coverage', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    titleToken: "Coverage",

    // passing model and having transition refresh so controller will run setup after route transition
    // for controller to reload newsletter count, loading in model interfers with article list call
    model: function model() {
      return {};
    },


    actions: {
      willTransition: function willTransition(transition) {
        if (transition.targetName == 'analyze.coverage.index') {
          this.refresh();
        }
      }
    }
  });
});
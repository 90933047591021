define('client/utils/filters/options', ['exports', 'client/components/location-filters'], function (exports, _locationFilters) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var FilterOptions = Ember.Object.extend({
    profile: null,
    otherProfiles: null,

    channelTypeOptions: [{
      id: 'earned',
      name: 'Earned'
    }, {
      id: 'owned',
      name: 'Owned'
    }, {
      id: 'press_release',
      name: 'Newswire'
    }],

    contentTypeOptions: [{
      id: 'article',
      name: 'Digital'
    }, {
      id: 'twitter',
      name: 'Twitter'
    }, {
      id: 'television',
      name: 'Television'
    }, {
      id: 'radio',
      name: 'Radio'
    }, {
      id: 'podcast',
      name: 'Podcast'
    }, {
      id: 'youtube_bc',
      name: 'Youtube'
    }, {
      id: 'print_publication',
      name: 'Print'
    }],

    toneLabelOptions: [{
      id: 'positive',
      name: 'Positive'
    }, {
      id: 'neutral',
      name: 'Neutral'
    }, {
      id: 'negative',
      name: 'Negative'
    }],

    amplificationLabelOptions: [{
      id: 'facebook_count',
      name: 'Facebook'
    }, {
      id: 'twitter_count',
      name: 'Twitter'
    }, {
      id: 'pinterest_count',
      name: 'Pinterest'
    }],

    tagOptions: Ember.computed('profile.alphabeticalTags', function () {
      var items = this.get('profile.alphabeticalTags');
      if (Ember.isEmpty(items)) {
        return [];
      }
      return items;
    }),

    volumeTypeOptions: Ember.computed('profile.showStoryLevels', function () {
      var _this = this;

      return [{
        id: 'influential',
        name: 'Influential'
      }, {
        id: 'uninfluential',
        name: 'Comprehensive',
        profileFlag: 'showStoryLevels'
      }].filter(function (filter) {
        return !filter.profileFlag || _this.get('profile.' + filter.profileFlag);
      });
    }),

    interactionLabelOptions: Ember.computed('profile.interaction_metrics', function () {
      var interactionMetrics = this.get('profile.interaction_metrics');
      if (Ember.isEmpty(interactionMetrics)) return [];
      return Object.keys(interactionMetrics).map(function (id) {
        return {
          id: id,
          name: interactionMetrics[id]
        };
      });
    }),

    locationOptions: Ember.computed("combinedLocationFilter.[]", function () {
      var locations = this.get("combinedLocationFilter");
      var locationData = this.get("profile.locationData");
      return (0, _locationFilters.defaultISOLocationOptions)(locations, locationData);
    }),

    locationDefaultOptions: Ember.computed("locationOptions.[]", function () {
      var locations = this.get("locationOptions");
      var locationData = this.get("profile.locationData");
      return (0, _locationFilters.defaultLocationOptions)(locations, locationData);
    }),

    resolvedOtherProfiles: Ember.computed('otherProfiles.[]', function () {
      return this.get('otherProfiles').mapBy('profile');
    }),

    combinedLocationFilter: Ember.computed("profile.locations.[]", "resolvedOtherProfiles.@each.locations", function () {
      var _getProperties = this.getProperties("profile", "otherProfiles"),
          profile = _getProperties.profile,
          otherProfiles = _getProperties.otherProfiles;

      if (otherProfiles.get("length") === 0) {
        var profileLocations = profile.get('locations') || [];
        return profileLocations.slice();
      } else {
        var anyHaveNone = !profile.get('locations.length') || otherProfiles.any(function (p) {
          return !p.get('profile.locations.length');
        });

        //if any of the profiles don't have a restriction on
        //locations, then show all locations
        if (anyHaveNone) {
          return [];
        } else {
          var other = otherProfiles.map(function (p) {
            return (p.get('profile.locations') || []).slice();
          }).flatten().compact();
          return (profile.get('locations') || []).slice().concat(other).uniq();
        }
      }
    }),

    whitelistTagSort: ['name'],
    whitelistTagOptions: Ember.computed.sort('_whitelistTags', 'whitelistTagSort'),
    whitelistTagOptionsIds: Ember.computed.mapBy('whitelistTagOptions', 'id'),

    _whitelistTags: Ember.computed("profile.allWhitelistTags.[]", "resolvedOtherProfiles.@each.allWhitelistTags", function () {
      var _getProperties2 = this.getProperties("profile", "otherProfiles"),
          profile = _getProperties2.profile,
          otherProfiles = _getProperties2.otherProfiles;

      var whitelistTags = profile.get('allWhitelistTags') || [];

      otherProfiles.forEach(function (profile) {
        var tags = profile.get('profile.allWhitelistTags');
        if (tags.length) {
          whitelistTags.addObjects(tags);
        }
      });
      return whitelistTags;
    }),

    neoOptions: Ember.computed('profile.soundbytes.[]', function () {
      var items = this.get('profile.soundbytes') || [];
      return items.sortBy('name');
    }),

    campaignOptions: Ember.computed('profile.keywordCampaigns.[]', function () {
      var items = this.get('profile.keywordCampaigns') || [];
      return items;
    })

  });

  exports.default = Ember.Mixin.create({
    filterOptions: Ember.computed("profile.id", "otherProfiles.[]", function () {
      return FilterOptions.create(this.getProperties("profile", "otherProfiles"));
    })
  });
});
define('client/models/impact-score-prioritized-url', ['exports', 'ember-data', 'client/utils/date-format'], function (exports, _emberData, _dateFormat2) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var ImpactScorePrioritizedUrl = _emberData.default.Model.extend({
    url: _emberData.default.attr("string"),
    profileImpactScoreSetting: _emberData.default.belongsTo('profile-impact-score-setting'),
    created_at: _emberData.default.attr("isodate"),
    applyToCompetitors: _emberData.default.attr("boolean"),

    dateAdded: Ember.computed('created_at', function () {
      var options = {
        format: 'MMM DD, YYYY'
      };
      return (0, _dateFormat2.default)(this.get('created_at'), options);
    })

  });

  ImpactScorePrioritizedUrl.pathForType = "impact_score_prioritized_urls";

  exports.default = ImpactScorePrioritizedUrl;
});
define('client/serializers/filter-set', ['exports', 'ember-data', 'active-model-adapter'], function (exports, _emberData, _activeModelAdapter) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _activeModelAdapter.ActiveModelSerializer.extend(_emberData.default.EmbeddedRecordsMixin, {
    attrs: {
      otherProfiles: { key: 'other_profile_ids', serialize: 'ids', deserialize: 'ids' },
      soundbytes: { key: 'soundbyte_ids', serialize: 'ids', deserialize: 'ids' },
      tags: { serialize: 'ids', deserialize: 'ids' },
      excludedTags: { key: 'not_tag_ids', serialize: 'ids', deserialize: 'ids' },
      whitelistTags: { serialize: 'ids', deserialize: 'ids' }
    }
  });
});
define('client/components/interaction-widget', ['exports', 'client/components/simple-widget', 'client/helpers/format-percent'], function (exports, _simpleWidget, _formatPercent) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var InteractionWidgetComponent = _simpleWidget.default.extend({

    title: Ember.computed.alias('profile.nameInteraction'),

    text: Ember.computed('profile.nameCustomer', 'profile.nameTotalInteraction', function () {
      var totalInteraction = this.get('profile.nameTotalInteraction');
      var customers = this.get('profile.nameCustomer');
      return Ember.String.htmlSafe('<p>' + totalInteraction + ' count is the total number of interactions taken by ' + customers + ' who came to your site as a result of PR activities.  ' + customers + ' may trigger multiple events in one session, but we only tally one instance per interaction type per session.</p> <p>' + totalInteraction + ' percentage is the ' + totalInteraction + ' count divided by the number of ' + customers + ' who came to your site as a result of PR activities.</p>');
    }),

    iconClass: "db-widget-icon-interaction",

    formattedCount: Ember.computed('interactionValue', function () {
      return this.get('format')(this.get('interactionValue'));
    }),

    formattedPercent: Ember.computed("interactionValue", 'visitorValue', function () {
      var iCount = this.get('interactionValue');
      var vCount = this.get('visitorValue');
      if (iCount && vCount) {
        return vCount == 0 ? 0 : iCount / vCount;
      } else {
        return null;
      }
    }),

    interactionValue: Ember.computed.alias("content.interaction"),
    visitorValue: Ember.computed.alias("content.visitors"),

    validValue: Ember.computed('interactionValue', 'visitorValue', function () {
      var iCount = this.get('interactionValue');
      var vCount = this.get('visitorValue');
      return typeof iCount === 'number' && !isNaN(iCount) && typeof vCount === 'number' && !isNaN(vCount);
    }),

    action: "toContextInteraction"

  });

  exports.default = InteractionWidgetComponent;
});
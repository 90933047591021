define('client/helpers/can-manage', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Helper.extend({
    abilityService: Ember.inject.service('ability'),

    onPermissionsUpdate: Ember.observer('abilityService.userPermissions', function () {
      this.recompute();
    }),

    compute: function compute(params) {
      var moduleName = params[0];
      return this.abilityService.canManage(moduleName);
    }
  });
});
define('client/controllers/plan/pitch/show/author', ['exports', 'ember-parachute'], function (exports, _emberParachute) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var AuthorQP = new _emberParachute.default({
    openNotes: {
      as: 'notes',
      type: "boolean",
      defaultValue: false
    }
  });

  exports.default = Ember.Controller.extend(AuthorQP.Mixin, {
    actions: {
      goBack: function goBack() {
        this.replaceRoute('plan.pitch.show');
      }
    }
  });
});
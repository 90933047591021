define('client/adapters/article-summary', ['exports', 'client/adapters/application', 'client/mixins/adapters/has-endpoint-actions'], function (exports, _application, _hasEndpointActions) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var ArticleSummaryAdapter = _application.default.extend(_hasEndpointActions.default, {
    /*
      When loading a single article summary, they are actually found as real articles
      but with &include_all_fields set to true
    */
    findRecord: function findRecord(store, type, id, snapshot) {
      var url = this.buildURL(type.typeKey || type.modelName, id, snapshot);
      url = this.checkUrl(url, snapshot);
      return this.ajax(url, 'GET').then(function (result) {
        //We copy here because article with include_all_fields=true contains the data
        //for both the article and the article summary
        result.article_summary = result.articles;
        return result;
      });
    },
    endpointActions: {
      refreshSocialCounts: function refreshSocialCounts(store, type, id, model, queryParams) {
        var _this = this;

        var url = this.buildURL(type, id, model);
        var queryString = this.serializeQueryParams(queryParams);
        var fullURL = this.checkUrl(url, model) + '/refresh_social_counts?' + queryString;

        return this.ajax(fullURL, 'PUT').then(function (data) {
          return _this.normalizeActionResponse('refreshSocialCounts', model, data);
        });
      }
    }
  });

  exports.default = ArticleSummaryAdapter;
});
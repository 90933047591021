define('client/components/dashboard/dashboard-card/trending', ['exports', 'client/utils/date-format', 'moment'], function (exports, _dateFormat2, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var TIMEFRAME_NAMES = [{ id: "day", name: "previous day" }, { id: "week", name: "previous week" }, { id: "month", name: "previous month" }, { id: "quarter", name: "previous quarter" }, { id: "year", name: "previous year" }];

  var TIMEFRAME_CALCULATIONS = {
    "day": [0, "day"],
    "week": [7, "day"],
    "month": [30, "day"],
    "quarter": [90, "day"],
    "year": [365, "day"]
  };

  exports.default = Ember.Component.extend({
    classNames: ["card-trending", "d-flex", "justify-content-center"],

    card: null,

    // Trend info
    trend: 0,
    trendDirection: Ember.computed("trend", function () {
      var trend = this.get("trend");
      return trend < 0 ? "down" : trend > 0 ? "up" : "null";
    }),
    name: "something trending",

    absolute: false,
    isLoading: false,

    allowTimeframe: true,
    timeframe: "month",
    timeframeName: Ember.computed("timeframe", function () {
      return TIMEFRAME_NAMES.findBy("id", this.get("timeframe")).name;
    }),

    timeframeNamesForUI: Ember.computed(function () {
      return TIMEFRAME_NAMES;
    }),

    ranges: Ember.computed("toDate", "timeframe", function () {
      var _getProperties = this.getProperties("toDate", "timeframe"),
          toDate = _getProperties.toDate,
          timeframe = _getProperties.timeframe;

      if (!toDate) {
        return null;
      }

      var ranges = [];
      while (ranges.length < 2) {
        var fromDate = (0, _moment.default)(toDate);
        fromDate.subtract.apply(fromDate, TIMEFRAME_CALCULATIONS[timeframe]);
        fromDate = fromDate.toDate();

        ranges.push([fromDate, toDate]);

        toDate = (0, _moment.default)(fromDate).subtract(1, "day").toDate();
      }
      return ranges;
    }),

    previousDates: Ember.computed("ranges", function () {
      var ranges = this.get("ranges");
      if (ranges) {
        if (ranges[1][0].valueOf() === ranges[1][1].valueOf()) {
          return (0, _dateFormat2.default)(ranges[1][0]);
        }
        return (0, _dateFormat2.default)(ranges[1][0]) + '-' + (0, _dateFormat2.default)(ranges[1][1]);
      }
    }),

    currentDates: Ember.computed("ranges", function () {
      var ranges = this.get("ranges");
      if (ranges) {
        if (ranges[0][0].valueOf() === ranges[0][1].valueOf()) {
          return (0, _dateFormat2.default)(ranges[0][0]);
        }
        return (0, _dateFormat2.default)(ranges[0][0]) + '-' + (0, _dateFormat2.default)(ranges[0][1]);
      }
    }),

    onTrendChange: function onTrendChange() {}
  });
});
define('client/adapters/article', ['exports', 'client/adapters/v3-application'], function (exports, _v3Application) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _v3Application.default.extend({
    pathForType: function pathForType() {
      return 'pers';
    },
    findRecord: function findRecord(store, type, id, snapshot) {
      var url = this.buildURL(type.modelName, id, snapshot, 'findRecord');
      var query = this.buildQuery(snapshot);
      if (snapshot.adapterOptions && snapshot.adapterOptions.query) {
        query = Ember.merge(query, snapshot.adapterOptions.query);
      }
      return this.ajax(url, 'GET', { data: query });
    }
  });
});
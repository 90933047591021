define('client/components/common/ui-circle-swatch', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: 'span',
    color: '#bbb',

    safeStyle: Ember.computed('color', function () {
      return new Ember.String.htmlSafe('background-color:' + this.get('color'));
    })
  });
});
define('client/components/formatted-resizable-input', ['exports', 'client/components/resizable-input'], function (exports, _resizableInput) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _resizableInput.default.extend({
    // override from textsupport
    _elementValueDidChange: function _elementValueDidChange() {
      this._internalSet = true;
      this.set("value", this.$().html());
      this._internalSet = false;
    },

    paste: function paste() /* e */{
      Ember.run.later(this, function () {
        this.onKeyPress();
      }, 10);
    }
  });
});
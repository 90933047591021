define('client/components/profile-name-renderer', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var ProfileNameRendererComponent = Ember.Component.extend({
    tagName: "span",
    classNameBindings: ["showAsCompetitorOfCustomer:customer-competitor-profile", "showAsCustomer:customer-profile"],

    user: null,
    profile: null,
    showIds: false,

    showAsCustomer: Ember.computed.and('user.isAirprAdmin', 'profile.isCustomer'),
    showAsCompetitorOfCustomer: Ember.computed.and('user.isAirprAdmin', 'profile.isCompetitorOfCustomer')
  });

  exports.default = ProfileNameRendererComponent;
});
define('client/routes/plan/neo/soundbytes/new', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    profileService: Ember.inject.service('profile'),

    titleToken: "New Soundbyte",

    model: function model(params) {
      var bucket = this.get('profileService.lastBucketId') ? this.store.peekRecord('profile-message-bucket', this.get('profileService.lastBucketId')) : null;
      return Ember.RSVP.hash({
        message: this.store.createRecord("message", {
          profile: this.get("profileService.profile"),
          profileMessageBucket: bucket
        }),
        buckets: this.modelFor("plan.neo")
      });
    }
  });
});
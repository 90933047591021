define('client/components/filter-item', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var FilterItemComponent = Ember.Component.extend({
    classNameBindings: [":filter-option", "open", "notValue:has-not-option", "isNotSet"],
    subfilters: null,
    open: false,
    content: null,
    showOptions: null,
    notValue: null,
    only: function only() {},


    hasChildren: Ember.computed("content.children.length", function () {
      return this.get('content.children.length');
    }),
    addSpacer: Ember.computed("content.children.length", "content.addSpacerIfNoChildren", function () {
      return !this.get('content.children.length') && this.get("content.addSpacerIfNoChildren");
    }),
    showChildren: Ember.computed("open", "content.children.length", function () {
      return this.get('content.children.length') && this.get('open');
    }),
    click: function click(evt) {
      if (evt.target.className === 'show-children-toggle') {
        evt.stopImmediatePropagation();
        this.toggleProperty('open');
      }
    },
    handleValueChange: Ember.observer('content.value', function () {
      if (this.get('content.propagateToChildren') === true) {
        var myvalue = this.get('content.value');
        var mychildren = this.get('content.children');
        if (mychildren) {
          mychildren.forEach(function (item) {
            item.set('value', myvalue);
          });
        }
      }
    }),

    isNotSet: Ember.computed('content.value', 'notValue', function () {
      return this.get('content.value') === this.get('notValue');
    }),

    init: function init() {
      this._super.apply(this, arguments);
      this.set('subfilters', this.get('subfilters') || []);
    },


    actions: {
      only: function only(filter) {
        this.only(filter);
      },
      setNot: function setNot(filter) {
        filter.set('value', this.get('isNotSet') ? filter.get('default') : this.get('notValue'));
      }
    }
  });

  exports.default = FilterItemComponent;
});
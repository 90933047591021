define('client/components/reports/rich-text', ['exports', 'dompurify', 'squire-rte', 'client/components/reports/base-text-widget', 'client/utils/rich-text-fonts', 'client/mixins/rich-text'], function (exports, _dompurify, _squireRte, _baseTextWidget, _richTextFonts, _richText) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  window.DOMPurify = _dompurify.default;


  var SIZES = [{
    name: "largewide",
    displayName: "Large (Wide)",
    rowspan: 1,
    colspan: 4
  }, {
    name: "largetall",
    displayName: "Large (Tall)",
    rowspan: 2,
    colspan: 2
  }, {
    name: "medium",
    displayName: "Medium",
    rowspan: 1,
    colspan: 2
  }, {
    name: "small",
    displayName: "Small",
    rowspan: 1,
    colspan: 1
  }];

  exports.default = _baseTextWidget.default.extend(_richText.default, {
    classNameBindings: [":report-rich-text"],
    editComponentName: "reports/edit-rich-text",
    text: Ember.computed.alias('item.content.text'),
    sizes: SIZES,
    hasText: Ember.computed('text', function () {
      var text = Ember.$(Ember.$.parseHTML(this.get('text'))).text();
      return this.get('hasHorizontalLine') || Ember.isPresent(text);
    }),
    placeholder: 'Text Block, Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut',

    isEditingDidChange: Ember.observer('isEditing', function () {
      this._super.apply(this, arguments);
      if (!this.get('isEditing') && !this.get('isViewOnly')) {
        this.send('saveItem');
      }
    }),

    isViewOnly: Ember.computed.and('reportService.isReadOnly', 'reportService.isViewingShare'),

    didInsertElement: function didInsertElement() {
      var _this = this;

      this._super.apply(this, arguments);
      // need this to ensure component's special html logic is run
      // and not just replaced by page grabbing rendered html
      // specifically, this component needs to replace iframes
      // for pdf generation
      this.get('item').on('didRerenderHtml', function () {
        _this._updateItemHtml();
      });
    },

    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);
      this.get('item').off('didRerenderHtml');
    },

    initializeEditor: function initializeEditor() {
      var _this2 = this;

      var $editorDiv = this.$().find('iframe.rich-text-editor');
      var body = void 0;
      if ($editorDiv[0]) {
        var prepareIframe = function prepareIframe(event) {

          body = $editorDiv[0].contentDocument.body;
          body.style.overflow = 'hidden';
          body.style.margin = "0";
          body.style['min-height'] = '130px';

          if (_this2.get('isViewOnly') && !_this2.get('header') && !_this2.get('subheader') && !_this2.get('subsubheader')) {
            body.innerHTML = _this2.get('text').replace(/href=/g, 'target="_blank" href=');
          }

          if (_this2.get('isViewOnly')) {
            _this2._setIframeHeight(body);
            return;
          }
          var editor = _this2._createSquireEditor($editorDiv[0].contentDocument.body);

          _this2.set('editor', editor);

          var shouldSave = _this2.convertLegacyTextbox();
          editor.moveCursorToEnd();
          editor.insertHTML(_this2.get('text'), true);
          Ember.$(_this2.get('editorDocumentBody')).find('.hacky-break').remove();
          _this2._setHorizontalLineButton();
          _this2._updateItemHtml();
          Ember.run.schedule('afterRender', _this2, function () {
            if (this.get('text')) {
              //for some reason saveUndoState is not working, so hacky workaround here:
              editor.setHTML(this._getCorrectedHTML());
            }
            this._setIframeHeight(this.get('editorDocumentBody'));
            if (shouldSave) {
              this.send('saveItem');
            }
          });

          editor.addEventListener('input', function (event) {
            _this2._setIframeHeight(_this2.get('editorDocumentBody'));
            Ember.run.debounce(_this2, 'updateRenderedHtml', 200);
          });
        };

        // Ensure that we set doctype on the iframe
        // so that it renders similarly on the pdf as well
        var idocument = $editorDiv[0].contentDocument;
        idocument.open();
        idocument.write("<!DOCTYPE html>");
        idocument.close();
        prepareIframe();
        // for firefox
        $editorDiv[0].addEventListener('load', prepareIframe);
      }
    },

    _createSquireEditor: function _createSquireEditor(body) {
      return new _squireRte.default(body, {
        blockAttributes: {
          style: 'font-family: ' + this.get('selectedFontFamily') + ';'
        },
        tagAttributes: { li: {
            style: 'font-family: ' + this.get('selectedFontFamily') + ';' }
        }
      });
    },

    _setIframeHeight: function _setIframeHeight(body) {
      // to simulate overflow text out of the iframe, need to change height of
      // the iframe as the html inside changes
      var height = Ember.$(body).height();
      this.$().find('.report-text-block, .rich-text-editor-mask').height(height);
    },
    // We can remove this logic once we rerender each report
    convertLegacyTextbox: function convertLegacyTextbox() {
      //no-op here, to be implemented downstream otherwise
    },
    // remove this after we rerender each report
    _convertBadFormatting: function _convertBadFormatting() {
      var $editor = Ember.$(this.get('editorDocumentBody'));
      // MsoNormal is from Microsoft Office HTML documents?
      // HelveticaNeueW02 was causing issues with iframes
      var badFonts = $editor.find('span[style="font-family:helveticaneuew02"], .MsoNormal');
      badFonts.css('font-family', _richTextFonts.default.HELVETICA.family);

      $editor.find('div[style*="font-size:"]').each(function () {
        var text = Ember.$(this).contents().filter(function () {
          return this.nodeType === Node.TEXT_NODE;
        });
        // If there is no text element, change the size of the parent div
        // to be the maximum of the child spans + 3(to give some spacing)
        if (!text.length) {
          var max = Math.max.apply(Math, Ember.$(this).find('span.size').map(function () {
            return parseInt(Ember.$(this).css('font-size'), 10);
          }));
          Ember.$(this).css('font-size', max + 3 + 'px');
        }
      });
      /*
      * workaround for add-on issue related to incorrect font/size
      * when font or size is changed before user enters any text
      * the span element gets inserted next to the div instead of inside the div(the parent element of text with font style)  
      * this causes the selected font to be different than the displayed font
      */
      if (!this.hasText && $editor.children().length === 2) {
        var span = $editor.find('span');
        var div = $editor.find('div');
        if (!span.children().length && div.find('span').length === 0) {
          div.append(span);
          $editor.find('br').remove();
        }
      }

      this.set('text', this.get('editor').getHTML());
    },

    _getCorrectedHTML: function _getCorrectedHTML() {
      return this.get('editor').getHTML().replace('HelveticaNeueW02,', '');
    },

    updateRenderedHtml: function updateRenderedHtml() {
      var editor = this.get("editor");
      if (Ember.isNone(editor) || this.get('text') === editor.getHTML()) {
        return;
      }
      this.set('text', editor.getHTML());
      this._super.apply(this, arguments);
    },

    _updateItemHtml: function _updateItemHtml() {
      Ember.run.schedule('afterRender', this, function () {
        if (this._state !== "inDOM") {
          return;
        }
        this._convertBadFormatting();
        var parent = this.$().parent(),
            item = this.get("item");
        var $html = Ember.$(parent[0].outerHTML);
        $html.find('iframe.rich-text-editor').replaceWith('<div class="rich-text-editor"> ' + this.get('text') + ' </div>');
        item.replaceItemHtml($html[0].outerHTML);
      });
    }
  });
});
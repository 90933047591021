define('client/routes/research/authors', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    titleToken: "Authors",

    model: function model() {
      return this.store.query('saved-search', {
        type: 2
      });
    },

    setupController: function setupController(controller, model) {
      this._super(controller, model);
      controller.send('checkRedirect');
    }

  });
});
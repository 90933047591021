define('client/components/reports/edit-search-and-filters', ['exports', 'client/mixins/reports/filter-set-filter'], function (exports, _filterSetFilter) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_filterSetFilter.default, {
    //need to be supplied
    item: null,
    profile: null,
    hasComprehensiveCoverage: true,

    activePanelItem: Ember.computed.alias('parentView.activePanelItem'),
    influential: Ember.computed.alias('item.influential'),
    uninfluential: Ember.computed.alias('item.uninfluential'),
    whitelistTagIds: Ember.computed.alias('item.whitelistTagIds'),
    whitelistTags: Ember.computed.alias('item.whitelistTags'),
    currentProfile: Ember.computed.or('profile.profile', 'profile'),

    selectedFilterSet: Ember.computed.reads('item.selectedFilterSet'),

    influentialDefault: Ember.computed.alias('item.influential'),
    uninfluentialDefault: Ember.computed.alias('item.uninfluential'),
    whitelistTagIdsDefault: Ember.computed.oneWay('item.whitelistTagIds'),

    actions: {
      activatePanelItem: function activatePanelItem(panel) {
        this.get('parentView').send('activatePanelItem', panel);
      }
    }
  });
});
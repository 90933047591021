define('client/components/power-of-voice-table', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var FULL_VIEW = 'full';
  var SNAPSHOT_VIEW = 'snapshot';
  var TRENDING_VIEW = 'trending';

  exports.default = Ember.Component.extend({
    classNames: ['graph-metrics-table'],
    expandedView: false,
    showPovSentiment: false,
    viewType: TRENDING_VIEW

  });
});
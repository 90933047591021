define('client/components/analyze/coverage-graph', ['exports', 'client/utils/svg-to-image'], function (exports, _svgToImage) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({

    customizeChartOpen: false,
    showInfo: false,
    infotipTexts: null,
    isLoading: false,

    IptcData: Ember.inject.service(),

    init: function init() {
      this._super.apply(this, arguments);
      this.checkForAllowedExpanded();
      this.set('trendOptions', this.get('trendOptions') || [{ id: 'snapshot', name: 'Snapshot' }, { id: 'trending', name: 'Trending' }]);
      this.mainIptcTopics();
    },
    didUpdateAttrs: function didUpdateAttrs() {
      this._super.apply(this, arguments);
      this.checkForAllowedExpanded();
    },
    checkForAllowedExpanded: function checkForAllowedExpanded() {
      var _this = this;

      if (!this.get('isPOVChart') && this.get('graphExpanded')) {
        //Needed in init so a rendering flash doesn't happen
        Ember.run.next(function () {
          _this.toggleGraphExpanded();
        });
      }
    },


    trendOptions: null,
    showingTrendingChart: Ember.computed.equal('trendSelection', 'trending'),

    _isPOVChart: Ember.computed.equal('graph.valueDimension.firstObject', 'power_of_voice'),
    _isPOVSentimentChart: Ember.computed.equal('graph.valueDimension.firstObject', 'power_of_voice_sent'),
    isPOVChart: Ember.computed.or('_isPOVChart', '_isPOVSentimentChart'),
    isSOVChart: Ember.computed.equal('graph.valueDimension.firstObject', 'share_of_voice'),

    trendSelection: Ember.computed('isPOVChart', 'isSOVChart', 'graph.graphType', function () {
      var graphType = this.get('graph.graphType');
      if (this.get('isPOVChart') || this.get('isSOVChart')) {
        if (graphType === 'graph-pie') {
          return 'snapshot';
        } else {
          return 'trending';
        }
      }
    }),

    showIntervalSelector: Ember.computed('graph.isByDate', 'isPOVChart', 'showingTrendingChart', function () {
      return this.get('graph.isByDate') || this.get('isPOVChart') && this.get('showingTrendingChart');
    }),

    showExpandChart: Ember.computed('isPOVChart', function () {
      return this.get('isPOVChart');
    }),

    showTrendSelector: Ember.computed('isPOVChart', 'isSOVChart', 'profile.hasPOV2UI', function () {
      return (this.get('isPOVChart') || this.get('isSOVChart')) && this.get('profile.hasPOV2UI');
    }),

    changeInterval: function changeInterval(interval) {
      var graph = this.get('graph');
      var graph2 = this.get('graph2');

      if (graph.get('title') && graph.get('generatedTitle') === graph.get('title').trim()) {
        graph.set('title');
      }
      graph.set('dateGrouping', interval);
      graph.notifyPropertyChange('dataQuery');
      if (this.get('graphExpanded')) {
        graph2.set('dateGrouping', interval);
        graph2.notifyPropertyChange('dataQuery');
      }
      this._saveGraph();
    },
    updateGraphType: function updateGraphType(type) {
      var graph = this.get('graph');
      var graph2 = this.get('graph2');
      if (type === 'trending') {
        graph.setProperties({
          'graphType': 'graph-column-stacked',
          'labelDimension': ['date'],
          'groupDimension': ['profile'],
          'dateGrouping': 'month'
        });
        if (graph2) {
          graph2.setProperties({
            'graphType': 'graph-column-stacked',
            'labelDimension': ['date'],
            'groupDimension': ['profile'],
            'dateGrouping': 'month'
          });
        }
      }

      if (type === 'snapshot') {
        graph.setProperties({
          'graphType': 'graph-pie',
          'labelDimension': ['profile'],
          'groupDimension': []
        });
        if (graph2) {
          graph2.setProperties({
            'graphType': 'graph-pie',
            'labelDimension': ['profile'],
            'groupDimension': []
          });
        }
      }
      graph.notifyPropertyChange('dataQuery');
      if (graph2) {
        graph2.notifyPropertyChange('dataQuery');
      }
      this._saveGraph();
    },


    exportToCSVData: Ember.computed.alias("graph.csvData"),

    _saveGraph: function _saveGraph() {
      if (this.get('graph.hasDirtyAttributes')) {
        return this.get('graph').save().then(function () {}, function (errors) {
          console.warn(errors);
        });
      }
    },
    downloadSVG: function downloadSVG() {
      (0, _svgToImage.svgToImage)(this.element);
    },


    setIsLoading: Ember.observer('IptcData.isLoading', function () {
      return this.set("isLoading", this.get('IptcData').getIsLoading());
    }),

    mainIptcTopics: Ember.observer('IptcData.ProfileSelectedIptcIds', function () {
      var result = this.get('IptcData').getProfileSelectedIptcIds();

      if (result) {
        var iptcs = result["top_level_iptc_topics_ids"].map(function (e) {
          return e.iptc_topic;
        });
        this.set("parentIptcTopics", iptcs);
      } else {
        this.set("parentIptcTopics", []);
      }
    }),

    actions: {
      customizeChart: function customizeChart() {
        this.toggleProperty('customizeChartOpen');
      },
      toggleInfo: function toggleInfo() {
        this.toggleProperty('showInfo');
      },
      changeInterval: function changeInterval(interval) {
        this.set('graph.dateGrouping', interval);
        this.changeInterval(interval);
      },
      trendSelect: function trendSelect(type) {
        this.updateGraphType(type.id);
      },
      toggleGraphExpanded: function toggleGraphExpanded() {
        this.toggleGraphExpanded();
        this.set('customizeChartOpen', false);
      },
      downloadGraph: function downloadGraph() {
        this.downloadSVG();
      }
    }
  });
});
define('client/models/term-count', ['exports', 'ember-data', 'client/utils/date-format'], function (exports, _emberData, _dateFormat2) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var TermCount = _emberData.default.Model.extend({
    term_value: _emberData.default.attr("string"),
    case_type: _emberData.default.attr("string"),
    case_type_cd: _emberData.default.attr("number"),
    profile_company_name: _emberData.default.attr("string"),
    profile_id: _emberData.default.attr("number"),
    user_name: _emberData.default.attr("string"),
    user_id: _emberData.default.attr("number"),
    created_at: _emberData.default.attr("isodate"),
    profile: _emberData.default.belongsTo("profile", { inverse: 'term_counts' }),

    dateAdded: Ember.computed('created_at', function () {
      var options = {
        format: 'MMM DD, YYYY'
      };
      return (0, _dateFormat2.default)(this.get('created_at'), options);
    })
  });
  TermCount.pathForType = "term_counts";

  exports.default = TermCount;
});
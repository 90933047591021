define("client/routes/plan/neo/buckets/new", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    model: function model() {
      return this.store.createRecord("profile-message-bucket", {
        profile: this.get("profileService.profile")
      });
    },

    actions: {
      willTransition: function willTransition(transition) {
        this.controller.set('error', null);
      }
    }
  });
});
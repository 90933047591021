define('client/components/common/ui-sidebar', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['ui-sidebar'],
    classNameBindings: ['visible::ui-sidebar-hide'],
    visible: false,

    actions: {
      close: function close() {
        this.set('visible', false);
      }
    }
  });
});
define("client/models/search", ["exports", "ember-data"], function (exports, _emberData) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var Search = _emberData.default.Model.extend({
    //
    // POSTING attributes
    //

    // global filters
    message_id: _emberData.default.attr("number"),
    profile_id: _emberData.default.attr("number"),
    campaign_id: _emberData.default.attr("number"),
    from: _emberData.default.attr("isodatetime"),
    to: _emberData.default.attr("isodatetime"),
    sort: _emberData.default.attr("string"),
    relevance: _emberData.default.attr("boolean"),
    // sort by interaction: count v %
    useInteractionCount: _emberData.default.attr('boolean'),

    // filters
    filters: _emberData.default.attr("array"),
    search_term: _emberData.default.attr("string"),
    search_type: _emberData.default.attr("string"),
    full_text: _emberData.default.attr("boolean")

  });

  exports.default = Search;
});
define('client/routes/reports/show', ['exports', 'client/utils/reports/errors'], function (exports, _errors) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    session: Ember.inject.service(),
    ajax: Ember.inject.service(),
    modal: Ember.inject.service(),
    titleToken: null,

    model: function model(params) {
      var report = this.store.peekRecord("report", params.id);
      if (Ember.isPresent(report)) {
        report.resetReport();
      }
      //we need to reload from the server so that we lock it
      return this.store.findRecord("report", params.id, { reload: true });
    },
    afterModel: function afterModel(model) {
      return model.get('profile').then(function (profile) {
        // check for campaign
        return profile.get("profile") || profile;
      }).then(function (profile) {
        return Ember.RSVP.all([profile.get('soundbytes'), profile.get('tags'), profile.get('whitelistTags')]);
      });
    },


    setupController: function setupController(controller, model) {
      var _this = this;

      // Make sure to clear all opened tools when entering this route
      controller.set('toolName', null);
      this._super(controller, model);
      model.on('becameError', function (internalModel) {
        // TODO: consolidate .touch() calls to singular action in
        // report/show controller so we can use promise callbacks for this
        if (internalModel.error && internalModel.error.errors[0].status === "409") {
          _this.get('modal').open('common/ui-confirm', {
            prompt: _errors.CONFLICT_ERROR_TEXT,
            showCancel: false,
            ok: "OK",
            okType: "danger"
          });
          model.resetReport();
        }
        model.rollbackAttributes();
      });
      this.set('titleToken', model.get('name'));
    },

    resetController: function resetController(controller) {
      controller.get('model').off('becameError');
      this._super.apply(this, arguments);
    },

    unlockReport: function unlockReport() {
      var model = this.controllerFor(this.routeName).get('model');
      if (+model.get('lockedById') !== +this.get('currentUser.id')) {
        return;
      }
      if (!this.get('session.isAuthenticated')) {
        return;
      }
      //not async as browser might cancel the ajax request as page is closing
      this.get('ajax').post('/v4/api/reports/' + model.get('id') + '/unlock', {
        async: false
      });
    },


    bindUnload: Ember.on('activate', function () {
      Ember.$(window).bind("beforeunload", this.unlockReport.bind(this));
    }),

    unbindUnload: Ember.on('deactivate', function () {
      Ember.$(window).unbind("beforeunload", this.unlockReport.bind(this));

      this.unlockReport();
      if (this.get("reportService.isReadOnly")) {
        this.set("reportService.isReadOnly", false);
      }
    }),

    actions: {
      error: function error(reason, transition) {
        console.log(reason);
        this.transitionTo("not-found");
        this.set("appState.inError", false);
      }
    }
  });
});
define('client/components/plan/campaigns/campaign-volume-selector', ['exports', 'client/utils/campaign-helper'], function (exports, _campaignHelper) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    profileService: Ember.inject.service('profile'),

    VOLUME_OPTIONS: _campaignHelper.VOLUME_OPTIONS,
    whitelistTags: null,
    currentWhitelistTags: null,

    profile: Ember.computed.alias('profileService.profile'),
    selectedWhitelistTags: Ember.computed.filterBy('whitelistTags', 'checked'),

    selectAllIndeterminate: Ember.computed('selectedWhitelistTags.[]', function () {
      var selectedAmount = this.get('selectedWhitelistTags.length');
      var totalAmount = this.get('whitelistTags.length');

      if (selectedAmount === 0) {
        return null;
      }

      if (selectedAmount === totalAmount) {
        return null;
      }

      if (selectedAmount < totalAmount) {
        return selectedAmount !== totalAmount;
      }
    }),
    selectAll: Ember.computed('selectedWhitelistTags.[]', {
      get: function get(key) {
        return this.get('selectedWhitelistTags.length') === this.get('whitelistTags.length');
      },
      set: function set(key, value) {
        return value;
      }
    }),

    init: function init() {
      this._super.apply(this, arguments);
      var whitelistTags = [];
      var currentWhitelistTags = this.get('currentWhitelistTags');
      (this.get('profile.defaultFilterSet.whitelistTags') || []).forEach(function (tag) {
        whitelistTags.push(tag);
      });
      if (currentWhitelistTags) {
        whitelistTags.setEach('checked', false);
        //only some are selected
        currentWhitelistTags.forEach(function (tag) {
          var foundTag = whitelistTags.findBy('id', tag.get('id'));
          if (foundTag) {
            foundTag.set('checked', true);
          }
        });
      } else {
        //all are selected
        whitelistTags.setEach('checked', true);
      }

      this.set('whitelistTags', whitelistTags);
    },
    updatedWhitelistTags: function updatedWhitelistTags() {},


    actions: {
      updateWhitelistTag: function updateWhitelistTag(tag) {
        tag.toggleProperty('checked');
        this.get('updatedWhitelistTags')(this.get('selectedWhitelistTags'));
      },
      selectAll: function selectAll() {
        this.get('whitelistTags').setEach('checked', this.get('selectAll'));
        this.get('updatedWhitelistTags')(this.get('selectedWhitelistTags'));
      }
    }
  });
});
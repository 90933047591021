define('client/components/reports/chart-base-widget', ['exports', 'client/components/reports/base-widget', 'client/mixins/report-chart-color-ordered', 'client/utils/twitter-settings', 'js-base64', 'client/utils/svg-to-image', 'moment'], function (exports, _baseWidget, _reportChartColorOrdered, _twitterSettings, _jsBase, _svgToImage, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) {
    return typeof obj;
  } : function (obj) {
    return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
  };

  var SIZES = [{
    name: "large",
    displayName: "Large",
    rowspan: 4,
    colspan: 4
  }, {
    name: "medium",
    displayName: "Medium",
    rowspan: 3,
    colspan: 4
  }, {
    name: "small",
    displayName: "Small",
    rowspan: 2,
    colspan: 2
  }];
  exports.default = _baseWidget.default.extend(_reportChartColorOrdered.default, {
    classNames: ["report-chart-base"],

    editComponentName: "reports/edit-chart",

    promiseProxy: Ember.computed.alias("item.info"),
    data: Ember.computed.alias("item.info.content"),

    // options definition
    hasGroupDimension: Ember.computed.notEmpty('groupDimension'),
    hasLabelDimension: Ember.computed.notEmpty('labelDimension'),

    groupOptionsMenuName: Ember.computed.alias("item.groupOptionsMenuName"),
    groupDimension: Ember.computed.alias("item.groupDimension"),

    // FROM PROFILE OPTIONS
    profile: Ember.computed.alias("item.profile"),
    profileFilterList: Ember.computed.alias("item.profileFilterList"),
    profileOptions: Ember.computed.alias("item.profileOptions"),
    profileNameOverrides: Ember.computed.alias("item.profileNameOverrides"),
    profileAndCampaignId: Ember.computed.alias("item.profileAndCampaignId"),
    profileName: Ember.computed.alias("item.profileName"),

    // OTHERS

    labelOptionsMenuName: 'Horizontal Axis',
    valueOptionsMenuName: 'Vertical Axis',
    sizes: SIZES,
    hasHorizontalLabel: true,
    customHorizontalLabel: Ember.computed.alias("item.customHorizontalLabel"),
    hasVerticalLabel: true,
    customVerticalLabel: Ember.computed.alias("item.customVerticalLabel", ""),
    showDataPoints: Ember.computed.alias("item.showDataPoints"),
    showDataValues: Ember.computed.alias("item.showDataValues"),
    autoAnnotate: Ember.computed.alias("item.autoAnnotate"),
    chartDataOrder: Ember.computed.alias("item.chartDataOrder"),
    addAverableLine: Ember.computed.alias("item.addAverableLine"),
    hideBlankCategories: Ember.computed.alias("item.hideBlankCategories"),
    chartTitle: Ember.computed.alias("item.chartTitle"),

    // Override this with a mash of options that you would like to hide for any
    // given chart. E.g., for report-chart-pie we like to hide any percent based
    // metric, so hideValueOptions: {interaction: true} is useful
    // Can also be null, meaning show all
    hideValueOptions: null,

    canAddOtherProfiles: true,

    showDataPointsCheckbox: false,

    legendNameOrderMap: Ember.computed('colorsByGroupDimension', 'groupDimension.firstObject', {
      get: function get() {
        if (this.get('groupDimension.firstObject') === 'tone_label') {
          // Tone is the only one that doesn't follow the color
          // order
          return {
            'Positive': 0,
            'Neutral': 1,
            'Negative': 2
          };
        }
        return this.get('colorsByGroupDimension');
      }
    }),

    // Maximum number of data values to show check box for data values
    // Charts that don't want to ever show this checkbox can set the threshold to
    // a negative number
    showDataValuesCheckboxThreshold: 19,
    showDataValuesCheckbox: Ember.computed('data.length', 'showDataValuesCheckboxThreshold', function () {
      return this.get('data.length') < this.get('showDataValuesCheckboxThreshold');
    }),
    // Need this here because if there number of data points changes the ability to see the
    // data values I don't want to change the users options, so instead this is the actual
    // show/hide data values option that will go to the graph
    showDataValuesForGraph: Ember.computed.and('showDataValues', 'showDataValuesCheckbox'),

    showDataInsights: Ember.computed.or('showDataValuesCheckbox', 'showDataPointsCheckbox'),

    horizontalLabel: Ember.computed('customHorizontalLabel', 'labelDimension.[]', 'calculateLabelName', function () {
      return this.get('calculateLabelName')('customHorizontalLabel', 'labelDimension');
    }),

    verticalLabel: Ember.computed('customVerticalLabel', 'item.info.apiValues.[]', 'calculateLabelName', function () {
      return this.get('calculateLabelName')('customVerticalLabel', 'item.info.apiValues');
    }),

    calculateLabelName: Ember.computed('dimensionNameOverride', function () {
      var _this = this;

      var _dimensionNameOverride = this.get('dimensionNameOverride');
      return function (customLabel, dimension) {
        var custom = _this.get(customLabel);
        if (!Ember.isEmpty(custom)) {
          return custom;
        }
        var name = _this.get(dimension + '.firstObject');
        if (Ember.isEmpty(name)) {
          return '';
        }
        if (_dimensionNameOverride[name]) {
          name = _dimensionNameOverride[name];
        }
        return name.split("_").join(" ").toUpperCase();
      };
    }),

    // all charts at least have these dimensions
    labelDimension: Ember.computed.alias("item.labelDimension"),

    valueDimension: Ember.computed.alias("item.valueDimension"),

    drilldownDimension: Ember.computed.readOnly('labelDimension.firstObject'),
    drilldownValue: Ember.computed.readOnly('valueDimension.firstObject'),
    groupDimensionValue: Ember.computed.readOnly('groupDimension.firstObject'),

    valueFormatType: Ember.computed('valueDimension', 'showAmplificationValue', 'showInteractionValue', 'coverageAggType', 'toneAggType', 'amplificationAggType', 'impactScoreAggType', 'scoreAggType', 'reachAggType', 'readershipAggType', 'visitorAggType', 'useInteractionCount', 'revenueAggType', 'formulaicRevenueAggType', 'powerOfVoiceAggType', 'powerOfVoiceSentAggType', function () {
      var valueDimension = this.get('valueDimension.firstObject');
      // when labelDimension is amplification, override valueDimension to
      // also be amplification.  Same with interaction.
      if (this.get('showAmplificationValue')) {
        valueDimension = 'amplification';
      } else if (this.get('showInteractionValue')) {
        valueDimension = 'interaction';
      }
      if (valueDimension === 'interaction') {
        // interaction can be count or %
        return this.get('useInteractionCount') ? 'count' : 'percent';
      } else if (valueDimension === 'score') {
        return 'score';
      } else if (valueDimension === 'revenue' || valueDimension === 'formulaic_revenue') {
        return 'money';
      } else if (valueDimension === 'power_of_voice') {
        return 'percent';
      } else {
        var aggType = this.get(valueDimension + 'AggType');
        if (aggType === 'avg') {
          return 'float';
        }
        return 'count';
      }
    }),

    // date config as radio
    dateDimGrouping: Ember.computed.alias("item.dateDimGrouping"),
    dateDimCustomGrouping: Ember.computed.alias("item.dateDimCustomGrouping"),

    // --- CATEGORICAL as checkboxes
    // Starts dynamic: Tag, Soundbytes
    // Add UI for profiles

    // media type config
    channelTypeFilterList: Ember.computed.alias("item.channelTypeFilterList"),

    // If interaction is chosen as the value dimension (and not also as a metric dimension),
    // the label becomes that of the chosen interaction type.
    interactionDimensionNameOverride: Ember.computed('valueInteractionFilters', 'interactionLabelOptions', 'useInteractionCount', 'profile.nameTotalInteraction', function () {
      var valueInteractionType = this.get('valueInteractionFilters.firstObject');
      // interaction is value dimension
      if (!Ember.isEmpty(valueInteractionType)) {
        var option = this.get('interactionLabelOptions').findBy('id', valueInteractionType);
        if (!Ember.isEmpty(option)) {
          return option.name;
        }
      }
      // interaction is metric dimension
      return this.get('useInteractionCount') ? this.get('profile.nameTotalInteraction') + ' Count' : this.get('profile.nameTotalInteraction') + ' Percentage';
    }),

    useInteractionCount: Ember.computed.equal('interactionAggType', 'sum'),

    // If amplification is chosen as the value dimension (and not also as a metric dimension),
    // the label becomes that of the chosen amplification type.
    amplificationDimensionNameOverride: Ember.computed('valueAmplificationFilters', 'amplificationLabelOptions', function () {
      var valueAmplificationType = this.get('valueAmplificationFilters.firstObject');
      // amplification is value dimension
      if (!Ember.isEmpty(valueAmplificationType)) {
        var option = this.get('amplificationLabelOptions').findBy('id', valueAmplificationType);
        return option ? option.name : 'Amplification';
      }
      // amplification is metric dimension
      return 'Amplification';
    }),

    // If we wish to have a custom name for any dimension (value or label)
    dimensionNameOverride: Ember.computed('profile.nameCustomer', 'interactionDimensionNameOverride', 'amplificationDimensionNameOverride', 'profile.nameReadership', 'profile.nameRevenue', 'profile.nameFormulaicRevenue', 'profile.nameInteraction', function () {
      return {
        'readership': this.get('profile.nameReadership'),
        'visitor': this.get('profile.nameCustomer'),
        'interaction': this.get('interactionDimensionNameOverride'),
        'amplification': this.get('amplificationDimensionNameOverride'),
        'revenue': this.get('profile.nameRevenue'),
        'formulaic_revenue': this.get('profile.nameFormulaicRevenue'),
        'target_media_outlets': 'Target Media Lists',
        'content_type': 'Publisher Type',
        'score': 'Onclusive Score',
        'impact_score': this.get("profile.nameAverageImpactScore"),
        'amplification_label': 'Amplification Type',
        'interaction_label': this.get('profile.nameInteraction') + ' Type',
        'tone_label': this.get("profile.nameSentiment")
      };
    }),

    channelTypeOptions: Ember.computed.alias("item.channelTypeOptions"),

    // coverage type config
    contentTypeFilterList: Ember.computed.alias("item.contentTypeFilterList"),

    contentTypeOptions: Ember.computed.alias("item.contentTypeOptions"),

    // volume config
    volumeTypeFilterList: Ember.computed.alias("item.volumeTypeFilterList"),

    volumeTypeOptions: Ember.computed.alias("item.volumeTypeOptions"),

    // tone label config
    toneLabelFilterList: Ember.computed.alias("item.toneLabelFilterList"),

    toneLabelOptions: Ember.computed.alias("item.toneLabelOptions"),

    // tags config
    tagFilterList: Ember.computed.alias("item.tagFilterList"),

    tagOptions: Ember.computed.alias("item.tagOptions"),

    // locations config
    locationFilterList: Ember.computed.alias("item.locationFilterList"),
    locationDefaultOptions: Ember.computed.alias("item.combinedLocationFilter"),

    locationDefaultFilterMenu: Ember.computed.alias("item.locationDefaultFilterMenu"),
    countrySelectedInLocationMenu: Ember.computed.alias("item.countrySelectedInLocationMenu"),

    locationOptions: Ember.computed.alias("item.locationOptions"),

    //don't went to set locationFilterListFilterMenu on initial
    //chart load, which is what this flag is set for.
    initialProfileSet: false,

    // soundbytes config
    neoFilterList: Ember.computed.alias("item.neoFilterList"),

    neoOptions: Ember.computed.alias("item.neoOptions"),

    // Show amplification value factor if we have amplification as either group or label
    showAmplificationValue: Ember.computed.alias("item.showAmplificationValue"),

    valueAmplificationSelection: Ember.computed.alias("item.valueAmplificationSelection"),

    valueAmplificationOptions: Ember.computed('amplificationLabelOptions', function () {
      // total should be first as default option
      return [{
        id: 'total_count',
        name: 'Total Amplification'
      }].concat(this.get('amplificationLabelOptions'));
    }),

    // These only get set when we have selected amplification as a value directly
    // and not as a label/group
    valueAmplificationFilters: Ember.computed('valueAmplificationSelection', 'showAmplificationValue', 'valueDimension', function () {
      // As long as we did not pick amplification as a label and the value dim
      // is amplification then show the amplification selected in the value dropdown
      if (!this.get('showAmplificationValue') && this.get('valueDimension.firstObject') === 'amplification') {
        return [this.get('valueAmplificationSelection')];
      }
      return null;
    }),

    amplificationLabelFilterList: Ember.computed.alias("item.amplificationLabelFilterList"),

    showTwitterDisclaimer: Ember.computed('amplificationLabelFilterList.[]', 'showAmplificationValue', 'fromDate', function () {
      var filterList = this.get('amplificationLabelFilterList');
      return (filterList.includes('twitter_count') || filterList.includes('total_count')) && this.get('showAmplificationValue') && this.get('fromDate') < _twitterSettings.default.GNIP_START_DATE;
    }),

    amplificationLabelOptions: Ember.computed.alias("item.amplificationLabelOptions"),

    originalTotalAmplificationCount: 0,

    // Show interaction value factor if we have interaction as either group or label
    showInteractionValue: Ember.computed.alias("item.showInteractionValue"),

    valueInteractionSelection: Ember.computed.alias("item.valueInteractionSelection"),

    // These only get set when we have selected interaction as a value directly
    // and not as a label/group
    valueInteractionFilters: Ember.computed.alias("item.valueInteractionFilters"),

    valueInteractionOptions: Ember.computed('interactionLabelOptions', 'profile.nameTotalInteraction', function () {
      // total should be first as default option
      return [{
        id: 'total_interaction',
        name: this.get('profile.nameTotalInteraction')
      }].concat(this.get('interactionLabelOptions'));
    }),

    interactionLabelFilterList: Ember.computed.alias("item.interactionLabelFilterList"),

    interactionLabelOptions: Ember.computed.alias("item.interactionLabelOptions"),

    // campaign config
    campaignFilterList: Ember.computed.alias("item.campaignFilterList"),

    campaignOptions: Ember.computed.alias("item.campaignOptions"),

    // --- NUMERIC as radio

    // coverage config
    coverageAggType: Ember.computed.alias("item.coverageAggType"),

    // tone config (want to have avg be the default setting for tone)
    toneAggType: Ember.computed.alias("item.toneAggType"),

    // amplification config
    amplificationAggType: Ember.computed.alias("item.amplificationAggType"),

    // impact score config
    impactScoreAggType: Ember.computed.alias("item.impactScoreAggType"),

    // score config
    scoreAggType: Ember.computed.alias("item.scoreAggType"),

    // reach config
    reachAggType: Ember.computed.alias("item.reachAggType"),

    // readership config
    readershipAggType: Ember.computed.alias("item.readershipAggType"),

    // potential customers config
    visitorAggType: Ember.computed.alias("item.visitorAggType"),

    // interaction config
    interactionAggType: Ember.computed.alias("item.interactionAggType"),

    // revenue config
    revenueAggType: Ember.computed.alias("item.revenueAggType"),

    // formulaic revenue config
    formulaicRevenueAggType: Ember.computed.alias("item.formulaicRevenueAggType"),

    // power of voice config
    powerOfVoiceAggType: Ember.computed.alias("item.powerOfVoiceAggType"),

    powerOfVoiceSentAggType: Ember.computed.alias("item.powerOfVoiceSentAggType"),
    // The following is a bit of a hack to add a bunch of the same filter types
    // found in the label dimensions, but instead these are for the label menus
    // Not sure what the right architecture should be...

    // media type config
    channelTypeFilterListFilterMenu: Ember.computed.alias("item.channelTypeFilterListFilterMenu"),

    // coverage type config
    contentTypeFilterListFilterMenu: Ember.computed.alias("item.contentTypeFilterListFilterMenu"),

    // volume config
    volumeTypeFilterListFilterMenu: Ember.computed.alias("item.volumeTypeFilterListFilterMenu"),

    // tags config
    tagFilterListFilterMenu: Ember.computed.alias("item.tagFilterListFilterMenu"),

    locationFilterListFilterMenu: Ember.computed.alias("item.locationFilterListFilterMenu"),

    // Only items that are selected in filter menu are available
    channelTypeOptionsFiltered: Ember.computed('channelTypeOptions.[]', 'channelTypeFilterListFilterMenu.[]', function () {
      var listFilterMenu = this.get('channelTypeFilterListFilterMenu');
      var options = this.get('channelTypeOptions');
      return listFilterMenu.map(function (id) {
        return options.findBy('id', id);
      }).filter(function (id) {
        return id;
      });
    }),

    contentTypeOptionsFiltered: Ember.computed('contentTypeOptions.[]', 'contentTypeFilterListFilterMenu.[]', function () {
      var listFilterMenu = this.get('contentTypeFilterListFilterMenu');
      var options = this.get('contentTypeOptions');
      return listFilterMenu.map(function (id) {
        return options.findBy('id', id);
      }).filter(function (id) {
        return id;
      });
    }),

    volumeTooltipLabel: Ember.computed('item.selectedFilterSet.influential', 'item.selectedFilterSet.uninfluential', function () {
      var influential = this.get('item.selectedFilterSet.influential'),
          uninfluential = this.get('item.selectedFilterSet.uninfluential');
      if (influential && uninfluential) {
        return 'Comprehensive';
      } else if (influential && !uninfluential) {
        return 'Influential & Target Media Lists';
      } else {
        return 'Target Media Lists Only';
      }
    }),

    tagOptionsFiltered: Ember.computed('tagOptions.[]', 'tagFilterListFilterMenu.[]', function () {
      var listFilterMenu = this.get('tagFilterListFilterMenu');
      var options = this.get('tagOptions');
      return listFilterMenu.map(function (id) {
        return options.findBy('id', id);
      }).filter(function (id) {
        return id;
      });
    }),

    exportToCSVData: Ember.computed("data", "item.info.labels", function () {
      var graphData = this.get("data"),
          labels = this.get("item.info.labels");

      if (!labels) {
        return null;
      }

      var csv = graphData.map(function (data) {
        return data.labels.concat([data.values]).join(",");
      });

      csv.unshift(labels.concat(["value"]).join(","));

      return "data:text/csv;base64," + _jsBase.Base64.encode(csv.join("\n") || "");
    }),

    exportImage: function exportImage() {
      var reportContent = this.$().parents(".report-content");
      var scale = +reportContent.css("transform").match(/matrix\(((\d|\.)*)/)[1];
      (0, _svgToImage.svgToImage)(this.element.parentNode.querySelector('.graph-viewport'), 2, scale);
    },


    actions: {
      clickDrilldown: function clickDrilldown(info, point, hasOneNoneZero, legendItems) {
        if (hasOneNoneZero === false) {
          return;
        }
        var drilldownValue = this.get('drilldownValue');
        var valueAmplificationSelection = this.get('valueAmplificationSelection');
        var valueInteractionSelection = this.get('valueInteractionSelection');
        var group = this.get('groupDimensionValue');
        var dimension = this.get('drilldownDimension');
        var fromLabelMap = this.get('item.info.labelParseMap.fromLabel')[dimension];
        var moreUsefulInfo = dimension === 'date' ? fromLabelMap(this.get('dateDimGrouping'), info) : fromLabelMap(info);

        if ((typeof moreUsefulInfo === 'undefined' ? 'undefined' : _typeof(moreUsefulInfo)) === "object") {
          moreUsefulInfo.dimension = dimension;
          moreUsefulInfo.group = group;
          moreUsefulInfo.drilldownValue = drilldownValue;
          moreUsefulInfo.valueAmplificationSelection = valueAmplificationSelection;
          moreUsefulInfo.valueInteractionSelection = valueInteractionSelection;
          moreUsefulInfo.point = point;
          moreUsefulInfo.value = info;
        } else {
          moreUsefulInfo = {
            dimension: dimension,
            group: group,
            drilldownValue: drilldownValue,
            valueAmplificationSelection: valueAmplificationSelection,
            valueInteractionSelection: valueInteractionSelection,
            point: point,
            value: moreUsefulInfo
          };
        }

        if (group === 'date') {
          moreUsefulInfo.graphDateBinType = this.get('graphDateBinType');
        }
        if (this.get('dateDimGrouping') === 'Day' && dimension === "date") {
          //Force drilling in to one day
          moreUsefulInfo.to = (0, _moment.default)(moreUsefulInfo.from).add(1, 'day').subtract(1, 'minute').toDate();
        }

        if (group === 'interaction_label') {
          moreUsefulInfo.interactionLabelOptions = this.get('interactionLabelOptions');
        }

        var reportService = this.get("reportService");
        reportService.set('chartApiFirstSelection', info);
        reportService.set('chartDimension', moreUsefulInfo);

        var apiHash = this.get('item.info.apiHash');
        if (legendItems) {
          apiHash.legendItems = legendItems;
        }
        reportService.set("chartApiHash", apiHash);
      },
      locationFiltersChanged: function locationFiltersChanged(filters) {
        this.set("locationFilterListFilterMenu", Object.keys(filters));
        this.send("saveItem");
      }
    }
  });
});
define("client/components/color-swatch-picker", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var COLORS = ["#000000", // black
  "#FFFFFF", // white
  "#555555", "#BBBBBB", // gray
  "#FF0000", // red
  "#E06666", "#FF8C00", // orange
  "#F6B26B", "#FFFF00", // yellow
  "#FFD966", "#00FF00", // green
  "#93C47D", "#00FFFF", // cyan
  "#76A5AF", "#0000FF", // blue
  "#6FA8DC", "#9900FF", // purple
  "#8E7CC3", "#FF00FF", // magenta
  "#C27BA0"];

  var ColorSwatchPickerComponent = Ember.Component.extend({
    colors: COLORS,
    onColorPick: function onColorPick() {},

    classNames: ['color-swatch-picker'],

    actions: {
      changeColor: function changeColor(index, color) {
        this.onColorPick(index, color);
      }
    }
  });

  exports.default = ColorSwatchPickerComponent;
});
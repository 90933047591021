define('client/components/checkbox-group', ['exports', 'client/computed/index'], function (exports, _index) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['checkbox-group-container'],

    classNameBindings: ['isOpen:open'],

    title: '',

    value: '',

    childrenValuePath: 'value',

    useGroupPrefix: false,

    isOpen: false,

    updateGroup: undefined,

    update: function update() {},
    updateChild: function updateChild() {},


    disabled: false,

    children: (0, _index.arrayProperty)(),

    selectedValues: (0, _index.arrayProperty)(),

    selectedValuesHash: Ember.computed('selectedValues.[]', 'useGroupPrefix', function () {
      return (this.get('selectedValues') || []).reduce(function (hash, selectedValue) {
        hash[selectedValue] = true;
        return hash;
      }, {});
    }),

    checked: Ember.computed('children.[]', 'selectedValues.[]', function () {
      var childrenValues = this.get('children').mapBy(this.get('childrenValuePath'));
      var selectedValues = this.get('selectedValues');
      if (!selectedValues) {
        return false;
      }
      return childrenValues.every(function (id) {
        return selectedValues.indexOf(id) >= 0;
      });
    }),

    indeterminate: Ember.computed('checked', 'selectedValues.[]', function () {
      var childrenValues = this.get('children').mapBy(this.get('childrenValuePath'));
      var selectedValues = this.get('selectedValues');
      if (this.get('checked') || !selectedValues) {
        return false;
      }
      return childrenValues.any(function (id) {
        return selectedValues.indexOf(id) >= 0;
      });
    }),

    actions: {
      toggleOpen: function toggleOpen() {
        if (!this.get('disabled')) {
          this.toggleProperty('isOpen');
        }
      },
      update: function update(evt, checked, value) {
        this.update(evt, checked, value);
      },
      updateChild: function updateChild(evt, checked, value) {
        value = this._formatValue(value);
        this.updateChild(evt, checked, value);
      }
    },

    // used to mimic hash API pattern for yielding bound (closure) actions to
    // the provided block
    // TODO: replace with hash helper
    groupActions: Ember.computed(function () {
      var actions = this._actions;
      return {
        updateChild: actions.updateChild.bind(this)
      };
    }),

    _formatValue: function _formatValue(value) {
      var groupValue = this.get('value');
      return this.get('useGroupPrefix') ? groupValue + ':' + value : value;
    }
  });
});
define('client/utils/filters/reporting-adapter', ['exports', 'client/utils/filters/profile-options', 'client/utils/filters/base-adapter'], function (exports, _profileOptions, _baseAdapter) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _baseAdapter.default.extend(_profileOptions.default, {
    filterSet: null,
    profile: null,
    campaign: null,

    searchTerm: Ember.computed.alias("filterSet.searchTerm"),
    otherProfiles: Ember.computed.alias("filterProfileOptions.otherProfiles"),
    syndicated: Ember.computed.alias("filterSet.syndicated"),
    followOn: Ember.computed.alias("filterSet.followOn"),
    excludeSyndicateArticles: Ember.computed.alias("filterSet.excludeSyndicateArticles"),
    hideLongTail: Ember.computed.alias('filterSet.hideLongTail'),
    languages: Ember.computed.alias("filterSet.languages"),
    channelTypeFilterList: Ember.computed("filterSet.earned", "filterSet.owned", "filterSet.pressRelease", function () {
      var filters = [];
      if (this.get('filterSet.earned')) {
        filters.pushObject('earned');
      }
      if (this.get('filterSet.owned')) {
        filters.pushObject('owned');
      }
      if (this.get('filterSet.pressRelease')) {
        filters.pushObject('press_release');
      }
      return filters;
    }),
    contentTypeFilterList: Ember.computed("filterSet.article", "filterSet.linkedinPulse", "filterSet.twitter", "filterSet.googlePlus", "filterSet.television", "filterSet.radio", "filterSet.podcast", "filterSet.youtube_bc", "filterSet.printPublication", function () {
      var filters = [];
      if (this.get('filterSet.article')) {
        filters.pushObject('article');
      }
      if (this.get('filterSet.linkedinPulse')) {
        filters.pushObject('linkedin_pulse');
      }
      if (this.get('filterSet.twitter')) {
        filters.pushObject('twitter');
      }
      if (this.get('filterSet.googlePlus')) {
        filters.pushObject('google_plus');
      }
      if (this.get('filterSet.television')) {
        filters.pushObject('television');
      }
      if (this.get('filterSet.radio')) {
        filters.pushObject('radio');
      }
      if (this.get('filterSet.podcast')) {
        filters.pushObject('podcast');
      }
      if (this.get('filterSet.youtube_bc')) {
        filters.pushObject('youtube_bc');
      }
      if (this.get('filterSet.printPublication')) {
        filters.pushObject('print_publication');
      }
      return filters;
    }),

    toneLabelFilterList: Ember.computed("filterSet.positive", "filterSet.neutral", "filterSet.negative", function () {
      var filters = [];

      if (this.get('filterSet.positive')) {
        filters.pushObject('positive');
      }
      if (this.get('filterSet.neutral')) {
        filters.pushObject('neutral');
      }
      if (this.get('filterSet.negative')) {
        filters.pushObject('negative');
      }

      return filters;
    }),

    amplificationLabelFilterList: Ember.computed("filterSet.facebookCount", "filterSet.twitterCount", "filterSet.linkedinCount", "filterSet.gplusCount", "filterSet.pinterestCount", function () {
      var filters = [];
      if (this.get('filterSet.facebookCount')) {
        filters.pushObject('facebook_count');
      }
      if (this.get('filterSet.twitterCount')) {
        filters.pushObject('twitter_count');
      }
      if (this.get('filterSet.linkedinCount')) {
        filters.pushObject('linkedin_count');
      }
      if (this.get('filterSet.gplusCount')) {
        filters.pushObject('gplus_count');
      }
      if (this.get('filterSet.pinterestCount')) {
        filters.pushObject('pinterest_count');
      }
      return filters;
    }),

    volumeTypeFilterList: Ember.computed("filterSet.influential", "filterSet.uninfluential", function () {
      var filters = [];
      if (this.get('filterSet.influential')) {
        filters.pushObject('influential');
      }
      if (this.get('filterSet.uninfluential')) {
        filters.pushObject('uninfluential');
      }
      return filters;
    }),

    neoFilterList: Ember.computed("filterSet.soundbytes.[]", "filterOptions.neoOptions.[]", function () {
      var filterSet = this.get("filterSet"),
          soundbytes = filterSet.get("soundbytes"),
          options = this.get("filterOptions.neoOptions");
      return options.mapBy("id").map(function (soundbyteId) {
        if (soundbytes.findBy("id", soundbyteId)) {
          return soundbyteId;
        }
        return null;
      }).compact();
    }),

    interactionLabelFilterList: Ember.computed("filterSet.interactionMetrics.[]", function () {
      var filterSet = this.get("filterSet"),
          interactionMetrics = filterSet.get("interactionMetrics");
      return interactionMetrics;
    }),

    campaignFilterList: Ember.computed("filterSet.campaigns.[]", "filterOptions.campaignOptions", function () {
      var filterSet = this.get("filterSet"),
          campaigns = filterSet.get("campaigns"),
          options = this.get("filterOptions.campaignOptions");
      return options.mapBy("id").map(function (campaignId) {
        if (campaigns.findBy("id", campaignId)) {
          return campaignId;
        }
        return null;
      }).compact();
    }),

    whitelistTagsFilterList: Ember.computed("filterSet.whitelistTags.[]", "filterOptions.whitelistTagOptions.[]", function () {
      var filterSet = this.get("filterSet"),
          whitelistTags = filterSet.get("whitelistTags");

      var tags = whitelistTags.sortBy('name').mapBy("id");

      if (tags.length === 0) {
        // needs to be undefined otherwise it includes an empty whitelist array in the query
        return undefined;
      }
      return tags;
    })
  });
});
define('client/models/report-items/profile-config', ['exports', 'client/utils/check-profile-header'], function (exports, _checkProfileHeader) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var PARENS_REGEX = /^\s*\(.*\)\s*$/;

  var ProfileConfig = Ember.Object.extend({
    item: null,
    profile: Ember.computed({
      get: function get() {
        return this._getProfileOrCampaign();
      },
      set: function set(k, v) {
        this.set("profileId", v.get("profile_id") || v.get("id"));
        this.set("campaignId", v.get("campaign_id"));

        return this._getProfileOrCampaign();
      }
    }),

    mainSearchTerm: Ember.computed.reads('item.searchTerm'),

    _getProfileOrCampaign: function _getProfileOrCampaign() {
      var campaignId = this.get("campaignId"),
          profileId = this.get("profileId"),
          store = this.get("store");

      var profileOrCampaign = (0, _checkProfileHeader.checkProfileHeaderData)(store, profileId);

      if (campaignId) {
        // use the campaign as the profile
        profileOrCampaign = store.findRecord("campaign", campaignId);
      }
      return profileOrCampaign;
    },


    nonUniqueIdentifier: Ember.computed('profileId', 'campaignId', 'searchTerm', 'useCustomSearchTerm', function () {
      var id = { profileId: this.get('profileId').toString() };
      if (this.get('campaignId')) {
        id.campaignId = this.get('campaignId').toString();
      }
      if (this.get('useCustomSearchTerm') && Ember.isPresent(this.get('searchTerm'))) {
        id.searchTerm = this.get('searchTerm');
      } else {
        id.searchTerm = "";
      }
      return JSON.stringify(id);
    }),

    name: Ember.computed.alias('profile.name'),

    originalName: Ember.computed('name', 'useCustomSearchTerm', 'mainSearchTerm', 'searchTerm', function () {
      var searchTerm = this.get('useCustomSearchTerm') ? this.get('searchTerm') : this.get('mainSearchTerm');
      if (Ember.isPresent(searchTerm)) {
        if (!PARENS_REGEX.test(searchTerm)) {
          searchTerm = '(' + searchTerm + ')';
        }
        return this.get('name') + ' ' + searchTerm;
      } else {
        return this.get('name');
      }
    }),

    displayName: Ember.computed('originalName', 'nonUniqueIdentifier', 'nameOverride', function () {
      var override = this.get('nameOverride');
      return Ember.isPresent(override) ? override : this.get('originalName');
    }),

    isActive: true,

    articleRange: 0,
    socialRange: 0,
    searchTerm: "",
    filters: null,
    useCustomSearchTerm: false,
    tagFilters: null,
    locationFilters: null,
    toJSON: function toJSON() {
      return this.getProperties("articleRange", "socialRange", "searchTerm", "useCustomSearchTerm", "isActive", "filters", "nameOverride", "tagFilters", "locationFilters", "campaignId", "profileId");
    }
  });

  exports.default = ProfileConfig;
});
define('client/components/checkbox-selection', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNameBindings: [":checkbox-selection", "notValue:has-not-option", "isNotSet"],

    onCheck: function onCheck() {},
    change: function change() {},

    notValue: null,

    isNotSet: Ember.computed('selection.[]', 'value', 'notValue', function () {
      var selection = this.get("selection"),
          value = this.get("value"),
          notValue = this.get('notValue');
      var notValueKey = value + ':' + notValue;
      return selection.indexOf(notValueKey) >= 0;
    }),

    isSelected: Ember.computed("selection.[]", "value", {
      get: function get() {
        var selection = this.get("selection"),
            value = this.get("value");

        if (!selection) {
          return false;
        }
        var included = selection.indexOf(value) >= 0;
        return included;
      },
      set: function set(k, v) {
        var selection = this.get("selection"),
            value = this.get("value"),
            notValue = this.get('notValue');

        if (!selection) {
          return false;
        }

        var notValueKey = value + ':' + notValue;
        var isSettingNot = notValue && v === notValue;
        selection.removeObject(notValueKey);
        var included = selection.indexOf(value) >= 0;

        if (v && !included) {
          // add to selection
          if (isSettingNot) {
            selection.pushObject(notValueKey);
            this.onCheck(notValueKey);
          } else {
            selection.pushObject(value);
            this.onCheck(value);
          }

          if (this.get('checks')) {
            selection.addObjects(this.get('checks'));
          }

          included = true;
        } else if (isSettingNot || !v && included) {
          selection.removeObject(value);

          if (isSettingNot) {
            selection.pushObject(notValueKey);
            this.onCheck(notValueKey);
          }

          if (this.get('unchecks')) {
            selection.removeObjects(this.get('unchecks'));
          }
          included = false;
        }
        this.change();

        return isSettingNot ? notValue : included;
      }
    }),

    actions: {
      setNot: function setNot() {
        this.set('isSelected', this.get('isNotSet') ? false : this.get('notValue'));
      }
    }
  });
});
define('client/components/image-uploader', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNameBindings: [":image-upload", "uploading"],

    uploading: false,

    click: function click(e) {
      if (["INPUT", "DIV"].indexOf(e.target.nodeName) >= 0) {
        e.stopImmediatePropagation();
        return;
      }
      this.$("input").click();
    },
    setFileUploadForInput: function setFileUploadForInput() {
      var that = this;
      if (this.$("input")) {
        this.$("input").fileupload({
          pasteZone: null,
          dataType: 'json',
          url: '/v2/api/images',
          done: function done(e, data) {
            that.sendAction("uploaded", data.result);
            that.set("uploading", false);
          },
          submit: function submit() {
            that.set("uploading", true);
            return true;
          }
        });
      }
    },


    resetFileUpload: Ember.observer('uploading', function () {
      if (!this.get('uploading')) {
        Ember.run.scheduleOnce("afterRender", this, function () {
          this.setFileUploadForInput();
        });
      }
    }),

    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      this.setFileUploadForInput();
    }
  });
});
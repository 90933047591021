define('client/components/social-widget', ['exports', 'client/components/simple-widget', 'client/utils/twitter-settings'], function (exports, _simpleWidget, _twitterSettings) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var SocialWidgetComponent = _simpleWidget.default.extend({
    layoutName: "components/social-widget",
    onClick: function onClick() {},


    facebook: Ember.computed("content.facebook_count", function () {
      return this.get("content.facebook_count") || 0;
    }),

    twitter: Ember.computed("content.twitter_count", function () {
      return this.get("content.twitter_count") || 0;
    }),

    google: Ember.computed("content.gplus_count", function () {
      return this.get("content.gplus_count") || 0;
    }),

    linkedin: Ember.computed("content.linkedin_count", function () {
      return this.get("content.linkedin_count") || 0;
    }),

    pinterest: Ember.computed("content.pinterest_count", function () {
      return this.get("content.pinterest_count") || 0;
    }),

    linkedinIsPresent: Ember.computed.notEmpty('content.linkedin_count'),
    twitterIsPresent: Ember.computed.notEmpty('content.twitter_count'),
    facebookIsPresent: Ember.computed.notEmpty('content.facebook_count'),
    googleIsPresent: Ember.computed.notEmpty('content.gplus_count'),
    pinterestIsPresent: Ember.computed.notEmpty('content.pinterest_count'),

    hasInformation: Ember.computed.or("facebookIsPresent", "twitterIsPresent", "googleIsPresent", "linkedinIsPresent", "pinterestIsPresent"),

    title: "Social",

    totalAmplification: Ember.computed("linkedin", "twitter", "facebook", "google", "pinterest", function () {
      return this.get("linkedin") + this.get("google") + this.get("facebook") + this.get("twitter") + this.get("pinterest");
    }),

    countsEndDate: _twitterSettings.default.COUNTS_END_DATE,
    gnipStartDate: _twitterSettings.default.GNIP_START_DATE,

    actions: {
      contextFacebook: function contextFacebook() {
        this.onClick('facebook');
      },
      contextTwitter: function contextTwitter() {
        this.onClick('twitter');
      },
      contextGPlus: function contextGPlus() {
        this.onClick('gPlus');
      },
      contextLinkedIn: function contextLinkedIn() {
        this.onClick('linkedIn');
      },
      contextPinterest: function contextPinterest() {
        this.onClick('pinterest');
      }
    }
  });

  // WIDGET_VIEWS.social = SocialWidgetComponent ;
  exports.default = SocialWidgetComponent;
});
define('client/components/research/authors/filter-locations', ['exports', 'ember-concurrency'], function (exports, _emberConcurrency) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ["author-filter"],
    listIsOpen: false,
    refresh: null,
    previousRefresh: null,
    disabled: false,
    tooltipText: null,
    locationFilters: null,

    applyLocations: function applyLocations() {},
    loadLocationFilters: function loadLocationFilters() {},
    loadStateFilters: function loadStateFilters() {},
    init: function init() {
      this._super.apply(this, arguments);
      this.set('selectedLocations', Ember.A());
      this.set('selectedStates', Ember.A());
    },
    didReceiveAttrs: function didReceiveAttrs() {
      var _this = this;

      this._super.apply(this, arguments);
      if (Ember.isEmpty(this.get('selectedLocations'))) {
        var filters = this.get('locationFilters');
        if (filters) {
          filters.forEach(function (filter) {
            filter.set('isSelected', false);
          });
        }
      }

      if (this.refresh !== this.previousRefresh) {
        this.previousRefresh = this.refresh;
        if (this.listIsOpen) {
          Ember.run.later(function () {
            return _this.get('loadLocationFilters')();
          });
        }
      }
    },

    usaSelected: Ember.computed('selectedLocations.[]', function () {
      if (this.get('selectedLocations')) {
        return this.get('selectedLocations').some(function (loc) {
          return loc == "USA";
        });
      }
      return false;
    }),
    addLocations: function addLocations(filter) {
      var locations = this.get('selectedLocations');
      locations.addObject(filter.name);
      this.get('applyLocations')(locations);
    },
    removeLocations: function removeLocations(filter) {
      var locations = this.get('selectedLocations').filter(function (item) {
        return item !== filter.name;
      });
      if (filter.name == "USA") {
        this.resetStates();
      }
      this.get('applyLocations')(locations);
    },
    addStates: function addStates(filter) {
      var states = this.get('selectedStates');
      states.addObject(filter.name);
      this.get('applyStates')(states);
    },
    removeStates: function removeStates(filter) {
      var states = this.get('selectedStates').filter(function (item) {
        return item !== filter.name;
      });
      this.get('applyStates')(states);
    },
    resetStates: function resetStates() {
      var filters = this.get('stateFilters');
      if (filters) {
        filters.forEach(function (filter) {
          filter.set('isSelected', false);
        });
      }
      this.get('applyStates')([]);
    },


    actions: {
      onCancelTags: function onCancelTags() {
        this.set('searchTerm', null);
      },
      onToggleList: function onToggleList(open) {
        this.set("listIsOpen", open);
        if (open && Ember.isEmpty(this.get('locationFilters'))) {
          this.get('loadLocationFilters')();
        }
      },
      onToggleAll: function onToggleAll(type, action) {
        if (action == 'clearAll') {
          this.set('selectedLocations', []);
        }
      },
      onToggleFilter: function onToggleFilter(filter) {
        if (filter.isSelected) {
          if (filter.name == 'USA') {
            this.get('loadStateFilters')();
          }
          this.addLocations(filter);
        } else {
          this.removeLocations(filter);
        }
      },
      onToggleAllStates: function onToggleAllStates(type, action) {
        if (action == 'clearAll') {
          this.set('selectedStates', []);
        }
      },
      onToggleStateFilter: function onToggleStateFilter(filter) {
        if (filter.isSelected) {
          this.addStates(filter);
        } else {
          this.removeStates(filter);
        }
      }
    }
  });
});
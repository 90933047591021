define('client/models/report-items/tile', ['exports', 'client/models/report-item', 'client/components/location-filters', 'client/mixins/filter-controller'], function (exports, _reportItem, _locationFilters, _filterController) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _reportItem.default.extend({
    featureFlags: Ember.inject.service('feature-flags'),

    defaultLocationFilters: Ember.computed.alias('profile.locations'),

    filterList: Ember.computed.alias("selectedFilterSet.articleListAdapter"),
    filters: Ember.computed.alias("filterList.filters"), // rq
    tagFilterList: Ember.computed.alias("filterList.tagFilterList"), // rq
    locationFilterList: Ember.computed.alias("filterList.locationFilterList"), // rq

    searchTerm: Ember.computed.alias("selectedFilterSet.searchTerm"),
    locationFilters: Ember.computed.alias('selectedFilterSet.articleListAdapter.locationFilterList'),

    activeProfile: Ember.computed.alias("profile"),

    channelFiltersMenu: (0, _reportItem.aliasArrayDefault)('channelFilters', function () {
      return (0, _filterController.defaultChannelFilters)().filterBy("value").mapBy("name");
    }),

    defaultContentFilters: Ember.computed(function () {
      return (0, _filterController.defaultContentFilters)().filter(function (f) {
        return f.name == 'news' || f.name == 'linkedin_pulse';
      });
    }),

    contentFiltersMenu: (0, _reportItem.aliasArrayDefault)('contentFilters', function () {
      return this.get("defaultContentFilters").filterBy("value").mapBy("name");
    }),

    tagFiltersMenu: (0, _reportItem.aliasArrayDefault)('tagFilters', function () {
      return (0, _filterController.defaultTagFilters)(this.get("activeProfile")).filterBy("value").mapBy("name");
    }),

    amplificationFilters: (0, _reportItem.aliasArrayDefault)("amplificationFilters", function () {
      return (0, _filterController.defaultAmplificationFilters)();
    }),

    trendGrouping: "month",

    info: Ember.computed.debounce("profile_id", "campaign_id", "fromDate", "toDate", "searchTerm", 'allFilters.[]', 'tagFilters.[]', 'languages.[]', 'locationFilters', 'whitelistTagIds.[]', 'syndicated', 'followOn', 'relevance', 'hideLongTail', 'companyWebsiteLink', 'articleLinksContain', 'selectedFilterSet.isLoaded', function () {
      // check if that tile is still on the report.
      if (this.get("page") && this.get('selectedFilterSet.isLoaded')) {
        var query = this.queryByName("dashboard-tile", this.getProperties("endpoint", "queryParams"));
        return query.load();
      }
    }, 200)
  });
});
define("client/utils/reports/errors", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var CONFLICT_ERROR_TITLE = "This report is out of date.";
  var CONFLICT_ERROR_TEXT = "Please ensure this report is not open in another\n                             tab and refresh the page to get the latest version.";
  var GENERIC_ERROR_TITLE = "The report could not be saved. Please contact you PR engineer for assistance.";
  var SPECIFIC_ERROR_MESSAGE = "There were problems saving this report: \n";
  exports.CONFLICT_ERROR_TEXT = CONFLICT_ERROR_TEXT;
  exports.CONFLICT_ERROR_TITLE = CONFLICT_ERROR_TITLE;
  exports.GENERIC_ERROR_TITLE = GENERIC_ERROR_TITLE;
  exports.SPECIFIC_ERROR_MESSAGE = SPECIFIC_ERROR_MESSAGE;
});
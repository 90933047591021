define('client/components/reports/report-header', ['exports', 'client/components/reports/rich-text-header', 'client/utils/rich-text-fonts', 'bigtext'], function (exports, _richTextHeader, _richTextFonts, _bigtext) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _richTextHeader.default.extend({
    layoutName: 'components/reports/rich-text',
    header: Ember.computed.alias('item.content.header'),
    headerMax: 50,
    headerMin: 16,
    boldHeader: true,

    textOnlyHeader: Ember.computed('header', function () {
      return Ember.$(Ember.$.parseHTML(this.get('header'))).text();
    }),

    convertLegacyTextbox: function convertLegacyTextbox() {
      if (this.get('header')) {
        this.$().find('.legacy-header').bigtext({
          maxfontsize: 50,
          minfontsize: 9
        });
        var fontSize = this.$().find('.header-container').css('font-size');
        fontSize = parseFloat(fontSize) - 1 + 'px';
        var header = this.get('textOnlyHeader');
        var editor = this.get('editor');
        this.set('header', null);
        this.$().find('.rich-text-editor').select();
        editor.bold().setFontFace(_richTextFonts.default.HELVETICA.family).setFontSize(fontSize);
        editor.insertHTML(header);
        editor.setFontSize('12px');
        if (this.get('text')) {
          editor.moveCursorToEnd();
        }
        return true;
      }
      return false;
    }
  });
});
define('client/mixins/research/top-articles', ['exports', 'client/computed/index'], function (exports, _index) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.SORT_BY = undefined;


  //article list
  var SORT_BY = {
    "totalAmplificationByRelevance": 'Trending Content',
    // "fbTotalAmplificationByRelevance": 'Trending by Facebook',
    "publishedOn": "Most Recent"
    // "googlePlusonesAmplificationByRelevance": 'Trending by Google+',
    // "totalAmplification": "Total Amplification",
    // "relevance": "Best Match",
    // "relevanceTfidf": "Best Match TFIDF",
    // "relevanceTfidfPg": "Best Match TFIDF * PG",
    // "score": "Best Match TFIDF * SOCIAL * PG",
    // "fbTotal": "Facebook Amplification",
    // "linkedinShares": "LinkedIn Shares",
    // "googlePlusones": "Google+ Shares"
  };

  exports.SORT_BY = SORT_BY;
  exports.default = Ember.Mixin.create({
    sortBy: "publishedOn",
    sortByName: Ember.computed("sortBy", function () {
      return SORT_BY[this.get("sortBy")];
    }),
    sortedArticlesByRelevance: Ember.computed('articles.[]', function () {
      var articles = this.get('articles');
      return articles && articles.sortBy('relevance').reverse() || [];
    }),

    topArticlesByRelevance: (0, _index.topItems)("sortedArticlesByRelevance", 400),

    totalAmplificationSortOption: ['totalAmplification:desc'],
    totalAmplificationByRelevance: Ember.computed.sort('topArticlesByRelevance', 'totalAmplificationSortOption'),

    fbTotalAmplificationSortOption: ['fbTotal:desc'],
    fbTotalAmplificationByRelevance: Ember.computed.sort('topArticlesByRelevance', 'fbTotalAmplificationSortOption'),

    googlePlusonesAmplificationSortOption: ['googlePlusones:desc'],
    googlePlusonesAmplificationByRelevance: Ember.computed.sort('topArticlesByRelevance', 'googlePlusonesAmplificationSortOption'),

    sortByOptions: Ember.computed("sortBy", function () {
      return [this.get('sortBy') + ':desc'];
    }),
    generalSortedArticles: Ember.computed.sort('articles.[]', 'sortByOptions'),

    sortedArticles: Ember.computed('articles.[]', "sortBy", function () {
      if (this.get("sortBy") === "totalAmplificationByRelevance") {
        return this.get("totalAmplificationByRelevance");
      } else if (this.get("sortBy") === "fbTotalAmplificationByRelevance") {
        return this.get("fbTotalAmplificationByRelevance");
      } else if (this.get("sortBy") === "googlePlusonesAmplificationByRelevance") {
        return this.get("googlePlusonesAmplificationByRelevance");
      } else {
        return this.get("generalSortedArticles");
      }
    }),
    topArticles: (0, _index.topItems)("sortedArticles", 400)

  });
});
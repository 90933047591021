define('client/routes/plan/neo/buckets/show', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    model: function model(params) {
      this.set('profileService.lastBucketId', params.id);
      return params.id || null;
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);
      this.controllerFor('plan.neo').set('selectedBucketId', model);
    }
  });
});
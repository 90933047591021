define('client/components/reports/competitor-widget', ['exports', 'client/components/reports/base-widget', 'client/mixins/resize'], function (exports, _baseWidget, _resize) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _baseWidget.default.extend(_resize.default, {
    classNameBindings: [":report-competitor"],
    endpoint: "competitors",
    size: 1,
    calcSize: Ember.on("resize", "didInsertElement", function (promise) {
      var count = this.get("info.competitors.length") * 1.4,
          surface = this.$().width() * this.$().height(),
          size = Math.floor(Math.sqrt(surface / count));

      this.set("size", isNaN(size) ? 1 : size);

      if (promise) {
        promise();
      }
    }),

    onCompetitorsChange: Ember.observer("info.competitors.[]", function () {
      this.calcSize();
    })
  });
});
define('client/queries/query', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.ObjectProxy.extend(Ember.Evented, Ember.PromiseProxyMixin, {
    errors: null,
    item: null,

    //
    // trigger an event for the item to save the values.
    //
    didFulfilled: Ember.observer("isFulfilled", function () {
      if (this.get("isFulfilled")) {
        this.trigger("fulfilled");
      }
    }),

    willDestroy: function willDestroy() {
      this.cancel();
    },


    //
    // Loads data from the server and update the promise when doing so.
    // The configuration of the query can be accessed to retrieve data.
    //
    load: function load() {
      throw "SUBCLASS TO IMPLEMENT";
    },


    //
    // Cancels any tasks the loading is performing if any.
    //
    cancel: function cancel() {
      throw "SUBCLASS TO IMPLEMENT";
    }
  });
});
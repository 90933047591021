define('client/utils/plan/campaign', ['exports', 'client/utils/timezones'], function (exports, _timezones) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.showTimeZoneSelection = exports.timeZones = undefined;
  var timeZones = exports.timeZones = Ember.computed(function () {
    return (0, _timezones.railsZoneNames)();
  });

  var showTimeZoneSelection = exports.showTimeZoneSelection = Ember.computed('selectedDuration', function () {
    return this.selectedDuration !== "all";
  });
});
define('client/components/checkbox-with-value', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Checkbox.extend({
    classNames: ['checkbox-simple'],

    onChange: function onChange() {},


    value: '',

    targetObj: null,

    change: function change(evt) {
      var checked = this.$().prop('checked');
      this.onChange(evt, checked, Ember.get(this, 'value'), Ember.get(this, 'targetObj'));
    }
  });
});
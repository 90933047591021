define('client/utils/search/item', ['exports', 'client/utils/search/parser', 'client/utils/search/constants'], function (exports, _parser, _constants) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var FREQUENCY_REGEX = /\s*(.*\S)\s*([<>]=?|==)\s*(\d+)/;
  var SearchItem = Ember.Object.extend({
    // the item definition from the config
    option: null,

    // the selected value
    value: null,

    frequencyMatch: Ember.computed('value', function () {
      var value = this.get('value');
      if (value == null) {
        return;
      }
      // > "perfect pr <= 3".match(FREQUENCY_REGEX)
      // < ["perfect pr <= 3", "perfect pr", "<=", "3"]
      return value.match(FREQUENCY_REGEX);
    }),

    valueProxy: Ember.computed('value', function () {
      var match = this.get('frequencyMatch');
      return match ? match[1] : this.get('value');
    }),

    frequency: Ember.computed('value', function () {
      var match = this.get('frequencyMatch');
      if (match !== null) {
        var frequency = {
          operator: match[2],
          occurrences: match[3]
        };
        return frequency;
      }
    }),

    // provide a default value
    modifier: Ember.computed("option.modifiers.[]", {
      get: function get() {
        return this.get("option.modifiers.firstObject");
      },
      set: function set(k, v) {
        if (typeof v === 'string') {
          v = this.get("option.modifiers").findBy("name", v);
        }
        return v;
      }
    }),

    // modifier boolean
    exact: Ember.computed.equal('modifier.name', 'exact'),
    similar: Ember.computed.equal('modifier.name', 'similar'),
    not: Ember.computed.equal('modifier.name', 'not'),

    greaterThan: Ember.computed.equal('modifier.name', 'gt'),
    lessThan: Ember.computed.equal('modifier.name', 'lt'),

    serialize: function serialize() {
      var name = this.get("option.name"),
          modifier = this.get("modifier"),
          value = this.get("valueProxy"),
          frequency = this.get("frequency");

      var suffix = '';

      if (frequency) {
        suffix = ' ' + frequency.operator + ' ' + frequency.occurrences;
      }

      var searchTerm = modifier.value.replace("{@}", value) + suffix;
      return name + ": " + searchTerm;
    }
  });

  var toSearchItem = function toSearchItem(pItem, flatOptions) {
    // find the option in the config
    var option = flatOptions.findBy("name", pItem.option);
    // Not a valid option
    if (!option) {
      return false;
    }

    // Only text search can have a frequency clause
    if (option.get("type") !== 'text' && pItem.frequency) {
      return false;
    }
    // Not a valid modifier for that option
    if (!_constants.default.MODIFIERS[option.get("type")].findBy("name", pItem.modifier)) {
      return false;
    }
    var value;
    if (pItem.frequency) {
      value = pItem.value + ' ' + pItem.frequency.operator + ' ' + pItem.frequency.occurrences;
    } else {
      value = pItem.value;
    }

    // build the search item itself.
    var item = SearchItem.create({
      option: option,
      value: value
    });
    item.set("modifier", pItem.modifier);
    return item;
  };

  var isGroupValid = function isGroupValid(group, flatOptions) {

    // discard nots from the grouping
    var values = group.values.reduce(function (all, val) {
      if (val.modifier !== "not") {
        all.push(val);
      }
      return all;
    }, []);

    // valid as this is not a group anymore
    if (values.length < 2) {
      return true;
    }

    // must all be OR except for ranges
    var valid = values.isEvery("or", true);
    if (!valid) {
      // they are not all OR
      // check if they are ranges

      var option = flatOptions.findBy("name", group.key);
      if (option.get("type") !== _constants.default.RANGE_TYPE) {
        return false;
      }
    }
    return true;
  };

  SearchItem.reopenClass({

    parseSearchTerm: function parseSearchTerm(term, config) {
      var flatOptions = config.get("categories").mapBy("options").flatten();
      var parsedItems, items;

      term = term.replace("(", " ( ").replace(")", " ) ");
      try {
        parsedItems = [_parser.default.parse(term)].flatten();
        // group by option
        parsedItems = d3.nest().key(function (d) {
          return d.option;
        }).entries(parsedItems);

        // map those to real search items
        items = parsedItems.map(function (group) {
          if (group.values.length > 1) {
            // check validity first
            if (!isGroupValid(group, flatOptions)) {
              throw "Invalid option grouping.";
            }
          }
          return group.values.map(function (item) {
            item = toSearchItem(item, flatOptions);
            if (!item) {
              throw "Invalid option.";
            }
            return item;
          });
        }).flatten();
      } catch (e) {
        // cannot be parsed
        console.warn(e);
        return false;
      }

      return items;
    }
  });

  exports.default = SearchItem;
});
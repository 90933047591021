define('client/services/current-user', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var CU = Ember.ObjectProxy.extend();

  CU.reopenClass({
    isServiceFactory: true
  });

  exports.default = CU;
});
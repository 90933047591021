define('client/models/research/graph-entry', ['exports', 'ember-data', 'client/models/trend-social-count'], function (exports, _emberData, _trendSocialCount) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var Entry = _trendSocialCount.default.extend({});

  // TODO change to the right endpoint.
  Entry.pathForType = "publication_activity";

  exports.default = Entry;
});
define('client/models/publication-search', ['exports', 'ember-data', 'client/models/search'], function (exports, _emberData, _search) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var PublicationSearch = _search.default.extend({

    //
    // POSTING attributes
    //

    // paging
    per_page: _emberData.default.attr("number"),
    offset: _emberData.default.attr("number"),

    //
    // RECEIVING attributes
    //

    // paging
    total_hits: _emberData.default.attr("number"),

    // publications
    publications: _emberData.default.hasMany("publication", { async: false }),

    content: Ember.computed.alias("publications"),
    pagedContent: Ember.computed.alias("content")

  });

  PublicationSearch.pathForType = "publications";

  exports.default = PublicationSearch;
});
define('client/components/plan/campaigns/campaign-interactions', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({

    classNames: ['campaign-interactions', 'campaign-creation-navigation'],

    prompt: 'Which interactions do you want to track for this campaign?',

    availableInteractions: null,
    selectedInteractions: null,
    onNext: function onNext() {},
    onPrevious: function onPrevious() {},
    onSelectedInteractions: function onSelectedInteractions() {},


    isContinueDisabled: Ember.computed('interactionOptions.@each.isSelected', function () {
      if (Ember.isEmpty(this.get("availableInteractions"))) {
        return false;
      }
      var interactions = this.get('interactionOptions');
      return Ember.isEmpty(interactions.filterBy('isSelected', true));
    }),

    /**
     * Computed property to create selection options for display.
     * Default: All options are selected.
     * @public
     */
    interactionOptions: Ember.computed('availableInteractions', 'selectedInteractions', function () {
      var _this = this;

      var interactionOptions = [];
      if (this.get('availableInteractions')) {
        var checkSelected = this.get('selectedInteractions');
        this.get('availableInteractions').forEach(function (interaction) {
          var isSelected = true; // default
          if (checkSelected) {
            isSelected = _this.get('selectedInteractions').includes(interaction);
          }
          interactionOptions.pushObject({ interaction: interaction, isSelected: isSelected });
        });
      }

      return interactionOptions;
    }),

    setInteractions: function setInteractions() {
      var selected = this.get('interactionOptions').filterBy('isSelected', true).mapBy('interaction');

      this.onSelectedInteractions(selected);
    },


    actions: {
      onNext: function onNext() {
        this.setInteractions();
        this.onNext();
      },
      onPrevious: function onPrevious() {
        this.setInteractions();
        this.onPrevious();
      }
    }
  });
});
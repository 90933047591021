define('client/components/image-upload', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var ImageUploadComponent = Ember.Component.extend({
    classNameBindings: [":img-upload"],
    content: null,

    uploading: false,

    src: Ember.computed("content.image_url", function () {
      var content = this.get("content.image_url");
      if (!content) {
        content = "holder.js/40x40";
      }
      return content;
    }),

    click: function click(e) {
      if (e.target.nodeName === "INPUT") {
        e.stopImmediatePropagation();
        return;
      }
      this.$("input").click();
    },

    setupUpload: Ember.observer("content.id", function () {
      var content = this.get("content"),
          type = content.constructor.pathForType,
          that = this;
      this.$("input").fileupload({
        pasteZone: null,
        dataType: 'json',
        url: '/v2/api/' + type + '/' + content.get("id") + "/image_upload",
        done: function done(e, data) {
          content.set("image_url", data.result.image_url);
          that.set("uploading", false);
        },
        submit: function submit() {
          that.set("uploading", true);
          return true;
        }
      });
    }),

    didInsertElement: function didInsertElement() {
      if (this.get("content.id")) {
        this.setupUpload();
      }
    }

  });

  exports.default = ImageUploadComponent;
});
define('client/serializers/article-search-related', ['exports', 'client/serializers/article-search'], function (exports, _articleSearch) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _articleSearch.default.extend({
    payloadKeyFromModelName: function payloadKeyFromModelName(modelName) {
      return 'article_search';
    },
    modelNameFromPayloadKey: function modelNameFromPayloadKey(payloadKey) {
      if (payloadKey === 'article_search') {
        return 'article_search_related';
      }
      return payloadKey;
    }
  });
});
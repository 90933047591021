define("client/components/form-item", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNameBindings: [":form-group", ":form-item"],
    label: null,
    trimInput: false,
    onChange: function onChange() {},


    actions: {
      change: function change() {
        if (this.get('trimInput')) {
          this.set('value', this.get('value').trim());
        }
        this.onChange();
      }
    }
  });
});
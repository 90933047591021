define('client/routes/dashboard/embed', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    //override beforeModel so authentication isn't checked
    beforeModel: function beforeModel() {},
    model: function model(params) {
      Ember.$.ajaxPrefilter(function (xhrOptions, originalOptions, xhr) {
        xhr.setRequestHeader('Authorization', "Token " + params.token);
      });

      return this.store.find("profile", params.profile_id);
    },
    afterModel: function afterModel(model) {
      return this.get("profileService").setProfile(model, { resetSort: false, refreshQueryParams: false });
    },


    actions: {
      error: function error() {
        this.transitionTo('report_not_found');
      }
    }
  });
});
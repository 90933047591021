define('client/modifiers/on-click-outside', ['exports', 'ember-click-outside/modifier'], function (exports, _modifier) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _modifier.default;
    }
  });
});
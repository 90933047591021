define('client/models/sales/search-seed', ['exports', 'ember-data', 'ember-cp-validations', 'ember-api-actions'], function (exports, _emberData, _emberCpValidations, _emberApiActions) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var Validations = (0, _emberCpValidations.buildValidations)({
    name: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('format', {
      type: 'url',
      disabled: Ember.computed.not("model.isURL")
    })]
  });

  var SearchSeed = _emberData.default.Model.extend(Validations, {

    profile: _emberData.default.belongsTo("profile"),
    name: _emberData.default.attr("string"),
    seedType: _emberData.default.attr("string"),
    active: _emberData.default.attr("boolean"),
    searchAlone: _emberData.default.attr("boolean"),
    mangle: _emberData.default.attr("boolean"),
    removeSuffix: _emberData.default.attr("boolean"),
    disableKeywords: _emberData.default.attr("boolean"),
    caseSensitive: _emberData.default.attr("boolean"),
    addParentCompanyName: _emberData.default.attr("boolean"),
    twitter: _emberData.default.attr("boolean"),
    facebook: _emberData.default.attr("boolean"),
    linkedin: _emberData.default.attr("boolean"),
    newsAndBlogs: _emberData.default.attr("boolean"),
    broadcast: _emberData.default.attr("boolean"),
    print: _emberData.default.attr("boolean"),
    searchTerms: _emberData.default.attr("array"),

    isURL: Ember.computed.equal("seedType", "url"),

    preview: (0, _emberApiActions.memberAction)({
      path: "preview"
    })

  });

  SearchSeed.pathForType = "sales/search_seeds";

  exports.default = SearchSeed;
});
define('client/components/profile-chooser-type', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var ProfileChooserTypeComponent = Ember.Component.extend({
    tagName: "li",
    classNameBindings: [":profile-chooser-type"],
    searchTerm: '',
    currentProfile: null,
    content: null,
    iconType: 'expand',
    stepTo: function stepTo() {},
    stepInto: function stepInto() {},


    click: function click(e) {
      e.preventDefault();
      e.stopImmediatePropagation();
      this.send("stepTo");
    },

    step: function step(close) {
      var content = this.get("content");
      if (content.profiles) {
        this.stepInto(this.get("content"));
      } else {
        this.stepTo(this.get("content"), close);
      }
    },

    isCompetitorOfCurrentProfile: Ember.computed('content', 'currentProfile', function () {
      return this.get('content.isCompetitor') && this.get('content.profile_ids') && this.get('content.profile_ids').indexOf(+this.get('currentProfile.id')) > -1;
    }),

    actions: {
      stepTo: function stepTo() {
        this.step(true);
      },

      stepInto: function stepInto() {
        this.step(false);
      }
    }
  });

  exports.default = ProfileChooserTypeComponent;
});
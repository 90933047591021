define('client/components/revenue-widget', ['exports', 'client/components/simple-widget'], function (exports, _simpleWidget) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var RevenueWidgetComponent = _simpleWidget.default.extend({

    iconClass: "db-widget-icon-revenue",

    action: "toContextRevenue",

    title: Ember.computed.alias("profile.nameRevenue"),

    text: Ember.computed("title", "profile.nameCustomer", function () {
      var title = this.get("title"),
          customer = this.get("profile.nameCustomer");
      return Ember.String.htmlSafe('<p> ' + title + ' represents the final outcome of ' + customer + ' that came to your website as a result of any PR activity.</p>');
    }),

    format: Ember.computed(function () {
      return function (v) {
        var prefix = d3.formatPrefix(v, 2);
        return "$" + d3.round(prefix.scale(v), 1) + prefix.symbol;
      };
    }),

    value: Ember.computed.alias("content.revenue")
  });

  exports.default = RevenueWidgetComponent;
});
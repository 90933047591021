define('client/mixins/models/print_publication', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    isPrintPublication: Ember.computed.equal('type', 'print_publication'),
    editorialRank: _emberData.default.attr("number"),
    category: _emberData.default.attr("string", { defaultValue: '' }),
    section: _emberData.default.attr("string", { defaultValue: '' }),
    pageNumber: _emberData.default.attr("number"),
    editorialTopics: _emberData.default.attr('array', { defaultValue: function defaultValue() {
        return [];
      } }),
    publisher: _emberData.default.attr("string", { defaultValue: '' }),
    topics: _emberData.default.attr('array', { defaultValue: function defaultValue() {
        return [];
      } }),
    copyright: _emberData.default.attr("string", { defaultValue: '' })
  });
});
define('client/components/reports/toolbar-option', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: '',
    currentSizeName: '',
    size: null,

    changeSize: function changeSize() {},


    isActive: Ember.computed('currentSizeName', function () {
      return this.get('size').name === this.get('currentSizeName');
    }),

    actions: {
      changeSize: function changeSize(size) {
        this.changeSize(size);
      }
    }
  });
});
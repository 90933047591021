define('client/components/expanded-article-pov-metric', ['exports', 'client/components/article-metric'], function (exports, _articleMetric) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var tooltipTitle = 'Power of Voice is Reputation, Relevance and Reception.\nHigh (H) is the top 30% of articles that are influential over the time period.\nMedium (M) is defined as the middle 40% of influential articles.\nLow (L) is is defined as the bottom 30% of influential articles.';

  exports.default = _articleMetric.default.extend({
    classNames: ["metric_pov"],
    tooltipTitle: tooltipTitle,
    metricTitle: 'Power of Voice',
    iconClass: "metric-icon metric-reach",
    conversionsTitle: 'Power of Voice',
    formatter: function formatter(d) {
      if (d === undefined) {
        d = 0;
      }

      var prefix = d3.formatPrefix(d, 2);
      if (prefix.symbol === 'G') {
        prefix.symbol = 'B';
      }
      return d3.round(prefix.scale(d), 1) + prefix.symbol;
    }
  });
});
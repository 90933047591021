define('client/components/insights/top-articles', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({

    classNames: ['top-articles', 'results'],
    articleIds: Ember.computed.mapBy('recommendations', 'var')

  });
});
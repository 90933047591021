define("client/models/permission", ["exports", "ember-data"], function (exports, _emberData) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var Permission = _emberData.default.Model.extend({
    name: _emberData.default.attr("string"),
    permission: _emberData.default.attr("string")
  });

  Permission.pathForType = "permissions";
  exports.default = Permission;
});
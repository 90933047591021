define('client/components/dashboard/widgets/amplification-card', ['exports', 'client/components/dashboard/widgets/widget-card', 'client/utils/twitter-settings'], function (exports, _widgetCard, _twitterSettings) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _widgetCard.default.extend({
    sort: "amplification",

    countsEndDate: _twitterSettings.default.COUNTS_END_DATE,
    gnipStartDate: _twitterSettings.default.GNIP_START_DATE,

    totalAmplification: Ember.computed("data.{facebook_count,linkedin_count,gplus_count,twitter_count,pinterest_count}", 'linkedinAvailable', 'pinterestAvailable', 'gplusAvailable', function () {
      var sum = this.get("data.facebook_count") + this.get("data.twitter_count");
      if (this.get('linkedinAvailable')) {
        sum = sum + this.get("data.linkedin_count");
      }
      if (this.get('gplusAvailable')) {
        sum = sum + this.get("data.gplus_count");
      }
      if (this.get('pinterestAvailable')) {
        sum = sum + this.get("data.pinterest_count");
      }
      return sum;
    })

  });
});
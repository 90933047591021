define('client/routes/reports/index', ['exports', 'ember-cli-pagination/remote/route-mixin'], function (exports, _routeMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_routeMixin.default, {
    queryParams: {
      filter: {
        refreshModel: true
      },
      search: {
        refreshModel: true
      }
    },

    router: Ember.inject.service(),
    perPage: 9,

    model: function model(params) {
      return this.findPaged('report', {
        filter: params.filter,
        page: params.page,
        search: (params.search || "").trim()
      });
    },

    titleToken: function titleToken(model) {
      var controller = this.controllerFor('reports.index');
      var reportText;
      if (controller.filter === "all") {
        reportText = 'All Reports';
      } else {
        reportText = 'My Reports';
      }

      if (controller.searchText) {
        reportText += ' - "' + controller.searchText + '"';
      }

      return reportText;
    },

    activate: function activate() {
      // Reset the search to user.name
      // This is going to fix a bug when switching from reports.show and index
      var controller = this.controllerFor('reports.index');
      controller.setProperties({
        initialRender: true,
        searchText: ""
      });
      // Force the computed property to recompute everytime we enter the route
      controller.notifyPropertyChange("filter");
    },


    actions: {
      loading: function loading() {
        var controller = this.controllerFor(this.fullRouteName);
        controller.set('isLoading', true);
      },
      didTransition: function didTransition() {
        var controller = this.controllerFor(this.fullRouteName);
        controller.set('isLoading', false);
      },
      refreshReports: function refreshReports() {
        this.refresh();
      }
    }
  });
});
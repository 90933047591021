define('client/models/campaign-analytics-goal-relationship', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var CampaignAnalyticsGoalRelationship = _emberData.default.Model.extend({
    analytics_goal: _emberData.default.belongsTo("analytics_goal"),
    campaign: _emberData.default.belongsTo("campaign"),
    created_at: _emberData.default.attr("date"),
    metric: Ember.computed.alias("analytics_goal")
  });
  CampaignAnalyticsGoalRelationship.pathForType = "campaign_analytics_goal_relationships";

  exports.default = CampaignAnalyticsGoalRelationship;
});
define("client/twitter", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var twttr = function (d, s, id) {
    var t,
        js,
        fjs = d.getElementsByTagName(s)[0];
    if (d.getElementById(id)) {
      return;
    }
    js = d.createElement(s);js.id = id;
    js.async = true;
    js.src = "https://platform.twitter.com/widgets.js";
    fjs.parentNode.insertBefore(js, fjs);
    return twttr || (t = { _e: [], ready: function ready(f) {
        t._e.push(f);
      } });
  }(document, "script", "twitter-wjs");

  exports.default = window.twttr;
});
define('client/models/analytics-goal', ['exports', 'ember-data', 'ember-cp-validations'], function (exports, _emberData, _emberCpValidations) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var Validations = (0, _emberCpValidations.buildValidations)({
    goal_id: (0, _emberCpValidations.validator)('presence', true),
    name: (0, _emberCpValidations.validator)('presence', true),
    value: (0, _emberCpValidations.validator)('number')

  });

  var AnalyticsGoal = _emberData.default.Model.extend(Validations, {

    analytics_profile: _emberData.default.belongsTo("analytics_profile", { async: true }),
    goal_id: _emberData.default.attr("string"),
    name: _emberData.default.attr("string"),
    display_name: _emberData.default.attr("string"),
    value: _emberData.default.attr("number"),
    created_at: _emberData.default.attr("date"),

    type: "Goal",
    display: Ember.computed("name", "display_name", {
      get: function get() {
        return this.get("display_name") || this.get("name");
      },
      set: function set(k, v) {
        this.set("display_name", v);
        return this.get("display_name") || this.get("name");
      }
    })
  });
  AnalyticsGoal.pathForType = "analytics_goals";

  exports.default = AnalyticsGoal;
});
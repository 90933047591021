define('client/serializers/report', ['exports', 'ember-data', 'active-model-adapter'], function (exports, _emberData, _activeModelAdapter) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var EmbeddedRecordsMixin = _emberData.default.EmbeddedRecordsMixin;
  exports.default = _activeModelAdapter.ActiveModelSerializer.extend(EmbeddedRecordsMixin, {
    isNewSerializerAPI: true,

    attrs: {
      pages: { embedded: 'always' }
    }
  });
});
define('client/components/reports/sub-header', ['exports', 'client/components/reports/base-text-widget', 'client/components/reports/base-widget'], function (exports, _baseTextWidget, _baseWidget) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _baseTextWidget.default.extend({
    classNameBindings: [":report-sub-header"],
    subheader: (0, _baseWidget.propWithDefaultValue)("subheader", "SUB HEADER")
  });
});
define('client/mixins/graph-group-columns', ['exports', 'client/mixins/graph-tooltip'], function (exports, _graphTooltip) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  var MAX_BAND_PERCENT = 0.15;

  var GraphGroupColumnsMixin = Ember.Mixin.create(_graphTooltip.default, {

    numYTicks: 6,

    toneGraphHackedData: Ember.computed('formattedData', 'showGraphAsToneChart', function () {
      var data = this.get('formattedData');
      if (!this.get('showGraphAsToneChart')) {
        return data;
      }
      return data.map(function (d) {
        var value = d.label === 'Negative' ? -d.value : d.value;
        return Ember.merge(Ember.merge({}, d), { value: value });
      });
    }),

    // TODO(tony) - sorting and maybe grouping should probably be moved out to
    // base class
    sortKey: null, //'value',

    sortAscending: true,

    sortedData: Ember.computed('toneGraphHackedData', 'sortKey', 'sortAscending', 'stackBars', function () {
      var data = this.get('toneGraphHackedData');
      var key = this.get('sortKey');
      var sortAscending = this.get('sortAscending');

      if (Ember.isEmpty(data)) {
        return [];
      }

      // TODO(tony) sort stacked differently

      if (key !== null) {
        if (sortAscending) {
          return data.sortBy(key);
        } else {
          return data.sortBy(key).reverse();
        }
      } else {
        return data;
      }
    }),

    // Aggregates objects provided in `data` in a dictionary, keyed by group names
    groupedData: Ember.computed("sortedData", function () {
      var data = this.get('sortedData');
      if (Ember.isEmpty(data)) {
        return [];
      }
      var ungroupedName = this.get('ungroupedSeriesName');
      return d3.nest().key(function (d) {
        return d.group ? d.group : ungroupedName;
      }).map(data);
    }).volatile(),

    groupNames: Ember.computed('groupedData', function () {
      return Object.keys(this.get('groupedData'));
    }).volatile(),

    // We know the data is grouped because it has more than one label. If there
    // are no labels on the data then every data object will have
    // 'ungroupedSeriesName' as its group name and the number of group
    // labels will be 1. If we are passed ungrouped data we will display
    // each data object in its own group.
    isGrouped: Ember.computed.gt('labelsPerColumn.length', 1),

    finishedData: Ember.computed('groupedData', 'isGrouped', 'stackColumns', 'sortedData', function () {
      if (Ember.isEmpty(this.get('groupedData')) || this.get('hasTooManyDataPoints') || this.get('hasTooManyLabels')) {
        return [];
      }

      if (this.get('isGrouped') || this.get('stackColumns')) {
        var groupedData = this.get('groupedData');
        var labelsPerColumn = this.get('labelsPerColumn');

        return Object.keys(groupedData).map(function (group) {
          var values = groupedData[group];
          // Calculate the the positive an negative stacks separately by splitting labels
          // with positive values separate from negative values, will do this per point

          var _labelsPerColumn$redu = labelsPerColumn.reduce(function (map, label) {
            var d = values.findBy('label', label);
            if (d && d.value && d.value < 0) {
              map.negLabels.push(label);
            } else {
              map.posLabels.push(label);
            }
            return map;
          }, { posLabels: [], negLabels: [] }),
              posLabels = _labelsPerColumn$redu.posLabels,
              negLabels = _labelsPerColumn$redu.negLabels;

          var y0 = 0;
          var negY0 = 0;
          var color = values[0].color;
          // Need to go by labelsPerColumn so that we always stack
          // in the same order
          var stackedValues = posLabels.map(function (label) {
            // find value by label
            var d = values.findBy('label', label);
            if (!d) {
              return null;
            }
            return {
              y0: y0,
              y1: y0 += Math.max(d.value, 0),
              value: d.value,
              group: d.group,
              label: d.label,
              color: d.color
            };
          }).compact();
          var negStackedValues = negLabels.map(function (label) {
            // find value by label
            var d = values.findBy('label', label);
            if (!d) {
              return null;
            }
            return {
              y0: negY0,
              y1: negY0 += Math.min(d.value, 0),
              value: d.value,
              group: d.group,
              label: d.label,
              color: d.color
            };
          }).compact();
          return { group: group, values: values, stackedValues: stackedValues, negStackedValues: negStackedValues, totalValue: y0, negTotalValue: negY0 };
        });
      } else {
        // If we have grouped data and do not have stackColumns turned on, split the
        // data up so it gets drawn in separate groups and labeled
        return this.get('sortedData').map(function (d) {
          return {
            group: d.group,
            color: d.color,
            values: [d]
          };
        });
      }
      // TODO(tony): Need to have stacked columns as a dependency here and the
      // calculation be outside of this
    }),

    // Data without group will be merged into a group with this name
    ungroupedSeriesName: 'Other',

    // If stackColumns is yes then it stacks columns, otherwise it groups them
    // horizontally. Stacking discards negative data.
    // TODO(nick): make stacked columns deal gracefully with negative data
    stackColumns: true,

    // Space between columns, as fraction of column size
    columnPadding: Ember.computed('numColumns', function () {
      // Use padding to make sure columns have a maximum thickness.
      //
      // TODO(tony): Use exact padding + bar width calculation
      // We have some set amount of bewtween group padding we use depending
      // on the number of columns there are in the chart. Really, what we would want
      // to do is have the equation for bar width based on padding and use that
      // to set the padding exactly.
      return d3.scale.linear().domain([1, 8]).range([0.25, 0.05]).clamp(true)(this.get('numColumns'));
    }),

    numColumns: Ember.computed('xDomain.length', function () {
      return this.get('xDomain.length') || 0;
    }),

    // ----------------------------------------------------------------------------
    // Label Layout
    // ----------------------------------------------------------------------------

    showColumnTickLines: false,

    // Padding between label and zeroline, or label and graphic
    labelPadding: 15,

    // Padding allocated for axes on left of graph
    labelWidth: 30,
    labelHeight: 15,

    labelWidthOffset: Ember.computed(function () {
      return this.get('labelWidth') + this.get('labelPadding');
    }).volatile(),

    labelHeightOffset: Ember.computed(function () {
      return this.get('labelHeight') + this.get('labelPadding');
    }).volatile(),

    // Space available for labels that are horizontally displayed. This is either
    // the unpadded group width or bar width depending on whether data is grouped
    //maxLabelWidth: Ember.computed.alias('columnWidth'),
    maxLabelWidth: Ember.computed(function () {
      var columnWidth = this.get('columnWidth');
      var numColumnsPerTick = this.get('numColumnsPerTick');
      return numColumnsPerTick * columnWidth;
    }).volatile(),

    numColumnsPerTick: Ember.computed(function () {
      var labelBuffer = 10;
      var columnWidth = this.get('columnWidth');
      var minLabelWidth = this.get('minLabelWidth') + labelBuffer;
      if (columnWidth >= minLabelWidth) {
        return 1;
      }
      return Math.ceil(minLabelWidth / columnWidth);
    }).volatile(),

    // If label width drops below this we will sub-smaple the labels
    minLabelWidth: 30,

    // If column width drops below this we just won't render the chart
    minColumnWidth: 5,

    maxNumberOfLabels: 12,
    hasTooManyLabels: Ember.computed(function () {
      return this.get('labelsPerColumn.length') > this.get('maxNumberOfLabels');
    }).volatile(),
    hasTooManyDataPoints: Ember.computed(function () {
      return this.get('columnWidth') < this.get('minColumnWidth');
    }).volatile(),

    // When the label width is less than this then we rotate the label
    // This number is based off the following calculation
    // 80% of words in English are less than 7 letters
    // The average size of a character in the default font is 4.5.
    // Thus, 5 * 7 = 35. Note that the size of the character is dynamic so
    // it would probably be best to measure this and make it dynamic here.
    // Anyway, this is just a rough marker of looks good and one could just
    // fiddle with this number until rotation happens the right amount.
    //
    // Note that this algo was used based on the basic heuristic idea, that
    // it probably won't look good for multi-line labels to only have one word
    // per line.
    minLabelWidthBeforeRotation: 35,
    shouldRotateLabels: function shouldRotateLabels() {
      return this.get('maxLabelWidth') < this.get('minLabelWidthBeforeRotation');
    },


    // Calculate the number of degrees to rotate labels based on how widely labels
    // will be spaced, but never rotate the labels less than 20 degrees
    rotateLabelDegrees: Ember.computed(function () {
      var radians = Math.atan(this.get('labelHeight') / this.get('maxLabelWidth'));
      var degrees = radians * 180 / Math.PI;
      return Math.max(degrees, 20);
    }).volatile(),

    rotatedLabelLength: Ember.computed(function () {
      var rotateLabelRadians = Math.PI / 180 * this.get('rotateLabelDegrees');
      // Need to take into consideration the axis label as well
      var adjustHeight = this.get('xAxisLabelHeight');
      return Math.abs((this.get('maxLabelHeight') - adjustHeight) / Math.sin(rotateLabelRadians));
    }).volatile(),

    // Gives the maximum of the lengths of the labels given in svgTextArray
    maxLabelLength: function maxLabelLength(svgTextArray) {
      var maxLabelLength = 0;
      svgTextArray.each(function () {
        // this.getComputedTextLength() gives the length in pixels of a text element
        var len = this.getComputedTextLength();
        if (len > maxLabelLength) {
          maxLabelLength = len;
        }
      });
      return maxLabelLength;
    },

    // Space allocated for rotated labels on the bottom of the chart. If labels
    // are rotated, they will be extended beyond labelHeight up to maxLabelHeight
    maxLabelHeight: Ember.computed(function () {
      if (this.shouldRotateLabels()) {
        return 70;
      } else {
        return 35;
      }
    }).volatile(),

    xAxisHeight: Ember.computed.alias('maxLabelHeight'),
    // TODO(tony) - try to make padding of xAxis dependent on size needed by ticks
    //paddingXAxisTicks: function() {
    //return this.get('_shouldRotateLabels') ?
    //this.get('maxLabelHeight') :
    //this.get('labelHeight');
    //}.property('_shouldRotateLabels', 'maxLabelHeight'),

    // ----------------------------------------------------------------------------
    // Selections
    // ----------------------------------------------------------------------------

    columnGroups: Ember.computed(function () {
      return this.get('viewport').selectAll('.columns');
    }).volatile(),

    yAxis: Ember.computed(function () {
      var yAxis = this.get('viewport').select('.y.axis');
      if (yAxis.empty()) {
        return this.get('viewport').insert('g', ':first-child').attr('class', 'y axis');
      }
      return yAxis;
    }).volatile(),

    xAxisBorders: Ember.computed(function () {
      var xAxisBorders = this.get('viewport').select('.x.axis.border');
      if (xAxisBorders.empty()) {
        return this.get('viewport').insert('g', ':first-child').attr('class', 'x axis border');
      }
      return xAxisBorders;
    }).volatile(),

    backgroundLayer: Ember.computed(function () {
      var backgroundLayer = this.get('viewport').select('g.background-layer');
      if (backgroundLayer.empty()) {
        // Hopefully you've added this when it needs to be
        backgroundLayer = this.get('viewport').append('g').attr('class', 'background-layer');
      }
      return backgroundLayer;
    }).volatile(),

    backgroundColumnGroups: Ember.computed(function () {
      return this.get('backgroundLayer').selectAll('.column-background');
    }).volatile(),

    // ----------------------------------------------------------------------------
    // Ticks and Scales
    // ----------------------------------------------------------------------------
    //
    yDomainGroups: Ember.computed('finishedData', function () {
      var finishedData = this.get('finishedData');
      if (Ember.isEmpty(finishedData)) {
        return [0, 1];
      }
      var minOfGroups = d3.min(finishedData, function (d) {
        return d3.min(d.values.map(function (dd) {
          return dd.value;
        }));
      });
      var maxOfGroups = d3.max(finishedData, function (d) {
        return d3.max(d.values.map(function (dd) {
          return dd.value;
        }));
      });

      return [minOfGroups, maxOfGroups];
    }),

    yDomainStacks: Ember.computed('finishedData', function () {
      var finishedData = this.get('finishedData');
      if (Ember.isEmpty(finishedData)) {
        return [0, 1];
      }

      var maxOfStacks = d3.max(finishedData, function (d) {
        return d.totalValue;
      });
      var minOfStacks = d3.min(finishedData, function (d) {
        return d.negTotalValue;
      });
      return [minOfStacks, maxOfStacks];
    }),

    // Vertical position/length of each bar and its value
    yDomain: Ember.computed('yDomainGroups', 'yDomainStacks', 'stackColumns', function () {
      var _ref = this.get('stackColumns') ? this.get('yDomainStacks') : this.get('yDomainGroups'),
          _ref2 = _slicedToArray(_ref, 2),
          min = _ref2[0],
          max = _ref2[1];

      // force one end of the range to include zero
      if (min > 0) {
        return [0, max];
      }
      if (max < 0) {
        return [min, 0];
      }
      if (min === max === 0) {
        return [0, 1];
      }
      return [min, max];
    }),

    yScale: Ember.computed(function () {
      var valueFormatType = this.get('valueFormatType');
      var domain = valueFormatType === 'score' ? [0, 100] : this.get('yDomain');
      return d3.scale.linear().domain(domain).range([this.get('graphicHeight'), 0]).nice(this.get('numYTicks'));
    }).volatile(),

    xDomain: Ember.computed.alias('groupNames'),

    // The space in pixels allocated to each group
    columnWidth: Ember.computed(function () {
      return this.get('xScale').rangeBand();
    }).volatile(),

    // The scale used to position each group and label across the horizontal axis
    // If we do not have grouped data, do not add additional padding around groups
    // since this will only add whitespace to the left/right of the graph.
    xScale: Ember.computed("xDomain", function () {
      var columnPadding = this.get('columnPadding'),
          graphicWidth = this.get('graphicWidth'),
          domain = this.get('xDomain'),
          cols = domain.length;
      var xScale = d3.scale.ordinal().domain(domain).rangeRoundBands([0, graphicWidth], columnPadding / 2, columnPadding / 2);
      var bandWidth = xScale.rangeBand(),
          labelsPerColumn = this.get('labelsPerColumn.length');

      if (graphicWidth * MAX_BAND_PERCENT * labelsPerColumn < bandWidth) {
        var barWidth = graphicWidth * MAX_BAND_PERCENT * labelsPerColumn,
            newPadding = 1 - barWidth / (graphicWidth / (cols + 1));
        var newScale = d3.scale.ordinal().domain(domain).rangeRoundBands([0, graphicWidth], newPadding);
        if (bandWidth > newScale.rangeBand()) {
          xScale = newScale;
        }
      }
      return xScale;
    }).volatile(),

    // Override axis mix-in min and max values to listen to the scale's domain
    minAxisValue: Ember.computed(function () {
      return this.get('yScale').domain()[0];
    }).volatile(),

    maxAxisValue: Ember.computed(function () {
      return this.get('yScale').domain()[1];
    }).volatile(),

    labelsPerColumn: Ember.computed('formattedData.@each.label', 'formattedData.@each.key', function () {
      // Return a list of all labels used throughout all groups
      var data = this.get('formattedData');
      return d3.nest().key(function (d) {
        return d.label;
      }).entries(data).map(function (d) {
        return d.key;
      });
    }),

    // ----------------------------------------------------------------------------
    // Styles
    // ----------------------------------------------------------------------------

    columnAttrs: Ember.computed(function () {
      var xScale = this.get('xScale');
      return {
        transform: function transform(d) {
          var dx = xScale(d.group);
          var dy = 0;
          return 'translate(' + dx + ', ' + dy + ')';
        }
      };
    }).volatile(),

    labelAttrs: Ember.computed(function () {
      var _this = this;

      return {
        'stroke-width': 0,
        transform: function transform() {
          var dx = _this.get('columnWidth') / 2;
          var dy = _this.get('graphicHeight') + _this.get('labelPadding');
          return 'translate(' + dx + ', ' + dy + ')';
        }
      };
    }).volatile(),

    labelLineAttrs: Ember.computed(function () {
      return {
        y1: -this.get('labelPadding'),
        y2: -this.get('graphicHeight') - this.get('labelPadding')
      };
    }).volatile(),

    valueLabelAttrs: Ember.computed(function () {
      var _this2 = this;

      var zeroDisplacement = 1;
      var yScale = this.get('yScale');
      return {
        x: this.get('columnWidth') / 2,
        y: function y(d) {
          // TODO(tony) figure out other charts as well, might need a
          // group here instead of text
          var offset = _this2.get('showDataPoints') ? 20 : 10;
          var location = d.value > 0 ? yScale(d.value) : yScale(0) + zeroDisplacement;
          return location - offset;
        },
        dy: '.35em',
        opacity: function opacity() {
          return _this2.get('showDataValues') ? 1 : 0;
        },
        'text-anchor': 'middle',
        'stroke-width': 0
      };
    }).volatile(),

    // ----------------------------------------------------------------------------
    // Drawing Functions
    // ----------------------------------------------------------------------------

    updateGraph: function updateGraph() {
      this.updateBackground();
      this.updateAxes();
      this.updateColumnGroups();
      this.updateLayout();
      this.updateColumns();
      this.updateValueLabels();
      this.updateHoverLine();
    },

    updateBackground: function updateBackground() {
      var finishedData = this.get('finishedData');
      if (Ember.isEmpty(finishedData)) {
        return;
      }

      var columns = this.get('backgroundColumnGroups').data(finishedData);
      columns.enter().append('g').attr('class', 'column-background');
      columns.exit().remove();
      columns.attr(this.get('columnAttrs'));
    },

    updateColumnGroups: function updateColumnGroups() {
      var finishedData = this.get('finishedData');
      if (Ember.isEmpty(finishedData)) {
        return;
      }

      var columnGroups = this.get('columnGroups').data(finishedData);
      var enteringColumns = columnGroups.enter().append('g').attr('class', 'columns');
      var enteringLabels = enteringColumns.append('g').attr('class', 'groupLabel');
      enteringLabels.append('text');

      enteringLabels.append('line').attr('class', 'column-tick-line');

      columnGroups.exit().remove();

      columnGroups.attr(this.get('columnAttrs'));
    },

    updateValueLabels: function updateValueLabels() {
      var _this3 = this;

      var finishedData = this.get('finishedData');
      if (Ember.isEmpty(finishedData)) {
        return;
      }

      //finished data values can be large decimal values
      //e.g. 63.3647847594793121
      //which won't render properly in d3
      //so we round them
      finishedData.forEach(function (d) {
        d.values.forEach(function (v) {
          if (v.value > 0 && v.value < 1) {
            v.value = d3.round(v.value, 2);
          } else if (typeof _this3.graph !== 'undefined' && _this3.graph.valueDimension.includes('impact_score')) {
            v.value = d3.round(v.value, 1);
          } else {
            v.value = d3.round(v.value, 0);
          }
        });
      });

      var valueLabelFormatter = this.get('valueLabelFormatter');
      var valueLabelGroups = this.get('viewport').selectAll('.valueLabel').data(finishedData);
      valueLabelGroups.enter().append('g').attr('class', 'valueLabel');
      valueLabelGroups.attr(this.get('columnAttrs'));
      valueLabelGroups.exit().remove();

      var valueLabelText = valueLabelGroups.selectAll('text').data(function (d) {
        return d.values;
      });
      valueLabelText.enter().append('text');
      valueLabelText.exit().remove();
      valueLabelText.text(function (d) {
        return valueLabelFormatter(d.value);
      }).attr(this.get('valueLabelAttrs'));
    },


    updateLayout: function updateLayout() {
      var _this4 = this;

      var columnGroups = this.get('columnGroups');
      columnGroups.select('g.groupLabel').attr(this.get('labelAttrs'));
      var numColumnsPerTick = this.get('numColumnsPerTick');

      var lineOpacity = function lineOpacity(d, i) {
        if (_this4.get('showColumnTickLines') && i % numColumnsPerTick === 0) {
          return 1;
        }
        return 0;
      };
      columnGroups.select('.groupLabel line').attr(this.get('labelLineAttrs')).attr('opacity', lineOpacity);

      // TODO(tony) consider splitting text and line for ticks
      var isLabelShown = function isLabelShown(d, i) {
        return i % numColumnsPerTick === 0 ? 1 : 0;
      };
      var labels = columnGroups.select('.groupLabel text').attr('transform', null) // remove any previous rotation attrs
      .attr('opacity', isLabelShown)
      // TODO(tony) - this is probably not good enough because sometimes the
      // target regions are very small so it is difficult to hit the target,
      // better is probably implement anywhere the mouse is within the label area
      // we intercept mouse move and are thus able to listen with distance to point
      // in mind
      .on('mouseenter', function (d, i) {
        if (isLabelShown(d, i)) {
          _this4.showTooltipIfTruncatedText(d.group);
        }
      }).on('mouseleave', function (d, i) {
        if (isLabelShown(d, i)) {
          _this4.hideTooltip('truncated-text');
        }
      }).text(function (d) {
        return d.group;
      });

      // If there is enough space horizontally, center labels underneath each
      // group. Otherwise, rotate each label and anchor it at the top of its
      // first character.
      if (this.shouldRotateLabels()) {
        var rotateLabelDegrees = this.get('rotateLabelDegrees');
        var labelTrimmer = this.labelTrimmer({
          getLabelSize: function getLabelSize() {
            return _this4.get('rotatedLabelLength');
          },
          getLabelText: function getLabelText(d) {
            return d.group;
          }
        });
        labels.call(labelTrimmer).attr({
          'text-anchor': 'end',
          transform: 'rotate(' + -rotateLabelDegrees + ')',
          dy: function dy() {
            return this.getBBox().height;
          }
        });
      } else {
        labels.attr({
          dy: '.35em',
          x: 0,
          y: 0,
          'text-anchor': 'middle'
        }).call(this.wordWrapper, this.get('maxLabelWidth'), this.get('xAxisHeight'));
      }
    },

    updateHoverLine: function updateHoverLine() {
      var viewport = this.get('viewport');
      var hoverLine = viewport.select('.hover-line');
      if (hoverLine.empty()) {
        hoverLine = viewport.append('line').attr('class', 'hover-line').attr('opacity', 0);
      }
    },


    updateAxes: function updateAxes() {
      //tickSize isn't doing anything here, it should take two arguments
      var yAxis = d3.svg.axis().scale(this.get('yScale')).orient('right').ticks(this.get('numYTicks')).tickSize(this.get('graphicWidth')).tickFormat(this.get('valueLabelFormatter'));

      var gYAxis = this.get('yAxis');
      gYAxis.call(yAxis);

      gYAxis.selectAll('g').filter(function (d) {
        return d !== 0;
      }).classed('major', false).classed('minor', true);

      gYAxis.selectAll('text').style('text-anchor', 'end').attr('x', -this.get('labelPadding') + 5);

      // Add left and right border lines to chart
      var viewport = this.get('viewport');
      var xAxisLeft = viewport.select('.x.axis.left');
      if (xAxisLeft.empty()) {
        xAxisLeft = viewport.append('line').attr('class', 'x axis left');
      }
      xAxisLeft.attr({
        x1: 0,
        x2: 0,
        y1: this.get('graphicHeight'),
        y2: 0
      });

      var xAxisRight = viewport.select('.x.axis.right');
      if (xAxisRight.empty()) {
        xAxisRight = viewport.append('line').attr('class', 'x axis right');
      }
      xAxisRight.attr({
        x1: this.get('graphicWidth'),
        x2: this.get('graphicWidth'),
        y1: this.get('graphicHeight'),
        y2: 0
      });

      this.updateAxisLabels();
    },

    graphicPadRight: 10,
    graphicPadTop: Ember.computed('showDataValues', function () {
      if (this.get('showDataValues')) {
        return 20;
      }
      return 10;
    }),

    getDataAtMouse: function getDataAtMouse(x) {
      var data = this.get('finishedData');
      if (Ember.isEmpty(data)) {
        return null;
      }
      var xPoints = this.get('xScale').range();
      var index = d3.bisectLeft(xPoints, x) - 1;
      if (index < 0 || index >= data.length) {
        return null;
      }
      return data[index];
    },
    getTooltipInfo: function getTooltipInfo(x) {
      var d = this.getDataAtMouse(x);
      if (d === null) {
        return null;
      }

      if (this.get('hasLegend')) {
        // If we have a legend then we also assume we have labels for datapoints
        // in addition to the x axis groups
        var title = d.group;
        return Ember.merge(this.getTooltipRows(d.values), { title: title });
      } else {
        // In the single column case, we display something else
        var format = this.get('valueLabelFormatter');
        return {
          title: this.get('xAxisLabel'),
          rows: [{
            label: d.group,
            value: format(d.values[0].value)
          }]
        };
      }
    },
    getDrilldownInfo: function getDrilldownInfo(x) {
      var d = this.getDataAtMouse(x);
      if (d === null) {
        return null;
      }
      return d.group;
    },


    showDataValues: false,

    // ----------------------------------------------------------------------------
    // Legend Configuration
    // ----------------------------------------------------------------------------
    hasLegend: true,
    legendItemNames: Ember.computed.alias('labelsPerColumn')

  });

  exports.default = GraphGroupColumnsMixin;
});
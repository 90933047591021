define('client/controllers/plan/campaigns', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    queryParams: ['search', 'sort'],

    modal: Ember.inject.service(),
    ajax: Ember.inject.service(),

    profile: Ember.computed.alias('profileService.profile'),
    campaign: Ember.computed.alias('profileService.campaign'),

    search: '',
    sort: Ember.computed(function () {
      return this.get('sortOptions.firstObject.value');
    }),

    sortOptions: [{ name: 'Alphabetical A-Z', value: 'name:asc' }, { name: 'Alphabetical Z-A', value: 'name:desc' }, { name: 'Start Date (Oldest to Latest)', value: 'startdate:asc' }, { name: 'Start Date (Latest to Oldest)', value: 'startdate:desc' }, { name: 'End Date (Oldest to Latest)', value: 'enddate:asc' }, { name: 'End Date (Latest to Oldest)', value: 'enddate:desc' }],

    // Default option when route loads
    selectedSort: Ember.computed(function () {
      return this.get('sortOptions.firstObject');
    }),

    profileDidChange: function profileDidChange() /*profile*/{
      if (this.get('target.currentRouteName') === 'plan.campaigns.index') {
        this.send('updateCampaignsForProfile');
      }
    },


    searchTextDebouncer: Ember.observer('search', function () {
      this.set('searchText', this.get('search'));
    }),

    sortDebouncer: Ember.observer('sort', function () {
      var sort = this.get('sort');
      var selectedSort = this.get('sortOptions').findBy('value', sort);
      if (selectedSort) {
        this.set('selectedSort', selectedSort);
      } else {
        this.transitionToRoute({ queryParams: {
            sort: this.get('sortOptions.firstObject.value'),
            search: (this.get('searchText') || '').trim()
          } });
      }
    }),

    loadPlanningData: function loadPlanningData() {
      // todo: ANL-5775
      // const campaign_ids = this.get('model').mapBy('id');
      // if(isPresent(campaign_ids)){
      //   this.get('ajax').request('/v3/api/plan/campaigns', {
      //     data: { campaign_ids }
      //   }).then((r) => {
      //     this.set('planningData', r);
      //   });
      // }
    },


    actions: {
      onPageChange: function onPageChange() {
        var _this = this;

        this.model.then(function () {
          _this.loadPlanningData();
        });
      },
      search: function search(term) {
        this.set('search', term);
      },
      sortBy: function sortBy(sortOption) {
        this.set('sort', sortOption.value);
      },
      onNewCampaign: function onNewCampaign() {
        this.transitionToRoute('plan.campaigns.new');
      },
      onDeleteCampaign: function onDeleteCampaign(campaign) {
        var _this2 = this;

        this.get('modal').open('common/ui-confirm', {
          question: 'Are you sure you want to delete: ' + campaign.get('name'),
          ok: 'Delete',
          okType: 'danger'
        }).then(function () {
          if (Ember.isEqual(_this2.get('campaign'), campaign)) {
            _this2.get('profileService').setCampaign(null);
          }
          campaign.destroyRecord().then(function () {
            _this2.send('updateCampaignsForProfile');
          });
        });
      }
    }
  });
});
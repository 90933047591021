define("client/components/common/ui-selected-value", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ["ui-selected-value"],
    label: null,
    value: null,

    onRemove: function onRemove() {}
  });
});
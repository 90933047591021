define('client/mixins/graph-v0-controller', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  // TODO(tony) - either deprecate and remove or perhaps this becomes some
  // part of the new graph data controller
  // V0 signifies the graphing code we had before we refactored to have
  // graphing code shared between report, context, dashboard graphs.
  var GraphControllerMixin = Ember.Mixin.create({
    graphData: Ember.computed(function () {
      return Ember.ArrayController.createWithMixins({
        content: [],

        isLoading: Ember.computed.alias("search.isNew"),
        isError: Ember.computed.alias("search.isError"),

        searchDidChange: Ember.observer("search", function () {
          var search = this.get("search"),
              profile = this.get("profile"),
              that = this;
          search.set("from", profile.get("startDate"));
          search.set("to", new Date());
          search.save();
          search.one("didCreate", function () {
            that.set("content", that.get("search.graph_data"));
          });
        }),
        search: Ember.computed({
          get: function get() {
            return null;
          },
          set: function set(k, v, old) {
            // remove events
            if (old) {
              old.off("didCreate");
              old.off("didUpdate");
            }

            return v;
          }
        })
      });
    }),

    graphAccumType: Ember.computed('selectedSort', 'controllers.profile.useInteractionCount', function () {
      var selectedSort = this.get('selectedSort');
      if (selectedSort === 'pov') {
        return 'average';
      } else if (selectedSort === 'all_interaction' || selectedSort.match("^event_") || selectedSort.match("^goal_")) {
        var useInteractionCount = this.get('controllers.profile.useInteractionCount');
        return useInteractionCount ? 'sum' : 'avg_percentage';
      } else {
        return 'sum';
      }
    }),

    _previousCriteria: false,
    _filterDidChange: function _filterDidChange(criteria, force) {
      var graphData = this.get("graphData"),
          store = this.get("store"),
          graphSearch = store.createRecord("graph_search", criteria),
          json;

      // Do not query if there is an empty profile
      if (!this.get("profile._profile_id")) {
        return false;
      }
      // Make sure we do not send the same query twice
      if (force) {
        this._previousCriteria = null;
      }

      delete criteria.from;
      delete criteria.to;
      // criteria.filters = $.extend({}, criteria.filters);
      // delete criteria.filters.tone_positive;
      // delete criteria.filters.tone_neutral;
      // delete criteria.filters.tone_negative;
      json = JSON.stringify(criteria);

      // jshint eqeqeq:false
      if (this._previousCriteria == json) {
        return false;
      }

      this._previousCriteria = json;
      graphData.set("profile", this.get("profile"));
      graphData.set("search", graphSearch);

      return true;
    }

  });

  exports.default = GraphControllerMixin;
});
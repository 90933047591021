define('client/utils/filter', ['exports', 'client/utils/observer'], function (exports, _observer) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var Filter = Ember.Object.extend({
    init: function init() {
      this.set("default", this.get("value"));
      this.get('isDefault');
    },

    type: "boolean", // or number
    value: false, // value based on type
    default: false, // default based on initial value

    name: "", // backend filter name
    title: "", // displayed name

    isDefault: Ember.computed("default", "value", function () {
      // jshint eqeqeq:false
      return this.get("value") == this.get("default");
    }),

    isNumber: Ember.computed("type", function () {
      // jshint eqeqeq:false
      return this.get("type") == "number";
    }),

    serialize: function serialize() {
      var val = {};
      val[this.get("name")] = this.get("value");
      return val;
    },

    deserialize: function deserialize(hash) {
      var value = hash[this.get("name")];
      if (value !== undefined) {
        this.set("value", value);
        delete hash[this.get("name")];
      }
    },

    reset: function reset() {
      this.set("value", this.get("default"));
    },
    // We want a debounced observer of value, so that select all/none doesn't
    // trigger multiple updateValue.
    updateValue: (0, _observer.debouncedObserver)(function () {
      var parent = this.get('parent');
      if (parent) {
        // Increment childrenUpdated value to trigger filter update.
        parent.set('childrenUpdated', parent.get('childrenUpdated') + 1);
      }
    }, 'value', 0)
  });

  exports.default = Filter;
});
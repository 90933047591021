define('client/components/reports/title-subitem', ['exports', 'client/components/reports/grid-subitem'], function (exports, _gridSubitem) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _gridSubitem.default.extend({
    selectedText: "Report Title",
    hoverText: "Click to edit report title",
    connectedHoverClass: "report-input-name",
    classNameBindings: ['isEditable:editable'],

    layoutName: 'components/reports/grid-subitem',

    isEditable: Ember.computed.not('reportService.isReadOnly'),

    isSelectedDidChange: Ember.observer('isSelected', function () {
      if (this.get('isSelected')) {
        Ember.$('.' + this.get('connectedHoverClass')).addClass('connected-select');
      } else {
        Ember.$('.' + this.get('connectedHoverClass')).removeClass('connected-select');
      }
    }),

    editingReportTitleDidChange: Ember.observer('reportService.editingReportTitle', function () {
      this.get('reportService').startEditing(this);
    })
  });
});
define('client/components/common/ui-timezone', ['exports', 'client/utils/iana-timezones'], function (exports, _ianaTimezones) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({

    selectedZone: null,
    timezone: null,
    defaultTimezone: 'America/New_York',
    onZoneChange: function onZoneChange() {},

    groupedTimezones: _ianaTimezones.groupedTimezones,

    init: function init() {
      this._super.apply(this, arguments);
      var selectedTimezone = (0, _ianaTimezones.ungroupedTimezones)().findBy('value', this.get('timezone'));
      if (!selectedTimezone) {
        //If no zone found, set to EST
        selectedTimezone = (0, _ianaTimezones.ungroupedTimezones)().findBy('value', this.get('defaultTimezone'));
      }
      this.set('selectedZone', selectedTimezone);
    },


    actions: {
      updateZone: function updateZone(item) {
        this.onZoneChange(item.value);
        this.set('selectedZone', item);
      }
    }
  });
});
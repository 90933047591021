define('client/components/product-list', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: 'span',
    classNames: ['product-list'],
    productNames: null,

    addFromSearch: function addFromSearch() {},
    removeProduct: function removeProduct() {},
    init: function init() {
      this._super.apply(this, arguments);
      this.set('productNames', this.get('productNames') || []);
    }
  });
});
define('client/components/airpr-popover', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var AirprPopoverComponent = Ember.Component.extend({
    classNames: ['airpr-popover'],
    //TODO: move this to use the hash helper in Ember 2.0 to define public API
    toggleFunction: Ember.computed(function () {
      var _this = this;

      return function () {
        _this.send('toggle');
      };
    }),

    // Content will let us know about this because trigger might
    // not want to let us close if the mouse is over, e.g., anchor
    // trigger
    isMouseOverContent: false,

    registerTrigger: function registerTrigger(trigger) {
      this.set('triggerView', trigger);
    },

    unregisterTrigger: function unregisterTrigger() {
      this.set('trigger', null);
    },

    actions: {
      toggle: function toggle() {
        this.toggleProperty('isOpen');
      }
    }
  });

  exports.default = AirprPopoverComponent;
});
define('client/routes/plan/neo/index', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    beforeModel: function beforeModel(transition) {
      if (transition.targetName === 'plan.neo.index') {
        this.transitionTo('plan.neo.buckets.index');
      }
    }
  });
});
define('client/components/radio-button', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNameBindings: [":radio-button"],
    label: null,
    selection: null,
    value: null,
    group: null,
    disabled: null,
    change: function change() {},

    checked: Ember.computed("selection", "value", function () {
      return this.get("selection") === this.get("value");
    }),
    select: Ember.on("click", function () {
      if (!this.get('disabled')) {
        var previousValue = this.get('selection');
        this.set("selection", this.get("value"));
        if (previousValue != this.get('value')) {
          this.change();
        }
      }
    })
  });
});
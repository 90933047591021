define('client/components/graph/whitelist-tag-options', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: "ul",
    filterSet: null,
    selectionSet: null,

    options: Ember.computed("filterSet.reportingAdapter.whitelistTagsFilterList", "filterSet.reportingAdapter.filterOptions.whitelistTagOptions", function () {
      var list = this.get("filterSet.reportingAdapter.whitelistTagsFilterList"),
          options = this.get("filterSet.reportingAdapter.filterOptions.whitelistTagOptions");
      if (!list) {
        return [];
      }
      return list.map(function (item) {
        return options.findBy("id", item);
      }).filter(function (item) {
        return item !== undefined;
      });
    }),

    update: function update() {}
  });
});
define('client/components/research/author-card', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['author-card'],

    nullStatDataMsg: 'N/A',
    expandedView: false,
    contactInfo: false,

    onSelectedAuthor: function onSelectedAuthor() {},
    onSelectedPublication: function onSelectedPublication() {},


    numArticles: Ember.computed.alias('authorStats.numArticles'),

    facebookShares: Ember.computed.alias('authorStats.fbShares'),

    linkedinShares: Ember.computed.alias('authorStats.linkedinShares'),

    totalAmplification: Ember.computed.alias('authorStats.totalAmplification'),

    averageAmplification: Ember.computed.alias('authorStats.averageAmplification'),

    pinterestPins: Ember.computed.alias('authorStats.pinterestPins'),

    actions: {
      selectedAuthor: function selectedAuthor(author) {
        this.get('onSelectedAuthor')(author.get("id"));
      },
      onSelectedPublication: function onSelectedPublication(publication) {
        this.get('onSelectedPublication')(publication);
      }
    }

  });
});
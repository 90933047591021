define('client/queries/research/graph', ['exports', 'client/queries/query', 'moment'], function (exports, _query, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _query.default.extend({
    store: Ember.inject.service(),
    load: function load() {
      var _getProperties = this.getProperties("store", "item"),
          store = _getProperties.store,
          item = _getProperties.item,
          searchTerm = item.get("searchTerm"),
          authorId = item.get("author.id"),
          publicationId = item.get("publication.id"),
          profileId = item.get("profileService.profile.id"),
          data = {
        author_id: authorId,
        publication_id: publicationId,
        search_term: searchTerm,
        profile_id: profileId
      };

      var promise = store.query("research/graph-entry", data);

      this.set("promise", promise);

      return this;
    }
  });
});
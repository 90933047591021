define('client/components/location-filters', ['exports', 'client/mixins/location-filters'], function (exports, _locationFilters) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.cleanLocationOptions = exports.defaultISOLocationOptions = exports.defaultLocationOptions = undefined;


  var defaultLocationOptions = function defaultLocationOptions(defaultLocations, locationData) {
    if (!defaultLocations) {
      return [];
    } else if (defaultLocations.length === 0) {
      var arr = locationData.map(function (v, i) {
        return 'lcn_' + i;
      });
      arr.splice(1, 1); //remove NonExistentCountry
      return arr;
    }
    return defaultLocations.map(function (l) {
      return "lcn_" + locationData.findIndex(function (d) {
        return d[1] === l;
      });
    });
  };

  var defaultISOLocationOptions = function defaultISOLocationOptions(defaultLocations, locationData) {
    if (!defaultLocations) {
      return [];
    } else if (defaultLocations.length === 0) {
      var arr = locationData.map(function (v) {
        return v[1];
      });
      arr.splice(1, 1); //remove NonExistentCountry
      return arr;
    }
    return defaultLocations;
  };

  var cleanLocationOptions = function cleanLocationOptions(locFilters, defaultLocations, locationData) {

    // If lcn_1 is selected (no locations selected),
    // pass all the locations that were selected.
    if (locFilters.length === 1 && locFilters[0] === 'lcn_1') {
      return locFilters;
    }

    var defaults = defaultLocationOptions(defaultLocations, locationData);

    return locFilters.filter(function (f) {
      return defaults.indexOf(f) > -1;
    });
  };

  exports.defaultLocationOptions = defaultLocationOptions;
  exports.defaultISOLocationOptions = defaultISOLocationOptions;
  exports.cleanLocationOptions = cleanLocationOptions;
  exports.default = Ember.Component.extend(_locationFilters.default, {
    classNames: ["location-filters"],
    totalLocations: 0,
    onChange: function onChange() {},


    serializedFilters: Ember.computed(function () {
      // flatten all filters
      var filters = this.get("locationFilters");
      // serialize them
      var serialized = filters.reduce(function (serialized, filter) {
        Ember.merge(serialized, filter.serialize());
        return serialized;
      }, {});

      return this.processLocationFilters(serialized);
    }).volatile(),

    locationOptions: Ember.computed("defaultLocationFilters.[]", function () {
      var locationData = Ember.getOwner(this).lookup('location-data:main'),
          defaultLocations = this.get("defaultLocationFilters"),
          locations = {};

      defaultLocationOptions(defaultLocations, locationData).forEach(function (l) {
        locations[l] = true;
      });

      return locations;
    }),

    _filtersDidChange: Ember.observer("locationFilters.@each.value", "locationFilters.@each.childrenUpdated", "defaultLocationFilters.[]", function () {
      Ember.run.debounce(this, "filtersDidChange", 200);
    }),

    filtersDidChange: function filtersDidChange() {
      var filters = this.get("serializedFilters");
      if (Object.keys(filters).length === 0) {
        this.onChange(this.get("locationOptions"), this.get('locationFilters'));
      } else {
        this.onChange(filters, this.get('locationFilters'));
      }
    }
  });
});
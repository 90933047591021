define('client/utils/filters/base-adapter', ['exports', 'client/utils/filters/options', 'client/utils/filters/profile-options'], function (exports, _options, _profileOptions) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Object.extend(_options.default, {

    locationFilterList: Ember.computed("filterSet.locations.[]", "filterOptions.locationOptions.[]", function () {
      var locationData = this.get("filterSet.locationData"),
          options = this.get("filterOptions.locationOptions");
      var locations = this.get("filterSet.locations");

      var results = options.map(function (l) {
        if (locations.indexOf(l) > -1 || Ember.isEmpty(locations)) {
          var i = locationData.find(function (d) {
            return d[1] === l;
          });

          return "lcn_" + locationData.indexOf(i);
        }
        return null;
      }).compact();
      // endpoints require at least one location filter
      // this allows users to unselect all locations
      return results.concat('lcn_1');
    }),

    tagFilterList: Ember.computed("filterSet.tags.[]", "filterSet.excludedTags.[]", "filterOptions.tagOptions.[]", function () {
      var filterSet = this.get("filterSet"),
          _filterSet$getPropert = filterSet.getProperties("tags", "excludedTags"),
          tags = _filterSet$getPropert.tags,
          excludedTags = _filterSet$getPropert.excludedTags,
          options = this.get("filterOptions.tagOptions");

      return options.mapBy("id").map(function (tagId) {
        if (excludedTags.findBy("id", tagId)) {
          return tagId + ':not';
        }
        if (tags.findBy("id", tagId)) {
          return tagId;
        }
        return null;
      }).compact();
    })

  });
});
define('client/utils/parent-filter', ['exports', 'client/utils/filter'], function (exports, _filter) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var ParentFilter = _filter.default.extend({
    childrenUpdated: 0,

    children: Ember.computed(function () {
      return Ember.A();
    }),

    propagateToChildren: true,

    updateValue: Ember.observer("childrenUpdated", function () {
      var children = this.get('children'),
          childrenOn = children.filter(function (filter) {
        return filter.get('value') > 0;
      }).length;

      this.set('partial', childrenOn > 0 && childrenOn < children.length);
      this.set('propagateToChildren', false);
      this.set('value', childrenOn > 0);
      this.set('propagateToChildren', true);
    }),

    serialize: function serialize() {
      return this.get('children').reduce(function (serializedFilters, child) {
        return Ember.merge(serializedFilters, child.serialize());
      }, {});
    }
  });

  exports.default = ParentFilter;
});
define('client/components/popover-trigger-container', ['exports', 'client/components/popover-trigger'], function (exports, _popoverTrigger) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var PopoverTriggerContainerComponent = _popoverTrigger.default.extend({
    tagName: 'div',
    classNameBindings: ['isOpen:open'],
    classNames: ['popover-trigger'],
    click: function click() {
      this.toggle();
    }
  });

  exports.default = PopoverTriggerContainerComponent;
});
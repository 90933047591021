define("client/models/impact-score-target-media-list", ["exports", "ember-data"], function (exports, _emberData) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var ImpactScoreTargetMediaList = _emberData.default.Model.extend({
    name: _emberData.default.attr("string"),
    whitelistTagId: _emberData.default.attr("string"),
    profileImpactScoreSetting: _emberData.default.belongsTo('profile-impact-score-setting')
  });

  exports.default = ImpactScoreTargetMediaList;
});
define('client/components/reports/image-list', ['exports', 'client/mixins/resize'], function (exports, _resize) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_resize.default, {
    classNameBindings: [":report-image-list"],
    attributeBindings: ["style"],

    calcStyles: Ember.on("resize", "didInsertElement", function () {
      var parent = this.$().parent(),
          sibling = this.$().siblings().first();

      var width = parent.width() - sibling.outerWidth() - 10;

      this.set("style", 'width:' + width + 'px');
    }),

    images: null,

    availableImages: Ember.computed("images.@each.thumb_url", function () {
      var config = 'reports/report-image',
          that = this;
      return this.get("images").filter(function (image) {
        return image.get("user_id") === +that.get("currentUser.id");
      }).map(function (image, i) {
        var content = Ember.merge({}, config);
        content.image = image;
        content.content = content.defaultValues = {
          image: image.get("id")
        };
        return {
          col: i,
          content: content
        };
      });
    }),

    actions: {
      onDoubleClick: function onDoubleClick(item) {
        this.get('onDoubleClick')(item);
      }
    }
  });
});
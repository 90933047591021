define('client/components/page-slider', ['exports', 'client/components/hv-slider'], function (exports, _hvSlider) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _hvSlider.default.extend({
    classNames: ['page-slider'],
    classNameBindings: ['canAdd'],
    report: null,

    canAdd: null,

    init: function init() {
      var p = Ember.$(document.createElement('div'));
      p.addClass('drag-placeholder');
      this.set('placeholder', p);
      return this._super.apply(this, arguments);
    },
    didInsertElement: function didInsertElement() {
      this.$().find('.slider-bd').on('scroll', this.get('onScroll').bind(this));
      this.set('register-as', this);
      return this._super.apply(this, arguments);
    },
    willDestroyElement: function willDestroyElement() {
      this.$().find('.slider-bd').off('scroll');
      return this._super.apply(this, arguments);
    },
    onScroll: function onScroll(evt) {
      if (!this.get('draggedPage')) {
        return;
      }
      evt.preventDefault();
      evt.stopPropagation();
      return false;
    },
    dragStart: function dragStart(evt) {
      evt.target.style.opacity = "0.2";

      this.set('draggedPage', evt.target);
      evt.dataTransfer.effectAllowed = 'move';

      //for firefox
      evt.dataTransfer.setData("text/html", evt.target);
    },
    dragEnd: function dragEnd(e) {
      e.preventDefault();
      e.target.style.opacity = "1";
      this.get('placeholder').hide();

      var afterThisPage = +this.get('addAfterPage');

      // When moved after the last page, scroll down to show the page
      if (afterThisPage === this.get('report.pages.length') - 1) {
        Ember.run.throttle(this, 'scrollDown', 400);
      }

      var pageGettingMoved = +Ember.$(this.get('draggedPage')).attr('data-page');
      this.set('draggedPage', null);

      this.get('report').movePage(pageGettingMoved, afterThisPage);
    },
    drop: function drop(e) {
      e.preventDefault();
    },
    scrollUp: function scrollUp() {
      var size = 89,
          s = this.$(".slider-bd"),
          newTranslate = s.scrollTop() - size;

      s.animate({
        scrollTop: newTranslate
      }, 200);
    },
    scrollDown: function scrollDown(scrollToVeryEnd) {
      var size = 89,
          contentSize = this.get("contentSize"),
          max = contentSize - size + 10,
          // + 10 is to leave some margin below the bottom-most slide
      s = this.$(".slider-bd"),
          newTranslate = s.scrollTop() + (scrollToVeryEnd ? max : size);

      s.animate({
        scrollTop: newTranslate
      }, 200);
    },
    dragOver: function dragOver(e) {
      if (!this.get('draggedPage')) {
        return false;
      }
      var page = e.target.closest('.report-page');

      if (page) {

        var curPage = +Ember.$(page).attr('data-page');
        var draggedPageElement = Ember.$(this.get('draggedPage'));
        var draggedPage = +draggedPageElement.attr('data-page');
        this.set('addAfterPage', curPage);

        if (curPage === draggedPage || curPage === 0) {
          this.get('placeholder').hide();
        } else {
          if (e.originalEvent.offsetY >= e.target.clientHeight / 2) {
            // Performance optimization (don't repeat if already next)
            if (page != this.get('placeholder').prev().get(0)) {
              this.get('placeholder').insertAfter(page);
            }
            this.get('placeholder').show();
            this.set('addAfterPage', curPage);
          } else if (e.originalEvent.offsetY < e.target.clientHeight / 2) {
            // Performance optimization (don't repeat if already prev)
            if (page != this.get('placeholder').next().get(0)) {
              this.get('placeholder').insertBefore(page);
            }
            this.get('placeholder').show();
            // Make sure we set the the previous page so that added page goes after
            this.set('addAfterPage', curPage - 1);
          }
        }
      } else {
        var target = Ember.$(e.target);
        if (target.hasClass('slider-arrow')) {
          if (target.parent().hasClass('slider-begin')) {
            Ember.run.throttle(this, 'scrollUp', 200);
          } else if (target.parent().hasClass('slider-end')) {
            Ember.run.throttle(this, 'scrollDown', 200);
          }
        }
      }
      e.preventDefault();
      e.stopPropagation();
    },


    actions: {
      scrollUp: function scrollUp() {
        this.scrollUp();
      },
      scrollDown: function scrollDown(scrollToVeryEnd) {
        this.scrollDown(scrollToVeryEnd);
      }
    }
  });
});
define('client/routes/analyze/coverage/chart', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    titleToken: "Coverage Chart",

    model: function model(params) {
      var coverageController = this.controllerFor('analyze.coverage');
      var chartType = params['type'];
      if (coverageController) {
        coverageController.setChartTemplate(chartType);
      }
    },
    deactivate: function deactivate() {
      this._super.apply(this, arguments);
      var coverageController = this.controllerFor('analyze.coverage');
      if (coverageController) {
        coverageController.setChartTemplate(null);
      }
    }
  });
});
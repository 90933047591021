define('client/components/dimension-config', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNameBindings: [":dimension-config", "isConfiguring:dimension-config-configure", "isSelected:dimension-config-selected"],

    isConfiguring: false,

    isSelected: Ember.computed("selection.[]", "value", function () {
      return this.get("selection").indexOf(this.get("value")) >= 0;
    }),

    isConfiguringDidChange: function isConfiguringDidChange() {
      if (this.get('isConfiguring')) {
        var container = Ember.$('.panel-group');
        container.animate({
          scrollTop: this.$().offset().top - container.offset().top + container.scrollTop()
        }, 200);
      }
    },


    bodySize: Ember.computed(function () {
      if (this._state === "inDOM") {
        return this.$(".dimension-config-body").outerHeight();
      }
    }).volatile(),

    calcBodyStyles: function calcBodyStyles(open) {
      this.set("bodyStyles", new Ember.String.htmlSafe('height: ' + (this.get("bodySize") || 0) + 'px'));
      if (open) {
        Ember.run.later(this, function () {
          if (this.get('isDestroyed')) {
            return;
          }
          this.set("bodyStyles", new Ember.String.htmlSafe("height: auto;"));
        }, 350);
      } else {
        Ember.run.later(this, function () {
          if (this.get('isDestroyed')) {
            return;
          }
          this.set("bodyStyles", new Ember.String.htmlSafe("height: 0px;"));
        }, 50);
      }
    },

    actions: {
      select: function select() {
        this.set("selection", [this.get("value")]);
        this.send("configure");
      },
      configure: function configure() {
        this.toggleProperty("isConfiguring");
        if (this.get("isConfiguring")) {
          // close all other
          Ember.$(".report-edit-dynamic .dimension-config-configure .dimension-config-config").click();
          this.calcBodyStyles(true);
        } else {
          this.calcBodyStyles(false);
        }
        this.isConfiguringDidChange();
      }
    }
  });
});
define('client/components/formulaic-revenue-widget', ['exports', 'client/components/simple-widget'], function (exports, _simpleWidget) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _simpleWidget.default.extend({
    profile: null,

    iconClass: "db-widget-icon-revenue",

    action: "toContextFormulaicRevenue",

    title: Ember.computed.alias("profile.nameFormulaicRevenue"),

    text: Ember.computed("title", "profile.nameCustomer", function () {
      var title = this.get("title"),
          customer = this.get("profile.nameCustomer");
      return Ember.String.htmlSafe("<p>" + title + " is calculated by multiplying the number " + customer + " in a selected date range &times; Average Revenue Per " + customer + " &times; a conversion rate. This figure represents an estimated revenue figure based on key stats that represent earning potential.</p>");
    }),

    format: Ember.computed(function () {
      return function (v) {
        var prefix = d3.formatPrefix(v, 2);
        return "$" + d3.round(prefix.scale(v), 1) + prefix.symbol;
      };
    }),

    value: Ember.computed("content.visitors", "profile.conversionRate", "profile.revenuePerCustomer", function () {
      var visitors = this.get("content.visitors"),
          rate = this.get("profile.conversionRate"),
          revenue = this.get("profile.revenuePerCustomer");
      if (rate && rate.replace) {
        rate = rate.replace(',', '');
      } else if (!rate) {
        rate = 0;
      }
      if (revenue && revenue.replace) {
        revenue = revenue.replace(',', '');
      } else if (!revenue) {
        revenue = 0;
      }

      return visitors * rate / 100.0 * revenue;
    })
  });
});
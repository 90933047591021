define('client/queries/chart-data-formatter', ['exports', 'client/utils/chart-data-transformer'], function (exports, _chartDataTransformer) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    maxToDate: function maxToDate(toDate) {
      var maxDate = toDate;

      var today = new Date();
      if (maxDate > today) {
        maxDate = today;
      }
      return maxDate;
    },


    //To handle interactions that are selectively chosen in a campaign level
    getOptimalFilterList: function getOptimalFilterList(dataType, item, filterList) {
      if (dataType === "interaction" && item.get('profile.isCampaign') && filterList.firstObject === 'total_interaction' && item.get('interactionLabelOptions')) {
        return item.get('interactionLabelOptions').map(function (o) {
          return o.id;
        });
      }
      return filterList;
    },


    dataTransformer: Ember.computed("item.hideBlankCategories", "item.valueDimension.firstObject", "item.toDate", "item.fromDate", "filterList.[]", "graphValues", "apiData", "graphValueKeys.[]", "graphLabelKeys.[]", "labelParseMap", "customFiscalYearOffset", "graphDateBinType", "toDate", "fromDate", "colorScale", function () {
      var item = this.get('item');
      var graphType = item.get('config') || item.get('type');
      var dataType = item.get('valueDimension.firstObject');

      return _chartDataTransformer.default.create({
        hideBlankCategories: item.get("hideBlankCategories"),
        colorScale: this.get('colorScale'),
        dataType: item.get('valueDimension.firstObject'),
        filterList: this.getOptimalFilterList(dataType, item, this.get('filterList')),
        graphValues: this.get('graphValues'),
        apiData: this.get("apiData"),
        graphLabelKeys: this.get('graphLabelKeys'),
        labelParseMap: this.get("labelParseMap"),
        customFiscalYearOffset: this.get("customFiscalYearOffset"),
        graphDateBinType: this.get("graphDateBinType"),
        graphType: graphType,
        toDate: this.maxToDate(this.get('toDate') || item.get('toDate')),
        fromDate: this.get('fromDate') || item.get('fromDate')
      });
    }),

    filterList: Ember.computed('isAmplificationGraph', 'isInteractionGraph', 'item.valueInteractionFilters', 'item.valueAmplificationFilters', function () {
      if (!this.get('isAmplificationGraph') && !this.get('isInteractionGraph')) {
        return ["total_count"];
      }

      var item = this.get('item');
      var filterList = this.get('isAmplificationGraph') ? item.get('valueAmplificationFilters') : item.get('valueInteractionFilters');
      if (Ember.isEmpty(filterList)) {
        if (this.get('isAmplificationGraph')) {
          filterList = item.get('availableAmplificationLabelOptions') || item.get('amplificationLabelFilterList');
        } else {
          filterList = item.get('interactionLabelFilterList');
        }
      }
      if (Ember.isEmpty(filterList)) {
        filterList = this.get('isAmplificationGraph') ? ["total_count"] : ["total_interaction"];
      }
      return filterList;
    }),

    isAmplificationGraph: Ember.computed('item.valueDimension.firstObject', 'item.groupDimension.firstObject', 'item.labelDimension.firstObject', function () {
      return this.get('item.groupDimension.firstObject') === 'amplification_label' || this.get('item.labelDimension.firstObject') === 'amplification_label' || this.get('item.valueDimension.firstObject') === 'amplification';
    }),

    isInteractionGraph: Ember.computed('item.valueDimension.firstObject', 'item.groupDimension.firstObject', 'item.labelDimension.firstObject', function () {
      return this.get('item.groupDimension.firstObject') === 'interaction_label' || this.get('item.labelDimension.firstObject') === 'interaction_label' || this.get('item.valueDimension.firstObject') === 'interaction';
    }),

    graphValues: Ember.computed('graphValueKeys.[]', 'item.coverageAggType', 'item.toneAggType', 'item.scoreAggType', 'item.reachAggType', 'item.amplificationAggType', 'item.impactScoreAggType', 'item.visitorAggType', 'item.readershipAggType', 'item.interactionAggType', 'item.revenueAggType', 'item.formulaicRevenueAggType', 'item.powerOfVoiceAggType', 'item.powerOfVoiceSentAggType', function () {
      var _this = this;

      return this.get('graphValueKeys').reduce(function (map, valueKey) {
        map[valueKey] = _this.get('item.' + valueKey.camelize() + 'AggType');
        return map;
      }, {});
    }),

    rebinnedData: Ember.computed.alias('dataTransformer.rebinnedData'),
    formattedData: Ember.computed.alias('dataTransformer.formattedData')
  });
});
define('client/mixins/serializers/has-endpoint-actions', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    mergedProperties: ['endpointActions'],

    serializeAction: function serializeAction(actionName, record) {
      var options = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};

      var actionSerialize = Ember.get(this.endpointActions, actionName + '.serialize');
      if (!actionSerialize) {
        return record;
      }
      return actionSerialize.call(this, record, options);
    },
    normalizeAction: function normalizeAction(actionName, typeClass, data) {
      var actionNormalize = Ember.get(this.endpointActions, actionName + '.normalize');
      if (!actionNormalize) {
        return data;
      }
      return actionNormalize.call(this, typeClass, data);
    },


    endpointActions: {}
  });
});
define("client/models/report-items/tile-interaction", ["exports", "client/models/report-items/tile"], function (exports, _tile) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _tile.default.extend({
    endpoint: "interaction",
    queryParams: "include_visitors=true"
  });
});
define('client/serializers/publication-search', ['exports', 'ember-data', 'active-model-adapter'], function (exports, _emberData, _activeModelAdapter) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  //
  // Article context search serializer.
  // Article summaries are always embedded.
  //
  var PublicationSearchSerializer = _activeModelAdapter.ActiveModelSerializer.extend(_emberData.default.EmbeddedRecordsMixin, {
    attrs: {
      publications: { embedded: 'always' }
    },
    //todo: remove in ember data 2
    isNewSerializerAPI: true
  });

  exports.default = PublicationSearchSerializer;
});
define('client/components/volume-filters', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var VolumeFilters = Ember.Component.extend({
    classNames: ['new-filter-group'],
    whitelistTags: null,
    whitelistTagIds: Ember.computed(Ember.A),
    influential: true,
    uninfluential: true,
    showStoryLevels: false,
    showWhitelistOnly: false,
    showCompanyWebsiteLink: false,
    toggleWhitelistTag: function toggleWhitelistTag() {},
    toggleAllWhitelistTags: function toggleAllWhitelistTags() {},
    toggleInfluential: function toggleInfluential() {},
    toggleUninfluential: function toggleUninfluential() {},
    toggleCompanyWebsiteLink: function toggleCompanyWebsiteLink() {},
    relevanceDidChange: function relevanceDidChange() {},


    actions: {
      toggleAllWhitelistTags: function toggleAllWhitelistTags(event, value) {
        this.toggleAllWhitelistTags(value);
      },
      toggleWhitelistTag: function toggleWhitelistTag(event, value, id) {
        this.toggleWhitelistTag(value, id);
      },
      toggleInfluential: function toggleInfluential() {
        this.toggleInfluential();
      },
      toggleUninfluential: function toggleUninfluential() {
        this.toggleUninfluential();
      },
      toggleCompanyWebsiteLink: function toggleCompanyWebsiteLink() {
        this.toggleCompanyWebsiteLink();
      },

      relevanceDidChange: function relevanceDidChange(relevanceValue) {
        this.relevanceDidChange(relevanceValue);
      }
    }
  });

  exports.default = VolumeFilters;
});
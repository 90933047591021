define("client/models/profile-message-relationship", ["exports", "ember-data"], function (exports, _emberData) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var ProfileMessageRelationship = _emberData.default.Model.extend({
    profile_message_bucket: _emberData.default.belongsTo("profile_message_bucket"),
    profile: _emberData.default.belongsTo("profile")
  });

  ProfileMessageRelationship.pathForType = "profile_message_relationships";

  exports.default = ProfileMessageRelationship;
});
define('client/components/expanded-article-revenues-metric', ['exports', 'client/components/article-metric'], function (exports, _articleMetric) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var ExpandedArticleRevenuesMetricComponent = _articleMetric.default.extend({
    layoutName: 'components/expanded-article-metric',
    classNameBindings: [":metric_revenue"],
    tooltipTitle: "The projected and/or accrued revenue as a result of the article.",
    metricTitle: Ember.computed.alias("profile.nameRevenue"),
    iconClass: "metric-icon metric-revenue",
    conversionsTitle: Ember.computed.alias("profile.nameRevenue"),
    formatter: function formatter(d) {
      if (d === undefined) {
        d = 0;
      }

      var format = d < 1 && d > 0 ? ",.2f" : ",.0f";
      return "$" + d3.format(format)(d);
    }

  });

  exports.default = ExpandedArticleRevenuesMetricComponent;
});
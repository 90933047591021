define('client/mixins/volume-filter', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var FILTER_DEFAULTS = {
    influential: true,
    uninfluential: false,
    company_website_link: null
  };

  var VolumeFilterMixin = Ember.Mixin.create({
    whitelistTagSort: ['name'],

    whitelistTags: Ember.computed.sort('profile.allWhitelistTags', 'whitelistTagSort'),

    savedFilters: Ember.computed.alias('_savedFilterSelection'),

    influentialDefault: Ember.computed('savedFilters.filters.influential', function () {
      return this._getVolumeFilterDefault('influential');
    }),

    uninfluentialDefault: Ember.computed('savedFilters.filters.uninfluential', function () {
      return this._getVolumeFilterDefault('uninfluential');
    }),

    companyWebsiteLinkDefault: Ember.computed('savedFilters.filters.company_website_link', function () {
      return this._getVolumeFilterDefault('company_website_link');
    }),

    whitelistTagIdsDefault: Ember.computed('savedFilters.filters.whitelist_tag_ids.[]', 'profile.id', 'whitelistTags.[]', function () {
      if (this.get('savedFilters.filters') && this.get('savedFilters.filters').hasOwnProperty('whitelist_tag_ids')) {
        return this.get('savedFilters.filters.whitelist_tag_ids') || [];
      } else if (Ember.isEmpty(this.get('whitelistTags'))) {
        return Ember.A([]);
      } else {
        return this.get('whitelistTags').mapBy('id');
      }
    }),

    volumeFiltersAreDefault: Ember.computed('savedFilters', 'influential', 'uninfluential', 'companyWebsiteLink', 'whitelistTagIdsDefault.length', 'whitelistTagIds.length', function () {
      var whitelistTagIds = this.get('whitelistTagIds');
      var defaults = this.get('whitelistTagIdsDefault');
      return this.get('influential') === this.get('influentialDefault') && this.get('uninfluential') === this.get('uninfluentialDefault') && this.get('companyWebsiteLink') === this.get('companyWebsiteLinkDefault') && defaults.every(function (id) {
        return whitelistTagIds.indexOf(id) >= 0;
      }) && whitelistTagIds.every(function (id) {
        return defaults.indexOf(id) >= 0;
      });
    }),

    setFiltersFromSavedSelection: function setFiltersFromSavedSelection() {
      if (this.get('savedFilters')) {
        this._setVolumeFilters();
      }
      this._super.apply(this, arguments);
    },

    resetFilters: function resetFilters() {
      this._setVolumeFilters();
      this._super.apply(this, arguments);
    },

    _setVolumeFilters: function _setVolumeFilters() {
      this.setProperties({
        influential: this.get('influentialDefault'),
        uninfluential: this.get('uninfluentialDefault'),
        companyWebsiteLink: this.get('companyWebsiteLinkDefault'),
        whitelistTagIds: this.get('whitelistTagIdsDefault').toArray()
      });
    },

    _getVolumeFilterDefault: function _getVolumeFilterDefault(filterName) {
      var savedFilterValue = this.get('savedFilters.filters.' + filterName);
      return savedFilterValue !== undefined ? savedFilterValue : FILTER_DEFAULTS[filterName];
    },

    actions: {
      toggleAllWhitelistTags: function toggleAllWhitelistTags(value) {
        if (!this.get('whitelistTags.length')) {
          return;
        }
        var whitelistTagIds = this.get('whitelistTagIds');
        if (!whitelistTagIds) {
          this.set('whitelistTagIds', Ember.A([]));
          whitelistTagIds = this.get('whitelistTagIds');
        }
        if (value) {
          this.get('whitelistTags').forEach(function (tag) {
            whitelistTagIds.addObject(tag.get('id'));
          });
        } else {
          whitelistTagIds.clear();
          this.set('uninfluential', false);
        }
      },

      toggleWhitelistTag: function toggleWhitelistTag(value, id) {
        var whitelistTagIds = this.get('whitelistTagIds');
        if (!whitelistTagIds) {
          this.set('whitelistTagIds', Ember.A([]));
          whitelistTagIds = this.get('whitelistTagIds');
        }
        if (value) {
          whitelistTagIds.addObject(id);
        } else {
          whitelistTagIds.removeObject(id);
          this.set('uninfluential', false);
        }
      },

      toggleInfluential: function toggleInfluential() {
        this.toggleProperty('influential');
        if (!this.get('influential')) {
          this.set('uninfluential', false);
        }
      },

      toggleUninfluential: function toggleUninfluential() {
        this.toggleProperty('uninfluential');
        if (this.get('uninfluential')) {
          this.set('influential', true);
          this.send('toggleAllWhitelistTags', true);
        }
      },

      toggleCompanyWebsiteLink: function toggleCompanyWebsiteLink() {
        var link = this.get("companyWebsiteLink");
        this.set("companyWebsiteLink", link === null ? true : null);
      }
    }
  });

  exports.default = VolumeFilterMixin;
});
define('client/components/input-copyable', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    inputValue: null,
    _inputValue: Ember.computed.oneWay('inputValue'),

    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      this.$('input').bind('copy', this.get('onCopy').bind(this));
    },

    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);
      this.$('input').off('copy');
    },

    copyErrored: false,
    onCopy: function onCopy() {
      var _this = this;

      if (this.get('copyErrored')) {
        this.$('button').attr('title', 'Copied!').tooltip('fixTitle').tooltip('show');
        Ember.run.later(function () {
          _this.$('button').tooltip('hide');
        }, 1000);
      }
    },

    actions: {
      success: function success() {
        var _this2 = this;

        this.$('button').attr('title', 'Copied!').tooltip('fixTitle').tooltip('show');
        Ember.run.later(function () {
          _this2.$('button').tooltip('hide');
        }, 1000);
      },

      error: function error() {
        this.set('copyErrored', true);
        this.$('input').select();
        this.$('button').attr('title', 'Press ⌘ + C to copy').tooltip('fixTitle').tooltip('show');
      }
    }
  });
});
define('client/models/email-digest-section', ['exports', 'ember-data', 'ember-cp-validations'], function (exports, _emberData, _emberCpValidations) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var Validations = (0, _emberCpValidations.buildValidations)({
    name: (0, _emberCpValidations.validator)('presence', {
      presence: true,
      message: 'Section name must be filled in.'
    }),
    viewLength: (0, _emberCpValidations.validator)('number', {
      integer: true,
      gt: 0,
      message: 'You must select a profile in this section.'
    })
  });

  var EmailDigestSection = _emberData.default.Model.extend(Validations, {

    containsErrors: Ember.computed('validations.errors.[]', function () {
      return this.get('validations.errors.length') > 0;
    }),

    email_digest: _emberData.default.belongsTo("email-digest", { async: true }),
    include_country: _emberData.default.attr("boolean", { defaultValue: true }),
    include_soundbytes: _emberData.default.attr("boolean", { defaultValue: true }),
    views: _emberData.default.hasMany("workspace-view", { async: true }),
    article_count: _emberData.default.attr("number", { defaultValue: 10 }),
    article_sort: _emberData.default.attr("number", { defaultValue: 0 }),
    section_number: _emberData.default.attr("number"),
    name: _emberData.default.attr("string"),

    viewLength: Ember.computed('views.[]', function () {
      return this.get('views.length');
    }),

    //for validations
    profiles: Ember.computed.oneWay('views')
  });

  EmailDigestSection.pathForType = "email_digest_sections";

  exports.default = EmailDigestSection;
});
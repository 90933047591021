define('client/queries/research/article', ['exports', 'client/queries/query'], function (exports, _query) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _query.default.extend({
    store: Ember.inject.service(),
    load: function load() {
      var _getProperties = this.getProperties("store", "item"),
          store = _getProperties.store,
          item = _getProperties.item,
          searchTerm = item.get("searchTerm"),
          authorId = item.get("author.id"),
          publicationId = item.get("publication.id"),
          profileId = item.get("profileService.profile.id"),
          filters = Object.assign({}, item.get("filters"));

      if (item.get('overrideCompetitor')) {
        filters.competitors = [item.get('overrideCompetitor.name')];
      }

      var data = {
        author_id: authorId,
        publication_id: publicationId,
        search_term: searchTerm,
        profile_id: profileId,
        filters: filters
      };

      if (filters.locations) {
        filters.locations = filters.locations.mapBy("iso");
      }

      var promise = store.query("research/article", data);

      this.set("promise", promise);

      return this;
    }
  });
});
define('client/components/influentials-table-headers', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var InfluentialsTableHeadersComponent = Ember.Component.extend({

    tagName: 'thead',

    headers: Ember.computed('model', function () {
      return this.get('model').map(function (header) {
        return Ember.Object.extend({
          controller: this,
          isSortBy: Ember.computed('controller.sortBy', 'key', function () {
            return this.get('controller.sortBy') === this.get('key');
          })
        }).create(header);
      }, this);
    }),

    actions: {
      clickHeader: function clickHeader(sortBy) {
        if (this.get('sortBy') === sortBy) {
          this.toggleProperty('isSortReversed');
        } else {
          this.set('sortBy', sortBy);
          this.set('isSortReversed', false);
        }
      }
    }
  });

  exports.default = InfluentialsTableHeadersComponent;
});
define('client/computed/index', ['exports', 'client/computed/array-property', 'client/computed/d3-format', 'client/computed/ternary-properties', 'client/computed/top-items', 'client/computed/alias-with-default'], function (exports, _arrayProperty, _d3Format, _ternaryProperties, _topItems, _aliasWithDefault) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.aliasWithDefault = exports.ternaryProperties = exports.d3Format = exports.arrayProperty = exports.topItems = undefined;
  exports.topItems = _topItems.default;
  exports.arrayProperty = _arrayProperty.default;
  exports.d3Format = _d3Format.default;
  exports.ternaryProperties = _ternaryProperties.default;
  exports.aliasWithDefault = _aliasWithDefault.default;
  exports.default = {
    topItems: _topItems.default,
    arrayProperty: _arrayProperty.default,
    d3Format: _d3Format.default,
    ternaryProperties: _ternaryProperties.default,
    aliasWithDefault: _aliasWithDefault.default
  };
});
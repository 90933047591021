define('client/routes/analyze', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    titleToken: "Analyze",
    IptcData: Ember.inject.service(),
    ajax: Ember.inject.service(),
    parentIptcTopics: [],

    afterModel: function afterModel() {
      var _this = this;

      var id = this.get("profileService.profile.id");
      var jsonData = {};
      this.get("ajax").request("/api/v1/iptc_topics/iptc_for_search_and_filter", {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        data: { profile_id: id }
      }).then(function (data) {
        if (data.error) {
          _this.set('parentIptcTopics', []);
        } else {
          jsonData = data.iptc_topics.map(function (entry) {
            return entry.iptc_topics;
          });
          _this.controllerFor('analyze').set('parentIptcTopics', jsonData);
        }
      }).catch(function (error) {
        throw error;
      });
      return this.controllerFor('analyze').setupFilterSet(false);
    },


    actions: {
      willTransition: function willTransition(transition) {
        //there are times when we transition to and from analyze
        //coverage and messaging, and want the model in this route to reload
        if (transition.targetName.startsWith('analyze.') && !transition.targetName.includes('chart')) {
          this.controllerFor('analyze').setupFilterSet(false, false);
        } else {
          this.controller.set('showSearchAndFilter', false);
        }
      }
    }
  });
});
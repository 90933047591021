define('client/helpers/plural-label', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.pluralLabel = pluralLabel;

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
        arr2[i] = arr[i];
      }

      return arr2;
    } else {
      return Array.from(arr);
    }
  }

  function pluralLabel(count, single, multi) {
    if (count === 1) {
      return single;
    }
    return multi;
  }

  exports.default = Ember.Helper.helper(function (params) {
    return pluralLabel.apply(undefined, _toConsumableArray(params));
  });
});
define('client/helpers/ordinal-suffix', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.getGetOrdinal = getGetOrdinal;

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
        arr2[i] = arr[i];
      }

      return arr2;
    } else {
      return Array.from(arr);
    }
  }

  function getGetOrdinal(n) {
    var s = ['th', 'st', 'nd', 'rd'],
        v = n % 100;
    return n + (s[(v - 20) % 10] || s[v] || s[0]);
  }

  exports.default = Ember.Helper.helper(function (params) {
    return getGetOrdinal.apply(undefined, _toConsumableArray(params));
  });
});
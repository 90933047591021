define('client/components/common/ui-skeleton-field', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['ui-skeleton-field'],
    attributeBindings: ['style'],
    width: '100%',
    height: '100%',

    style: Ember.computed('width', 'height', function () {
      return Ember.String.htmlSafe('width: ' + this.get('width') + '; height: ' + this.get('height') + ';');
    })
  });
});
define('client/adapters/email-newsletter-cart', ['exports', 'client/adapters/v3-application'], function (exports, _v3Application) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _v3Application.default.extend({
    queryRecord: function queryRecord(store, type, query) {
      var url = this.buildURL(type.modelName, null, null, 'queryRecord', query);
      url = url + '/current';
      return this.ajax(this.urlPrefix(url), 'GET');
    }
  });
});
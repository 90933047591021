define('client/mixins/new-location-filter', ['exports', 'client/utils/locations-by-region'], function (exports, _locationsByRegion) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var NewLocationFilterMixin = Ember.Mixin.create({
    northAmericaLocations: Ember.computed.oneWay('activeProfile.northAmericaLocations'),
    southAmericaLocations: Ember.computed.oneWay('activeProfile.southAmericaLocations'),
    africaLocations: Ember.computed.oneWay('activeProfile.africaLocations'),
    asiaLocations: Ember.computed.oneWay('activeProfile.asiaLocations'),
    europeLocations: Ember.computed.oneWay('activeProfile.europeLocations'),
    oceaniaLocations: Ember.computed.oneWay('activeProfile.oceaniaLocations'),
    antarcticaLocations: Ember.computed.oneWay('activeProfile.antarcticaLocations'),
    otherLocations: Ember.computed.oneWay('activeProfile.otherLocations'),

    _checkRegion: function _checkRegion(region) {
      var _this = this;

      this.get('activeProfile').getLocationsForRegion(region).forEach(function (item, index) {
        _this.set('savedFilters.filters.lcn_' + item.id, true);
      });
    },
    _uncheckRegion: function _uncheckRegion(region) {
      var _this2 = this;

      this.get('activeProfile').getLocationsForRegion(region).forEach(function (item, index) {
        _this2.set('savedFilters.filters.lcn_' + item.id, false);
      });
    },


    actions: {
      toggleLocation: function toggleLocation(locationId) {
        this.toggleProperty('savedFilters.filters.lcn_' + locationId);
      },
      checkRegion: function checkRegion(region) {
        this._checkRegion(region);
      },
      uncheckRegion: function uncheckRegion(region) {
        this._uncheckRegion(region);
      },
      checkAllLocations: function checkAllLocations() {
        this._checkRegion('NA');
        this._checkRegion('SA');
        this._checkRegion('AF');
        this._checkRegion('AS');
        this._checkRegion('EU');
        this._checkRegion('OC');
        this._checkRegion('AN');
        this.set('savedFilters.filters.lcn_0', true);
      },
      uncheckAllLocations: function uncheckAllLocations() {
        this._uncheckRegion('NA');
        this._uncheckRegion('SA');
        this._uncheckRegion('AF');
        this._uncheckRegion('AS');
        this._uncheckRegion('EU');
        this._uncheckRegion('OC');
        this._uncheckRegion('AN');
        this.set('savedFilters.filters.lcn_0', false);
      }
    }
  });

  exports.default = NewLocationFilterMixin;
});
define('client/controllers/reports/show/page', ['exports', 'client/utils/reports/item'], function (exports, _item) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var alias = Ember.computed.alias;
  exports.default = Ember.Controller.extend({
    page: alias('model'),

    reportsController: Ember.inject.controller('reports.show'),

    canDrag: Ember.computed("reportService.isEditText", function () {
      return this.get("reportService.isEditText") ? null : "true";
    }),

    isLoading: Ember.computed.oneWay('reportsController.isLoading'),

    //
    // Called when you need to refresh the HTML of the whole page
    // - when some item does not exist yet in the saved html
    // - when you have a blank page
    //
    saveRenderedHtml: function saveRenderedHtml(page) {
      Ember.run.scheduleOnce("afterRender", this, this.savePage, page);
    },
    savePage: function savePage(page) {
      var content = Ember.$(".report-content"),
          contentHtml = content.html();
      if (!contentHtml) {
        return;
      }
      if (page.isSameHtml(contentHtml)) {
        return;
      }
      page.set("renderedHtml", contentHtml);
      page.touch();
      page.get('items').forEach(function (item) {
        item.trigger('didRerenderHtml');
      });
    },
    scrollTo: function scrollTo(pageNum) {
      var reportPage = Ember.$(".page-slider").find('[data-page=' + pageNum + ']');
      if (reportPage.length) {
        reportPage[0].scrollIntoView();
      }
    },


    actions: {
      closeEdit: function closeEdit() {
        this.get('reportService').stopEditing();
        // close all other before showing this new one.
        Ember.$(".report-edit-dynamic .report-edit .report-edit-header .fa-times").click();
      },
      previousPage: function previousPage() {
        var newPageNumber = this.get("page.pageNumber") - 1;
        if (newPageNumber < 0) {
          return;
        }
        this.transitionToRoute("reports.show.page", newPageNumber);
        this.scrollTo(newPageNumber);
      },
      nextPage: function nextPage() {
        var newPageNumber = this.get("page.pageNumber") + 1;
        if (newPageNumber >= this.get("page.report.pages.length")) {
          return;
        }
        this.transitionToRoute("reports.show.page", newPageNumber);
        this.scrollTo(newPageNumber);
      },
      newGridItem: function newGridItem(item) {
        this.get('page').newGridItem(item);
      },
      moveGridItem: function moveGridItem(item) {
        item.content.touch();
      }
    }

  });
});
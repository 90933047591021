define('client/components/common/limited-navbar', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    currentUser: Ember.inject.service('current-user'),
    profileService: Ember.inject.service('profile')

    //classNames: ['ui-page-toolbar']
  });
});
define('client/components/insights/insights-manager', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    store: Ember.inject.service(),
    profile: null,
    selectedQuestion: null,
    insightStatus: null,
    insightLimit: null,
    searchTerm: null,
    requireSearchTerm: false,
    isLoading: Ember.computed.alias('unfilteredInsights.isPending'),

    unfilteredInsights: Ember.computed('profile.id', 'selectedQuestion', 'insightStatus', 'insightLimit', 'searchTerm', 'from', 'to', function () {

      var params = { profile_id: this.get('profile.id') },
          question = this.get('selectedQuestion'),
          status = this.get('insightStatus'),
          limit = this.get('insightLimit'),
          searchTerm = this.get('searchTerm'),
          from = this.get('from'),
          to = this.get('to');

      if (this.get('requireSearchTerm') && !Ember.isPresent(searchTerm)) {
        return Ember.A();
      }

      if (Ember.isPresent(question)) {
        params.question = question;
      }

      if (Ember.isPresent(status)) {
        params.status = status;
      }
      if (Ember.isPresent(searchTerm)) {
        params.search_term = searchTerm;
      }
      if (Ember.isPresent(limit)) {
        params.limit = limit;
      }
      if (Ember.isPresent(from) && Ember.isPresent(to)) {
        params.from = moment(from).format("YYYY-MM-DD");
        params.to = moment(to).format("YYYY-MM-DD");
      }
      return this.get('store').query('insight', params);
    }),

    insights: Ember.computed.filter('unfilteredInsights', function (insight) {
      if (Ember.isPresent(this.get('insightStatus'))) {
        return Ember.isEqual(insight.get('userStatus'), this.get('insightStatus'));
      } else {
        return true;
      }
    }),

    actions: {
      changeSearchTerm: function changeSearchTerm(searchTerm) {
        this.set('searchTerm', searchTerm);
      },
      changeStatus: function changeStatus(status) {
        this.set('insightStatus', status);
      },
      changeDateRange: function changeDateRange(from, to) {
        this.setProperties({
          from: from,
          to: to
        });
      }
    }
  });
});
define('client/models/workspace-view', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var WorkspaceView = _emberData.default.Model.extend({
    profile: _emberData.default.belongsTo("profile", { async: true }),
    campaign: _emberData.default.belongsTo("campaign", { async: true }),
    search_term: _emberData.default.attr("string", { defaultValue: "" }),
    filters: _emberData.default.attr("json"),

    hasErrors: Ember.computed.bool('errors.length'),

    activeProfileName: Ember.computed.alias('activeProfile.company_name'),
    activeProfile: Ember.computed("profile.content", "campaign.content", {
      get: function get() {
        return this.get("campaign.content") || this.get("profile.content");
      },
      set: function set(k, value) {
        var _this = this;

        if (value.get("isCampaign")) {
          this.set("profile", value.get("profile"));
          this.set("campaign", value);
        } else if (value.get('header_data')) {
          this.store.findRecord('profile', value.get('id')).then(function (loadedProfile) {
            _this.set('profile', loadedProfile);
            _this.set('campaign', null);
          });
        } else {
          this.set("campaign", null);
          this.set("profile", value);
        }
        return value;
      }
    })

  });

  WorkspaceView.pathForType = "workspace_views";

  exports.default = WorkspaceView;
});
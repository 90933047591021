define('client/mixins/find-page', ['exports', 'client/mixins/page'], function (exports, _page) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var FindPageMixin = Ember.Mixin.create(_page.default, {
    _loadPage: function _loadPage(range) {
      var start = range[0],
          end = range[1],
          type = this.get("type"),
          params = this.get("search"),
          extraParams = this.get("extraParams"),
          that = this;

      Ember.merge(params, extraParams);

      if (start >= end) {
        return;
      }

      this.set("isLoadingPage", true);

      if (this._emptyOnLoad) {
        this.get("model").replace(0, this.get("model.length"), []);
      }
      params.offset = start;
      params.per_page = end - start;

      this.get("store").query(type, params).then(function (results) {
        that._materialize(start, results);
      }).finally(function () {
        that.set("isLoadingPage", false);
      });
    }
  });

  exports.default = FindPageMixin;
});
define('client/components/research/publication-modal', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['publication-modal'],

    notAvailable: 'Not Available',

    term: Ember.computed.reads('searchTerm'),

    didRender: function didRender() {
      this._super.apply(this, arguments);
      // Prevent background scrolling when modal is open
      Ember.$('.app-body.ember-application').addClass('modal-open');
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);
      Ember.$('.app-body.ember-application').removeClass('modal-open');
    },
    onClose: function onClose() {},
    onSaveToList: function onSaveToList() {},
    onSearchTerm: function onSearchTerm() {},


    actions: {
      close: function close() {
        // Allow background scrolling when modal is closed
        Ember.$('.app-body.ember-application').removeClass('modal-open');
        this.get('onClose')();
      },
      onSearchTerm: function onSearchTerm(term) {
        this.get('onSearchTerm')(term);
      }
    }
  });
});
define('client/components/reports/shared-article-list', ['exports', 'client/utils/time-series-labeler', 'client/helpers/format-neo-for-analyze'], function (exports, _timeSeriesLabeler, _formatNeoForAnalyze) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  var TONE_SEARCH_MAPPING = {
    negative: '(tone: "Negative")',
    neutral: '(tone: "Neutral")',
    positive: '(tone: "Positive")'
  };

  var FILTER_MAPPING = {
    earned: "media_earned",
    owned: "media_owned",
    article: "news",
    google_plus: "gplus"
  };

  var CHART_TO_ARTICLE_KEY_MAP = {
    tag: "tag",
    tone_label: "tone"
  };

  var ProfileProxy = Ember.ObjectProxy.extend({
    searchTerm: "",
    displayName: "",
    selectId: 0,
    content: null,
    fill: ""
  });

  exports.default = Ember.Component.extend({
    classNameBindings: [":report-shared-article-list"],
    store: Ember.inject.service(),
    isLoaded: false,

    wheel: function wheel(e) {
      e.stopImmediatePropagation();
    },


    legendItems: null,
    chartApiHash: null,
    chartDimension: null,
    chartApiFirstSelection: null,
    selectedArticle: null,
    timezone: null,

    fromDate: Ember.computed.oneWay('chartApiHash.charts.from'),
    toDate: Ember.computed.oneWay('chartApiHash.charts.to'),

    addProfileSearchTerm: function addProfileSearchTerm(searchTerm) {
      var profileSearchTerm = this.get('selectedProfile.searchTerm');
      if (profileSearchTerm) {
        searchTerm = '(' + searchTerm + ') AND (' + profileSearchTerm + ')';
      }
      return searchTerm;
    },


    listText: Ember.computed('chartApiHash', 'chartDimension', 'selectedSerie', 'selectedProfile', 'hasSeries', function () {
      var horizontalAxis,
          horizontalValue,
          verticalAxis,
          verticalValue,
          verticalUnit = '';
      horizontalValue = this.get('chartDimension.point.group');

      if (horizontalValue && this.get('chartDimension.dimension')) {
        horizontalValue += '';
      }

      if (this.get('hasSeries')) {
        var name = this.get('selectedSerie.displayName') || this.get('selectedSerie.name');
        verticalValue = parseFloat(this.get('chartDimension.point.values').findBy('label', name).value);
      } else {
        verticalValue = this.get('chartDimension.point.values').reduce(function (a, i) {
          return a + i.value;
        }, 0);
      }

      var values = this.get('chartApiHash.charts.values');
      if (values.length) {
        verticalAxis = values[0].split('_').map(function (s) {
          return s.capitalize();
        }).join(' ');
        switch (values[0]) {
          case 'readership':
          case 'visitor':
            verticalAxis += ' count';
            break;
          case 'interaction':
            verticalValue = verticalValue.toFixed(2);
            verticalUnit = '%';
            break;
          case 'coverage':
            verticalUnit = verticalValue === 1 ? ' article' : ' articles';
            break;
          default:
            break;
        }

        if (values[0] !== 'interaction') {
          var prefix = d3.formatPrefix(verticalValue, 2);
          verticalValue = d3.round(prefix.scale(verticalValue), 2) + prefix.symbol;
        }
      }

      var verticalType = this.get('selectedSerie') || this.get('selectedProfile');
      if (verticalType) {
        var label = verticalType.get ? verticalType.get('name') || verticalType.get('displayName') : verticalType.name || verticalType.displayName;
        if (label) {
          verticalAxis = label + ' ' + verticalAxis;
        }
      }

      var str = 'Based on selected chart criteria, the ' + verticalAxis + ' ' + ((horizontalValue ? 'for ' + horizontalValue : '') + ' was ') + ('' + verticalValue + verticalUnit + '.');

      return str;
    }),

    hasSeries: Ember.computed('chartApiHash', 'chartDimension', function () {
      var group = this.get('chartDimension.group');
      if (!group) {
        return false;
      }
      // if(this.get('chartDimension.dimension') === 'amplification_label' ||
      if (['channel_type', 'amplification_label', 'date', 'interaction_label', 'content_type', 'volume_type', 'tone_label', 'tag', 'campaign', 'location', 'neo'].indexOf(group) >= 0) {
        var labels = this.get('chartApiHash.charts.labels.length');
        if (labels === 0) {
          var items = this.get('chartApiHash.legendItems.length');
          return items > 0;
        } else {
          return labels > 0;
        }
      } else {
        return this.get('chartApiHash.charts.profiles.length') > 1;
      }
    }),
    serieChoices: Ember.computed("chartApiHash", function () {
      var type = this.get("chartApiHash.charts.labels.lastObject"),
          filters = this.get("chartApiHash.charts.filters"),
          store = this.get("store"),
          group = this.get("chartDimension.group"),
          legendItems = this.get('chartApiHash.legendItems');
      var choices = null;
      if (!this.get("hasSeries")) {
        return [];
      }

      if (group === 'amplification_label') {
        var selectedAmps = this.get('chartDimension.point.values').map(function (v) {
          return v.label;
        });
        choices = [{ id: 'fb_total', name: 'Facebook' }, { id: 'twitter_retweets', name: 'Twitter' }, { id: 'google_plusones', name: 'Google+' }, { id: 'linkedin_shares', name: 'LinkedIn' }].filter(function (obj) {
          return selectedAmps.indexOf(obj.name) > -1;
        });
      } else if (group === 'interaction_label') {
        choices = this.get('chartDimension.interactionLabelOptions');
      } else {
        switch (type) {
          case "channel_type":
            {
              choices = [{ id: "media_earned", name: "Earned" }, { id: "media_owned", name: "Owned" }, { id: "press_release", name: "Newswire" }];
              break;
            }
          case "content_type":
            {
              choices = [{ id: "news", name: "Digital" }, { id: "linkedin_pulse", name: "LinkedIn Pulse" }, { id: "twitter", name: "Twitter" }, { id: "gplus", name: "Google+" }];
              break;
            }
          case "volume_type":
            {
              choices = [{ id: "whitelisted", name: "Whitelist" }, { id: "influential", name: "Influential" }];
              break;
            }
          case "tone_label":
            {
              choices = [{ id: "tone_positive", name: "Positive" }, { id: "tone_neutral", name: "Neutral" }, { id: "tone_negative", name: "Negative" }];
              break;
            }
          case "date":
            {
              choices = this.get('chartDimension.point.values').map(function (v) {
                return { id: v.label, name: v.label };
              });
              break;
            }
          case "tag":
            {
              choices = Object.keys(filters.tag).map(function (tagId) {
                if (!filters.tag[tagId]) {
                  return null;
                }
                return store.peekRecord("tag", tagId);
              }).compact();
              break;
            }
          case "profile":
            {
              choices = this.get('profiles');
              choices.optionLabelPath = 'displayName';
              choices.optionValuePath = 'selectId';
              break;
            }
          case "campaign":
            {
              choices = Object.keys(filters.campaign).map(function (campaignId) {
                if (!filters.campaign[campaignId]) {
                  return null;
                }
                return store.peekRecord("campaign", campaignId);
              }).compact();
              break;
            }
          case "location":
            {
              var locations = Ember.getOwner(this).lookup("location-data:main");
              choices = Object.keys(filters.location).map(function (locationId) {
                if (!filters.location[locationId]) {
                  return null;
                }
                var loc = locations[+locationId.replace("lcn_", "")];
                return { id: locationId, name: loc[0], location: true };
              }).compact();
              break;
            }
          case "neo":
            {
              choices = Object.keys(filters.neo).map(function (neoId) {
                if (!filters.neo[neoId]) {
                  return null;
                }
                return store.peekRecord("message", neoId);
              }).compact();
              break;
            }
        }

        var filtersForType = filters[type];
        if (filtersForType) {
          Object.keys(filtersForType).forEach(function (k) {
            if (!filtersForType[k]) {
              var o = choices.findBy("id", FILTER_MAPPING[k] || k);
              choices.removeObject(o);
            }
          });
        }
      }

      for (var i = 0; i < choices.length; i++) {
        var choice = choices[i];
        var name = choice.get ? choice.get('displayName') || choice.get('name') : choice.name;
        var f = legendItems.findBy('label', name);
        if (f) {
          if (choice.set) {
            choice.set('fill', f.fill);
          } else {
            choice.fill = f.fill;
          }
        }
      }

      return choices;
    }),

    selectedSerie: Ember.computed("serieChoices", {
      get: function get() {
        var info = this.get('chartDimension.value');
        var choice = this.get("serieChoices").findBy("id", info);
        if (!choice) {
          choice = this.get("serieChoices.firstObject");
        }
        return choice;
      },
      set: function set(key, value) {
        var findBy = 'id';
        if (this.get('hasSeries') && this.get('chartDimension.group') === 'profile') {
          findBy = 'selectId';
        }
        return this.get("serieChoices").findBy(findBy, value);
      }
    }),

    profiles: Ember.computed("chartApiHash", function () {
      var store = this.get("store"),
          charts = this.get("chartApiHash").charts,
          legendItems = this.get('chartApiHash.legendItems');

      if (!charts) {
        return [];
      }

      return charts.profiles.map(function (p, i) {
        var profile = void 0,
            proxy = ProfileProxy.create();
        if (p.campaign_id) {
          profile = store.peekRecord("campaign", p.campaign_id);
        } else {
          profile = store.peekRecord("profile", p.profile_id);
        }

        var name = profile.get("company_name"),
            f = legendItems.findBy('label', name);

        proxy.set("selectId", i);
        proxy.set("content", profile);
        proxy.set("searchTerm", p.search_term || "");
        proxy.set("displayName", p.label || name);

        if (f) {
          proxy.set('fill', f.fill);
        }
        return proxy;
      });
    }),
    selectedProfile: Ember.computed("profiles", {
      get: function get() {
        return this.get("profiles.firstObject");
      },
      set: function set(key, value) {
        return this.get("profiles").findBy("selectId", +value);
      }
    }),

    articles: Ember.computed(function () {
      var owner = Ember.getOwner(this);
      var pagingArrayController = owner.lookup('controller:paging-array');

      pagingArrayController.setProperties({
        content: []
      });

      return pagingArrayController;
    }),

    _updateArticles: Ember.on("didInsertElement", function () {
      Ember.run.debounce(this, "updateArticles", 100);
    }).observes("chartApiHash", "selectedProfile.searchTerm", "selectedProfile._profile_id", "selectedProfile.campaign_id", "selectedSerie.id", "selectedSerie.selectId"),

    updateArticles: function updateArticles() {
      var _this = this;

      var store = this.get("store"),
          articleSearch = store.createRecord("article_search", { time_zone: this.get('timezone') }),
          chartApiHash = this.get("chartApiHash"),
          charts = chartApiHash && chartApiHash.charts,
          dimension = this.get("chartDimension");

      if (!charts) {
        return { articles: [] };
      }
      // convert the api hash into article search

      var filters = {
        media_earned: false,
        media_owned: false,
        press_release: false,
        news: false,
        linkedin_pulse: false,
        twitter: false,
        gplus: false,
        whitelisted: false,
        influential: false,
        uninfluential: false,
        tone_positive: false,
        tone_neutral: false,
        tone_negative: false,
        all_metrics: true
      };

      for (var cat in charts.filters) {
        if (cat === 'neo') {
          continue;
        }

        var keyMap = CHART_TO_ARTICLE_KEY_MAP[cat];

        if (keyMap) {
          var keys = Object.keys(charts.filters[cat]);
          for (var i = 0, n = keys.length; i < n; i++) {
            filters[CHART_TO_ARTICLE_KEY_MAP[cat] + '_' + keys[i]] = charts.filters[cat][keys[i]];
          }
        } else {
          var value = charts.filters[cat];
          if ($.type(value) === 'object') {
            Ember.merge(filters, charts.filters[cat]);
          } else {
            filters[cat] = charts.filters[cat];
          }
        }
      }

      for (var filter in filters) {
        if (FILTER_MAPPING[filter]) {
          filters[FILTER_MAPPING[filter]] = filters[filter];
          delete filters[filter];
        }
      }

      var obj = {
        offset: 0,
        per_page: 20,
        profile_id: this.get('selectedProfile._profile_id') || charts.profiles[0].profile_id,
        campaign_id: this.get('selectedProfile.campaign_id') || charts.profiles[0].campaign_id,
        search_term: this.get('selectedProfile.searchTerm') || '',
        search_type: '',
        filters: filters
      };

      if (this.get('hasSeries') && dimension.group === 'profile') {
        if (this.get('selectedSerie.isCampaign')) {
          obj.campaign_id = this.get('selectedSerie.id');
          obj.profile_id = this.get('selectedSerie._profile_id');
        } else {
          obj.profile_id = this.get('selectedSerie.id');
        }
        obj.search_term = this.get('selectedSerie.searchTerm');
      }

      if (!this.get('hasSeries') && dimension.dimension === 'profile') {
        var values = JSON.parse(this.get('chartDimension.value'));
        obj.profile_id = values.profileId;
        obj.campaign_id = values.campaignId;
        var itemId = obj.campaign_id ? obj.campaign_id : obj.profile_id;
        obj.search_term = this.get('profiles').findBy('content.id', itemId).searchTerm || '';
      }

      if (dimension.dimension === 'neo') {
        var message = this.get('chartDimension.point.group');
        var message_id = store.peekAll('message').findBy('name', message);
        if (message_id) {
          obj.message_id = message_id.get('id');
        }
      }

      if (dimension.dimension === 'interaction_label') {
        obj.omit_interaction_padding = true;
        obj.sort = dimension.value;
        obj.omit_interaction_padding = true;
      } else if (dimension.group === 'interaction_label') {
        var id = this.get('selectedSerie.id');
        obj.omit_interaction_padding = true;
        obj.sort = id;
      } else {
        if (dimension.group === 'neo') {
          obj.message_id = this.get('selectedSerie').id;
        }
        switch (dimension.drilldownValue) {
          case 'readership':
            obj.sort = 'readership';
            break;
          case 'score':
          case 'power_of_voice':
            obj.sort = 'pov';
            break;
          case 'power_of_voice_sent':
            obj.sort = 'pov_rel_amp_sent';
            break;
          case 'visitor':
            obj.sort = 'visitors';
            break;
          case 'reach':
            obj.sort = 'reach';
            break;
          case 'amplification':
            if (dimension.valueAmplificationSelection === 'total_count') {
              obj.sort = 'all_amplification';
            } else {
              obj.sort = dimension.valueAmplificationSelection.replace(/_count$/, '');
            }
            break;
          case 'interaction':
            if (dimension.valueInteractionSelection === 'total_interaction') {
              obj.sort = 'all_interaction';
            } else {
              obj.sort = dimension.valueInteractionSelection;
            }
            break;
          case 'coverage':
          default:
            obj.sort = 'date';
            break;
        }
      }
      obj.from = moment(charts.from).startOf('day').toDate();
      obj.to = moment(charts.to).endOf('day').toDate();
      dimension.from = moment(dimension.from || charts.from).startOf('day').toDate();
      dimension.to = moment(dimension.to || charts.to).endOf('day').toDate();

      var group = this.get('chartDimension') || {};
      if (group.group == 'amplification_label') {
        obj.search_term += ' ' + (obj.search_term ? 'AND' : '') + ' (' + this.get('selectedSerie.id') + ': >= 1)';
      }

      articleSearch.setProperties(obj);

      // special cases for each type of graph
      this.updateFiltersByDimension(dimension, articleSearch);

      this.updateFiltersBySerie(articleSearch);

      //this block of logic needs to come after updateFiltersByDimension()
      if (group.group === 'date') {
        var dateBinType = group.graphDateBinType;
        var date = (0, _timeSeriesLabeler.formattedTime)(dateBinType).parse(this.get('selectedSerie.id'));

        var _getDateRangeByInterv = (0, _timeSeriesLabeler.getDateRangeByInterval)(dateBinType, date),
            _getDateRangeByInterv2 = _slicedToArray(_getDateRangeByInterv, 2),
            from = _getDateRangeByInterv2[0],
            to = _getDateRangeByInterv2[1];

        articleSearch.set('from', from);
        articleSearch.set('to', to);
      }

      this.set("selectedArticle", null);
      articleSearch.on("didCreate", function () {
        _this.set('isLoaded', true);
        var article = articleSearch.get("articles.firstObject");
        if (!_this.get("selectedArticle") && article) {
          article.set('searchTerm', _this.get('articles.search.search_term'));
          _this.set("selectedArticle", article);
        }
      });
      this.set("articles.search", articleSearch);
    },

    updateFiltersBySerie: function updateFiltersBySerie(articleSearch) {
      var choices = this.get("serieChoices"),
          selection = this.get("selectedSerie"),
          filters = articleSearch.get("filters"),
          type = this.get("chartApiHash.charts.labels.lastObject");

      if (!choices || type === 'date' || type === 'neo') {
        return;
      }

      if (type === "campaign") {
        articleSearch.set("campaign_id", selection.get ? selection.get("id") : selection.id);
        return;
      } else if (type == "tone_label") {
        var selection_name = (selection.get ? selection.get("name") : selection.name).toLowerCase();
        articleSearch.set("search_term", this.addProfileSearchTerm(TONE_SEARCH_MAPPING[selection_name]));
        return;
      }

      choices.forEach(function (c) {
        var content = c.get ? c.get("content") : null,
            id = c.get ? c.get("id") : c.id;
        var prefix = "";
        if (content && content.constructor.pathForType === "tags") {
          prefix = "tag_";
        }
        filters[prefix + id] = selection === c;
      });
    },
    updateFiltersByDimension: function updateFiltersByDimension(dimension, articleSearch) {
      var filters = articleSearch.get("filters");
      // special cases for each type of graph
      switch (dimension.dimension) {
        case "date":
        case "profile":
          articleSearch.setProperties(dimension);
          break;
        case "channel_type":
          filters.media_earned = filters.media_owned = filters.press_release = false;
          if (FILTER_MAPPING[dimension.value]) {
            filters[FILTER_MAPPING[dimension.value]] = true;
          } else {
            filters[dimension.value] = true;
          }
          break;
        case "content_type":
          filters.linkedin_pulse = filters.news = false;
          if (FILTER_MAPPING[dimension.value]) {
            filters[FILTER_MAPPING[dimension.value]] = true;
          } else {
            filters[dimension.value] = true;
          }
          break;
        case "tone_label":
          articleSearch.set("search_term", this.addProfileSearchTerm(TONE_SEARCH_MAPPING[dimension.value]));
          break;
        case "volume_type":
          filters.influential = filters.whitelisted = false;
          filters[dimension.value] = true;
          break;
        case "tag":
          {
            Object.keys(filters).filter(function (k) {
              return k.indexOf("tag") === 0;
            }).forEach(function (k) {
              filters[k] = false;
            });
            filters["tag_" + dimension.value] = true;
            break;
          }
        case "location":
          {
            var locations = Ember.getOwner(this).lookup("location-data:main"),
                loc = locations.find(function (l) {
              return l[1] === dimension.value;
            }) || locations[0];
            Object.keys(filters).filter(function (k) {
              return k.indexOf("lcn") === 0;
            }).forEach(function (k) {
              filters[k] = false;
            });

            filters["lcn_" + locations.indexOf(loc)] = true;
            break;
          }
        case "neo":
          {
            var soundbytes = this.get('selectedProfile.soundbytes');
            if (!soundbytes) {
              break;
            }
            var item = soundbytes.findBy('id', dimension.value);
            if (item) {
              var soundbyte_name = Ember.get(item, 'name');
              if (!soundbyte_name.match(/\(|\)/)) {
                soundbyte_name = (0, _formatNeoForAnalyze.formatNeoForAnalyze)(soundbyte_name);
              }
              articleSearch.set("search_term", this.addProfileSearchTerm(soundbyte_name));
            }
            break;
          }
        case "campaign":
          articleSearch.set("campaign_id", dimension.value);
          break;
      }
    },
    didInsertElement: function didInsertElement() {
      var _this2 = this;

      this._super.apply(this, arguments);
      this.$().parent().on("mousewheel." + this.get("elementId"), function (e) {
        return _this2.wheel(e);
      });
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);
      this.$().parent().off("mousewheel." + this.get("elementId"));
    },


    actions: {
      nextPage: function nextPage() {
        this.get("articles").nextPage();
      },
      closeArticle: function closeArticle() {
        this.set("selectedArticle", null);
      },
      selectArticle: function selectArticle(article) {
        article.set('searchTerm', this.get('articles.search.search_term'));
        this.set("selectedArticle", article);
      },
      close: function close() {
        this.set("chartApiHash", false);
      }
    }
  });
});
define("client/models/broadcast-provider", ["exports", "ember-data"], function (exports, _emberData) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var Model = _emberData.default.Model.extend({

    name: _emberData.default.attr("string"),
    callsign: _emberData.default.attr("string")

  });

  Model.pathForType = "broadcast_providers";

  exports.default = Model;
});
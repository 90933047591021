define('client/components/research/author-editorial-tags', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['research-key', 'author-editorial-tags'],
    onSelectedTag: function onSelectedTag() {},


    hasMoreTags: Ember.computed.gt('tags.length', 3),
    shownTags: Ember.computed('tags.[]', function () {
      return this.get('tags').slice(0, 3);
    }),
    extraTags: Ember.computed('tags.[]', function () {
      return this.get('tags.length') - 3;
    })

  });
});
define('client/components/impact-score-widget', ['exports', 'client/components/simple-widget'], function (exports, _simpleWidget) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var ImpactScoreComponent = _simpleWidget.default.extend({
    layoutName: "components/impact-score-widget",
    title: "Average Impact Score",

    text: Ember.computed(function () {
      return Ember.String.htmlSafe("<p>Impact Score is a qualitative metric that measures the absolute impact of media coverage on your brand during a given time period.</p>");
    }),

    impactScore: Ember.computed("content.impact_score", function () {
      return this.get('content.impact_score');
    }),
    iconClass: "db-widget-icon db-widget-icon-impact-score"
  });

  exports.default = ImpactScoreComponent;
});
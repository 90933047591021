define('client/components/article-component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var ArticleComponent = Ember.Component.extend({
    tagName: "li",
    attributeBindings: ["style"],
    classNames: ["article"],
    classNameBindings: ["content.isSelected:article_selection", "content.isSocial:article-social", "content.isVideo:article-video", "isHighlighted:highlighted-article"],
    selection: false,
    content: null,
    selectedArticle: null,
    modal: Ember.inject.service(),
    // Its important this is oneWay so that setting isClickable
    // from article container doesn't change readonly
    isClickable: Ember.computed.oneWay('reportService.isReadOnly'),

    profileId: null,
    campaignId: null,

    showFacebook: true,
    showTwitter: true,
    showGoogle: true,
    showLinkedIn: true,
    showPinterest: true,
    showReadership: false,
    showImpactScore: false,
    showVisitors: false,
    showInteraction: false,
    showReach: false,
    showRevenue: false,
    showFormulaicRevenue: false,

    shouldShowVisitors: Ember.computed.and('content.isArticle', 'showVisitors'),
    shouldShowInteraction: Ember.computed.and('content.isArticle', 'showInteraction'),
    shouldShowReadership: Ember.computed.and('content.isArticle', 'showReadership'),
    shouldShowImpactScore: Ember.computed.and('content.isArticle', 'showImpactScore'),
    shouldShowRevenue: Ember.computed.and('content.isArticle', 'showRevenue'),
    shouldShowArticleReach: Ember.computed.and('content.isArticle', 'showReach'),
    shouldShowBroadcastReach: Ember.computed.and('content.isBroadcast', 'showReach'),
    shouldShowFormulaicRevenue: Ember.computed.and('content.isArticle', 'showFormulaicRevenue'),

    position: null,

    isHighlighted: Ember.computed('selectedArticle', 'content', function () {
      return this.get('selectedArticle.id') === this.get('content.id');
    }),

    isNlaArticle: Ember.computed("content", function () {
      return this.get('content.url').includes('nla.onclusive.com') || this.get('content.url').includes('nla-eclips.com');
    }),

    baseArticle: Ember.computed('profileId', 'campaignId', 'searchTerm', function () {
      if (!this.get('profileId')) {
        return [];
      }
      return this.get('content').baseArticleForProfile(this.get('profileId'), this.get('campaignId'), this.get('searchTerm'));
    }),

    isAirprAdmin: Ember.computed.alias("user.isAirprAdmin"),

    didInsertElement: function didInsertElement() {
      this.windowManagerService.on("window:resize", this, "resize");
      this.setParentSize();
      this.$().on('selectstart', function (e) {
        if (typeof e.target.dragDrop === 'function') {
          e.target.dragDrop();
        }
        return false;
      });
    },

    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);
      this.windowManagerService.off("window:resize", this, "resize");
      this.$().off('selectstart');
      if (this.clone) {
        this.clone.remove();
      }
    },

    resize: function resize() {
      this.setParentSize();
      this.notifyPropertyChange("style");
    },

    setParentSize: function setParentSize() {
      var slider = this.$().parents(".related-articles");
      if (slider.length > 0) {
        this.set("parentSize", slider.width());
      }
    },

    forceOneArticleCategoryPerRow: false,
    style: Ember.computed("parentSize", function () {
      var w = Ember.$(window).width(),
          max = null;
      if (w < 768) {
        max = w - 20;
      } else {
        // check if this article is within a slider
        var parentSize = this.get("parentSize");
        if (parentSize) {
          max = parentSize / (this.get('forceOneArticleCategoryPerRow') ? 1 : 3) - 2;
        }
      }
      if (max) {
        return new Ember.String.htmlSafe("max-width:" + max + "px;");
      }
    }),

    positionIndex: Ember.computed('position', function () {
      var pos = this.get("position");
      return 'number' === typeof pos ? this.get("position") + 1 : false;
    }),

    tags: Ember.computed("content.tags.length", function () {
      var tags = this.get("content.tags"),
          length = 0;

      if (tags) {
        length = tags.get("length");
      }

      if (length > 2) {
        tags = tags.slice(0, 2);
        tags.push({
          name: "(" + length + ") Tags"
        });
      }
      return tags;
    }),

    actions: {
      clickedArticle: function clickedArticle() {
        if (this.get('isClickable')) {
          var win = window.open(this.get('content.url'), '_blank');
          win.focus();
        }
      }
    }
  });

  exports.default = ArticleComponent;
});
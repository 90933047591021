define('client/components/analyze/messaging/soundbyte-item', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var heatStyles = function heatStyles(prop, mapProp) {
    return Ember.computed('soundbyte.' + prop, mapProp + '.[]', function () {
      var map = this.get(mapProp),
          count = this.get('soundbyte.info.' + prop);
      if (!map) {
        return "";
      }
      var step = 1 / map.length,
          color = 1 - map.indexOf(count) * step;

      return ('opacity: ' + color + ';').htmlSafe();
    });
  };

  exports.default = Ember.Component.extend({
    classNames: ["soundbyte-item", "row", "m-0", "no-gutter"],
    classNameBindings: ["selected:soundbyte-item_selected"],

    selected: false,
    articleHeatMap: null,
    amplificationHeatMap: null,

    articleStyles: heatStyles("coverage", "articleHeatMap"),
    amplificationStyles: heatStyles("amplification", "amplificationHeatMap"),
    visitorStyles: heatStyles("visitor", "visitorHeatMap"),
    interactionStyles: heatStyles("interaction", "interactionHeatMap"),

    select: function select() {},
    click: function click() {
      this.get("select")(this.get("soundbyte"));
    }
  });
});
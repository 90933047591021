define('client/components/common/ui-question-block', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['draggableItem', 'ui-question-block'],
    attributeBindings: ['draggable'],
    draggable: 'true',

    browser: Ember.inject.service('browser'),

    text: null,
    showContent: false,
    isMouseDown: false,

    dragData: Ember.computed('draggableItem.id', 'draggableItem.modelName', function () {
      var modelName = this.get('draggableItem.modelName') || this.get('draggableItem').constructor.modelName;
      return {
        id: this.get('draggableItem.id'),
        modelName: modelName
      };
    }),
    onDoubleClick: function onDoubleClick() {},
    dragDidStart: function dragDidStart() {},
    dragDidEnd: function dragDidEnd() {},
    doubleClick: function doubleClick() {
      this.onDoubleClick(0, this.get('dragData'));
    },
    mouseDown: function mouseDown() {
      this.set('isMouseDown', true);
      this.set('showContent', false);
    },
    mouseUp: function mouseUp() {
      this.set('isMouseDown', false);
    },
    mouseEnter: function mouseEnter() {
      this.get('isMouseDown') ? this.set('showContent', false) : this.set('showContent', true);
    },
    mouseLeave: function mouseLeave() {
      this.set('showContent', false);
      this.set('isMouseDown', false);
    },
    dragStart: function dragStart(event) {

      var data = this.get('dragData');
      Ember.run.later(this, function () {
        //slightly delay as to not intefere with the dragging
        this.dragDidStart();
      }, 200);
      return event.dataTransfer.setData(this.get('browser.dragDataType'), JSON.stringify(data));
    },
    dragEnd: function dragEnd() {
      this.dragDidEnd();
    }
  });
});
define('client/components/common/ui-graph', ['exports', 'client/mixins/report-chart-color-ordered'], function (exports, _reportChartColorOrdered) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_reportChartColorOrdered.default, {
    classNames: ["ui-graph"],

    graph: null,
    profile: null,
    item: Ember.computed.alias("graph"),
    isHovering: false,
    showFooter: false,
    infotipTexts: null,

    graphTitle: Ember.computed.oneWay('graph.displayTitle'),

    valueFormatType: Ember.computed('graph.valueDimension.firstObject', function () {
      var valueDimension = this.get('graph.valueDimension.firstObject');

      if (valueDimension === 'score') {
        return 'score';
      } else if (valueDimension === 'revenue' || valueDimension === 'formulaic_revenue') {
        return 'money';
      } else if (valueDimension === 'power_of_voice' || valueDimension === 'share_of_voice') {
        return 'percent';
      } else {
        return 'count';
      }
    }),

    legendNameOrderMap: Ember.computed("colorsByGroupDimension", function () {
      if (this.get('item.groupDimension.firstObject') === 'tone_label') {
        // Tone is the only one that doesn't follow the color
        // order
        return {
          'Positive': 0,
          'Neutral': 1,
          'Negative': 2
        };
      }
      return this.get('colorsByGroupDimension');
    }),

    xAxisLabel: Ember.computed('graph.graphType', 'graph.displayLabel', 'graph.displayValue', function () {
      return this.get('graph.graphType') === 'graph-row' ? this.get('graph.displayValue') : this.get('graph.displayLabel');
    }),

    yAxisLabel: Ember.computed('graph.graphType', 'graph.displayLabel', 'graph.displayValue', function () {
      return this.get('graph.graphType') === 'graph-row' ? this.get('graph.displayLabel') : this.get('graph.displayValue');
    }),

    // Action for controller
    drilldown: function drilldown() {},
    onClick: function onClick() {},


    actions: {
      updateInfotipText: function updateInfotipText(info) {
        this.set("infotipTexts", info);
      }
    }
  });
});
define('client/components/common/ui-calendar-range/ui-quick-ranges', ['exports', 'moment', 'client/utils/date-ranges'], function (exports, _moment, _dateRanges) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    init: function init() {
      this._super.apply(this, arguments);
      this.set('customFiscalYearOffset', this.get('customFiscalYearOffset') || {});
    },

    tagName: 'ul',
    classNames: ['ui-quick-ranges'],

    // start of time... or of the profile.
    startOfTime: null,

    // currently selected range
    range: null,

    selectedRange: null,
    customFiscalYearOffset: null,

    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);

      if (this.selectedRange && (!(0, _moment.default)(this.range.from).isSame(this.selectedRange.from) || !(0, _moment.default)(this.range.to).isSame(this.selectedRange.to))) {
        this.selectedRange = null;
      }
    },


    displayRanges: Ember.computed("selectedRange", "range.start", "range.end", "today", "startOfTime", function () {

      var currentRange = this.get("range") || { start: (0, _moment.default)(), end: (0, _moment.default)() };
      return this.computeRanges(_dateRanges.RANGES, currentRange);
    }),

    moreRanges: Ember.computed("selectedRange", "range.start", "range.end", "today", "startOfTime", function () {

      var currentRange = this.get("range") || { start: (0, _moment.default)(), end: (0, _moment.default)() };
      return this.computeRanges(_dateRanges.MORE_RANGES, currentRange);
    }),

    moreRangeActive: Ember.computed("moreRanges.@each.active", function () {
      return this.get("moreRanges").findBy("active");
    }),

    computeRanges: function computeRanges(ranges, currentRange) {
      var selectedRange = this.get("selectedRange"),
          startOfTime = this.get('startOfTime'),
          fiscalOffset = this.get('customFiscalYearOffset');
      return ranges.map(function (range) {
        var liveRange = (0, _dateRanges.calcRangeFor)(range, startOfTime, fiscalOffset);

        var sameAsCurrent = (0, _moment.default)(liveRange.from).isSame(currentRange.start, "day") && (0, _moment.default)(liveRange.to).isSame(currentRange.end, "day");

        liveRange.active = selectedRange ? selectedRange.name === range.name : sameAsCurrent;
        liveRange.disabled = (0, _moment.default)(liveRange.from).isBefore((0, _moment.default)(startOfTime));
        return liveRange;
      });
    },


    // Action called
    onSelect: function onSelect() {},


    actions: {
      onSelectRange: function onSelectRange(range) {
        this.set("selectedRange", range);
        this.get("onSelect")(range.from, range.to);
      }
    }
  });
});
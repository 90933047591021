define('client/components/filter-list', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var FilterListComponent = Ember.Component.extend({
    classNameBindings: [":filter-list"],
    content: null,
    notValue: null,
    sliderRange: null,

    showOptions: Ember.computed("max", function () {
      return !!this.get("max");
    }),

    max: null,
    lastFilter: false,
    showSetClearAll: false,
    showMoreLess: Ember.computed("content.length", "max", function () {
      if (!this.get("max")) {
        return false;
      }
      // Hide more/less if content fits.
      return this.get("content.length") > this.get("max");
    }),

    _showAll: false,
    showAll: Ember.computed("max", "_showAll", function () {
      return this.get("_showAll") || !this.get("max");
    }),

    displayContent: Ember.computed("max", "content.[]", "showOptions", "showAll", function () {
      var content = this.get('content');
      if (this.get("showOptions") && !this.get("showAll") && Ember.isArray(content)) {
        return content.slice(0, this.get("max"));
      }
      return content;
    }),

    IsFiltersOpenedDidChange: Ember.observer("controller.isFiltersOpened", function () {
      if (!this.get("controller.isFiltersOpened") && this.get("_showAll")) {
        this.set("_showAll", false);
      }
    }),

    showStoryLevelSlider: Ember.computed('featureFlags.story_levels', function () {
      return this.featureFlags.get('story_levels');
    }),

    showFilter: Ember.computed('hideFilters', 'filter.hide', function () {
      return !this.get('hideFilters') && !this.get('filter.hide');
    }),

    init: function init() {
      this._super.apply(this, arguments);
      this.set('sliderRange', this.get('sliderRange') || { 'min': 0, 'max': 1 });
    },


    actions: {
      only: function only(filter) {
        function recurse(f) {
          if (filter === f) {
            f.set("value", true);
          } else {
            f.set("value", false);
          }
          if (f.get("children")) {
            f.get("children").forEach(recurse);
          }
        }
        this.get("content").forEach(recurse);
      },

      all: function all() {
        function recurse(f) {
          f.set("value", true);
          if (f.get("children")) {
            f.get("children").forEach(recurse);
          }
        }
        this.get("content").forEach(recurse);
      },

      none: function none() {
        function recurse(f) {
          f.set("value", false);
          if (f.get("children")) {
            f.get("children").forEach(recurse);
          }
        }
        this.get("content").forEach(recurse);
      },

      showMore: function showMore() {
        this.set("_showAll", true);
      },

      showLess: function showLess() {
        this.set("_showAll", false);
      }
    }
  });

  exports.default = FilterListComponent;
});
define('client/utils/search/config', ['exports', 'client/utils/search/option', 'client/utils/search/constants', 'client/utils/languages'], function (exports, _option, _constants, _languages) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var yesNo = ["Yes", "No"];

  // GENERAL OPTIONS

  // version 2
  // const Campaign = Option.extend({
  //   type: C.SELECT_TYPE,
  //   name: "campaign"
  // });
  var Tone = _option.default.extend({
    type: _constants.default.SELECT_TYPE,
    name: "tone",
    tooltip: "Provides 5 tone rating options to search and isolate",
    displayName: "Brand Sentiment",
    displayNameSafe: "Brand Sentiment<div class=\"label label-default\">Twitter only</div>".htmlSafe(),
    values: ["Positive", "Neutral", "Negative"]
  });
  var ToneSource = _option.default.extend({
    type: _constants.default.SELECT_TYPE,
    name: "tone_source",
    tooltip: "Allows you to filter by tone analyzer",
    displayName: "Brand Sentiment Source",
    displayNameSafe: "Brand Sentiment Source<div class=\"label label-default\">Twitter only</div>".htmlSafe(),
    values: [{ name: "machine", value: "prediction", tooltip: "Set by Onclusive sentiment algorithm" }, { name: "human", value: "crowdsourced", tooltip: "Set by crowd sourced company" }, { name: "user", value: "user", tooltip: "Set by a person at your organization" }]
  });

  var SelfLinkPresent = _option.default.extend({
    type: _constants.default.SELECT_TYPE,
    name: "company_website_link",
    displayName: "Company Website Link",
    displayNameSafe: "Company Website Link",
    values: yesNo
  });

  var Language = _option.default.extend({
    type: _constants.default.SELECT_TYPE,
    name: "language",
    tooltip: "Isolates content by specific languages",
    values: _languages.default
  });

  var generalOptions = function generalOptions(profile, user) {
    var campaigns = profile.get("campaigns") || [],
        options = [All.create(), Content.create(), Language.create(), Author.create(), Tone.create()];

    if (user.get('isAirprAdmin')) {
      options.push(ToneSource.create());
    }

    options.push(SelfLinkPresent.create());

    // version 2
    // if(campaigns.get("length") > 0){
    //   options.push(Campaign.create({values: campaigns.mapBy("name")}));
    // }
    return {
      name: "General",
      options: options
    };
  };

  // ARTICLE OPTIONS
  var Title = _option.default.extend({
    type: _constants.default.TEXT_TYPE,
    name: "title",
    tooltip: "Article title only"
  });
  var Content = _option.default.extend({
    type: _constants.default.TEXT_TYPE,
    name: "content",
    tooltip: "Title and body"
  });
  var Author = _option.default.extend({
    type: _constants.default.TEXT_TYPE,
    name: "author",
    tooltip: "Searches author field",
    displayName: "Author",
    displayNameSafe: "Author<div class=\"label label-default\">Beta</div>".htmlSafe()
  });
  var PubName = _option.default.extend({
    type: _constants.default.TEXT_TYPE,
    name: "publication",
    displayName: "Publication",
    tooltip: "Publication name only"
  });
  var PubDomain = _option.default.extend({
    type: _constants.default.INEXACT_TYPE,
    name: "publication_domain",
    tooltip: "Publication URL only"
  });
  var All = _option.default.extend({
    type: _constants.default.TEXT_TYPE,
    name: "all",
    tooltip: "Title, body, author, publication, and URL"
  });

  var articleOptions = function articleOptions() {
    var options = [Title.create(), PubName.create(), PubDomain.create()];

    // for version 2
    // if(profile.get("showCustomer")){
    //   options.push(PotentialCustomer.create({displayName: profile.get("nameCustomer")}));
    // }

    // if(profile.get("showInteraction")){
    //   options.push(Interaction.create({displayName: profile.get("nameInteraction")}));
    // }

    // if(profile.get("showRevenue")){
    //   options.push(Revenue.create({displayName: profile.get("nameRevenue")}));
    // }

    return {
      name: 'Article',
      tooltip: 'For articles only (no social content), search for words that appear in the title, publication name or publication domain.',
      options: options
    };
  };

  // RELATIONSHIP OPTIONS

  var Syndicated = _option.default.extend({
    type: _constants.default.SELECT_TYPE,
    name: "syndicated",
    values: yesNo,
    tooltip: "A new carbon copy of another media placement"
  });
  var FollowOn = _option.default.extend({
    type: _constants.default.SELECT_TYPE,
    name: "followon",
    displayName: "Follow On",
    values: yesNo,
    tooltip: 'Contains a link that references another media placement'
  });

  //version 2
  // const Related = Option.extend({
  //   type: C.SELECT_TYPE,
  //   name: "related",
  //   values: yesNo
  // });

  var relationshipOptions = function relationshipOptions() {
    return {
      name: "Relationship",
      tooltip: 'Ways media placements are related.',
      options: [Syndicated.create(), FollowOn.create()]
    };
  };

  // SOCIAL OPTIONS
  var FacebookTotal = _option.default.extend({
    type: _constants.default.RANGE_TYPE,
    name: "fb_total",
    displayName: "Facebook Total"
  });
  var Twitter = _option.default.extend({
    type: _constants.default.RANGE_TYPE,
    name: "twitter_retweets",
    displayName: "Tweets"
  });
  var GooglePlus = _option.default.extend({
    type: _constants.default.RANGE_TYPE,
    name: "google_plusones",
    displayName: "Google +1"
  });
  var LinkedIn = _option.default.extend({
    type: _constants.default.RANGE_TYPE,
    name: "linkedin_shares"
  });

  var socialOptions = function socialOptions(profile) {
    var group = {
      name: "Social",
      tooltip: "Search for results that contain specific social shares greater than or less than a designated number.",
      options: [FacebookTotal.create()]
    };
    group.options.push(Twitter.create());
    group.options.push(GooglePlus.create());
    group.options.push(LinkedIn.create());
    return group;
  };

  exports.default = Ember.Object.extend({

    categories: Ember.computed("profile.campaigns.[]", "user", function () {
      var profile = this.get("profile"),
          user = this.get('user');

      return [generalOptions(profile, user), socialOptions(profile), articleOptions(profile), relationshipOptions(profile)];
    })

  });
});
define('client/components/checkbox-simple', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['checkbox-simple'],
    value: false,
    title: null,
    indeterminate: null,
    tooltipPlacement: "right",
    tooltipText: null,
    disabled: false
  });
});
define('client/utils/trends/filters', ['exports', 'client/utils/languages', 'client/components/location-filters'], function (exports, _languages, _locationFilters) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var FILTERS = [{
    type: "competitors",
    name: "Competitors",
    title: "How many articles mention my competitors?",
    filterObject: Ember.Object.extend({
      id: null,
      value: null,
      isSelected: false,
      name: null
    })
  }, {
    type: "entities",
    name: "Co-mentions",
    title: "What terms are co-mentioned?",
    description: "Companies, products, brands or person names that are talked about in articles about your topic",
    filterObject: Ember.Object.extend({
      id: null,
      value: null,
      isSelected: false,
      name: null
    })
  }, {
    type: "domains",
    name: "Domains",
    title: "Which publications are picking this up?",
    filterObject: Ember.Object.extend({
      id: null,
      value: null,
      isSelected: false,
      name: null
    })
  }, {
    type: "authors",
    name: "Authors",
    title: "Who's writing about this?",
    filterObject: Ember.Object.extend({
      id: null,
      value: null,
      isSelected: false,
      name: null
    })
  }, {
    type: "countries",
    name: "Locations",
    title: "In which countries is coverage being published?",
    filterObject: Ember.Object.extend({
      id: null,
      value: null,
      isSelected: false,
      name: null
    })
  }, {
    type: "languages",
    name: "Languages",
    title: "In what languages is coverage being published?",
    filterObject: Ember.Object.extend({
      id: null,
      value: null,
      isSelected: false,
      name: null
    })
  }];

  exports.default = FILTERS;
});
define('client/computed/d3-format', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  function d3Format(propertyKey, format) {
    var formatFn = d3.format(format);

    return Ember.computed(propertyKey, function () {
      return formatFn(Ember.get(this, propertyKey));
    });
  }

  d3Format.time = function d3FormatTimeProperty(propertyKey, format) {
    var formatFn = d3.time.format(format);

    return Ember.computed(propertyKey, function () {
      return formatFn(Ember.get(this, propertyKey));
    });
  };

  var smallPercentFormat = d3.format(",.2%");
  var percentFormat = d3.format(",%");

  d3Format.percentage = function (percentage) {
    return percentage < 0.01 & percentage > 0.00 ? smallPercentFormat(percentage) : percentFormat(percentage);
  };

  exports.default = d3Format;
});
define('client/components/domains-manager', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    store: Ember.inject.service(),
    domainUrls: null,

    domains: Ember.computed('domainUrls.[]', function () {

      if (this.get('domainUrls.length')) {
        return this.get('store').query('domain', { url: this.get('domainUrls') });
      }{
        return Ember.A();
      }
    })
  });
});
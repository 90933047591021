define('client/components/graph/interaction-label-options', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    filterSet: null,
    selectionSet: null,

    options: Ember.computed.alias("filterSet.reportingAdapter.filterOptions.interactionLabelOptions"),

    update: function update() {}
  });
});
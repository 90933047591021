define('client/models/article-search-related', ['exports', 'client/models/article-search', 'ember-data'], function (exports, _articleSearch, _emberData) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });


    var ArticleSearchRelated = _articleSearch.default.extend({
        articles: _emberData.default.hasMany("article_related", { async: false })
    });

    ArticleSearchRelated.pathForType = "articles";

    exports.default = ArticleSearchRelated;
});
define('client/models/base-profile', ['exports', 'ember-data', 'client/utils/twitter-settings', 'client/utils/social-settings', 'client/utils/locations-by-region', 'client/report-settings', 'lodash', 'client/utils/date-ranges'], function (exports, _emberData, _twitterSettings, _socialSettings, _locationsByRegion, _reportSettings, _lodash, _dateRanges) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  function aliasWithDefault(path, defaultVal) {
    return Ember.computed(path, {
      get: function get() {
        var v = this.get(path);
        return v === undefined || v === null ? defaultVal : v;
      },
      set: function set(k, v) {
        this.set(path, v);
        this.notifyPropertyChange(k);
        v = this.get(path);
        return v === undefined || v === null ? defaultVal : v;
      }
    });
  }

  function hasFeature(featureName) {
    return Ember.computed('features.[]', function () {
      var features = this.get('features');
      return features && features.indexOf(featureName) !== -1;
    });
  }

  var BaseProfile = _emberData.default.Model.extend({
    user: Ember.computed.alias('currentUser.content'),

    useOriginalAdapter: true,
    _profile_id: Ember.computed.alias("id"),
    profileId: Ember.computed.alias("id"),
    companyName: _emberData.default.attr("string"),
    company_name: Ember.computed.alias("companyName"),
    url: _emberData.default.attr("string"),
    imageUrl: _emberData.default.attr("string"),
    image_url: Ember.computed.alias("imageUrl"),
    isCompetitorProfile: _emberData.default.attr("boolean"),
    isCustomer: _emberData.default.attr("boolean"),
    isCompetitorOfCustomer: _emberData.default.attr("boolean"),
    parent: _emberData.default.belongsTo("profile", { inverse: null, async: false }),
    competitorParent: _emberData.default.belongsTo("profile", { inverse: null, async: false }),
    type_cd: _emberData.default.attr("number"),
    title: _emberData.default.attr("string"),
    analytics_profile: _emberData.default.belongsTo("analytics-profile", { async: true }),
    analytics_profile_id: _emberData.default.attr("string"),

    defaultDateRange: _emberData.default.attr("string", { defaultValue: "all" }),
    applyDefaultDateRangeToCompetitors: _emberData.default.attr("boolean", { defaultValue: true }),
    isDefaultDateRangeFromParent: _emberData.default.attr("boolean"),

    defaultCalculatedRange: Ember.computed("defaultDateRange", "customFiscalYearOffset", "user.defaultDateRange", "startDate", function () {
      var defaultRange = this.get("user.defaultDateRange") || this.get("defaultDateRange"),
          offset = this.get("customFiscalYearOffset"),
          ranges = (0, _dateRanges.calcRangeFor)(defaultRange, this.get("startDate"), offset),
          to = ranges.to,
          from = ranges.from;

      return { from: moment(from).startOf("day").toDate(), to: moment(to).endOf("day").toDate() };
    }),

    // feature flags
    features: _emberData.default.attr("array"),

    facebook_page: _emberData.default.attr("string"),
    twitter_page: _emberData.default.attr("string"),
    linkedin_page: _emberData.default.attr("string"),
    gplus_page: _emberData.default.attr("string"),
    salesforce_email: _emberData.default.attr("string"),
    crm_provider: _emberData.default.attr("string"),
    dashboard: _emberData.default.belongsTo('dashboard'),
    tags: _emberData.default.hasMany("tag", { async: true }),
    whitelistTags: _emberData.default.hasMany("whitelist-tag"),
    competitorWhitelistTags: _emberData.default.hasMany("whitelist-tag", { inverse: null }),
    allWhitelistTags: Ember.computed.union('whitelistTags', 'competitorWhitelistTags'),
    settings: _emberData.default.attr("object"),
    interaction_metrics: _emberData.default.attr("object"),

    competitorColors: _emberData.default.hasMany("profile-competitor-color", { inverse: null }),
    stableCompetitorColors: Ember.computed('competitorColors.@each.{competitorId,color}', "competitors.@each.id", function () {
      var competitorColors = this.get("competitorColors"),
          competitors = this.get("competitors") || [],
          competitorLength = competitors.get('length');

      // if there are enough colors defined, return those
      if (competitorColors && competitorColors.get("length") > 0) {
        //if there are less competitor colors than there are competitors,
        //then fill in the empty slots with default colors
        if (competitorColors.get("length") < competitorLength && competitorLength > 0) {
          return competitors.map(function (competitor, index) {
            var cc = competitorColors.find(function (cc) {
              return +cc.get('competitorId') == +competitor.id;
            });
            return Ember.Object.create({
              competitorId: competitor.get("id"),
              color: cc ? cc.get('color') : _reportSettings.DEFAULT_COLOR_SWATCHES[(index + 1) % _reportSettings.DEFAULT_COLOR_SWATCHES.length]
            });
          });
        } else {
          return competitorColors;
        }
      }

      return (this.get("competitors") || []).map(function (competitor, index) {
        return Ember.Object.create({
          competitorId: competitor.get("id"),
          color: _reportSettings.DEFAULT_COLOR_SWATCHES[(index + 1) % _reportSettings.DEFAULT_COLOR_SWATCHES.length]
        });
      });
    }),

    useCustomColors: Ember.computed.alias("settings.report_use_custom_color"),
    customColors: Ember.computed('settings.report_profile_colors.@each.hex', 'useCustomColors', function () {
      var colors = this.get("settings.report_profile_colors") || [],
          defaultCopy = _reportSettings.DEFAULT_COLOR_SWATCHES.slice(0, 10).map(function (hex, index) {
        return { index: index, hex: hex };
      });
      if (this.get("useCustomColors")) {
        // add the default colors to the array
        defaultCopy = defaultCopy.slice(colors.length);
      } else {
        colors = [];
      }

      return colors.concat(defaultCopy);
    }),
    profileColors: Ember.computed('stableCompetitorColors', 'customColors.@each.hex', function () {
      var competitorColors = this.get('stableCompetitorColors');
      var reportColors = this.get('customColors');
      var color = reportColors.get('firstObject.hex');
      var profileColors = [Ember.Object.create({
        profileId: this.get('id'),
        color: color
      })];
      competitorColors.forEach(function (competitorColor) {
        profileColors.pushObject(Ember.Object.create({
          profileId: competitorColor.get('competitorId'),
          color: competitorColor.get('color')
        }));
      });

      return profileColors;
    }),

    pitchLists: _emberData.default.hasMany("pitch-list", { inverse: "profile" }),

    profileMessageBuckets: _emberData.default.hasMany("profile-message-bucket"),

    corporateMessages: Ember.computed(function () {
      var query = this.store.query('message', {
        profile_message_bucket_id: null,
        profile_id: this.get('id')
      });

      return query;
    }),

    corporateMessageBucket: Ember.computed('corporateMessages.isFulfilled', function () {
      var soundbytes = [];
      if (this.get('corporateMessages.isFulfilled')) {
        soundbytes = this.get('corporateMessages');
      }
      return this.store.createRecord("profile-message-bucket", {
        name: "Corporate",
        messages: soundbytes
      });
    }),

    onboardingValidations: _emberData.default.attr("object"),

    filterSets: _emberData.default.hasMany("filter-set", { inverse: null }),
    defaultFilterSet: _emberData.default.belongsTo("filter-set", { inverse: null }),
    reusableFilterSets: Ember.computed.filterBy('filterSets', 'reusable'),

    startDate: _emberData.default.attr("isodate"),
    created_at: _emberData.default.attr("isodate"),
    header_data: _emberData.default.attr("boolean", { defaultValue: true }),
    associations_loaded: _emberData.default.attr("boolean"),
    onboarding_current_step: _emberData.default.attr("number"),

    isCompany: Ember.computed.equal("type_cd", 0),
    isExecutive: Ember.computed.equal("type_cd", 4),
    isBrand: Ember.computed.equal("type_cd", 2),
    isProduct: Ember.computed.equal("type_cd", 3),

    maxNumberOfCompetitors: 10,

    tagSort: ["name:asc"],
    alphabeticalTags: Ember.computed.sort('tags', 'tagSort'),
    isCompetitor: Ember.computed.bool("settings.can_be_competitor"),
    nameAverageImpactScore: "Average Impact Score",
    nameImpactScore: "Impact Score",
    includeNeutralArticles: aliasWithDefault("settings.include_neutral_articles", false),
    locations: aliasWithDefault("settings.locations", []),
    showRevenue: aliasWithDefault("settings.show_revenue", true),
    showFormulaicRevenue: aliasWithDefault("settings.show_formulaic_revenue", true),
    showInteraction: aliasWithDefault("settings.show_interaction", true),
    showCustomer: aliasWithDefault("settings.show_customer", true),
    _showReadership: aliasWithDefault("settings.show_readership", true),
    showBlog: aliasWithDefault("settings.show_readership", false),
    revenuePerCustomer: aliasWithDefault("settings.revenue_per_customer", 0),
    conversionRate: aliasWithDefault("settings.conversion_rate", 0),
    nameReach: aliasWithDefault("settings.name_reach", "Reach"),
    nameRevenue: aliasWithDefault("settings.name_revenue", "Dollars"),
    nameFormulaicRevenue: aliasWithDefault("settings.name_formulaic_revenue", "Formulaic Revenue"),
    nameCustomer: aliasWithDefault("settings.name_customer", "Potential Customers"),
    nameReadership: aliasWithDefault("settings.name_readership", "Readership"),
    defaultRelevance: aliasWithDefault("settings.default_relevance_filter", 0),
    _showReach: aliasWithDefault("settings.show_reach", false),
    nameInteraction: aliasWithDefault("settings.name_interaction", "Interaction"),
    nameValueInteraction: aliasWithDefault("settings.name_interaction", "Value Interactions"),
    nameTotalInteraction: Ember.computed('nameInteraction', function () {
      return 'Total ' + this.get('nameInteraction');
    }),
    nameUseInteractionCount: Ember.computed('nameInteraction', function () {
      return 'Use ' + this.get('nameInteraction') + ' count instead of percentage';
    }),
    nameInteractionCount: Ember.computed('nameInteraction', function () {
      return this.get('nameInteraction') + ' Count';
    }),
    nameInteractionPercentage: Ember.computed('nameInteraction', function () {
      return this.get('nameInteraction') + ' Percentage';
    }),

    showReadership: Ember.computed('_showReadership', 'onboardingValidations.step_8_hide_readership', {
      get: function get() {
        return this.get('_showReadership') && !this.get("onboardingValidations.step_8_hide_readership");
      },
      set: function set(k, v) {
        this.set('_showReadership', v);
        return this.get('_showReadership') && !this.get("onboardingValidations.step_8_hide_readership");
      }
    }),
    showReach: Ember.computed('_showReach', 'hasImpressions', "onboardingValidations.step_8_hide_reach", {
      get: function get() {
        return this.get('_showReach') && !this.get("onboardingValidations.step_8_hide_reach") && this.get('hasImpressions');
      },
      set: function set(k, v) {
        this.set('_showReach', v);
        return this.get('_showReach') && !this.get("onboardingValidations.step_8_hide_reach") && this.get('hasImpressions');
      }
    }),
    showReachSplit: Ember.computed("isCompetitorProfile", "settings.show_reach_split", "parent.showReachSplit", "competitorParent", {
      get: function get() {
        var value = this.get("settings.show_reach_split");
        if (value) {
          return true;
        }
        if (this.get("isCompetitorProfile")) {
          value = this.get("parent.showReachSplit") || this.get("competitorParent.showReachSplit");
        }
        if (value === undefined || value === null) {
          value = false;
        }
        return value;
      },
      set: function set(k, v) {
        this.set("settings.show_reach_split", v);
        this.notifyPropertyChange(k);
        v = this.get("settings.show_reach_split");
        return v === undefined || v === null ? false : v;
      }
    }),
    defaultFilters: Ember.computed(function () {
      var filters = {
        relevance: this.get('defaultRelevance'),
        influential: true,
        uninfluential: false,
        whitelist_tag_ids: this.get('allWhitelistTags').mapBy('id'),
        media_earned: true,
        media_owned: true,
        press_release: true,
        news: true,
        linkedin_pulse: true
      };
      this.get('allLocations').forEach(function (location) {
        filters['lcn_' + location.id] = true;
      });
      return filters;
    }).volatile(),

    defaultFiltersWithoutLocation: Ember.computed(function () {
      var filters = {
        relevance: this.get('defaultRelevance'),
        influential: true,
        uninfluential: false,
        whitelist_tag_ids: this.get('allWhitelistTags').mapBy('id'),
        media_earned: true,
        media_owned: true,
        press_release: true,
        news: true,
        linkedin_pulse: true
      };
      return filters;
    }).volatile(),

    getLocationsForRegion: function getLocationsForRegion(region, location) {
      if (!this.get('locations.length')) {
        return _locationsByRegion.default[region];
      }
      var profileLocations = this.get('locations');
      return Ember.A(_locationsByRegion.default[region]).filter(function (location) {
        return profileLocations.indexOf(location.iso) >= 0;
      });
    },

    northAmericaLocations: Ember.computed('locations.[]', function () {
      return this.getLocationsForRegion('NA');
    }),

    southAmericaLocations: Ember.computed('locations.[]', function () {
      return this.getLocationsForRegion('SA');
    }),

    africaLocations: Ember.computed('locations.[]', function () {
      return this.getLocationsForRegion('AF');
    }),

    asiaLocations: Ember.computed('locations.[]', function () {
      return this.getLocationsForRegion('AS');
    }),

    europeLocations: Ember.computed('locations.[]', function () {
      return this.getLocationsForRegion('EU');
    }),

    oceaniaLocations: Ember.computed('locations.[]', function () {
      return this.getLocationsForRegion('OC');
    }),

    antarcticaLocations: Ember.computed('locations.[]', function () {
      return this.getLocationsForRegion('AN');
    }),

    otherLocations: Ember.computed('locations.[]', function () {
      return this.getLocationsForRegion('XX');
    }),

    allLocations: Ember.computed.union('northAmericaLocations', 'southAmericaLocations', 'africaLocations', 'asiaLocations', 'europeLocations', 'oceaniaLocations', 'antarcticaLocations', 'otherLocations'),

    customFiscalYearEndDate: Ember.computed('settings.custom_fiscal_year_end_date', {
      get: function get() {
        var customDateString = this.get('settings.custom_fiscal_year_end_date');
        var endOfYearDate = customDateString ? new Date(customDateString) : d3.time.day.offset(d3.time.year(new Date()), -1);
        return endOfYearDate;
      },
      set: function set(k, v) {
        this.set('settings.custom_fiscal_year_end_date', v.toISOString());
        return v;
      }
    }),

    customFiscalYearOffset: Ember.computed('customFiscalYearEndDate', {
      get: function get() {
        // We only care about the month and day offset, year is ignored, also
        // the day offset is special depending on the end of month
        var endDate = this.get('customFiscalYearEndDate');
        var month = endDate.getMonth();
        // Cannot remove this because the end date for the month may change
        // depending on the year (thanks february)
        var endOfMonth = d3.time.day.offset(d3.time.month.offset(d3.time.month(endDate), 1), -1);
        var day = endOfMonth.getDate() - endDate.getDate() < 1 ? null : endDate.getDate();
        return { month: month, day: day };
      }
    }),

    hasArticleSentimentFeature: Ember.computed('settings.article_sentiment', {
      get: function get() {
        return this.get('settings.article_sentiment');
      }
    }),

    hasAuthorIntelligence: hasFeature("author_intelligence"),

    showAuthorIntelligence: Ember.computed('hasAuthorIntelligence', 'currentUser.liteVersion', function () {
      return this.get('hasAuthorIntelligence') || this.get('currentUser.liteVersion');
    }),

    hasBroadcast: hasFeature("broadcast"),

    hasBroadcastFullPlay: hasFeature("broadcast_full_player"),

    hasBroadcastTV: hasFeature("broadcast_cm_tv"),

    hasBroadcastRadio: hasFeature("broadcast_cm_radio"),

    hasBroadcastPodcast: hasFeature("broadcast_cm_podcast"),

    hasBroadcastYoutube: hasFeature("broadcast_cm_youtube"),

    hasPrint: hasFeature("print_coverage"),

    hasRelevanceSlider: hasFeature("relevance_slider"),

    showStoryLevels: hasFeature("story_levels"),

    hasAllInteractionsDisabled: hasFeature("disable_total_interactions_sort"),

    hasVisitorCountBreakdown: hasFeature("visitor_count_breakdown"),

    hasImpressions: hasFeature("reach"),

    hasImpactScore: hasFeature("impact_score"),

    hasRosetteSentimentFeature: hasFeature("rosette_sentiment"),

    hasBroadcastSentiment: hasFeature("broadcast_sentiment"),

    hasArticleSentiment: Ember.computed("hasArticleSentimentFeature", "hasRosetteSentimentFeature", function () {
      return this.get('hasArticleSentimentFeature') || this.get('hasRosetteSentimentFeature');
    }),

    hasTranslation: hasFeature("translation"),

    nameSentiment: Ember.computed("hasArticleSentiment", function () {
      return this.get("hasArticleSentiment") ? "Brand Sentiment" : "Social Sentiment";
    }),

    hasSetTone: hasFeature("set_tone"),

    hasInsights: hasFeature("insights"),

    // TO REMOVE: same as hasPrint
    hasPrintCoverage: hasFeature("print_coverage"),

    hasMergePers: hasFeature("merge_pers"),

    hasAbbreviatedNumbersInAnalyze: hasFeature("abbreviated_numbers_in_analyze"),

    showSearchSeeds: hasFeature("show_search_seeds"),

    hasCompetitorSearchTerm: hasFeature("competitor_search_term"),

    hasPOV2: hasFeature("pov2"),

    hasPOV2UI: hasFeature("pov2_ui"),

    hasPOV2Sentiment: hasFeature("pov2_sentiment"),

    showValueInteraction: hasFeature("show_value_interaction"),

    hasOnPromote: hasFeature("on_promote"),

    hideStoryLevels: Ember.computed.not("showStoryLevels"),

    hasIptc: hasFeature("iptc"),

    needsReloading: false,

    hasLegacyTwitter: Ember.computed('startDate', function () {
      return this.get('startDate') <= _twitterSettings.default.COUNTS_END_DATE;
    }),

    startedBeforeGnip: Ember.computed('startDate', function () {
      return this.get('startDate') <= _twitterSettings.default.GNIP_START_DATE;
    }),

    gplusAvailable: Ember.computed("startDate", function () {
      // cut off on september 1st 2017
      return this.get("startDate") < _socialSettings.default.GOOGLEPLUS_END;
    }),
    linkedinAvailable: Ember.computed("startDate", function () {
      // cut off on february 15 2018
      return this.get("startDate") < _socialSettings.default.LINKEDIN_END;
    }),

    hasImageUrl: Ember.computed('image_url', function () {
      return this.get('image_url') !== '/assets/dashboard-placeholder.png';
    }),

    name: Ember.computed.alias('companyName'),

    tiles: Ember.computed("id", function () {
      var profile = this.get("id");
      return this.store.findRecord("tile", profile);
    }),

    savedSearches: Ember.computed("id", function () {
      var profile_id = this.get("id");
      return this.store.query("saved-search", { profile_id: profile_id });
    }),

    //
    // inverse: null is required as messages are shared between profiles
    // so we do not want ember data to automatically deal with association,
    // it should just stick to the data
    //
    messages: _emberData.default.hasMany("message", { inverse: null }),
    soundbytes: Ember.computed.alias("messages"),

    facebook_pages: Ember.computed("id", function () {
      var profile = this.get("id"),
          pages = Ember.ArrayProxy.create();
      $.get("/v2/api/profiles/" + profile + "/facebook_pages").then(function (data) {
        pages.set("content", data.facebook_pages);
      });
      return pages;
    }),

    twitter_pages: Ember.computed("id", function () {
      var profile = this.get("id"),
          pages = Ember.ArrayProxy.create();
      $.get("/v2/api/profiles/" + profile + "/twitter_pages").then(function (data) {
        pages.set("content", data.twitter_pages);
      });
      return pages;
    }),

    gplus_pages: Ember.computed("id", function () {
      var profile = this.get("id"),
          pages = Ember.ArrayProxy.create();
      $.get("/v2/api/profiles/" + profile + "/gplus_pages").then(function (data) {
        pages.set("content", data.gplus_pages);
      });
      return pages;
    }),

    linkedin_pages: Ember.computed("id", function () {
      var profile = this.get("id"),
          pages = Ember.ArrayProxy.create();
      $.get("/v2/api/profiles/" + profile + "/linkedin_pages").then(function (data) {
        pages.set("content", data.linkedin_pages);
      });
      return pages;
    }),

    parents: Ember.computed("parent", function () {
      var parent = this.get("parent"),
          parents = [];
      parents.push(this);
      while (parent) {
        parents.unshift(parent);
        parent = parent.get("parent");
      }
      return parents;
    }),

    children: [],
    profileTypes: [],

    deauthorize: function deauthorize(type) {
      var that = this;
      $.post("/v2/api/profiles/" + this.get("id") + "/social_media/unlink", {
        social_media_network: type
      }).then(function () {
        that.set(type + "_page", null);
      });
    }

  });

  exports.default = BaseProfile;
});
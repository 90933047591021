define('client/components/content-filters', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var ContentFilters = Ember.Component.extend({
    news: true,
    linkedin_pulse: true,
    twitter: false,
    gplus: false,
    radio: false,
    television: false,
    printPublication: false,
    podcast: false,
    youtube_bc: false,

    toggleNews: function toggleNews() {},
    toggleLinkedInPulse: function toggleLinkedInPulse() {},
    toggleTwitter: function toggleTwitter() {},
    toggleGplus: function toggleGplus() {},
    toggleRadio: function toggleRadio() {},
    toggleTelevision: function toggleTelevision() {},
    togglePodcast: function togglePodcast() {},
    toggleYoutubeBc: function toggleYoutubeBc() {},
    togglePrintPublication: function togglePrintPublication() {},


    actions: {
      toggleNews: function toggleNews() {
        this.toggleNews();
      },
      toggleLinkedInPulse: function toggleLinkedInPulse() {
        this.toggleLinkedInPulse();
      },
      toggleTwitter: function toggleTwitter() {
        this.toggleTwitter();
      },
      toggleGplus: function toggleGplus() {
        this.toggleGplus();
      },
      toggleRadio: function toggleRadio() {
        this.toggleRadio();
      },
      toggleTelevision: function toggleTelevision() {
        this.toggleTelevision();
      },
      togglePodcast: function togglePodcast() {
        this.togglePodcast();
      },
      toggleYoutubeBc: function toggleYoutubeBc() {
        this.toggleYoutubeBc();
      },
      togglePrintPublication: function togglePrintPublication() {
        this.togglePrintPublication();
      }
    }
  });

  exports.default = ContentFilters;
});
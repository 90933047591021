define('client/helpers/join', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.join = join;
  function join(array) {
    var joiningChar = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : ', ';

    if (!Ember.isArray(array) || Ember.isEmpty(array)) {
      return array;
    }
    return array.join(joiningChar);
  }

  exports.default = Ember.Helper.helper(function (params, hashParams) {
    return join(params[0], hashParams.char);
  });
});
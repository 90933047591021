define('client/routes/research/authors/author', ['exports', 'moment'], function (exports, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    model: function model(params, transition) {
      var type = transition.queryParams.type;
      var term = transition.queryParams.term;
      if (type == 'author' && !Ember.isBlank(transition.queryParams.term)) {
        term = '';
      }
      this.controllerFor('research.authors.author').set('term', term);
      return params.id;
    }
  });
});
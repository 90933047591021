define('client/components/trends/trends-graph', ['exports', 'moment', 'client/utils/chart-data-transformer', 'client/mixins/report-chart-color-ordered', 'client/utils/time-series-labeler'], function (exports, _moment, _chartDataTransformer, _reportChartColorOrdered, _timeSeriesLabeler) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_reportChartColorOrdered.default, {
    classNames: ["trends-graph"],

    social: false,
    socialCounts: null,
    socialCountsRanges: null,
    rangeChanged: function rangeChanged() {},


    dataTransformer: Ember.computed('graphValues', 'defaultValue', 'parsedData', 'fromDate', 'toDate', function () {
      var graphDateBinType = "Day";
      if ((0, _moment.default)(this.get('toDate')).diff((0, _moment.default)(this.get('fromDate')), 'months') > 3) {
        graphDateBinType = "Month";
      }
      return _chartDataTransformer.default.create({
        graphValues: this.get('graphValues'),
        fromDate: this.get('fromDate'),
        toDate: this.get('toDate'),
        graphDateBinType: graphDateBinType,
        graphLabelKeys: ["date"],
        parsedData: this.get('parsedData'),
        defaultValue: this.get('defaultValue')
      });
    }),

    rebinnedData: Ember.computed.alias('dataTransformer.rebinnedData'),

    graphValues: Ember.computed(function () {
      return {
        fbTotal: "count",
        pinterestPins: "count",
        docCount: "count"
      };
    }),

    nulledDefaultValuesKeys: Ember.computed("graphValues", function () {
      var values = Object.keys(this.get('graphValues'));
      return values.reduce(function (prev, valueKey) {
        prev[valueKey] = null;
        return prev;
      }, {});
    }),

    defaultValue: Ember.computed("nulledDefaultValuesKeys", 'loadedRange', function () {
      var nulledDefaultValuesKeys = this.get("nulledDefaultValuesKeys");
      var loadedRange = this.get('loadedRange');
      return function (date, defaultValues) {
        if (loadedRange) {
          var d = (0, _moment.default)(date),
              from = (0, _moment.default)(loadedRange.from);
          if (d.isAfter(from, "day") || d.isSame(from, "day")) {
            return defaultValues;
          }
        }
        // blank value for blank line
        return nulledDefaultValuesKeys;
      };
    }),

    loadedRange: Ember.computed("socialCountsRanges.firstObject", "socialCountsRanges.lastObject", function () {
      var first = this.get("socialCountsRanges.firstObject"),
          last = this.get("socialCountsRanges.lastObject");
      if (!first || !last) {
        return null;
      }
      return { from: last.from, to: first.to };
    }),

    parsedData: Ember.computed("socialCounts", function () {
      var counts = this.get("socialCounts");
      if (Ember.isEmpty(counts)) {
        return [];
      }
      return counts.map(function (c) {
        return c.getProperties("fbTotal", "pinterestPins", "docCount", "date");
      });
    }),

    data: Ember.computed("social", "rebinnedData", function () {
      var counts = this.get("rebinnedData"),
          colorPalette = this.get('colorPalette');

      if (Ember.isEmpty(counts)) {
        return [];
      }

      if (this.get("social")) {
        // convert to reporting format
        return counts.map(function (c) {
          return [{
            labels: [c.date, "Facebook"],
            values: [c.fbTotal],
            color: colorPalette[0]
          }, {
            labels: [c.date, "Pinterest"],
            values: [c.pinterestPins],
            color: colorPalette[1]
          }];
        }).flatten();
      } else {
        return counts.map(function (c) {
          return {
            labels: [c.date, "Articles"],
            values: [c.docCount],
            color: colorPalette[0]
          };
        });
      }
    }),

    legend: null,
    valueFormatType: "count",
    minColumnWidth: 3,

    hasSelection: Ember.computed("brushedSelection", "selection", function () {
      return this.get("selection") ? this.get("brushedSelection") : null;
    }),

    init: function init() {
      this._super.apply(this, arguments);
      this.set('legend', this.get('legend') || { "Facebook": 0, "Pinterest": 1, "Articles": 0 });
    },


    actions: {
      brushed: function brushed(domain, graphDateBinType) {
        var graphBinType = this.get('dataTransformer').graphDateBinType;
        var momentFormat = graphBinType === 'Day' ? 'MMM DD, YYYY' : 'MMM \'YY';

        this.set("brushedSelection", domain);

        domain = !domain ? domain : domain.map(function (d) {
          return d ? (0, _moment.default)(d.group, momentFormat).toDate() : null;
        });
        this.rangeChanged(domain, graphBinType);
      },
      clearSelection: function clearSelection() {
        this.rangeChanged(null);
      }
    }
  });
});
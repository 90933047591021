define('client/utils/rich-text-fonts', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    HELVETICA: { name: 'Helvetica', family: "Helvetica, Arial" },
    TAHOMA: { name: 'Tahoma', family: "Tahoma, Geneva" },
    LUCIDA: { name: 'Lucida Sans', family: '"Lucida Sans Unicode", "Lucida Grande"' },
    VERDANA: { name: 'Verdana', family: "Verdana, Geneva" },
    TIMES_NEW_ROMAN: { name: 'Times New Roman', family: '"Times New Roman", Times' },
    GARAMOND: { name: 'Garamond', family: "Garamond, Georgia" },
    PALATINO: { name: 'Palatino', family: '"Palatino Linotype", "Book Antiqua3", Palatino' },
    COURIER_NEW: { name: 'Courier New', family: '"Courier New", Courier' }
  };
});
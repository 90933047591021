define('client/components/insights/publication-recommendations', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['publication-recommendations', 'recommendations'],
    domainUrls: Ember.computed.mapBy('recommendations', 'var')
  });
});
define('client/components/reports/main-container', ['exports', 'client/mixins/resize'], function (exports, _resize) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_resize.default, {
    classNameBindings: [":report-main"],
    isLoading: false,
    report: null,

    onPreviousPage: function onPreviousPage() {},
    onCloseEdit: function onCloseEdit() {},
    refresh: function refresh() {},
    onNextPage: function onNextPage() {},


    minHeight: 450,
    useParent: true,

    style: Ember.computed(function () {
      if (this._state !== "inDOM") {
        return;
      }

      var parent = this.get("useParent") ? this.$().parent() : this.$();
      var parentHeight = parent.outerHeight(),
          parentWidth = parent.outerWidth(),
          h = Math.max(parentHeight - 17, this.get("minHeight")),
          w = parseInt(h * (4 / 3)),
          transformOrigin = '0px 0px',
          left = 0;

      // Center the page
      left = parentWidth / 2 - w / 2;
      if (left < 0) {
        left = 0;
      }
      var ratio = w / 1024;
      return new Ember.String.htmlSafe('transform:scale3d(' + ratio + ', ' + ratio + ', 1); left: ' + left + 'px; transform-origin: ' + transformOrigin + ';');
    }),

    didInsertElement: function didInsertElement() {
      var _this = this;

      this._super.apply(this, arguments);
      this.handleResize();
      this.$().on("mousewheel." + this.get("elementId"), function (e) {
        return _this.wheel(e);
      });
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);
      this.$().off("mousewheel." + this.get("elementId"));
    },
    wheel: function wheel(e) {

      // check if the scroll is either 0 or scrollheight
      var oe = e.originalEvent,
          $container = Ember.$("html,body"),
          height = $container.height(),
          scrollTop = $container.scrollTop(),
          scrollHeight = $container[0].scrollHeight,
          isEdit = this.get("reportService.isEdit") || this.get("reportService.isEditText");

      if (isEdit) {
        return;
      }

      if (scrollTop > 0 && scrollTop < scrollHeight - height || oe.deltaY < 30 && oe.deltaY > -30) {
        // still scrolling
        return;
      }

      e.preventDefault();
      e.stopImmediatePropagation();

      if (oe.deltaY < -30) {
        // going up
        Ember.run.cancel(this._downBounce);
        this._upBounce = Ember.run.debounce(this, "previousPage", 100, true);
      } else if (oe.deltaY > 30) {
        // going down
        Ember.run.cancel(this._upBounce);
        this._downBounce = Ember.run.debounce(this, "nextPage", 100, true);
      }
    },
    previousPage: function previousPage() {
      this.onPreviousPage();
      Ember.$(".reports-container").scrollTop(0);
    },
    nextPage: function nextPage() {
      this.onNextPage();
      Ember.$(".reports-container").scrollTop(0);
    },


    onResize: Ember.on("resize", function (promise) {
      // this forces the refresh. Might be a bug in chrome for all I know.
      Ember.run.scheduleOnce("afterRender", this, function () {
        this.refresh();

        // Forcing a repaint because we need
        // text in the page to be re-rendered
        // this causes the blurriness to vanish
        var elem = this.$();
        elem.css('display', 'none');
        elem[0].offsetHeight; // jshint ignore:line
        elem.css('display', '');

        if (promise) {
          Ember.run.later(this, function () {
            this.notifyPropertyChange("style");
            promise();
          }, 100);
        }
      });
    }),

    click: function click() {
      var isEdit = this.get("reportService.isEdit") || this.get("reportService.isEditText");
      if (isEdit) {
        this.onCloseEdit();
      }
    }
  });
});
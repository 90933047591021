define('client/components/research/graph-card', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ["research-card", "graph-card"],

    socialCounts: null,

    parsedData: Ember.computed("socialCounts.content", function () {
      var counts = this.get("socialCounts.content");
      if (Ember.isEmpty(counts)) {
        return [];
      }
      return counts.map(function (c) {
        return c.getProperties("fbTotal", "pinterestPins", "docCount", "date");
      });
    })
  });
});
define('client/components/reports/report-score', ['exports', 'client/components/reports/base-widget', 'client/mixins/resize'], function (exports, _baseWidget, _resize) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _baseWidget.default.extend(_resize.default, {
    classNameBindings: [":report-score"],
    endpoint: "score",
    size: 1,
    calcSize: Ember.on("resize", "didInsertElement", function (promise) {
      var size = this.$().height() - 10;
      this.set("size", isNaN(size) ? 1 : size);

      if (promise) {
        promise();
      }
    }),

    actions: {
      changeSize: function changeSize(size) {
        this._super(size);
        Ember.run.schedule("afterRender", this, "calcSize");
      }
    }
  });
});
define('client/components/article-list/article-item-sort-values', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: '',
    profileService: Ember.inject.service('profile'),
    content: null,
    sort: null,
    hasSyndicates: null,
    minView: false,
    showInteractionCount: false,

    /**
     * Display informational text based on selected sort option if article is outside the selected date-range.
     */

    visitorTooltip: Ember.computed.alias('profileService.profile.nameCustomer'),
    interactionTooltip: Ember.computed.alias('profileService.profile.nameTotalInteraction'),
    nameRevenueTooltip: Ember.computed.alias('profileService.profile.nameRevenue'),

    displayEstimatedAudience: Ember.computed('content.isBroadcast', 'content.isPrintPublication', function () {
      var isBroadcastable = this.get('content.isBroadcast');
      if (isBroadcastable) {
        return true;
      }

      var sort = this.get('sort');
      var isPrintPublishable = this.get('content.isPrintPublication');
      if (isPrintPublishable && sort !== "impact_score") {
        return true;
      }

      return false;
    }),

    articleInfo: Ember.computed('sort', 'content.date', 'profileService.{from,to}', function () {
      var info = null; // default
      var articleDate = this.get('content.date');
      var from = moment(this.get('profileService.from'));
      var to = moment(this.get('profileService.to'));
      var isBetween = moment(articleDate).isBetween(from, to, 'day', '[]'); //[] param used to include same date
      if (isBetween) {
        return null;
      }
      var timeRange = from.format('MM/DD/YYYY') + ' to ' + to.format('MM/DD/YYYY');
      var sort = this.get('sort');
      if (sort === 'date') {
        info = 'This article is being displayed as it has one or more syndicates that were published between ' + timeRange;
      } else {
        var metricName = null;
        switch (sort) {
          case 'visitors':
            metricName = this.get('visitorTooltip');break;
          case 'revenue':
            metricName = this.get('nameRevenueTooltip');break;
          case 'all_interaction':
            metricName = this.get('interactionTooltip');break;
        }
        if (metricName) {
          info = 'This article is being displayed in this list as it drove ' + metricName + ' during ' + timeRange;
        }
      }
      return info;
    }),
    showPOV2indicators: Ember.computed('sort', 'profileService.profile.hasPOV2UI', function () {
      return this.get('profileService.profile.hasPOV2UI') && (this.get('sort') === 'pov' || this.get('sort') === 'pov_rel_amp_sent');
    }),
    totalAmplification: Ember.computed('content.facebook_count', 'content.gplus_count', 'content.twitter_count', 'content.linkedin_count', 'content.pinterest_count', function () {
      return this.get('content.facebook_count') + this.get('content.gplus_count') + this.get('content.twitter_count') + this.get('content.linkedin_count') + this.get('content.pinterest_count');
    }),
    showSyndicateValues: Ember.computed('hasSyndicates', 'minView', function () {
      //Always show values when in min mode
      return this.get('minView') || !this.get('hasSyndicates');
    })

  });
});
define('client/components/research/similar-authors-card', ['exports', 'ember-data', 'client/mixins/models/query-consumer'], function (exports, _emberData, _queryConsumer) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_queryConsumer.default, {
    classNames: ["research-card", "similar-authors-card"],

    profile: Ember.computed.alias('profileService.profile'),

    // the author to load similar from
    author: null,

    similarAuthors: Ember.computed("profile.id", "author.id", "searchTerm", function () {
      var authorId = this.get("author.id");
      if (authorId) {
        var query = this.queryByName("research/similar-authors");
        return query.load();
      } else {
        // this is a never resolving promise to show loading
        return _emberData.default.PromiseObject.create({ promise: new Ember.RSVP.Promise(function () {}) });
      }
    }),

    actions: {}

  });
});
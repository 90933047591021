define('client/components/insights/top-publications', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['top-publications', 'results'],

    domainUrls: Ember.computed.mapBy('recommendations', 'var')
  });
});
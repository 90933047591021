define("client/helpers/format-interaction-percent", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.formatInteractionPercent = formatInteractionPercent;
  function formatInteractionPercent(v) {
    // If the interaction percentage is < 1, we need to show the actual value upto 2 decimal places
    if (v < 0.01 & v > 0.00) {
      return d3.format(",.2%")(v);
    } else {
      return d3.format(",%")(v);
    }
  }

  exports.default = Ember.Helper.helper(formatInteractionPercent);
});
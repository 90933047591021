define('client/mixins/models/has-endpoint-actions', ['exports', 'ember-data', 'client/computed/index'], function (exports, _emberData, _index) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.endpointAction = endpointAction;


  var EndpointActionPromise = _emberData.default.PromiseObject.extend({
    actionName: '',
    name: Ember.computed.alias('actionName'),
    model: undefined
  });

  // TODO: This could possibly mix very well with Ember Concurrency
  exports.default = Ember.Mixin.create({
    pendingActions: (0, _index.arrayProperty)(),

    pendingActionsByName: Ember.computed('pendingActions.@each.actionName', function () {
      return this.get('pendingActions').reduce(function (actionMap, actionPromise) {
        var actionPromiseName = Ember.get(actionPromise, 'actionName');
        if (!actionMap[actionPromiseName]) {
          actionMap[actionPromiseName] = Ember.A();
        }
        actionMap[actionPromiseName].pushObject(actionPromise);
        return actionMap;
      }, Ember.Object.create());
    }),

    hasPendingActions: Ember.computed.notEmpty('pendingActions'),

    dispatchEndpointAction: function dispatchEndpointAction(actionName) {
      if (!this.store) {
        throw new Ember.Error('hasEndpointActions Mixin requires access to the store.');
      }

      var modelName = this.constructor.modelName,
          adapter = this.store.adapterFor(modelName);

      var actionPromise = adapter.dispatchAction(actionName, this),
          pendingActions = Ember.get(this, 'pendingActions');

      var actionPromiseObject = EndpointActionPromise.create({
        actionName: actionName,
        model: this,
        promise: actionPromise
      });

      pendingActions.addObject(actionPromiseObject);
      actionPromise.finally(function () {
        return pendingActions.removeObject(actionPromiseObject);
      });

      return actionPromiseObject;
    }
  });
  function endpointAction(actionName) {
    return function () {
      this.dispatchEndpointAction(actionName);
    };
  }
});
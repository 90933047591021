define('client/components/popover-content', ['exports', 'ember-click-outside/mixin'], function (exports, _mixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var PopoverContentComponent = Ember.Component.extend(_mixin.default, {
    classNameBindings: [':popover', ':fade', ':in', 'showArrow:bottom', 'name'],

    isOpen: Ember.computed.alias('parentView.isOpen'),
    showArrow: true,
    reposition: true,
    position: null,
    align: "left",
    popover: null,

    // TODO(Tony) Popovers triggered by hover care about this, maybe this
    // should be done in a more specific content type??
    mouseEnter: function mouseEnter() {
      this.set('parentView.isMouseOverContent', true);
    },

    mouseLeave: function mouseLeave() {
      this.set('parentView.isMouseOverContent', false);
    },

    clickOutside: function clickOutside(e) {
      this.set('isOpen', false);
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      this.show();
      Ember.run.next(this, this.addClickOutsideListener);
    },


    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);
      this.removeClickOutsideListener();
    },

    show: function show() {
      if (this.get('reposition')) {
        var align = this.get("align");
        var $trigger = this.get('parentView.triggerView').$();
        var triggerPosition = this.get('position') ? this.get('position') : $trigger.position();
        if (align === "center") {
          this.$().css({
            left: triggerPosition.left + ($trigger.width() - this.$().width()) / 2
          });
        } else if (align === "right") {
          this.$().css({
            left: "auto",
            right: 0
          });
        }
      }
    }
  });

  exports.default = PopoverContentComponent;
});
define('client/queries/neo', ['exports', 'client/queries/query', 'ember-concurrency'], function (exports, _query, _emberConcurrency) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  exports.default = _query.default.extend({

    // original filter set the query will be based on.
    filterSet: null,
    // the messages we will be querying for
    messages: null,

    from: null,
    to: null,

    store: Ember.inject.service(),

    load: function load() {
      var _getProperties = this.getProperties("store", "filterSet", "messages", "from", "to"),
          store = _getProperties.store,
          filterSet = _getProperties.filterSet,
          messages = _getProperties.messages,
          from = _getProperties.from,
          to = _getProperties.to,
          newFilterSet = filterSet.copy ? filterSet.copy() : filterSet.content.copy(),
          fromDate = moment(from).toDate() || filterSet.get("profile.startDate"),
          toDate = moment(to).toDate() || new Date(),
          raw = true;

      newFilterSet.get("soundbytes").addObjects(messages);
      // create a graph model
      var coverageGraph = store.createRecord("graph", {
        labelDimension: ["neo"],
        valueDimension: ["coverage"],
        filterSet: filterSet,
        selectionSet: newFilterSet,
        fromDate: fromDate,
        toDate: toDate,
        raw: raw
      });

      var amplificationGraph = store.createRecord("graph", {
        labelDimension: ["neo"],
        valueDimension: ["amplification"],
        filterSet: filterSet,
        selectionSet: newFilterSet,
        fromDate: fromDate,
        toDate: toDate,
        raw: raw
      });

      var visitorGraph = store.createRecord("graph", {
        labelDimension: ["neo"],
        valueDimension: ["visitor"],
        filterSet: filterSet,
        selectionSet: newFilterSet,
        fromDate: fromDate,
        toDate: toDate,
        raw: raw
      });

      var interactionGraph = store.createRecord("graph", {
        labelDimension: ["neo"],
        valueDimension: ["interaction"],
        filterSet: filterSet,
        selectionSet: newFilterSet,
        fromDate: fromDate,
        toDate: toDate,
        raw: raw
      });

      var promise = Ember.RSVP.all([coverageGraph.get("dataQuery"), amplificationGraph.get("dataQuery"), visitorGraph.get("dataQuery"), interactionGraph.get("dataQuery")]).then(function (_ref) {
        var _ref2 = _slicedToArray(_ref, 4),
            coverage = _ref2[0],
            amps = _ref2[1],
            visitors = _ref2[2],
            interactions = _ref2[3];

        // group all those together
        return messages.filter(function (m) {
          return Ember.isPresent(m.id);
        }).map(function (message) {
          var id = message.get("id"),
              interaction = interactions.findBy("neo", id).interaction;
          return {
            messageId: id,
            coverage: coverage.findBy("neo", id).coverage,
            amplification: amps.findBy("neo", id).amplification.total_count || 0,
            visitor: visitors.findBy("neo", id).visitor,
            interaction: interaction ? interaction.total_interaction : 0
          };
        });
      });

      this.set("promise", promise);
      return this;
    }
  });
});
define("client/models/profile-impact-score-setting", ["exports", "ember-data"], function (exports, _emberData) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var ProfileImpactScoreSetting = _emberData.default.Model.extend({
    useSentiment: _emberData.default.attr("boolean"),
    useAmplification: _emberData.default.attr("boolean"),
    profile: _emberData.default.belongsTo('profile')
  });

  exports.default = ProfileImpactScoreSetting;
});
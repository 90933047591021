define('client/components/research/author-reason', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNameBindings: [':author-reasons', "reason::hide"],
    store: Ember.inject.service(),
    reason: Ember.computed("authorId", function () {
      return this.get("store").peekRecord("author-reason", this.get("authorId"));
    })
  });
});
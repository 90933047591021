define('client/components/common/ui-close', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['ui-close'],
    bubbles: false,
    actions: {
      close: function close() {
        this.get('onClick')();
      }
    }
  });
});
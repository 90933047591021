define('client/components/article-extended-tags', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    allTags: null,
    article: null,
    refresh: false,

    showTools: true,
    modal: Ember.inject.service(),

    untagArticle: function untagArticle() {},

    unselectedTags: Ember.computed("article.tags.[]", function () {
      var ids = this.article.tags.map(function (e) {
        return e.id;
      });

      var tags = this.allTags.filter(function (e) {
        return !ids.includes(e.id);
      });
      return tags;
    }),

    tagList: Ember.computed("article.tags", function () {
      var tags = this.article.tags;
      return tags;
    }),

    actions: {
      tagSyndicatePopUp: function tagSyndicatePopUp(article, tag) {
        this.get('modal').open('article-list/tag-syndicates-addition', {
          article: article,
          tag: tag,
          tagArticle: this.tagArticle

        });
      },
      toggleRefresh: function toggleRefresh() {
        this.set("refresh", !this.refresh);
      }
    }
  });
});
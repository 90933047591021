define('client/components/dashboard/widgets/reach-card', ['exports', 'client/components/dashboard/widgets/widget-card'], function (exports, _widgetCard) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _widgetCard.default.extend({
    sort: "reach",

    profile: Ember.computed.alias("profileService.profile")

  });
});
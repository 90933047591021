define("client/components/common/ui-card/ui-card-body", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ["ui-card-body", "card-block"],
    classNameBindings: ["tallBody:card-block-tall"],
    tallBody: false
  });
});
define('client/models/cardable', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var Cardable = _emberData.default.Model.extend({
    changeTracker: { trackHasMany: true, auto: true },
    dashboardCard: _emberData.default.belongsTo('dashboard-card', { inverse: 'cardable' }),

    trendGrouping: _emberData.default.attr("string", { defaultValue: "month" }),
    openedDetails: _emberData.default.attr("boolean", { defaultValue: false }),

    currentFilterSet: null,
    isFilterSetCampaign: false,
    selectedFilterSet: Ember.computed('currentFilterSet', 'filterSet', 'isFilterSetCampaign', function () {
      var currentFilterSet = this.get('currentFilterSet');
      // if a campaign is selected, apply the widget's filters
      // on top of the campaign's filter set, but only on attributes
      // that are not applicable to campaigns.
      if (currentFilterSet && this.get('isFilterSetCampaign')) {
        var fs = currentFilterSet.content || currentFilterSet;
        var fsCopy = fs.copy();
        var mainFs = this.get('filterSet');
        fsCopy.setProperties({
          whitelistTags: mainFs.get('whitelistTags'),
          tags: mainFs.get('tags'),
          excludedTags: mainFs.get('excludedTags'),
          languages: mainFs.get('languages'),
          syndicated: mainFs.get('syndicated'),
          followOn: mainFs.get('followOn'),
          companyWebsiteLink: mainFs.get('companyWebsiteLink'),
          articleLinksContain: mainFs.get('articleLinksContain'),
          hideLongTail: mainFs.get('hideLongTail')
        });
        return fsCopy;
      } else {
        return this.get('currentFilterSet') || this.get('filterSet');
      }
    })

  });

  exports.default = Cardable;
});
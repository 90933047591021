define('client/components/plan/campaigns/product-launch-campaign', ['exports', 'client/utils/campaign-helper'], function (exports, _campaignHelper) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({

    currentStep: 0,
    onBack: function onBack() {},
    onSave: function onSave() {},


    profileStartDate: Ember.computed.alias('profile.startDate'),

    // Union of goals and events that belong to analytics_profile (aka Interactions)
    profileMetrics: Ember.computed.alias('profile.analytics_profile.metrics'),

    selectedKeywordsOrMessages: null,
    selectedKeywordType: "keyword",
    selectedVolumeCoverage: null,
    selectedMediaChannels: null,
    selectedInteractions: null,
    selectedPublishers: null,
    selectedLocations: null,
    selectedDuration: null,
    selectedCampaignName: null,
    whitelistTags: null,
    timeZone: null,

    init: function init() {
      this._super.apply(this, arguments);
      this.setProperties({
        isSaved: false,
        isSaving: false
      });
      //Make sure profileMetrics has been loaded
      this.get('profileMetrics');
    },


    actions: {
      onSelectedKeywords: function onSelectedKeywords(keywords) {
        this.set('selectedKeywordsOrMessages', keywords);
      },
      onSelectedInteractions: function onSelectedInteractions(interactions) {
        this.set('selectedInteractions', interactions);
      },
      onSelectedVolumeCoverage: function onSelectedVolumeCoverage(selectedVolumeCoverage, whitelistTags) {
        if (selectedVolumeCoverage.showFilters) {
          this.set('whitelistTags', whitelistTags);
        }
        this.set('selectedVolumeCoverage', selectedVolumeCoverage);
      },
      onSelectedMediaChannels: function onSelectedMediaChannels(mediaChannels) {
        this.set('selectedMediaChannels', mediaChannels);
      },
      onSelectedPublishers: function onSelectedPublishers(selectedPublishers) {
        this.set('selectedPublishers', selectedPublishers);
      },
      onSelectedCampaignLocations: function onSelectedCampaignLocations(selectedLocations) {
        this.set('selectedLocations', selectedLocations);
      },
      onSelectedCampaignDuration: function onSelectedCampaignDuration(selectedDuration) {
        this.set('selectedDuration', selectedDuration);
      },
      onSelectedTimeZone: function onSelectedTimeZone(timeZone) {
        this.set('timeZone', timeZone);
      },
      onNext: function onNext() {
        var step = this.get('currentStep');
        this.set('currentStep', ++step);
        // Skip interactions if profile has no avilable metrics
        if (step == 5 && Ember.isEmpty(this.get('profileMetrics'))) {
          this.send('onNext');
        }
      },
      onPrevious: function onPrevious() {
        var step = this.get('currentStep');
        this.set('currentStep', --step);
        if (step < 0) {
          // Navigate back to campaign selection view
          this.onBack();
        } else if (step == 5 && Ember.isEmpty(this.get('profileMetrics'))) {
          // Skip interactions if profile has no avilable metrics
          this.send('onPrevious');
        }
      },
      onSave: function onSave() {
        var _this = this;

        // Compose keywords from selected names/nicknames and keywords/messages
        var keywords = [];

        if (this.get('selectedKeywordsOrMessages')) {
          var arr = this.get('selectedKeywordsOrMessages');
          if (this.get('selectedKeywordType') === "keyword") {
            arr = arr.split('\n');
          }
          keywords = keywords.concat(arr);
        }

        var availableChannels = (0, _campaignHelper.getChannels)();
        var mediaList = Object.keys(availableChannels).reduce(function (obj, ch) {
          obj[ch] = _this.get('selectedMediaChannels').includes(availableChannels[ch]);
          return obj;
        }, {});

        var availablePublishers = (0, _campaignHelper.getPublishers)(this.get("profileService.profile"));
        var publisherList = Object.keys(availablePublishers).reduce(function (obj, av) {
          obj[av] = _this.get('selectedPublishers').includes(availablePublishers[av]);
          return obj;
        }, {});
        if (this.get("selectedDuration.value") == "all") {
          this.set('timeZone', "UTC");
        }

        // Compose campaign options
        var campaignOptions = {
          keywords: keywords,
          keywordType: this.get("selectedKeywordType"),
          influential: this.get('selectedVolumeCoverage.influential'),
          uninfluential: this.get('selectedVolumeCoverage.uninfluential'),
          mediaList: mediaList,
          publisherList: publisherList,
          whitelistTags: this.get('whitelistTags'),
          locationIds: this.get('selectedLocations'),
          interactions: this.get('selectedInteractions'),
          startDate: this.get('selectedDuration.startDate'),
          endDate: this.get('selectedDuration.endDate'),
          timeZone: this.get('timeZone'),
          name: this.get('selectedCampaignName')
        };
        this.onSave(campaignOptions);
      }
    }

  });
});
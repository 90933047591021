define('client/instance-initializers/inject-session', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.initialize = initialize;
  function initialize(appInstance) {
    appInstance.inject('route', 'session', 'service:session');
    appInstance.inject('controller', 'session', 'service:session');
    appInstance.inject('component', 'session', 'service:session');
    appInstance.inject('model', 'session', 'service:session');
  }

  exports.default = {
    name: 'inject-session',
    initialize: initialize
  };
});
define('client/components/research/author-publications', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var SHOWN_LENGTH = 3;

  exports.default = Ember.Component.extend({
    classNames: ['research-key', 'author-publications'],
    showHeader: true,

    hasMorePubs: Ember.computed.gt('publications.length', SHOWN_LENGTH),

    shownPubs: Ember.computed('publications.[]', function () {
      return this.get('publications').slice(0, SHOWN_LENGTH);
    }),

    extraPubs: Ember.computed('publications.[]', function () {
      return this.get('publications.length') - SHOWN_LENGTH;
    }),

    onSelectedPublication: function onSelectedPublication() {},

    actions: {
      selectedPublication: function selectedPublication(publication) {
        // do not trigger action as publications is WIP
        // this.get('onSelectedPublication')(publication);
      }
    }
  });
});
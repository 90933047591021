define('client/computed/ternary-properties', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  function ternaryProperties(testKey, trueKey, falseKey) {
    return Ember.computed(testKey, trueKey, falseKey, function () {
      return Ember.get(this, testKey) ? Ember.get(this, trueKey) : Ember.get(this, falseKey);
    });
  }

  exports.default = ternaryProperties;
});
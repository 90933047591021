define('client/components/campaign-selector', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: '',
    searchTerm: null,
    searchTermRegexObj: null,
    profileService: Ember.inject.service('profile'),
    abilityService: Ember.inject.service('ability'),

    filteredCampaigns: Ember.computed('profileService.campaign.id', 'profileService.profile.campaigns.[]', 'searchTermRegexObj', function () {
      var campaigns = this.get('profileService.profile.sortedCampaigns'),
          regex = this.get('searchTermRegexObj'),
          currentId = +this.get('profileService.campaign.id');
      return campaigns.filter(function (campaign) {
        return +campaign.get('id') !== currentId && (!regex || regex.test(campaign.get('name')));
      });
    }),

    actions: {
      canShowDropdown: function canShowDropdown(dropdown, event) {
        if (this.abilityService.canAccess("global_saved_campaign_selector")) {
          dropdown.toggleDropdown();
        } else {
          event.preventDefault();
        }
      },
      selectCampaign: function selectCampaign(campaign, closeMenu) {
        this.get('profileService').setCampaign(campaign, {
          refreshQueryParams: false,
          force_date_reset: true
        });
        if (closeMenu) {
          closeMenu();
        }
      },
      clearCampaign: function clearCampaign(closeMenu) {
        this.get('profileService').setCampaign(null, {
          force_date_reset: true
        });
        if (closeMenu) {
          closeMenu();
        }
      }
    }
  });
});
define('client/models/dashboard-widget-template', ['exports', 'ember-data', 'client/mixins/models/full-prompt', 'client/mixins/models/dashboard-template'], function (exports, _emberData, _fullPrompt, _dashboardTemplate) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend(_fullPrompt.default, _dashboardTemplate.default, {
    prompt: _emberData.default.attr("string"),
    widgetType: _emberData.default.attr('string'),
    identifier: Ember.computed.alias("widgetType"),
    forCompetitor: _emberData.default.attr('boolean'),
    filterSet: _emberData.default.belongsTo('filter-set'),
    dashboardCardCategory: _emberData.default.belongsTo('dashboard-card-category')
  });
});
define('client/controllers/settings/owned-media-channels/index', ['exports', 'client/mixins/data-table-sort'], function (exports, _dataTableSort) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend(_dataTableSort.default, {

    modal: Ember.inject.service(),

    profile: Ember.computed.alias('profileService.profile'),

    _sorts: ["Date", "Url", "Profile"],

    media: Ember.computed(function () {
      var owner = Ember.getOwner(this);
      var findPagingController = owner.lookup('controller:find-paging');

      findPagingController.setProperties({
        model: [],
        type: "owned_medium",
        store: this.get("store")
      });
      Ember.run.later(this, "paramsDidChange");
      return findPagingController;
    }),

    paramsDidChange: Ember.observer("sortUrl", "sortDate", "sortProfile", "profile", function () {
      Ember.run.scheduleOnce("afterRender", this, "_paramsDidChange");
    }),

    resources: Ember.computed.alias("media"),

    canAdd: Ember.computed("media.length", "profile.onboardingValidations.owned_media_number", function () {
      var nb = this.get("media.length"),
          max = parseInt(this.get("profile.onboardingValidations.owned_media_number"));

      if (isNaN(max)) {
        return true;
      }
      return nb < max;
    }),

    newLink: Ember.computed('profile', function () {
      return this.store.createRecord('owned_medium', {
        profile_id: this.get('profile.id')
      });
    }),

    actions: {
      addLink: function addLink(newLink) {
        var _this = this;

        if (Ember.isEmpty(newLink.get('url'))) {
          this.get('modal').open('common/ui-confirm', {
            prompt: 'Not a valid URL',
            ok: 'OK',
            okType: "danger"
          });
          return;
        }
        var link = this.get('media.model').findBy('url', newLink.get('url'));
        if (link) {
          this.get('modal').open('common/ui-confirm', {
            question: "Owned Media Channel already exists.",
            ok: 'OK'
          });
        } else {
          newLink.set("selected", true);
          this.set('media.isLoadingPage', true);
          newLink.save().then(function (res) {
            _this.set('media.isLoadingPage', false);
            _this.notifyPropertyChange("media");
            _this.notifyPropertyChange("newLink");
          }).catch(function (failure) {
            var error = failure.errors[0].detail;
            _this.get('modal').open('common/ui-confirm', {
              prompt: error,
              ok: 'OK',
              okType: "danger"
            });
            _this.set('media.isLoadingPage', false);
          });
        }
      },
      removeLink: function removeLink(link) {
        var _this2 = this;

        this.get('modal').open('common/ui-confirm', {
          prompt: 'Are you sure?',
          ok: "Delete",
          okType: "danger"
        }).then(function () {
          _this2.set('media.isLoadingPage', true);
          link.get('content').destroyRecord().then(function () {
            _this2.set('media.isLoadingPage', false);
            _this2.get("media.model").removeObject(link);
            _this2.notifyPropertyChange("media");
          });
        });
      }
    }
  });
});
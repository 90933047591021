define('client/models/report-page', ['exports', 'client/utils/reports/generate-id', 'ember-data'], function (exports, _generateId, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var _Ember$computed = Ember.computed,
      alias = _Ember$computed.alias,
      equal = _Ember$computed.equal;
  var attr = _emberData.default.attr,
      hasMany = _emberData.default.hasMany,
      belongsTo = _emberData.default.belongsTo;
  exports.default = _emberData.default.Model.extend({
    page: Ember.computed("report.sortedPages.@each.id", "isDeleted", function () {
      if (!this.get("isDeleted")) {
        return this.get("report.sortedPages").indexOf(this);
      }
    }),
    type: attr('string'),
    html: attr('string'),
    image: attr('string'),
    report: belongsTo('report', { async: false }),
    items: hasMany('report-item', { async: false, polymorphic: true }),

    renderedImage: undefined,

    renderedHtml: Ember.computed('html', {
      get: function get() {
        return Ember.String.htmlSafe(this.get('html'));
      },
      set: function set(_key, value) {
        var sanitizedHTML = sanitizeHTML(value);
        this.set('html', sanitizedHTML);
        return Ember.String.htmlSafe(sanitizedHTML);
      }
    }),

    pageNumber: alias('page'),
    isTitlePage: equal('pageNumber', 0),

    rendered: function rendered() {
      // take a snapshot
      this.trigger("render-html", this);
    },
    touch: function touch() {
      if (Ember.isEmpty(this.get('report'))) {
        return;
      }
      return this.get('report').touch();
    },
    save: function save() {
      // on delete the report is null.
      var report = this.get('report') || this._report;
      return report.touch();
    },
    updatePageNumberHTML: function updatePageNumberHTML() {
      var previousHtml = this.get('renderedHtml').toString();
      var newHtml = previousHtml.replace(/<div class="pull-right">\n?Page (\d+)\n?<\/div>/i, '<div class="pull-right">Page $1</div>');
      this.set('renderedHtml', newHtml);
    },
    isSameHtml: function isSameHtml(html) {
      var sanitizedHTML = sanitizeHTML(html);
      var htmlSafeHtml = Ember.String.htmlSafe(sanitizedHTML);
      return htmlSafeHtml === this.get('renderedHtml').toString();
    },
    getGridLocation: function getGridLocation(colspan, rowspan) {
      var grid = [[true, true, true, true], [true, true, true, true], [true, true, true, true], [true, true, true, true]];
      this.get('items').forEach(function (item) {
        var row = item.get('row');
        var col = item.get('col');
        var rowspan = item.get('rowspan');
        var colspan = item.get('colspan');
        for (var i = row; i < rowspan + row; i++) {
          for (var j = col; j < colspan + col; j++) {
            grid[i][j] = false;
          }
        }
      });
      for (var i = 0; i < grid.length; i++) {
        for (var j = 0; j < grid[i].length; j++) {
          if (this._canPlaceAtGridLocation(grid, i, j, rowspan, colspan)) {
            return [i, j];
          }
        }
      }
    },
    _canPlaceAtGridLocation: function _canPlaceAtGridLocation(grid, row, col, rowspan, colspan) {
      for (var i = row; i < rowspan + row; i++) {
        for (var j = col; j < colspan + col; j++) {
          if (!grid[i][j]) {
            return false;
          }
        }
      }
      return true;
    },
    newGridItem: function newGridItem(item) {
      var items = this.get("items");
      var defaultValues = item.content && item.content.defaultValues || {};
      defaultValues.profile_id = this.get('report.profileId');
      var newItem = this.store.createRecord('report-items/' + item.configName, {
        id: (0, _generateId.default)(this.get('report.id')),
        page: this,
        content: defaultValues,
        config: item.configName,
        row: item.row,
        col: item.col,
        colspan: item.colspan,
        rowspan: item.rowspan
      });
      items.addObject(newItem);
      newItem.touch();
    }
  });


  function sanitizeHTML(html) {
    var sanitizedHTML;
    // Replace empty styles with empty
    sanitizedHTML = html.replace(/<style>[\s\S]*?<\/style>/g, "");

    // Replace ember ids with empty
    sanitizedHTML = sanitizedHTML.replace(/id="ember\d*"/g, "");

    // Replace ember actions with empty
    sanitizedHTML = sanitizedHTML.replace(/data-ember-action="\d*"/g, "");
    sanitizedHTML = sanitizedHTML.replace(/data-ember-action-\d*="\d*"/g, "");

    // Replace ember databinds with empty
    sanitizedHTML = sanitizedHTML.replace(/data-bindattr-\d*="\d*"/g, "");

    // Replace ember specific clip selected ids with nil
    sanitizedHTML = sanitizedHTML.replace(/clip-selected-ember\d*/g, "");

    // Replace loading class with empty
    sanitizedHTML = sanitizedHTML.replace(/report-base_loading/g, "");

    // Replace dropdown menus with empty
    sanitizedHTML = sanitizedHTML.replace(/<ul class="dropdown-menu">.*<\/ul>/g, "");

    // Replace multiple spaces (including cover tabs, newlines, etc) with one space
    sanitizedHTML = sanitizedHTML.replace(/\s\s+/g, ' ');

    //Replace all Loading classes as they cause performance paint issues
    sanitizedHTML = sanitizedHTML.replace(/ui-loading/g, "");

    return sanitizedHTML;
  }
});
define('client/components/article-list/article-item-syndicates', ['exports', 'client/computed/index'], function (exports, _index) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: '',
    profileService: Ember.inject.service('profile'),
    content: null,
    sort: null,
    showInteractionCount: false,

    select: function select() {},


    syndicatesUrlsFormatted: Ember.computed("content.syndicatesDomainUrls.length", function () {
      return this.get("content.syndicatesDomainUrls").map(function (url) {
        return url === 'NA' ? 'assets/images/2_context/storylogo_article.png' : url;
      });
    }),

    topSyndicatesDomainUrls: (0, _index.topItems)("syndicatesUrlsFormatted", 4),

    moreSyndicatesDomains: Ember.computed("topSyndicatesDomainUrls.length", "content.syndicatesCount", function () {
      return this.get("content.syndicatesCount") - this.get("topSyndicatesDomainUrls.length");
    }),
    visitorTooltip: Ember.computed.alias('profileService.profile.nameCustomer'),
    interactionTooltip: Ember.computed.alias('profileService.profile.nameTotalInteraction'),
    nameReachTooltip: Ember.computed.alias('profileService.profile.nameReach'),
    nameReadershipTooltip: Ember.computed.alias('profileService.profile.nameReadership'),
    nameRevenueTooltip: Ember.computed.alias('profileService.profile.nameRevenue'),
    nameImpactScoreTooltip: Ember.computed.alias('profileService.profile.nameAverageImpactScore'),

    showTotals: Ember.computed('sort', 'profileService.profile.hasPOV2UI', function () {
      return !(this.get('sort') === "pov" && this.get('profileService.profile.hasPOV2UI'));
    })
  });
});
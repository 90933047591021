define('client/components/promote/ozmotik-iframe', ['exports', 'client/mixins/profile-service-mixin'], function (exports, _profileServiceMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_profileServiceMixin.default, {
    profileService: Ember.inject.service('profile'),
    currentPage: null,
    iframeRef: null,

    init: function init() {
      this._super.apply(this, arguments);
    },
    didUpdateAttrs: function didUpdateAttrs() {
      this._super.apply(this, arguments);
      this.setCurrentPage();
    },
    willDestory: function willDestory() {
      this._super.apply(this, arguments);
      window.removeEventListener("message", Ember.run.bind(this, this.recieveOzmotikMessage));
    },
    rangeDidChange: function rangeDidChange() {
      this.sendDateRange();
    },
    profileDidChange: function profileDidChange() {
      if (this.profileService.profile.hasOnPromote) {
        this.set('isValidProfile', true);
        this.sendProfileChange();
      }
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      this.set('iframeRef', this.element.querySelector('#ozmotik'));
      //Setup ozmotik incoming communication
      window.addEventListener("message", Ember.run.bind(this, this.recieveOzmotikMessage));
      this.setCurrentPage();
    },
    recieveOzmotikMessage: function recieveOzmotikMessage(ev) {
      if (ev.data.req == "oz_get_date_range") {
        //Get request date range message
        this.sendDateRange();
      }
    },
    sendDateRange: function sendDateRange() {
      var startDate = this.profileService.fromJSDate;
      var endDate = this.profileService.toJSDate;
      if (this.iframeRef.contentWindow) {
        this.iframeRef.contentWindow.window.postMessage({ "req": "oz_set_date_range", "start": startDate, "end": endDate }, "*");
      }
    },
    sendProfileChange: function sendProfileChange() {
      var profileId = this.profileService.profile.id;
      if (this.iframeRef.contentWindow) {
        this.iframeRef.contentWindow.window.postMessage({ "req": "oz_change_profile", "value": profileId }, "*");
      }
    },
    setCurrentPage: function setCurrentPage() {
      //Send ozmotik page change
      if (this.iframeRef.contentWindow) {
        this.iframeRef.contentWindow.window.postMessage({ "req": "oz_show_section", "section": this.currentPage }, '*');
      }
    },

    actions: {
      iframeLoaded: function iframeLoaded() {
        this.setCurrentPage();
      }
    }

  });
});
define('client/models/report-items/bar', ['exports', 'client/models/report-items/chart'], function (exports, _chart) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _chart.default.extend({
    valueOptionsMenuName: 'Horizontal Axis',
    labelOptionsMenuName: 'Vertical Axis'
  });
});
define('client/models/profile-message-bucket', ['exports', 'ember-data', 'client/mixins/models/query-consumer'], function (exports, _emberData, _queryConsumer) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var ProfileMessageBucket = _emberData.default.Model.extend(_queryConsumer.default, {
    name: _emberData.default.attr('string'),
    profile: _emberData.default.belongsTo('profile'),
    messages: _emberData.default.hasMany("message", { inverse: 'profileMessageBuckets' }),
    message: _emberData.default.hasMany("message", { inverse: 'profileMessageBucket' }),

    getMessageInfos: function getMessageInfos(options) {
      var query = this.queryByName("neo"),
          messages = this.get("messages");
      query.setProperties(Ember.merge({ messages: messages }, options));
      return query.load();
    }
  });

  ProfileMessageBucket.pathForType = "profile_message_buckets";

  exports.default = ProfileMessageBucket;
});
define('client/queries/power-of-voice-metrics', ['exports', 'client/utils/-iso-format', 'client/models/profile-pov-metrics'], function (exports, _isoFormat, _profilePovMetrics) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Object.extend(Ember.PromiseProxyMixin, {
    ajax: Ember.inject.service(),
    store: Ember.inject.service(),
    profiles: null,
    filterSet: null,
    from: null,
    to: null,

    metrics: Ember.computed('isFulfilled', function () {
      var _this = this;

      if (!this.get('isFulfilled')) {
        return [];
      }
      var sum = function sum(prev, current) {
        return Ember.isPresent(current) ? prev + current : prev;
      };
      var metrics = this.get('content.pov_metrics');
      var numProfiles = this.get('profiles.length');
      var totals = Ember.Object.create({
        coverage: metrics.mapBy('coverage').reduce(sum, 0),
        reputation: metrics.mapBy('pov_base').reduce(sum, 0),
        relevance: metrics.mapBy('pov_rel').reduce(sum, 0),
        reception: metrics.mapBy('pov_rel_amp').reduce(sum, 0),
        povSentiment: metrics.mapBy('pov_rel_amp_sent').reduce(sum, 0),
        previousReception: metrics.mapBy('previous_pov_rel_amp').reduce(sum, 0),
        currentReception: metrics.mapBy('current_pov_rel_amp').reduce(sum, 0),
        previousPovSentiment: metrics.mapBy('previous_pov_rel_amp').reduce(sum, 0),
        currentPovSentiment: metrics.mapBy('current_pov_rel_amp').reduce(sum, 0),
        previousCoverage: metrics.mapBy('previous_coverage').reduce(sum, 0),
        currentCoverage: metrics.mapBy('current_coverage').reduce(sum, 0)
      });

      var povMetrics = this.get('profiles').toArray().map(function (profile) {
        var args = { profile: profile, totals: totals };
        var metric = _this.get('content.pov_metrics').findBy('profile_id', +profile.get('id'));
        if (metric) {
          Ember.merge(args, metric);
        }
        return _profilePovMetrics.default.create(args);
      });
      return povMetrics;
    }),

    fetch: function fetch() {
      var _this2 = this;

      var filters = this.get('filterSet.articleListAdapter.filtersHash');
      var ids = this.get('profiles').toArray().mapBy('id');
      var profiles = this.get('profiles');
      var competitorSearchTerms = this.get("filterSet.competitorSearchTerms");
      var profilesHash = profiles.map(function (profile) {
        if (!profile) {
          return null;
        }
        var attrs = profile.getProperties("profileId", "store");
        var search_term = _this2.get("filterSet.searchTerm");
        var competitorSearchTerm = competitorSearchTerms.findBy("profileId", attrs.profileId);

        if (competitorSearchTerm) {
          search_term = competitorSearchTerm.get("searchTerm");
        }

        var campaign_id = profile.get('campaignId');
        var profile_id = profile.get('profileId');
        var label = profile.get('displayName');

        if (campaign_id) {
          return {
            profile_id: profile_id,
            campaign_id: campaign_id,
            search_term: search_term,
            label: label
          };
        }

        return {
          profile_id: profile_id,
          search_term: search_term,
          label: label
        };
      });

      var promise = this.get('ajax').post('/v3/api/pov_metrics', {
        data: {
          profiles_with_search_terms: profilesHash,
          profile_ids: ids,
          from: (0, _isoFormat.isoTZFormat)(moment(this.get('from')).startOf('day').toDate()),
          to: (0, _isoFormat.isoTZFormat)(moment(this.get('to')).endOf('day').toDate()),
          search_term: this.get('filterSet.searchTerm'),
          filters: filters,
          trend_grouping: this.get('trendGrouping')
        }
      });
      this.set('promise', promise);
      return promise;
    }

  });
});
define("client/transitions", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  exports.default = function () {
    var duration = 200;
    this.transition(this.hasClass("analyze-graph-liquid"), this.toValue(false), this.use("toLeft", { duration: duration }), this.reverse("toRight", { duration: duration }));

    this.transition(this.hasClass("dashboard-liquid"), this.toValue(false), this.use('fadeAndScale'), this.reverse('fade', { duration: 100 }));

    this.transition(this.toValue(true), this.use('fade'), this.reverse('fade'));

    this.transition(this.hasClass("down-up"), this.toValue(true), this.use("toDown", { duration: duration }), this.reverse("toUp", { duration: duration }));
  };
});
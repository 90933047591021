define('client/controllers/settings/user-management', ['exports', 'client/mixins/airpr'], function (exports, _airpr) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend(_airpr.default, {
    modal: Ember.inject.service(),
    roles: ["view_only", "power", "admin"],

    profile: Ember.computed.alias('profileService.profile'),
    resources: Ember.computed.alias('users'),

    users: Ember.computed(function () {
      var owner = Ember.getOwner(this);
      var findPagingController = owner.lookup('controller:find-paging');

      findPagingController.setProperties({
        model: [],
        type: "account-user",
        store: this.get("store")
      });
      Ember.run.later(this, "paramsDidChange");
      return findPagingController;
    }),
    actions: {
      openUserEdit: function openUserEdit(user) {
        this.set("selectedAccountUser", user);
        this.set("openedForm", true);
      },
      cancelEdit: function cancelEdit() {
        this.set("selectedAccountUser", null);
        this.set("errorMessage", null);
        this.set("openedForm", false);
      },
      updateUserRole: function updateUserRole() {
        var _this = this;

        var selectedAccountUser = this.selectedAccountUser;
        if (selectedAccountUser && selectedAccountUser.content) {
          var selectedRecord = selectedAccountUser.content;
          selectedRecord.save().then(function () {
            _this.set("selectedAccountUser", null);
            _this.set("openedForm", false);
            _this.set("errorMessage", null);
            _this.modal.open('common/ui-confirm', {
              prompt: 'User role updated successfully.',
              showCancel: false,
              ok: "OK",
              okType: "info"
            });
          }).catch(function (error) {
            var errorMessage = error.errors && error.errors[0] || error;
            _this.set("errorMessage", errorMessage);
          });
        }
      }
    }
  });
});
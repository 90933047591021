define('client/components/article-sort-dropdown', ['exports', 'client/components/sort-chooser', 'client/mixins/article-sort-dropdown'], function (exports, _sortChooser, _articleSortDropdown) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var ArticleSortDropdown = _sortChooser.default.extend(_articleSortDropdown.default, {
    layoutName: 'components/sort-chooser',
    classNames: ['article-sort-dropdown'],
    profile: null,
    isPublicationSearch: false,
    isUsingCurrentProfile: true,
    disabled: false,

    content: Ember.computed("sorts", function () {
      return Ember.Object.create({ sorts: this.get('sorts') });
    })

  });

  exports.default = ArticleSortDropdown;
});
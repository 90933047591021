define('client/transforms/object', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) {
    return typeof obj;
  } : function (obj) {
    return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
  };

  //
  // Handle array and object like JSON data from the backend
  //
  var ObjectTransform = _emberData.default.Transform.extend({
    serialize: function serialize(value) {
      return value;
    },
    deserialize: function deserialize(value) {
      if (Ember.isArray(value)) {
        return value.map(function (v) {
          return "object" === (typeof v === 'undefined' ? 'undefined' : _typeof(v)) ? Ember.Object.create(v) : v;
        });
      } else if ("object" === (typeof value === 'undefined' ? 'undefined' : _typeof(value))) {
        return Ember.Object.create(value);
      }
      return value;
    }
  });

  exports.default = ObjectTransform;
});
define('client/components/graph-line', ['exports', 'client/mixins/graph-legend', 'client/mixins/graph-viewport', 'client/mixins/graph-group-columns', 'client/mixins/graph-title'], function (exports, _graphLegend, _graphViewport, _graphGroupColumns, _graphTitle) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var ReportGroupLineGraphComponent = Ember.Component.extend(_graphLegend.default, _graphViewport.default, _graphGroupColumns.default, _graphTitle.default, {
    classNames: ['graph-column'],

    showHoverLine: function showHoverLine(x, y) {
      var hoverLine = this.get('viewport').select('.hover-line');
      var xPoints = this.get('xScale').range();
      var index = d3.bisectLeft(xPoints, x) - 1;
      var right = this.get('columnWidth') / 2;
      hoverLine.attr({
        opacity: 1,
        x1: xPoints[index] + right,
        x2: xPoints[index] + right,
        y1: this.get('graphicHeight'),
        y2: 0
      });
      var selector = function selector(d, i) {
        if (i === index) {
          return d.values;
        } else {
          return [];
        }
      };
      var columnGroups = this.get('columnGroups');
      var dataPointOuter = columnGroups.selectAll('circle.hover-data-point-outer').data(selector);
      var dataPointInner = columnGroups.selectAll('circle.hover-data-point-inner').data(selector);

      dataPointOuter.enter().append('circle').attr('class', 'hover-data-point-outer');
      dataPointOuter.attr(this.pointAttrs('outer', this.get('showDataPoints')));
      dataPointOuter.exit().remove();

      dataPointInner.enter().append('circle').attr('class', 'hover-data-point-inner');
      dataPointInner.attr(this.pointAttrs('inner', this.get('showDataPoints')));
      dataPointInner.exit().remove();
    },
    hideHoverLine: function hideHoverLine() {
      var line = this.get('viewport').select('.hover-line');
      if (!line.empty()) {
        line.attr('opacity', 0);
        var columnGroups = this.get('columnGroups');
        var dataPointOuter = columnGroups.selectAll('circle.hover-data-point-outer');
        var dataPointInner = columnGroups.selectAll('circle.hover-data-point-inner');
        dataPointOuter.remove();
        dataPointInner.remove();
      }
    },


    // ----------------------------------------------------------------------------
    // Data Processing
    // ----------------------------------------------------------------------------
    _groupDataExample: Ember.computed(function () {
      return [{
        labels: ['Jan', 'Rackspace'],
        values: [273]
      }, {
        labels: ['Jan', 'Microsoft Azure'],
        values: [329]
      }, {
        labels: ['Feb', 'Rackspace'],
        values: [491]
      }, {
        labels: ['Feb', 'Microsoft Azure'],
        values: [372]
      }, {
        labels: ['Mar', 'Rackspace'],
        values: [558]
      }, {
        labels: ['Mar', 'Microsoft Azure'],
        values: [225]
      }, {
        labels: ['Apr', 'Rackspace'],
        values: [603]
      }, {
        labels: ['Apr', 'Microsoft Azure'],
        values: [391]
      }, {
        labels: ['May', 'Rackspace'],
        values: [663]
      }, {
        labels: ['May', 'Microsoft Azure'],
        values: [438]
      }];
    }),

    _dataExample: Ember.computed(function () {
      return [{
        labels: ['Jan', 'Rackspace'],
        values: [273]
      }, {
        labels: ['Feb', 'Rackspace'],
        values: [491]
      }, {
        labels: ['Mar', 'Rackspace'],
        values: [558]
      }, {
        labels: ['Apr', 'Rackspace'],
        values: [603]
      }, {
        labels: ['May', 'Rackspace'],
        values: [663]
      }];
    }),

    //data: Ember.computed.alias('_groupDataExample'),

    stackColumns: false,

    // Take data API and format for this specific chart
    formattedData: Ember.computed('data', function () {
      var data = this.get('data');
      if (Ember.isEmpty(data)) {
        return [];
      }
      return data.map(function (d) {
        return {
          label: d.labels[1] || d.labels[0],
          color: d.color,
          group: d.labels[0],
          value: d.values[0]
        };
      });
    }),

    // ----------------------------------------------------------------------------
    // Drawing Functions
    // ----------------------------------------------------------------------------
    updateColumns: function updateColumns() {
      this.updateLinePaths();
      this.updatePoints();
      if (this.get("useBrush")) {
        this.brush = null;
        this.updateBrush();
      }
    },

    showDataPoints: true,
    showColumnTickLines: true,
    useBrush: false,

    updatePoints: function updatePoints() {
      var columnGroups = this.get('columnGroups');

      var subdata = this.get('stackColumns') ? function (d) {
        return d.stackedValues;
      } : function (d) {
        return d.values;
      };

      var dataPointOuter = columnGroups.selectAll('circle.data-point-outer').data(subdata);
      var dataPointInner = columnGroups.selectAll('circle.data-point-inner').data(subdata);

      if (!this.get('showDataPoints')) {
        dataPointOuter.remove();
        dataPointInner.remove();
      } else {
        dataPointOuter.enter().append('circle').attr('class', 'data-point-outer');
        dataPointOuter.exit().remove();
        dataPointOuter.attr(this.pointAttrs('outer'));

        dataPointInner.enter().append('circle').attr('class', 'data-point-inner');
        dataPointInner.exit().remove();
        dataPointInner.attr(this.pointAttrs('inner'));
      }
    },

    updateLinePaths: function updateLinePaths() {
      // Split data by labels for line drawing
      var formattedData = this.get('finishedData');
      if (Ember.isEmpty(formattedData)) {
        return;
      }
      var stackColumns = this.get('stackColumns');
      var labelSplitData = this.get('labelsPerColumn').map(function (label) {
        return {
          label: label,
          values: formattedData.map(function (d) {
            return {
              point: stackColumns ? d.stackedValues.find(function (v) {
                return v.label === label;
              }) : d.values.find(function (v) {
                return v.label === label;
              })
            };
          })
        };
      });

      var pathGroups = this.get('viewport').selectAll('.line-group').data(labelSplitData, function (d) {
        return d.label;
      });
      pathGroups.enter().append('g').attr('class', 'line-group').append('path');
      pathGroups.exit().remove();

      var lines = pathGroups.select('path');
      lines.datum(function (d) {
        return d.values;
      }).attr(this.get('lineAttrs'));
    },

    // ----------------------------------------------------------------------------
    // Styles
    // ----------------------------------------------------------------------------
    dataPointRadius: Ember.computed(function () {
      var maxPointRadius = 11;
      var columnWidth = this.get('columnWidth');
      var outer = d3.min([columnWidth / 2, maxPointRadius]);
      var inner = outer - Math.ceil(outer * 0.3);
      return { inner: inner, outer: outer };
    }).volatile(),

    dataPointOpacity: null,

    pointAttrs: function pointAttrs(type) {
      var slightlyLargerRadius = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;

      // zeroDisplacement is the number of pixels to shift graphics away from
      // the origin line so that they do not overlap with it
      var zeroDisplacement = 1;
      var yScale = this.get('yScale');
      var cy = this.get('stackColumns') ? function (d) {
        return yScale(d.y1) + zeroDisplacement;
      } : function (d) {
        return d.value > 0 ? yScale(d.value) : yScale(0) + zeroDisplacement;
      };

      var r = this.get('dataPointRadius')[type];
      if (slightlyLargerRadius) {
        r *= 1.3;
      }

      return {
        'stroke-width': 0,
        fill: function fill(d) {
          return d.color;
        },
        opacity: this.get('dataPointOpacity')[type],
        cx: this.get('columnWidth') / 2,
        cy: cy,
        r: r
      };
    },


    lineAttrs: Ember.computed(function () {
      var columnWidth = this.get('columnWidth');
      var xScale = this.get('xScale');
      var yScale = this.get('yScale');
      var stackColumns = this.get('stackColumns');
      var line = d3.svg.line().interpolate('linear').defined(function (d) {
        if (!d.point) {
          return false;
        }
        var val = stackColumns ? d.point.y1 : d.point.value;
        return val !== null;
      }).x(function (d) {
        return xScale(d.point.group) + columnWidth / 2;
      }).y(function (d) {
        var val = stackColumns ? d.point.y1 : d.point.value;
        return yScale(val);
      });

      return {
        'stroke-width': 3,
        fill: 'none',
        stroke: function stroke(d, line) {
          return d[line % d.length].point.color;
        },
        d: line
      };
    }).volatile(),

    init: function init() {
      this._super.apply(this, arguments);
      this.set('dataPointOpacity', this.get('dataPointOpacity') || { inner: 0.7, outer: 0.3 });
    }
  });

  exports.default = ReportGroupLineGraphComponent;
});
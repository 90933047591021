define('client/components/reports/base-text-widget', ['exports', 'client/components/reports/base-widget'], function (exports, _baseWidget) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _baseWidget.default.extend({
    classNameBindings: ["isEdit:report-base_edit"],
    isSettled: true,
    isEdit: false,

    isEditTextString: Ember.computed("isEdit", function () {
      var edit = this.get("isEdit");
      return edit ? edit.toString() : false;
    }),

    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);
      Ember.$("body").off("click." + this.get("elementId"));
      this.get("reportService").stopEditing();
    }
  });
});
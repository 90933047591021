define('client/components/common/ui-page-title/dropdown-item', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      this.get("pageTitle").addItem(this);
    },
    willDestroyElement: function willDestroyElement() {
      this.get('pageTitle').removeItem(this);
    },


    title: null,
    route: null,
    selected: false
  });
});
define('client/components/research/filter-industry', ['exports', 'ember-concurrency'], function (exports, _emberConcurrency) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var allIndustries = [{ name: 'Aerospace', value: 'aerospace' }, { name: 'Arts', value: 'arts' }, { name: 'Beverages', value: 'beverages' }, { name: 'Economics', value: 'economics' }, { name: 'Engineering', value: 'engineering' }, { name: 'Environment', value: 'environment' }, { name: 'Financial', value: 'financial' }, { name: 'Food', value: 'food' }, { name: 'Health', value: 'health' }, { name: 'International development', value: 'international development' }, { name: 'Internet', value: 'internet' }, { name: 'Law', value: 'law' }, { name: 'Management', value: 'management' }, { name: 'Marketing', value: 'marketing' }, { name: 'Media', value: 'media' }, { name: 'Medical', value: 'medical' }, { name: 'Motors', value: 'motors' }, { name: 'Oil and gas', value: 'oil and gas' }, { name: 'Pharmaceutical', value: 'pharmaceutical' }, { name: 'Politics', value: 'politics' }, { name: 'Recreation', value: 'recreation' }, { name: 'Science', value: 'science' }, { name: 'Sport', value: 'sport' }, { name: 'Technology', value: 'technology' }, { name: 'Transport', value: 'transport' }];

  exports.default = Ember.Component.extend({
    ajax: Ember.inject.service(),
    allIndustries: allIndustries,
    selectedIndustries: null,
    availableEditorialTopics: null,
    listIsOpen: false,
    suggestions: null,
    searchTerm: null,
    resetManually: false,
    disabled: false,

    onApplyIndustries: function onApplyIndustries() {},
    applyEditorialTags: function applyEditorialTags() {},


    allIndustriesSelected: Ember.computed('allCheckedIndustries.[]', 'allIndustries.[]', function () {
      return this.get('allCheckedIndustries.length') === this.get('allIndustries.length');
    }),
    limitedIndustries: Ember.computed('allIndustries', function () {
      return this.get('allIndustries').slice(0, 4);
    }),
    allCheckedIndustries: Ember.computed.filterBy('selectedIndustries', 'checked'),
    selectedIndustriesValues: Ember.computed.mapBy('allCheckedIndustries', 'value'),

    init: function init() {
      this._super.apply(this, arguments);
      this.set('selectedIndustries', this.get('selectedIndustries') || []);
      this.set('availableEditorialTopics', this.get('availableEditorialTopics') || []);
      this.set('suggestions', this.get('suggestions') || []);

      var selectedIndustries = this.get('allIndustries').map(function (industry) {
        return Ember.Object.create(industry);
      });
      if (Ember.isBlank(this.get('industries'))) {
        selectedIndustries.setEach('checked', true);
      } else {
        this.get('industries').forEach(function (industry) {
          var foundIndustry = selectedIndustries.findBy('value', industry);
          if (foundIndustry) {
            foundIndustry.set('checked', true);
          }
        });
        this.set('listIsOpen', true);
      }
      this.set('availableEditorialTopics', this.get('editorialTopics').slice());
      this.set('selectedIndustries', selectedIndustries);
    },
    didUpdateAttrs: function didUpdateAttrs() {
      this._super.apply(this, arguments);
      if (Ember.isBlank(this.get('industries')) && !this.get('resetManually') && !Ember.isEmpty(this.get('allCheckedIndustries'))) {
        this.get('selectedIndustries').setEach('checked', true);
      } else {
        this.set('resetManually', false);
      }
    },
    addEditorialTag: function addEditorialTag(tag) {
      var tags = this.get('editorialTopics');
      this.get('availableEditorialTopics').addObject(tag);
      this.get('applyEditorialTags')(tags.concat([tag]));
    },
    removeEditorialTag: function removeEditorialTag(tag) {
      var tags = this.get('editorialTopics').filter(function (item) {
        return item !== tag;
      });
      this.get('applyEditorialTags')(tags);
    },


    actions: {
      toggleAllIndustries: function toggleAllIndustries() {
        this.get('selectedIndustries').setEach('checked', !this.get('allIndustriesSelected'));
        if (!this.get('allIndustriesSelected')) {
          this.set('resetManually', true);
        }
        this.get('onApplyIndustries')([]);
      },
      toggleIndustry: function toggleIndustry(industry) {
        industry.toggleProperty('checked');
        if (this.get('allIndustriesSelected')) {
          this.get('onApplyIndustries')([]);
        } else {
          this.get('onApplyIndustries')(this.get('selectedIndustriesValues'));
        }
      },
      onCancelTags: function onCancelTags() {
        this.set('searchTerm', null);
        this.set('suggestions', []);
      },
      onChangeTags: function onChangeTags(term) {
        this.set('searchTerm', term);
        if (term) {} else {
          this.set('suggestions', []);
        }
      },
      selectTag: function selectTag(tag) {
        this.set('searchTerm', null);
        this.addEditorialTag(tag);
      },
      updateEditorialTag: function updateEditorialTag(tag, includeTag) {
        if (includeTag) {
          this.addEditorialTag(tag);
        } else {
          this.removeEditorialTag(tag);
        }
      }
    }
  });
});
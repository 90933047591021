define('client/mixins/graph-title', ['exports', 'client/mixins/graph-text'], function (exports, _graphText) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var GraphTitleMixin = Ember.Mixin.create(_graphText.default, {
    chartTitle: '',
    sanitizedChartTitle: Ember.computed('chartTitle', function () {
      if (this.get('chartTitle')) {
        return this.get('chartTitle').trim().toUpperCase();
      }
      return '';
    }),
    hasTitle: Ember.computed('sanitizedChartTitle', function () {
      return this.get('sanitizedChartTitle').length > 0;
    }),
    titleTopPadding: 4,
    titleRightPadding: 20,

    titleHeight: Ember.computed('chartTitle', function () {
      var node = this.get('svg').select('.chart-title-container').node();
      return node ? node.getBBox().height : 0;
    }).volatile(),

    titleTop: Ember.computed('titleHeight', 'titleTopPadding', function () {
      return this.get('titleTopPadding') + this.get('titleHeight');
    }).volatile(),

    titleLeft: Ember.computed(function () {
      var title = this.get('titleElement');
      return this.get('svgWidth') - title.node().getBBox().width - this.get('titleRightPadding');
    }).volatile(),

    chartTitleContainerElement: Ember.computed(function () {
      var title = this.get('svg').select('.chart-title-container');
      return title.empty() ? this.get('svg').append('g').attr('class', 'chart-title-container') : title;
    }).volatile(),

    titleElement: Ember.computed(function () {
      var chartTitleContainerElement = this.get('chartTitleContainerElement');
      chartTitleContainerElement.selectAll("*").remove();

      var maxWidth;
      if (this.get('isDonut') !== undefined || !this.get('hasLegend')) {
        maxWidth = this.get('svgWidth') - this.get('titleRightPadding');
      } else {
        // The 1.7 is to allow for enough space for the legend
        maxWidth = this.get('svgWidth') / 1.7;
      }

      var titleElement = chartTitleContainerElement.append('text').text(this.get('sanitizedChartTitle'));
      titleElement.attr({ x: 0, y: 0, dy: '1em' }).call(this.wordWrapper, maxWidth, this.get('svgHeight'));
      return titleElement;
    }).volatile(),

    drawTitle: function drawTitle() {
      //need to be called for pie charts
      this.get('titleElement');

      var chartTitleContainerElement = this.get('chartTitleContainerElement');
      chartTitleContainerElement.attr('transform', 'translate(' + this.get('titleLeft') + ',' + this.get('titleTopPadding') + ')');
    }
  });

  exports.default = GraphTitleMixin;
});
define('client/serializers/article-search', ['exports', 'ember-data', 'active-model-adapter'], function (exports, _emberData, _activeModelAdapter) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  //
  // Article context search serializer.
  // Article summaries are always embedded.
  //
  var ArticleSearchSerializer = _activeModelAdapter.ActiveModelSerializer.extend(_emberData.default.EmbeddedRecordsMixin, {
    attrs: {
      articles: { embedded: 'always' }
    },
    serialize: function serialize(snapshot, options) {
      //We are reusing the search model, we can't send all the existing aticles with the search
      var json = this._super.apply(this, arguments);
      json.articles = [];
      return json;
    },


    //todo: remove in ember data 2
    isNewSerializerAPI: true
  });

  exports.default = ArticleSearchSerializer;
});
define('client/utils/timezones', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  /*
  * https://www.joda.org/joda-time/timezones.html
  * reference for mapping some of the time zones
  * ElasticSearch is using joda-time library, which is based of Iana time zones
  */

  var TIMEZONE_MAP = exports.TIMEZONE_MAP = [{ rails_zone_name: 'International Date Line West',
    iana_zone_name: 'Etc/GMT+12' }, { rails_zone_name: 'Midway Island',
    iana_zone_name: 'Pacific/Midway' }, { rails_zone_name: 'American Samoa',
    iana_zone_name: 'Pacific/Pago_Pago' }, { rails_zone_name: 'Hawaii',
    iana_zone_name: 'Pacific/Honolulu' }, { rails_zone_name: 'Alaska',
    iana_zone_name: 'America/Juneau' }, { rails_zone_name: 'Pacific Time (US & Canada)',
    iana_zone_name: 'America/Los_Angeles' }, { rails_zone_name: 'Tijuana',
    iana_zone_name: 'America/Tijuana' }, { rails_zone_name: 'Mountain Time (US & Canada)',
    iana_zone_name: 'America/Denver' }, { rails_zone_name: 'Arizona',
    iana_zone_name: 'America/Phoenix' }, { rails_zone_name: 'Chihuahua',
    iana_zone_name: 'America/Chihuahua' }, { rails_zone_name: 'Mazatlan',
    iana_zone_name: 'America/Mazatlan' }, { rails_zone_name: 'Central Time (US & Canada)',
    iana_zone_name: 'America/Chicago' }, { rails_zone_name: 'Saskatchewan',
    iana_zone_name: 'America/Regina' }, { rails_zone_name: 'Guadalajara',
    iana_zone_name: 'America/Mexico_City' }, { rails_zone_name: 'Mexico City',
    iana_zone_name: 'America/Mexico_City' }, { rails_zone_name: 'Monterrey',
    iana_zone_name: 'America/Monterrey' }, { rails_zone_name: 'Central America',
    iana_zone_name: 'America/Guatemala' }, { rails_zone_name: 'Eastern Time (US & Canada)',
    iana_zone_name: 'America/New_York' }, { rails_zone_name: 'Indiana (East)',
    iana_zone_name: 'America/Indiana/Indianapolis' }, { rails_zone_name: 'Bogota',
    iana_zone_name: 'America/Bogota' }, { rails_zone_name: 'Lima',
    iana_zone_name: 'America/Lima' }, { rails_zone_name: 'Quito',
    iana_zone_name: 'America/Lima' }, { rails_zone_name: 'Atlantic Time (Canada)',
    iana_zone_name: 'America/Halifax' }, { rails_zone_name: 'Caracas',
    iana_zone_name: 'America/Caracas' }, { rails_zone_name: 'La Paz',
    iana_zone_name: 'America/La_Paz' }, { rails_zone_name: 'Santiago',
    iana_zone_name: 'America/Santiago' }, { rails_zone_name: 'Newfoundland',
    iana_zone_name: 'America/St_Johns' }, { rails_zone_name: 'Brasilia',
    iana_zone_name: 'America/Sao_Paulo' }, { rails_zone_name: 'Buenos Aires',
    iana_zone_name: 'America/Argentina/Buenos_Aires' }, { rails_zone_name: 'Montevideo',
    iana_zone_name: 'America/Montevideo' }, { rails_zone_name: 'Georgetown',
    iana_zone_name: 'America/Guyana' }, { rails_zone_name: 'Puerto Rico',
    iana_zone_name: 'America/Puerto_Rico' }, { rails_zone_name: 'Greenland',
    iana_zone_name: 'America/Godthab' }, { rails_zone_name: 'Mid-Atlantic',
    iana_zone_name: 'Atlantic/South_Georgia' }, { rails_zone_name: 'Azores',
    iana_zone_name: 'Atlantic/Azores' }, { rails_zone_name: 'Cape Verde Is.',
    iana_zone_name: 'Atlantic/Cape_Verde' }, { rails_zone_name: 'Dublin',
    iana_zone_name: 'Europe/Dublin' }, { rails_zone_name: 'Edinburgh',
    iana_zone_name: 'Europe/London' }, { rails_zone_name: 'Lisbon',
    iana_zone_name: 'Europe/Lisbon' }, { rails_zone_name: 'London',
    iana_zone_name: 'Europe/London' }, { rails_zone_name: 'Casablanca',
    iana_zone_name: 'Africa/Casablanca' }, { rails_zone_name: 'Monrovia',
    iana_zone_name: 'Africa/Monrovia' }, { rails_zone_name: 'UTC',
    iana_zone_name: 'Etc/UTC' }, { rails_zone_name: 'Belgrade',
    iana_zone_name: 'Europe/Belgrade' }, { rails_zone_name: 'Bratislava',
    iana_zone_name: 'Europe/Bratislava' }, { rails_zone_name: 'Budapest',
    iana_zone_name: 'Europe/Budapest' }, { rails_zone_name: 'Ljubljana',
    iana_zone_name: 'Europe/Ljubljana' }, { rails_zone_name: 'Prague',
    iana_zone_name: 'Europe/Prague' }, { rails_zone_name: 'Sarajevo',
    iana_zone_name: 'Europe/Sarajevo' }, { rails_zone_name: 'Skopje',
    iana_zone_name: 'Europe/Skopje' }, { rails_zone_name: 'Warsaw',
    iana_zone_name: 'Europe/Warsaw' }, { rails_zone_name: 'Zagreb',
    iana_zone_name: 'Europe/Zagreb' }, { rails_zone_name: 'Brussels',
    iana_zone_name: 'Europe/Brussels' }, { rails_zone_name: 'Copenhagen',
    iana_zone_name: 'Europe/Copenhagen' }, { rails_zone_name: 'Madrid',
    iana_zone_name: 'Europe/Madrid' }, { rails_zone_name: 'Paris',
    iana_zone_name: 'Europe/Paris' }, { rails_zone_name: 'Amsterdam',
    iana_zone_name: 'Europe/Amsterdam' }, { rails_zone_name: 'Berlin',
    iana_zone_name: 'Europe/Berlin' }, { rails_zone_name: 'Bern',
    iana_zone_name: 'Europe/Zurich' }, { rails_zone_name: 'Zurich',
    iana_zone_name: 'Europe/Zurich' }, { rails_zone_name: 'Rome',
    iana_zone_name: 'Europe/Rome' }, { rails_zone_name: 'Stockholm',
    iana_zone_name: 'Europe/Stockholm' }, { rails_zone_name: 'Vienna',
    iana_zone_name: 'Europe/Vienna' }, { rails_zone_name: 'West Central Africa',
    iana_zone_name: 'Africa/Algiers' }, { rails_zone_name: 'Bucharest',
    iana_zone_name: 'Europe/Bucharest' }, { rails_zone_name: 'Cairo',
    iana_zone_name: 'Africa/Cairo' }, { rails_zone_name: 'Helsinki',
    iana_zone_name: 'Europe/Helsinki' }, { rails_zone_name: 'Kyiv',
    iana_zone_name: 'Europe/Kiev' }, { rails_zone_name: 'Riga',
    iana_zone_name: 'Europe/Riga' }, { rails_zone_name: 'Sofia',
    iana_zone_name: 'Europe/Sofia' }, { rails_zone_name: 'Tallinn',
    iana_zone_name: 'Europe/Tallinn' }, { rails_zone_name: 'Vilnius',
    iana_zone_name: 'Europe/Vilnius' }, { rails_zone_name: 'Athens',
    iana_zone_name: 'Europe/Athens' }, { rails_zone_name: 'Istanbul',
    iana_zone_name: 'Europe/Istanbul' }, { rails_zone_name: 'Minsk',
    iana_zone_name: 'Europe/Minsk' }, { rails_zone_name: 'Jerusalem',
    iana_zone_name: 'Asia/Jerusalem' }, { rails_zone_name: 'Harare',
    iana_zone_name: 'Africa/Harare' }, { rails_zone_name: 'Pretoria',
    iana_zone_name: 'Africa/Johannesburg' }, { rails_zone_name: 'Kaliningrad',
    iana_zone_name: 'Europe/Kaliningrad' }, { rails_zone_name: 'Moscow',
    iana_zone_name: 'Europe/Moscow' }, { rails_zone_name: 'St. Petersburg',
    iana_zone_name: 'Europe/Moscow' }, { rails_zone_name: 'Volgograd',
    iana_zone_name: 'Europe/Volgograd' }, { rails_zone_name: 'Samara',
    iana_zone_name: 'Europe/Samara' }, { rails_zone_name: 'Kuwait',
    iana_zone_name: 'Asia/Kuwait' }, { rails_zone_name: 'Riyadh',
    iana_zone_name: 'Asia/Riyadh' }, { rails_zone_name: 'Nairobi',
    iana_zone_name: 'Africa/Nairobi' }, { rails_zone_name: 'Baghdad',
    iana_zone_name: 'Asia/Baghdad' }, { rails_zone_name: 'Tehran',
    iana_zone_name: 'Asia/Tehran' }, { rails_zone_name: 'Abu Dhabi',
    iana_zone_name: 'Asia/Muscat' }, { rails_zone_name: 'Muscat',
    iana_zone_name: 'Asia/Muscat' }, { rails_zone_name: 'Baku',
    iana_zone_name: 'Asia/Baku' }, { rails_zone_name: 'Tbilisi',
    iana_zone_name: 'Asia/Tbilisi' }, { rails_zone_name: 'Yerevan',
    iana_zone_name: 'Asia/Yerevan' }, { rails_zone_name: 'Kabul',
    iana_zone_name: 'Asia/Kabul' }, { rails_zone_name: 'Ekaterinburg',
    iana_zone_name: 'Asia/Yekaterinburg' }, { rails_zone_name: 'Islamabad',
    iana_zone_name: 'Asia/Karachi' }, { rails_zone_name: 'Karachi',
    iana_zone_name: 'Asia/Karachi' }, { rails_zone_name: 'Tashkent',
    iana_zone_name: 'Asia/Tashkent' }, { rails_zone_name: 'Chennai',
    iana_zone_name: 'Asia/Kolkata' }, { rails_zone_name: 'Kolkata',
    iana_zone_name: 'Asia/Kolkata' }, { rails_zone_name: 'Mumbai',
    iana_zone_name: 'Asia/Kolkata' }, { rails_zone_name: 'New Delhi',
    iana_zone_name: 'Asia/Kolkata' }, { rails_zone_name: 'Kathmandu',
    iana_zone_name: 'Asia/Kathmandu' }, { rails_zone_name: 'Astana',
    iana_zone_name: 'Asia/Dhaka' }, { rails_zone_name: 'Dhaka',
    iana_zone_name: 'Asia/Dhaka' }, { rails_zone_name: 'Sri Jayawardenepura',
    iana_zone_name: 'Asia/Colombo' }, { rails_zone_name: 'Almaty',
    iana_zone_name: 'Asia/Almaty' }, { rails_zone_name: 'Novosibirsk',
    iana_zone_name: 'Asia/Novosibirsk' }, { rails_zone_name: 'Rangoon',
    iana_zone_name: 'Asia/Rangoon' }, { rails_zone_name: 'Bangkok',
    iana_zone_name: 'Asia/Bangkok' }, { rails_zone_name: 'Hanoi',
    iana_zone_name: 'Asia/Bangkok' }, { rails_zone_name: 'Jakarta',
    iana_zone_name: 'Asia/Jakarta' }, { rails_zone_name: 'Krasnoyarsk',
    iana_zone_name: 'Asia/Krasnoyarsk' }, { rails_zone_name: 'Beijing',
    iana_zone_name: 'Asia/Shanghai' }, { rails_zone_name: 'Chongqing',
    iana_zone_name: 'Asia/Chongqing' }, { rails_zone_name: 'Hong Kong',
    iana_zone_name: 'Asia/Hong_Kong' }, { rails_zone_name: 'Urumqi',
    iana_zone_name: 'Asia/Urumqi' }, { rails_zone_name: 'Kuala Lumpur',
    iana_zone_name: 'Asia/Kuala_Lumpur' }, { rails_zone_name: 'Singapore',
    iana_zone_name: 'Asia/Singapore' }, { rails_zone_name: 'Taipei',
    iana_zone_name: 'Asia/Taipei' }, { rails_zone_name: 'Perth',
    iana_zone_name: 'Australia/Perth' }, { rails_zone_name: 'Irkutsk',
    iana_zone_name: 'Asia/Irkutsk' }, { rails_zone_name: 'Ulaanbaatar',
    iana_zone_name: 'Asia/Ulaanbaatar' }, { rails_zone_name: 'Seoul',
    iana_zone_name: 'Asia/Seoul' }, { rails_zone_name: 'Osaka',
    iana_zone_name: 'Asia/Tokyo' }, { rails_zone_name: 'Sapporo',
    iana_zone_name: 'Asia/Tokyo' }, { rails_zone_name: 'Tokyo',
    iana_zone_name: 'Asia/Tokyo' }, { rails_zone_name: 'Yakutsk',
    iana_zone_name: 'Asia/Yakutsk' }, { rails_zone_name: 'Darwin',
    iana_zone_name: 'Australia/Darwin' }, { rails_zone_name: 'Adelaide',
    iana_zone_name: 'Australia/Adelaide' }, { rails_zone_name: 'Canberra',
    iana_zone_name: 'Australia/Melbourne' }, { rails_zone_name: 'Melbourne',
    iana_zone_name: 'Australia/Melbourne' }, { rails_zone_name: 'Sydney',
    iana_zone_name: 'Australia/Sydney' }, { rails_zone_name: 'Brisbane',
    iana_zone_name: 'Australia/Brisbane' }, { rails_zone_name: 'Hobart',
    iana_zone_name: 'Australia/Hobart' }, { rails_zone_name: 'Vladivostok',
    iana_zone_name: 'Asia/Vladivostok' }, { rails_zone_name: 'Guam',
    iana_zone_name: 'Pacific/Guam' }, { rails_zone_name: 'Port Moresby',
    iana_zone_name: 'Pacific/Port_Moresby' }, { rails_zone_name: 'Magadan',
    iana_zone_name: 'Asia/Magadan' }, { rails_zone_name: 'Srednekolymsk',
    iana_zone_name: 'Asia/Srednekolymsk' }, { rails_zone_name: 'Solomon Is.',
    iana_zone_name: 'Pacific/Guadalcanal' }, { rails_zone_name: 'New Caledonia',
    iana_zone_name: 'Pacific/Noumea' }, { rails_zone_name: 'Fiji',
    iana_zone_name: 'Pacific/Fiji' }, { rails_zone_name: 'Kamchatka',
    iana_zone_name: 'Asia/Kamchatka' }, { rails_zone_name: 'Marshall Is.',
    iana_zone_name: 'Pacific/Majuro' }, { rails_zone_name: 'Auckland',
    iana_zone_name: 'Pacific/Auckland' }, { rails_zone_name: 'Wellington',
    iana_zone_name: 'Pacific/Auckland' }, { rails_zone_name: 'Nuku\'alofa',
    iana_zone_name: 'Pacific/Tongatapu' }, { rails_zone_name: 'Tokelau Is.',
    iana_zone_name: 'Pacific/Fakaofo' }, { rails_zone_name: 'Chatham Is.',
    iana_zone_name: 'Pacific/Chatham' }, { rails_zone_name: 'Samoa',
    iana_zone_name: 'Pacific/Apia' }, { rails_zone_name: 'Buenos Aires',
    iana_zone_name: 'America/Buenos_Aires' }, { rails_zone_name: 'Alaska',
    iana_zone_name: 'America/Anchorage' }, { rails_zone_name: 'Hawaii',
    iana_zone_name: 'America/Adak' }, { rails_zone_name: 'Atlantic Time (Canada)',
    iana_zone_name: 'America/Anguilla' }, { rails_zone_name: 'Atlantic Time (Canada)',
    iana_zone_name: 'America/Antigua' }, { rails_zone_name: 'Buenos Aires',
    iana_zone_name: 'America/Araguaina' }, { rails_zone_name: 'Buenos Aires',
    iana_zone_name: 'America/Argentina/Catamarca' }, { rails_zone_name: 'Buenos Aires',
    iana_zone_name: 'America/Catamarca' }, { rails_zone_name: 'Buenos Aires',
    iana_zone_name: 'America/Argentina/Cordoba' }, { rails_zone_name: 'Buenos Aires',
    iana_zone_name: 'America/Argentina/Jujuy' }, { rails_zone_name: 'Buenos Aires',
    iana_zone_name: 'America/Argentina/La_Rioja' }, { rails_zone_name: 'Buenos Aires',
    iana_zone_name: 'America/Argentina/Mendoza' }, { rails_zone_name: 'Buenos Aires',
    iana_zone_name: 'America/Argentina/Rio_Gallegos' }, { rails_zone_name: 'Buenos Aires',
    iana_zone_name: 'America/Argentina/Salta' }, { rails_zone_name: 'Buenos Aires',
    iana_zone_name: 'America/Argentina/San_Juan' }, { rails_zone_name: 'Atlantic Time (Canada)',
    iana_zone_name: 'America/Argentina/San_Luis' }, { rails_zone_name: 'Buenos Aires',
    iana_zone_name: 'America/Argentina/Tucuman' }, { rails_zone_name: 'Buenos Aires',
    iana_zone_name: 'America/Argentina/Ushuaia' }, { rails_zone_name: 'Atlantic Time (Canada)',
    iana_zone_name: 'America/Aruba' }, { rails_zone_name: 'Atlantic Time (Canada)',
    iana_zone_name: 'America/Asuncion' }, { rails_zone_name: 'Eastern Time (US & Canada)',
    iana_zone_name: 'America/Atikokan' }, { rails_zone_name: 'Buenos Aires',
    iana_zone_name: 'America/Bahia' }, { rails_zone_name: 'Atlantic Time (Canada)',
    iana_zone_name: 'America/Barbados' }, { rails_zone_name: 'Buenos Aires',
    iana_zone_name: 'America/Belem' }, { rails_zone_name: 'Central Time (US & Canada)',
    iana_zone_name: 'America/Belize' }, { rails_zone_name: 'Atlantic Time (Canada)',
    iana_zone_name: 'America/Blanc-Sablon' }, { rails_zone_name: 'Atlantic Time (Canada)',
    iana_zone_name: 'America/Boa_Vista' }, { rails_zone_name: 'Mountain Time (US & Canada)',
    iana_zone_name: 'America/Boise' }, { rails_zone_name: 'Mountain Time (US & Canada)',
    iana_zone_name: 'America/Cambridge_Bay' }, { rails_zone_name: 'Atlantic Time (Canada)',
    iana_zone_name: 'America/Campo_Grande' }, { rails_zone_name: 'Central Time (US & Canada)',
    iana_zone_name: 'America/Cancun' }, { rails_zone_name: 'Buenos Aires',
    iana_zone_name: 'America/Cayenne' }, { rails_zone_name: 'Eastern Time (US & Canada)',
    iana_zone_name: 'America/Cayman' }, { rails_zone_name: 'Central Time (US & Canada)',
    iana_zone_name: 'America/Costa_Rica' }, { rails_zone_name: 'Atlantic Time (Canada)',
    iana_zone_name: 'America/Cuiaba' }, { rails_zone_name: 'Atlantic Time (Canada)',
    iana_zone_name: 'America/Curacao' }, { rails_zone_name: 'London',
    iana_zone_name: 'America/Danmarkshavn' }, { rails_zone_name: 'Pacific Time (US & Canada)',
    iana_zone_name: 'America/Dawson' }, { rails_zone_name: 'Mountain Time (US & Canada)',
    iana_zone_name: 'America/Dawson_Creek' }, { rails_zone_name: 'Eastern Time (US & Canada)',
    iana_zone_name: 'America/Detroit' }, { rails_zone_name: 'Atlantic Time (Canada)',
    iana_zone_name: 'America/Dominica' }, { rails_zone_name: 'Mountain Time (US & Canada)',
    iana_zone_name: 'America/Edmonton' }, { rails_zone_name: 'Atlantic Time (Canada)',
    iana_zone_name: 'America/Eirunepe' }, { rails_zone_name: 'Central Time (US & Canada)',
    iana_zone_name: 'America/El_Salvador' }, { rails_zone_name: 'Buenos Aires',
    iana_zone_name: 'America/Fortaleza' }, { rails_zone_name: 'Atlantic Time (Canada)',
    iana_zone_name: 'America/Glace_Bay' }, { rails_zone_name: 'Atlantic Time (Canada)',
    iana_zone_name: 'America/Goose_Bay' }, { rails_zone_name: 'Eastern Time (US & Canada)',
    iana_zone_name: 'America/Grand_Turk' }, { rails_zone_name: 'Atlantic Time (Canada)',
    iana_zone_name: 'America/Grenada' }, { rails_zone_name: 'Atlantic Time (Canada)',
    iana_zone_name: 'America/Guadeloupe' }, { rails_zone_name: 'Eastern Time (US & Canada)',
    iana_zone_name: 'America/Guayaquil' }, { rails_zone_name: 'Eastern Time (US & Canada)',
    iana_zone_name: 'America/Havana' }, { rails_zone_name: 'Mountain Time (US & Canada)',
    iana_zone_name: 'America/Hermosillo' }, { rails_zone_name: 'Central Time (US & Canada)',
    iana_zone_name: 'America/Indiana/Knox' }, { rails_zone_name: 'Eastern Time (US & Canada)',
    iana_zone_name: 'America/Indiana/Marengo' }, { rails_zone_name: 'Eastern Time (US & Canada)',
    iana_zone_name: 'America/Indiana/Petersburg' }, { rails_zone_name: 'Central Time (US & Canada)',
    iana_zone_name: 'America/Indiana/Tell_City' }, { rails_zone_name: 'Eastern Time (US & Canada)',
    iana_zone_name: 'America/Indiana/Vevay' }, { rails_zone_name: 'Eastern Time (US & Canada)',
    iana_zone_name: 'America/Indiana/Vincennes' }, { rails_zone_name: 'Eastern Time (US & Canada)',
    iana_zone_name: 'America/Indiana/Winamac' }, { rails_zone_name: 'Mountain Time (US & Canada)',
    iana_zone_name: 'America/Inuvik' }, { rails_zone_name: 'Eastern Time (US & Canada)',
    iana_zone_name: 'America/Iqaluit' }, { rails_zone_name: 'Eastern Time (US & Canada)',
    iana_zone_name: 'America/Jamaica' }, { rails_zone_name: 'Eastern Time (US & Canada)',
    iana_zone_name: 'America/Kentucky/Louisville' }, { rails_zone_name: 'Eastern Time (US & Canada)',
    iana_zone_name: 'America/Kentucky/Monticello' }, { rails_zone_name: 'Buenos Aires',
    iana_zone_name: 'America/Maceio' }, { rails_zone_name: 'Central Time (US & Canada)',
    iana_zone_name: 'America/Managua' }, { rails_zone_name: 'Atlantic Time (Canada)',
    iana_zone_name: 'America/Manaus' }, { rails_zone_name: 'Atlantic Time (Canada)',
    iana_zone_name: 'America/Marigot' }, { rails_zone_name: 'Atlantic Time (Canada)',
    iana_zone_name: 'America/Martinique' }, { rails_zone_name: 'Central Time (US & Canada)',
    iana_zone_name: 'America/Matamoros' }, { rails_zone_name: 'Central Time (US & Canada)',
    iana_zone_name: 'America/Menominee' }, { rails_zone_name: 'Central Time (US & Canada)',
    iana_zone_name: 'America/Merida' }, { rails_zone_name: 'Buenos Aires',
    iana_zone_name: 'America/Miquelon' }, { rails_zone_name: 'Atlantic Time (Canada)',
    iana_zone_name: 'America/Moncton' }, { rails_zone_name: 'Eastern Time (US & Canada)',
    iana_zone_name: 'America/Montreal' }, { rails_zone_name: 'Atlantic Time (Canada)',
    iana_zone_name: 'America/Montserrat' }, { rails_zone_name: 'Eastern Time (US & Canada)',
    iana_zone_name: 'America/Nassau' }, { rails_zone_name: 'Eastern Time (US & Canada)',
    iana_zone_name: 'America/Nipigon' }, { rails_zone_name: 'Alaska',
    iana_zone_name: 'America/Nome' }, { rails_zone_name: 'Mid-Atlantic',
    iana_zone_name: 'America/Noronha' }, { rails_zone_name: 'Central Time (US & Canada)',
    iana_zone_name: 'America/North_Dakota/Center' }, { rails_zone_name: 'Central Time (US & Canada)',
    iana_zone_name: 'America/North_Dakota/New_Salem' }, { rails_zone_name: 'Mountain Time (US & Canada)',
    iana_zone_name: 'America/Ojinaga' }, { rails_zone_name: 'Eastern Time (US & Canada)',
    iana_zone_name: 'America/Panama' }, { rails_zone_name: 'Eastern Time (US & Canada)',
    iana_zone_name: 'America/Pangnirtung' }, { rails_zone_name: 'Buenos Aires',
    iana_zone_name: 'America/Paramaribo' }, { rails_zone_name: 'Eastern Time (US & Canada)',
    iana_zone_name: 'America/Port-au-Prince' }, { rails_zone_name: 'Atlantic Time (Canada)',
    iana_zone_name: 'America/Port_of_Spain' }, { rails_zone_name: 'Atlantic Time (Canada)',
    iana_zone_name: 'America/Porto_Velho' }, { rails_zone_name: 'Central Time (US & Canada)',
    iana_zone_name: 'America/Rainy_River' }, { rails_zone_name: 'Central Time (US & Canada)',
    iana_zone_name: 'America/Rankin_Inlet' }, { rails_zone_name: 'Buenos Aires',
    iana_zone_name: 'America/Recife' }, { rails_zone_name: 'Eastern Time (US & Canada)',
    iana_zone_name: 'America/Resolute' }, { rails_zone_name: 'Atlantic Time (Canada)',
    iana_zone_name: 'America/Rio_Branco' }, { rails_zone_name: 'Pacific Time (US & Canada)',
    iana_zone_name: 'America/Santa_Isabel' }, { rails_zone_name: 'Buenos Aires',
    iana_zone_name: 'America/Santarem' }, { rails_zone_name: 'Atlantic Time (Canada)',
    iana_zone_name: 'America/Santo_Domingo' }, { rails_zone_name: 'Cape Verde Is.',
    iana_zone_name: 'America/Scoresbysund' }, { rails_zone_name: 'Mountain Time (US & Canada)',
    iana_zone_name: 'America/Shiprock' }, { rails_zone_name: 'Atlantic Time (Canada)',
    iana_zone_name: 'America/St_Barthelemy' }, { rails_zone_name: 'Atlantic Time (Canada)',
    iana_zone_name: 'America/St_Kitts' }, { rails_zone_name: 'Atlantic Time (Canada)',
    iana_zone_name: 'America/St_Lucia' }, { rails_zone_name: 'Atlantic Time (Canada)',
    iana_zone_name: 'America/St_Thomas' }, { rails_zone_name: 'Atlantic Time (Canada)',
    iana_zone_name: 'America/St_Vincent' }, { rails_zone_name: 'Central Time (US & Canada)',
    iana_zone_name: 'America/Swift_Current' }, { rails_zone_name: 'Central Time (US & Canada)',
    iana_zone_name: 'America/Tegucigalpa' }, { rails_zone_name: 'Atlantic Time (Canada)',
    iana_zone_name: 'America/Thule' }, { rails_zone_name: 'Eastern Time (US & Canada)',
    iana_zone_name: 'America/Thunder_Bay' }, { rails_zone_name: 'Eastern Time (US & Canada)',
    iana_zone_name: 'America/Toronto' }, { rails_zone_name: 'Atlantic Time (Canada)',
    iana_zone_name: 'America/Tortola' }, { rails_zone_name: 'Pacific Time (US & Canada)',
    iana_zone_name: 'America/Vancouver' }, { rails_zone_name: 'Pacific Time (US & Canada)',
    iana_zone_name: 'America/Whitehorse' }, { rails_zone_name: 'Central Time (US & Canada)',
    iana_zone_name: 'America/Winnipeg' }, { rails_zone_name: 'Alaska',
    iana_zone_name: 'America/Yakutat' }, { rails_zone_name: 'Mountain Time (US & Canada)',
    iana_zone_name: 'America/Yellowknife' }, { rails_zone_name: 'Paris',
    iana_zone_name: 'Europe/Andorra' }, { rails_zone_name: 'Cairo',
    iana_zone_name: 'Europe/Chisinau' }, { rails_zone_name: 'Paris',
    iana_zone_name: 'Europe/Gibraltar' }, { rails_zone_name: 'London',
    iana_zone_name: 'Europe/Guernsey' }, { rails_zone_name: 'London',
    iana_zone_name: 'Europe/Isle_of_Man' }, { rails_zone_name: 'London',
    iana_zone_name: 'Europe/Jersey' }, { rails_zone_name: 'Paris',
    iana_zone_name: 'Europe/Luxembourg' }, { rails_zone_name: 'Paris',
    iana_zone_name: 'Europe/Malta' }, { rails_zone_name: 'Cairo',
    iana_zone_name: 'Europe/Mariehamn' }, { rails_zone_name: 'Paris',
    iana_zone_name: 'Europe/Monaco' }, { rails_zone_name: 'Paris',
    iana_zone_name: 'Europe/Oslo' }, { rails_zone_name: 'Paris',
    iana_zone_name: 'Europe/Podgorica' }, { rails_zone_name: 'Paris',
    iana_zone_name: 'Europe/San_Marino' }, { rails_zone_name: 'Cairo',
    iana_zone_name: 'Europe/Simferopol' }, { rails_zone_name: 'Paris',
    iana_zone_name: 'Europe/Tirane' }, { rails_zone_name: 'Cairo',
    iana_zone_name: 'Europe/Uzhgorod' }, { rails_zone_name: 'Paris',
    iana_zone_name: 'Europe/Vaduz' }, { rails_zone_name: 'Paris',
    iana_zone_name: 'Europe/Vatican' }, { rails_zone_name: 'Cairo',
    iana_zone_name: 'Europe/Zaporozhye' }, { rails_zone_name: 'Moscow',
    iana_zone_name: 'Asia/Aden' }, { rails_zone_name: 'Cairo',
    iana_zone_name: 'Asia/Amman' }, { rails_zone_name: 'Solomon Is.',
    iana_zone_name: 'Asia/Anadyr' }, { rails_zone_name: 'Karachi',
    iana_zone_name: 'Asia/Aqtau' }, { rails_zone_name: 'Karachi',
    iana_zone_name: 'Asia/Aqtobe' }, { rails_zone_name: 'Karachi',
    iana_zone_name: 'Asia/Ashgabat' }, { rails_zone_name: 'Moscow',
    iana_zone_name: 'Asia/Bahrain' }, { rails_zone_name: 'Cairo',
    iana_zone_name: 'Asia/Beirut' }, { rails_zone_name: 'Dhaka',
    iana_zone_name: 'Asia/Bishkek' }, { rails_zone_name: 'Hong Kong',
    iana_zone_name: 'Asia/Brunei' }, { rails_zone_name: 'Hong Kong',
    iana_zone_name: 'Asia/Choibalsan' }, { rails_zone_name: 'Cairo',
    iana_zone_name: 'Asia/Damascus' }, { rails_zone_name: 'Tokyo',
    iana_zone_name: 'Asia/Dili' }, { rails_zone_name: 'Baku',
    iana_zone_name: 'Asia/Dubai' }, { rails_zone_name: 'Karachi',
    iana_zone_name: 'Asia/Dushanbe' }, { rails_zone_name: 'Cairo',
    iana_zone_name: 'Asia/Gaza' }, { rails_zone_name: 'Hong Kong',
    iana_zone_name: 'Asia/Harbin' }, { rails_zone_name: 'Jakarta',
    iana_zone_name: 'Asia/Ho_Chi_Minh' }, { rails_zone_name: 'Jakarta',
    iana_zone_name: 'Asia/Hovd' }, { rails_zone_name: 'Tokyo',
    iana_zone_name: 'Asia/Jayapura' }, { rails_zone_name: 'Hong Kong',
    iana_zone_name: 'Asia/Kashgar' }, { rails_zone_name: 'Hong Kong',
    iana_zone_name: 'Asia/Kuching' }, { rails_zone_name: 'Hong Kong',
    iana_zone_name: 'Asia/Macau' }, { rails_zone_name: 'Hong Kong',
    iana_zone_name: 'Asia/Makassar' }, { rails_zone_name: 'Hong Kong',
    iana_zone_name: 'Asia/Manila' }, { rails_zone_name: 'Cairo',
    iana_zone_name: 'Asia/Nicosia' }, { rails_zone_name: 'Dhaka',
    iana_zone_name: 'Asia/Novokuznetsk' }, { rails_zone_name: 'Dhaka',
    iana_zone_name: 'Asia/Omsk' }, { rails_zone_name: 'Karachi',
    iana_zone_name: 'Asia/Oral' }, { rails_zone_name: 'Jakarta',
    iana_zone_name: 'Asia/Phnom_Penh' }, { rails_zone_name: 'Jakarta',
    iana_zone_name: 'Asia/Pontianak' }, { rails_zone_name: 'Tokyo',
    iana_zone_name: 'Asia/Pyongyang' }, { rails_zone_name: 'Moscow',
    iana_zone_name: 'Asia/Qatar' }, { rails_zone_name: 'Dhaka',
    iana_zone_name: 'Asia/Qyzylorda' }, { rails_zone_name: 'Sydney',
    iana_zone_name: 'Asia/Sakhalin' }, { rails_zone_name: 'Karachi',
    iana_zone_name: 'Asia/Samarkand' }, { rails_zone_name: 'Dhaka',
    iana_zone_name: 'Asia/Thimphu' }, { rails_zone_name: 'Jakarta',
    iana_zone_name: 'Asia/Vientiane' }, { rails_zone_name: 'London',
    iana_zone_name: 'Africa/Abidjan' }, { rails_zone_name: 'London',
    iana_zone_name: 'Africa/Accra' }, { rails_zone_name: 'Moscow',
    iana_zone_name: 'Africa/Addis_Ababa' }, { rails_zone_name: 'Moscow',
    iana_zone_name: 'Africa/Asmara' }, { rails_zone_name: 'London',
    iana_zone_name: 'Africa/Bamako' }, { rails_zone_name: 'Paris',
    iana_zone_name: 'Africa/Bangui' }, { rails_zone_name: 'London',
    iana_zone_name: 'Africa/Banjul' }, { rails_zone_name: 'London',
    iana_zone_name: 'Africa/Bissau' }, { rails_zone_name: 'Cairo',
    iana_zone_name: 'Africa/Blantyre' }, { rails_zone_name: 'Paris',
    iana_zone_name: 'Africa/Brazzaville' }, { rails_zone_name: 'Cairo',
    iana_zone_name: 'Africa/Bujumbura' }, { rails_zone_name: 'Paris',
    iana_zone_name: 'Africa/Ceuta' }, { rails_zone_name: 'London',
    iana_zone_name: 'Africa/Conakry' }, { rails_zone_name: 'London',
    iana_zone_name: 'Africa/Dakar' }, { rails_zone_name: 'Moscow',
    iana_zone_name: 'Africa/Dar_es_Salaam' }, { rails_zone_name: 'Moscow',
    iana_zone_name: 'Africa/Djibouti' }, { rails_zone_name: 'Paris',
    iana_zone_name: 'Africa/Douala' }, { rails_zone_name: 'London',
    iana_zone_name: 'Africa/El_Aaiun' }, { rails_zone_name: 'London',
    iana_zone_name: 'Africa/Freetown' }, { rails_zone_name: 'Cairo',
    iana_zone_name: 'Africa/Gaborone' }, { rails_zone_name: 'Moscow',
    iana_zone_name: 'Africa/Kampala' }, { rails_zone_name: 'Moscow',
    iana_zone_name: 'Africa/Khartoum' }, { rails_zone_name: 'Cairo',
    iana_zone_name: 'Africa/Kigali' }, { rails_zone_name: 'Paris',
    iana_zone_name: 'Africa/Kinshasa' }, { rails_zone_name: 'Paris',
    iana_zone_name: 'Africa/Lagos' }, { rails_zone_name: 'Paris',
    iana_zone_name: 'Africa/Libreville' }, { rails_zone_name: 'London',
    iana_zone_name: 'Africa/Lome' }, { rails_zone_name: 'Paris',
    iana_zone_name: 'Africa/Luanda' }, { rails_zone_name: 'Cairo',
    iana_zone_name: 'Africa/Lubumbashi' }, { rails_zone_name: 'Cairo',
    iana_zone_name: 'Africa/Lusaka' }, { rails_zone_name: 'Paris',
    iana_zone_name: 'Africa/Malabo' }, { rails_zone_name: 'Cairo',
    iana_zone_name: 'Africa/Maputo' }, { rails_zone_name: 'Cairo',
    iana_zone_name: 'Africa/Maseru' }, { rails_zone_name: 'Cairo',
    iana_zone_name: 'Africa/Mbabane' }, { rails_zone_name: 'Moscow',
    iana_zone_name: 'Africa/Mogadishu' }, { rails_zone_name: 'Paris',
    iana_zone_name: 'Africa/Ndjamena' }, { rails_zone_name: 'Paris',
    iana_zone_name: 'Africa/Niamey' }, { rails_zone_name: 'London',
    iana_zone_name: 'Africa/Nouakchott' }, { rails_zone_name: 'London',
    iana_zone_name: 'Africa/Ouagadougou' }, { rails_zone_name: 'Paris',
    iana_zone_name: 'Africa/Porto-Novo' }, { rails_zone_name: 'London',
    iana_zone_name: 'Africa/Sao_Tome' }, { rails_zone_name: 'Cairo',
    iana_zone_name: 'Africa/Tripoli' }, { rails_zone_name: 'Paris',
    iana_zone_name: 'Africa/Tunis' }, { rails_zone_name: 'Paris',
    iana_zone_name: 'Africa/Windhoek' }, { rails_zone_name: 'Adelaide',
    iana_zone_name: 'Australia/Broken_Hill' }, { rails_zone_name: 'Sydney',
    iana_zone_name: 'Australia/Currie' }, { rails_zone_name: 'Sydney',
    iana_zone_name: 'Australia/Lindeman' }, { rails_zone_name: 'Moscow',
    iana_zone_name: 'Indian/Antananarivo' }, { rails_zone_name: 'Dhaka',
    iana_zone_name: 'Indian/Chagos' }, { rails_zone_name: 'Jakarta',
    iana_zone_name: 'Indian/Christmas' }, { rails_zone_name: 'Rangoon',
    iana_zone_name: 'Indian/Cocos' }, { rails_zone_name: 'Moscow',
    iana_zone_name: 'Indian/Comoro' }, { rails_zone_name: 'Karachi',
    iana_zone_name: 'Indian/Kerguelen' }, { rails_zone_name: 'Baku',
    iana_zone_name: 'Indian/Mahe' }, { rails_zone_name: 'Karachi',
    iana_zone_name: 'Indian/Maldives' }, { rails_zone_name: 'Baku',
    iana_zone_name: 'Indian/Mauritius' }, { rails_zone_name: 'Moscow',
    iana_zone_name: 'Indian/Mayotte' }, { rails_zone_name: 'Baku',
    iana_zone_name: 'Indian/Reunion' }, { rails_zone_name: 'Atlantic Time (Canada)',
    iana_zone_name: 'Atlantic/Bermuda' }, { rails_zone_name: 'London',
    iana_zone_name: 'Atlantic/Canary' }, { rails_zone_name: 'London',
    iana_zone_name: 'Atlantic/Faroe' }, { rails_zone_name: 'London',
    iana_zone_name: 'Atlantic/Madeira' }, { rails_zone_name: 'London',
    iana_zone_name: 'Atlantic/Reykjavik' }, { rails_zone_name: 'Atlantic Time (Canada)',
    iana_zone_name: 'Atlantic/Stanley' }, { rails_zone_name: 'London',
    iana_zone_name: 'Atlantic/St_Helena' }, { rails_zone_name: 'Central Time (US & Canada)',
    iana_zone_name: 'Pacific/Easter' }, { rails_zone_name: 'Solomon Is.',
    iana_zone_name: 'Pacific/Efate' }, { rails_zone_name: 'Nuku\'alofa',
    iana_zone_name: 'Pacific/Enderbury' }, { rails_zone_name: 'Auckland',
    iana_zone_name: 'Pacific/Funafuti' }, { rails_zone_name: 'Central Time (US & Canada)',
    iana_zone_name: 'Pacific/Galapagos' }, { rails_zone_name: 'Alaska',
    iana_zone_name: 'Pacific/Gambier' }, { rails_zone_name: 'Hawaii',
    iana_zone_name: 'Pacific/Johnston' }, { rails_zone_name: 'Solomon Is.',
    iana_zone_name: 'Pacific/Kosrae' }, { rails_zone_name: 'Auckland',
    iana_zone_name: 'Pacific/Kwajalein' }, { rails_zone_name: 'Auckland',
    iana_zone_name: 'Pacific/Nauru' }, { rails_zone_name: 'Samoa',
    iana_zone_name: 'Pacific/Niue' }, { rails_zone_name: 'Tokyo',
    iana_zone_name: 'Pacific/Palau' }, { rails_zone_name: 'Pacific Time (US & Canada)',
    iana_zone_name: 'Pacific/Pitcairn' }, { rails_zone_name: 'Solomon Is.',
    iana_zone_name: 'Pacific/Ponape' }, { rails_zone_name: 'Hawaii',
    iana_zone_name: 'Pacific/Rarotonga' }, { rails_zone_name: 'Sydney',
    iana_zone_name: 'Pacific/Saipan' }, { rails_zone_name: 'Hawaii',
    iana_zone_name: 'Pacific/Tahiti' }, { rails_zone_name: 'Auckland',
    iana_zone_name: 'Pacific/Tarawa' }, { rails_zone_name: 'Sydney',
    iana_zone_name: 'Pacific/Truk' }, { rails_zone_name: 'Auckland',
    iana_zone_name: 'Pacific/Wake' }, { rails_zone_name: 'Auckland',
    iana_zone_name: 'Pacific/Wallis' }, { rails_zone_name: 'Hong Kong',
    iana_zone_name: 'Antarctica/Casey' }, { rails_zone_name: 'Jakarta',
    iana_zone_name: 'Antarctica/Davis' }, { rails_zone_name: 'Sydney',
    iana_zone_name: 'Antarctica/DumontDUrville' }, { rails_zone_name: 'Dhaka',
    iana_zone_name: 'Antarctica/Mawson' }, { rails_zone_name: 'Auckland',
    iana_zone_name: 'Antarctica/McMurdo' }, { rails_zone_name: 'Atlantic Time (Canada)',
    iana_zone_name: 'Antarctica/Palmer' }, { rails_zone_name: 'Buenos Aires',
    iana_zone_name: 'Antarctica/Rothera' }, { rails_zone_name: 'Auckland',
    iana_zone_name: 'Antarctica/South_Pole' }, { rails_zone_name: 'Moscow',
    iana_zone_name: 'Antarctica/Syowa' }, { rails_zone_name: 'London',
    iana_zone_name: 'Antarctica/Vostok' }, { rails_zone_name: 'Paris',
    iana_zone_name: 'Arctic/Longyearbyen' }, { rails_zone_name: 'Eastern Time (US & Canada)',
    iana_zone_name: 'America/Coral_Harbour' }, { rails_zone_name: 'Hawaii',
    iana_zone_name: 'America/Atka' }, { rails_zone_name: 'Buenos Aires',
    iana_zone_name: 'America/Cordoba' }, { rails_zone_name: 'Buenos Aires',
    iana_zone_name: 'America/Jujuy' }, { rails_zone_name: 'Buenos Aires',
    iana_zone_name: 'America/Mendoza' }, { rails_zone_name: 'Pacific Time (US & Canada)',
    iana_zone_name: 'America/Ensenada' }, { rails_zone_name: 'Eastern Time (US & Canada)',
    iana_zone_name: 'America/Fort_Wayne' }, { rails_zone_name: 'Eastern Time (US & Canada)',
    iana_zone_name: 'America/Indianapolis' }, { rails_zone_name: 'Eastern Time (US & Canada)',
    iana_zone_name: 'America/Indianapolis' }, { rails_zone_name: 'Eastern Time (US & Canada)',
    iana_zone_name: 'America/Louisville' }, { rails_zone_name: 'Central Time (US & Canada)',
    iana_zone_name: 'America/Knox_IN' }, { rails_zone_name: 'Atlantic Time (Canada)',
    iana_zone_name: 'America/Porto_Acre' }, { rails_zone_name: 'Buenos Aires',
    iana_zone_name: 'America/Rosario' }, { rails_zone_name: 'Atlantic Time (Canada)',
    iana_zone_name: 'America/Virgin' }, { rails_zone_name: 'London',
    iana_zone_name: 'Europe/Belfast' }, { rails_zone_name: 'London',
    iana_zone_name: 'Europe/Belfast' }, { rails_zone_name: 'Moscow',
    iana_zone_name: 'Europe/Nicosia' }, { rails_zone_name: 'Moscow',
    iana_zone_name: 'Europe/Tiraspol' }, { rails_zone_name: 'Ekaterinburg',
    iana_zone_name: 'Asia/Ashkhabad' }, { rails_zone_name: 'Mumbai',
    iana_zone_name: 'Asia/Calcutta' }, { rails_zone_name: 'Mumbai',
    iana_zone_name: 'Asia/Calcutta' }, { rails_zone_name: 'Beijing',
    iana_zone_name: 'Asia/Chungking' }, { rails_zone_name: 'Dhaka',
    iana_zone_name: 'Asia/Dacca' }, { rails_zone_name: 'Istanbul',
    iana_zone_name: 'Asia/Istanbul' }, { rails_zone_name: 'Kathmandu',
    iana_zone_name: 'Asia/Katmandu' }, { rails_zone_name: 'Hong Kong',
    iana_zone_name: 'Asia/Macao' }, { rails_zone_name: 'Jakarta',
    iana_zone_name: 'Asia/Saigon' }, { rails_zone_name: 'Jerusalem',
    iana_zone_name: 'Asia/Tel_Aviv' }, { rails_zone_name: 'Dhaka',
    iana_zone_name: 'Asia/Thimbu' }, { rails_zone_name: 'Hong Kong',
    iana_zone_name: 'Asia/Ujung_Pandang' }, { rails_zone_name: 'Ulaanbaatar',
    iana_zone_name: 'Asia/Ulan_Bator' }, { rails_zone_name: 'Moscow',
    iana_zone_name: 'Africa/Asmera' }, { rails_zone_name: 'London',
    iana_zone_name: 'Africa/Timbuktu' }, { rails_zone_name: 'London',
    iana_zone_name: 'Africa/Timbuktu' }, { rails_zone_name: 'Sydney',
    iana_zone_name: 'Australia/ACT' }, { rails_zone_name: 'Sydney',
    iana_zone_name: 'Australia/NSW' }, { rails_zone_name: 'Sydney',
    iana_zone_name: 'Australia/Canberra' }, { rails_zone_name: 'Perth',
    iana_zone_name: 'Australia/Eucla' }, { rails_zone_name: 'Darwin',
    iana_zone_name: 'Australia/North' }, { rails_zone_name: 'Brisbane',
    iana_zone_name: 'Australia/Queensland' }, { rails_zone_name: 'Melbourne',
    iana_zone_name: 'Australia/Victoria' }, { rails_zone_name: 'Perth',
    iana_zone_name: 'Australia/West' }, { rails_zone_name: 'Hobart',
    iana_zone_name: 'Australia/Tasmania' }, { rails_zone_name: 'Perth',
    iana_zone_name: 'Australia/West' }, { rails_zone_name: 'Sydney',
    iana_zone_name: 'Australia/Canberra' }, { rails_zone_name: 'Sydney',
    iana_zone_name: 'Australia/LHI' }, { rails_zone_name: 'Sydney',
    iana_zone_name: 'Australia/Lord_Howe' }, { rails_zone_name: 'Adelaide',
    iana_zone_name: 'Australia/South' }, { rails_zone_name: 'Adelaide',
    iana_zone_name: 'Australia/Yancowinna' }, { rails_zone_name: 'Dublin',
    iana_zone_name: 'Atlantic/Faeroe' }, { rails_zone_name: 'London',
    iana_zone_name: 'Atlantic/Jan_Mayen' }, { rails_zone_name: 'Midway Island',
    iana_zone_name: 'Pacific/Kiritimati' }, { rails_zone_name: 'Hawaii',
    iana_zone_name: 'Pacific/Marquesas' }, { rails_zone_name: 'Magadan',
    iana_zone_name: 'Pacific/Norfolk' }, { rails_zone_name: 'Fiji',
    iana_zone_name: 'Pacific/Samoa' }, { rails_zone_name: 'Guam',
    iana_zone_name: 'Pacific/Yap' }, { rails_zone_name: 'Auckland',
    iana_zone_name: 'Pacific/Macquarie' }, { rails_zone_name: 'UTC',
    iana_zone_name: 'UTC' }, { rails_zone_name: 'Midway Island',
    iana_zone_name: 'Etc/GMT-14' }, { rails_zone_name: 'Nuku\'alofa',
    iana_zone_name: 'Etc/GMT-13' }, { rails_zone_name: 'Auckland',
    iana_zone_name: 'Etc/GMT-12' }, { rails_zone_name: 'Magadan',
    iana_zone_name: 'Etc/GMT-11' }, { rails_zone_name: 'Sydney',
    iana_zone_name: 'Etc/GMT-10' }, { rails_zone_name: 'Tokyo',
    iana_zone_name: 'Etc/GMT-9' }, { rails_zone_name: 'Beijing',
    iana_zone_name: 'Etc/GMT-8' }, { rails_zone_name: 'Bangkok',
    iana_zone_name: 'Etc/GMT-7' }, { rails_zone_name: 'Dhaka',
    iana_zone_name: 'Etc/GMT-6' }, { rails_zone_name: 'Karachi',
    iana_zone_name: 'Etc/GMT-5' }, { rails_zone_name: 'Dubai',
    iana_zone_name: 'Etc/GMT-4' }, { rails_zone_name: 'Moscow',
    iana_zone_name: 'Etc/GMT-3' }, { rails_zone_name: 'Jerusalem',
    iana_zone_name: 'Etc/GMT-2' }, { rails_zone_name: 'Amsterdam',
    iana_zone_name: 'Etc/GMT-1' }, { rails_zone_name: 'London',
    iana_zone_name: 'Etc/GMT+0' }, { rails_zone_name: 'Cape Verde Is.',
    iana_zone_name: 'Etc/GMT+1' }, { rails_zone_name: 'Mid-Atlantic',
    iana_zone_name: 'Etc/GMT+2' }, { rails_zone_name: 'Buenos Aires',
    iana_zone_name: 'Etc/GMT+3' }, { rails_zone_name: 'Puerto Rico',
    iana_zone_name: 'Etc/GMT+4' }, { rails_zone_name: 'Eastern Time (US & Canada)',
    iana_zone_name: 'Etc/GMT+5' }, { rails_zone_name: 'Central Time (US & Canada)',
    iana_zone_name: 'Etc/GMT+6' }, { rails_zone_name: 'Mountain Time (US & Canada)',
    iana_zone_name: 'Etc/GMT+7' }, { rails_zone_name: 'Pacific Time (US & Canada)',
    iana_zone_name: 'Etc/GMT+8' }, { rails_zone_name: 'Alaska',
    iana_zone_name: 'Etc/GMT+9' }, { rails_zone_name: 'Hawaii',
    iana_zone_name: 'Etc/GMT+10' }, { rails_zone_name: 'Midway Island',
    iana_zone_name: 'Etc/GMT+11' }, { rails_zone_name: 'Midway Island',
    iana_zone_name: 'Etc/GMT+12' }];

  var railsZoneNames = exports.railsZoneNames = function railsZoneNames() {
    return Array.from(new Set(TIMEZONE_MAP.mapBy('rails_zone_name')));
  };
});
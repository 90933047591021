define('client/components/reports/chart-line', ['exports', 'client/components/reports/chart-base-widget', 'client/components/reports/base-widget'], function (exports, _chartBaseWidget, _baseWidget) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _chartBaseWidget.default.extend({
    classNameBindings: [':report-chart-item'],

    showDataPointsCheckbox: true,
    showDataValuesCheckboxThreshold: 20,

    groupOptionsMenuName: Ember.computed.alias("item.groupOptionsMenuName"),
    groupDimension: Ember.computed.alias("item.groupDimension")
  });
});
define('client/components/expanded-article-readership-metric', ['exports', 'client/components/article-metric'], function (exports, _articleMetric) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var ExpandedArticleReadershipMetricComponent = _articleMetric.default.extend({
    layoutName: 'components/expanded-article-metric',
    classNames: ["metric-readship", "no-hover-metric", "metric"],
    tooltipTitle: "The number of unique visitors to this article (owned media only).",
    metricTitle: Ember.computed.alias("profile.nameReadership"),
    conversionsTitle: Ember.computed.alias("profile.nameReadership"),
    iconClass: "metric-icon metric-readership",
    formatter: function formatter(d) {
      return d;
    }
  });

  exports.default = ExpandedArticleReadershipMetricComponent;
});
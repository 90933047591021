define('client/models/pitch-list', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var PitchList = _emberData.default.Model.extend({
    ajax: Ember.inject.service("ajax"),

    profile: _emberData.default.belongsTo("profile"),
    name: _emberData.default.attr("string"),
    description: _emberData.default.attr("string"),
    authors: _emberData.default.hasMany("author"),

    exportToCSV: function exportToCSV(user) {

      var request = this.store.createRecord("export-request", {
        user: user,
        profile: this.get("profile"),
        sendEmail: true,
        generatorType: "PitchList",
        generatorId: this.get("id")
      });
      return request.save();
    }
  });

  exports.default = PitchList;
});
define('client/utils/chart-data-transformer', ['exports', 'client/utils/time-series-labeler', 'client/utils/percent-data-points', 'client/utils/social-settings', 'lodash'], function (exports, _timeSeriesLabeler, _percentDataPoints, _socialSettings, _lodash) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Object.extend({
    // This object expects the following:
    // - apiData: array of data tuples
    // - filterList: If data has sub-fields, which to filter.  `null` means data has no sub-fields
    // - customFiscalYearOffset: Allow shifting fiscal year end date
    // - graphValues: Map of keys to aggregate type.  E.g:
    //   {
    //     coverage: "sum"
    //   }
    // - graphLabelKeys: Array of keys for the graph labels
    // - graphDateBinType: How to bin dates... 'Month', 'Day', ...
    // - labelParseMap: {
    //      toLabel: for each key how to go from that tuple value to chart label
    //      fromLabel: how to go from label back to data (drilldown)
    //   }
    // - fromDate: Date to restrict formatted
    // - toDate:   Date to restrict data returned
    // - hideBlankCategories: (optional) hide blank?
    filterList: null,
    graphLabelKeys: null,
    graphDateBinType: null,
    apiData: null,
    hideBlankCategories: false,
    labelParseMap: {
      toLabel: {},
      fromLabel: {}
    },

    graphValueKeys: Ember.computed('graphValues', function () {
      return Object.keys(this.get('graphValues'));
    }),

    // overrideable function for determining default value
    defaultValue: Ember.computed(function () {
      return function (date, defaultValues) {
        return defaultValues;
      };
    }),

    formattedData: Ember.computed.alias("filledData"),
    filledData: Ember.computed("preFilledData", function () {
      var defaultValuesByLabel = {};
      var data = this.get('preFilledData');
      if (this.get('graphType') === 'pie' || this.get('graphType') === 'donut') {
        return data;
      }

      var filledData = data;

      var labels = d3.nest().key(function (d) {
        return d.labels[1] || d.labels[0];
      }).entries(data).map(function (d) {
        return d.key;
      }).uniq();
      labels.forEach(function (label) {
        var first = data.find(function (d) {
          return d.labels.indexOf(label) >= 0;
        });
        defaultValuesByLabel[label] = {
          values: new Array(first.values.length).fill(0),
          labels: [label],
          color: first.color
        };
      });

      d3.nest().key(function (d) {
        return d.labels[0];
      }).entries(data).forEach(function (group) {
        if (group.values.length === labels.length) {
          return;
        }
        // Add data point for each missing label
        labels.forEach(function (label) {
          var point = group.values.find(function (v) {
            return v.labels.indexOf(label) >= 0;
          });
          if (!point) {
            var defaultPoint = Ember.merge({}, defaultValuesByLabel[label]);
            defaultPoint.labels = defaultPoint.labels.concat([]);
            defaultPoint.labels.splice(0, 0, group.key);
            filledData = filledData.concat([defaultPoint]);
          }
        });
      });
      return filledData;
    }),

    preFilledData: Ember.computed('hideBlankData', 'graphLabelKeys', 'graphValueKeys.[]', function () {
      var labelKeys = this.get('graphLabelKeys');
      var valueKeys = this.get('graphValueKeys');
      var colorScale = this.get("colorScale");

      return this.get('hideBlankData').map(function (d) {
        var labels = labelKeys.map(function (key) {
          return d[key];
        });
        var values = valueKeys.map(function (key) {
          return d[key];
        });
        var color = colorScale(d);
        return { labels: labels, values: values, color: color };
      });
    }),

    hideBlankData: Ember.computed("rebinnedData", "hideBlankCategories", "graphLabelKeys", "graphValueKeys.[]", function () {
      var data = this.get('rebinnedData');
      if (Ember.isEmpty(data)) {
        return [];
      }

      if (this.get('hideBlankCategories')) {
        // Do not show data point if every value dimension is zero
        var valueKeys = this.get('graphValueKeys');
        data = data.filter(function (d) {
          return valueKeys.every(function (key) {
            return d[key] && d[key] !== 0;
          });
        });
      }
      return data;
    }),

    rebinnedData: Ember.computed('parsedData', function () {
      // I assume there will be more than just data binning stuff happening here
      var data = this.get('parsedData');

      if (Ember.isEmpty(data)) {
        return [];
      }
      var labels = this.get('graphLabelKeys');

      if (labels.indexOf('date') >= 0) {
        var binType = this.get('graphDateBinType');
        data = this._binDataByDate(data, binType);
      } else {
        data = this._transformPercTypeData(data);
      }

      return data;
      // TODO(tony) - need to add all possible binning triggers here
      // switch with Api hashes
    }),

    parsedData: Ember.computed('filteredData', 'labelParseMap', function () {
      var _this = this;

      var filteredData = this.get('filteredData');
      if (Ember.isEmpty(filteredData)) {
        return [];
      }

      var labelParseMap = this.get('labelParseMap.toLabel');
      var mapDataPoint = function mapDataPoint(datapoint) {
        return Object.keys(datapoint).reduce(function (mapped, key) {
          var identity = function identity(d) {
            return d;
          };
          var parseFunc = labelParseMap[key] || identity;
          var valueToParse = key === 'profile' ? datapoint : datapoint[key] || 0;

          if (valueToParse === null) {
            _this.get('honeybadger').log("null valueToParse", { key: key, datapoint: datapoint });
          }
          mapped[key] = parseFunc(valueToParse);
          mapped[key + 'Raw'] = valueToParse;
          return mapped;
        }, {});
      };
      return filteredData.map(mapDataPoint);
    }),

    filteredData: Ember.computed("filterList.[]", function () {
      var apiData = this.get('apiData.chart') || [];

      // type can be 'amplification' or 'interaction'
      if (Ember.isPresent(this.get('filterList'))) {
        apiData = this._transformDataForType({
          data: apiData,
          type: 'amplification',
          filterList: this.get('filterList')
        });
        apiData = this._transformDataForType({
          data: apiData,
          type: 'interaction',
          filterList: this.get('filterList')
        });
      }
      return apiData;
    }),

    //Private methods:

    _transformPercTypeData: function _transformPercTypeData(data) {
      var graphValueKeys = this.get('graphValueKeys'),
          graphValuesMap = this.get('graphValues'),
          graphLabelKeys = this.get('graphLabelKeys'),
          labelKey = graphLabelKeys[0];
      if (graphLabelKeys.length <= 1) {
        return data;
      }
      graphValueKeys.forEach(function (valueKey) {
        var valueType = graphValuesMap[valueKey];
        // apply the largest remainder
        // only apply to perc type
        if (valueType !== "perc") {
          return;
        }

        var dataByLabelKey = d3.nest().key(function (d) {
          return d[labelKey];
        }).entries(data);

        dataByLabelKey.forEach(function (d) {
          var percents = d.values.mapBy(valueKey);
          percents = percents.reduce(function (t, d) {
            return t + d;
          }, 0) > 0 ? percents : [];
          if (percents.length > 0) {
            percents = (0, _percentDataPoints.getLargestRemainder)(percents, 100);
            d.values.forEach(function (v, index) {
              v[valueKey] = percents[index];
            });
          }
        });
      });
      return data;
    },


    _transformDataForType: function _transformDataForType(_ref) {
      var data = _ref.data,
          type = _ref.type,
          filterList = _ref.filterList;

      // First see if we can get them from the value dimension because somebody
      // selected it as a value metric directly rather than as a label
      if (data.length === 0 || !data[0][type]) {
        return data;
      }
      // If this data has an amplification key then we transform it
      // Need to copy each point and then flatten the amplification
      // value object into multiple points with different amplification
      // labels

      return data.reduce(function (flatData, nestedPoint) {
        var flatPoints = filterList.map(function (label) {
          var point = Ember.merge({}, nestedPoint);
          point[type + '_label'] = label;
          point[type] = nestedPoint[type][label] || 0;
          return point;
        });
        return flatData.concat(flatPoints);
      }, []);
    },

    _binDataByDate: function _binDataByDate(data, binType) {
      if (Ember.isEmpty(data)) {
        return data;
      }

      var originalFrom = this.get("fromDate");
      var originalTo = this.get("toDate");
      var customYearEndOffset = this.get('customFiscalYearOffset');

      var _getRoundDomainRange = (0, _timeSeriesLabeler.getRoundDomainRange)({
        from: originalFrom,
        to: originalTo,
        binType: binType,
        customYearEndOffset: customYearEndOffset
      }),
          from = _getRoundDomainRange.from,
          to = _getRoundDomainRange.to;

      // from is the beginning of the first bin, to is the end of the last bin


      var filtered = data.filter(function (d) {
        if (d.date && d.date.constructor !== Date) {
          d.date = moment(d.date).toDate();
        }
        return originalFrom <= d.date && d.date <= originalTo;
      });
      // TODO(tony) If you want to filter by rounded dates, switch to this
      // from <= date && date <= to;

      // If we are centering data in the bin then we are moving the points to the middle of the
      // bin interval as that is better for display
      var completeRange = this._completeDateRange({
        data: filtered,
        startDate: from,
        stopDate: to,
        cumulative: false,
        binType: binType
      });

      // Center data and format date labels
      var formatDate = (0, _timeSeriesLabeler.formattedTime)(binType, customYearEndOffset);
      var centeredData = completeRange.map(function (d) {
        var date = (0, _timeSeriesLabeler.advanceMiddle)(d.date, binType, false);
        if (d['amplification_label'] === "Google+" && date > _socialSettings.default.GOOGLEPLUS_END || d['amplification_label'] === "LinkedIn" && date > _socialSettings.default.LINKEDIN_END || d['amplification_label'] === "Pinterest" && date < _socialSettings.default.PINTEREST_START) {
          d.amplification = null;
        }
        date = formatDate(date);
        return Ember.merge(d, { date: date });
      });
      return centeredData;
    },

    // Input:
    //   data: Takes data that is sampled in any order, at any frequency, and sparse
    //   startDate: Start of first bin. No data samples should be before this.
    //   stopDate: End of last bin or beginning of next bin after last bin. All samples
    //     are before this.
    //   cumulative: Whether we cumulate the summation from bin left - right.
    //   binType: 'Day', 'Week', 'Month', etc.
    // Output:
    //   A tick for each bin between start and end
    _completeDateRange: function _completeDateRange(_ref2) {
      var _this2 = this;

      var data = _ref2.data,
          startDate = _ref2.startDate,
          stopDate = _ref2.stopDate,
          cumulative = _ref2.cumulative,
          binType = _ref2.binType;

      if (Ember.isEmpty(data)) {
        return data;
      }
      // Create something to fill empty data points
      var defaultBinData = [];
      var values = this.get('graphValueKeys');
      var defaultValue = 0;
      var defaultValues = values.reduce(function (prev, valueKey) {
        prev[valueKey] = defaultValue;
        return prev;
      }, {});
      var labelKeys = this.get('graphLabelKeys').filter(function (l) {
        return l !== 'date';
      });
      if (labelKeys.length === 0) {
        defaultBinData.push(Ember.merge({}, defaultValues));
      } else {
        // Assume there is only one other dimension than date
        var labelKey = labelKeys[0];
        // get all possible labels for the labelKeys
        var labelValues = data.reduce(function (prev, d) {
          prev[d[labelKey]] = d[labelKey + 'Raw'];
          return prev;
        }, {});
        // Add a data point for each label
        Object.keys(labelValues).forEach(function (label) {
          var labelRaw = labelValues[label],
              dataPoint = Ember.merge({}, defaultValues);
          dataPoint[labelKey] = label;
          dataPoint[labelKey + 'Raw'] = labelRaw;
          defaultBinData.push(dataPoint);
        });
      }

      // get bin right edges for histogram
      var customYearEndOffset = this.get('customFiscalYearOffset');
      var bins = (0, _timeSeriesLabeler.getBinEdges)(binType, [startDate, stopDate], customYearEndOffset);

      // Assume dates already normalized and sort data
      var sortedContent = data.sortBy('date');

      // Rebin data by our bin edges
      var nextValueIndex = 0;
      return bins.reduce(function (completeRange, date) {
        // Each tick date has a value of all data after previous tick
        // and <= this tick. If cumulative, we carry over previous value, if the
        // current value is zero. NOTE: this is not cumulative.

        // Collect all data points before this date
        var binData = [];
        while (nextValueIndex < sortedContent.length && sortedContent[nextValueIndex].date < date) {
          binData.push(sortedContent[nextValueIndex]);
          nextValueIndex++;
        }

        // Accumulate data for this bin and return data point for each label dimension
        var dataToAdd = void 0;
        if (binData.length === 0) {
          dataToAdd = defaultBinData.map(function (d) {
            return Ember.merge({ date: date }, _this2.get('defaultValue')(date, d));
          });
        } else {
          dataToAdd = _this2._accumDateBin(date, binData);
        }
        return completeRange.concat(dataToAdd);
      }, []);
    },

    _percentLeaves: function _percentLeaves(binData, leaves, valueKey) {
      var total = d3.sum(binData, function (leaf) {
        return leaf[valueKey];
      });
      if (!total || total === 0) {
        return 0;
      }
      return d3.sum(leaves, function (leaf) {
        return leaf[valueKey];
      }) / total * 100.0;
    },


    _accumLeaves: function _accumLeaves(valueKey, leaves, dimKey, binData) {
      var type = dimKey;
      var weightKey = valueKey === 'interaction' ? 'visitor' : 'coverage';

      switch (type) {
        case 'perc':
          return this._percentLeaves(binData, leaves, valueKey);
        case 'sum':
        case 'count':
          return d3.sum(leaves, function (leaf) {
            return leaf[valueKey];
          });
        case 'score':
          return d3.sum(leaves, function (leaf) {
            return leaf.score;
          }) / leaves.length;
        case 'avg':
          // Weighted avg
          if (d3.max(leaves.getEach(weightKey)) === 0) {
            return 0;
          }
          return d3.sum(leaves, function (leaf) {
            return leaf[valueKey] * leaf[weightKey];
          }) / d3.sum(leaves, function (leaf) {
            return leaf[weightKey];
          });
        default:
          console.warn('Unknown aggregation type', valueKey, type);
          return d3.sum(leaves, function (leaf) {
            return leaf[valueKey];
          });
      }
    },

    // This function takes data points that fall within a date bin and will
    // return new data points for that date bin
    _accumDateBin: function _accumDateBin(date, binData) {
      var _this3 = this;

      var values = this.get('graphValueKeys');
      var graphValuesMap = this.get('graphValues');
      // Remove date because we are summing by that for the bin
      var labelKeys = this.get('graphLabelKeys').filter(function (l) {
        return l !== 'date';
      });

      // Nest data by the label keys in order
      var nestFunc = d3.nest();
      labelKeys.forEach(function (k) {
        return nestFunc = nestFunc.key(function (d) {
          return d[k];
        });
      });

      nestFunc.rollup(function (leaves) {
        var rollup = {};

        values.map(function (value) {
          rollup['raw'] = leaves[0];
          rollup[value] = _this3._accumLeaves(value, leaves, graphValuesMap[value], binData);
        });
        // Also provide a count
        rollup.leafCount = leaves.length;
        return rollup;
      });
      var nestedData = nestFunc.entries(binData);

      values.forEach(function (valueKey) {
        if (graphValuesMap[valueKey] !== "perc") {
          return;
        }
        var percents = nestedData.mapBy('values.' + valueKey);
        percents = percents.reduce(function (t, d) {
          return t + d;
        }, 0) > 0 ? percents : [];
        percents = (0, _percentDataPoints.getLargestRemainder)(percents, 100);

        nestedData.forEach(function (data, index) {
          data["values"][valueKey] = percents[index] || 0;
        });
      });

      // For each leaf, add a data point
      var dfsAddDataPoints = function dfsAddDataPoints(dataPoints, node, prevPath) {
        var path = prevPath.concat([node.key]);
        if (!Ember.isArray(node.values)) {
          // We are at the leaf at a data point for this date
          var dataPoint = {};
          values.forEach(function (value) {
            return dataPoint[value] = node.values[value];
          });
          labelKeys.forEach(function (k, i) {
            if (k !== 'date') {
              var rawKey = k + 'Raw';
              dataPoint[rawKey] = node.values.raw[rawKey];
            }
            dataPoint[k] = path[i];
          });
          dataPoints.push(dataPoint);
        } else {
          // Not at a leaf add the key to the path and continue DFS
          node.values.forEach(function (nextNode) {
            dfsAddDataPoints(dataPoints, nextNode, path);
          });
        }
      };
      // Need to add date back to labelKeys as that is part of the labels, has to be first
      // since it is grouped this way, labelKeys specifies grouping order
      labelKeys = ['date'].concat(labelKeys);
      var binDataPoints = [];
      dfsAddDataPoints(binDataPoints, { key: date, values: nestedData }, []);

      return binDataPoints;
    }

  });
});
define('client/components/profile-chooser', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var ProfileChooserComponent = Ember.Component.extend({
    tagName: "ul",
    classNameBindings: [":profile-chooser", "dropdown:dropdown-menu"],

    searchTerm: "",

    onClick: null,
    content: null,
    profile: Ember.computed.alias("content"),

    dropdown: true,

    parent: null,
    currentProfile: null,

    showCompetitors: true,
    showCampaigns: true,
    switchToProfile: function switchToProfile() {},


    parents: Ember.computed("profile.parents", "parent", function () {
      var parent = this.get("parent");
      if (parent) {
        return [parent];
      }

      return this.get("profile.parents");
    }),

    profileTypes: Ember.computed("profile.profileTypes", "showCampaigns", "showCompetitors", function () {
      var types = [],
          pTypes = this.get("profile.profileTypes");
      if (!this.get("showCompetitors")) {
        types.push("competitors");
      }
      if (!this.get("showCampaigns")) {
        types.push("campaigns");
      }
      return pTypes ? pTypes.filter(function (type) {
        return types.indexOf(type.company_name.toLowerCase()) === -1;
      }) : [];
    }),

    profileType: null,

    searchProfiles: null,
    searchItemSizes: Ember.computed('searchProfiles', function () {
      return this.get('searchProfiles').map(function (profile) {
        return {
          width: 260,
          height: profile.get('parent') ? 67 : 43
        };
      });
    }),

    hasCorporates: Ember.computed.gt("corporates.length", 1),
    showCorporate: false,
    allowEmpty: false,

    click: function click(e) {
      e.preventDefault();
      e.stopImmediatePropagation();
    },

    flatProfiles: Ember.computed("profile", "parents", "corporates", "showCompetitors", "showCampaigns", function () {
      var topProfiles = this.get("corporates"),
          firstParent = this.get("parents.firstObject"),
          checkCompetitors = this.get("showCompetitors"),
          checkCampaigns = this.get("showCampaigns");

      if (!topProfiles && firstParent) {
        topProfiles = [firstParent];
      } else if (!topProfiles) {
        topProfiles = [this.get("profile")];
      }
      var flat = Ember.A(),
          flatten = function flatten(p) {
        if (p.get('profile_id') && !flat.filter(function (z) {
          return z.get('isCampaign');
        }).findBy("id", p.get("id"))) {
          flat.pushObject(p);
        } else if (!flat.filter(function (z) {
          return !z.get('isCampaign');
        }).findBy("id", p.get("id"))) {
          flat.pushObject(p);
        }

        p.get("children").forEach(function (child) {
          if (child.get("isCompetitor") && !checkCompetitors) {
            // nothing
          } else if (child.get("isCampaign") && !checkCampaigns) {
            // nothing
          } else {
            flatten(child);
          }
        });
      };

      topProfiles.forEach(function (tp) {
        flatten(tp);
      });

      var ret = Ember.A();
      var uniqIds = {};
      var uniqCampaignIds = {};
      flat.forEach(function (k) {
        //campaign
        if (k.get('profile_id') && !uniqCampaignIds[k.get('id')]) {
          uniqCampaignIds[k.get('id')] = k;
        } else if (!uniqIds[k.get('id')]) {
          uniqIds[k.get('id')] = k;
        }
      });

      Object.keys(uniqCampaignIds).forEach(function (key) {
        ret.pushObject(uniqCampaignIds[key]);
      });
      Object.keys(uniqIds).forEach(function (key) {
        ret.pushObject(uniqIds[key]);
      });
      return ret.sortBy("company_name");
    }),

    search: Ember.observer("searchTerm", function () {
      var flat = this.get("flatProfiles"),
          term = this.get("searchTerm");

      if (term == null || term.length === 0) {
        this.set("searchProfiles", null);
        return;
      }

      flat = flat.filter(function (p) {
        var name = p.get("company_name");
        return name && name.toLowerCase().indexOf(term.toLowerCase()) >= 0;
      }).sortBy("company_name");

      this.set("showCorporate", false);
      this.set("searchProfiles", flat);
    }),

    actions: {
      toggleCorporate: function toggleCorporate() {
        this.set("searchTerm", null);
        this.toggleProperty("showCorporate");
      },

      stepInto: function stepInto(profile) {
        this.set("profileType", profile);
        this.set("searchTerm", null);
        this.set("showCorporate", false);
      },

      stepTo: function stepTo(profile, close) {
        if (this.get("allowEmpty") && profile === this.get("profile")) {
          profile = null;
          this.set("showCorporate", true);
        } else {
          this.set("showCorporate", false);
        }

        this.switchToProfile(profile, this.get("context"));
        this.set("profileType", null);
        this.set("searchTerm", null);

        if (close) {
          this.$().parents(".dropdown").removeClass("open");
        }
      }
    }

  });

  exports.default = ProfileChooserComponent;
});
define('client/components/reports/rich-text-header-subheader', ['exports', 'client/components/reports/rich-text', 'client/mixins/rich-text-bold-default'], function (exports, _richText, _richTextBoldDefault) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _richText.default.extend(_richTextBoldDefault.default, {
    layoutName: 'components/reports/rich-text',
    selectedFontSize: '50px',
    placeholder: '\n    <div style="font-family: Helvetica, Arial; font-size: 50px; font-weight: bold;">\n      HEADER\n    </div>\n    <div style="height: 6px;">\n    </div>\n    <div style="font-family: Helvetica, Arial; font-size: 24px; font-weight: bold;">\n      SUBHEADER\n    </div>',
    text: Ember.computed.alias("item.text"),

    hasText: Ember.computed('text', function () {
      var text = Ember.$(Ember.$.parseHTML(this.get('text'))).text();
      return Ember.isPresent(text);
    })
  });
});
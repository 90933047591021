define('client/controllers/settings/custom-colors/index', ['exports', 'client/report-settings'], function (exports, _reportSettings) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    ajax: Ember.inject.service(),
    profile: Ember.computed.alias("profileService.profile"),
    settings: Ember.computed.alias("profile.settings"),
    competitorColors: Ember.computed.alias("profile.competitorColors"),
    stableCompetitorColors: Ember.computed.alias("profile.stableCompetitorColors"),

    colors: Ember.computed("profile.customColors.@each.hex", function () {
      return this.get("profile.customColors").map(function (c, i) {
        Ember.set(c, "brand", _reportSettings.DEFAULT_COLOR_SWATCHES[i] !== c.hex); // eslint-disable-line
        return c;
      });
    }),
    colorHex: Ember.computed.oneWay('color.hex'),

    currentlyEditingColor: null,

    useCustom: Ember.computed.alias("profile.useCustomColors"),

    actions: {
      useCustom: function useCustom(state) {
        var _this = this;

        this.set('useCustom', state);
        this.get('ajax').post('/v2/api/profiles/' + this.get('profile.id') + '/set_custom_report_colors', {
          data: {
            state: state
          }
        }).catch(function () {
          Ember.run.bind(_this, function () {
            return _this.set('useCustom', !state);
          });
        });
      },
      saveColors: function saveColors(color, hex) {
        Ember.set(color, "hex", '#' + hex);
        this.get('ajax').put('/v2/api/profiles/' + this.get('profile.id') + '/report_colors/' + this.get('profile.id'), {
          data: {
            colors: this.colors
          }
        });
      },
      updateCompetitorColor: function updateCompetitorColor(competitorId, competitorColor, colorHex) {
        if (competitorColor) {
          competitorColor.set("color", colorHex);
        } else {
          var profile = this.get("profile");
          competitorColor = this.get("store").createRecord("profile-competitor-color", {
            profileId: profile.get("id"),
            competitorId: competitorId,
            color: colorHex
          });
          profile.get("competitorColors").addObject(competitorColor);
        }
        competitorColor.save();
      }
    }
  });
});
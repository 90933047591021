define('client/mixins/adapters/has-endpoint-actions', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    mergedProperties: ['endpointActions'],

    dispatchAction: function dispatchAction(actionName, model) {
      var queryParams = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};

      var action = this.endpointActions[actionName];
      if (!action) {
        return Ember.RSVP.resolve();
      }
      var store = Ember.getOwner(this).lookup('service:store'),
          type = model.constructor.modelName,
          id = Ember.get(model, 'id');

      return action.call(this, store, type, id, model, queryParams);
    },
    serializeQueryParams: function serializeQueryParams(hash) {
      return Ember.$.param(hash);
    },
    serializeActionRequest: function serializeActionRequest(actionName, model, options) {
      var store = Ember.getOwner(this).lookup('service:store');
      var serializer = store.serializerFor(model.constructor.modelName);
      return serializer.serializeAction(actionName, model, options);
    },
    normalizeActionResponse: function normalizeActionResponse(actionName, model, data) {
      var store = Ember.getOwner(this).lookup('service:store');
      var serializer = store.serializerFor(model.constructor.modelName);
      return serializer.normalizeAction(actionName, model.constructor, data);
    },


    endpointActions: {}
  });
});
define('client/adapters/application', ['exports', 'client/not-store', 'active-model-adapter'], function (exports, _notStore, _activeModelAdapter) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  //
  // The base adapter for all Ember data models
  //
  var ApplicationAdapter = _activeModelAdapter.default.extend({
    session: Ember.inject.service(),

    // always append this to the ajax url
    namespace: '/v3/api',

    headers: Ember.computed("session.isDebugES", function () {
      return this.session.get("isDebugES") ? { "is-debug-es": "true" } : {};
    }),

    //
    // Each model can define its own ajax path
    //
    pathForType: function pathForType(type) {
      if (this.store.modelFor(type).pathForType) {
        return this.store.modelFor(type).pathForType;
      } else {
        return this._super.apply(this, arguments);
      }
    },

    //
    // Prepare the url for nested resources based on the
    // nested resrouces ids object.
    //
    checkUrl: function checkUrl(url, snapshot, data) {
      // Must check serialized form AND record, because some things (see added_links)
      // add properties to record that aren't actually serialized, any paths we find
      // in either will be added to the paramsCache so that we don't double add
      var paramsCache = {};
      function findAnyIDParam(theData) {
        _notStore.default.forEach(function (res) {
          if (!paramsCache[res.param]) {
            var id = Ember.get(theData, res.path);
            if (id) {
              paramsCache[res.param] = id;
            }
          }
        });
      }

      if (data) {
        // First, assume data is one level deep
        var key = Object.keys(data)[0];
        if (key && data[key]) {
          findAnyIDParam(data[key]);
        }
      }
      var record = snapshot.record;
      if (record) {
        findAnyIDParam(record);
      }

      // paramsCache has all params with IDs to append to the URL
      var sep = "?";
      Object.keys(paramsCache).forEach(function (param) {
        url = url + sep + param + "=" + paramsCache[param];
        sep = "&";
      });

      return url;
    },

    findRecord: function findRecord(store, type, id, snapshot) {
      if (snapshot.record.get('useOriginalAdapter')) {
        return this._super.apply(this, arguments);
      } else {
        Ember.deprecate("AirPR custom findRecord is deprecated", false, { id: "airpr.data.findRecord", until: "2.13" });

        var url = this.buildURL(type.modelName, id, snapshot);
        url = this.checkUrl(url, snapshot);

        return this.ajax(url, 'GET');
      }
    },

    //
    // Find query does not understand nested resources,
    // so if an id is found in the query, first build a get
    // url from it and then append the query params.
    //
    query: function query(store, type, _query) {
      if (type.useOriginalAdapter) {
        return this._super.apply(this, arguments);
      } else {
        Ember.deprecate("AirPR custom query is deprecated", false, { id: "airpr.data.query", until: "2.13" });
        var url,
            id = _query["id"];
        if (id) {
          url = this.buildURL(type.modelName, id);
          _query["id"] = null;
          delete _query["id"];
        } else {
          url = this.buildURL(type.modelName);
        }
        return this.ajax(url, 'GET', { data: _query });
      }
    },
    //
    // Create a record with nested resource support
    //
    createRecord: function createRecord(store, type, snapshot) {
      if (snapshot.record.get('useOriginalAdapter')) {
        return this._super.apply(this, arguments);
      } else {

        Ember.deprecate("AirPR custom createRecord is deprecated", false, { id: "airpr.data.createRecord", until: "2.13" });

        var data = {},
            serializer = store.serializerFor(type.modelName),
            url = this.buildURL(type.modelName, null, snapshot);
        serializer.serializeIntoHash(data, type, snapshot, { includeId: true });
        url = this.checkUrl(url, snapshot, data);
        return this.ajax(url, "POST", { data: data });
      }
    },
    //
    // Update a record with nested resource support
    //
    updateRecord: function updateRecord(store, type, snapshot) {
      if (snapshot.record.get('useOriginalAdapter')) {
        return this._super.apply(this, arguments);
      } else {
        Ember.deprecate("AirPR custom updateRecord is deprecated", false, { id: "airpr.data.updateRecord", until: "2.13" });

        var data = {},
            serializer = store.serializerFor(type.modelName),
            id = snapshot.id,
            url = this.buildURL(type.modelName, id, snapshot);
        serializer.serializeIntoHash(data, type, snapshot);
        url = this.checkUrl(url, snapshot, data);
        return this.ajax(url, "PUT", { data: data });
      }
    },
    //
    // Delete a record with nested resource support
    //
    deleteRecord: function deleteRecord(store, type, snapshot) {
      if (snapshot.record.get('useOriginalAdapter')) {
        return this._super.apply(this, arguments);
      } else {

        Ember.deprecate("AirPR custom deleteRecord is deprecated", false, { id: "airpr.data.deleteRecord", until: "2.13" });

        var id = snapshot.id,
            url = this.buildURL(type.modelName, id);
        url = this.checkUrl(url, snapshot);
        return this.ajax(url, "DELETE");
      }
    },
    ajaxOptions: function ajaxOptions(url, type, hash) {
      hash = this._super(url, type, hash);
      hash.contentType = 'application/json; charset=UTF-8';
      return hash;
    }

  });

  exports.default = ApplicationAdapter;
});
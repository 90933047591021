define('client/mixins/content-filter', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var FILTER_DEFAULTS = {
    news: true,
    linkedin_pulse: true,
    twitter: false,
    gplus: false,
    radio: true,
    television: true,
    print_publication: true,
    podcast: true,
    youtube_bc: true
  };

  var ContentFilterMixin = Ember.Mixin.create({
    //override this downstream if the filters are saved in a different way
    // eg. email-digest-view.js
    savedFilters: Ember.computed.alias('_savedFilterSelection'),

    newsDefault: Ember.computed('savedFilters.filters.news', function () {
      return this._getContentFilterDefault('news');
    }),

    linkedinPulseDefault: Ember.computed('savedFilters.filters.linkedin_pulse', function () {
      return this._getContentFilterDefault('linkedin_pulse');
    }),

    twitterDefault: Ember.computed('savedFilters.filters.twitter', function () {
      return this._getContentFilterDefault('twitter');
    }),

    gplusDefault: Ember.computed('savedFilters.filters.gplus', function () {
      return this._getContentFilterDefault('gplus');
    }),

    radioDefault: Ember.computed('savedFilters.filters.radio', function () {
      return this._getContentFilterDefault('radio');
    }),

    televisionDefault: Ember.computed('savedFilters.filters.television', function () {
      return this._getContentFilterDefault('television');
    }),

    podcastDefault: Ember.computed('savedFilters.filters.podcast', function () {
      return this._getContentFilterDefault('podcast');
    }),

    youtubeBcDefault: Ember.computed('savedFilters.filters.youtube_bc', function () {
      return this._getContentFilterDefault('youtube_bc');
    }),

    printPublicationDefault: Ember.computed('savedFilters.filters.print_publication', function () {
      return this._getContentFilterDefault('print_publication');
    }),

    contentFiltersAreDefault: Ember.computed('savedFilters', 'news', 'linkedin_pulse', 'twitter', 'gplus', 'radio', 'television', 'podcast', 'youtube_bc', 'printPublication', function () {
      return this.get('news') === this.get('newsDefault') && this.get('linkedin_pulse') === this.get('linkedinPulseDefault') && this.get('twitter') === this.get('twitterDefault') && this.get('gplus') === this.get('gplusDefault') && this.get('radio') === this.get('radioDefault') && this.get('television') === this.get('televisionDefault') && this.get('podcast') === this.get('podcastDefault') && this.get('youtube_bc') === this.get('youtubeBcDefault') && this.get('printPublication') === this.get('printPublicationDefault');
    }),

    setFiltersFromSavedSelection: function setFiltersFromSavedSelection() {
      if (this.get('savedFilters')) {
        this._setContentFilters();
      }
      this._super.apply(this, arguments);
    },

    resetFilters: function resetFilters() {
      this._setContentFilters();
      this._super.apply(this, arguments);
    },

    _setContentFilters: function _setContentFilters() {
      this.setProperties({
        news: this.get('newsDefault'),
        linkedin_pulse: this.get('linkedinPulseDefault'),
        twitter: this.get('twitterDefault'),
        gplus: this.get('gplusDefault'),
        radio: this.get('radioDefault'),
        television: this.get('televisionDefault'),
        podcast: this.get('podcastDefault'),
        youtube_bc: this.get('youtubeBcDefault'),
        printPublication: this.get('printPublicationDefault')
      });
    },

    _getContentFilterDefault: function _getContentFilterDefault(filterName) {
      var savedFilterValue = this.get('savedFilters.filters.' + filterName);
      return Ember.isPresent(savedFilterValue) ? savedFilterValue : FILTER_DEFAULTS[filterName];
    },

    actions: {

      toggleNews: function toggleNews() {
        this.toggleProperty('news');
      },

      toggleLinkedInPulse: function toggleLinkedInPulse() {
        this.toggleProperty('linkedin_pulse');
      },

      toggleTwitter: function toggleTwitter() {
        this.toggleProperty('twitter');
      },

      toggleGplus: function toggleGplus() {
        this.toggleProperty('gplus');
      },

      toggleTelevision: function toggleTelevision() {
        this.toggleProperty('television');
      },

      toggleRadio: function toggleRadio() {
        this.toggleProperty('radio');
      },

      togglePodcast: function togglePodcast() {
        this.toggleProperty('podcast');
      },

      toggleYoutubeBc: function toggleYoutubeBc() {
        this.toggleProperty('youtube_bc');
      },

      togglePrintPublication: function togglePrintPublication() {
        this.toggleProperty('printPublication');
      }
    }
  });

  exports.default = ContentFilterMixin;
});
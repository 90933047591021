define('client/components/reach-widget', ['exports', 'client/components/simple-widget'], function (exports, _simpleWidget) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var text = 'Reach signals the size of a potential\naudience who could have been exposed to an article,\nnews release, etc. in a specific digital environment.\nIt does not guarantee that an individual actually\nconsumed content.';

  var ReachWidgetComponent = _simpleWidget.default.extend({

    title: Ember.computed.alias("profile.nameReach"),

    action: "toContextReach",

    iconClass: "db-widget-icon-reach",

    text: text,

    value: Ember.computed("reach", "desktop", "profile.showReachSplit", function () {
      if (this.get("profile.showReachSplit")) {
        return this.get("reach");
      }
      return this.get("desktop");
    }),

    reach: Ember.computed.alias("content.reach"),

    desktop: Ember.computed.alias("content.desktop_reach"),

    mobile: Ember.computed.alias("content.mobile_reach")

  });

  exports.default = ReachWidgetComponent;
});
define('client/components/email-newsletter-icon', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: 'span',
    classNames: ['email-newsleter-icon'],
    classNameBindings: ['articlesPresent:has-articles'],

    onClick: function onClick() {},


    articleCount: 0,
    articlesPresent: Ember.computed.gt('articleCount', 0),

    click: function click() {
      if (this.get('articlesPresent')) {
        this.onClick();
      }
    }
  });
});
define("client/components/analyze/messaging/soundbyte-header", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ["soundbyte-item", "row", "m-0", "no-gutter"]

  });
});
define('client/components/analyze/page-title', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var ROUTE_TITLE_MAP = [{ route: '/analyze/coverage/index', title: 'Coverage' }, { route: '/analyze/coverage/chart/pov', title: 'Power Of Voice' }, { route: '/analyze/coverage/chart/sov', title: 'Share Of Voice' }, { route: '/analyze/messaging', title: 'NEO Messaging' }];

  exports.default = Ember.Component.extend({
    router: Ember.inject.service(),

    routeTitle: Ember.computed('router.currentURL', function () {
      //Remove query params from url
      var route = this.get('router.currentURL').split('?')[0];
      var mappedTitle = ROUTE_TITLE_MAP.findBy('route', route);
      return mappedTitle ? mappedTitle.title : 'Coverage';
    }),

    onClickSF: function onClickSF() {},

    actions: {
      clickSF: function clickSF() {
        this.get('onClickSF')();
      }
    }
  });
});
define("client/components/insights/insight-question", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNameBindings: [":insight-question", "active"],

    onSelect: function onSelect() {},
    click: function click() {
      this.get("onSelect")();
    }
  });
});
define('client/components/analyze/article-tabs', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ["article-tabs"],

    selectedTab: null,

    tabs: Ember.computed(function () {
      return [];
    }),

    addTab: function addTab(tab) {
      var tabs = this.get("tabs");
      tabs.pushObject(tab);
    },
    removeTab: function removeTab(tab) {
      var tabs = this.get("tabs"),
          selectedTab = this.get("selectedTab");
      tabs.removeObject(tab);

      Ember.run.later(this, function () {
        if (selectedTab === tab && !this.get('isDestroyed')) {
          this.set("selectedTab", this.get("tabs.firstObject"));
        }
      });
    },


    actions: {
      selectTab: function selectTab(tab) {
        this.set("selectedTab", tab);
        this.set('currentSection', tab.get('sectionName'));
      }
    }
  });
});
define('client/components/research/author-note-form', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ["note-edit"],

    note: null,

    isUpdating: false,
    isDeleting: false,

    editMode: Ember.computed.bool("note.id"),

    onDeleteNote: function onDeleteNote() {},
    onSaveNote: function onSaveNote() {},
    onCancel: function onCancel() {},


    modal: Ember.inject.service(),

    actions: {
      deleteNote: function deleteNote() {
        var _this = this;

        this.get('modal').open('common/ui-confirm', {
          question: 'Are you sure you want to delete this note?',
          ok: "Delete",
          okType: "danger"
        }).then(function () {
          _this.get("onDeleteNote")();
        });
      }
    }

  });
});
define('client/components/research/article-graph', ['exports', 'client/mixins/report-chart-color-ordered', 'client/utils/chart-data-transformer', 'moment'], function (exports, _reportChartColorOrdered, _chartDataTransformer, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var monthsBack = 6;

  exports.default = Ember.Component.extend(_reportChartColorOrdered.default, {
    classNames: ["trends-graph"],

    dataTransformer: Ember.computed('graphValues', 'defaultValue', 'parsedData', 'fromDate', 'toDate', function () {
      return _chartDataTransformer.default.create({

        graphValues: this.get('graphValues'),
        fromDate: this.get('fromDate'),
        toDate: this.get('toDate'),
        graphDateBinType: "Day",
        graphLabelKeys: ["date"],
        parsedData: this.get('parsedData')
      });
    }),

    graphValues: Ember.computed(function () {
      return {
        fbTotal: "count",
        pinterestPins: "count",
        googlePlusones: "count",
        twitterCount: "count",
        docCount: "count"
      };
    }),

    legend: null,
    valueFormatType: "count",
    minColumnWidth: 4,

    fromDate: null,
    toDate: null,

    rebinnedData: Ember.computed.alias('dataTransformer.rebinnedData'),
    data: Ember.computed("rebinnedData", function () {
      var counts = this.get("rebinnedData"),
          colorPalette = this.get('colorPalette');

      if (Ember.isEmpty(counts)) {
        return [];
      }

      return counts.map(function (c) {
        return [{
          labels: [c.date, "Articles"],
          values: [c.docCount],
          color: colorPalette[0]
        }];
      }).flatten();
    }),

    init: function init() {
      this._super.apply(this, arguments);
      this.set('legend', this.get('legend') || { "Facebook": 0, "Pinterest": 1, "Articles": 2 });
      this.set('fromDate', this.get('fromDate') || (0, _moment.default)().subtract(monthsBack, "months").startOf("month").subtract(1, "day").toDate());
      this.set('toDate', this.get('toDate') || new Date());
    }
  });
});
define('client/components/common/ui-page-modal', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['ui-page-modal'],
    onClose: function onClose() {}
  });
});
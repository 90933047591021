define('client/components/amplification-explanation', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Component.extend({
		profileService: Ember.inject.service('profile'),
		profile: Ember.computed.alias('profileService.profile')
	});
});
define('client/helpers/top-iptc-topics-payload', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.topIptcTopicsPayload = topIptcTopicsPayload;

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
        arr2[i] = arr[i];
      }

      return arr2;
    } else {
      return Array.from(arr);
    }
  }

  function topIptcTopicsPayload(filterSet) {
    var filters = {
      media_earned: filterSet.earned,
      media_owned: filterSet.owned,
      press_release: filterSet.pressRelease,
      news: true, //q
      twitter: filterSet.twitter,
      gplus: filterSet.googlePlus,
      television: filterSet.television,
      radio: filterSet.radio,
      podcast: filterSet.podcast,
      youtube_bc: filterSet.youtube_bc,
      print_publication: filterSet.printPublication,
      influential: filterSet.influential,
      tone_positive: filterSet.positive,
      tone_negative: filterSet.negative,
      tone_neutral: filterSet.neutral,
      searchTerm: filterSet.searchTerm,
      linkedin: true, //q
      locations: filterSet.locations,
      uninfluential: filterSet.uninfluential,
      syndicated: filterSet.syndicated,
      follow_on: filterSet.followOn,
      exclude_syndicate_articles: filterSet.excludeSyndicateArticles,
      relevance: filterSet.relevance,
      company_website_link: filterSet.companyWebsiteLink,
      article_links_contain: filterSet.articleLinksContain,
      whitelist_tag_ids: filterSet.whitelistTagIds,
      included_iptc_topics: filterSet.iptcTopicIds,
      languages: filterSet.languages
    };

    var searchCriteria = {
      article_search: {
        record_count: null,
        omit_interaction_padding: false,
        total_hits: null,
        syndicate_hits: null,
        in_crawler: true,
        outside_search_criteria: false,
        unique_article_ids: false,
        time_zone: null,
        message_id: null,
        profile_id: filterSet.profile_id,
        campaign_id: filterSet.campaign_id,
        from: moment(filterSet.fromDate).startOf('day').toDate(),
        to: moment(filterSet.toDate).endOf('day').toDate(),
        sort: "date",
        relevance: false,
        use_interaction_count: false,
        filters: filters,
        search_term: null,
        search_type: null,
        full_text: false,
        articles: []
      }
    };

    filterSet.tagIds.forEach(function (tagId) {
      var tagKey = 'tag_' + tagId;
      filters[tagKey] = true;
    });

    return searchCriteria;
  }

  exports.default = Ember.Helper.helper(function (params) {
    return topIptcTopicsPayload.apply(undefined, _toConsumableArray(params));
  });
});
define('client/components/reports/tile-sentiment', ['exports', 'client/components/reports/base-widget', 'client/components/reports/tile-base'], function (exports, _baseWidget, _tileBase) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _tileBase.default.extend({
    classNameBindings: [":report-tile-sentiment"],
    isToneTile: true,

    twitterContentFilterOnly: Ember.computed('profile', 'profile.hasArticleSentiment', function () {
      return !this.get('profile.hasArticleSentiment');
    })
  });
});
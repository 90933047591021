define('client/helpers/format-percent', ['exports', 'client/computed/index', 'client/helpers/format-number-with-default'], function (exports, _index, _formatNumberWithDefault) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.formatPercent = formatPercent;

  function _toArray(arr) {
    return Array.isArray(arr) ? arr : Array.from(arr);
  }

  function formatPercent(_ref, _ref2) {
    var _ref3 = _toArray(_ref),
        percentage = _ref3[0],
        rest = _ref3.slice(1);

    var _ref2$defaultValue = _ref2.defaultValue,
        defaultValue = _ref2$defaultValue === undefined ? '-' : _ref2$defaultValue,
        _ref2$abs = _ref2.abs,
        abs = _ref2$abs === undefined ? false : _ref2$abs;


    if (Ember.isNone(percentage) || Number.isNaN(percentage) || isNaN(percentage)) {
      return defaultValue;
    }

    if (abs) {
      percentage = Math.abs(percentage);
    }

    if (percentage < 10 && percentage > -10) {
      return Ember.String.htmlSafe(_index.d3Format.percentage(percentage).replace("%", "<sup>%</sup>"));
    } else {
      percentage = (0, _formatNumberWithDefault.formatNumberWithDefault)([percentage * 100], { format: '.2s' });
      return Ember.String.htmlSafe(percentage + '<sup>%</sup>');
    }
  }

  exports.default = Ember.Helper.helper(formatPercent);
});
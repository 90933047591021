define("client/components/profile-chooser-parent", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var ProfileChooserParentComponent = Ember.Component.extend({
    tagName: "li",
    classNameBindings: [":profile-chooser-parent"],
    content: null,
    stepTo: function stepTo() {},


    click: function click(e) {
      e.preventDefault();
      e.stopImmediatePropagation();
      this.stepTo(this.get("content.profile") || this.get("content"), false);
    }
  });

  exports.default = ProfileChooserParentComponent;
});
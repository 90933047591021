define('client/components/common/ui-pill-selector', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['ui-pill-selector'],
    selectedValue: null,
    selectedValueProperty: null,
    displayValueProperty: null,
    items: null,

    // actions
    selectItem: function selectItem() {},
    init: function init() {
      this._super.apply(this, arguments);

      if (!this.get('items')) {
        this.set('items', []);
      }
      if (!this.get('selectedValueProperty')) {
        //Use id as the default selectedvalue property to compare for active
        this.set('selectedValueProperty', 'id');
      }
      if (!this.get('displayValueProperty')) {
        //Use name as the default disply value property
        this.set('displayValueProperty', 'name');
      }
    }
  });
});
define('client/components/expanded-article-metrics-list', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var ExpandedArticleMetricsListComponent = Ember.Component.extend({
    classNames: ["metrics-list"],

    // Expand/collapse state of metric details
    isDetailsShowing: false,

    numberOfMetrics: Ember.computed("profile.showRevenue", "profile.showReach", "profile.showFormulaicRevenue", "profile.showCustomer", "profile.showReadership", "profile.showInteraction", "fullArticle.showReadership", function () {
      var count = [this.get("profile.showRevenue"), this.get("profile.showReach"), this.get("profile.showFormulaicRevenue"), this.get("profile.showCustomer"), this.get("profile.showReadership") && this.get("fullArticle.showReadership"), this.get("profile.showInteraction")].filter(function (t) {
        return t;
      }).length;
      return Math.min(count, 4);
    }),

    // for customers who did not purchase analytics product
    showIntegrationMessage: Ember.computed("profile.showRevenue", "profile.showFormulaicRevenue", "profile.showCustomer", "profile.showReadership", "profile.showInteraction", function () {
      return !(this.get("profile.showRevenue") || this.get("profile.showReach") || this.get("profile.showFormulaicRevenue") || this.get("profile.showCustomer") || this.get("profile.showReadership") || this.get("profile.showInteraction"));
    })

  });

  exports.default = ExpandedArticleMetricsListComponent;
});
define('client/mixins/models/broadcast', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    isRadio: Ember.computed.equal('type', 'radio'),
    isTelevision: Ember.computed.equal('type', 'television'),
    isPodcast: Ember.computed.equal('type', 'podcast'),
    isYoutubeBc: Ember.computed.equal('type', 'youtube_bc'),
    isYoutubeOrPodcast: Ember.computed.or('isPodcast', 'isYoutubeBc'),
    isBroadcast: Ember.computed.or('isRadio', 'isTelevision', 'isPodcast', 'isYoutubeBc'),

    isOlderThan30Days: Ember.computed('date', function () {
      return moment().subtract(30, 'days') > this.get('date');
    }),

    isOlderThan60Days: Ember.computed('date', function () {
      return moment().subtract(60, 'days') > this.get('date');
    })

  });
});
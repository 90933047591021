define('client/components/common/ui-modal', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['ui-modal'],
    classNameBindings: ['visible::ui-modal-hide'],
    visible: false,
    onDone: function onDone() {},
    didRender: function didRender() {
      this._super.apply(this, arguments);
      if (this.get('visible')) {
        Ember.$('.app-body.ember-application').addClass('modal-open');
      }
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);
      Ember.$('.app-body.ember-application').removeClass('modal-open');
    },


    actions: {
      close: function close() {
        this.set('visible', false);
        Ember.$('.app-body.ember-application').removeClass('modal-open');
        // Call done hook
        this.onDone();
      }
    }
  });
});
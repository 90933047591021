define('client/controllers/reports/index', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({

    application: Ember.inject.controller(),

    queryParams: ['filter', 'page', 'search'],

    //TODO: use Ember.computed.alias at some point, `XBinding` is deprecated
    //https://github.com/mharris717/ember-cli-pagination/issues/146
    page: Ember.computed.oneWay('model.page'),
    totalPages: Ember.computed.oneWay('model.totalPages'),
    selectedPage: Ember.computed.oneWay('model.page'),
    filter: 'all',
    search: '',
    modal: Ember.inject.service(),

    searchText: '',
    isLoading: false,
    initialRender: true,
    userFilterActive: Ember.computed.equal('filter', 'user'),
    filteredContent: [],

    totalRecords: Ember.computed.oneWay('model.meta.count'),
    fromNumber: Ember.computed.oneWay('model.meta.first'),
    toNumber: Ember.computed.oneWay('model.meta.last'),

    justDeleted: false,
    checkPage: Ember.observer('page', 'totalPages', 'justDeleted', function () {
      var totalPages = +this.get('totalPages');
      var page = +this.get('page');
      if (page > totalPages) {
        if (totalPages === 0) {
          totalPages += 1;
        }
        if (this.get('justDeleted')) {
          this.set('justDeleted', false);
          this.transitionToRoute({ queryParams: { page: totalPages } });
        } else {
          this.transitionToRoute({ queryParams: { page: 1 } });
        }
      }
    }),

    searchTextDebouncer: Ember.observer('search', function () {
      this.set('searchText', this.get('search'));
    }),

    defaultProfile: Ember.computed('currentUser.defaultProfile', 'currentUser.corporates', function () {
      var profile = this.get('currentUser.defaultProfile'),
          corporates = this.get('currentUser.corporates');
      if (profile && profile.get('id')) {
        return profile;
      }
      return corporates.get('firstObject');
    }),

    actions: {
      search: function search(term) {
        this.set("search", term);
      },
      switchReportProfile: function switchReportProfile(report, profile) {
        var parent = report.get('profile');
        report.setProperties({
          profile_id: profile.get("profile_id") || profile.get("id"),
          campaign_id: profile.get("campaign_id"),
          startDate: profile.get("startDate"),
          cover_page_image: undefined
        });
      },
      showUserReports: function showUserReports() {
        this.transitionToRoute({ queryParams: {
            filter: 'user',
            search: (this.get("searchText") || "").trim(),
            page: 1
          } });
      },
      showAllReports: function showAllReports() {
        this.transitionToRoute({ queryParams: {
            filter: 'all',
            search: (this.get("searchText") || "").trim(),
            page: 1
          } });
      },
      createReport: function createReport() {
        this.transitionToRoute('reports.new');
      },
      openReport: function openReport(report) {
        //we need to pass the id so that ember fires the model hook,
        //so we can re-fetch it and lock it
        this.transitionToRoute('reports.show', report.get('id'));
      },
      destroyReport: function destroyReport(report) {
        var _this = this;

        report.destroyRecord().then(function () {
          _this.set('justDeleted', true);
          _this.send('refreshReports');
        }).catch(function (errors) {
          var error = errors.errors[0].detail;
          if (error) {
            _this.get('modal').open('common/ui-confirm', {
              prompt: error,
              messages: '',
              showCancel: false,
              ok: "OK",
              okType: "info"
            });
          }
        });
      },
      togglePrivacy: function togglePrivacy(report) {
        var createdById = report.get('createdById');
        if (+this.get('currentUser.id') !== createdById) {
          this.get('modal').open('common/ui-confirm', {
            prompt: 'Report privacy level can be toggled only by its creator.',
            showCancel: false,
            ok: "OK",
            okType: "info"
          });
          return;
        }

        if (report.get('isPrivate')) {
          report.set('user_id', null);
        } else {
          report.set('user_id', createdById);
        }
        report.save();
      }
    }
  });
});
define("client/utils/reports/generate-id", ["exports", "ember-uuid"], function (exports, _emberUuid) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  exports.default = function (id) {
    return "r" + id + "-" + (0, _emberUuid.v4)();
  };
});
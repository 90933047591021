define('client/components/reports/edit-panel', ['exports', 'client/utils/date-format', 'client/models/campaign'], function (exports, _dateFormat2, _campaign) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
        arr2[i] = arr[i];
      }

      return arr2;
    } else {
      return Array.from(arr);
    }
  }

  exports.default = Ember.Component.extend({
    classNameBindings: [":report-edit", "isEditing::hide"],
    store: Ember.inject.service(),
    isEditing: true,
    showAirPrData: true,
    canAddProfile: false,

    profile: null,
    fromDate: null,
    toDate: null,
    socialRange: null,
    articleRange: null,
    searchTerm: null,
    otherProfiles: null,
    display: function display() {},
    save: function save() {},
    addProfile: function addProfile() {},


    fromDateFormatted: Ember.computed("fromDate", function () {
      return (0, _dateFormat2.default)(this.get('fromDate'));
    }),
    toDateFormatted: Ember.computed("toDate", function () {
      return (0, _dateFormat2.default)(this.get('toDate'));
    }),

    getAllStartDates: function getAllStartDates() {
      var _this = this;

      var profile = this.get('profile');
      if (!profile) {
        return [];
      }
      var otherProfiles = this.get('otherProfiles') || [];
      var otherP = otherProfiles.map(function (pc) {
        return _this.get('store').peekRecord('profile', pc.get('profileId'));
      });
      return [profile, otherP].flatten().compact().filter(function (p) {
        return p.get('isFulfilled') || p.get('isLoaded');
      }).map(function (p) {
        return p.get('startDate').getTime();
      });
    },


    latestStartDate: Ember.computed('profile.id', 'otherProfiles.@each.id', function () {
      return new Date(Math.min.apply(Math, _toConsumableArray(this.getAllStartDates())));
    }),

    // oldProfile is passed in the case when a profile gets removed or changed for another profile.
    resetDates: function resetDates() {
      var oldProfile = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;

      // want to check if the fromDate was set to that oldProfile's startDate.
      // also check that toDate is today; otherwise user has selected a custom date range
      // and we don't change anything.
      var isDefaultStartDate = oldProfile && this.get('fromDate').getTime() == oldProfile.get('startDate').getTime();
      var isDefaultToDate = this.get('toDate').getTime() == moment().startOf('day').valueOf();
      var isDefaultRange = isDefaultStartDate && isDefaultToDate;

      var allStartDates = this.getAllStartDates();
      if (allStartDates.indexOf(this.get('fromDate').getTime()) > -1 || isDefaultRange) {
        this.set('fromDate', new Date(Math.min.apply(Math, _toConsumableArray(allStartDates))));
      }
    },


    actions: {
      dateRangeChanged: function dateRangeChanged(from, to) {
        this.setProperties({
          fromDate: moment(from).toDate(),
          toDate: moment(to).toDate()
        });
      },
      display: function display() {
        this.display();
      },
      saveItem: function saveItem() {
        this.save();
      },
      removeProfile: function removeProfile(profileConfig) {
        var otherProfiles = this.get("otherProfiles");
        otherProfiles.removeObject(profileConfig);
        this.resetDates(this.get('store').peekRecord('profile', profileConfig.get('profileId')));
        this.send("saveItem");
      },
      addProfile: function addProfile() {
        this.addProfile();
        this.resetDates();
      },
      changeOtherProfile: function changeOtherProfile(profile, oldProfile) {
        this.resetDates(oldProfile);
        this.send("saveItem");
      },
      changeProfile: function changeProfile(profile, oldProfile) {
        var campaign = void 0;
        var item = this.get("item");

        if (item) {
          item.setProperties({
            filterSetId: null,
            useDefaultFilterSet: true,
            profile: profile
          });
        }
        this.resetDates(oldProfile);
        this.send("saveItem");
      }
    }
  });
});
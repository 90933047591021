define("client/utils/identity", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = I;
  function I(x) {
    return x.toLocaleString();
  }
});
define('client/components/graph/tag-options', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    filterSet: null,
    selectionSet: null,

    options: Ember.computed("filterSet.reportingAdapter.tagFilterList", "filterSet.reportingAdapter.filterOptions.tagOptions", function () {
      var list = this.get("filterSet.reportingAdapter.tagFilterList"),
          options = this.get("filterSet.reportingAdapter.filterOptions.tagOptions");
      if (!list) {
        return [];
      }
      // ":not" tags are not selectable for graph.
      // same behavior as report
      return list.map(function (item) {
        return options.findBy("id", item);
      }).compact();
    }),

    update: function update() {}
  });
});
define('client/components/grid-item', ['exports', 'client/mixins/draggable'], function (exports, _draggable) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_draggable.default, {
    classNameBindings: [":grid-item"],
    attributeBindings: ['style'],

    content: null,
    componentName: null,

    col: 0,
    row: 0,
    colspan: 1,
    rowspan: 1,

    totalRows: Ember.computed.alias("grid.rows"),
    totalCols: Ember.computed.alias("grid.cols"),
    gutter: Ember.computed.alias("grid.gutter"),
    gridError: Ember.computed.oneWay("grid.isError"),

    didDragStart: function didDragStart(e) {
      if (this.get("reportService.isEdit") || this.get("reportService.isEditText") || this.get("reportService.isReadOnly")) {
        return false;
      }
      if (this.get("isEditing")) {
        return false;
      }
      if (e.originalEvent.offsetY < this.get("gutter")) {
        e.preventDefault();
        return false;
      }
      this.set("isEditing", false);

      var scale = this.$()[0].getBoundingClientRect().width / this.$()[0].offsetWidth * 100;
      var isFirefox = navigator.userAgent.toLowerCase().indexOf('firefox') > -1;
      //create clone so item is scaled properly when dragged
      this.clone = this.$().clone();
      this.clone.css('width', this.$().width() + 'px');
      this.clone.css('height', this.$().height() + 'px');
      this.clone.css('top', '0');
      this.clone.css('left', '0');
      this.clone.css('height', this.$().height() + 'px');
      this.clone.css('border', '1px dashed #bbb');
      //Use zoom instead of transform becuase of bug in chrome with drag clones
      //Zoom not available in FF so use transform
      if (isFirefox) {
        this.clone.css('transform', 'scale(' + scale / 100 + ', ' + scale / 100 + ')');
      } else {
        this.clone.css('zoom', scale + '%');
      }

      this.clone.removeAttr('id');
      this.clone.removeClass('dragging');
      //Iframes cause issues with drag so remove them
      Ember.$('iframe', this.clone).remove();
      Ember.$('body').append(this.clone[0]);
      var insertXPos = this.$().width() * (scale / 100) / 2;
      var insertYPos = this.$().height() * (scale / 100) / 2;
      e.dataTransfer.setDragImage(this.clone[0], insertXPos, insertYPos);
    },

    didDragEnd: function didDragEnd(e) {
      if (this.clone) {
        this.clone.remove();
      }
    },

    style: Ember.computed("col", "row", "colspan", "rowspan", "width", "height", "gutter", function () {
      var col = this.get("col"),
          row = this.get("row"),
          totalRows = this.get("totalRows"),
          totalCols = this.get("totalCols"),
          colspan = this.get("colspan"),
          rowspan = this.get("rowspan"),
          gutter = this.get("gutter"),
          width = 100 / totalCols,
          height = 100 / totalRows;

      var left = col * width,
          top = row * height;
      return new Ember.String.htmlSafe(["top:", top, "%;left:", left, "%;width:", colspan * width, "%;height:", rowspan * height, "%;padding:" + gutter, "px;"].join(""));
    }),
    actions: {
      expandItem: function expandItem(rowspan, colspan) {
        this.get("grid").send("expandItem", this, rowspan, colspan);
      }
    }
  });
});
define('client/mixins/models/query-consumer', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    queryByName: function queryByName(name) {
      var options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

      // Yes - querie - this avoid a bug in the resolver that just adds an 's'
      var queryFactory = Ember.getOwner(this).factoryFor('querie:' + name);
      options.item = this;
      var query = queryFactory.create(options);
      query.on("fulfilled", this, "refreshData");
      return query;
    },


    //
    // In this version this triggers another event for the component layer
    // to update. In a next version, when we do not save the html, this will
    // save the query content and the values returned.
    //
    refreshData: function refreshData() {
      this.trigger("refresh-data", this);
    }
  });
});
define('client/components/edit-report-color-row', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: '',
    color: null,
    isEditing: false,
    tempvalue: null,
    editColor: function editColor() {},
    removeColor: function removeColor() {},


    colorStyle: Ember.computed('color.hex', function () {
      return Ember.String.htmlSafe('background:' + this.get('color.hex') + ';width:25%;');
    }),

    actions: {
      setEditColor: function setEditColor(state) {
        this.set('tempvalue', this.get('color.hex'));
        this.set('isEditing', state);
      },
      saveColor: function saveColor() {
        var str = '' + (this.get('tempvalue')[0] === '#' ? '' : '#') + this.get('tempvalue');
        this.editColor(this.get('color.index'), str);
        this.set('isEditing', false);
      },
      onRemove: function onRemove(index) {
        this.removeColor(index);
      }
    }
  });
});
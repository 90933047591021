define('client/components/popover-close-button', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var PopoverCloseButtonComponent = Ember.Component.extend({
    tagName: 'button',
    classNames: ['popover-trigger', 'btn', 'btn-xs', 'btn-default', 'pull-right'],
    onClose: function onClose() {},


    click: function click() {
      this.onClose();
      this.get('callback')();
    }
  });

  exports.default = PopoverCloseButtonComponent;
});
define('client/components/common/ui-editable-field', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNameBindings: [':ui-editable-field', "isHovering"],
    attributeBindings: ['draggable'],
    isEditingField: false,
    isEditable: true,
    isHovering: false,
    field: '',
    // Not really draggable, but set to true so that dragStart
    // below gets fired and prevents parent from being dragged
    draggable: true,

    editedField: Ember.computed('field', {
      get: function get() {
        return this.get('field');
      },
      set: function set(key, value) {
        return value;
      }
    }),

    mouseEnter: function mouseEnter() {
      this.set('isHovering', true);
    },
    mouseLeave: function mouseLeave() {
      this.set('isHovering', false);
      if (this.get("isEditable")) {
        this.get('updateField')(this.get('editedField'));
      }
    },
    dragStart: function dragStart(event) {
      event.preventDefault();
      event.stopPropagation();
    },
    updateField: function updateField() {},


    actions: {
      editField: function editField() {
        this.set('isEditingField', true);
        Ember.run.schedule('afterRender', this, function () {
          this.$().find('input.edit-field-input').focus();
        });
      },
      resetField: function resetField() {
        this.set('editedField', this.get('field'));
        this.set('isEditingField', false);
      },
      stopEditing: function stopEditing(field, action) {
        var _this = this;

        var promise = action(field);
        promise = promise && promise.then ? promise : Ember.RSVP.resolve();
        promise.then(function () {}, function () {
          _this.set('editedField', _this.get('field'));
        });
      }
    }
  });
});
define('client/components/reports/report-select', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) {
    return typeof obj;
  } : function (obj) {
    return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
  };

  var ReportSelectOption = Ember.Object.extend({
    value: null,
    label: null,
    fill: null,
    reportSelect: null,
    isSelection: Ember.computed.oneWay('_isSelection'),
    _isSelection: Ember.computed('reportSelect.selection', function () {
      return Ember.isEqual(this.get('value'), this.get('reportSelect.selection'));
    })

  });

  var ReportSelect = Ember.Component.extend({

    content: null,
    groupPath: null,
    optionValuePath: null,
    optionLabelPath: null,
    optionFillPath: null,
    onSelect: function onSelect() {},

    selectId: null,
    selection: null,
    internalSelection: null,
    maxWidth: false,
    firstSelection: null,
    firstSelectionKeyName: null,
    hasSetInitialSelection: false,

    didInsertElement: function didInsertElement() {
      this.setInitialSelection();
    },

    //sometimes the options that are passed in are promises so
    //this code won't work if we put this in didInsertElement
    setInitialSelection: function setInitialSelection() {
      var initial;
      if (this.get('firstSelection') && this.get('firstSelectionKeyName')) {
        var options = this.get('flattenedOptions');
        initial = options.findBy(this.get('firstSelectionKeyName'), this.get('firstSelection'));
      } else if (this.get('selection')) {
        var selection = this.get('selection');
        initial = this.get('flattenedOptions').find(function (o) {
          return o === selection;
        });

        if (!initial) {
          initial = this.get('flattenedOptions').find(function (o) {
            return o.get('value') === selection;
          });
        }

        //if a selection is specified but not found in the options
        //then the options are not fully populated yet
        if (!initial) {
          return;
        }
      }

      if (!initial) {
        initial = this.get('flattenedOptions.firstObject');
      }

      this.set('internalSelection', initial);
      this.send('onChange', initial);
    },

    _setInitialSelection: Ember.observer('flattenedOptions.[]', function () {
      this.setInitialSelection();
    }),

    options: Ember.computed('content.[]', function () {
      if (!this.get('groupOptionsPath')) {
        var options = this._convertOptions(this.get('content'));
        var anyHaveFill = options.any(function (v) {
          return v.fill;
        });
        if (anyHaveFill) {
          options = options.filterBy('fill');
        }
        return options;
      }
    }),

    groupedOptions: Ember.computed('content.[]', function () {
      if (this.get('groupOptionsPath')) {
        var groupedOptions = Ember.A();
        for (var i = 0; i < this.get('content.length'); i++) {
          var group = this.get('content').objectAt(i);
          var oldOptions = group.get(this.get('groupOptionsPath'));
          groupedOptions.push(Ember.Object.create({
            groupName: group.get(this.get('groupPath')),
            options: this._convertOptions(oldOptions)
          }));
        }
        return groupedOptions;
      }
    }),

    flattenedOptions: Ember.computed('groupedOptions.[]', 'options.[]', function () {
      var _this = this;

      var groupedOptions = this.get('groupedOptions');
      if (groupedOptions) {
        return groupedOptions.map(function (o) {
          return o[_this.get('groupOptionsPath')];
        }).flatten();
      } else {
        return this.get('options');
      }
    }),

    _convertOptions: function _convertOptions(oldOptions) {
      var newOptions = Ember.A();
      if (!oldOptions) {
        return;
      }
      var optionFillPath = this.get('optionFillPath');
      for (var j = 0; j < oldOptions.length; j++) {
        var option = oldOptions.objectAt(j);
        if ((typeof option === 'undefined' ? 'undefined' : _typeof(option)) !== 'object') {
          newOptions.push(ReportSelectOption.create({
            reportSelect: this,
            value: option,
            label: option,
            fill: option
          }));
        } else {
          // if a vanilla object
          if (!option.get) {
            option = Ember.Object.create(option);
          }
          var reportOpt = ReportSelectOption.create({
            reportSelect: this,
            option: option
          });

          Ember.defineProperty(reportOpt, 'value', Ember.computed.alias('option.' + this.get('optionValuePath')));
          Ember.defineProperty(reportOpt, 'label', Ember.computed.alias('option.' + this.get('optionLabelPath')));
          if (optionFillPath) {
            Ember.defineProperty(reportOpt, 'fill', Ember.computed.alias('option.' + this.get('optionFillPath')));
          }

          newOptions.push(reportOpt);
        }
      }
      return newOptions;
    },

    actions: {
      onChange: function onChange(obj) {
        if (!obj) {
          return;
        }
        this.set('internalSelection', obj);
        this.set('selection', obj.get('value'));
        this.onSelect(obj.get('value'));
      }
    }

  });

  exports.default = ReportSelect;
});
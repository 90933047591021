define('client/components/reports/value-options', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    isHidden: function isHidden(valueName) {
      var hideOptions = this.get('hideOptions');
      if (Ember.isEmpty(hideOptions)) return false;
      return !!hideOptions[valueName];
    },


    showInteraction: Ember.computed('profile.showInteraction', 'hideOptions', function () {
      return !this.isHidden('interaction') && this.get('profile.showInteraction');
    })
  });
});
define('client/components/reports/profile-config', ['exports', 'client/components/dimension-config', 'client/models/competitor'], function (exports, _dimensionConfig, _competitor) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _dimensionConfig.default.extend({
    classNameBindings: [":report-profile-config", "profile.isRejected:error"],

    profile: null,
    corporates: null,

    isSelected: false,
    showProfiles: false,
    showSavedSearch: false,
    showSearchTerm: true,
    useCustomSearchTerm: Ember.computed.alias('config.useCustomSearchTerm'),
    changeProfile: function changeProfile() {},
    deleteProfile: function deleteProfile() {},


    canRemove: false,

    sliderRange: null,

    targetSelector: Ember.computed("elementId", function () {
      return "#" + this.get("elementId");
    }),

    init: function init() {
      this._super.apply(this, arguments);
      this.set('sliderRange', this.get('sliderRange') || { 'min': 0, 'max': 2 });
    },


    actions: {
      switchToProfile: function switchToProfile(profile) {
        var parent = this.get("profile");
        var oldProfile = parent;
        this.set("profile", profile);

        // this is for the hierarchy
        if (profile.constructor !== _competitor.default) {
          parent = profile.get("profile");
        }
        this.set("parentProfile", parent);

        if (profile.get("header_data")) {
          profile.reload();
        }
        this.changeProfile(profile, oldProfile);
        this.send("toggleProfile");
      },
      removeProfile: function removeProfile() {
        this.deleteProfile(this.get("config"));
      },
      toggleProfile: function toggleProfile() {
        this.toggleProperty("showProfiles");
      },
      toggleSavedSearches: function toggleSavedSearches() {
        this.toggleProperty("showSavedSearch");
      },
      cancelSearch: function cancelSearch() {
        this.set("searchTerm", "");
      },
      useSearch: function useSearch(search) {
        this.set("searchTerm", search.get("term"));
        this.set("showSavedSearch", false);
      }
    }
  });
});
define('client/initializers/attribute-bindings', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.initialize = initialize;


	var SELECTOR_PREFIX = /data-gs-.*/;

	function initialize() {

		Ember.Component.reopen({
			init: function init() {
				this._super.apply(this, arguments);
				bindDataAttributes(this);
			}
		});

		function bindDataAttributes(component) {
			var dataProperties = [];
			for (var attr in component) {
				if (SELECTOR_PREFIX.test(attr)) {
					dataProperties.push(attr);
				}
			}

			if (dataProperties.length === 0) {
				return;
			}

			var tagName = component.get('tagName');
			if (tagName === '') {
				var message = 'Could not bind data-gs-* properties on ' + component + ' automatically because tagName is empty.';

				(false && Ember.warn(message, false, {
					id: 'initializer-attribute-bindings.empty-tag-name'
				}));


				return;
			}

			var computedBindings = component.attributeBindings && component.attributeBindings.isDescriptor;
			if (computedBindings) {
				var _message = 'Could not bind data-gs-* properties on ' + component + ' automatically because attributeBindings is a computed property.';

				(false && Ember.warn(_message, false, {
					id: 'initializer-attribute-bindings.computed-attribute-bindings'
				}));


				return;
			}

			var attributeBindings = component.getWithDefault('attributeBindings', []);

			if (!Ember.isArray(attributeBindings)) {
				attributeBindings = [attributeBindings];
			} else {
				attributeBindings = attributeBindings.slice();
			}

			dataProperties.forEach(function (it) {
				return attributeBindings.push(it);
			});

			component.set('attributeBindings', attributeBindings);
		}
	}

	exports.default = {
		name: 'attribute-bindings',
		initialize: initialize
	};
});
define('client/utils/date-ranges', ['exports', 'moment', 'client/utils/time-series-labeler'], function (exports, _moment, _timeSeriesLabeler) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.quarterFromToday = exports.calcRangeFor = exports.ALL_RANGES = exports.MORE_RANGES = exports.RANGES = exports.OTHER_RANGES = exports.METHODS = exports.PROFILE_RANGES = undefined;


  var quarterFromToday = function quarterFromToday(today) {
    today = (0, _moment.default)(today);
    var quarterAdjustment = today.month() % 3 + 1,
        lastQuarterEndDate = today.clone().subtract({ months: quarterAdjustment }).endOf('month'),
        lastQuarterStartDate = lastQuarterEndDate.clone().subtract({ months: 2 }).startOf('month');

    return [lastQuarterStartDate.toDate(), lastQuarterEndDate.toDate()];
  };

  var RANGES = [{
    id: "today",
    name: "Today",
    from: "today",
    to: "today"
  }, {
    id: "yesterday",
    name: "Yesterday",
    from: "yesterday",
    to: "yesterday"
  }, {
    id: "last_week",
    name: "Last Week",
    from: "lastWeekFrom",
    to: "lastWeekTo"
  }, {
    id: "last_7",
    name: "Last 7 Days",
    from: "lastSevenFrom",
    to: "today"
  }, {
    id: "last_month",
    name: "Last Month",
    from: "lastMonthFrom",
    to: "lastMonthTo"
  }, {
    id: "last_30",
    name: "Last 30 Days",
    from: "lastThirtyFrom",
    to: "today"
  }, {
    id: "all",
    name: "All Time",
    from: "startOfTime",
    to: "today"
  }];

  var MORE_RANGES = [{
    id: "last_90",
    name: "Last 90 days",
    from: "lastNinetyFrom",
    to: "today"
  }, {
    id: "last_quarter",
    name: "Last Quarter (calendar)",
    from: "lastQuarterFrom",
    to: "lastQuarterTo"
  }, {
    id: "this_quarter",
    name: "This Quarter (calendar)",
    from: "thisQuarterFrom",
    to: "today"
  }, {
    id: "last_quarter_fiscal",
    name: "Last Quarter (fiscal)",
    from: "lastQuarterFiscalFrom",
    to: "lastQuarterFiscalTo"
  }, {
    id: "this_quarter_fiscal",
    name: "This Quarter (fiscal)",
    from: "thisQuarterFiscalFrom",
    to: "today"
  }, {
    id: "last_year",
    name: "Last Year (calendar)",
    from: "lastYearFrom",
    to: "lastYearTo"
  }, {
    id: "this_year",
    name: "This Year (calendar)",
    from: "thisYearFrom",
    to: "today"
  }, {
    id: "this_year_fiscal",
    name: "This Year (fiscal)",
    from: "thisYearFiscalFrom",
    to: "today"
  }, {
    id: "13_months",
    name: "Last 13 months",
    from: "last13MonthsFrom",
    to: "today"
  }, {
    id: "this_month",
    name: "This Month",
    from: "thisMonthFrom",
    to: "today"
  }, {
    id: "this_week",
    name: "This Week",
    from: "thisWeekFrom",
    to: "today"
  }];

  var OTHER_RANGES = [];

  var ALL_RANGES = RANGES.concat(MORE_RANGES).concat(OTHER_RANGES);

  var PROFILE_RANGES = ["this_week", "last_week", "this_month", "last_month", "last_quarter", "this_quarter", "this_quarter_fiscal", "this_year", "last_quarter_fiscal", "this_year_fiscal", "all", "13_months"].map(function (id) {
    return ALL_RANGES.findBy("id", id);
  });

  var METHODS = {

    today: function today() {
      // Rounded todays
      return d3.time.day(new Date());
    },

    yesterday: function yesterday() {
      return d3.time.day.offset(this.today(), -1);
    },

    thisWeekFrom: function thisWeekFrom() {
      var today = this.today();
      // We want Monday based weeks
      return d3.time.monday(today);
    },

    lastWeekFrom: function lastWeekFrom() {
      var today = this.today();
      // We want Monday based weeks
      var thisWeekFrom = d3.time.monday(today);
      return d3.time.monday.offset(thisWeekFrom, -1);
    },

    lastWeekTo: function lastWeekTo() {
      var from = METHODS.lastWeekFrom();
      return d3.time.day.offset(from, 6);
    },

    lastSevenFrom: function lastSevenFrom() {
      var today = this.today();
      return d3.time.day.offset(today, -6);
    },

    thisMonthFrom: function thisMonthFrom() {
      var today = this.today();
      return d3.time.month(today);
    },

    lastMonthFrom: function lastMonthFrom() {
      var today = this.today();
      var thisMonthFrom = d3.time.month(today);
      return d3.time.month.offset(thisMonthFrom, -1);
    },

    lastMonthTo: function lastMonthTo() {
      var from = METHODS.lastMonthFrom();
      var nextMonth = d3.time.month.offset(from, 1);
      return d3.time.day.offset(nextMonth, -1);
    },

    last13MonthsFrom: function last13MonthsFrom() {
      var today = this.today();
      var thisMonthFrom = d3.time.month(today);
      return d3.time.month.offset(thisMonthFrom, -13);
    },

    lastThirtyFrom: function lastThirtyFrom() {
      var today = this.today();
      return d3.time.day.offset(today, -29);
    },

    lastSixtyFrom: function lastSixtyFrom() {
      var today = this.today();
      return d3.time.day.offset(today, -59);
    },

    lastNinetyFrom: function lastNinetyFrom() {
      var today = this.today();
      return d3.time.day.offset(today, -89);
    },

    lastQuarterFrom: function lastQuarterFrom() {
      return quarterFromToday(this.today())[0];
    },

    lastQuarterTo: function lastQuarterTo() {
      return quarterFromToday(this.today())[1];
    },

    lastQuarterFiscalFrom: function lastQuarterFiscalFrom() {
      return d3.time.month.offset((0, _timeSeriesLabeler.quarterFromDate)(this.today(), this.fiscalOffset()).dates[0], -3);
    },

    lastQuarterFiscalTo: function lastQuarterFiscalTo() {
      return d3.time.month.offset((0, _timeSeriesLabeler.quarterFromDate)(this.today(), this.fiscalOffset()).dates[1], -3);
    },

    thisQuarterFiscalFrom: function thisQuarterFiscalFrom() {
      return (0, _timeSeriesLabeler.quarterFromDate)(this.today(), this.fiscalOffset()).dates[0];
    },

    thisQuarterFrom: function thisQuarterFrom() {
      return (0, _moment.default)(quarterFromToday(this.today())[1]).add({ day: 1 }).toDate();
    },

    lastYearFrom: function lastYearFrom() {
      return (0, _moment.default)(this.today()).subtract(1, "year").startOf("year").toDate();
    },

    lastYearTo: function lastYearTo() {
      return (0, _moment.default)(this.today()).subtract(1, "year").endOf("year").toDate();
    },

    thisYearFrom: function thisYearFrom() {
      return (0, _moment.default)(this.today()).startOf("year").toDate();
    },

    thisYearFiscalFrom: function thisYearFiscalFrom() {
      return (0, _timeSeriesLabeler.fiscalYearDatesFromDate)(this.today(), this.fiscalOffset())[0];
    }
  };

  var calcRangeFor = function calcRangeFor(range, _startOfTime) {
    var _fiscalOffset = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : { month: 0, day: 0 };

    var month = _fiscalOffset.month,
        day = _fiscalOffset.day;

    var methods = Ember.merge({
      startOfTime: function startOfTime() {
        return _startOfTime;
      },
      fiscalOffset: function fiscalOffset() {
        return _fiscalOffset;
      }
    }, METHODS);
    if (!range.id) {
      range = ALL_RANGES.findBy("id", range);
    }
    return { name: range.name, from: methods[range.from](), to: methods[range.to]() };
  };

  exports.PROFILE_RANGES = PROFILE_RANGES;
  exports.METHODS = METHODS;
  exports.OTHER_RANGES = OTHER_RANGES;
  exports.RANGES = RANGES;
  exports.MORE_RANGES = MORE_RANGES;
  exports.ALL_RANGES = ALL_RANGES;
  exports.calcRangeFor = calcRangeFor;
  exports.quarterFromToday = quarterFromToday;
});
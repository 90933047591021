define('client/components/analyze/article-tabs/article-tab', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNameBindings: [":article-tabs_tab", "selected"],
    initiallySelected: false,
    selected: Ember.computed("parentTabs.selectedTab", function () {
      return this.get("parentTabs.selectedTab") === this;
    }),
    tabBody: Ember.computed("parentTabs.elementId", function () {
      return Ember.$("#" + this.get("parentTabs.elementId") + " .article-tabs_body")[0];
    }),

    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      this.get("parentTabs").addTab(this);
      if (this.get("initiallySelected")) {
        this.click();
      }
      this.notifyPropertyChange("tabBody");
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);
      this.get("parentTabs").removeTab(this);
    },
    click: function click() {
      this.get("onClick")(this);
    },
    onClick: function onClick() {}
  });
});
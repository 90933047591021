define('client/models/author-stat', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    author: _emberData.default.belongsTo('author'),
    filterSet: _emberData.default.attr('string'),
    month: _emberData.default.attr('number'),
    year: _emberData.default.attr('number'),
    totalAmplification: _emberData.default.attr('number'),
    numArticles: _emberData.default.attr('number'),
    fbShares: _emberData.default.attr('number'),
    linkedinShares: _emberData.default.attr('number'),
    pinterestPins: _emberData.default.attr('number'),

    averageAmplification: Ember.computed('totalAmplification', 'numArticles', function () {
      var totalAmplification = this.get('totalAmplification');
      if (Ember.isEmpty(totalAmplification)) {
        return null;
      }
      var numArtitlces = this.get('numArticles') || 1;
      return totalAmplification / numArtitlces;
    })
  });
});
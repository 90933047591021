define('client/components/common/ui-error-display', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var UiErrorDisplay = Ember.Component.extend({

    classNameBindings: [":form-group", "hasDanger"],

    errorMessageProperty: 'message',

    hasDanger: Ember.computed.bool("errors.length"),

    init: function init() {
      this._super.apply(this, arguments);
      //can pass in a comma-seperate list of properties
      //Or the error array can be passed in directly
      if (!this.get('errors')) {
        Ember.defineProperty(this, 'errors', Ember.computed('model.errors.{' + this.get('property') + '}', function (argument) {
          var _this = this;

          var prop = this.get('property');
          if (prop.indexOf(',') >= 0) {
            var props = prop.split(',');
            var errors = props.map(function (e) {
              return _this.get('model.errors.' + e);
            });
            return errors.compact().flatten();
          } else {
            return this.get('model.errors.' + prop);
          }
        }));
      }
    }
  });

  UiErrorDisplay.reopenClass({
    positionalParams: ["model"]
  });

  exports.default = UiErrorDisplay;
});
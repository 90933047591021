define('client/components/article-metric', ['exports', 'client/utils/identity'], function (exports, _identity) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var _Ember$computed = Ember.computed,
      equal = _Ember$computed.equal,
      bool = _Ember$computed.bool;


  var ExpandedArticleMetricComponent = Ember.Component.extend({
    classNames: ["metric"],

    hasAnalytics: bool("profile.hasAnalytics"),

    formatter: _identity.default,

    formattedConversions: Ember.computed('expandedArticleShowsCurrentSelection', 'conversions', 'conversionsForDateRange', function () {
      var conversions,
          formattedConversions,
          formatter = this.get('formatter');

      if (this.get('expandedArticleShowsCurrentSelection')) {
        conversions = this.get('conversionsForDateRange');
      } else {
        conversions = this.get('conversions');
      }

      formattedConversions = (conversions || []).map(function (d) {
        return {
          name: d.name,
          value: formatter(d.value)
        };
      });
      return formattedConversions;
    }),

    formattedMetricTotal: Ember.computed('expandedArticleShowsCurrentSelection', 'metricForDateRange', 'metric', function () {
      var total;
      if (this.get('expandedArticleShowsCurrentSelection')) {
        total = this.get('metricForDateRange');
      } else {
        total = this.get('metric');
      }
      return this.get('formatter')(total);
    })
  });

  exports.default = ExpandedArticleMetricComponent;
});
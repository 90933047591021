define('client/components/reports/download-modal', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    sharing: false,
    report: null,
    modal: Ember.inject.service(),
    host: Ember.inject.service(),

    onFinish: function onFinish() {},


    reportUrl: Ember.computed("report.hexUrl", function () {
      var hex = this.get("report.hexUrl");

      return window.location.origin + "/reports/shared/" + hex;
    }),

    pdfUrl: Ember.computed('report.id', function () {
      var url = _emberData.default.RESTAdapter.prototype.buildURL('report', this.get('report.id'));
      return this.get('host.appRoot') + '/v4/api' + url + '/pdf';
    }),

    imagesUrl: Ember.computed('report.id', function () {
      var url = _emberData.default.RESTAdapter.prototype.buildURL('report', this.get('report.id'));
      return '/v4/api' + url + '/images';
    }),

    actions: {
      saveReport: function saveReport() {
        this.get("report").touch();
      },
      close: function close() {
        this.onFinish();
      },
      share: function share() {
        this.toggleProperty("sharing");
        Ember.run.schedule("afterRender", this, function () {
          this.$("input").select();
        });
      },
      pdf: function pdf() {
        window.open(this.get("pdfUrl"));
        this.onFinish();
      },
      pdf_preview: function pdf_preview() {
        window.open(this.get("pdfUrl") + '_preview');
        this.onFinish();
      },
      images: function images() {
        Ember.$.get(this.get("imagesUrl"));
        this.get('modal').open('common/ui-confirm', {
          question: 'You will receive an email containing the report                    exported as images.',
          showCancel: false,
          ok: "OK",
          okType: "info"
        });
        this.onFinish();
      }
    }
  });
});
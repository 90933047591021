define('client/components/reports/report-page', ['exports', 'client/mixins/draggable'], function (exports, _draggable) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_draggable.default, {
    classNameBindings: [':report-page'],
    attributeBindings: ['dataPage:data-page'],

    dataPage: Ember.computed.alias('page.page'),
    deletePage: function deletePage() {},


    report: null,
    page: null,

    dragStart: function dragStart(e) {
      if (+this.get('page.page') > 0) {
        return this._super(e);
      } else {
        e.preventDefault();
        e.stopPropagation();
        return false;
      }
    },


    actions: {
      onDeletePage: function onDeletePage(page) {
        this.deletePage(page);
      }
    }
  });
});
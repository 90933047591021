define('client/components/insights/author-recommendations', ['exports', 'client/computed/top-items'], function (exports, _topItems) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var TOP_AUTHORS = 3;

  exports.default = Ember.Component.extend({
    classNames: ['author-recommendations', 'recommendations'],

    showTop: true,
    topAuthors: (0, _topItems.default)("recommendations", TOP_AUTHORS)
  });
});
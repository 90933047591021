define('client/components/reports/image-list-item', ['exports', 'client/components/reports/toolbar-item'], function (exports, _toolbarItem) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _toolbarItem.default.extend({
    classNameBindings: [":report-image-list-item"],
    attributeBindings: ['style'],

    onDoubleClick: function onDoubleClick() {},


    style: Ember.computed('image', function () {
      return new Ember.String.htmlSafe(['background-image:url("', this.get('image.thumb_url'), '")'].join(''));
    }),

    image: Ember.computed.alias("content.image"),
    actions: {
      destroyImage: function destroyImage() {
        this.get("image").destroyRecord();
      }
    },

    doubleClick: function doubleClick() {
      this.onDoubleClick(Ember.Object.create({
        configName: this.get('configName'),
        colspan: this.get('minColspan'),
        rowspan: this.get('minRowspan'),
        content: this.get('content')
      }));
    }
  });
});
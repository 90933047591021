define('client/routes/reports/share', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    titleToken: function titleToken(model) {
      return model.get('name');
    },

    //override beforeModel so authentication isn't checked
    beforeModel: function beforeModel(transition) {
      Ember.$.ajaxPrefilter(function (xhrOptions, originalOptions, xhr) {
        xhr.setRequestHeader('Authorization', "Token " + transition.params['reports.share'].hex);
      });
    },
    model: function model(params) {
      return this.store.queryRecord("report", { hex: params.hex });
    },
    afterModel: function afterModel(model) {
      this.get("profileService").setProfile(this.store.peekRecord("profile", model.get("profile_id")));
      this.set("reportService.isReadOnly", true);
      this.set("reportService.isViewingShare", true);
    },


    unsetReadOnly: Ember.on('deactivate', function () {
      this.set("reportService.isReadOnly", false);
      this.set("reportService.isViewingShare", false);
    }),

    actions: {
      error: function error() {
        this.transitionTo('report_not_found');
      }
    }
  });
});
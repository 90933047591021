define('client/components/settings/competitor-color', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: '',
    competitor: null,
    competitorColors: null,
    stableCompetitorColors: null,
    color: Ember.computed.alias("competitorColor.color"),
    colorOneWay: Ember.computed.oneWay("competitorColor.color"),
    displayColor: Ember.computed.alias('displayCompetitorColor.color'),
    displayCompetitorColor: Ember.computed("competitorColor", "stableCompetitorColors.@each.competitorId", function () {
      var competitorColor = this.get("competitorColor"),
          stableCompetitorColors = this.get('stableCompetitorColors');
      if (competitorColor) {
        return competitorColor;
      }
      return stableCompetitorColors.findBy("competitorId", this.get("competitor.id"));
    }),
    competitorColor: Ember.computed("competitor", "competitorColors.@each.competitor", function () {
      var competitorsColors = this.get("competitorColors");
      if (!competitorsColors) {
        return null;
      }
      return competitorsColors.findBy("competitorId", this.get("competitor.id"));
    }),

    colorStyle: Ember.computed('displayColor', function () {
      return Ember.String.htmlSafe('background:' + this.get('displayColor'));
    }),

    isEditing: false,

    editColor: function editColor() {},
    removeColor: function removeColor() {},


    actions: {
      saveColor: function saveColor(color) {
        this.get('editColor')(this.get('competitor.id'), this.get("competitorColor"), '#' + color);
      }
    }
  });
});
define('client/components/dashboard/dashboard-card', ['exports', 'client/components/common/ui-card'], function (exports, _uiCard) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _uiCard.default.extend({
    classNameBindings: [":dashboard-card"],

    draggable: true,
    showInfo: false,

    isHovering: false,
    mouseEnter: function mouseEnter() {
      this.set('isHovering', true);
    },
    mouseLeave: function mouseLeave() {
      this.set('isHovering', false);
    },
    onClickBody: function onClickBody() {},
    onTrendChange: function onTrendChange() {},
    onToggleDetails: function onToggleDetails() {},


    actions: {
      toggleInfo: function toggleInfo() {
        this.toggleProperty("showInfo");
      }
    }
  });
});
define("client/routes/reports/share/index", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    beforeModel: function beforeModel() {
      var report = this.modelFor("reports.share"),
          page = report.get("pages.firstObject");

      this.transitionTo("reports.share.page", report.get('hexUrl'), page);
    }
  });
});
define('client/models/campaign-keyword', ['exports', 'ember-data', 'ember-cp-validations'], function (exports, _emberData, _emberCpValidations) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var Validations = (0, _emberCpValidations.buildValidations)({
    name: (0, _emberCpValidations.validator)('presence', true)
  });

  var CampaignKeyword = _emberData.default.Model.extend(Validations, {

    name: _emberData.default.attr("string"),
    type: _emberData.default.attr("string"),
    campaign_id: _emberData.default.attr("number")

  });
  CampaignKeyword.pathForType = "campaign_keywords";

  exports.default = CampaignKeyword;
});
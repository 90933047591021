define('client/controllers/analyze/messaging', ['exports', 'ember-parachute', 'client/helpers/format-neo-for-analyze'], function (exports, _emberParachute, _formatNeoForAnalyze) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var NeoQP = new _emberParachute.default({
    bucketId: {
      defaultValue: null
    },
    messageId: {
      defaultValue: null
    }
  });

  var heatMap = function heatMap(prop) {
    return Ember.computed('soundbyteStats.' + prop, function () {
      return this.get("soundbyteStats").mapBy(prop).uniq().sort(function (a, b) {
        return b - a;
      });
    });
  };

  exports.default = Ember.Controller.extend(NeoQP, {
    analyzeController: Ember.inject.controller("analyze"),
    filterSet: Ember.computed.alias("analyzeController.filterSet"),
    showArticleList: false,
    articleListSort: 'date',
    groupByPublication: false,
    currentSoundbyte: Ember.computed('messageId', 'soundbytes.[]', function () {
      return this.get('sortedSoundbytes').findBy('id', this.get('messageId')) || this.get('sortedSoundbytes.firstObject.id');
    }),
    soundbyteStats: Ember.computed('soundbytes.@each.info', function () {
      return this.get('soundbytes').mapBy('info');
    }),
    // create a special graph with the selected soundbyte
    graphData: Ember.computed("selectedSoundbyteFilterSet", "selectedSort.valueDimension", function () {
      var _getProperties = this.getProperties("selectedSoundbyteFilterSet", "analyzeController", "profileService"),
          selectedSoundbyteFilterSet = _getProperties.selectedSoundbyteFilterSet,
          analyzeController = _getProperties.analyzeController,
          profileService = _getProperties.profileService,
          profile = profileService.get("profile"),
          _profileService$getPr = profileService.getProperties("from", "to"),
          from = _profileService$getPr.from,
          to = _profileService$getPr.to;

      return this.store.createRecord("graph", {
        title: null,
        xAxisTitle: null,
        yAxisTitle: null,
        valueDimension: [this.get("selectedSort.valueDimension")],
        filterSet: selectedSoundbyteFilterSet,
        selectionSet: this.createNewFilterSet(),
        fromDate: moment(from).toDate() || profile.get("startDate"),
        toDate: moment(to).toDate() || new Date()
      });
    }),

    createNewFilterSet: function createNewFilterSet() {
      return this.store.createRecord("filter-set", {
        user: this.get('currentUser.content'),
        profile: this.get("profileService.profile"),
        campaign: this.get("profileService.campaign")
      });
    },


    selectedSoundbyte: Ember.computed("messageId", "soundbytes.@each.id", function () {
      var _getProperties2 = this.getProperties("messageId", "soundbytes"),
          messageId = _getProperties2.messageId,
          soundbytes = _getProperties2.soundbytes;

      if (soundbytes) {
        return soundbytes.findBy("id", messageId);
      }
    }),

    selectedSoundbyteFilterSet: Ember.computed("selectedSoundbyte", "filterSet", function () {
      var _getProperties3 = this.getProperties("filterSet", "selectedSoundbyte"),
          filterSet = _getProperties3.filterSet,
          selectedSoundbyte = _getProperties3.selectedSoundbyte,
          modifiedFilterSet = filterSet.copy();

      if (selectedSoundbyte) {
        var text = selectedSoundbyte.get("text");
        if (!text.match(/\(|\)/)) {
          text = (0, _formatNeoForAnalyze.formatNeoForAnalyze)(text);
        }
        modifiedFilterSet.set("searchTerm", text);
      }
      return modifiedFilterSet;
    }),

    preselectSoundbyte: function preselectSoundbyte() {
      var messageId = this.get("messageId"),
          selection = this.get("sortedSoundbytes").findBy("id", messageId);
      if (!selection) {
        this.set("messageId", this.get("sortedSoundbytes.firstObject.id"));
      }
    },


    corporateBucket: Ember.computed.alias("profileService.profile.corporateMessageBucket"),
    buckets: Ember.computed("profileService.profile.profileMessageBuckets", "corporateBucket", function () {
      var buckets = this.get("profileService.profile.profileMessageBuckets").toArray(),
          corporateBucket = this.get("corporateBucket");
      buckets.unshift(corporateBucket);
      return buckets;
    }),
    bucket: Ember.computed("corporateBucket", "bucketId", "buckets.@each.id", function () {
      var buckets = this.get("buckets"),
          bucketId = this.get("bucketId"),
          corporateBucket = this.get("corporateBucket");
      return buckets.findBy("id", bucketId) || corporateBucket;
    }),

    sorts: Ember.computed("profileService.profile", function () {
      var profile = this.get("profileService.profile"),
          sorts = [Ember.Object.create({
        name: "Most Articles",
        valueDimension: "coverage",
        properties: ["info.coverage:desc"]
      }), Ember.Object.create({
        name: "Most Amplification",
        valueDimension: "amplification",
        properties: ["info.amplification:desc"]
      })];
      if (profile.get("showCustomer")) {
        sorts.push(Ember.Object.create({
          name: 'Most ' + profile.get("nameCustomer"),
          valueDimension: "visitor",
          properties: ["info.visitor:desc"]
        }));
      }
      if (profile.get("showInteraction")) {
        sorts.push(Ember.Object.create({
          name: 'Most ' + profile.get("nameInteraction"),
          valueDimension: "interaction",
          properties: ["info.interaction:desc"]
        }));
      }

      return sorts;
    }),

    selectedSort: Ember.computed("sorts", function () {
      return this.get("sorts.firstObject");
    }),

    messages: Ember.computed.alias("bucket.messages"),

    messageInfos: Ember.computed("bucket", "messages.[]", "filterSet", function () {
      var _getProperties4 = this.getProperties("bucket", "filterSet", "profileService"),
          bucket = _getProperties4.bucket,
          filterSet = _getProperties4.filterSet,
          profileService = _getProperties4.profileService,
          _profileService$getPr2 = profileService.getProperties("from", "to"),
          from = _profileService$getPr2.from,
          to = _profileService$getPr2.to;

      if (bucket) {
        return bucket.getMessageInfos({ filterSet: filterSet, from: from, to: to });
      }
    }),

    soundbytes: Ember.computed("messageInfos.isFulfilled", function () {
      var infos = this.get("messageInfos");
      if (infos.get("isFulfilled")) {
        Ember.run.later(this, "preselectSoundbyte");

        return this.get("messages").map(function (message) {
          var info = infos.get("content").findBy("messageId", message.get("id"));
          return Ember.ObjectProxy.create({
            content: message,
            info: info
          });
        });
      }
    }),
    soundbytesSort: Ember.computed.alias("selectedSort.properties"),
    sortedSoundbytes: Ember.computed.sort("soundbytes", "soundbytesSort"),

    articleHeatMap: heatMap("coverage"),
    amplificationHeatMap: heatMap("amplification"),
    visitorHeatMap: heatMap("visitor"),
    interactionHeatMap: heatMap("interaction"),

    actions: {
      changeBucket: function changeBucket(bucket) {
        this.set("bucketId", bucket.get("id"));
      },
      changeSort: function changeSort(sort) {
        this.set("selectedSort", sort);
      },
      openArticleListModal: function openArticleListModal() {
        this.set('showArticleList', true);
      },
      selectSoundbyte: function selectSoundbyte(soundbyte) {
        this.set("messageId", soundbyte.get("id"));
      }
    }

  });
});
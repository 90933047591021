define("client/utils/date-format", ["exports", "moment-timezone"], function (exports, _momentTimezone) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.parseDate = undefined;

  exports.default = function (date) {
    var options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

    if (!date) {
      return "";
    }
    var format = options && options.format || DEFAULT_FORMAT;
    if (options.timezone) {
      return (0, _momentTimezone.default)(date).tz(options.timezone).format(format);
    }
    return (0, _momentTimezone.default)(date).format(format);
  };

  var DEFAULT_FORMAT = "M/D/YYYY";

  var parseDate = function parseDate(string) {
    var options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

    return (0, _momentTimezone.default)(string, options && options.format || DEFAULT_FORMAT);
  };

  exports.parseDate = parseDate;
});
define('client/components/resizable-input', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var validateInput = function validateInput(e, type) {
    if ([46, 8, 9, 27, 13, 110, 190].indexOf(e.keyCode) !== -1 ||
    // Allow: Ctrl+A
    e.keyCode === 65 && e.ctrlKey === true ||
    // Allow: Ctrl+C
    e.keyCode === 67 && e.ctrlKey === true ||
    // Allow: Ctrl+X
    e.keyCode === 88 && e.ctrlKey === true ||
    // Allow: home, end, left, right
    e.keyCode >= 35 && e.keyCode <= 39) {

      // let it happen, don't do anything
      return true;
    }

    var isAlphaNumeric = type === 'alphanumeric';

    var maxCode;
    if (isAlphaNumeric) {
      maxCode = 90;
    } else {
      //'numeric'
      maxCode = 57;
    }

    if (isAlphaNumeric) {
      if (e.shiftKey && e.keyCode >= 48 && e.keyCode <= 57 || (e.keyCode < 48 || e.keyCode > 90) && (e.keyCode < 96 || e.keyCode > 105)) {
        return false;
      } else {
        return true;
      }
    } else {
      if ((e.shiftKey || e.keyCode < 48 || e.keyCode > 57) && (e.keyCode < 96 || e.keyCode > 105)) {
        return false;
      } else {
        return true;
      }
    }
  };

  exports.default = Ember.Component.extend(Ember.TextSupport, {
    classNames: ['resizable-input'],
    attributeBindings: ["contentEditable"],

    contentEditable: "true",

    isNumeric: false,
    isAlphaNumeric: false,
    allowReturn: false,

    onKeyPress: function onKeyPress() {},
    onBackspace: function onBackspace() {},
    onUpKey: function onUpKey() {},
    onDownKey: function onDownKey() {},


    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      this.valueDidChange();
      this.editableFix = Ember.$('<input style="display:none;width:1px;height:1px;border:none;margin:0;padding:0;" tabIndex="-1">').prependTo(this.$().parent());
      this.$().on("paste." + this.get("elementId"), Ember.$.proxy(this, "paste"));
    },

    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);
      if (this.editableFix) {
        this.editableFix.remove();
      }
      this.$().off("paste." + this.get("elementId"), Ember.$.proxy(this, "paste"));
    },

    focusOut: function focusOut(e) {
      this.editableFix[0].setSelectionRange(0, 0);
      this.editableFix.blur();
      this._super(e);
    },

    // override from textsupport
    _elementValueDidChange: function _elementValueDidChange() {
      this._internalSet = true;
      this.set("value", this.$().text());
      this._internalSet = false;
    },

    valueDidChange: Ember.observer("value", function () {
      if (this._internalSet) {
        return;
      }
      this.$().html(this.get("value"));
    }),

    paste: function paste(e) {
      var text = (e.originalEvent || e).clipboardData.getData('text/plain');
      document.execCommand('insertText', false, text);
      e.preventDefault();
      Ember.run.later(this, function () {
        this.onKeyPress();
      }, 10);
    },


    keyDown: function keyDown(e) {
      if (!this.get("allowReturn") && e.keyCode === 13) {
        e.preventDefault();
      }

      switch (e.keyCode) {
        case 8:
        case 46:
          this.onBackspace(e);
          break;
        case 38:
          this.onUpKey(e);
          break;
        case 40:
          this.onDownKey(e);
          break;
      }

      var ret;
      if (this.get("isNumeric")) {
        ret = validateInput(e, 'numeric');
      } else if (this.get('isAlphaNumeric')) {
        ret = validateInput(e, 'alphanumeric');
      }

      if (ret !== undefined) {
        if (ret) {
          this._super(e);
        } else {
          e.preventDefault();
        }
        return;
      }

      this._super(e);
    }

  });
});
define('client/components/plan/media/search-print-publication', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ["search-print"],

    store: Ember.inject.service(),

    options: Ember.computed.debounce("searchTerm", function () {
      var search = this.get("searchTerm");
      if (Ember.isPresent(search)) {
        // refresh from the server with filtered values
        return this.get("store").query("print-publication", {
          search: search
        });
      }
      return [];
    }, 200),

    onAdd: function onAdd() {},


    actions: {
      searchPrintPublication: function searchPrintPublication(term) {
        this.set("searchTerm", term);
      }
    }
  });
});
define('client/models/dashboard', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var Dashboard = _emberData.default.Model.extend({
    dashboardCards: _emberData.default.hasMany('dashboard-card', { async: true, inverse: 'dashboard' }),
    profile: _emberData.default.belongsTo('profile'),
    campaign: null,

    sortedDashboardCards: Ember.computed('dashboardCards.@each.{next,nextId,id,previous,first}', 'dashboardCards.[]', function () {
      var cards = Ember.A();
      var dashboardCards = this.get('dashboardCards');
      var nextCard = dashboardCards.findBy('first');
      while (Ember.isPresent(nextCard)) {
        cards.pushObject(nextCard);
        nextCard = nextCard.get('next');
      }
      return cards;
    })
  });

  exports.default = Dashboard;
});
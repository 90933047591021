define('client/not-store', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  //
  // Nested resource ids are used to fake nested routes.
  // Whenever the path is found on the record, a nested resource
  // param will be added with the id found in path.
  // This is valid for create, update, delete/
  //
  // See function checkUrl below.
  //
  var nestedResourceIds = [{ path: 'profile_id', param: 'profile_id' }, { path: 'parent_id', param: 'profile_id' }, { path: 'analytics_profile_id', param: 'analytics_profile_id' }, { path: 'campaign_id', param: 'campaign_id' }, { path: 'campaign.id', param: 'campaign_id' }];

  exports.default = nestedResourceIds;
});
define("client/routes/reports/new/create", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    model: function model(params) {
      if (params.id === undefined) {
        // This handles the case when creating a new form
        // null model is handled in the template
        return null;
      }
      return this.store.find("report-template", params.id);
    },
    setupController: function setupController(controller, model) {
      this._super.apply(this, arguments);
      controller.createReport();
    }
  });
});
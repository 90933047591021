define("client/models/profile-link-filter", ["exports", "ember-data"], function (exports, _emberData) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.ProfileLinkFilterTypes = undefined;


  var ProfileLinkFilterTypes = {
    BLACKLIST: 0,
    WHITELIST: 1,
    BROADCAST: 2,
    PRINT: 3
  };

  var ProfileLinkFilter = _emberData.default.Model.extend({

    useOriginalAdapter: true,

    profile: _emberData.default.belongsTo("profile"),
    user: _emberData.default.belongsTo("user"),
    userName: _emberData.default.attr("string"),
    whitelistTagId: _emberData.default.attr("string"),
    whitelistTag: _emberData.default.belongsTo('whitelist-tag'),
    broadcastProvider: _emberData.default.belongsTo('broadcast-provider'),
    printPublication: _emberData.default.belongsTo('print-publication'),
    entity_id: _emberData.default.attr("string"),
    pattern: _emberData.default.attr("string"),
    updated_at: _emberData.default.attr("isodate"),
    type: _emberData.default.attr("number"),
    logoUrl: _emberData.default.attr("string"),

    propagate_down: _emberData.default.attr("boolean"),
    propagate_competitors: _emberData.default.attr("boolean")
  });

  ProfileLinkFilter.useOriginalAdapter = true;

  exports.ProfileLinkFilterTypes = ProfileLinkFilterTypes;
  exports.default = ProfileLinkFilter;
});
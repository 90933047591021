define('client/components/research/graph-manager', ['exports', 'ember-data', 'client/mixins/models/query-consumer'], function (exports, _emberData, _queryConsumer) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_queryConsumer.default, {
    tagName: null,

    author: null,
    searmTerm: null,

    socialCounts: Ember.computed("searchTerm", "author.id", "publication.id", "profileService.profile.id", function () {
      var authorId = this.get("author.id"),
          pubId = this.get("publication.id");
      if (authorId || pubId) {
        var query = this.queryByName("research/graph");
        return query.load();
      } else {
        // this is a never resolving promise to show loading
        return _emberData.default.PromiseObject.create({ promise: new Ember.RSVP.Promise(function () {}) });
      }
    })

  });
});
define('client/helpers/tree-view-search', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.treeViewSearch = treeViewSearch;

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
        arr2[i] = arr[i];
      }

      return arr2;
    } else {
      return Array.from(arr);
    }
  }

  function treeViewSearch(arr, term) {
    var parentIds = [];

    function filter(arr, term) {
      var matches = [];
      if (!Array.isArray(arr)) return matches;

      arr.forEach(function (i) {
        if (i.label.toLowerCase().includes(term.toLowerCase())) {
          matches.push(i);
        } else {
          var childResults = filter(i.children, term);
          if (childResults.length) {
            parentIds.push(i.id);
            matches.push(Object.assign({}, i, { children: childResults }));
          }
        }
      });

      return matches;
    }
    var results = filter(arr, term);

    return { results: results, parentIds: parentIds };
  }

  exports.default = Ember.Helper.helper(function (params) {
    return treeViewSearch.apply(undefined, _toConsumableArray(params));
  });
});
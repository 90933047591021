define('client/models/insight-rule', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var RULE_TYPE_TO_COMPONENT = {
    TopRule: 'insights/top-results',
    IntervalRule: 'insights/results-over-time',
    VSCompetitorRule: 'insights/results-vs-competitor',
    CompetitorTopRule: 'insights/top-results',
    CompetitorIntervalRule: 'insights/results-over-time'
  };

  var InsightRule = _emberData.default.Model.extend({
    metric: _emberData.default.attr("string"),
    ruleName: _emberData.default.attr("string"),
    ruleType: _emberData.default.attr("string"),

    component: Ember.computed('ruleType', function () {
      return RULE_TYPE_TO_COMPONENT[this.get('ruleType')];
    })

  });

  exports.default = InsightRule;
});
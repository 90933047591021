define('client/components/impact-score-productname', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var ImpactScoreProductnameComponent = Ember.Component.extend({

    store: Ember.inject.service(),
    modal: Ember.inject.service(),

    intialArray: Ember.computed.alias('productNames'),
    newArray: [],
    delArray: [],

    impactProductName: Ember.computed('profile', function () {
      return this.get("profile.profileImpactScoreSetting");
    }),

    actions: {
      addFromSearch: function addFromSearch(product) {
        this.get("productNames").pushObject(product);
        this.get("newArray").pushObject(product);
      },
      removeProduct: function removeProduct(product) {
        if (this.get("intialArray").includes(product) == true) {
          this.get("delArray").pushObject(product);
        }
        this.get("newArray").removeObject(product);
        this.get("productNames").removeObject(product);
      },
      addProduct: function addProduct() {
        var newAr = this.newArray;
        var s = this.store;
        var profile_iss = this.impactProductName;
        this.productNames.forEach(function (item) {
          if (newAr.includes(item) == true) {
            var a = s.createRecord("impact-score-product-name", {
              name: item,
              profileImpactScoreSetting: profile_iss
            });
            a.save();
          }
        });
        newAr.length = 0;
        var delAr = this.delArray;
        var productnames = this.store.peekAll('impact-score-product-name');
        productnames.forEach(function (item) {
          if (delAr.includes(item.name) == true) {
            item.destroyRecord();
          }
        });

        this.get('modal').open('common/ui-confirm', {
          prompt: 'Your changes are saved and we are processing your request.',
          ok: 'OK',
          okType: "danger",
          showCancel: false
        });
      }
    }
  });

  exports.default = ImpactScoreProductnameComponent;
});
define('client/components/article-widget', ['exports', 'client/components/simple-widget'], function (exports, _simpleWidget) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var ArticleWidgetComponent = _simpleWidget.default.extend({
    showLinkedIn: true,
    showNews: true,
    showTelevision: true,
    showRadio: true,
    showPodcast: true,
    showYoutubeBc: true,
    showPrint: true,
    title: "Coverage",

    action: "toContextCoverage",

    classNames: ['article-widget'],

    iconClass: "db-widget-icon-article",

    text: Ember.computed("profile.name", function () {
      return Ember.String.htmlSafe('<p>Total number of news and/or blog posts written by or about ' + this.get("profile.name") + '. </p> <p > This figure will reflect any filters applied or omitted. </p>');
    }),

    value: Ember.computed.alias("content.articles")
  });

  exports.default = ArticleWidgetComponent;
});
define('client/components/common/ui-calendar', ['exports', 'moment'], function (exports, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['ui-calendar', 'form-control'],
    classNameBindings: ['disabled:ui-calendar-disabled'],
    disabled: false,

    align: "right",

    // Default current time
    date: (0, _moment.default)(),
    dateFormat: null,
    endOfTime: new Date(),
    closeOnClick: false,

    // event sent when date is selected
    onDateSelected: function onDateSelected() {},


    actions: {
      onSelect: function onSelect(date) {
        this.set('date', date);
        this.onDateSelected(date);
        if (!this.get("closeOnClick")) {
          this.$(".dropdown-toggle").click();
        }
      }
    }
  });
});
define('client/components/reports/tile-amplification', ['exports', 'client/components/reports/tile-base', 'client/utils/twitter-settings'], function (exports, _tileBase, _twitterSettings) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _tileBase.default.extend({
    classNameBindings: [":report-tile-amplification"],

    showTwitterDisclaimer: Ember.computed('fromDate', function () {
      return this.get('fromDate') < _twitterSettings.default.GNIP_START_DATE;
    }),
    countsEndDate: _twitterSettings.default.COUNTS_END_DATE,
    gnipStartDate: _twitterSettings.default.GNIP_START_DATE

  });
});
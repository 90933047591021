define('client/routes/settings/user-management', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    titleToken: 'User Management',
    store: Ember.inject.service(),
    model: function model() {
      return this.store.findAll("account-user");
    }
  });
});
define('client/helpers/go-back', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Helper.extend({
    router: Ember.inject.service(),

    compute: function compute(params) {
      return function () {
        return window.history.back();
      };
    }
  });
});
define('client/models/hidden-campaign-per', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var HiddenCampaignPer = _emberData.default.Model.extend({
    url: _emberData.default.attr('string'),
    profile: _emberData.default.belongsTo("profile"),
    timestamp: _emberData.default.attr("isodate"),
    ajax: Ember.inject.service('ajax'),

    unhideEntity: function unhideEntity(campaign_id) {
      return this.get('ajax').request('/v2/api/hidden_campaign_pers/' + this.get('id'), {
        data: {
          campaign_id: campaign_id
        },
        method: 'DELETE'
      });
    }
  });

  HiddenCampaignPer.pathForType = "hidden_campaign_pers";

  exports.default = HiddenCampaignPer;
});
define('client/models/competitor', ['exports', 'client/models/base-profile', 'ember-data'], function (exports, _baseProfile, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var Competitor = _baseProfile.default.extend({
    useOriginalAdapter: false,
    type: "competitor",
    tags: [],
    isCompetitor: true,

    profile_ids: _emberData.default.attr("array"),
    keywords: _emberData.default.hasMany("keyword"),
    messages: _emberData.default.hasMany("message"),
    isAlreadyCompetitor: _emberData.default.attr('boolean'),

    profile: _emberData.default.belongsTo('profile', { inverse: null }),
    profileId: Ember.computed.alias("id")

  });
  Competitor.pathForType = "competitors";

  exports.default = Competitor;
});
define('client/components/saved-searches-list', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['saved-searches'],
    store: Ember.inject.service(),
    isEditing: false,
    editable: false,
    editedSearch: null,
    useSearch: function useSearch() {},


    savedSearches: Ember.computed.alias("profile.savedSearches"),

    actions: {

      editSearch: function editSearch(search) {
        this.send("openSaveSearch");
        search.set("isEditing", true);
        this.forceSet("editedSearch.isEditing", false);
        this.set("editedSearch", search);
      },

      saveSearch: function saveSearch(search, isPrivate) {
        var _this = this;

        search.set("profile_id", +this.get("profile.id"));
        search.set("user_id", isPrivate ? this.get("user.id") : null);
        var success = function success(search) {
          _this.get("profile").notifyPropertyChange("savedSearches");
        };
        search.save().then(success, function () {});
      },

      removeSearch: function removeSearch(search) {
        search.destroyRecord();
      },

      useSearch: function useSearch(search) {
        this.useSearch(search);
      },


      openSaveSearch: function openSaveSearch() {
        var store = this.get('store');
        var search = store.createRecord("saved-search", {
          term: this.get("searchTerm")
        });
        search.set("isEditing", true);
        this.forceSet("editedSearch.isEditing", false);
        this.set("editedSearch", search);
        this.set("isEditing", true);

        Ember.run.schedule("afterRender", this, function () {
          if (this.isDestroyed) {
            return;
          }
          this.$(".save-search input").focus();
        });
      }

    }

  });
});
define('client/components/reports/rich-text-header', ['exports', 'client/components/reports/rich-text', 'client/mixins/rich-text-bold-default', 'client/models/report-item'], function (exports, _richText, _richTextBoldDefault, _reportItem) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _richText.default.extend(_richTextBoldDefault.default, {
    layoutName: 'components/reports/rich-text',
    selectedFontSize: '50px',
    text: (0, _reportItem.aliasWithDefault)('text', '<div style="font-family: Helvetica, Arial; font-size: 50px; font-weight: bold;">\n    </div>'),
    placeholder: '\n    <div style="font-family: Helvetica, Arial; font-size: 50px; font-weight: bold;">\n      HEADER\n    </div>'
  });
});
define('client/controllers/plan/pitch/show', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    allQueryParams: {},

    authorsSort: ['lastName:asc'],
    authors: Ember.computed.sort('model.authors', 'authorsSort'),

    actions: {
      onCancel: function onCancel() {
        this.transitionToRoute("plan.pitch");
      },
      goToAuthor: function goToAuthor(author, openNotes) {
        this.transitionToRoute('plan.pitch.show.author', author.get('id'), { queryParams: { openNotes: openNotes } });
      }
    }

  });
});
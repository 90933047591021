define('client/components/date-range-quick-select', ['exports', 'client/utils/date-format'], function (exports, _dateFormat2) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: 'ul',
    classNames: ['date-range-quick-select'],

    startDate: null,
    fromDate: null,
    toDate: null,
    from: Ember.computed('fromDate', function () {
      return (0, _dateFormat2.default)(this.get('fromDate'));
    }),
    to: Ember.computed('toDate', function () {
      return (0, _dateFormat2.default)(this.get('toDate'));
    }),

    todayDate: Ember.computed(function () {
      // Rounded todays date
      return d3.time.day(new Date());
    }),

    today: Ember.computed('todayDate', function () {
      return (0, _dateFormat2.default)(this.get('todayDate'));
    }),

    yesterday: Ember.computed('todayDate', function () {
      return (0, _dateFormat2.default)(d3.time.day.offset(this.get('todayDate'), -1));
    }),

    lastWeekFromDate: Ember.computed('todayDate', function () {
      var today = this.get('todayDate');
      // We want Monday based weeks
      var thisWeekFrom = d3.time.monday(today);
      return d3.time.monday.offset(thisWeekFrom, -1);
    }),

    lastWeekFrom: Ember.computed('lastWeekFromDate', function () {
      return (0, _dateFormat2.default)(this.get('lastWeekFromDate'));
    }),

    lastWeekToDate: Ember.computed('lastWeekFromDate', function () {
      var from = this.get('lastWeekFromDate');
      return d3.time.day.offset(from, 6);
    }),

    lastWeekTo: Ember.computed('lastWeekToDate', function () {
      return (0, _dateFormat2.default)(this.get('lastWeekToDate'));
    }),

    lastSevenFromDate: Ember.computed('todayDate', function () {
      var today = this.get('todayDate');
      return d3.time.day.offset(today, -6);
    }),

    lastSevenFrom: Ember.computed('lastSevenFrom', function () {
      return (0, _dateFormat2.default)(this.get('lastSevenFromDate'));
    }),

    lastMonthFromDate: Ember.computed('todayDate', function () {
      var today = this.get('todayDate');
      var thisMonthFrom = d3.time.month(today);
      return d3.time.month.offset(thisMonthFrom, -1);
    }),

    lastMonthFrom: Ember.computed('lastMonthFromDate', function () {
      return (0, _dateFormat2.default)(this.get('lastMonthFromDate'));
    }),

    lastMonthToDate: Ember.computed('lastMonthFromDate', function () {
      var from = this.get('lastMonthFromDate');
      var nextMonth = d3.time.month.offset(from, 1);
      return d3.time.day.offset(nextMonth, -1);
    }),

    lastMonthTo: Ember.computed('lastMonthToDate', function () {
      return (0, _dateFormat2.default)(this.get('lastMonthToDate'));
    }),

    lastThirtyFromDate: Ember.computed('todayDate', function () {
      var today = this.get('todayDate');
      return d3.time.day.offset(today, -29);
    }),

    lastSixtyFromDate: Ember.computed('todayDate', function () {
      var today = this.get('todayDate');
      return d3.time.day.offset(today, -59);
    }),

    lastNinetyFromDate: Ember.computed('todayDate', function () {
      var today = this.get('todayDate');
      return d3.time.day.offset(today, -89);
    }),

    lastThirtyFrom: Ember.computed('lastThirtyFrom', function () {
      return (0, _dateFormat2.default)(this.get('lastThirtyFromDate'));
    }),

    lastSixtyFrom: Ember.computed('lastSixtyFrom', function () {
      return (0, _dateFormat2.default)(this.get('lastSixtyFromDate'));
    }),

    lastNinetyFrom: Ember.computed('lastNinetyFrom', function () {
      return (0, _dateFormat2.default)(this.get('lastNinetyFromDate'));
    }),

    allFromDate: Ember.computed('startDate', function () {
      return this.get('startDate');
    }),

    allFrom: Ember.computed('allFromDate', function () {
      return (0, _dateFormat2.default)(this.get('allFromDate'));
    })

  });
});
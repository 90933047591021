define("client/components/common/ui-nav-left/content", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNameBindings: [":ui-nav-left-menu-content"],
    tagName: "ul"
  });
});
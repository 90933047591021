define('client/components/graph-pie', ['exports', 'client/mixins/graph-legend', 'client/mixins/graph-viewport', 'client/mixins/graph-tooltip', 'client/mixins/graph-title', 'client/utils/percent-data-points'], function (exports, _graphLegend, _graphViewport, _graphTooltip, _graphTitle, _percentDataPoints) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var ReportGroupPieGraphComponent = Ember.Component.extend(_graphLegend.default, _graphViewport.default, _graphTooltip.default, _graphTitle.default, {
    classNames: ['graph-pie'],

    // ----------------------------------------------------------------------------
    // Data Processing
    // ----------------------------------------------------------------------------

    // Take data API and format for this specific chart
    formattedData: Ember.computed('data', function () {
      var data = this.get('data');
      if (Ember.isEmpty(data)) {
        return [];
      }
      var formattedData = data.map(function (d) {
        return {
          label: d.labels[0],
          color: d.color,
          value: d.values[0]
        };
      }).compact();
      var counts = formattedData.reduce(function (all, d) {
        if (d.label in all) {
          all[d.label]++;
        } else {
          all[d.label] = 1;
        }
        return all;
      }, {});

      var altered = {};
      formattedData.forEach(function (d) {
        var label = d.label;
        if (counts[label] > 1) {
          if (label in altered) {
            altered[label]++;
          } else {
            altered[label] = 1;
          }
          d.label = label + ' (' + altered[label] + ')';
        }
      });
      return formattedData;
    }),

    //Implementation of https://en.wikipedia.org/wiki/Largest_remainder_method
    getLargestRemainder: function getLargestRemainder(values, desiredSum) {
      if (Ember.isEmpty(values)) {
        return [];
      }
      var diff, i, parts, sum;
      sum = 0;
      parts = values.map(function (item, i) {
        var int;
        int = item | 0;
        sum += int;
        return {
          integer: int,
          decimal: item % 1,
          originalIndex: i
        };
      });
      if (sum !== desiredSum) {
        parts = parts.sortBy('decimal').reverse();
        diff = desiredSum - sum;
        i = 0;
        while (i < diff) {
          parts[i].integer++;
          i++;
        }
      }
      return parts.sortBy('originalIndex').mapBy('integer');
    },

    finishedData: Ember.computed('formattedData', function () {
      if (this.get('hasTooManyDataPoints')) {
        return [];
      }
      var data = this.get('formattedData');
      var total = data.reduce(function (t, d) {
        return t + d.value;
      }, 0);
      var percents = total > 0 ? data.map(function (d) {
        return d.value / total * 100;
      }) : [];
      var fixedPercents = (0, _percentDataPoints.getLargestRemainder)(percents, 100);

      // Add percent to all values
      return data.map(function (d, i) {
        return Ember.merge(Ember.merge({}, d), {
          percent: fixedPercents[i] / 100
        });
      });
    }),

    // ----------------------------------------------------------------------------
    // Pie Options
    // ----------------------------------------------------------------------------

    // Minimum height of the whole chart, including padding
    defaultOuterHeight: 500,

    // Data values on pie slices
    showDataValues: true,
    // Can be actual value or percent and should match tooltip
    showDataValuesAs: 'percent',

    graphicPadLeft: 10,
    graphicPadTop: 10,
    graphicPadBottom: 10,
    yAxisWidth: 0,
    xAxisHeight: 0,
    hasYAxisLabel: false,
    hasXAxisLabel: false,
    reducedPadding: false,

    init: function init() {
      this._super.apply(this, arguments);
      if (this.get('reducedPadding')) {
        this.setProperties({
          graphicPadLeft: 10,
          graphicPadTop: 5,
          graphicPadBottom: 10
        });
      }
    },


    // Pie overrides the default behavior of how the viewport is positioned relative
    // to the legend, in that the legend is vertical and offsets the left of the
    // viewport, rather than being horizontally aligned and offsetting the top.
    graphicTop: Ember.computed(function () {
      var pad = this.get('graphicPadTop');
      if (this.get('hasTitle')) {
        pad += this.get('titleTop');
      }
      return pad;
    }).volatile(),

    graphicLeft: Ember.computed.alias('graphicPadLeft'),
    isDonut: false,

    // ----------------------------------------------------------------------------
    // Layout
    // ----------------------------------------------------------------------------

    // ----------------------------------------------------------------------------
    // Ticks and Scales
    // ----------------------------------------------------------------------------
    maxNumberOfSlices: 18,
    hasTooManyDataPoints: Ember.computed(function () {
      return this.get('formattedData.length') > this.get('maxNumberOfSlices');
    }).volatile(),

    // ----------------------------------------------------------------------------
    // Styles
    // ----------------------------------------------------------------------------

    // ----------------------------------------------------------------------------
    // Drawing Functions
    // ----------------------------------------------------------------------------
    updateGraph: function updateGraph() {
      this.updateGraphic();
      this.updateDropShadow();
    },

    updateDropShadow: function updateDropShadow() {
      // From (http://bl.ocks.org/cpbotha/5200394)
      // filters go in defs element
      var svg = d3.select(this.$('svg')[0]);
      var defs = svg.select('defs');
      if (defs.empty()) {
        defs = svg.append('defs');
      }

      var filter = defs.select('filter');
      if (filter.empty()) {
        filter = defs.append('filter').attr({
          id: 'drop-shadow',
          height: '130%',
          width: '130%'
        });

        var colorFilter = filter.append('feComponentTransfer').attr('in', 'SourceAlpha');
        colorFilter.append('feFuncR').attr('type', 'discrete').attr('tableValues', 0.5);
        colorFilter.append('feFuncG').attr('type', 'discrete').attr('tableValues', 0.5);
        colorFilter.append('feFuncB').attr('type', 'discrete').attr('tableValues', 0.5);

        filter.append('feGaussianBlur').attr('stdDeviation', 5);

        filter.append('feOffset').attr('dx', 3).attr('dy', 3).attr('result', 'shadow');

        filter.append('feComposite').attr('in', 'SourceGraphic').attr('in2', 'shadow').attr('operator', 'over');
      }
    },


    updateGraphic: function updateGraphic() {
      var data = this.get('finishedData').filter(function (d) {
        return d.value > 0;
      });
      var width = this.get('graphicWidth');
      var height = this.get('graphicHeight');
      var isDonut = this.get('isDonut');
      var backgroundRadius = d3.min([width, height]) / 2;
      var outerRadius = backgroundRadius - 3;
      var hoverRadiusAdd = 10;
      var innerRadius = isDonut ? outerRadius / 3 : data.length > 1 ? 1.5 : 0;

      var padAngle = 0.02;
      var pie = d3.layout.pie().value(function (d) {
        return d.value;
      }).padAngle(padAngle);

      var arc = d3.svg.arc().padRadius(outerRadius).innerRadius(innerRadius);

      var pieLayer = this.get('viewport').selectAll('g.pie-layer').data(['pie-layer']);
      // Background circle
      pieLayer.enter().append('g').attr('class', 'pie-layer').append('circle').style('filter', 'url(#drop-shadow)').attr({
        'class': 'pie-background',
        cx: 0,
        cy: 0,
        fill: '#fff'
      });
      pieLayer.select('circle.pie-background').attr('r', backgroundRadius);

      pieLayer.attr('transform', 'translate(' + backgroundRadius + ', ' + height / 2 + ')');

      // We need to get some layers for our graph so that the labels are always
      // rendered above the slices
      var addOrSelectLayer = function addOrSelectLayer(layerClass) {
        var selectLayer = pieLayer.select('g.' + layerClass);
        if (selectLayer.empty()) {
          selectLayer = pieLayer.append('g').attr('class', layerClass);
        }
        return selectLayer;
      };
      var pieSlicesLayer = addOrSelectLayer('pie-slices-layer');
      var pieLabelsLayer = addOrSelectLayer('pie-labels-layer');

      var pieSlices = pieSlicesLayer.selectAll('path').data(pie(data), function (d) {
        return d.data.label;
      });

      pieSlices.enter().append('path');

      var handleMouseOver = this.setupPieSliceHover(true, arc, outerRadius, 0);
      var handleMouseOut = this.setupPieSliceHover(false, arc, outerRadius - hoverRadiusAdd, 150);

      pieSlices.each(function (d) {
        return d.outerRadius = outerRadius - hoverRadiusAdd;
      }).attr({
        d: arc,
        fill: function fill(d) {
          return d.data.color;
        }
      }).on('mouseover', handleMouseOver).on('mouseout', handleMouseOut);

      // transition here
      pieSlices.exit().remove();

      // Label values for pie slices
      var labelFormatter = this.get('labelFormatter');
      var labelValues = this.get('showDataValues') ? pie(data) : [];
      var labels = pieLabelsLayer.selectAll('text.pie-label').data(labelValues, function (d) {
        return d.data.label;
      });
      labels.enter().append('text').attr('class', 'pie-label');
      labels.exit().remove();
      labels.each(function (d) {
        return d.outerRadius = outerRadius - hoverRadiusAdd;
      }).attr({
        transform: function transform(d) {
          return 'translate(' + arc.centroid(d) + ')';
        },
        dy: '.35em',
        opacity: function opacity(d) {
          var area = (d.endAngle - d.startAngle) * (outerRadius - hoverRadiusAdd - innerRadius);
          return area > 20 ? 1 : 0;
        },
        'text-anchor': 'middle'
      }).text(function (d) {
        return labelFormatter(d.data);
      });
    },

    getTooltipRows: function getTooltipRows(values) {
      var items = this.get('legendItems').filter(function (i) {
        return values.findBy('label', i.label);
      });
      var format = this.get('labelFormatter');
      return {
        rows: items.map(function (i) {
          var value = values.findBy('label', i.label);
          // For pie graphs, convert the large numeric value to a
          // humanized comma separated numeric value
          var graphValue = value.value.toFixed(2);
          var localizedGraphValue = parseFloat(graphValue || 0).toLocaleString();
          return {
            color: i.fill,
            label: i.label,
            value: localizedGraphValue
          };
        })
      };
    },


    labelFormatter: Ember.computed('valueLabelFormatter', 'showDataValuesAs', function () {
      var showAs = this.get('showDataValuesAs');
      var valueFormatter = this.get('valueLabelFormatter');
      var getLabelPercent = function getLabelPercent(d) {
        //const ratio = (d.endAngle - d.startAngle) / (2 * Math.PI);
        var ratio = d.percent;
        return d3.format('%')(ratio);
      };
      if (showAs === 'percent') {
        return getLabelPercent;
      } else if (showAs === 'actual') {
        return function (d) {
          return valueFormatter(d.value);
        };
      } else {
        return function (d) {
          return valueFormatter(d.value) + ' (' + getLabelPercent(d) + ')';
        };
      }
    }),

    mouseOverDataPoint: null,

    setupPieSliceHover: function setupPieSliceHover(isHover, arc, toRadius, delay) {
      var graph = this;
      return function (d) {
        if (isHover) {
          graph.set('mouseOverDataPoint', d.data);
        } else {
          graph.set('mouseOverDataPoint', null);
        }
        // Animate slice
        d3.select(this).transition().delay(delay).attrTween('d', function (d) {
          var i = d3.interpolate(d.outerRadius, toRadius);
          return function (t) {
            d.outerRadius = i(t);
            return arc(d);
          };
        });
      };
    },

    // ----------------------------------------------------------------------------
    // Interaction
    // ----------------------------------------------------------------------------
    // For pie we cheat and just see if we already triggered a hover over
    // a pie slice
    isMousePositionWithinRange: function isMousePositionWithinRange() {
      return !Ember.isEmpty(this.get('mouseOverDataPoint'));
    },

    getTooltipInfo: function getTooltipInfo() {
      var d = this.get('mouseOverDataPoint');
      if (!d) {
        return null;
      }
      return this.getTooltipRows([d]);
    },
    getDrilldownInfo: function getDrilldownInfo() {
      var d = this.get('mouseOverDataPoint');
      if (!d) {
        return null;
      }
      return d.label;
    },
    getDataAtMouse: function getDataAtMouse() {
      //we return all data because:
      //-donut/pie charts are considered as "series" type of chart, so we return
      //-interactive reports needs the whole array so the dropdown works
      return { values: this.get('finishedData') };
    },


    // ----------------------------------------------------------------------------
    // Legend Configuration
    // ----------------------------------------------------------------------------
    hasLegend: true,
    legendItemNames: Ember.computed('finishedData', function () {
      return this.get('finishedData').mapBy('label');
    }),
    numLegendItemsPerRow: 1,
    maxLegendItemWidth: Ember.computed(function () {
      var maxWidth = 135;
      if (this.get('hasTitle')) {
        maxWidth += this.get('titleTop');
      }
      return maxWidth;
    }).volatile(),
    legendWidth: Ember.computed(function () {
      var itemWidth = null;
      // Get the maximum width legend item (items must already be drawn)
      // We draw first and then position after
      // The +1 is a hack to disallow trimming for text that fit correctly
      var legendLabels = this.get('legend').selectAll('.legend-item text');
      if (!legendLabels.empty()) {
        var widths = legendLabels[0].map(function (node) {
          return node.getBBox().width + 1;
        });
        itemWidth = d3.max(widths) + this.get('legendOutsideLabelSpace');
      }

      var maxWidth = this.get('maxLegendItemWidth');
      if (itemWidth > maxWidth) {
        itemWidth = maxWidth;
      }
      return itemWidth;
    }).volatile(),
    legendTop: Ember.computed(function () {
      return this.get('svgHeight') - this.get('legendHeight');
    }).volatile(),
    legendLeft: Ember.computed(function () {
      return this.get('svgWidth') - this.get('legendWidth');
    }).volatile(),

    graphicPadRight: Ember.computed("legendWidth", function () {
      return (this.get("legendWidth") || 0) + 20;
    }).volatile()
  });

  exports.default = ReportGroupPieGraphComponent;
});
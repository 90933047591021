define('client/components/reports/toolbar-item', ['exports', 'client/mixins/draggable'], function (exports, _draggable) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_draggable.default, {
    classNames: ['new-report-tool', 'report-base'],

    onDoubleClick: function onDoubleClick() {},
    dragStart: function dragStart(e) {
      if (this.get("reportService.isEdit") || this.get("reportService.isEditText") || this.get("reportService.isReadOnly")) {
        return false;
      }
      if (this.get("isEditing")) {
        return false;
      }
      if (e.originalEvent.offsetY < this.get("gutter")) {
        e.preventDefault();
        return false;
      }

      this.set("isEditing", false);
      this._super(e);
    },
    doubleClick: function doubleClick() {
      this.onDoubleClick(Ember.Object.create({
        configName: this.get('configName'),
        colspan: this.get('minColspan'),
        rowspan: this.get('minRowspan')
      }));
    }
  });
});
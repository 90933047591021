define('client/models/publication', ['exports', 'ember-data', 'client/mixins/models/broadcast', 'client/mixins/models/print_publication'], function (exports, _emberData, _broadcast, _print_publication) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var Publication = _emberData.default.Model.extend(_broadcast.default, _print_publication.default, {
    name: Ember.computed.alias("id"),

    totalSocialCount: _emberData.default.attr("number"),
    docCount: _emberData.default.attr("number"),
    visitors: _emberData.default.attr("number", { defaultValue: 0 }),
    interactions: _emberData.default.attr("number", { defaultValue: 0 }),
    reach: _emberData.default.attr("number", { defaultValue: 0 }),
    impactScore: _emberData.default.attr("number", { defaultValue: 0 }),
    readership: _emberData.default.attr("number", { defaultValue: 0 }),
    revenue: _emberData.default.attr("number", { defaultValue: 0 }),
    type: _emberData.default.attr("string"),
    reputation: _emberData.default.attr("string"),
    publicationUrl: _emberData.default.attr("string"),
    whitelistTags: _emberData.default.hasMany("whitelist-tag"),
    logoUrl: _emberData.default.attr("string"),

    hasWhitelists: Ember.computed('whitelistTags.length', function () {
      return this.get('whitelistTags.length') > 0;
    })
  });

  exports.default = Publication;
});
define('client/models/export-request', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var ExportRequest = _emberData.default.Model.extend({

    profile: _emberData.default.belongsTo('profile'),
    campaign: _emberData.default.belongsTo("campaign"),
    filterSet: _emberData.default.belongsTo('filter-set'),
    user: _emberData.default.belongsTo("user"),
    userIsSuperAdmin: _emberData.default.attr('boolean', { default: false }),
    user_name: _emberData.default.attr("string"),
    date_start: _emberData.default.attr('isodatetime'),
    dateStart: Ember.computed.alias('date_start'),
    date_end: _emberData.default.attr('isodatetime'),
    dateEnd: Ember.computed.alias('date_end'),
    created_at: _emberData.default.attr("date"),
    updated_at: _emberData.default.attr("date"),
    status_cd: _emberData.default.attr("number"),
    options: _emberData.default.attr("object"),
    maxArticles: _emberData.default.attr('number'),
    sendEmail: _emberData.default.attr('boolean', { default: false }),
    generatorType: _emberData.default.attr("string"),
    generatorId: _emberData.default.attr("number"),

    userNameFormatted: Ember.computed('user.name', 'user_name', function () {
      return this.get('user.name') || this.get('user_name');
    }),

    timeSinceCreated: Ember.computed(function () {
      return moment(this.get('created_at')).fromNow();
    }).volatile(),

    statusFormatted: Ember.computed('status_cd', function () {
      var STATUSES = ["Pending", "Complete", "Error", "Expired"];
      return STATUSES[this.get('status_cd')];
    }),

    canDownload: Ember.computed.equal('status_cd', 1),
    isErrorState: Ember.computed.equal('status_cd', 2),

    url: Ember.computed('id', 'profile', 'user', function () {
      return "/v2/api/export_downloads?" + $.param({
        profile_id: this.get('profile.id'),
        id: this.get('id')
      });
    })

  });
  ExportRequest.pathForType = "export_requests";

  exports.default = ExportRequest;
});
define('client/components/mobile-article-search', ['exports', 'client/utils/clean-quotes'], function (exports, _cleanQuotes) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['mobile-articles-search'],

    searchTerm: "",

    init: function init() {
      this._super.apply(this, arguments);
      this.set('searchTerm', (0, _cleanQuotes.default)(this.get('searchTerm')));
    },
    onToggle: function onToggle() {},
    onFocusIn: function onFocusIn() {},
    onFoucsOut: function onFoucsOut() {},
    onKeyUp: function onKeyUp() {},


    actions: {
      toggle: function toggle() {
        this.onToggle();
      },
      focusIn: function focusIn() {
        this.onFocusIn();
      },
      focusOut: function focusOut() {
        this.onFoucsOut();
      },
      keyUp: function keyUp() {
        this.onKeyUp();
      },
      change: function change() {
        this.set('searchTerm', (0, _cleanQuotes.default)(this.get('searchTerm')));
      }
    }
  });
});
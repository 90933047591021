define('client/components/research/social-graph', ['exports', 'client/components/research/article-graph'], function (exports, _articleGraph) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _articleGraph.default.extend({

    graphValueKeys: null,

    fbSharesAggType: "count",
    pinterestPinsAggType: "count",
    twitterRetweetsAggType: "count",

    data: Ember.computed("rebinnedData", function () {
      var counts = this.get("rebinnedData"),
          colorPalette = this.get('colorPalette');

      if (Ember.isEmpty(counts)) {
        return [];
      }
      return counts.map(function (c, i) {
        return [{
          labels: [c.date, "Facebook"],
          values: [c.fbTotal],
          color: colorPalette[0]
        }, {
          labels: [c.date, "Pinterest"],
          values: [c.pinterestPins],
          color: colorPalette[1]
        }];
      }).flatten();
    }),

    init: function init() {
      this._super.apply(this, arguments);
      this.set('graphValueKeys', this.get('graphValueKeys') || ["fbTotal", "pinterestPins"]);
    }
  });
});
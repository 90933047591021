define('client/components/admin-settings-onboarding-list-item', ['exports', 'client/models/profile'], function (exports, _profile) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var AdminSettingsOnboardingListItemComponent = Ember.Component.extend({
    tagName: 'li',
    classNames: ['admin-settings-onboarding-list-item'],
    title: '',
    stepNumber: 1,
    displayStepNumber: Ember.computed.oneWay('stepNumber'),

    formattedStepNumber: Ember.computed('stepNumber', function () {
      // Get a two digit zero padded number
      var s = '00' + this.get('displayStepNumber');
      return s.substr(s.length - 2, s.length);
    }),

    stepLink: Ember.computed('stepNumber', function () {
      return 'onboard.step-' + this.get('stepNumber');
    }),

    isLinkDisabled: Ember.computed.alias('isHidden'),

    // displayState set externally and can be 'enable', 'hide', 'disable'
    displayStateDefault: Ember.computed('stepNumber', function () {
      var defaultDisplayStates = _profile.default.DEFAULT_ONBOARDING_STATES;
      return defaultDisplayStates[this.get('stepNumber')] || 'enable';
    }),

    displayStateWithDefault: Ember.computed('displayState', 'displayStateDefault', {
      get: function get() {
        var state = this.get('displayState');
        if (state !== 'enable' && state !== 'hide' && state !== 'disable') {
          return this.get('displayStateDefault');
        }
        return state;
      },
      set: function set(key, value) {
        this.set('displayState', value);
        return value;
      }
    }),

    isDisabled: Ember.computed('displayStateWithDefault', {
      get: function get() {
        return this.get('displayStateWithDefault') === 'disable';
      },
      set: function set(key, value) {
        this.set('displayStateWithDefault', value ? 'disable' : 'enable');
        return value;
      }
    }),

    isHidden: Ember.computed('displayStateWithDefault', {
      get: function get() {
        return this.get('displayStateWithDefault') === 'hide';
      },
      set: function set(key, value) {
        this.set('displayStateWithDefault', value ? 'hide' : 'enable');
        return value;
      }
    })
  });

  exports.default = AdminSettingsOnboardingListItemComponent;
});
define('client/components/editable-title', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['editable-title'],
    value: null,
    title: null,
    titleOverride: null,
    disabled: false,
    inputTitle: Ember.computed.oneWay('titleOverride'),
    isEditing: false,
    allOtherTitles: Ember.computed('allTitles', 'titleOverride', function () {
      var title = this.get('titleOverride');
      return (this.get('allTitles') || []).filter(function (t) {
        return t !== title;
      });
    }),
    isInvalid: Ember.computed('allOtherTitles', 'inputTitle', function () {
      return this.get('allOtherTitles').includes(this.get('inputTitle'));
    }),
    // Currently only type of invalidity
    invalidMessage: 'Name already exists',

    actions: {
      startEditing: function startEditing() {
        this.set('isEditing', true);
      },

      doneEditing: function doneEditing() {
        var _this = this;

        if (!this.get('isInvalid')) {
          Ember.run.schedule('render', function () {

            _this.get('changeTitle')(_this.get('inputTitle'));
            _this.set('isEditing', false);
          });
        }
      }
    }
  });
});
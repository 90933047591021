define('client/serializers/article-summary', ['exports', 'active-model-adapter', 'client/mixins/serializers/has-endpoint-actions'], function (exports, _activeModelAdapter, _hasEndpointActions) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  // Article Summary serializer
  // The JSON root is always per and not article and all related articles are embedded.
  var ArticleSummarySerializer = _activeModelAdapter.ActiveModelSerializer.extend(_hasEndpointActions.default, {
    // TODO(data-2.0): Remove
    isNewSerializerAPI: true,

    endpointActions: {
      refreshSocialCounts: {
        normalize: function normalize(typeClass, hash) {
          var attrs = {};
          this._setIfPresent(attrs, 'facebook_count', hash.fb_total);
          this._setIfPresent(attrs, 'linkedin_count', hash.linkedin_shares);
          this._setIfPresent(attrs, 'gplus_count', hash.google_plusones);
          return attrs;
        }
      }
    },

    _setIfPresent: function _setIfPresent(attrs, key, value) {
      if (Ember.isNone(value)) {
        return attrs;
      }
      attrs[key] = value;
      return attrs;
    }
  });

  exports.default = ArticleSummarySerializer;
});
define('client/mixins/report-chart-color-ordered', ['exports', 'client/components/reports/base-widget', 'client/report-settings', 'client/utils/locations-by-region'], function (exports, _baseWidget, _reportSettings, _locationsByRegion) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var ReportChartColorOrderedMixin = Ember.Mixin.create({
    store: Ember.inject.service(),
    customColorsDisabled: Ember.computed.equal('item.groupDimension.firstObject', 'tone_label'),
    customColorsEnabled: Ember.computed.alias("item.customColorsEnabled"),

    showAppendColor: Ember.computed.lt('customColors.length', 10),
    showCustomColors: Ember.computed.equal('colorMode', 'custom'),
    showColorPicker: Ember.computed.notEmpty('colorToEdit'),
    // Never delete first color
    showDeleteColor: Ember.computed.gt('colorIndexToEdit', 0),

    // HACK: this should only be dependent on groupDimension and the other
    // properties should recompute themselves. Volatile is temporary since the
    // computing cost is low and the complexity of a full refactor was high.
    colorsByGroupDimension: Ember.computed('item.groupDimension.firstObject', 'item.labelDimension.firstObject', 'colorsByTone', 'colorsByCountryCode', 'colorsByDate', 'colorsByProfile', 'colorsByTargetMediaOutlets', 'colorsBySomeOtherOptions', 'colorsByYOY', function () {

      var groupDimension = this.get('item.groupDimension.firstObject') || this.get('item.labelDimension.firstObject');

      if (Ember.isEmpty(groupDimension)) {
        // Don't have a group dimension return a index map that always
        // picks the primary color
        return null;
      }

      switch (groupDimension) {
        case 'tone_label':
          return this.get('colorsByTone');
        case 'location':
          return this.get('colorsByCountryCode');
        case 'date':
          return this.get('colorsByDate');
        case 'prioryear':
          return this.get('colorsByYOY');
        case 'target_media_outlets':
          return this.get('colorsByTargetMediaOutlets');
        case 'profile':
          return this.get('colorsByProfile');
        default:
          return this.get('colorsBySomeOtherOptions');
      }
    }),

    /**
      @return {Object} Dictionary of tone Key and colorIndex value
        { [tone]: colorIndex }
    */
    colorsByTone: Ember.computed(function () {
      return {
        'Positive': 1,
        'Negative': 4,
        'Neutral': 10
      };
    }),

    /**
      @return {Object} Dictionary of countryCode Key and colorIndex value
        { [CountryCode]: colorIndex }
    */
    colorsByCountryCode: Ember.computed('item.locationFilterList.[]', function () {
      // only care about the ones we have

      return this.get('item.locationFilterList').reduce(function (colorMap, countryCode, colorIndex) {
        var id = parseInt(countryCode.replace('lcn_', ''));
        var country = _locationsByRegion.default.ALL.findBy('id', id);
        colorMap[country.name] = colorIndex;
        return colorMap;
      }, {});
    }),

    /**
      @return {Object} Dictionary of date Key and colorIndex value
        { [date]: colorIndex }
    */
    colorsByDate: Ember.computed('item.info.content', 'item.dataQuery.content', function () {
      var bins = this.get('item.info.content') || this.get('item.dataQuery.content');
      if (!bins) {
        return {};
      }
      return bins.map(function (bin) {
        return bin.labels[0];
      }).uniq().reduce(function (colorMap, date, colorIndex) {
        colorMap[date] = colorIndex;
        return colorMap;
      }, {});
    }),

    /**
      @return {Object} Dictionary of date Key and colorIndex value
        { [date]: colorIndex }
    */
    colorsByYOY: Ember.computed('item.info.formattedData', 'item.dataQuery.formattedData', function () {
      var groupDimension = this.get('item.groupDimension.firstObject') || this.get('item.labelDimension.firstObject');
      var groupOptions = this.get('item.' + groupDimension.camelize() + 'Options');
      var nextIndex = 0;
      return groupOptions.reduce(function (map, option) {
        map[Ember.get(option, "name") || ''] = nextIndex++;
        return map;
      }, {});
    }),

    colorsByTargetMediaOutlets: Ember.computed('item.whitelistTagIds.[]', 'item.profile.id', 'item.whitelistTags.[]', function () {
      var whitelistTagIds = this.get('item.whitelistTagIds');
      var whitelistTags = this.get('item.whitelistTags');
      var item = this.get("item");
      return whitelistTagIds.reduce(function (colorMap, whitelistTagId, colorIndex) {
        var tag = whitelistTags.findBy("id", whitelistTagId);

        if (tag) {
          colorMap[tag.get("name")] = colorIndex;
        }
        return colorMap;
      }, {});
    }),

    // this is tied to the profile, campaign, searchTerm, and a dynamic key for
    // options and filter list, so keeping this volatile
    colorsBySomeOtherOptions: Ember.computed('item.groupDimension.firstObject', 'item.groupDimension', 'item.labelDimension.firstObject', 'item.labelDimension', 'item.profile', 'item.profile.name',
    // Date dimension depends on rebinned data
    'rebinnedData',
    // Location is special
    'locationNameToColorIndexMap',
    // All filters
    'item.amplificationLabelFilterList.[]', 'item.campaignFilterList.[]', 'item.neoFilterList.[]', 'item.tagFilterList.[]', 'item.toneLabelFilterList.[]', 'item.contentTypeFilterList.[]', 'item.channelTypeFilterList.[]',
    // All Options
    'item.amplificationLabelOptions.[]', 'item.campaignOptions.[]', 'item.neoOptions.[]', 'item.tagOptions.[]', 'item.toneLabelOptions.[]', 'item.contentTypeOptions.[]', 'item.channelTypeOptions.[]', function () {
      var groupDimension = this.get('item.groupDimension.firstObject') || this.get('item.labelDimension.firstObject');
      var groupOptions = this.get('item.' + groupDimension.camelize() + 'Options');
      var groupFilterList = this.get('item.' + groupDimension.camelize() + 'FilterList');
      var colorMap = {},
          item = this.get("item");

      var nextIndex = 0;

      if (!Ember.isEmpty(groupOptions)) {
        colorMap = groupOptions.reduce(function (map, option) {
          if (!groupFilterList.includes(option.id)) {
            return map;
          }
          map[Ember.get(option, "name") || ''] = nextIndex++;
          return map;
        }, {});
      }
      return colorMap;
    }),

    colorsByProfile: Ember.computed('item.allProfiles.[]', 'item.allProfiles.@each.nonUniqueIdentifier', 'item.allProfiles.@each.displayName', 'item.allProfiles.@each.isActive', 'item.profile', 'item.profile.name', function () {

      var colorMap = {};
      // Always need to prepend the current profile to profile options
      var groupOptions = this.get('item.allProfiles').filterBy('isActive');
      var nextIndex = 0;

      if (!Ember.isEmpty(groupOptions)) {
        colorMap = groupOptions.reduce(function (map, option) {
          map[Ember.get(option, "displayName") || ''] = nextIndex++;
          return map;
        }, {});
      }
      return colorMap;
    }),

    colorScale: Ember.computed('colorsByGroupDimension', 'item.profile', 'item.profile.competitors.@each.{id,name}', 'item.groupDimension.firstObject', 'item.labelDimension.firstObject', "item.profile.stableCompetitorColors.@each.{competitorId,color}", 'colorPalette.[]', 'defaultColors.[]', 'colorMode', function () {
      var colorIndexMap = this.get('colorsByGroupDimension') || {};
      var groupDimension = this.get('item.groupDimension.firstObject');
      var labelDimension = this.get('item.labelDimension.firstObject');
      var competitorsColors = this.get("item.profile.stableCompetitorColors");

      var colorPalette = this.get('colorPalette'),
          defaultColorPalette = this.get('defaultColors'),
          isCustomColor = this.get('colorMode') === 'custom';
      if (!Ember.isArray(colorPalette)) {
        colorPalette = [colorPalette];
      }

      if (competitorsColors) {
        // get the competitors colors
        competitorsColors = competitorsColors.map(function (cc) {
          return {
            id: +cc.get("competitorId"),
            color: cc.get("color")
          };
        });
      }

      return function (data) {
        var dimension = groupDimension || labelDimension;
        var dataKey = data[dimension];

        if (dimension === "profile" && competitorsColors && typeof dataKey === 'string' && data.profileRaw) {

          var cc = competitorsColors.findBy("id", +data.profileRaw.profile);
          if (cc) {
            return cc.color;
          }
        }

        var colorIndex = colorIndexMap[dataKey];
        if (typeof colorIndex !== 'number') {
          console.warn('Chart color map failed! index,dataKey', colorIndex, dataKey);
          colorIndex = 0;
        }
        return colorPalette[colorIndex % colorPalette.length];
      };
    }),

    // Custom color palette code
    // 'default' - Default colors
    // 'custom' - User chooses colors
    _colorMode: Ember.computed.alias('item.colorMode'),

    colorMode: Ember.computed('_colorMode', 'customColorsDisabled', {
      get: function get() {
        if (this.get('customColorsDisabled')) {
          return 'default';
        }
        return this.get('_colorMode');
      },
      set: function set(key, mode) {
        if (this.get('customColorsDisabled')) {
          return 'default';
        }
        this.set('_colorMode', mode);
        return mode;
      }
    }),

    colorPalette: Ember.computed('colorMode', 'defaultColors.[]', 'customColors.[]', function () {
      var mode = this.get('colorMode') || "default";
      return this.get(mode + 'Colors');
    }),

    defaultColors: Ember.computed("item.page.report.profile.customColors", "customColorsDisabled", function () {
      var profile = this.get("item.page.report.profile");
      var colors = null;
      if (profile && !this.get("customColorsDisabled")) {
        profile = profile.get("profile") || profile;
        var customColors = profile.get("customColors");
        if (customColors && customColors.length > 0) {
          colors = customColors.map(function (val) {
            return val.hex;
          });
        }
      }
      if (!colors) {
        colors = [].concat(_reportSettings.DEFAULT_COLOR_SWATCHES);
      }
      // if a single custom color is added, just pad with the default colors
      if (colors.length === 1) {
        colors = colors.concat(_reportSettings.DEFAULT_COLOR_SWATCHES);
      }
      return colors;
    }),

    customColors: Ember.computed.alias("item.customColors"),

    // Need to provide index with color for swatch UI
    customColorSwatches: Ember.computed.map('customColors', function (hex, index) {
      return { index: index, hex: hex };
    }),

    // The user selects one of the colors to edit or null means not editing
    colorIndexToEdit: null,

    colorToEdit: Ember.computed('customColors.[]', 'colorIndexToEdit', {
      get: function get() {
        var index = this.get('colorIndexToEdit');
        var customColors = this.get('customColors');
        if (Ember.isEmpty(index)) {
          return null;
        }
        return customColors[index];
      },
      set: function set(k, v) {
        var index = this.get('colorIndexToEdit');
        var customColors = this.get('customColors');
        var newColors = customColors.slice();
        newColors[index] = '#' + v;
        this.set('customColors', newColors);
        return v;
      }
    }),

    saveCustomColor: Ember.observer('colorToEdit', function () {
      Ember.run.throttle(this, function () {
        this.send('saveItem');
      }, 100);
    }),

    actions: {
      deleteColor: function deleteColor(index) {
        // Never delete the first color
        if (index === 0) {
          return;
        }
        var customColors = this.get('customColors');
        var newColors = customColors.slice(0, index).concat(customColors.slice(index + 1));
        this.set('customColors', newColors);
        this.set('colorIndexToEdit', null);
      },
      appendColor: function appendColor() {
        var customColors = this.get('customColors');
        var defaults = this.get('defaultColors');
        var newColor = this.get('defaultColors')[(customColors.length - 1) % defaults.length];
        this.set('customColors', customColors.concat([newColor]));
        this.set('colorIndexToEdit', customColors.length);
      },
      selectColor: function selectColor(index) {
        this.set('colorIndexToEdit', this.get('colorIndexToEdit') === index ? null : index);
      }
    }
  });

  exports.default = ReportChartColorOrderedMixin;
});
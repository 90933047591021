define("client/components/research/topic-search", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ["research-topic-search", "d-flex"],
    selectedType: null,
    types: null,

    onSelectType: function onSelectType() {}
  });
});
define('client/components/popover-trigger-anchor', ['exports', 'client/components/popover-trigger'], function (exports, _popoverTrigger) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var PopoverTriggerAnchorComponent = _popoverTrigger.default.extend({
    tagName: 'a',
    attributeBindings: ['href', 'target'],
    href: "#",
    classNames: ['popover-trigger'],
    delay: 300,

    isMouseOverContentDidChange: Ember.observer('parentView.isMouseOverContent', function () {
      if (!this.get('parentView.isMouseOverContent')) {
        this.scheduleClose();
      }
    }),

    mouseEnter: function mouseEnter() {
      Ember.run.later(this, function () {
        this.set('isOpen', true);
      }, this.get('delay'));
    },

    mouseLeave: function mouseLeave() {
      this.scheduleClose();
    },

    scheduleClose: function scheduleClose() {
      Ember.run.later(this, function () {
        if (!this.get('parentView.isMouseOverContent')) {
          this.set('isOpen', false);
        }
      }, this.get('delay'));
    }
  });

  exports.default = PopoverTriggerAnchorComponent;
});
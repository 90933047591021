define('client/mixins/corporate', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var CorporateMixin = Ember.Mixin.create({
    needs: ['application', 'settings'],

    user: Ember.computed.alias('currentUser.content'),

    queryParams: ['type'],
    type: null,
    perType: null,

    sortDate: "DESC",
    sortStartDate: "",
    sortEndDate: "",
    sortUrl: "",
    sortPattern: "",
    sortName: "",
    sortProfile: "",
    sortUser: "",

    profile: Ember.computed.alias('profileService.profile'),

    _sorts: ["Date", "StartDate", "EndDate", "Url", "Pattern", "Name", "Profile", "User"],

    parent_id: null,

    parent: Ember.computed('parent_id', function () {
      var id = this.get('parent_id');
      if (!id || id === 'null') {
        return false;
      }
      return this.store.findRecord('profile', id);
    }),

    _paramsDidChange: function _paramsDidChange() {
      var type = this.get("type"),
          profile = this.get("profile.id"),
          perType = this.get("perType"),
          params = {
        sorts: {}
      };
      if (type && type != "null") {
        params.type = type;
      }

      if (perType && perType != "null") {
        params.per_type = perType;
      }

      if (profile) {
        params.profile_id = profile;
      }

      this.get("_sorts").forEach(function (sort) {
        var sortVal = this.get("sort" + sort);
        if (sortVal) {
          params.sorts[sort.toLowerCase()] = sortVal;
        }
      }, this);

      this.set("resources.search", params);
    },

    paramsDidChange: Ember.observer("type", "sortUrl", "sortPattern", "sortDate", "profile", "sortEndDate", "sortStartDate", "sortName", "perType", "sortProfile", "sortUser", function () {
      Ember.run.scheduleOnce("afterRender", this, "_paramsDidChange");
    }),

    actions: {
      removeSorts: function removeSorts() {
        this.beginPropertyChanges();
        this.get("_sorts").forEach(function (sort) {
          this.set("sort" + sort, null);
        }, this);
        this.endPropertyChanges();
      }
    }

  });

  exports.default = CorporateMixin;
});
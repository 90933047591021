define('client/components/article-list/tag-syndicates-addition', ['exports', 'ember-modal-service/components/modal', 'client/computed'], function (exports, _modal, _computed) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _modal.default.extend({
    classNames: ["tag-syndicates-addition"],
    size: (0, _computed.aliasWithDefault)("model.options.size", "sm"),
    options: Ember.computed.alias("model.options"),
    ignoreSyndicate: false,
    removeSyndicate: true,

    actions: {
      setIgnoreSyndicate: function setIgnoreSyndicate(checked) {
        this.set("ignoreSyndicate", checked);
      },
      unSetIgnoreSyndicate: function unSetIgnoreSyndicate(checked) {
        this.set("removeSyndicate", !checked);
      },
      applyTags: function applyTags() {
        this.options.tagArticle(this.options.article, this.options.tag, this.ignoreSyndicate);
        this.resolve();
      },
      removeTags: function removeTags() {
        this.options.untagArticleAction(this.options.article, this.options.tag, this.removeSyndicate);
        this.resolve();
      }
    }
  });
});
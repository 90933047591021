define('client/components/research/authors/author-filters', ['exports', 'ember-concurrency'], function (exports, _emberConcurrency) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ["author-filter"],

    ajax: Ember.inject.service(),
    listIsOpen: false,
    refresh: null,
    previousRefresh: null,
    disabled: false,
    tooltipText: null,
    filters: null,
    isLoading: false,

    applyFilters: function applyFilters() {},
    loadFilters: function loadFilters() {},
    init: function init() {
      this._super.apply(this, arguments);
      this.set('selectedFilters', Ember.A());
    },
    didReceiveAttrs: function didReceiveAttrs() {
      var _this = this;

      this._super.apply(this, arguments);
      if (Ember.isEmpty(this.get('selectedFilters'))) {
        var et = this.get('filters');
        if (et) {
          et.forEach(function (filter) {
            if (filter.get('isSelected')) {
              filter.set('isSelected', false);
            }
          });
        }
      }
      if (this.refresh !== this.previousRefresh) {
        this.previousRefresh = this.refresh;
        Ember.run.later(function () {
          if (_this.listIsOpen) {
            _this.get('loadFilters')();
          } else {
            _this.set("needsRefresh", true);
          }
        });
      }
    },
    addFilter: function addFilter(filter) {
      var filters = this.get('selectedFilters');
      filters.addObject(filter.name);
      this.get('applyFilters')(filters);
    },
    removeFilter: function removeFilter(filter) {
      var filters = this.get('selectedFilters').filter(function (item) {
        return item !== filter.name;
      });
      this.get('applyFilters')(filters);
    },


    actions: {
      onCancelTags: function onCancelTags() {
        this.set('searchTerm', null);
      },
      onToggleList: function onToggleList(open) {
        this.set("listIsOpen", open);
        if (open && Ember.isEmpty(this.get('filters')) || this.get("needsRefresh")) {
          this.set("needsRefresh", false);
          this.get('loadFilters')();
        }
      },
      onToggleAll: function onToggleAll(type, action) {
        if (action == 'clearAll') {
          this.set('selectedFilters', []);
        }
      },
      onToggleFilter: function onToggleFilter(filter) {
        if (filter.isSelected) {
          this.addFilter(filter);
        } else {
          this.removeFilter(filter);
        }
      }
    }
  });
});
define('client/models/company-sector', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var CompanySector = _emberData.default.Model.extend({
    name: _emberData.default.attr('string'),
    profiles: _emberData.default.hasMany('profile', { async: true }),
    parent: _emberData.default.belongsTo('company-sector', { inverse: null })
  });

  CompanySector.pathForType = "company_sectors";
  exports.default = CompanySector;
});
define('client/components/common/ui-interval-selector', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['ui-interval-selector', 'u-textCenter'],
    intervals: null,
    init: function init() {
      this._super.apply(this, arguments);
      this.set('intervals', this.get('intervals') || [{ id: 'day', name: 'Day' }, { id: 'week', name: 'Week' }, { id: 'month', name: 'Month' }, { id: 'quarter', name: 'Quarter' }, { id: 'year', name: 'Year' }]);
    },

    actions: {
      changeInterval: function changeInterval(intervalObject) {
        this.changeInterval(intervalObject.id);
      }
    }
  });
});
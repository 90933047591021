define('client/components/article-list/article-item-logo', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: '',
    content: null,
    isNlaArticle: Ember.computed("content", function () {
      return this.get('content.url').includes('nla.onclusive.com') || this.get('content.url').includes('nla-eclips.com');
    })
  });
});
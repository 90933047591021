define('client/components/research/tweets-by', ['exports', 'client/twitter'], function (exports, _twitter) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: "A",
    classNames: ["tweets-by", "twitter-timeline"],

    attributeBindings: ["href", "data-tweet-limit", "data-width", "data-height", "data-chrome"],

    "data-height": "300",
    "data-width": "520",
    "data-tweet-limit": 5,
    "data-chrome": "noheader nofooter noborders",

    href: Ember.computed("handle", function () {
      return 'https://twitter.com/' + this.get("handle");
    }),

    didInsertElement: function didInsertElement() {
      if (window.twttr && window.twttr.widgets) {
        window.twttr.widgets.load();
      }
    }
  });
});
define("client/utils/-coverage-to-title", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var COVERAGE_TO_TITLES = {
    "date": "articles",
    "potential customers": "potential customers",
    "visitors": "potential customers",
    "readership": "readership",
    "sales": "sales",
    "tone": "count",
    "fans": "fans",
    "pov": "score",
    "revenue": "revenue",
    "formulaic_revenue": "revenue",
    "twitter": "tweets",
    "facebook": "count",
    "linkedin": "shares",
    "google plus": "shares",
    "interaction": "intearction"
  };

  exports.default = COVERAGE_TO_TITLES;
});
define('client/components/reports/tile-base', ['exports', 'client/components/reports/base-widget', 'client/mixins/filter-controller'], function (exports, _baseWidget, _filterController) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var SIZES = [{
    name: "large",
    displayName: "Large",
    rowspan: 2,
    colspan: 2
  }, {
    name: "small",
    displayName: "Small",
    rowspan: 2,
    colspan: 1

  }];

  exports.default = _baseWidget.default.extend(_filterController.default, {
    classNames: ['report-tile'],

    editComponentName: "reports/edit-tile",

    dataRetrievalErrorMsg: '',

    sizes: SIZES,

    searchTerm: Ember.computed.alias("item.searchTerm"),
    activeProfile: Ember.computed.alias("item.activeProfile"),
    locationFilters: Ember.computed.alias("item.locationFilters"),
    defaultLocationFilters: Ember.computed.alias("item.defaultLocationFilters"),
    whitelistTagIds: Ember.computed.alias("item.whitelistTagIds"),
    channelFiltersMenu: Ember.computed.alias("item.channelFiltersMenu"),
    defaultContentFilters: Ember.computed.alias("item.defaultContentFilters"),
    contentFiltersMenu: Ember.computed.alias("item.contentFiltersMenu"),
    tagFiltersMenu: Ember.computed.alias("item.tagFiltersMenu"),

    // Noop on filters did change
    _filtersDidChange: function _filtersDidChange() {},


    promiseProxy: Ember.computed.alias("item.info"),
    info: Ember.computed.alias("promiseProxy")
  });
});
define("client/models/author-note", ["exports", "ember-data"], function (exports, _emberData) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    userId: _emberData.default.attr("string"),
    userName: _emberData.default.attr("string"),
    notable: _emberData.default.belongsTo('author', { inverse: 'authorNotes' }),
    date: _emberData.default.attr('date'),
    note: _emberData.default.attr('string'),
    priority: _emberData.default.attr("boolean")
  });
});
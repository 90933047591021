define('client/components/research/filter-sentiment', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    onApplyFilter: function onApplyFilter() {},


    actions: {
      onApplyFilter: function onApplyFilter() {
        this.onApplyFilter.apply(this, arguments);
      }
    }
  });
});
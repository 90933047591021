define('client/components/radio-button-input', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: 'input',
    type: 'radio',
    attributeBindings: ['type', 'checked', 'value', 'disabled', 'required', 'name']
  });
});
define('client/controllers/plan/neo/buckets/new', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    neoController: Ember.inject.controller('plan.neo'),
    error: null,
    actions: {
      save: function save(model) {
        var _this = this;

        this.set('error', null);
        var names = this.get('store').peekAll('profile-message-bucket').filterBy('name', model.get('name')).filterBy('profile.id', model.get('profile.id'));
        // Check duplicates
        if (names.length > 1) {
          this.set('error', 'Duplicate Name');
        } else {
          model.save().then(function (model) {
            _this.transitionToRoute('plan.neo.buckets.show', model.id);
          });
        }
      },
      cancel: function cancel() {
        this.get('neoController').send('closeNewForm');
      }
    }
  });
});
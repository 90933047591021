define("client/routes/reports/share/page", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    beforeModel: function beforeModel(transition) {
      var report = this.modelFor("reports.share"),
          pages = report.get("pages"),
          requestedPage = +transition.params['reports.share.page'].page;
      if (requestedPage >= pages.get('length')) {
        this.transitionTo('reports.share.page', report.get('hexUrl'), pages.get('length') - 1);
      }
    },
    model: function model(params) {
      var report = this.modelFor("reports.share"),
          pages = report.get("pages"),
          page = pages.findBy("page", +params.page);
      return page;
    },
    afterModel: function afterModel(model) {
      var report = this.modelFor("reports.share");
      model.set("report", report);
    },
    setupController: function setupController(controller, model) {
      this._super.apply(this, arguments);
      controller.setPage(model.get('page'));
    }
  });
});
define('client/mixins/profile-service-mixin', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    profileService: Ember.inject.service('profile'),
    init: function init(model) {
      var profileService = this.get("profileService");

      profileService.on("range-change", this, "rangeDidChange");
      profileService.on("profile-change", this, "profileDidChange");
      profileService.on("campaign-change", this, "campaignDidChange");
      profileService.on("filter-set-change", this, "filterSetDidChange");

      this._super(model);
    },
    rangeDidChange: function rangeDidChange() /*from, to*/{
      // override in controller if needed
    },
    profileDidChange: function profileDidChange() /*profile*/{
      // override in controller if needed
    },
    campaignDidChange: function campaignDidChange() /*campaign*/{
      // override in controller if needed
    },
    filterSetDidChange: function filterSetDidChange() /*filterSet*/{
      // override in controller if needed
    },


    actions: {
      dateRangeChanged: function dateRangeChanged(from, to) {
        this.get("profileService").setRange(from, to);
      }
    }
  });
});
define('client/components/dashboard/widgets/interaction-card', ['exports', 'client/components/dashboard/widgets/widget-card'], function (exports, _widgetCard) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _widgetCard.default.extend({

    sort: "all_interaction",

    percentage: Ember.computed("data.{interaction,visitors}", function () {
      var iCount = this.get('data.interaction');
      var vCount = this.get('data.visitors');
      if (iCount && vCount) {
        return vCount == 0 ? 0 : iCount / vCount;
      } else {
        return null;
      }
    }),

    infoText: Ember.computed('profileService.profile.nameCustomer', 'profileService.profile.nameTotalInteraction', function () {
      var totalInteraction = this.get('profileService.profile.nameTotalInteraction');
      var customers = this.get('profileService.profile.nameCustomer');
      return Ember.String.htmlSafe('<p>' + totalInteraction + ' count is the total number of interactions taken by ' + customers + ' who came to your site as a result of PR activities.  ' + customers + ' may trigger multiple events in one session, but we only tally one instance per interaction type per session.</p> <p>' + totalInteraction + ' percentage is the ' + totalInteraction + ' count divided by the number of ' + customers + ' who came to your site as a result of PR activities.</p>');
    })
  });
});
define('client/adapters/report', ['exports', 'client/adapters/application'], function (exports, _application) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var ReportAdapter = _application.default.extend({
    namespace: '/v4/api',
    queryRecord: function queryRecord(store, type, query) {
      if (query.hex) {
        var url = this.buildURL(type.modelName, null, null, 'queryRecord', query);
        url = url + '/hex/' + query.hex;
        return this.ajax(this.urlPrefix(url), 'GET');
      } else {
        return this._super.apply(this, arguments);
      }
    }
  });
  exports.default = ReportAdapter;
});
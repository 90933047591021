define('client/models/dashboard-widget', ['exports', 'ember-data', 'client/models/cardable', 'client/mixins/models/query-consumer'], function (exports, _emberData, _cardable, _queryConsumer) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var WIDGET_QUERY_ENDPOINT_OVERRIDE = {
    articles: "article_breakdown",
    tone: "sentiment",
    tone_brand: "sentiment"
  };

  var WIDGET_NAMES = {
    articles: "Coverage",
    amplification: "Amplification",
    owned_media_impact: "Owned Media Conversion",
    tone: "Brand Social Sentiment",
    tone_brand: "Brand Article Sentiment"
  };

  var WIDGET_TREND = {
    tone: "Social Sentiment",
    tone_brand: "Article Sentiment"
  };

  var WIDGET_COMPONENT_NAMES = {
    tone_brand: "tone-card"
  };

  exports.default = _cardable.default.extend(_queryConsumer.default, {

    generatedTitle: Ember.computed("profile", "cardableType", function () {
      var profile = this.get("profile");
      var type = this.get("widgetType");
      if (type === "interaction") {
        return profile.get("nameInteraction");
      } else if (type === "visitors") {
        return profile.get("nameCustomer");
      } else if (type === "owned_media_readership") {
        return profile.get("nameReadership");
      } else if (type === "reach") {
        return profile.get("nameReach");
      }
      return WIDGET_NAMES[type] || "Aggregate Tile";
    }),

    generatedTrend: Ember.computed("generatedTitle", "cardableType", function () {
      var type = this.get("widgetType");
      return WIDGET_TREND[type] || this.get("generatedTitle");
    }),

    profileService: Ember.inject.service('profile'),

    cardableType: 'dashboardWidget',

    // Types are:
    // - articles
    // - visitors
    // - interaction
    // - amplification
    // - owned_media_readership
    // - owned_media_impact
    // - impact_score

    widgetType: _emberData.default.attr("string", { defaultValue: "articles" }),

    widgetQueryEndpoint: Ember.computed("widgetType", function () {
      var widgetType = this.get("widgetType");
      return WIDGET_QUERY_ENDPOINT_OVERRIDE[widgetType] || widgetType;
    }),

    componentName: Ember.computed("widgetType", function () {
      var type = this.get("widgetType"),
          name = WIDGET_COMPONENT_NAMES[type] || this.get("widgetType").dasherize() + '-card';
      return 'dashboard/widgets/' + name;
    }),

    associatedGraphProperties: Ember.computed('widgetType', 'profile.nameSentiment', function () {
      var graphProperties = {};
      var filterSetProperties = null;
      var sort = 'date';

      switch (this.get('widgetType')) {
        case 'articles':
          graphProperties = {
            title: 'Article Count By Month',
            valueDimension: ['coverage'],
            graphType: 'graph-column',
            labelDimension: ['date'],
            groupDimension: [null],
            yAxisTitle: '',
            xAxisTitle: '',
            dateGrouping: 'month'
          };
          break;
        case 'amplification':
          sort = 'all_amplification';
          graphProperties = {
            title: 'Amplification By Month',
            valueDimension: ['amplification'],
            graphType: 'graph-column',
            labelDimension: ['date'],
            groupDimension: [null],
            yAxisTitle: '',
            xAxisTitle: '',
            dateGrouping: 'month'
          };
          break;
        case 'tone':
          graphProperties = {
            title: 'Article Count By ' + this.get('generatedTitle'),
            valueDimension: ['coverage'],
            graphType: 'graph-column-stacked',
            labelDimension: ['tone_label'],
            groupDimension: [null],
            yAxisTitle: '',
            xAxisTitle: ''
          };
          filterSetProperties = {
            article: false,
            linkedinPulse: false,
            twitter: true
          };
          break;
        case 'tone_brand':
          graphProperties = {
            title: 'Article Count By ' + this.get('generatedTitle'),
            valueDimension: ['coverage'],
            graphType: 'graph-column-stacked',
            labelDimension: ['tone_label'],
            groupDimension: [null],
            yAxisTitle: '',
            xAxisTitle: ''
          };
          filterSetProperties = {
            article: true,
            linkedinPulse: true,
            twitter: false
          };
          break;
        case 'owned_media_readership':
          sort = 'readership';
          graphProperties = {
            title: 'Readership by Time',
            valueDimension: ['readership'],
            graphType: 'graph-column',
            labelDimension: ['date'],
            groupDimension: [null],
            yAxisTitle: '',
            xAxisTitle: '',
            dateGrouping: 'month'
          };
          filterSetProperties = {
            earned: false,
            owned: true,
            pressRelease: false
          };
          break;
        case 'visitors':
          sort = 'visitors';
          graphProperties = {
            title: 'Potential Customers By Month',
            valueDimension: ['visitor'],
            graphType: 'graph-column',
            labelDimension: ['date'],
            groupDimension: [null],
            yAxisTitle: '',
            xAxisTitle: '',
            dateGrouping: 'month'
          };
          break;
        case 'interaction':
          sort = 'all_interaction';
          graphProperties = {
            title: this.get('profile.nameInteraction') + ' By Quarter',
            valueDimension: ['interaction'],
            graphType: 'graph-column',
            labelDimension: ['date'],
            groupDimension: [null],
            yAxisTitle: '',
            xAxisTitle: '',
            dateGrouping: 'quarter'
          };
          break;
        case 'impact_score':
          sort = 'impact_score';
          graphProperties = {
            title: '' + this.get('profile.nameAverageImpactScore'),
            valueDimension: ['impact_score'],
            graphType: 'graph-line',
            labelDimension: ['date'],
            groupDimension: [null],
            yAxisTitle: '',
            xAxisTitle: '',
            dateGrouping: 'month'
          };
          break;
        case 'reach':
          sort = 'reach';
          graphProperties = {
            title: this.get('profile.nameReach') + ' By Quarter',
            valueDimension: ['reach'],
            graphType: 'graph-column',
            labelDimension: ['date'],
            groupDimension: [null],
            yAxisTitle: '',
            xAxisTitle: '',
            dateGrouping: 'quarter'
          };
      }
      return { graph: graphProperties, filterSet: filterSetProperties, sort: sort };
    }),

    filterSet: _emberData.default.belongsTo('filter-set'),

    filterList: Ember.computed.alias("selectedFilterSet.articleListAdapter"),

    profile: Ember.computed.alias("selectedFilterSet.profile"),
    profile_id: Ember.computed.alias("profile.id"), // rq

    campaign: Ember.computed.alias("selectedFilterSet.campaign"),
    campaign_id: Ember.computed.alias("campaign.id"), // rq

    languages: Ember.computed.alias("selectedFilterSet.languages"), // rq
    relevance: Ember.computed.alias("selectedFilterSet.relevance"), // rq
    syndicated: Ember.computed.alias("selectedFilterSet.syndicated"), // rq
    excludeSyndicateArticles: Ember.computed.alias('selectedFilterSet.excludeSyndicateArticles'), // rq
    followOn: Ember.computed.alias("selectedFilterSet.followOn"), // rq
    companyWebsiteLink: Ember.computed.alias("selectedFilterSet.companyWebsiteLink"), // rq
    articleLinksContain: Ember.computed.alias("selectedFilterSet.articleLinksContain"), // rq
    hideLongTail: Ember.computed.alias("selectedFilterSet.hideLongTail"), // rq

    fromDate: Ember.computed("profileService.fromJSDate", function () {
      return this.get('profileService.fromJSDate');
    }), // rq
    toDate: Ember.computed("profileService.toJSDate", function () {
      return this.get('profileService.toJSDate');
    }), // rq
    searchTerm: Ember.computed.alias('selectedFilterSet.searchTerm'), // rq

    filters: Ember.computed.alias("filterList.filters"), // rq
    tagFilterList: Ember.computed.alias("filterList.tagFilterList"), // rq
    locationFilterList: Ember.computed.alias("filterList.locationFilterList"), // rq

    influential: Ember.computed.alias("selectedFilterSet.influential"), // rq
    uninfluential: Ember.computed.alias("selectedFilterSet.uninfluential"), // rq
    whitelistTagIds: Ember.computed.mapBy("selectedFilterSet.whitelistTags", "id"), // rq

    //
    // Create a report query to get the graph data based on
    // the config and workspace-view. Refreshed when the filter
    // set is saved.
    //
    dataQuery: Ember.computed("selectedFilterSet.isLoaded", 'profile_id', 'campaign_id', 'trendGrouping', function () {
      var query = this.queryByName("dashboard-tile", { endpoint: this.get("widgetQueryEndpoint") });
      return query.load();
    })

  });
});
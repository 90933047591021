define("client/routes/plan/media/new", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    model: function model() {
      return {
        model: this.store.createRecord("whitelist-tag", {
          profile: this.get("profileService.profile")
        }),
        profileLinks: null
      };
    },
    deactivate: function deactivate() {
      if (this.controller.model.get('isNew')) {
        this.controller.model.destroyRecord();
      }
    }
  });
});
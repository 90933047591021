define('client/models/translation', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var Translation = _emberData.default.Model.extend({
    type_cd: _emberData.default.attr(),
    target_language: _emberData.default.attr('string', { defaultValue: 'en' }),
    article: _emberData.default.belongsTo('article'),
    title: _emberData.default.attr('string'),
    content: _emberData.default.attr('string')
  });
  Translation.pathForType = "translations";

  exports.default = Translation;
});
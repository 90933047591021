define('client/components/common/ui-each-collection', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  Ember.Component.reopenClass({
    positionalParams: ['collection']
  });

  exports.default = Ember.Component;
});
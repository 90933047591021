define('client/computed/top-items', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = topItems;
  function topItems(propertyKey, count) {
    return Ember.computed(propertyKey + '.[]', function () {
      return (this.get(propertyKey) || []).slice(0, count);
    });
  }
});
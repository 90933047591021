define('client/models/saved-search', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var SavedSearch = _emberData.default.Model.extend({
    profile_id: _emberData.default.attr(),
    user_id: _emberData.default.attr(),
    editable: _emberData.default.attr(),
    created_by: _emberData.default.attr(),
    name: _emberData.default.attr("string"),
    term: _emberData.default.attr(),
    type_cd: _emberData.default.attr(),
    updatedAt: _emberData.default.attr(),
    by: Ember.computed("created_by", function () {
      return "by " + this.get("created_by");
    })
  });
  SavedSearch.pathForType = "saved_searches";

  exports.default = SavedSearch;
});
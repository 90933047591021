define('client/components/embed-tweet', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var EmbedTweetComponent = Ember.Component.extend({
    storedTweet: null,
    isLoading: true,
    deletedTweet: false,
    onFinishedEmbed: function onFinishedEmbed() {},

    didInsertElement: function didInsertElement() {
      this.onTwitterIDChange();
    },
    onTwitterIDChange: Ember.observer("twitterID", function () {
      var _this = this;

      this.set('isLoading', true);
      if (this._state !== "inDOM" || !this.get("twitterID")) {
        return;
      }
      this.$("twitterwidget").remove();
      if (window.twttr) {
        window.twttr.widgets.createTweet(this.get('twitterID'), this.get('element'), { cards: 'hidden' }).then(function (tweet) {
          if (_this._state !== "inDOM") {
            return;
          }
          _this.setProperties({
            isLoading: false,
            deletedTweet: Ember.isNone(tweet)
          });
          _this.onFinishedEmbed();
        });
      }
    })
  });

  exports.default = EmbedTweetComponent;
});
define('client/utils/search/option', ['exports', 'client/utils/search/constants'], function (exports, _constants) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Object.extend({

    // The option type: text, select, range
    type: null,
    tooltip: null,
    isSelect: Ember.computed.equal("type", _constants.default.SELECT_TYPE),
    isText: Ember.computed.equal("type", _constants.default.TEXT_TYPE),
    isRange: Ember.computed.equal("type", _constants.default.RANGE_TYPE),

    name: null,
    displayName: Ember.computed("name", function () {
      return this.get("name").replace(/_/g, " ").capitalize();
    }),
    displayNameSafe: Ember.computed("displayName", function () {
      return this.get("displayName").htmlSafe();
    }),

    tooltipClass: Ember.computed('tooltip', function () {
      var length = this.get('tooltip.length');
      if (Ember.isPresent(length) && length < 60) {
        return 'short-tooltip';
      } else {
        return '';
      }
    }),

    // the dropdown values if any
    values: null,

    // modifier for the search itself
    modifiers: Ember.computed("type", function () {
      return _constants.default.MODIFIERS[this.get("type")];
    })
  });
});
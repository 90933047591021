define('client/models/newsletter-section', ['exports', 'ember-data', 'ember-api-actions'], function (exports, _emberData, _emberApiActions) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    articles: _emberData.default.hasMany('article-summary'),
    emailNewsletter: _emberData.default.belongsTo("email-newsletter-cart"),
    name: _emberData.default.attr(),
    order: _emberData.default.attr(),

    addArticles: (0, _emberApiActions.memberAction)({ path: 'add_articles', type: 'POST' }),
    removeArticles: (0, _emberApiActions.memberAction)({ path: 'remove_articles', type: 'POST' })
  });
});
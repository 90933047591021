define("client/helpers/format-currency", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.formatCurrency = formatCurrency;

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
        arr2[i] = arr[i];
      }

      return arr2;
    } else {
      return Array.from(arr);
    }
  }

  function formatCurrency(d) {
    var format = d < 1 && d > 0 ? ",.2f" : ",.0f";
    return "$" + d3.format(format)(d);
  }

  exports.default = Ember.Helper.helper(function (params) {
    return formatCurrency.apply(undefined, _toConsumableArray(params));
  });
});
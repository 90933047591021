define('client/controllers/plan/pitch', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    modal: Ember.inject.service(),
    profile: Ember.computed.alias("profileService.profile"),

    setup: function setup() {
      this._super();
      this.get('redirect');
    },

    /**
     * Observer like property used to check profile permissions to view this feature.
     * Features are available once the profile model is loaded.
     * Observer is not used because property will not be triggered if profile data is available.
     */
    redirect: Ember.computed('profile.showAuthorIntelligence', function () {
      if (!this.get('profileService.profile.showAuthorIntelligence')) {
        this.transitionToRoute('plan');
      }
    }),

    sortOptions: [{ name: 'Alphabetical A-Z', type: ['name'] }, { name: 'Alphabetical Z-A', type: ['name:desc'] }],
    selectedSort: Ember.computed(function () {
      return this.get('sortOptions.firstObject');
    }),
    profilePitchLists: Ember.computed.alias("profile.pitchLists"),
    pitchLists: Ember.computed("profilePitchLists.@each.name", "searchTerm", function () {
      var term = (this.get("searchTerm") || "").toLowerCase();
      return this.get("profilePitchLists").filter(function (list) {
        return (list.get("name") || "").toLowerCase().indexOf(term) >= 0;
      });
    }),
    sortedPitchLists: Ember.computed.sort("pitchLists", "selectedSort.type"),

    actions: {
      sortBy: function sortBy(sortOption) {
        this.set('selectedSort', sortOption);
      },
      editList: function editList(list) {},
      deleteList: function deleteList(list) {
        this.get('modal').open('common/ui-confirm', {
          question: 'Are you sure you want to delete: ' + list.get('name'),
          ok: "Delete",
          okType: "danger"
        }).then(function () {
          list.destroyRecord();
        });
      }
    }

  });
});
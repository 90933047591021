define('client/initializers/debounced', ['exports', 'client/utils/debounced'], function (exports, _debounced) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.debouncedPatch = debouncedPatch;
  function debouncedPatch() {
    Ember.computed.debounce = _debounced.default;
  }

  exports.default = {
    name: 'extend-ember-computed',
    initialize: debouncedPatch
  };
});
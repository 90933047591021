define('client/controllers/settings/excluded-articles/index', ['exports', 'client/mixins/corporate'], function (exports, _corporate) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var CorporateIrrelevantController = Ember.Controller.extend(_corporate.default, {
    modal: Ember.inject.service(),
    perType: 'excluded',

    setup: function setup(model) {
      this._super(model);
      Ember.run.scheduleOnce("afterRender", this, "paramsDidChange");
    },


    excluded: Ember.computed(function () {
      var owner = Ember.getOwner(this);
      var findPagingController = owner.lookup('controller:find-paging');

      findPagingController.setProperties({
        model: [],
        type: "excluded",
        store: this.store
      });
      Ember.run.later(this, "paramsDidChange");
      return findPagingController;
    }),

    resources: Ember.computed.alias("excluded"),

    articleTypes: [{ typeName: 'Articles', typeVal: 'null' }, { typeName: 'Twitter', typeVal: '0' }, { typeName: 'Google+', typeVal: '2' }, { typeName: 'Television', typeVal: '11' }, { typeName: 'Radio', typeVal: '12' }, { typeName: 'Podcast', typeVal: '16' }, { typeName: 'Youtube', typeVal: '17' }, { typeName: 'Print', typeVal: '13' }],

    actions: {
      removeFromList: function removeFromList(article) {
        var _this = this;

        this.get('modal').open('common/ui-confirm', {
          prompt: 'Are you sure?',
          ok: 'OK',
          okType: "danger"
        }).then(function () {
          var excluded = _this.store.peekRecord('excluded', article.get('id'));
          if (article.get('user_spam_flag')) {
            excluded.set('spam', false);
            excluded.set('relevance', excluded.get('user_relevance_flag'));
          } else {
            excluded.set('relevance', true);
            excluded.set('spam', excluded.get('user_spam_flag'));
          }
          excluded.save();
          _this.get("excluded.model").removeObject(article);
        });
      }
    }

  });

  exports.default = CorporateIrrelevantController;
});
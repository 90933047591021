define('client/components/reports/base-widget', ['exports', 'ember-data', 'client/utils/reports/generate-id', 'client/models/campaign'], function (exports, _emberData, _generateId, _campaign) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  var sizeComputed = function sizeComputed(size) {
    return Ember.computed("item.rowspan", "item.colspan", "sizes.@each.rowspan", "sizes.@each.colspan", function () {
      var colspan = this.get("item.colspan"),
          rowspan = this.get("item.rowspan");
      var values = this.get("sizes") || this.get("item.sizes");

      if (!values) {
        return false;
      }

      values = values.findBy("name", size);

      return values.colspan === colspan && values.rowspan === rowspan;
    });
  };

  exports.default = Ember.Component.extend({
    classNameBindings: [":report-base", "isEditing:report-base_edit", "isSettled::report-base_loading", "sizeClass"],
    attributeBindings: ["data-id"],

    isEditing: false,

    tempId: Ember.computed.alias('item.tempId'),
    "data-id": Ember.computed.or("item.id", 'item.tempId'),

    router: Ember.inject.service(),
    store: Ember.inject.service(),

    largeSize: sizeComputed("large"),
    mediumSize: sizeComputed("medium"),
    smallSize: sizeComputed("small"),

    sizeClass: Ember.computed("item.rowspan", "item.colspan", function () {
      var row = this.get("item.rowspan"),
          col = this.get("item.colspan"),
          sizes = this.get('sizes');
      if (sizes) {
        var size = sizes.find(function (s) {
          return s.rowspan === row && s.colspan === col;
        });
        return 'report-size-' + size.name;
      }
    }),

    item: Ember.computed.alias("content"),

    expandItem: function expandItem() {},
    _setProfile: function _setProfile(profile) {
      var _this = this;

      // set profile and campaign
      this.set("profile_id", profile.get("profile_id") || profile.get("id"));
      this.set("campaign_id", profile.get("campaign_id"));
      this.set('isSetToCampaign', profile.constructor === _campaign.default);

      // For campaigns, use the start date of the campaign
      // as well as end date if available.
      // If end date is not available, use that of the report
      // When switching to all profiles that are not campaigns,
      // use the start date and end date of the report.
      if (this.get('isSetToCampaign')) {
        // set the new start date
        this.set("fromDate", profile.get("startDate"));
        // and the new end date to be that of campaign
        if (profile.get("endDate")) {
          this.set("toDate", profile.get("endDate"));
        } else {
          this.set("toDate", this.get("item.page.report.endDate"));
        }
      } else {
        this.set("fromDate", this.get("item.page.report.startDate"));
        this.set("toDate", this.get("item.page.report.endDate"));
      }

      [
      // invalidate dimensions
      "tagFilterList", "locationFilterList", "neoFilterList", "campaignFilterList", "tagFilterListFilterMenu", "locationFilterListFilterMenu",

      // invalidate filters
      "tagFilters", "locationFilters"].forEach(function (filter) {
        _this.set('item.content.' + filter, undefined);
      });
    },


    profile_id: Ember.computed.alias("item.profile_id"),
    campaign_id: Ember.computed.alias('item.campaign_id'),
    _campaign_id: Ember.computed.alias("item.campaign_id"),

    isSetToCampaign: Ember.computed.alias('item.isSetToCampaign'),

    mainProfile: Ember.computed.alias("item.mainProfile"),

    profile: Ember.computed.alias("item.profile"),

    fromDate: Ember.computed.alias("item.fromDate"),
    toDate: Ember.computed.alias("item.toDate"),

    selectedFilterSet: Ember.computed.alias('item.selectedFilterSet'),
    gridError: Ember.computed.alias("item.gridError"),

    articleRange: Ember.computed.alias("item.articleRange"),
    socialRange: Ember.computed.alias("item.socialRange"),
    searchTerm: Ember.computed.alias("item.searchTerm"),
    perPage: Ember.computed.alias("item.perPage"),
    offset: Ember.computed.alias("item.offset"),
    sort: Ember.computed.alias("item.sort"),
    filters: Ember.computed.alias("item.filters"),

    tagFilters: Ember.computed.alias("item.tagFilters"),

    locationFilters: Ember.computed.alias("item.locationFilters"),

    whitelistTags: Ember.computed.alias('item.whitelistTags'),
    whitelistTagIds: Ember.computed.alias('item.whitelistTagIds'),
    labelWhitelistTagIds: Ember.computed.alias('item.labelWhitelistTagIds'),
    allTagsSorted: Ember.computed.alias('item.allTagsSorted'),
    iptcTopicIds: Ember.computed.alias('item.iptcTopicIds'),
    chartIptcTopicIds: Ember.computed.alias('item.chartIptcTopicIds'),
    influential: Ember.computed.alias('item.influential'),
    uninfluential: Ember.computed.alias('item.uninfluential'),

    otherProfiles: Ember.computed.alias("item.otherProfiles"),

    combinedLocationFilter: Ember.computed.alias("item.combinedLocationFilter"),

    promiseProxy: Ember.computed(function () {
      return _emberData.default.PromiseObject.create();
    }),

    isSettled: Ember.computed.alias("promiseProxy.isSettled"),

    //
    // Called when you need to refresh the html for an item
    // - on save
    // - when new data is showing (promise resolution)
    //
    // works in tandem with updateItemHtml and report item replaceItemHtml
    //
    updateRenderedHtml: function updateRenderedHtml() {
      Ember.run.schedule("afterRender", this, function () {
        var _this2 = this;

        var page = this.get("item.page"),
            proxy = this.get("promiseProxy");
        try {
          if (page.rendered && proxy.get("promise")) {
            proxy.finally(function () {
              _this2.updateItemHtml();
            });
          }
        } catch (e) {
          this.updateItemHtml();
        }
      });
    },
    updateItemHtml: function updateItemHtml() {
      Ember.run.debounce(this, "_updateItemHtml", 300);
    },
    _updateItemHtml: function _updateItemHtml() {
      if (this._state !== "inDOM") {
        return;
      }
      var parent = this.$().parent(),
          item = this.get("item");
      item.replaceItemHtml(parent[0].outerHTML);
    },
    findResizableParentView: function findResizableParentView(parent) {
      if (Ember.isNone(parent)) {
        return null;
      }
      if (parent && !parent.has('resize')) {
        return this.findResizableParentView(parent.get('parentView'));
      }
      return parent;
    },
    click: function click(e) {
      if (Ember.$(e.target).closest(".report-tool-toolbar").length === 0 && Ember.$(e.target).closest(".report-edit").length === 0) {
        this.send("edit");
        e.stopImmediatePropagation();
      }
    },
    canDropOnPage: function canDropOnPage(item) {
      var grid = this.get("parentView.parentView"),
          rowspan = item.get("rowspan"),
          colspan = item.get("colspan");
      var canDrop = false,
          finalRow = 0,
          finalCol = 0;

      for (var row = 0; row <= 4 - rowspan; row++) {
        for (var col = 0; col <= 4 - colspan; col++) {
          canDrop = grid.canDrop(row, col, rowspan, colspan);
          if (!canDrop) {
            continue;
          }
          finalCol = col;
          break;
        }
        if (!canDrop) {
          continue;
        }
        finalRow = row;
        break;
      }
      return canDrop ? [finalCol, finalRow] : [];
    },


    actions: {
      locationFiltersChanged: function locationFiltersChanged(filters) {
        this.set("item.locationFilters", Object.keys(filters));
        this.send("saveItem");
      },
      duplicate: function duplicate(item) {
        // check where it can be dropped
        var _canDropOnPage = this.canDropOnPage(item),
            _canDropOnPage2 = _slicedToArray(_canDropOnPage, 2),
            finalCol = _canDropOnPage2[0],
            finalRow = _canDropOnPage2[1];

        var page = item.get("page");
        var props = item.duplicateAttrs();
        props.id = (0, _generateId.default)(page.get('report.id'));
        var newItem = this.get('store').createRecord(item.constructor.modelName, props);

        if (Ember.isPresent(finalCol) && Ember.isPresent(finalRow)) {
          newItem.set("col", finalCol);
          newItem.set("row", finalRow);
          page.get("items").addObject(newItem);
        } else {
          this._addItemToNewPage(newItem);
        }
        var oldFs = void 0;
        if (newItem.get('customFilterSetId')) {
          oldFs = this.get('store').peekRecord('filter-set', newItem.get('customFilterSetId'));
        }
        if (oldFs) {
          var newFs = oldFs.copy();
          newFs.setProperties({
            user: this.get('currentUser.content'),
            actualProfile: oldFs.get('actualProfile'),
            actualCampaign: oldFs.get('actualCampaign')
          });
          newFs.save().then(function (fs) {
            newItem.set('customFilterSetId', fs.id);
            newItem.touch();
          });
        } else {
          newItem.touch();
        }
      },
      changeSize: function changeSize(size) {
        var _this3 = this;

        // TODO: This is breaking because config is a string?
        var values = this.get("sizes").findBy("name", size);
        this.expandItem(values.rowspan, values.colspan);
        var parent = this.findResizableParentView(this.get("parentView"));
        if (parent) {
          parent.handleResize().then(function () {
            _this3.updateRenderedHtml();
          });
        } else {
          this.updateRenderedHtml();
        }
      },
      saveItem: function saveItem() {
        this.updateRenderedHtml();
      },
      edit: function edit() {
        var _this4 = this;

        if (this.get('reportService.isReadOnly') || this._state !== "inDOM") {
          return;
        }

        // close all other before showing this new one.
        Ember.$(".report-content .report-edit .report-edit-header .fa-times").click();
        this.set("isEditing", true);
        this.get("reportService").startEditing(this);

        Ember.$("body").on("keyup." + this.get("elementId"), function (event) {
          if (event.keyCode === 27) {
            _this4.send("display");
          }
        });
      },
      display: function display() {
        if (this.isDestroyed || this.get('reportService.isReadOnly')) {
          return;
        }

        this.set("isEditText", false);
        this.set("isEditing", false);
        this.get("reportService").stopEditing();
      }
    },

    _addItemToNewPage: function _addItemToNewPage(newItem) {
      var page = newItem.get('page');
      var report = page.get('report');
      var newPage = report.createPage();
      newItem.set('row', 0);
      newItem.set('col', 0);
      newItem.set('page', newPage);
      newPage.get('items').addObject(newItem);
      report.get('pages').addObject(newPage);

      report.movePage(newPage.get("pageNumber"), +page.get("pageNumber"));
      Ember.run.later(this, function () {
        this.get('router').transitionTo("reports.show.page", report, newPage);
        Ember.run.schedule("afterRender", this, function () {
          newPage.rendered();
        });
      });
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      //
      // This listens to the refresh data from the item model to update the
      // html. In a future version, this will not be needed.
      //
      this.get("item").on("refresh-data", this, "updateRenderedHtml");
      this.updateRenderedHtml();
    }
  });
});
define("client/components/plan/pitch/edit-list", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ["pitch-edit-list"],

    list: null,

    onSaved: function onSaved() {},
    onCancel: function onCancel() {},


    actions: {
      save: function save() {
        var _this = this;

        var list = this.get("list");
        list.save().then(function () {
          _this.get("onSaved")(list);
        });
      }
    }
  });
});
define('client/components/sentiment-widget', ['exports', 'client/components/simple-widget'], function (exports, _simpleWidget) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var SentimentWidgetComponent = _simpleWidget.default.extend({
    layoutName: "components/sentiment-widget",

    title: "",

    hasArticleSentiment: false,

    positive: Ember.computed.alias("content.positive"),
    negative: Ember.computed.alias("content.negative"),
    neutral: Ember.computed.alias("content.neutral"),

    onClick: function onClick() {},
    handleTileClick: function handleTileClick() {},


    sum: Ember.computed("positive", "negative", "neutral", "profile.includeNeutralArticles", function () {
      var _getProperties = this.getProperties("positive", "neutral", "negative"),
          positive = _getProperties.positive,
          negative = _getProperties.negative,
          neutral = _getProperties.neutral,
          includeNeutral = this.get("profile.includeNeutralArticles");

      return (positive || 0) + (negative || 0) + (includeNeutral ? neutral || 0 : 0);
    }),

    positivePct: Ember.computed("positive", "sum", function () {
      var _getProperties2 = this.getProperties("positive", "sum"),
          positive = _getProperties2.positive,
          sum = _getProperties2.sum;

      if (sum === 0) {
        return 0;
      } else {
        return Math.round((positive || 0) / sum * 100);
      }
    }),

    neutralPct: Ember.computed("neutral", "sum", function () {
      var _getProperties3 = this.getProperties("neutral", "sum"),
          neutral = _getProperties3.neutral,
          sum = _getProperties3.sum;

      if (sum === 0) {
        return 0;
      } else {
        return Math.round((neutral || 0) / sum * 100);
      }
    }),

    negativePct: Ember.computed("negative", "sum", function () {
      var _getProperties4 = this.getProperties("negative", "sum"),
          negative = _getProperties4.negative,
          sum = _getProperties4.sum;

      if (sum === 0) {
        return 0;
      } else {
        return Math.round((negative || 0) / sum * 100);
      }
    }),

    positiveStyle: Ember.computed("positivePct", function () {
      var val = this.get("positivePct");
      return new Ember.String.htmlSafe(val !== null ? "height:" + val * 0.81 + "px;" : "");
    }),

    neutralStyle: Ember.computed("neutralPct", function () {
      var val = this.get("neutralPct");
      return new Ember.String.htmlSafe(val !== null ? "height:" + val * 0.81 + "px;" : "");
    }),

    negativeStyle: Ember.computed("negativePct", function () {
      var val = this.get("negativePct");
      return new Ember.String.htmlSafe(val !== null ? "height:" + val * 0.81 + "px;" : "");
    }),

    click: function click(e) {
      if (Ember.$(e.target).parents(".bd").length > 0) {
        this.onClick();
      }
    },


    actions: {
      positiveTone: function positiveTone() {
        this.handleTileClick('positiveTone');
      },
      neutralTone: function neutralTone() {
        this.handleTileClick('neutralTone');
      },
      negativeTone: function negativeTone() {
        this.handleTileClick('negativeTone');
      }
    }
  });

  exports.default = SentimentWidgetComponent;
});
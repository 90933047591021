define('client/components/collection-list', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  function mode(array) {
    if (array.length === 0) {
      return null;
    }
    var modeMap = {};
    var maxEl = array[0],
        maxCount = 1;
    for (var i = 0; i < array.length; i++) {
      var el = array[i];
      if (modeMap[el] == null) {
        modeMap[el] = 1;
      } else {
        modeMap[el]++;
      }

      if (modeMap[el] > maxCount) {
        maxEl = el;
        maxCount = modeMap[el];
      }
    }
    return maxEl;
  }

  exports.default = Ember.Component.extend({
    items: Ember.A(),
    height: 20,
    width: 50,
    itemSizes: null,
    minHeight: Ember.computed('items', 'height', 'itemSizes', function () {
      var count = Math.min(this.get('items.length'), 5);

      if (this.get('itemSizes')) {
        var height = mode(this.get('itemSizes').map(function (c) {
          return c.height;
        }));
        return height * count + 'px';
      } else {
        return this.get('height') * count + 'px';
      }
    })
  });
});
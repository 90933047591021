define('client/components/plan/campaigns/campaign-publishers', ['exports', 'client/utils/campaign-helper'], function (exports, _campaignHelper) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['campaign-publishers', 'campaign-creation-navigation'],

    prompt: 'Which publishers do you want to track for this campaign?',

    isContinueDisabled: Ember.computed('publishers.@each.isSelected', function () {
      var publishers = this.get('publishers');
      return Ember.isEmpty(publishers.filterBy('isSelected', true));
    }),

    selectedPublishers: null,

    publishers: Ember.computed('selectedPublishers', "profileService.profile.hasBroadcast", function () {
      var selectedPublishers = this.get('selectedPublishers') || Ember.A();
      var checkSelected = Ember.isPresent(selectedPublishers),
          profile = this.get("profileService.profile"),
          PUBLISHERS = (0, _campaignHelper.getPublishers)(profile);

      var publishers = (0, _campaignHelper.getPublisherNames)(profile).map(function (publisher) {
        var isSelected = [PUBLISHERS.article, PUBLISHERS.linkedinPulse].indexOf(publisher) >= 0;

        if (checkSelected) {
          isSelected = selectedPublishers.includes(publisher);
        }
        return Ember.Object.create({ name: publisher, isSelected: isSelected });
      });
      return publishers;
    }),

    onSelectedPublishers: function onSelectedPublishers() {},
    onNext: function onNext() {},
    onPrevious: function onPrevious() {},
    setPublishers: function setPublishers() {
      var selectedPublishers = this.get('publishers').filterBy('isSelected').map(function (publisher) {
        return publisher.name;
      });
      this.onSelectedPublishers(selectedPublishers);
    },


    actions: {
      onNext: function onNext() {
        this.setPublishers();
        this.onNext();
      },
      onPrevious: function onPrevious() {
        this.setPublishers();
        this.onPrevious();
      }
    }

  });
});
define('client/components/email-digest-section', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var SORTS = {
    DATE: { name: "Date", value: 0 },
    AIRPR_SCORE: { name: "Power of Voice", value: 2 },
    FACEBOOK: { name: "Facebook", value: 11 },
    GOOGLE: { name: "Google+", value: 12 },
    LINKEDIN: { name: "LinkedIn", value: 13 }
  };

  var SORT_CHOICES = Object.keys(SORTS).map(function (sortName) {
    return SORTS[sortName];
  });

  exports.default = Ember.Component.extend({
    classNames: ["email-digest-section", "light-section"],

    opened: false,
    section: null,
    frequency: undefined,
    session: Ember.inject.service(),
    store: Ember.inject.service(),
    selectedView: null,
    lastSelectedView: null,
    lastSectionNumber: null,
    showReorderButtons: false,

    init: function init() {
      this._super.apply(this, arguments);
      if (!this.get("section.isNew")) {
        return;
      }
      this.set("opened", true);
    },


    mustSortByDate: Ember.computed.equal('frequency.value', 0),

    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);
      if (this.get('frequency.value.value') === 0) {
        this.set('section.article_sort', SORTS.DATE.value);
      }
    },


    sortChoices: Ember.computed(function () {
      return SORT_CHOICES;
    }),

    sort: Ember.computed("section.article_sort", "sortChoices", function () {
      return this.get("sortChoices").findBy("value", this.get("section.article_sort"));
    }),

    countChoices: Ember.computed(function () {
      return [5, 10, 25, 50, 100];
    }),

    profiles: Ember.computed("currentUser", function () {
      return this.get("session").flatProfiles(true, false);
    }),

    filtersOverflowClass: 'email-section-filter-selection-overflow',
    setOverflowClassOnWorkspaceView: Ember.observer('filtersOverflowClass', 'selectedView', 'lastSelectedView', function () {
      if (this.get('selectedView') && this.get('selectedView') === this.get('lastSelectedView')) {
        Ember.run.later(this, function () {
          this.set('filtersOverflowClass', null);
        }, 300);
      } else {
        this.set('filtersOverflowClass', 'email-section-filter-selection-overflow');
      }
    }),

    actions: {
      toggleOpened: function toggleOpened() {
        this.toggleProperty("opened");
        if (this.get("opened")) {
          // select the last view;
          this.set("selectedView", this.get("section.views.lastObject"));
          this.set("lastSelectedView", this.get("selectedView"));
          if (this.get('selectedView.activeProfile.header_data')) {
            this.get('selectedView.activeProfile').reload();
          }
        }
      },
      changeArticleCount: function changeArticleCount(count) {
        this.set("section.article_count", count);
      },
      changeSort: function changeSort(sort) {
        this.set("section.article_sort", sort.value);
      },
      selectView: function selectView(view) {
        this.set('selectedView', this.get('selectedView') === view ? null : view);
        this.set('filtersOverflowClass', 'email-section-filter-selection-overflow');
        if (view.get('activeProfile.header_data')) {
          view.get('activeProfile').reload();
        }
        if (this.get('lastSelectedView')) {
          Ember.run.later(this, function () {
            this.set('lastSelectedView', view);
          }, 300);
        } else {
          this.set('lastSelectedView', view);
        }
      },
      addProfile: function addProfile(profile, dropdown) {
        var _this = this;

        var setProfile = function setProfile(loadedProfile) {
          _this.set('filtersOverflowClass', 'email-section-filter-selection-overflow');
          // create a new view
          var view = _this.get("store").createRecord("workspace-view");
          view.set('filters', { filters: loadedProfile.get('defaultFilters') });
          view.set("activeProfile", profile);
          _this.get("section.views").addObject(view);
          _this.set("lastSelectedView", view);
          Ember.run.later(_this, function () {
            this.set("selectedView", view);
          }, 300);
        };
        // If the profile doesnt have all the data loaded, then reload the profile
        if (profile.get('header_data') && !profile.get('isReloading')) {
          profile.reload().then(setProfile);
        } else {
          setProfile(profile);
        }
      },
      removeView: function removeView(view) {
        this.set('filtersOverflowClass', 'email-section-filter-selection-overflow');

        if (this.get("lastSelectedView") === view) {
          this.set("lastSelectedView", null);
        }

        this.get("section.views").removeObject(view);
        if (this.get("selectedView") === view) {
          this.set("selectedView", null);
        }
      }
    }
  });
});
define('client/components/saved-filter-set-selector', ['exports', 'client/mixins/check-term-count-exists'], function (exports, _checkTermCountExists) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_checkTermCountExists.default, {
    classNames: ["saved-filter-set-selector"],
    tagName: '',
    clearLinkTitle: "Clear Search and Filter",
    abilityService: Ember.inject.service("ability"),

    searchTermRegexObj: null,
    searchTerm: null,
    currentFilterSet: null,
    currentFilterSetChanged: Ember.computed('currentFilterSet.hasDirtyAttributes', function () {
      return Object.keys(this.get('currentFilterSet').changedAttributes()).length > 0;
    }),

    unsortedFilterSetOptions: Ember.computed('currentProfile.filterSets.[]', 'currentProfile.filterSets.@each.reusable', 'currentFilterSet', 'currentCampaign', 'searchTermRegexObj', function () {
      var currentId = +this.get('currentFilterSet.id');
      var currentProfileId = this.get('currentProfile.id');
      var currentCampaignId = null;
      var re = this.get('searchTermRegexObj');
      if (this.get('currentCampaign')) {
        currentCampaignId = this.get('currentCampaign').get('id');
      }
      return this.get('currentProfile.filterSets').filter(function (fs) {
        var check = !fs.get('isNew') && +fs.get('id') !== currentId && fs.belongsToProfile(currentProfileId) && (!re || re.test(fs.get('name'))) && fs.get('reusable');
        // Must check if filter is a default filter set that belongs to another campaign
        // If filter belongs to another campaign then it cannot be used or shown
        var allowCampaignFilter = !fs.get('campaign_id') || !fs.get('isCampaignDefault') && fs.belongsToCampaign(currentCampaignId);
        return check && allowCampaignFilter;
      }).map(function (fs) {
        return Ember.ObjectProxy.create({
          content: fs,
          showDelete: false
        });
      });
    }),
    filterSetOptions: Ember.computed.sort('unsortedFilterSetOptions', 'filterSetOptionsSortProperties'),
    filterSetOptionsSortProperties: null,

    onSelectFilterSet: function onSelectFilterSet() {},
    init: function init() {
      this._super.apply(this, arguments);
      this.set('filterSetOptionsSortProperties', this.get('filterSetOptionsSortProperties') || ["name:asc"]);
    },


    actions: {
      canShowDropdown: function canShowDropdown(dropdown, event) {
        if (this.abilityService.canAccess("global_snf_selector")) {
          dropdown.toggleDropdown();
        } else {
          event.preventDefault();
        }
      },
      onSelectFilterSet: function onSelectFilterSet(filterSet, closeMenu) {
        this.onSelectFilterSet(filterSet);
        closeMenu();
        if (filterSet) {
          var searchTerm = filterSet.get("searchTerm");
          if (searchTerm) {
            var termCounts = this.get('currentProfile.term_counts').mapBy('term_value');
            this.CheckTermCountExists(searchTerm, termCounts);
          }
        }
      },
      onDeleteFilterSet: function onDeleteFilterSet(filterSet, closeMenu) {
        this.onDeleteFilterSet(filterSet);
        closeMenu();
      }
    }
  });
});
define('client/routes/plan/campaigns/new', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({

    actions: {
      didTransition: function didTransition() {
        this.controller.showModal();
        return true; // Bubble the didTransition event
      }
    }
  });
});
define("client/components/reports/edit-article-list", ["exports", "client/components/reports/edit-base"], function (exports, _editBase) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _editBase.default.extend({
    actions: {
      selectTitle: function selectTitle(value) {
        this.get("item").send("selectTitle", value);
      },
      selectSort: function selectSort(value) {
        this.get("item").send("selectSort", value);
      },
      selectOffset: function selectOffset(value) {
        this.get("item").send("selectOffset", value);
      },
      selectPerPage: function selectPerPage(value) {
        this.get("item").send("selectPerPage", value);
      },
      selectUseInteractionCount: function selectUseInteractionCount(value) {
        this.get("item").send("selectUseInteractionCount", value);
      }
    }
  });
});
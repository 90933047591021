define("client/components/graph/channel-type-options", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: "ul",
    filterSet: null,
    selectionSet: null,

    update: function update() {}
  });
});
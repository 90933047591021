define('client/services/honeybadger', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    _send: function _send(method, msg, data) {
      if (typeof Honeybadger !== 'undefined') {
        Honeybadger.notify(msg);
      }
    },
    error: function error(msg, data) {
      this._send('error', msg, data);
    },
    log: function log(msg, data) {
      this._send('log', msg, data);
    },
    info: function info(msg, data) {
      this._send('info', msg, data);
    },
    warning: function warning(msg, data) {
      this._send('warning', msg, data);
    }
  });
});
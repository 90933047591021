define('client/components/email-list', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: 'span',
    classNames: ['email-list'],
    recipients: null,

    addFromSearch: function addFromSearch() {},
    removeRecipient: function removeRecipient() {},
    init: function init() {
      this._super.apply(this, arguments);
      this.set('recipients', this.get('recipients') || []);
    }
  });
});
define('client/components/color-swatch', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNameBindings: [':color-swatch', 'isSelected'],
    attributeBindings: ['style'],

    action: function action() {},


    isSelected: Ember.computed('index', 'selectedSwatchIndex', function () {
      return this.get('index') === this.get('selectedSwatchIndex');
    }),

    selectedSwatchIndex: null,
    style: Ember.computed('color', function () {
      var color = this.get('color');
      return new Ember.String.htmlSafe('background-color: ' + color + ';');
    }),
    index: 0,
    color: '#000',
    click: function click(e) {
      e.preventDefault();
      e.stopImmediatePropagation();
      this.action(this.get('index'), this.get('color'));
    }
  });
});
define('client/models/filter-set', ['exports', 'ember-data', 'client/utils/filters/reporting-adapter', 'client/utils/filters/article-list-adapter', 'client/utils/locations-by-region'], function (exports, _emberData, _reportingAdapter, _articleListAdapter, _locationsByRegion) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var FILTERS = ["searchTerm", "earned", "owned", "pressRelease", "article", "linkedinPulse", "twitter", "googlePlus", "television", "radio", "podcast", "youtube_bc", "printPublication", "positive", "neutral", "negative", "facebookCount", "twitterCount", "linkedinCount", "gplusCount", "pinterestCount", "influential", "uninfluential", "locations", "languages", "syndicated", "followOn", "excludeSyndicateArticles", "interactionMetrics", "relevance", "companyWebsiteLink", "articleLinksContain", "hideLongTail"];

  var FilterSet = _emberData.default.Model.extend({
    copy: function copy() {
      return this.store.createRecord("filter-set", this.getFilterSetProperties());
    },
    copyFilters: function copyFilters(filterSet) {
      this.setProperties(filterSet.getFilters());
    },
    getFilters: function getFilters() {
      var filters = this.getProperties.apply(this, FILTERS);
      filters.whitelistTags = this.get("whitelistTags").toArray();
      filters.iptcTopicIds = this.get("iptcTopicIds").toArray();
      filters.tags = this.get('tags').toArray();
      filters.excludedTags = this.get("excludedTags").toArray();
      filters.soundbytes = this.get('soundbytes').toArray();

      // do a deep copy
      filters.competitorSearchTerms = JSON.parse(JSON.stringify(this.get("competitorSearchTerms")) || []).map(function (o) {
        return Ember.Object.create(o);
      });

      return filters;
    },

    getFilterSetProperties: function getFilterSetProperties() {
      var filters = this.getFilters();
      var properties = this.getProperties("profile_id", "campaign_id", "isPrivate");

      Ember.merge(properties, {
        actualProfile: this.get('actualProfile.content'),
        actualCampaign: this.get('actualCampaign.content')
      });

      properties.campaigns = this.get('campaigns').toArray();
      properties.otherProfiles = this.get("otherProfiles").toArray();
      return Ember.merge(filters, properties);
    },

    saveForProfile: function saveForProfile() {
      this.setProperties({
        profile_id: this.get("profile.id"),
        campaign_id: this.get("campaign.id"),
        actualProfile: this.get("profile"),
        actualCampaign: this.get("campaign")
      });
      return this.save();
    },


    user: _emberData.default.belongsTo('user', { inverse: 'filterSets' }),
    userId: _emberData.default.attr("string"),
    userName: _emberData.default.attr("string"),
    // must be inverse or else ember data gets confused
    // and blanks one relationship out since
    // there are both filterSets and defaultFilterSet relationships
    // on base-profile
    actualProfile: _emberData.default.belongsTo('profile', { inverse: null }),
    actualCampaign: _emberData.default.belongsTo('campaign', { inverse: null }),

    profile: Ember.computed("profileService.profile", {
      get: function get() {
        return this.get("profileService.profile");
      },
      set: function set(k, v) {
        this.set('actualProfile', v);
        return v;
      }
    }),
    campaign: Ember.computed("profileService.campaign", {
      get: function get() {
        return this.get("profileService.campaign");
      },
      set: function set(k, v) {
        this.set('actualCampaign', v);
        return v;
      }
    }),
    profile_id: _emberData.default.attr("number"),
    campaign_id: _emberData.default.attr("number"),

    otherProfiles: _emberData.default.hasMany("profile", { inverse: null }),

    competitorSearchTerms: _emberData.default.attr("array", {
      defaultValue: function defaultValue() {
        return [];
      }
    }),

    name: _emberData.default.attr("string", { defaultValue: "" }),
    isPrivate: _emberData.default.attr('boolean', { defaultValue: true }),
    searchTerm: _emberData.default.attr("string", { defaultValue: "" }),
    //if a filter set is reusable it will show up in the dropdown
    reusable: _emberData.default.attr('boolean'),

    // channel type
    earned: _emberData.default.attr("boolean", { defaultValue: true }),
    owned: _emberData.default.attr("boolean", { defaultValue: true }),
    pressRelease: _emberData.default.attr("boolean", { defaultValue: true }),

    // content type
    article: _emberData.default.attr("boolean", { defaultValue: true }),
    linkedinPulse: _emberData.default.attr("boolean", { defaultValue: true }),
    twitter: _emberData.default.attr("boolean", { defaultValue: false }),
    googlePlus: _emberData.default.attr("boolean", { defaultValue: false }),
    television: _emberData.default.attr("boolean", { defaultValue: false }),
    radio: _emberData.default.attr("boolean", { defaultValue: false }),
    podcast: _emberData.default.attr("boolean", { defaultValue: false }),
    youtube_bc: _emberData.default.attr("boolean", { defaultValue: false }),
    printPublication: _emberData.default.attr("boolean", { defaultValue: false }),

    // tone
    positive: _emberData.default.attr("boolean", { defaultValue: true }),
    neutral: _emberData.default.attr("boolean", { defaultValue: true }),
    negative: _emberData.default.attr("boolean", { defaultValue: true }),

    // amplification
    facebookCount: _emberData.default.attr("boolean", { defaultValue: true }),
    twitterCount: _emberData.default.attr("boolean", { defaultValue: true }),
    linkedinCount: _emberData.default.attr("boolean", { defaultValue: true }),
    gplusCount: _emberData.default.attr("boolean", { defaultValue: true }),
    pinterestCount: _emberData.default.attr("boolean", { defaultValue: true }),

    // volume
    influential: _emberData.default.attr("boolean", { defaultValue: true }),
    uninfluential: _emberData.default.attr("boolean", { defaultValue: false }),

    //syndicated and follow-on
    syndicated: _emberData.default.attr("boolean", { defaultValue: false }),
    followOn: _emberData.default.attr("boolean", { defaultValue: false }),
    excludeSyndicateArticles: _emberData.default.attr("boolean", { defaultValue: false }),

    // tags
    tags: _emberData.default.hasMany("tag"),
    excludedTags: _emberData.default.hasMany("tag"),

    // target media list (whitelist tags)
    whitelistTags: _emberData.default.hasMany("whitelist-tag"),

    // iptc topics
    iptcTopicIds: _emberData.default.attr("array", {
      defaultValue: function defaultValue() {
        return [];
      }
    }),

    // locations
    locations: _emberData.default.attr("array", {
      defaultValue: function defaultValue() {
        return [];
      }
    }),
    defaultLocations: Ember.computed.alias("reportingAdapter.filterOptions.locationOptions"),

    // languages
    languages: _emberData.default.attr("array", {
      defaultValue: function defaultValue() {
        return [];
      }
    }),

    //brand signal
    relevance: _emberData.default.attr('number', { defaultValue: 0 }),

    //backlinks
    companyWebsiteLink: _emberData.default.attr('boolean', { defaultValue: null, allowNull: true }),
    articleLinksContain: _emberData.default.attr('string', { defaultValue: '' }),
    //long tail results
    hideLongTail: _emberData.default.attr('boolean', { defaultValue: false }),

    // attribute to denote if filterset is created for downloading data
    forDataDownload: _emberData.default.attr('boolean'),

    nameAsSuffix: Ember.computed('name', function () {
      if (this.get('isDefault')) {
        return '';
      } else {
        return '(' + (this.get('name') || 'Filtered') + ')';
      }
    }),

    displayName: Ember.computed('name', 'isCampaignDefault', 'reusable', function () {
      if (this.get('reusable')) {
        if (this.get('isCampaignDefault')) {
          return 'Campaign Filters';
        } else {
          return this.get('name');
        }
      } else {
        return 'Custom';
      }
    }),

    getLocationsForRegion: function getLocationsForRegion(region, location) {
      var locations = this.get("locations");
      locations = Ember.isEmpty(locations) ? this.get("defaultLocations") : locations;

      if (!locations.get('length')) {
        return [];
      }
      return Ember.A(_locationsByRegion.default[region]).filter(function (location) {
        return locations.indexOf(location.iso) >= 0;
      });
    },

    northAmericaLocations: Ember.computed('locations.[]', function () {
      return this.getLocationsForRegion('NA');
    }),

    southAmericaLocations: Ember.computed('locations.[]', function () {
      return this.getLocationsForRegion('SA');
    }),

    africaLocations: Ember.computed('locations.[]', function () {
      return this.getLocationsForRegion('AF');
    }),

    asiaLocations: Ember.computed('locations.[]', function () {
      return this.getLocationsForRegion('AS');
    }),

    europeLocations: Ember.computed('locations.[]', function () {
      return this.getLocationsForRegion('EU');
    }),

    oceaniaLocations: Ember.computed('locations.[]', function () {
      return this.getLocationsForRegion('OC');
    }),

    antarcticaLocations: Ember.computed('locations.[]', function () {
      return this.getLocationsForRegion('AN');
    }),

    otherLocations: Ember.computed('locations.[]', function () {
      return this.getLocationsForRegion('XX');
    }),

    allLocations: Ember.computed.union('northAmericaLocations', 'southAmericaLocations', 'africaLocations', 'asiaLocations', 'europeLocations', 'oceaniaLocations', 'antarcticaLocations', 'otherLocations'),

    // soundbytes
    soundbytes: _emberData.default.hasMany("message"),

    // campaigns
    campaigns: _emberData.default.hasMany("campaign", { inverse: null }),

    // interaction labels
    interactionMetrics: _emberData.default.attr("array", {
      defaultValue: function defaultValue() {
        return [];
      }
    }),

    getAllData: function getAllData() {
      var profile = this.get('profile');
      this.setProperties({
        earned: true,
        owned: true,
        pressRelease: true,
        article: true,
        linkedinPulse: true,
        twitter: true,
        googlePlus: true,
        television: true,
        radio: true,
        podcast: true,
        youtube_bc: true,
        printPublication: true,
        positive: true,
        neutral: true,
        negative: true,
        influential: true,
        uninfluential: true,

        searchTerm: null,

        syndicated: false,
        followOn: false,
        excludeSyndicateArticles: false,

        tags: [],
        excludedTags: [],
        languages: [],

        locations: profile.get('allLocations'),
        whitelistTagIds: profile.get('allWhitelistTags'),

        relevance: 0,
        companyWebsiteLink: null,
        articleLinksContain: null,
        hideLongTail: false
      });
    },

    // convert to reporting format
    reportingAdapter: Ember.computed("actualProfile", "profile", "actualCampaign", "campaign", "searchTerm", function () {
      //
      // When saving the graph, the selection set reload with
      // a null profile. To avoid that, we use the last non null
      // profile.
      //

      var profile = this.get("actualProfile.id") ? this.get("actualProfile") : this.get('profile'),
          campaign = this.get("actualCampaign.id") ? this.get('actualCampaign') : this.get('campaign');
      if (profile) {
        this._prevProfile = profile;
      } else {
        profile = this._prevProfile;
      }

      return _reportingAdapter.default.create({ filterSet: this, profile: profile, campaign: campaign });
    }),

    profileInOtherProfiles: function profileInOtherProfiles(profileId) {
      return this.hasMany('otherProfiles').ids().includes(profileId);
    },


    // convert to article format
    articleListAdapter: Ember.computed(function () {
      return _articleListAdapter.default.create({ filterSet: this });
    }),

    belongsToProfile: function belongsToProfile(profileId) {
      return Ember.isEqual(+this.get('actualProfile.id'), +profileId);
    },

    belongsToCampaign: function belongsToCampaign(campaignId) {
      return Ember.isEqual(+this.get('actualCampaign.id'), +campaignId);
    },

    isDefault: Ember.computed('isProfileDefault', 'isCampaignDefault', function () {
      return this.get('isProfileDefault') || this.get('isCampaignDefault');
    }),

    isProfileDefault: Ember.computed('profile.defaultFilterSet.id', function () {
      return Ember.isEqual(+this.get('id'), +this.get('actualProfile.defaultFilterSet.id'));
    }),

    isCampaignDefault: Ember.computed('campaign.defaultFilterSet.id', function () {
      return Ember.isEqual(+this.get('id'), +this.get('actualCampaign.defaultFilterSet.id'));
    }),

    toggleTag: function toggleTag(tagId) {
      var toggledOn = this._toggleAssociation('tags', tagId, 'tag');
      if (toggledOn) {
        this._removeAssociation('excludedTags', tagId);
      }
    },
    toggleNotTag: function toggleNotTag(tagId) {
      var toggledOn = this._toggleAssociation('excludedTags', tagId, 'tag');
      if (toggledOn) {
        this._removeAssociation('tags', tagId);
      }
    },
    toggleWhitelistTag: function toggleWhitelistTag(tagId) {
      this._toggleAssociation('whitelistTags', tagId, 'whitelist-tag');
    },
    _removeAssociation: function _removeAssociation(association, id) {
      this.set(association, this.get(association).rejectBy('id', id));
    },
    _toggleAssociation: function _toggleAssociation(association, id, modelName) {
      if (this.get(association).isAny('id', id)) {
        this._removeAssociation(association, id);
        return false;
      } else {
        var record = this.get('store').peekRecord(modelName, id);
        this.get(association).addObject(record);
        return true;
      }
    }
  });

  FilterSet.pathForType = "filter_sets";

  exports.default = FilterSet;
});
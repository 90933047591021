define('client/utils/svg-to-image', ['exports', 'file-saver'], function (exports, _fileSaver) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.computedStyleToInlineStyle = exports.getSVGString = exports.svgString2Image = exports.svgToImage = undefined;


  var computedStyleToInlineStyle = function computedStyleToInlineStyle(element) {
    var options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

    if (!element) {
      throw new Error("No element specified.");
    }

    if (options.recursive && element.children) {
      var _iteratorNormalCompletion = true;
      var _didIteratorError = false;
      var _iteratorError = undefined;

      try {
        for (var _iterator = element.children[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
          var child = _step.value;

          computedStyleToInlineStyle(child, options);
        }
      } catch (err) {
        _didIteratorError = true;
        _iteratorError = err;
      } finally {
        try {
          if (!_iteratorNormalCompletion && _iterator.return) {
            _iterator.return();
          }
        } finally {
          if (_didIteratorError) {
            throw _iteratorError;
          }
        }
      }
    }

    var computedStyle = getComputedStyle(element);
    var properties = options.properties || computedStyle;
    if (properties) {
      properties.forEach(function (property) {
        element.style[property] = computedStyle.getPropertyValue(property);
      });
    }
  };

  var getSVGString = function getSVGString(svgNode, domElement) {
    svgNode.setAttribute('xlink', 'http://www.w3.org/1999/xlink');
    var serializer = new XMLSerializer();
    var svgString = serializer.serializeToString(svgNode);
    svgString = svgString.replace(/(\w+)?:?xlink=/g, 'xmlns:xlink='); // Fix root xlink without namespace
    svgString = svgString.replace(/NS\d+:href/g, 'xlink:href'); // Safari NS namespace fix
    return svgString;
  };

  var svgString2Image = function svgString2Image(svgString, width, height, domElement, callback) {
    // Convert SVG string to data URL
    var imgsrc = 'data:image/svg+xml;base64,' + btoa(unescape(encodeURIComponent(svgString)));
    var format = 'png';
    var canvas = document.createElement("canvas");
    var context = canvas.getContext("2d");

    canvas.width = width;
    canvas.height = height;

    var image = new Image();
    var existingCanvas = domElement.querySelector('canvas');
    if (existingCanvas) {
      existingCanvas.remove();
    }
    //Paint image on Canvas
    image.src = imgsrc;
    image.onload = function () {
      context.clearRect(0, 0, width, height);
      context.fillStyle = 'white';
      context.fillRect(0, 0, width, height);
      context.drawImage(image, 0, 0, width, height);

      canvas.toBlob(function (blob) {
        var filesize = Math.round(blob.length / 1024) + ' KB';
        if (callback) callback(blob, filesize);
      });
    };
  };

  var svgToImage = function svgToImage(domElement) {
    var sizeMultiplier = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 2;
    var scale = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 1;

    //Based on https://github.com/lukehorvat/computed-style-to-inline-style
    //and http://bl.ocks.org/Rokotyan/0556f8facbaf344507cdc45dc3622177

    var originalSvg = domElement.querySelector('svg');
    var clonedSvg = originalSvg.cloneNode(true);

    //Strip info icon
    var chartIcon = clonedSvg.querySelector('.chart-info-container');
    if (chartIcon) {
      chartIcon.remove();
    }

    var svgParent = domElement.querySelector('svg').parentNode;

    svgParent.appendChild(clonedSvg);
    originalSvg.style.visibility = 'hidden';

    //Get and set Sizes
    var svgWidth = originalSvg.getClientRects()[0].width;
    var svgHeight = originalSvg.getClientRects()[0].height;
    var exportWidth = svgWidth * sizeMultiplier;
    var exportHeight = svgHeight * sizeMultiplier;
    clonedSvg.setAttribute("height", exportHeight);
    clonedSvg.setAttribute("width", exportWidth);
    clonedSvg.setAttribute("viewBox", '0 0 ' + svgWidth / scale + ' ' + svgHeight / scale);

    //Convert css styles to inline styles (Modifies SVG in DOM)
    computedStyleToInlineStyle(clonedSvg, {
      recursive: true,
      properties: ["font-size", "text-decoration", 'font-family', 'stroke', 'stroke-width', 'fill']
    });
    var svgText = getSVGString(clonedSvg);
    //Removed cloned element and reinstate original svg
    clonedSvg.remove();
    originalSvg.style.visibility = 'visible';

    svgString2Image(svgText, exportWidth, exportHeight, domElement, function (dataBlob, filesize) {
      (0, _fileSaver.saveAs)(dataBlob, 'graph-image.png');
    });
  };

  exports.svgToImage = svgToImage;
  exports.svgString2Image = svgString2Image;
  exports.getSVGString = getSVGString;
  exports.computedStyleToInlineStyle = computedStyleToInlineStyle;
});
define('client/components/expanded-article-formulaic-revenues-metric', ['exports', 'client/components/article-metric'], function (exports, _articleMetric) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var ExpandedArticleRevenuesMetricComponent = _articleMetric.default.extend({
    layoutName: 'components/expanded-article-metric',
    classNameBindings: [":metric_revenue"],
    tooltipTitle: "The projected and/or accrued revenue as a result of the article.",
    metricTitle: Ember.computed.alias("profile.nameFormulaicRevenue"),
    iconClass: "metric-icon metric-revenue",
    conversionsTitle: Ember.computed.alias("profile.nameFormulaicRevenue"),
    formatter: function formatter(d) {
      var format = d < 1 && d > 0 ? ",.2f" : ",.0f";
      return "$" + d3.format(format)(d);
    },
    formattedMetricTotal: Ember.computed('metric', 'metricForDateRange', 'expandedArticleShowsCurrentSelection', function () {
      var formulaicRevenue;
      if (this.get('expandedArticleShowsCurrentSelection')) {
        formulaicRevenue = this.get('metricForDateRange');
      } else {
        formulaicRevenue = this.get('metric');
      }
      return this.formatter(formulaicRevenue);
    })

  });

  exports.default = ExpandedArticleRevenuesMetricComponent;
});
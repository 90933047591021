define('client/components/reports/report-image', ['exports', 'client/components/reports/base-widget', 'client/mixins/resize', 'ember-click-outside/mixin'], function (exports, _baseWidget, _resize, _mixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var SIZES = [{
    name: "large",
    displayName: "Large",
    rowspan: 4,
    colspan: 4
  }, {
    name: "medium",
    displayName: "Medium",
    rowspan: 2,
    colspan: 2
  }, {
    name: "small",
    displayName: "Small",
    rowspan: 1,
    colspan: 1
  }];

  exports.default = _baseWidget.default.extend(_resize.default, _mixin.default, {
    classNameBindings: [":report-image", "isEditText:report-base_edit"],
    attributeBindings: ["style"],

    sizes: SIZES,

    // used to black out everything
    isEditText: false,
    isEditImage: Ember.computed.alias("isEditText"),

    image: Ember.computed.alias("item.image"),

    imageTop: Ember.computed.alias("item.imageTop"),
    imageLeft: Ember.computed.alias("item.imageLeft"),
    imageScale: Ember.computed.alias("item.imageScale"),

    scaleRange: null,

    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      Ember.run.next(this, this.addClickOutsideListener);
      var image = this.get("image");
      this.set("promiseProxy.promise", image && image.then ? image : Ember.RSVP.resolve());
      this.notifyPropertyChange("style");
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);
      this.removeClickOutsideListener();
    },
    clickOutside: function clickOutside(e) {
      if (this.get('isEditImage')) {
        this.set("isEditImage", false);
        this.set("reportService.isEdit", false);
        // save when done editing
        this.get("item").touch();
      }
    },


    hasImage: Ember.computed.bool("image"),

    onResize: Ember.on("resize", function () {
      this.notifyPropertyChange("style");
    }),

    style: Ember.computed('image.original_url', "imageTop", "imageLeft", "imageScale", "hasImage", function () {
      if (this._state !== "inDOM") {
        return;
      }
      if (!this.get('imageScale') && this.get('hasImage')) {
        this.setProperties({ imageScale: Math.max(Math.min(this.$().width() / this.get('image.width'), 2), 0.1) });
      }

      var imageUrl = this.get('image.original_url'),
          top = this.get("imageTop"),
          left = this.get('imageLeft'),
          scale = this.get('imageScale'),
          h = this.get('image.height'),
          w = this.get('image.width'),
          containerHeight = this.$().height(),
          containerWidth = this.$().width();

      if (imageUrl) {
        return new Ember.String.htmlSafe(["background-repeat: no-repeat", 'background-image:url("' + imageUrl + '")', 'background-position: ' + (left + containerWidth / 2 - scale * w / 2) + 'px ' + (top + containerHeight / 2 - scale * h / 2) + 'px', scale ? 'background-size: ' + scale * w + 'px ' + scale * h + 'px' : null].compact().join(";"));
      } else {
        return new Ember.String.htmlSafe('');
      }
    }),

    mouseDown: function mouseDown(e) {
      if (!this.get("isEditImage")) {
        return;
      }
      this._down = {
        x: e.offsetX,
        y: e.offsetY,
        top: this.get("imageTop"),
        left: this.get('imageLeft')
      };
    },
    mouseUp: function mouseUp() {
      if (!this.get("isEditImage")) {
        return;
      }
      this._down = false;
    },
    mouseMove: function mouseMove(e) {
      if (!this._down) {
        return;
      }
      if (!this.get("isEditImage")) {
        return;
      }

      this.setProperties({
        imageLeft: e.offsetX - this._down.x + this._down.left,
        imageTop: e.offsetY - this._down.y + this._down.top
      });
    },
    click: function click(e) {
      if (Ember.$(e.target).closest(".report-tool-toolbar").length > 0 || this.get("reportService.isReadOnly")) {
        return;
      }
      e.preventDefault();
      if (this.get("image.original_url")) {
        e.stopImmediatePropagation();
        this.switchToEditMode();
      }
    },
    switchToEditMode: function switchToEditMode() {
      if (this.get("reportService.isReadOnly")) {
        return;
      }

      this._down = false;
      this.set("isEditImage", true);
      // Notify reports service that editing is in-progress to faciliate communication between components/views
      this.set("reportService.isEdit", true);
    },
    init: function init() {
      this._super.apply(this, arguments);
      this.set('scaleRange', this.get('scaleRange') || {
        min: 0.1, // from 10% size
        max: 2 // to 200% size
      });
    },


    actions: {
      onImageUploaded: function onImageUploaded(data) {
        var _this = this;

        var store = Ember.getOwner(this).lookup("service:store");
        // load the data into ember-data
        store.pushPayload("image", data);
        store.findRecord("image", data.image.id).then(function (image) {
          _this.set("image", image);
          // save this.
          _this.get("item").touch();
        });
      }
    }
  });
});
define('client/controllers/plan/media/new', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var ADD_BROADCAST = "plan/media/add-broadcasts";
  var ADD_PRINT = "plan/media/add-prints";
  var ADD_WEBSITE = "plan/media/add-websites";
  var LIST_NAME = "plan/media/list-name";

  var STEP_TO_COMPONENT = [LIST_NAME, ADD_WEBSITE, ADD_BROADCAST, ADD_PRINT];

  exports.default = Ember.Controller.extend({
    setup: function setup(model) {
      this.set("currentStep", 0);
      this.setProperties(model);
    },


    currentStep: 0,

    currentComponent: Ember.computed("currentStep", function () {
      return this.getComponentForStep(this.get('currentStep'));
    }),

    getComponentForStep: function getComponentForStep(step) {
      if (step > STEP_TO_COMPONENT.length) {
        return null;
      } else {
        return STEP_TO_COMPONENT[step];
      }
    },
    moveToComponent: function moveToComponent() {
      var up = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : true;

      var diff = up ? 1 : -1;

      var currentStep = this.get("currentStep");
      var nextComponent = this.getComponentForStep(currentStep + diff);

      if (Ember.isNone(nextComponent)) {
        this.transitionToRoute("plan.media");
      } else if (nextComponent === ADD_BROADCAST && !this.get('profileService.profile.hasBroadcast') || nextComponent === ADD_PRINT && !this.get('profileService.profile.hasPrint')) {
        // skip it
        this.set("currentStep", currentStep + diff);
        this.moveToComponent(up);
      } else {
        this.set("currentStep", currentStep + diff);
      }
    },


    actions: {
      next: function next(opts) {
        if (opts && opts.profileLinks) {
          this.set("profileLinks", opts.profileLinks);
        }
        this.moveToComponent();
      },
      back: function back() {
        var currentStep = this.get("currentStep");

        if (currentStep !== 0) {
          this.moveToComponent(false);
        }
      },
      cancel: function cancel() {
        this.transitionToRoute("plan.media");
      }
    }
  });
});
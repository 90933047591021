define('client/components/blog-impact-widget', ['exports', 'client/components/simple-widget', 'client/utils/date-format'], function (exports, _simpleWidget, _dateFormat2) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var BlogWidgetComponent = _simpleWidget.default.extend({
    title: "Owned Media Conversion",
    formatPct: function formatPct(v) {
      return d3.format(",.2%")(v);
    },
    text: Ember.computed(function () {
      return Ember.String.htmlSafe("<p>Owned Media Conversion represents the percentage of total unique visitors to your company's owned media channel(s) that go elsewhere on your website. This figure demonstrates how well your owned media channel(s) push unique visitors through the funnel towards deeper conversion.</p>");
    }),

    format: Ember.computed(function () {
      return function (v) {
        // If the interaction percentage is < 1, we need to show the actual value upto 2 decimal places
        if (v < 0.01 & v > 0.00) {
          return d3.format(",.2%")(v);
        } else {
          return d3.format(",%")(v);
        }
      };
    }),
    value: Ember.computed.oneWay("content.blog_impact"),
    iconClass: "db-widget-icon-impact"
  });

  exports.default = BlogWidgetComponent;
});
define('client/components/research/save-to-neo-messaging', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    store: Ember.inject.service(),
    modal: Ember.inject.service(),
    profileService: Ember.inject.service('profile'),

    profile: Ember.computed.alias('profileService.profile'),
    buckets: Ember.computed.alias('profile.profileMessageBuckets'),

    tagName: "span",

    showModal: false,
    messageToAdd: null,
    message: null,
    selectedBucket: null,
    showSuccess: false,

    constructModel: function constructModel() {
      var model = this.get('store').createRecord("message", {
        name: this.get('messageToAdd'),
        profile: this.get("profile"),
        profileMessageBucket: null
      });
      this.set('message', model);
    },


    actions: {
      openModal: function openModal() {
        this.constructModel();
        this.set('showModal', true);
      },
      cancel: function cancel() {
        this.set('showModal', false);
      }
    }
  });
});
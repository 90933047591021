define("client/models/whitelist-tag", ["exports", "ember-data"], function (exports, _emberData) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var WhitelistTag = _emberData.default.Model.extend({
    useOriginalAdapter: true,

    name: _emberData.default.attr("string"),
    profile: _emberData.default.belongsTo("profile", { inverse: 'whitelistTags' }),
    propagateCompetitors: _emberData.default.attr('boolean', { defaultValue: true }),
    updated_at: _emberData.default.attr("isodate"),

    exportToCSV: function exportToCSV(user) {
      var request = this.store.createRecord("export-request", {
        user: user,
        profile: this.get("profile"),
        sendEmail: true,
        generatorType: "Tag",
        generatorId: this.get("id")
      });
      return request.save();
    }
  });

  WhitelistTag.useOriginalAdapter = true;

  exports.default = WhitelistTag;
});
define('client/queries/research/similar-authors', ['exports', 'client/queries/query'], function (exports, _query) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _query.default.extend({
    store: Ember.inject.service(),
    ajax: Ember.inject.service(),
    load: function load() {
      var _getProperties = this.getProperties("store", "item", "ajax"),
          store = _getProperties.store,
          item = _getProperties.item,
          ajax = _getProperties.ajax,
          authorId = item.get("author.id"),
          profileId = item.get("profile.id"),
          search_term = item.get("searchTerm"),
          data = {
        author_id: authorId,
        profile_id: profileId,
        search_term: search_term
      };

      var promise = ajax.post("/v3/api/similar_authors", {
        data: data
      }).then(function (response) {
        // push into store and then reload
        store.pushPayload("author", response);

        return response.authors.map(function (author) {
          var reason = store.peekRecord("author-reason", author.id);
          return store.peekRecord("author", author.id);
        }).compact();
      });

      this.set("promise", promise);

      return this;
    }
  });
});
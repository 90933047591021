define('client/transforms/report-pages', ['exports', 'ember-data', 'client/utils/reports/page'], function (exports, _emberData, _page) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) {
    return typeof obj;
  } : function (obj) {
    return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
  };

  exports.default = _emberData.default.Transform.extend({
    serialize: function serialize(value) {
      return JSON.stringify(value.map(function (p) {
        if (p) {
          return p.serialize();
        }
      }), function (key, value) {
        if (key === "profile" && 'object' === (typeof value === 'undefined' ? 'undefined' : _typeof(value))) {
          return undefined;
        }
        if (key === "store") {
          return undefined;
        }

        return value;
      });
    },
    deserialize: function deserialize(value) {
      if (!value) {
        return [_page.default.create({
          report: this,
          page: 1,
          items: []
        })];
      }
      return JSON.parse(value).map(function (p) {
        return _page.default.deserialize(p);
      });
    }
  });
});
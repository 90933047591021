define('client/components/channel-filters', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var ChannelFilters = Ember.Component.extend({
    media_earned: true,
    media_owned: true,
    press_release: false,

    toggleMediaEarned: function toggleMediaEarned() {},
    toggleMediaOwned: function toggleMediaOwned() {},
    togglePressRelease: function togglePressRelease() {},


    actions: {
      toggleMediaEarned: function toggleMediaEarned() {
        this.toggleMediaEarned();
      },
      toggleMediaOwned: function toggleMediaOwned() {
        this.toggleMediaOwned();
      },
      togglePressRelease: function togglePressRelease() {
        this.togglePressRelease();
      }
    }
  });

  exports.default = ChannelFilters;
});
define("client/utils/-iso-format", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  // The format to serialize and deserialize date from the backend.
  var isoFormat = d3.time.format("%Y-%m-%d");
  var isoTZFormat = d3.time.format("%Y-%m-%dT%H:%M:%S%Z");

  exports.isoFormat = isoFormat;
  exports.isoTZFormat = isoTZFormat;
});
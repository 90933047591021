define('client/components/article-list', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['article-list'],
    classNameBindings: ['minView:article-list-minimal'],

    modal: Ember.inject.service(),
    profileService: Ember.inject.service('profile'),

    perPage: 50,
    offset: 0,
    isLoading: null,
    showOffset: true,
    minView: false,

    fromDate: null,
    toDate: null,
    errorMsg: 'There was an error loading the list',

    // loaded articles
    hits: Ember.A(),
    totalHits: 0,
    syndicateHits: 0,
    infinite: true,
    manualLoad: false,
    loadExternal: false,
    forceReloadOnUpdate: false,

    filter: true,

    list: null,

    query: Ember.computed.alias("list.dataQuery"),
    states: null,
    onCheckedArticle: function onCheckedArticle() {},


    // Filter out articles marked spam and irrelevant
    filteredHits: Ember.computed("filter", 'hits.length', 'totalHits', function () {
      var unfiltered = this.get('hits'),
          totalHits = this.get('totalHits'),
          syndicateHits = this.get('syndicateHits'),
          filtered = this.get('hits'),
          excludedHits = void 0;

      if (this.get("filter")) {
        var campaignId = this.get('profileService.campaign.id');

        var articleFilter = function articleFilter(article) {
          article = article.get("content") || article;
          return !article.get('spam') && article.get('relevance') && !article.hiddenForCampaign(campaignId);
        };
        filtered = unfiltered.filter(articleFilter);
        excludedHits = unfiltered.reject(articleFilter);

        // Adjust totalHits un loadMore is not triggered when an article is marked as spam or irrelevant
        if (excludedHits.length) {
          var excludedSyndicateHits = excludedHits.mapBy('syndicatesCount').reduce(function (sum, count) {
            return sum + count;
          });
          syndicateHits = syndicateHits - excludedSyndicateHits;
          totalHits = totalHits - excludedHits.length;
        }
      }

      var hits = {
        totalHits: totalHits,
        syndicateHits: syndicateHits,
        filtered: filtered
      };

      return filtered;
    }),

    init: function init() {
      this._super.apply(this, arguments);
      if (!this.get('list') || this.get('loadExternal')) {
        return;
      }
      this.set("hits", Ember.A());
      this.set("queries", Ember.A());
      this.set("states", {});
      this.get("reset")();
    },
    didReceiveAttrs: function didReceiveAttrs() {
      if (!this.get('list') || this.get('loadExternal')) {
        return;
      }
      // reset page offset when filter set is changed
      this.set("hits", Ember.A());
      this.set("queries", Ember.A());
      this.set("states", {});
      this.get("reset")();

      if (this.get('forceReloadOnUpdate')) {
        this.set('list.forceReload', true);
      }

      if (this.get("manualLoad")) {
        this.loadAtOffset(0);
      } else {
        Ember.run.debounce(this, "loadAtOffset", 0, 50);
      }
    },
    loadMore: function loadMore() {
      if (this.get('loadExternal')) {
        //If using manager component to load offset
        this.loadOffsetExternal(this.get("hits.length"));
        return;
      }
      if (this.get('infinite') && this.get("hits.length") && !this.get("isLoading")) {
        // change page
        this.loadAtOffset(this.get("hits.length"));
      }
    },
    loadAtOffset: function loadAtOffset(offset) {
      var _this = this;

      var list = this.get('list');
      if (!list || this.isDestroyed) {
        // sometimes this component is used without a list model
        // and just displays supplied articles
        //TODO:  Break this component out into 2:
        // 1. One that is responsible for querying logic
        // 2. One that is responsible for display logic
        return;
      }

      list.setProperties({
        offset: offset,
        perPage: this.get("perPage")
      });

      this.set("isLoading", true);
      this.get("loading")(this.get("filteredHits.length") > 0);

      var query = this.get("query");
      this.get("queries").push(query);

      query.then(function (search) {
        if (_this.isDestroyed) {
          return;
        }
        if (_this.get("queries").indexOf(query) < 0) {
          // query changed before the initial query was completed
          // discard the results
          return;
        }
        _this.set("errorMsg", null);
        var hits = _this.get("hits");
        _this.set("totalHits", search.get("total_hits"));
        _this.set('syndicateHits', search.get('syndicate_hits'));
        hits.addObjects(search.get("pagedContent"));
      }, function (msg) {
        if (_this.isDestroyed) {
          return;
        }
        msg = msg && msg.errors && msg.errors[0] && msg.errors[0].detail || msg;
        console.warn(msg);
        _this.set("errorMsg", msg);
      }).finally(function () {
        if (_this.get("queries").indexOf(query) >= 0 && !_this.isDestroyed) {
          _this.set("isLoading", false);
          _this.get("loading")(false);
        }
      });
    },


    // actions
    reset: function reset() {},
    loaded: function loaded() {},
    loading: function loading() {},
    loadOffsetExternal: function loadOffsetExternal() {},


    actions: {
      onCheckedArticle: function onCheckedArticle(article) {
        this.onCheckedArticle(article);
      },
      updateArticleList: function updateArticleList(item) {
        this.decrementProperty('totalHits');
        if (this.get('updateArticleStats')) {
          this.get('updateArticleStats')();
        }
        this.get('hits').removeObject(item);
      },
      loadOffsetExternal: function loadOffsetExternal(offset) {
        this.loadOffsetExternal(offset);
      }
    }

  });
});
define('client/components/simple-tag', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: "li",
    classNameBindings: [":tag", "editable:tag_editable"],
    editable: null,

    modal: Ember.inject.service(),
    untagArticle: function untagArticle() {},

    tagRendered: Ember.computed('article.tags', function () {
      return this.content;
    }),
    actions: {
      untagArticle: function untagArticle() {
        if (this.get("editable")) {
          if (this.article.hasSyndicates || this.article.hasFollowon) {
            this.get('modal').open('article-list/tag-syndicates-addition', {
              article: this.article,
              tag: this.content,
              untagArticleAction: this.untagArticle,
              untagArticleFlag: true
            });
          } else {

            this.untagArticle(this.article, this.content);
          }
          this.toggleRefresh();
        }
      }
    }
  });
});
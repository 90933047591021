define('client/components/new-location-filters', ['exports', 'client/utils/locations-by-region'], function (exports, _locationsByRegion) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var getLocationKey = function getLocationKey(loc) {
    return 'lcn_' + loc.id;
  };

  var northAmericaFilterKeys = _locationsByRegion.default.NA.map(getLocationKey);
  var northAmericaFilterKey = 'locationFilters.{' + northAmericaFilterKeys.join(',') + '}';

  var southAmericaFilterKeys = _locationsByRegion.default.SA.map(getLocationKey);
  var southAmericaFilterKey = 'locationFilters.{' + southAmericaFilterKeys.join(',') + '}';

  var europeFilterKeys = _locationsByRegion.default.EU.map(getLocationKey);
  var europeFilterKey = 'locationFilters.{' + europeFilterKeys.join(',') + '}';

  var africaFilterKeys = _locationsByRegion.default.AF.map(getLocationKey);
  var africaFilterKey = 'locationFilters.{' + africaFilterKeys.join(',') + '}';

  var asiaFilterKeys = _locationsByRegion.default.AS.map(getLocationKey);
  var asiaFilterKey = 'locationFilters.{' + asiaFilterKeys.join(',') + '}';

  var oceaniaFilterKeys = _locationsByRegion.default.OC.map(getLocationKey);
  var oceaniaFilterKey = 'locationFilters.{' + oceaniaFilterKeys.join(',') + '}';

  var antarcticaFilterKeys = _locationsByRegion.default.AN.map(getLocationKey);
  var antarcticaFilterKey = 'locationFilters.{' + antarcticaFilterKeys.join(',') + '}';

  var NewLocationFilters = Ember.Component.extend({
    northAmericaLocations: _locationsByRegion.default.NA,
    southAmericaLocations: _locationsByRegion.default.SA,
    europeLocations: _locationsByRegion.default.EU,
    africaLocations: _locationsByRegion.default.AF,
    asiaLocations: _locationsByRegion.default.AS,
    oceaniaLocations: _locationsByRegion.default.OC,
    antarcticaLocations: _locationsByRegion.default.AN,
    otherLocations: _locationsByRegion.default.XX,
    toggleLocation: function toggleLocation() {},
    checkRegion: function checkRegion() {},
    uncheckRegion: function uncheckRegion() {},


    selectedNorthAmericaLocationIds: Ember.computed(northAmericaFilterKey, 'northAmericaLocations.[]', function () {
      return this._selectedLocations(this.get('northAmericaLocations'));
    }),

    selectedSouthAmericaLocationIds: Ember.computed(southAmericaFilterKey, 'southAmericaLocations.[]', function () {
      return this._selectedLocations(this.get('southAmericaLocations'));
    }),

    selectedEuropeLocationIds: Ember.computed(europeFilterKey, 'europeLocations.[]', function () {
      return this._selectedLocations(this.get('europeLocations'));
    }),

    selectedAfricaLocationIds: Ember.computed(africaFilterKey, 'africaLocations.[]', function () {
      return this._selectedLocations(this.get('africaLocations'));
    }),

    selectedAsiaLocationIds: Ember.computed(asiaFilterKey, 'asiaLocations.[]', function () {
      return this._selectedLocations(this.get('asiaLocations'));
    }),

    selectedOceaniaLocationIds: Ember.computed(oceaniaFilterKey, 'oceaniaLocations.[]', function () {
      return this._selectedLocations(this.get('oceaniaLocations'));
    }),

    selectedAntarcticaLocationsIds: Ember.computed(antarcticaFilterKey, 'antarcticaLocations.[]', function () {
      return this._selectedLocations(this.get('antarcticaLocations'));
    }),

    _toggleRegion: function _toggleRegion(bool, region) {
      if (bool) {
        this.checkRegion(region);
      } else {
        this.uncheckRegion(region);
      }
    },

    _selectedLocations: function _selectedLocations(locations) {
      var _this = this;

      return locations.filter(function (location) {
        return _this.get('locationFilters.lcn_' + location.id);
      }).mapBy('id');
    },

    actions: {
      toggleLocation: function toggleLocation(locationId) {
        this.toggleLocation(locationId);
      },
      toggleAllNorthAmerica: function toggleAllNorthAmerica(event, value) {
        this._toggleRegion(value, 'NA');
      },
      toggleAllSouthAmerica: function toggleAllSouthAmerica(event, value) {
        this._toggleRegion(value, 'SA');
      },
      toggleAllEurope: function toggleAllEurope(event, value) {
        this._toggleRegion(value, 'EU');
      },
      toggleAllAfrica: function toggleAllAfrica(event, value) {
        this._toggleRegion(value, 'AF');
      },
      toggleAllAsia: function toggleAllAsia(event, value) {
        this._toggleRegion(value, 'AS');
      },
      toggleAllOceania: function toggleAllOceania(event, value) {
        this._toggleRegion(value, 'OC');
      },
      toggleAllAntarctica: function toggleAllAntarctica(event, value) {
        this._toggleRegion(value, 'AN');
      }
    }
  });

  exports.default = NewLocationFilters;
});
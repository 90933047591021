define('client/components/common/ui-card-drop', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var UiCardDropComponent = Ember.Component.extend({
    classNames: ["ui-card-drop"],
    classNameBindings: ["isDraggingOver:ui-card-over"],
    isDroppable: true,
    isDraggingOver: false,
    didDrop: function didDrop() {},


    browser: Ember.inject.service('browser'),

    dragEnter: function dragEnter(event) {
      this.set('isDraggingOver', true);
      event.preventDefault();
    },

    dragLeave: function dragLeave(event) {
      this.set('isDraggingOver', false);
      event.preventDefault();
    },

    dragOver: function dragOver(event) {
      event.preventDefault();
    },

    drop: function drop(event) {
      // Is there a better way to do this?  Need to reset state on this component,
      // but not save a duplicate
      if (!event.alreadyHandled) {
        var data = JSON.parse(event.dataTransfer.getData(this.get('browser.dragDataType')));
        this.didDrop(data);
        event.alreadyHandled = true;
      }
      this.set('isDraggingOver', false);
      event.preventDefault();
    }
  });

  exports.default = UiCardDropComponent;
});
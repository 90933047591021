define('client/components/dashboard/dashboard-card/header', ['exports', 'client/components/common/ui-card/ui-card-header'], function (exports, _uiCardHeader) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _uiCardHeader.default.extend({

    classNames: ["d-flex"],

    // parent dashboard card
    card: null,

    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);
      var card = this.get('card');
      if (card) {
        Ember.run.schedule('actions', this, function () {
          if (!this.get('isDestroyed')) {
            card.set('header', this);
          }
        });
      }
    },


    isEditable: Ember.computed("updateTitle", function () {
      return !!this.get("updateTitle");
    }),

    // Title properties
    title: "Add a title",

    toggleInfo: function toggleInfo() {}
  });
});
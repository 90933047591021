define('client/components/expanded-article-impact-score-metric', ['exports', 'client/components/article-metric', 'client/computed/index'], function (exports, _articleMetric, _index) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var TOOLTIP_TEXT = 'Impact Score is a qualitative metric that measures the absolute \nimpact of media coverage on your brand during a given time period.';

  var ExpandedArticleImpactScoreMetricComponent = _articleMetric.default.extend({
    classNames: ["metric-impact-score", "no-hover-metric", "metric"],

    iconClass: "metric-icon metric-impact-score",

    tooltipTitle: TOOLTIP_TEXT,

    metricTitle: Ember.computed.alias("profile.nameAverageImpactScore"),

    formattedMetricTotal: Ember.computed.alias("metric")
  });

  exports.default = ExpandedArticleImpactScoreMetricComponent;
});
define('client/controllers/insights/author', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    setup: function setup() {
      this._super();
      this.get('redirect');
    },

    /**
     * Observer like property used to check profile permissions to view this feature.
     * Features are available once the profile model is loaded.
     * Observer is not used because property will not be triggered if profile data is available.
     */
    redirect: Ember.computed('profile.showAuthorIntelligence', function () {
      if (!this.get('profileService.profile.showAuthorIntelligence')) {
        this.transitionToRoute('research');
      }
    }),

    actions: {
      goToAuthors: function goToAuthors() {
        this.replaceRoute('insights');
      }
    }
  });
});
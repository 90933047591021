define('client/initializers/location-data', ['exports', 'client/utils/locations-by-region'], function (exports, _locationsByRegion) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    name: 'location-data',
    initialize: function initialize(application) {

      application.register('location-data:main', _locationsByRegion.locationData, {
        instantiate: false,
        singleton: true
      });
      application.inject('controller', 'locationData', 'location-data:main');
      application.inject('model', 'locationData', 'location-data:main');
    }
  };
});
define("client/utils/search/constants", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var TEXT_TYPE = "text";
  var SELECT_TYPE = "select";
  var RANGE_TYPE = "range";
  var INEXACT_TYPE = "inexact";

  var modifiers = {};

  modifiers[RANGE_TYPE] = [{
    name: "gt",
    displayName: "Greater than",
    value: ">= {@}"
  }, {
    name: "lt",
    displayName: "Less than",
    value: "<= {@}"
  }];

  modifiers[SELECT_TYPE] = [{
    name: "exact-select",
    displayName: "Exact",
    value: "\"{@}\""
  }, {
    name: "similar",
    displayName: "Similar",
    value: "\"{@}\"~4"
  }, {
    name: "not",
    displayName: "Not",
    value: "NOT \"{@}\""
  }];

  modifiers[TEXT_TYPE] = [{
    name: "exact",
    displayName: "Exact",
    value: "CASE \"{@}\""
  }, {
    name: "similar",
    displayName: "Similar",
    value: "\"{@}\"~4"
  }, {
    name: "not",
    displayName: "Not",
    value: "NOT \"{@}\""
  }, {
    name: "exact-select",
    displayName: "Case insensitive",
    value: "\"{@}\""
  }];

  modifiers[INEXACT_TYPE] = [{
    name: "similar",
    displayName: "Similar",
    value: "\"{@}\"~4"
  }, {
    name: "not",
    displayName: "Not",
    value: "NOT \"{@}\""
  }];

  exports.default = {
    // TYPES
    TEXT_TYPE: TEXT_TYPE,
    SELECT_TYPE: SELECT_TYPE,
    RANGE_TYPE: RANGE_TYPE,
    INEXACT_TYPE: INEXACT_TYPE,

    // MODIFIERS
    MODIFIERS: modifiers
  };
});
define("client/models/account-user", ["exports", "ember-data"], function (exports, _emberData) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var AccountUser = _emberData.default.Model.extend({
    role: _emberData.default.attr("string"),
    name: _emberData.default.attr("string"),
    email: _emberData.default.attr("string")
  });

  AccountUser.pathForType = "account_users";
  exports.default = AccountUser;
});
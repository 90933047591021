define('client/models/translation-issue', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var TranslationIssue = _emberData.default.Model.extend({
    comment: _emberData.default.attr('string'),
    translation_task: _emberData.default.belongsTo('translationTask')
  });
  TranslationIssue.pathForType = "translation_issues";

  exports.default = TranslationIssue;
});
define('client/models/publisher', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var PUBLICATION_TIERS = {
    tier_0: "Syndicates",
    tier_1: "Top News Sources",
    tier_2: "Top Regional Sources",
    tier_3: "Trade and Country Sources",
    tier_4: "Newswire and Local Sources",
    tier_5: "Message Boards and Blogs"
  };

  exports.default = _emberData.default.Model.extend({
    authors: _emberData.default.hasMany('author'),
    publicationName: _emberData.default.attr('string'),
    subdomain: _emberData.default.attr('string'),
    country: _emberData.default.attr("string"),
    state: _emberData.default.attr("string"),
    syndicate: _emberData.default.attr("boolean"),

    publicationTier: _emberData.default.attr('number'),
    publicationTierName: Ember.computed("publicationTier", function () {
      return PUBLICATION_TIERS['tier_' + this.get("publicationTier")];
    })
  });
});
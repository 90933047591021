define('client/routes/plan/media/edit', ['exports', 'client/models/profile-link-filter'], function (exports, _profileLinkFilter) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    model: function model(params) {
      return Ember.RSVP.hash({
        model: this.store.findRecord("whitelist-tag", params.id),
        profileLinks: this.store.query("profile-link-filter", {
          whitelist_tag_id: params.id,
          profile_id: this.get("profileService.profile.id"),
          type: [_profileLinkFilter.ProfileLinkFilterTypes.WHITELIST, _profileLinkFilter.ProfileLinkFilterTypes.BROADCAST, _profileLinkFilter.ProfileLinkFilterTypes.PRINT]
        })
      });
    }
  });
});
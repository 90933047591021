define('client/components/research/publication-manager', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({

    filteredPublication: Ember.computed('publication', {
      get: function get() {
        return this.get('publication');
      },
      set: function set(k, v) {
        return v;
      }
    }),

    term: Ember.computed('searchTerm', {
      get: function get() {
        return this.get('searchTerm');
      },
      set: function set(k, v) {
        return v;
      }
    }),

    actions: {
      onSearchTerm: function onSearchTerm(searchTerm) {
        this.set('term', searchTerm);
      }
    }
  });
});
define('client/controllers/insights', ['exports', 'ember-parachute', 'client/mixins/profile-service-mixin'], function (exports, _emberParachute, _profileServiceMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var InsightsQP = new _emberParachute.default({
    status: {
      as: 'status',
      defaultValue: "new"
    },
    insightId: {
      as: 'insight',
      defaultValue: null
    }
  });

  exports.default = Ember.Controller.extend(_profileServiceMixin.default, InsightsQP.Mixin, {

    profileService: Ember.inject.service('profile'),
    router: Ember.inject.service(),
    profile: Ember.computed.alias('profileService.profile'),

    init: function init() {
      if (this.get('profile.isLoaded') && !this.get('profile.hasInsights') && !this.get('currentUser.isSuperAdminEngineer')) {
        this.transitionToRoute('dashboard');
      }
      this._super.apply(this, arguments);
    },

    profileDidChange: function profileDidChange(profile) {
      if (this.get('router.currentRouteName').startsWith('insights.')) {
        if (!this.get('profile.hasInsights') && !this.get('currentUser.isSuperAdminEngineer')) {
          this.transitionToRoute('dashboard');
        }
      }
    },


    selectedQuestion: '',

    actions: {
      selectInsight: function selectInsight(insight) {
        if (Ember.isEqual(insight.get('id'), this.get('insightId'))) {
          this.set('insightId', null);
        } else {
          this.set('insightId', insight.get('id'));
        }
      },
      changeStatus: function changeStatus(status) {
        this.set('status', status);
        this.set('insightId', null);
      },
      selectQuestion: function selectQuestion(question) {
        this.set('selectedQuestion', question);
        this.set('insightId', null);
      },
      clearQuestion: function clearQuestion() {
        this.set('selectedQuestion', '');
      },
      resetSelectedInsight: function resetSelectedInsight() {
        this.set('insightId', null);
      }
    }
  });
});
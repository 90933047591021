define('client/components/common/ui-drawer', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ["ui-drawer"],

    card: null,

    title: "Give me a title",
    opened: false,

    onToggle: function onToggle() {},


    actions: {
      toggle: function toggle() {
        this.get("onToggle")(this.toggleProperty("opened"));
      }
    }
  });
});
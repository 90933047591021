define('client/components/hv-slider', ['exports', 'client/mixins/resize'], function (exports, _resize) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var TRANSLATE_PATTERN = "-ms-transform:translate(Xpx,Ypx);-webkit-transform:translate(Xpx,Ypx);transform:translate(Xpx,Ypx);";

  exports.default = Ember.Component.extend(_resize.default, {
    classNameBindings: [":slider", "horizontal:h-slider:v-slider"],
    showSliders: true,
    showBeginSlider: false,
    showEndSlider: false,
    contentSize: 0,
    translate: 0,
    margin: 10,

    contentStyle: Ember.computed("contentSize", "translate", function () {
      var horizontal = this.get("horizontal"),
          dimSize = horizontal ? "width" : "height",
          translate = "" + this.get("translate"),
          size = dimSize + ':' + this.get("contentSize") + 'px;';
      var translation;

      if (horizontal) {
        translation = TRANSLATE_PATTERN.replace(/Y/g, 0).replace(/X/g, translate);
      } else {
        translation = TRANSLATE_PATTERN.replace(/X/g, 0).replace(/Y/g, translate);
      }
      return new Ember.String.htmlSafe([translation].join(""));
    }),

    onResize: Ember.on("resize", "didInsertElement", function () {
      if (this._state !== "inDOM") {
        return;
      }

      var horizontal = this.get("horizontal"),
          dimSize = horizontal ? "width" : "height",
          move = this.$(".slider-move"),
          parentSize = this.$(".slider-bd")[dimSize]();
      var size = 0;

      // calc the real size of all content
      move.children().each(function () {
        size = realSize(Ember.$(this), size, horizontal);
      });
      // show or hide slider buttons as needed.
      if (size <= parentSize) {
        size = parentSize + 2;
        this.set("showSliders", false);
      } else {
        this.set("showSliders", true);
      }

      // set the size that will refresh the slider on screen
      this.set("contentSize", size);
    }),

    actions: {
      scrollBegin: function scrollBegin() {
        var horizontal = this.get("horizontal"),
            dimSize = horizontal ? "width" : "height";

        // calc the new translation
        var size = this.$(".slider-bd")[dimSize](),
            translate = this.get("translate");
        var newTranslate = translate + size;

        if (newTranslate > 0) {
          newTranslate = 0;
        }

        // update the sliders state
        if (newTranslate === 0) {
          this.set("showBeginSlider", false);
        } else {
          this.set("showBeginSlider", this.get("showSliders"));
        }
        this.set("showEndSlider", this.get("showSliders"));

        // translate
        this.set("translate", newTranslate);
      },
      scrollEnd: function scrollEnd(scrollToVeryEnd) {
        var horizontal = this.get("horizontal"),
            dimSize = horizontal ? "width" : "height";

        // calc the new translation
        var size = this.$(".slider-bd")[dimSize](),
            contentSize = this.get("contentSize"),
            translate = -this.get("translate"),
            max = contentSize - size + this.get("margin"); // + 10 is to leave some margin below the bottom-most slide
        var newTranslate = translate + size;

        if (newTranslate >= max || scrollToVeryEnd) {
          newTranslate = max;
        }

        // update the sliders state
        if (newTranslate === max) {
          this.set("showEndSlider", false);
        } else {
          this.set("showEndSlider", this.get("showSliders"));
        }
        this.set("showBeginSlider", this.get("showSliders"));

        // translate
        this.set("translate", -newTranslate);
      }
    }
  });


  var realSize = function realSize(child, size, horizontal) {
    if (!size) {
      size = 0;
    }
    if (horizontal) {
      size += child.outerWidth();
      size += parseInt(child.css("marginLeft"));
      size += parseInt(child.css("marginRight"));
    } else {
      size += child.outerHeight();
      size += parseInt(child.css("marginTop"));
      size += parseInt(child.css("marginBottom"));
    }
    return size;
  };
});
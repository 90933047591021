define('client/mixins/location-filters', ['exports', 'client/utils/filter', 'client/utils/parent-filter'], function (exports, _filter, _parentFilter) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) {
    return typeof obj;
  } : function (obj) {
    return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
  };

  var REGIONS_MAP = {
    NA: { rank: 1, title: 'North America' },
    SA: { rank: 2, title: 'South America' },
    EU: { rank: 3, title: 'Europe' },
    AF: { rank: 4, title: 'Africa' },
    AS: { rank: 5, title: 'Asia' },
    OC: { rank: 6, title: 'Oceania' }
  };

  var LOCATION_REGEX = RegExp(/^lcn_[0-9]+$/);

  exports.default = Ember.Mixin.create({
    defaultLocationFilters: null,

    hasDefaultLocationFilters: Ember.computed('defaultLocationFilters.[]', function () {
      var filters = this.get('defaultLocationFilters');
      return !!(filters && Ember.isArray(filters) && !Ember.isEmpty(filters));
    }),

    locationData: Ember.computed(function () {
      return Ember.getOwner(this).lookup('location-data:main');
    }),

    regions: Ember.computed('defaultLocationFilters.[]', 'selectedLocationFilters.[]', function () {
      var regions = this._createRegions(),
          locationData = this.get('locationData');

      for (var i = 0; i < locationData.length; i++) {
        //e.g. countryTuple structure - ["Albania", "ALB", "EU"],
        var countryTuple = locationData[i],
            key = countryTuple[2];

        if (!regions.hasOwnProperty(key)) {
          continue;
        }
        var regionObject = regions[key],
            countryObject = this._createCountryObj(countryTuple, i, regionObject);

        if (!countryObject) {
          continue;
        }
        regionObject.children.push(countryObject);
        regionObject.updateValue();
      }

      return regions;
    }).volatile(),

    locationFilters: Ember.computed('defaultLocationFilters.[]', 'regions.[]', function () {
      var regionArray = Ember.A();
      var regions = this.get('regions');
      for (var regionCode in regions) {
        var regionObject = Ember.get(regions, regionCode);
        if (regionObject.includeWhenNoConfig || !Ember.isEmpty(regionObject.children)) {
          regionArray.push(regionObject);
        }
      }
      regionArray.sort(function (a, b) {
        return a.rank - b.rank;
      });
      return regionArray;
    }),

    deserializeLocationFilters: function deserializeLocationFilters(serializedLocationFilters) {
      var locationFilters = this.get('locationFilters');

      locationFilters.forEach(function (region) {
        if (Ember.isEmpty(region.children)) {
          return;
        }

        region.children.forEach(function (countryFilter) {
          var countryFilterName = Ember.get(countryFilter, 'name'),
              value = serializedLocationFilters[countryFilterName];

          if ((typeof value === 'undefined' ? 'undefined' : _typeof(value)) === undefined) {
            return;
          }
          Ember.set(countryFilter, 'value', value);
        });
      });
    },
    groupFilters: function groupFilters(serializedFilters) {
      var organizedFilters = {
        location: {
          all: {},
          // Ensure whitelist is always restrictive by including fake country.
          whitelist: { 'lcn_1': true },
          blacklist: {}
        },
        nonLocation: {}
      };

      for (var filterKey in serializedFilters) {
        var val = serializedFilters[filterKey];

        if (!LOCATION_REGEX.test(filterKey)) {
          organizedFilters.nonLocation[filterKey] = val;
          continue;
        }

        var stateKey = val ? 'whitelist' : 'blacklist';
        organizedFilters.location.all[filterKey] = val;
        organizedFilters.location[stateKey][filterKey] = val;
      }

      return organizedFilters;
    },


    /**
    * transforms the map of locations to either a map of only chosen countries
    * or an empty map to signify all countries are currently chosen.
    *
    * TODO: Blacklisting (excluded countries) as an optimization needs to be
    * implenented on the back-end
    */
    processLocationFilters: function processLocationFilters(serialized) {
      var groupedFilters = this.groupFilters(serialized),
          blacklistedLocations = groupedFilters.location.blacklist,
          whitelistedLocations = groupedFilters.location.whitelist,
          nonLocationFilters = groupedFilters.nonLocation,
          locationBlacklistCount = Object.keys(blacklistedLocations).length,
          locationWhitelistCount = Object.keys(whitelistedLocations).length;

      // Use no location filters if all countries are selected.
      if (!locationBlacklistCount) {
        return nonLocationFilters;
      }

      // If we have at least one normal country selected, we don't need to
      // include the nonexistent country key.
      var threshold = whitelistedLocations['lcn_0'] ? 2 : 1;
      if (locationWhitelistCount > threshold) {
        delete whitelistedLocations['lcn_1'];
      }

      return Ember.merge(nonLocationFilters, whitelistedLocations);
    },

    _createRegions: function _createRegions() {
      var regions = {};

      for (var key in REGIONS_MAP) {
        regions[key] = this._createRegionObj(REGIONS_MAP[key], key);
      }

      // Add "Other"
      var unknownRegion = this._createUnknownRegionObj();
      regions['XXX'] = unknownRegion;

      return regions;
    },
    _createRegionObj: function _createRegionObj(region, key) {
      return _parentFilter.default.create({
        name: Ember.get(region, 'name'),
        key: key,
        title: Ember.get(region, 'title'),
        isRegion: true,
        value: true,
        children: [],
        rank: Ember.get(region, 'rank'),
        addSpacerIfNoChildren: true
      });
    },
    _createUnknownRegionObj: function _createUnknownRegionObj() {
      var unknownRegionValue = false,
          hasDefaultLocations = this.get('hasDefaultLocationFilters'),
          defaultFilters = this.get("defaultLocationFilters"),
          selectedLocationFilters = this.get("selectedLocationFilters"),
          isCountrySet = true;

      if (hasDefaultLocations && Ember.isArray(defaultFilters)) {
        unknownRegionValue = defaultFilters.indexOf('XXX') >= 0;
      }

      if (Ember.isArray(selectedLocationFilters)) {
        isCountrySet = selectedLocationFilters.indexOf("lcn_0") >= 0 || selectedLocationFilters.length === 0;
      }

      return _filter.default.create({
        name: "lcn_0",
        title: "Unknown",
        key: "Unknown",
        value: isCountrySet,
        default: true,
        rank: 7,
        parent: null,
        emphasized: true,
        addSpacerIfNoChildren: true,
        includeWhenNoConfig: !hasDefaultLocations || unknownRegionValue
      });
    },
    _createCountryObj: function _createCountryObj(countryTuple, locationIndex, regionObject) {
      var isCountrySet = true,
          // selected by default
      key = countryTuple[2],
          selectedLocationFilters = this.get("selectedLocationFilters"),
          hasDefaultLocations = this.get('hasDefaultLocationFilters'),
          defaultFilters = this.get("defaultLocationFilters");

      if (Ember.isArray(selectedLocationFilters)) {
        isCountrySet = selectedLocationFilters.indexOf(countryTuple[1]) >= 0 || selectedLocationFilters.indexOf("lcn_" + locationIndex) >= 0 || selectedLocationFilters.length === 0;
      }

      // if there is a pre-configured location filter, show only those. Otherwise, show all.
      if (!hasDefaultLocations || hasDefaultLocations && defaultFilters.indexOf(countryTuple[1]) >= 0) {
        return _filter.default.create({
          name: "lcn_" + locationIndex,
          title: countryTuple[0],
          key: countryTuple[1],
          // always start checked if no default filters, otherwise only check if the default filters say so
          value: isCountrySet,
          parent: regionObject
        });
      }
    }
  });
});
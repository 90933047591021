define('client/routes/research/trends', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    titleToken: "Trends",

    model: function model() {
      return this.store.query('saved-search', {
        type: 1
      });
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);
      controller.send("triggerSearch");
    }
  });
});
define('client/mixins/profile-link-filter-controller', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var ProfileLinkFilterControllerMixin = Ember.Mixin.create({
    //controllers that use this mixin
    //should set this to a correct value
    type: -1,

    links: Ember.computed(function () {
      var owner = Ember.getOwner(this);
      var findPagingController = owner.lookup('controller:find-paging');

      findPagingController.setProperties({
        model: [],
        type: "profile_link_filter",
        extraParams: {
          "type": this.get("type")
        },
        store: this.store
      });
      Ember.run.later(this, "paramsDidChange");
      return findPagingController;
    }),

    resources: Ember.computed.alias("links"),

    openedForm: false,

    actions: {
      linkAdded: function linkAdded() {
        this.notifyPropertyChange("type"); // force reload of list
      },


      switchToProfile: function switchToProfile(profile, context) {
        if (!context) {
          // table profile switch
          this.set("profile", profile);
        } else {
          // newLink profile switch
          this.set("newLink.profile", profile);
        }
      }
    }
  });

  exports.default = ProfileLinkFilterControllerMixin;
});
define('client/helpers/transition-to-research-route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
        arr2[i] = arr[i];
      }

      return arr2;
    } else {
      return Array.from(arr);
    }
  }

  exports.default = Ember.Helper.extend({
    router: Ember.inject.service(),

    onNewRoute: Ember.observer('router.currentRouteName', function () {
      this.recompute();
    }),

    compute: function compute(params, queryParams) {
      var _this = this;

      return function (authorOrPublication) {
        var _get;

        var method = "transitionTo";
        authorOrPublication = authorOrPublication || params[1];
        if (queryParams) {
          if (queryParams.replaceWith) {
            method = "replaceWith";
          }
          queryParams = { queryParams: queryParams };
        }
        var segments = [authorOrPublication, queryParams].compact();
        var baseRoute = _this.get("router.currentRouteName").replace(/\.index$/, "").replace(/\.author$/, "").replace(/\.publication$/, "");
        return (_get = _this.get('router'))[method].apply(_get, [baseRoute + '.' + params[0]].concat(_toConsumableArray(segments)));
      };
    }
  });
});
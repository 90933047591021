define('client/models/dashboard-card-category', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var forCompetitor = function forCompetitor(list) {
    var type = list + '.@each.{forCompetitor,shouldShow}';
    return Ember.computed(type, "profileService.profile", function () {

      var templates = this.get(list);
      if (!templates) {
        return [];
      }
      var all = templates.filterBy("shouldShow", true),
          competitor = this.get("profileService.profile.isCompetitor");
      if (!competitor) {
        return all;
      }

      var profile = this.get('profileService.profile');
      var showCustomer = profile.get('hasAnalytics') && profile.get('showCustomer');
      var showInteraction = profile.get('hasAnalytics') && profile.get('showInteraction');
      var showReach = profile.get('showReach');

      return all.filter(function (template) {
        var isVisitor = template.get('isVisitor') || template.get('widgetType') === 'visitors';
        var isInteraction = template.get('isInteraction') || template.get('widgetType') === 'interaction';
        var isReach = template.get('widgetType') === 'reach';

        return template.get('forCompetitor') || showCustomer && isVisitor || showInteraction && isInteraction || showReach && isReach;
      });
    });
  };

  exports.default = _emberData.default.Model.extend({
    name: _emberData.default.attr('string'),
    displayName: _emberData.default.attr('string'),
    position: _emberData.default.attr('number'),

    graphTemplates: _emberData.default.hasMany('graph-template'),
    allGraphTemplates: forCompetitor("graphTemplates"),

    articleListTemplates: _emberData.default.hasMany('article-list-template'),
    allArticleListTemplates: forCompetitor("articleListTemplates"),

    dashboardWidgetTemplates: _emberData.default.hasMany('dashboard-widget-template'),
    allDashboardWidgetTemplates: forCompetitor("dashboardWidgetTemplates"),

    allTemplates: Ember.computed.union('allGraphTemplates', 'allArticleListTemplates', 'allDashboardWidgetTemplates')
  });
});
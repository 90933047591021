define('client/transforms/json', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Transform.extend({
    serialize: function serialize(value) {
      return JSON.stringify(value);
    },
    deserialize: function deserialize(value) {
      return JSON.parse(value);
    }
  });
});
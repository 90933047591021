define('client/components/tag-filters', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var TagFilters = Ember.Component.extend({

    tagsSortBy: null,
    tagsSorted: Ember.computed.sort('tags', 'tagsSortBy'),
    toggleTag: function toggleTag() {},
    toggleNotTag: function toggleNotTag() {},

    showSelectAllNone: true,

    select: function select(on) {
      var _this = this;

      var filters = this.get("tagFilters");
      this.get("tags").forEach(function (tag) {

        var id = tag.get("id"),
            key = 'tag_' + id,
            notKey = 'tag_' + id + ':not';
        if (on && !filters[key] || filters[key] === "not" || !on && filters[key]) {
          if (filters[key] === "not") {
            _this.toggleNotTag(id);
            if (on) {
              _this.toggleTag(id);
            }
          } else {
            _this.toggleTag(id);
          }
        }
      });
    },
    init: function init() {
      this._super.apply(this, arguments);
      this.set('tagsSortBy', this.get('tagsSortBy') || ['name']);
    },


    actions: {
      selectAll: function selectAll() {
        this.select(true);
      },
      selectNone: function selectNone() {
        this.select(false);
      },
      toggleTag: function toggleTag(tagId) {
        this.toggleTag(tagId);
      },
      toggleNotTag: function toggleNotTag(tagId) {
        this.toggleNotTag(tagId);
      }
    }
  });

  exports.default = TagFilters;
});
define('client/mixins/filter-controller', ['exports', 'client/utils/filter', 'client/mixins/location-filters', 'client/mixins/volume-filter', 'deep-equal'], function (exports, _filter, _locationFilters, _volumeFilter, _deepEqual) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.defaultContentFilters = exports.defaultChannelFilters = exports.defaultToneFilters = exports.defaultAmplificationFilters = exports.defaultTagFilters = undefined;


  var defaultContentFilters = function defaultContentFilters() {
    return [_filter.default.create({
      name: "news",
      title: "Digital",
      tooltip: 'Short and long form content published online',
      value: true
    }), _filter.default.create({
      name: "linkedin_pulse",
      title: "LinkedIn Pulse",
      tooltip: 'Long form posts published on LinkedIn Pulse by or about your brand',
      value: true
    }), _filter.default.create({
      name: "twitter",
      title: "Twitter",
      tooltip: 'Posts created on Twitter about your brand or your PR content'
    }), _filter.default.create({
      name: "gplus",
      title: "Google+",
      tooltip: 'Posts created on Google+ about your brand or your PR content'
    })];
  };

  var defaultChannelFilters = function defaultChannelFilters() {
    return [_filter.default.create({
      name: "media_earned",
      title: "Earned",
      value: true,
      tooltip: "Publishing is controlled by a 3rd party"
    }), _filter.default.create({
      name: "media_owned",
      title: "Owned",
      value: true,
      tooltip: "Publishing is controlled by you"
    }), _filter.default.create({
      name: "press_release",
      title: "Newswire",
      value: true,
      tooltip: 'Publishing and circulation is controlled by press release distributors'
    })];
  };

  var defaultAmplificationFilters = function defaultAmplificationFilters() {
    return [_filter.default.create({
      name: "amp_facebook",
      title: "Facebook",
      value: true
    }), _filter.default.create({
      name: "amp_twitter",
      title: "Twitter",
      value: true
    }), _filter.default.create({
      name: "amp_gplus",
      title: "Google+",
      value: true
    }), _filter.default.create({
      name: "amp_linkedin",
      title: "LinkedIn",
      value: true
    }), _filter.default.create({
      name: "amp_pinterest",
      title: "Pinterest",
      value: true
    })];
  };

  var defaultTagFilters = function defaultTagFilters(profile) {
    var tags = profile.get("tags");
    if (!tags) {
      return [];
    }
    return profile.get("tags").sortBy('name').map(function (tag) {
      var title,
          first_name = tag.get("user_name").split(" ")[0];
      if (tag.get("visibility") === 0) {
        // private
        title = "<i class=\"fa fa-user\"></i>".htmlSafe();
      } else {
        var by = "";
        if (tag.get("user_id")) {
          by = " by " + first_name;
        }
        title = ("<i class=\"fa fa-users\" title=\"" + by + "\"></i>").htmlSafe();
      }
      return _filter.default.create({
        name: "tag_" + tag.get("id"),
        title: tag.get("name"),
        additionnalInfo: title,
        value: false
      });
    });
  };

  var defaultToneFilters = function defaultToneFilters() {
    return [_filter.default.create({
      name: "tone_positive",
      value: true,
      default: true
    }), _filter.default.create({
      name: "tone_neutral",
      value: true,
      default: true
    }), _filter.default.create({
      name: "tone_negative",
      value: true,
      default: true
    })];
  };

  var FilterControllerMixin = Ember.Mixin.create(_locationFilters.default, _volumeFilter.default, {
    _searchTerm: "",
    searchTerm: "",
    searchFullText: true,
    isLoading: false,

    showRelevanceOverride: Ember.computed("searchTerm", function () {
      return !Ember.isBlank(this.get("searchTerm"));
    }),

    whitelistTagIds: Ember.computed('whitelistTagIdsDefault', {
      get: function get() {
        return this.get('whitelistTagIdsDefault').toArray();
      },
      set: function set(key, value) {
        return value;
      }
    }),
    showUseInteractionCount: Ember.computed('selectedSort', function () {
      var selectedSort = this.get("selectedSort");
      return selectedSort === 'all_interaction' || selectedSort.match("^event_") || selectedSort.match("^goal_");
    }),

    _searchTermDidChange: Ember.observer('_searchTerm', function () {
      Ember.run.debounce(this, "debounceSearchTerm", 500);
    }),

    _searchFullTextDidChange: Ember.observer('searchFullText', function () {
      this.set("_searchTerm", "");
    }),

    debounceSearchTerm: function debounceSearchTerm() {
      if (this.get('isDestroyed')) {
        return;
      }
      this.set("searchTerm", this.get("_searchTerm"));
    },

    setFiltersFromSavedSelection: function setFiltersFromSavedSelection() {
      this._super.apply(this, arguments);
      var savedSelections = this.get('savedFilters.filters');
      if (savedSelections) {
        this.get('allFilters').forEach(function (filter) {
          var val;
          if (filter && (val = savedSelections[filter.get('name')]) !== null) {
            filter.set('value', val);
          }
        });
      }
    },

    _savedFilterSelection: null,
    savedFilterSelection: Ember.observer('activeProfile', function () {
      //code inside searchCriteria() will set it
      this.set('savedFilters', null);
    }),

    channelFilters: Ember.computed(function () {
      return defaultChannelFilters();
    }),

    contentFilters: Ember.computed(function () {
      return defaultContentFilters();
    }),

    relevance: Ember.computed('profile.defaultRelevance', function () {
      return _filter.default.create({
        name: 'relevance',
        title: 'relevance',
        value: this.get('profile.defaultRelevance')
      });
    }),

    toneFilters: Ember.computed(function () {
      return defaultToneFilters();
    }),

    forceNews: Ember.computed('controllers.application.currentRouteName', function () {
      var routeName = this.get("controllers.application.currentRouteName");
      return routeName !== 'profile.dashboard' && routeName !== 'profile.neo';
    }),

    // TODO(Tony) For some reason I can't find any ember-data state that will let me know
    // when the tag data has come back from the server after the POST and has been loaded
    // into the tag. Thus, I'm forced to do this hack here where I assume that if the
    // user_name is null than we have not finished retrieving this value from the server
    savedTags: Ember.computed('activeProfile.tags.@each.user_name', function () {
      return (this.get('activeProfile.tags') || []).filter(function (tag) {
        return tag.get('user_name');
      });
    }),

    tagFilters: Ember.computed("activeProfile.tag.@each.user_name", function (tag) {
      return defaultTagFilters(this.get("activeProfile"));
    }),

    amplificationFilters: Ember.computed(function () {
      return defaultAmplificationFilters();
    }),

    checkedLocationFilters: Ember.computed("flatLocationFilters", function () {
      return this.get('flatLocationFilters').filter(function (filter) {
        return filter && filter.get('value');
      });
    }).volatile(),

    flatLocationFilters: Ember.computed('locationFilters.[]', function () {
      return this.get('locationFilters').map(function (top) {
        return top.children || top;
      }).flatten();
    }),

    areFiltersAtDefaultSettings: Ember.computed('allFilters.@each.isDefault', '_savedFilterSelection.filters', 'volumeFiltersAreDefault', function () {
      if (this.get('_savedFilterSelection')) {
        return this.get('volumeFiltersAreDefault') && (0, _deepEqual.default)(this.get('_savedFilterSelection.filters'), this.get('serializedFilters'));
      } else {
        return this.get('volumeFiltersAreDefault') && this.get('allFilters').every(function (f) {
          return !f || f.get("isDefault");
        });
      }
    }),

    allFilters: Ember.computed('flatLocationFilters', function () {
      return this.get('flatLocationFilters').filter(function (f) {
        return f && f.get;
      }).concat(this.get("flatFilters"));
    }).volatile(),

    _flatFilters: Ember.A(),
    flatFilters: Ember.computed("contentFilters.@each.value", "channelFilters.@each.value", "tagFilters.@each.value", "amplificationFilters.@each.value", "toneFilters.@each.value", 'relevance.value', function () {
      if (this.get('_flatFilters.length')) {
        this.get('_flatFilters').removeAt(0, this.get('_flatFilters.length'));
      }
      this.get('_flatFilters').pushObjects(this.get("contentFilters"));
      this.get('_flatFilters').pushObjects(this.get("channelFilters"));
      this.get('_flatFilters').pushObjects(this.get("tagFilters"));
      this.get('_flatFilters').pushObjects(this.get("amplificationFilters"));
      this.get('_flatFilters').pushObjects(this.get("toneFilters"));

      if (this.get('profile.hasRelevanceSlider')) {
        this.get('_flatFilters').pushObject(this.get('relevance'));
      }
      return this.get('_flatFilters');
    }),

    resetFilters: function resetFilters() {
      this._super.apply(this, arguments);
      this.setProperties({
        searchTerm: '',
        _searchTerm: ''
      });
      if (!this.get("areFiltersAtDefaultSettings")) {
        if (this.get('_savedFilterSelection')) {
          this.setFiltersFromSavedSelection();
        } else {
          var filters = this.get("flatFilters");
          filters.forEach(function (f) {
            f.reset();
          });

          if (this.get('locationFilters')) {
            this.get("locationFilters").forEach(function (f) {
              var children = f.get('children');
              if (children) {
                children.forEach(function (c) {
                  if (c) {
                    c.reset();
                  }
                });
              } else {
                f.reset();
              }
            });
          }
        }
      }
    },

    deserializeFilters: function deserializeFilters(hash) {

      this._deserializing = true;

      var serialized = Ember.merge({}, hash.filters),
          filters = this.get("flatFilters");

      // deserialize each filters
      filters.forEach(function (f) {
        return f.deserialize(serialized);
      });

      // check relevance
      if (this.get('activeProfile.hasRelevanceSlider') && serialized.relevance !== undefined) {
        this.set('relevance', serialized.relevance);
        delete serialized.relevance;
      }

      // What is left are locations
      this.deserializeLocationFilters(serialized);
      this._deserializing = false;
    },


    serializedFilters: Ember.computed(function () {
      if (!this.get('_savedFilterSelection')) {
        var filterSelection = this.get('store').peekRecord('saved-filter-selection', this.get('activeProfile.id'));
        this.set('_savedFilterSelection', filterSelection); // eslint-disable-line
        this.setFiltersFromSavedSelection();
      }

      // flatten all filters
      var filters = this.get("flatFilters");
      // serialize them
      var serialized = filters.reduce(function (serialized, filter) {
        Ember.merge(serialized, filter.serialize());
        return serialized;
      }, {});

      if (!this.get('forceNews')) {
        serialized.news = true;
      }

      var locations = this.get("flatLocationFilters");

      var locationData = Ember.getOwner(this).lookup('location-data:main');

      var foundLcn1 = false;
      locations.forEach(function (f) {
        foundLcn1 = f.get('name') === 'lcn_1';
        serialized[f.get('name')] = Boolean(f.get('value'));
      });

      if (!foundLcn1) {
        serialized['lcn_1'] = false;
      }
      if (this.get('profile.isLoaded')) {
        serialized.whitelist_tag_ids = this.get('whitelistTagIds');
      }
      serialized.influential = this.get('influential');
      serialized.uninfluential = this.get('uninfluential');
      serialized = this.processLocationFilters(serialized);
      if (this.get('activeProfile.hasRelevanceSlider')) {
        serialized['relevance'] = this.get('relevance.value');
      }
      return serialized;
    }).volatile(),

    cumulative: Ember.computed(function () {
      return this.get('selectedSort') === 'date' && this.get("featureFlags.coverage_cumulative_graph");
    }).volatile(),

    searchCriteria: Ember.computed(function () {
      return {
        profile_id: this.get("activeProfile._profile_id"),
        campaign_id: this.get("activeProfile.campaign_id"),
        full_text: this.get("searchFullText"),
        filters: this.get("serializedFilters"),
        sort: this.get("selectedSort"),
        from: this.get("fromDate"),
        to: this.get("toDate"),
        search_term: this.get("searchTerm") || "",
        search_type: this.get("searchType") || "",
        cumulative: this.get("cumulative"),
        relevance_override: this.get("effectiveRelevanceOverride"),
        useInteractionCount: this.get("useInteractionCount"),
        customYearEndOffset: this.get('activeProfile.customFiscalYearOffset')
      };
    }).volatile(),

    effectiveRelevanceOverride: Ember.computed('relevanceOverride', 'searchTerm', 'selectedSort', function () {
      return this.get('relevanceOverride') && Ember.isPresent(this.get('searchTerm')) && this.get("selectedSort") === 'date';
    }),

    _filtersDidChange: Ember.observer("activeProfile._profile_id", "activeProfile.campaign_id", "relevanceOverride", "fromDate", "toDate", "selectedSort", "useInteractionCount", "flatLocationFilters.@each.value", "contentFilters.@each.value", "channelFilters.@each.value", "amplificationFilters.@each.value", "toneFilters.@each.value", "whitelistTagIds.length", "influential", "uninfluential", "tagFilters.@each.value", "searchTerm", "searchFullText", "relevance.value", function () {
      if (this._deserializing) {
        return;
      }
      this.set("isLoading", true);
      Ember.run.debounce(this, "__filtersDidChange", 600);
    }),

    __filtersDidChange: function __filtersDidChange() {
      if (this.get('isDestroyed')) {
        return;
      }
      this.trigger("filtersDidChange", this.get('searchCriteria'));
    },

    actions: {

      saveFilterSelection: function saveFilterSelection() {
        var _this = this;

        if (!this.get('areFiltersAtDefaultSettings')) {
          this.store.filter('saved-filter-selection', function (sfs) {
            return _this.get('user.id') == sfs.get('user.id') && _this.get('profile.id') == sfs.get('id');
          }).then(function (sfss) {
            var sfs;
            if (sfss.get('length')) {
              sfs = sfss.objectAt(0);
              sfs.set('filters', _this.get("serializedFilters"));
            } else {
              sfs = _this.store.createRecord('saved-filter-selection', {
                id: _this.get('profile.id'),
                user: _this.get('user'),
                filters: _this.get("serializedFilters")
              });
            }
            sfs.save();
            _this.set('_savedFilterSelection', sfs);
            _this.get('notifyService').info('Your personal filter preferences have been saved for this profile.');
          });
        }
      },
      relevanceDidChange: function relevanceDidChange(relevanceValue) {
        this.set('relevance.value', relevanceValue);
      },
      resetSavedFilterSelection: function resetSavedFilterSelection() {
        var _this2 = this;

        this.store.filter('saved-filter-selection', function (sfs) {
          return +_this2.get('user.id') === +sfs.get('user.id') && +_this2.get('profile.id') === +sfs.get('id');
        }).then(function (sfss) {
          if (sfss.get('length')) {
            var sfs = sfss.objectAt(0);
            sfs.destroyRecord();
            _this2.set('_savedFilterSelection', null);
            _this2.get('notifyService').info('Your personal filter preferences have been reset for this profile.');
            _this2.resetFilters();
          }
        });
      }
    }

  });

  exports.defaultTagFilters = defaultTagFilters;
  exports.defaultAmplificationFilters = defaultAmplificationFilters;
  exports.defaultToneFilters = defaultToneFilters;
  exports.defaultChannelFilters = defaultChannelFilters;
  exports.defaultContentFilters = defaultContentFilters;
  exports.default = FilterControllerMixin;
});
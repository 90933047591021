define('client/utils/campaign-helper', ['exports', 'moment'], function (exports, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.getPublishers = exports.getPublisherNames = exports.getChannelNames = exports.getTotalChannels = exports.getChannels = exports.getVolumeOption = exports.VOLUME_OPTIONS = undefined;


  // Volume Options
  var INFLUENTIAL_AND_TARGET_MEDIA = 'Influential & Target Media Lists';
  var TARGET_MEDIA = 'Target Media Lists Only';
  var COMPREHENSIVE = 'Comprehensive';

  var VOLUME_OPTIONS = Ember.A([{ name: INFLUENTIAL_AND_TARGET_MEDIA,
    influential: true,
    uninfluential: false
  }, { name: TARGET_MEDIA,
    influential: false,
    uninfluential: false,
    showFilters: true
  }, { name: COMPREHENSIVE,
    influential: true,
    uninfluential: true
  }]);

  function getVolumeOption(influential, uninfluential) {
    if (influential && !uninfluential) {
      return VOLUME_OPTIONS[0];
    } else if (!influential && !uninfluential) {
      return VOLUME_OPTIONS[1];
    } else if (influential && uninfluential) {
      return VOLUME_OPTIONS[2];
    }
  }

  // Channels
  var _CAMPAIGN_CHANNELS = {
    earned: 'Earned',
    owned: 'Owned',
    pressRelease: 'Newswire'
  };

  function getTotalChannels() {
    return Object.keys(_CAMPAIGN_CHANNELS).length;
  }

  function getChannelNames() {
    var channelNames = [];
    var keys = Object.keys(_CAMPAIGN_CHANNELS);
    keys.forEach(function (key) {
      channelNames.pushObject(_CAMPAIGN_CHANNELS[key]);
    });
    return channelNames;
  }

  function getChannels() {
    return _CAMPAIGN_CHANNELS;
  }

  // Publishers
  var _CAMPAIGN_PUBLISHERS = {
    article: 'Digital',
    linkedinPulse: 'LinkedIn Pulse',
    twitter: 'Twitter',
    googlePlus: 'Google+',
    television: 'Television',
    radio: 'Radio',
    podcast: 'Podcast',
    youtube_bc: 'Youtube',
    printPublication: 'Print'
  };

  function getPublisherNames(profile) {
    var publishers = getPublishers(profile),
        keys = Object.keys(publishers);

    return keys.map(function (key) {
      return publishers[key];
    });
  }

  function getPublishers(profile) {
    var publishers = Ember.merge({}, _CAMPAIGN_PUBLISHERS);
    if (profile) {
      if (!profile.get("hasBroadcast")) {
        delete publishers.television;
        delete publishers.radio;
      }
      if (!profile.get("hasPrint")) {
        delete publishers.printPublication;
      }
      if (!profile.get("hasBroadcastPodcast")) {
        delete publishers.podcast;
      }
      if (!profile.get("hasBroadcastYoutube")) {
        delete publishers.youtube_bc;
      }
    }

    return publishers;
  }

  exports.VOLUME_OPTIONS = VOLUME_OPTIONS;
  exports.getVolumeOption = getVolumeOption;
  exports.getChannels = getChannels;
  exports.getTotalChannels = getTotalChannels;
  exports.getChannelNames = getChannelNames;
  exports.getPublisherNames = getPublisherNames;
  exports.getPublishers = getPublishers;
});
define('client/computed/alias-with-default', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = aliasWithDefault;
  function aliasWithDefault(propertyKey, def) {
    return Ember.computed('' + propertyKey, function () {
      return this.get(propertyKey) || def;
    });
  }
});
define('client/components/analyze/ui-full-article', ['exports', 'ember-modal-service/components/modal', 'client/computed'], function (exports, _modal, _computed) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _modal.default.extend({
    classNames: ["ui-full-article"],
    size: (0, _computed.aliasWithDefault)("model.options.size", "sm"),
    title: Ember.computed.alias("model.options.title"),
    content: Ember.computed.alias("model.options.content"),
    contentMarkup: Ember.computed('content', function () {
      return Ember.String.htmlSafe(this.get('content').replace(/\n/g, '<br>'));
    })
  });
});
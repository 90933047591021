define('client/components/reports/chart-pie', ['exports', 'client/components/reports/chart-base-widget'], function (exports, _chartBaseWidget) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _chartBaseWidget.default.extend({
    classNameBindings: [":report-chart-item"],

    hasHorizontalLabel: false,
    hasVerticalLabel: false,

    groupOptionsMenuName: Ember.computed.alias("item.groupOptionsMenuName"),
    groupDimension: Ember.computed.alias('item.groupDimension'),
    drilldownDimension: Ember.computed.readOnly('groupDimension.firstObject'),
    valueOptionsMenuName: Ember.computed.alias("item.valueOptionsMenuName"),

    // Pie doesn't have label dimension, just group
    labelDimension: Ember.computed.alias("item.labelDimension"),
    labelOptionsMenuName: Ember.computed.alias("item.labelOptionsMenuName"),
    canAddOtherProfiles: true,
    hideValueOptions: null,

    showDataValuesAs: Ember.computed.alias('item.showDataValuesAs'),
    showDataValuesAsOptions: Ember.computed.alias("item.showDataValuesAsOptions"),
    showDataValuesAsSelect: Ember.computed.notEmpty('item.showDataValuesAsOptions'),

    init: function init() {
      this._super.apply(this, arguments);
      this.set('hideValueOptions', this.get('hideValueOptions') || { 'interaction': true });
    }
  });
});
define('client/mixins/airpr', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var AirprMixin = Ember.Mixin.create({
    user: Ember.computed.alias('currentUser.content'),

    sortDate: "",
    sortName: "",
    sortUser: "",
    sortFrequency: "",
    sortSelection: "",
    sortLevel: "",
    sortEmail: "",
    sortProfile: "",

    profile: Ember.computed.alias('profileService.profile'),
    _sorts: ["Date", "Name", "User", "Frequency", "Selection", "Profile", "Email", "Level"],

    _paramsDidChange: function _paramsDidChange() {
      var profile = this.get("profile.id"),
          params = {
        sorts: {}
      };

      if (profile) {
        params.profile_id = profile;
      }

      this.get("_sorts").forEach(function (sort) {
        var sortVal = this.get("sort" + sort);
        if (sortVal) {
          params.sorts[sort.toLowerCase()] = sortVal;
        }
      }, this);

      if (this.get('resources')) {
        this.set("resources.search", params);
      }
    },

    paramsDidChange: Ember.observer("sortDate", "sortName", "sortUser", "sortVisibility", "sortSelection", "sortFrequency", "sortLevel", "sortEmail", "sortProfile", "profile", function () {
      Ember.run.scheduleOnce("afterRender", this, "_paramsDidChange");
    }),

    actions: {
      removeSorts: function removeSorts() {
        this.beginPropertyChanges();
        this.get("_sorts").forEach(function (sort) {
          this.set("sort" + sort, null);
        }, this);
        this.endPropertyChanges();
      }
    }

  });

  exports.default = AirprMixin;
});
define('client/components/plan/campaigns/campaign-name', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    store: Ember.inject.service(),

    classNames: ['campaign-name', 'campaign-creation-navigation'],

    prompt: 'What do you want to name this campaign?',

    selectedCampaignName: null,

    error: Ember.computed('selectedCampaignName', function () {
      var isDuplicate = this.get('store').peekAll('campaign').isAny('name', this.get('selectedCampaignName'));
      return isDuplicate ? 'Duplicate Name' : null;
    }),

    isDisabled: Ember.computed('selectedCampaignName', 'error', function () {
      return Ember.isEmpty(this.get('selectedCampaignName')) || Ember.isPresent(this.get('error'));
    }),

    onNext: function onNext() {},
    onPrevious: function onPrevious() {},


    actions: {
      onContinue: function onContinue() {
        if (!this.get('error')) {
          this.onNext();
        }
        //this.onSelectedVolumeCoverage(this.get('selectedVolumeCoverage'), this.get('whitelistTags'));
      },
      onBack: function onBack() {
        this.onPrevious();
      }
    }
  });
});
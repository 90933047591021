define('client/components/visitor-widget', ['exports', 'client/components/simple-widget'], function (exports, _simpleWidget) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var alias = Ember.computed.alias;


  var VISITOR_TEXT = '<p>The total number of people who came to your\n  website as result of PR coverage.</p> <p> Analyst tracks direct and\n  indirect visitors to your site, both of which are included in this figure.\n  </p>';

  var VisitorWidgetComponent = _simpleWidget.default.extend({

    classNames: ['visitor-widget'],

    iconClass: "db-widget-icon-visitors",

    action: "toContextVisitors",

    title: alias("profile.nameCustomer"),

    value: alias("content.visitors"),

    text: Ember.computed(function () {
      return Ember.String.htmlSafe(VISITOR_TEXT);
    })
  });

  exports.default = VisitorWidgetComponent;
});
define('client/mixins/date-range-controller', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var DateRangeControllerMixin = Ember.Mixin.create({
    from: Ember.computed.alias("controllers.profile.from"),
    fromDate: Ember.computed.alias("controllers.profile.fromDate"),
    to: Ember.computed.alias("controllers.profile.to"),
    toDate: Ember.computed.alias("controllers.profile.toDate")
  });

  exports.default = DateRangeControllerMixin;
});
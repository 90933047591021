define('client/components/reports/chart-bar', ['exports', 'client/components/reports/chart-base-widget'], function (exports, _chartBaseWidget) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _chartBaseWidget.default.extend({
    classNameBindings: [":report-chart-item"],

    labelOptionsMenuName: 'Vertical Axis',
    valueOptionsMenuName: 'Horizontal Axis',

    canAddOtherProfiles: true,

    horizontalLabel: Ember.computed('customHorizontalLabel', 'item.info.apiValues.[]', function () {
      return this.get('calculateLabelName')('customHorizontalLabel', 'item.info.apiValues');
    }),

    verticalLabel: Ember.computed('customVerticalLabel', 'labelDimension.[]', function () {
      return this.get('calculateLabelName')('customVerticalLabel', 'labelDimension');
    })

  });
});
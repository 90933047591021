define('client/controllers/settings/excluded-campaign-articles/index', ['exports', 'client/mixins/settings-campaign'], function (exports, _settingsCampaign) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend(_settingsCampaign.default, Ember.Evented, {
    modal: Ember.inject.service(),
    hiddenPers: Ember.computed('profile', function () {
      var owner = Ember.getOwner(this);
      var findPagingController = owner.lookup('controller:find-paging');

      findPagingController.setProperties({
        model: [],
        type: "hidden_campaign_per",
        store: this.store
      });
      Ember.run.later(this, "paramsDidChange");
      return findPagingController;
    }),

    resources: Ember.computed.alias("hiddenPers"),

    actions: {
      selectCampaign: function selectCampaign(campaign) {
        this.set('campaign', campaign);
      },
      unhideEntity: function unhideEntity(entity) {
        var _this = this;

        this.get('modal').open('common/ui-confirm', {
          prompt: 'Are you sure?',
          ok: 'OK',
          okType: "danger"
        }).then(function () {
          // TODO: Confirm works
          entity.get("content").unhideEntity(_this.get('campaign.id'));
          _this.get("hiddenPers.model").removeObject(entity);
        });
      }
    }
  });
});
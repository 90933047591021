define('client/models/email-newsletter-cart', ['exports', 'ember-data', 'ember-api-actions'], function (exports, _emberData, _emberApiActions) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.SORT_OPTIONS = undefined;


  var SORT_OPTIONS = [{ id: 0, label: 'Date' }, { id: 1, label: 'Publication Location' }, { id: 2, label: 'Publication Name' }, { id: 3, label: 'Power of Voice (individual articles only)' }, { id: 4, label: 'Impact Score (individual articles only)' }];

  exports.SORT_OPTIONS = SORT_OPTIONS;
  exports.default = _emberData.default.Model.extend({
    // TODO (later: Ankur): when removing articles, remove custom serializer as well and test
    articles: _emberData.default.hasMany('article-summary', { inverse: null }),
    newsletterSections: _emberData.default.hasMany('newsletter-section', { inverse: 'emailNewsletter' }),
    article_sort_cd: _emberData.default.attr('number', { defaultValue: 0 }),
    recipients: _emberData.default.attr({
      defaultValue: function defaultValue() {
        return [];
      }
    }),
    subject: _emberData.default.attr(),
    bodyText: _emberData.default.attr(),
    sendEmail: _emberData.default.attr('boolean'),
    user: _emberData.default.belongsTo('user'),
    showLogo: _emberData.default.attr('boolean'),

    addDefaultSection: (0, _emberApiActions.collectionAction)({ path: 'add_default_section', type: 'POST' })
  });
});
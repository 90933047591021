define('client/components/graph-row', ['exports', 'client/mixins/graph-legend', 'client/mixins/graph-viewport', 'client/mixins/graph-tooltip', 'client/mixins/graph-title'], function (exports, _graphLegend, _graphViewport, _graphTooltip, _graphTitle) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var MAX_BAND_PERCENT = 0.20;

  var ReportGroupRowGraphComponent = Ember.Component.extend(_graphLegend.default, _graphViewport.default, _graphTooltip.default, _graphTitle.default, {
    classNames: ['graph-row'],

    // ----------------------------------------------------------------------------
    // Data Processing
    // ----------------------------------------------------------------------------

    // Take data API and format for this specific chart
    formattedData: Ember.computed('data', function () {
      var data = this.get('data');
      if (Ember.isEmpty(data)) {
        return [];
      }
      return data.map(function (d) {
        return {
          label: d.labels[0],
          color: d.color,
          group: d.labels[1],
          value: d.values[0]
        };
      });
    }),

    // TODO(tony) - sorting and maybe grouping should probably be moved out to
    // base class
    sortKey: null, //'value',
    sortAscending: true,
    graphicPadBottom: 15,
    sortedData: Ember.computed('formattedData', 'sortKey', 'sortAscending', function () {
      var data = this.get('formattedData');
      var key = this.get('sortKey');
      var sortAscending = this.get('sortAscending');

      if (Ember.isEmpty(data)) {
        return [];
      }

      if (key !== null) {
        if (sortAscending) {
          return data.sortBy(key);
        } else {
          return data.sortBy(key).reverse();
        }
      } else {
        return data;
      }
    }),

    // Aggregates objects provided in `data` in a dictionary, keyed by group names
    groupedData: Ember.computed('sortedData', 'ungroupedSeriesName', function () {
      var data = this.get('sortedData');
      if (Ember.isEmpty(data)) {
        return [];
      }
      var ungroupedName = this.get('ungroupedSeriesName');
      return d3.nest().key(function (d) {
        return d.group ? d.group : ungroupedName;
      }).map(data);
    }),

    groupNames: Ember.computed('groupedData', function () {
      return Object.keys(this.get('groupedData'));
    }),

    // We know the data is grouped because it has more than one label. If there
    // are no labels on the data then every data object will have
    // 'ungroupedSeriesName' as its group name and the number of group
    // labels will be 1. If we are passed ungrouped data we will display
    // each data object in its own group.
    isGrouped: Ember.computed.gt('groupNames.length', 1),

    rowsData: Ember.computed('groupedData', 'isGrouped', 'groupNames', function () {
      var groupedData = this.get('groupedData');
      if (Ember.isEmpty(this.get('groupedData'))) {
        return [];
      }
      // TODO(tony) - do something for multiple groups
      var groupName = this.get('groupNames.firstObject');
      // Ignore grouped data because we only have one group
      return groupedData[groupName];
    }),

    finishedData: Ember.computed('rowsData', 'hasTooManyDataPoints', 'hasTooManyLabels', function () {
      if (this.get('hasTooManyDataPoints') || this.get('hasTooManyLabels')) {
        return [];
      }
      return this.get('rowsData').map(function (d) {
        return {
          group: d.label,
          values: [d]
        };
      });
    }),

    // ----------------------------------------------------------------------------
    // Horizontal Bar Chart Options
    // ----------------------------------------------------------------------------

    // Minimum height of the whole chart, including padding
    defaultOuterHeight: 500,

    // Override maximum width of labels to be a percentage of the total width
    labelWidth: Ember.computed(function () {
      return 0.1 * this.get('svgWidth');
    }).volatile(),

    // Space between label and zeroline (overrides ChartView)
    // Also used to pad labels against the edges of the viewport
    labelPadding: 20,

    // If we have to show data values, then give more space on the right, otherwise
    // just use a standard smallish value
    graphicPadRight: Ember.computed('showDataValues', 'labelWidth', function () {
      if (this.get('showDataValues')) {
        return this.get('labelWidth');
      } else {
        return 10;
      }
    }),

    graphicPadTop: 10,

    yAxisWidth: Ember.computed(function () {
      return this.get('labelWidth') + this.get('labelPadding');
    }).volatile(),

    xAxisHeight: 20,

    // Space between adjacent bars, as fraction of padded bar size
    barPadding: 0.2,

    // Constraints on size of each bar
    maxBarThickness: 80,
    minBarThickness: 12,

    showDataValues: true,

    // ----------------------------------------------------------------------------
    // Ticks and Scales
    // ----------------------------------------------------------------------------
    numBars: Ember.computed.alias('rowsData.length'),

    // Range of values used to size the graph, within which bars will be drawn
    xDomain: Ember.computed('rowsData', function () {
      var finishedData = this.get('rowsData');
      if (Ember.isEmpty(finishedData)) {
        return [0, 1];
      }

      var values = finishedData.map(function (d) {
        return d.value;
      });
      var minValue = d3.min(values);
      var maxValue = d3.max(values);
      if (minValue < 0) {
        // Balance negative and positive axes if we have negative values
        var absMax = Math.max(-minValue, maxValue);
        return [-absMax, absMax];
      } else {
        return [0, maxValue];
      }
    }),

    // Scale to map value to horizontal length of bar
    xScale: Ember.computed(function () {
      var valueFormatType = this.get('valueFormatType');
      var domain = valueFormatType === 'score' ? [0, 100] : this.get('xDomain');
      return d3.scale.linear().domain(domain).range([0, this.get('graphicWidth')]).nice();
    }).volatile(),

    // Scale to map bar index to its horizontal position
    yScale: Ember.computed(function () {
      var graphicHeight = this.get('graphicHeight'),
          numBars = this.get('numBars');
      // Evenly split up height for bars with space between bars
      var yScale = d3.scale.ordinal().domain(d3.range(numBars)).rangeRoundBands([0, graphicHeight], this.get('barPadding'));

      var bandWidth = yScale.rangeBand();

      if (graphicHeight * MAX_BAND_PERCENT < bandWidth) {
        var barWidth = graphicHeight * MAX_BAND_PERCENT,
            newPadding = 1 - barWidth / (graphicHeight / (numBars + 1));
        yScale = d3.scale.ordinal().domain(d3.range(numBars)).rangeRoundBands([0, graphicHeight], newPadding);
      }
      return yScale;
    }).volatile(),

    // Space in pixels allocated to each bar + padding
    barThickness: Ember.computed(function () {
      return this.get('yScale').rangeBand();
    }).volatile(),

    hasTooManyDataPoints: Ember.computed(function () {
      return this.get('barThickness') < this.get('minBarThickness');
    }).volatile(),

    // ----------------------------------------------------------------------------
    // Styles
    // ----------------------------------------------------------------------------

    groupAttrs: Ember.computed(function () {
      var xScale = this.get('xScale');
      var yScale = this.get('yScale');
      return {
        transform: function transform(d, i) {
          return 'translate(' + xScale(Math.min(0, d.value)) + ', ' + yScale(i) + ')';
        }
      };
    }).volatile(),

    barAttrs: Ember.computed(function () {
      var xScale = this.get('xScale');
      return {
        width: function width(d) {
          return Math.abs(xScale(d.value) - xScale(0));
        },
        height: this.get('barThickness'),
        'stroke-width': 0,
        // TODO(tony) - support grouping
        fill: function fill(d) {
          return d.color;
        }
      };
    }).volatile(),

    rightRoundedRect: function rightRoundedRect(x, y, width, height) {
      return 'M' + x + ',' + y + ('h' + width) + ('v' + height) + ('h' + -width) + 'z';
    },


    roundedBarAttrs: Ember.computed(function () {
      var _this = this;

      var xScale = this.get('xScale');
      return {
        d: function d(_d) {
          var width = Math.abs(xScale(_d.value) - xScale(0));
          var height = _this.get('barThickness');
          return _this.rightRoundedRect(0, 0, width, height);
        },
        'stroke-width': 0,
        // TODO(tony) - support grouping
        fill: function fill(d) {
          return d.color;
        }
      };
    }).volatile(),

    backgroundBarAttrs: Ember.computed(function () {
      var yScale = this.get('yScale');
      return {
        width: this.get('graphicWidth'),
        height: this.get('barThickness'),
        'stroke-width': 0,
        fill: function fill(d) {
          return d.color;
        },
        opacity: 0.1,
        transform: function transform(d, i) {
          return 'translate(0, ' + yScale(i) + ')';
        }
      };
    }).volatile(),

    valueLabelAttrs: Ember.computed(function () {
      var _this2 = this;

      var xScale = this.get('xScale');
      // Anchor the label 'labelPadding' away from the zero line
      return {
        x: function x(d) {
          var pad = _this2.get('labelPadding');
          return d.value < 0 ? -pad : xScale(d.value) - xScale(0) + pad;
        },
        y: this.get('barThickness') / 2,
        dy: '.35em',
        opacity: function opacity() {
          return _this2.get('showDataValues') ? 1 : 0;
        },
        // How to anchor the text depends on the direction of the bar
        'text-anchor': function textAnchor(d) {
          return d.value < 0 ? 'end' : 'start';
        },
        'stroke-width': 0
      };
    }).volatile(),

    groupLabelAttrs: Ember.computed(function () {
      // Anchor the label 'labelPadding' away from the zero line
      return {
        x: 0,
        y: 0,
        dy: '1em',
        'text-anchor': 'middle',
        // How to anchor the text depends on the direction of the bar
        //'text-anchor': d => d.value < 0 ? 'start' : 'end',
        'stroke-width': 0
      };
    }).volatile(),

    // ----------------------------------------------------------------------------
    // Drawing Functions
    // ----------------------------------------------------------------------------

    updateGraph: function updateGraph() {
      this.updateBackground();
      this.updateAxes();
      this.updateGraphic();
    },

    showHoverLine: function showHoverLine(x, y) {
      var data = this.getDataAtMouse(x, y).values[0];
      var bars = this.get('viewport').selectAll('.bar');
      bars.filter(function (d, z, i) {
        d3.select(this).attr('opacity', d.label !== data.label ? 0.5 : null);
      });
    },
    hideHoverLine: function hideHoverLine() {
      this.get('viewport').selectAll('.bar').attr('opacity', null);
    },


    updateYAxis: function updateYAxis() {
      // Just want two lines to mark the vertical borders of the chart
      var viewport = this.get('viewport');
      var yAxisTop = viewport.select('.y.axis.top');
      if (yAxisTop.empty()) {
        yAxisTop = viewport.append('line').attr('class', 'y axis top');
      }
      yAxisTop.attr({
        x1: 0,
        x2: this.get('graphicWidth'),
        y1: 0,
        y2: 0
      });

      var yAxisBottom = viewport.select('.y.axis.bottom');
      if (yAxisBottom.empty()) {
        yAxisBottom = viewport.append('line').attr('class', 'y axis bottom');
      }
      yAxisBottom.attr({
        x1: 0,
        x2: this.get('graphicWidth'),
        y1: this.get('graphicHeight'),
        y2: this.get('graphicHeight')
      });
    },

    numXTicks: 6,

    xAxis: Ember.computed(function () {
      var xAxis = this.get('viewport').select('.x.axis');
      if (xAxis.empty()) {
        return this.get('viewport').insert('g', ':first-child').attr('class', 'x axis');
      }
      return xAxis;
    }).volatile(),

    updateXAxis: function updateXAxis() {
      var xScale = this.get('xScale');
      var xAxisFormatter = this.getValueAxisFormatter(xScale);
      var xAxis = d3.svg.axis().scale(this.get('xScale')).orient('bottom').ticks(this.get('numXTicks')).tickSize(-this.get('graphicHeight')).tickFormat(xAxisFormatter);

      var gXAxis = this.get('xAxis');
      gXAxis.attr('transform', 'translate(0,' + this.get('graphicHeight') + ')').call(xAxis);

      gXAxis.selectAll('text').attr('y', this.get('labelPadding'));
    },

    updateAxes: function updateAxes() {
      this.updateXAxis();
      this.updateYAxis();
      this.updateAxisLabels();
    },

    backgroundLayer: Ember.computed(function () {
      var viewport = this.get('viewport');
      var backgroundLayer = viewport.select('g.background-layer');
      if (backgroundLayer.empty()) {
        // Hopefully you've added this when it needs to be
        backgroundLayer = viewport.append('g').attr('class', 'background-layer');
      }
      return backgroundLayer;
    }).volatile(),

    updateBackground: function updateBackground() {
      var bars = this.get('backgroundLayer').selectAll('.bar-background').data(this.get('rowsData'));

      bars.enter().append('rect').attr('class', 'bar-background');
      bars.exit().remove();
      bars.attr(this.get('backgroundBarAttrs'));
    },

    updateGraphic: function updateGraphic() {
      var _this3 = this;

      var viewport = this.get('viewport');
      var graphicLayer = viewport.select('g.graphic-layer');
      if (graphicLayer.empty()) {
        graphicLayer = viewport.append('g').attr('class', 'graphic-layer');
      }

      var groups = graphicLayer.selectAll('.bar').data(this.get('rowsData'));

      var entering = groups.enter().append('g').attr('class', 'bar');
      entering.append('path');
      entering.append('text').attr('class', 'value');
      entering.append('g').attr('class', 'group-text').append('text').attr('class', 'group').on('mouseenter', function (d) {
        _this3.showTooltipIfTruncatedText(d.label);
      }).on('mouseleave', function () {
        _this3.hideTooltip('truncated-text');
      });

      groups.exit().remove();

      groups.attr(this.get('groupAttrs'));
      groups.select('path').attr(this.get('roundedBarAttrs'));

      var valueLabelFormatter = this.get('valueLabelFormatter');
      groups.select('text.value').text(function (d) {
        return valueLabelFormatter(d.value);
      }).attr(this.get('valueLabelAttrs'));

      var barThickness = this.get('barThickness');
      var labelWidth = this.get('labelWidth');
      var labelPadding = this.get('labelPadding');
      groups.select('text.group').text(function (d) {
        return d.label;
      }).attr(this.get('groupLabelAttrs')).call(this.wordWrapper, labelWidth, barThickness);

      // Need to move the new text into position
      groups.selectAll('g.group-text').each(function () {
        var g = d3.select(this);

        var _g$node$getBoundingCl = g.node().getBoundingClientRect(),
            width = _g$node$getBoundingCl.width,
            height = _g$node$getBoundingCl.height;

        var yOffset = (barThickness - height) / 2;
        var xOffset = -(width / 2 + labelPadding);
        g.attr('transform', 'translate(' + xOffset + ', ' + yOffset + ')');
      });
    },

    getDataAtMouse: function getDataAtMouse(_, y) {
      var data = this.get('finishedData');
      if (Ember.isEmpty(data)) {
        return null;
      }
      var yPoints = this.get('yScale').range();
      var index = d3.bisectLeft(yPoints, y) - 1;
      if (index < 0 || index >= data.length) {
        return null;
      }
      return data[index];
    },
    getTooltipInfo: function getTooltipInfo(x, y) {
      var d = this.getDataAtMouse(x, y);
      if (d === null) {
        return d;
      }
      var format = this.get('valueLabelFormatter');
      var title = this.get('yAxisLabel');
      return {
        title: title,
        rows: [{
          label: d.values[0].label,
          value: format(d.values[0].value)
        }]
      };
    },
    getDrilldownInfo: function getDrilldownInfo(x, y) {
      var d = this.getDataAtMouse(x, y);
      if (d === null) {
        return d;
      }
      return d.values[0].label;
    },


    // ----------------------------------------------------------------------------
    // Legend Configuration
    // ----------------------------------------------------------------------------
    hasLegend: false,
    legendItemNames: Ember.computed.reads('groupNames')

  });

  exports.default = ReportGroupRowGraphComponent;
});
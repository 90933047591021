define('client/components/email-digest-view', ['exports', 'client/utils/search/config', 'client/mixins/new-filters-mixin'], function (exports, _config, _newFiltersMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_newFiltersMixin.default, {
    classNames: ["email-digest-section-body"],

    activeProfile: Ember.computed.alias("workspaceView.activeProfile"),
    profile: Ember.computed.alias("activeProfile"),
    workspaceView: null,
    currentlySelectedView: null,
    parentOverflowClass: null,
    user: null,

    defaultLocationFilters: Ember.computed.alias("activeProfile.locations"),
    showSavedSearch: false,
    searchTerm: Ember.computed.alias("workspaceView.search_term"),
    savedFilters: Ember.computed.alias('workspaceView.filters'),

    searchConfig: Ember.computed("workspaceView.profile", function () {
      return _config.default.create({
        profile: this.get("workspaceView.profile"),
        user: this.get("user")
      });
    }),

    store: Ember.inject.service(),

    savedSearches: Ember.computed("activeProfile.id", function () {
      return this.get("store").query("saved-search", {
        profile_id: this.get("activeProfile.id")
      });
    }),

    selectedLocationFilters: Ember.computed("profile", "savedFilters.filters", function () {
      var filters = this.get("savedFilters.filters");
      if (!filters) {
        return filters;
      }
      var groupedFilters = this.groupFilters(filters);
      return Object.keys(groupedFilters.location.whitelist);
    }),

    actions: {
      toggleSavedSearches: function toggleSavedSearches() {
        this.toggleProperty('showSavedSearch');
      },
      saveSearch: function saveSearch(savedSearch, isPrivate) {
        var _this = this;

        savedSearch.set("profile_id", +this.get("activeProfile.id"));
        savedSearch.set("user_id", isPrivate ? this.get("user.id") : null);
        var success = function success() {
          _this.notifyPropertyChange('savedSearches');
        };
        savedSearch.save().then(success, function () {});
      },
      removeSearch: function removeSearch(savedSearch) {
        savedSearch.destroyRecord();
      },
      locationFilterChanged: function locationFilterChanged(serializedLocationFilters, regionObjects) {
        var workspaceView = this.get("workspaceView"),
            workspaceViewFilters = workspaceView.get("filters.filters");

        var currentGroupedFilters = this.groupFilters(workspaceViewFilters);
        var newFilters = Ember.merge(currentGroupedFilters.nonLocation, serializedLocationFilters);
        workspaceView.set("filters.filters", newFilters);
      }
    }
  });
});
define('client/routes/plan/campaigns', ['exports', 'ember-cli-pagination/remote/route-mixin'], function (exports, _routeMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_routeMixin.default, {
    queryParams: {
      search: {
        refreshModel: true
      },
      sort: {
        refreshModel: true
      }
    },

    ajax: Ember.inject.service(),

    perPage: 12,

    titleToken: function titleToken() {
      var controller = this.controllerFor(this.fullRouteName);
      var titleText = 'Campaigns';
      if (controller.searchText) {
        titleText += ' - "' + controller.searchText + '"';
      }
      return titleText;
    },
    beforeModel: function beforeModel() {
      return this.get("profileService.profile");
    },
    model: function model(params, transition) {
      var profile_id = this.get('profileService.profile.id');
      if (transition.queryParams.profile) {
        profile_id = transition.queryParams.profile;
      }

      var sortObj = {};
      // params.sort should be something like "name:desc"
      if (params.sort) {
        var sortArray = params.sort.split(':');
        if (sortArray.length == 2) {
          sortObj[sortArray[0]] = sortArray[1];
        }
      }

      return this.findPaged('campaign', {
        page: params.page,
        profile_id: profile_id,
        sorts: sortObj,
        search: (params.search || "").trim()
      });
    },
    setupController: function setupController(controller, model) {
      controller.set('model', model);
      controller.loadPlanningData();
    },


    actions: {
      /**
       * Action bubbled up from sub-route (new) to notify parent to update view.
       * @param {*} campaign
       */
      updateCampaignsForProfile: function updateCampaignsForProfile() {
        var _this = this;

        this.controller.model.setOtherParam('profile_id', this.get('profileService.profile.id'));
        this.controller.model.reload().then(function () {
          _this.controller.loadPlanningData();
        });
      }
    }
  });
});
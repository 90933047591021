define("client/utils/social-settings", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    PINTEREST_START: new Date(2018, 0, 1),
    LINKEDIN_END: new Date(2018, 1, 15),
    GOOGLEPLUS_END: new Date(2017, 8, 1)
  };
});
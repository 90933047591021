define("client/utils/search/parser", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  exports.default = function () {

    "use strict";

    /*
     * Generated by PEG.js 0.9.0.
     *
     * http://pegjs.org/
     */

    function peg$subclass(child, parent) {
      function ctor() {
        this.constructor = child;
      }
      ctor.prototype = parent.prototype;
      child.prototype = new ctor();
    }

    function peg$SyntaxError(message, expected, found, location) {
      this.message = message;
      this.expected = expected;
      this.found = found;
      this.location = location;
      this.name = "SyntaxError";

      if (typeof Error.captureStackTrace === "function") {
        Error.captureStackTrace(this, peg$SyntaxError);
      }
    }

    peg$subclass(peg$SyntaxError, Error);

    function peg$parse(input) {
      var options = arguments.length > 1 ? arguments[1] : {},
          parser = this,
          peg$FAILED = {},
          peg$startRuleFunctions = { start: peg$parsestart },
          peg$startRuleFunction = peg$parsestart,
          peg$c0 = /^[ ]/,
          peg$c1 = { type: "class", value: "[ ]", description: "[ ]" },
          peg$c2 = ")",
          peg$c3 = { type: "literal", value: ")", description: "\")\"" },
          peg$c4 = "(",
          peg$c5 = { type: "literal", value: "(", description: "\"(\"" },
          peg$c6 = /^["]/,
          peg$c7 = { type: "class", value: "[\"]", description: "[\"]" },
          peg$c8 = /^[a-z,_]/,
          peg$c9 = { type: "class", value: "[a-z,_]", description: "[a-z,_]" },
          peg$c10 = ":",
          peg$c11 = { type: "literal", value: ":", description: "\":\"" },
          peg$c12 = function peg$c12(field) {
        return field.join("");
      },
          peg$c13 = /^[^"]/,
          peg$c14 = { type: "class", value: "[^\"]", description: "[^\"]" },
          peg$c15 = function peg$c15(value) {
        return value.join("");
      },
          peg$c16 = /^[^ ]/,
          peg$c17 = { type: "class", value: "[^ ]", description: "[^ ]" },
          peg$c18 = /^[0-9]/,
          peg$c19 = { type: "class", value: "[0-9]", description: "[0-9]" },
          peg$c20 = function peg$c20(digits) {
        return digits.join("");
      },
          peg$c21 = "AND",
          peg$c22 = { type: "literal", value: "AND", description: "\"AND\"" },
          peg$c23 = "OR",
          peg$c24 = { type: "literal", value: "OR", description: "\"OR\"" },
          peg$c25 = "NOT",
          peg$c26 = { type: "literal", value: "NOT", description: "\"NOT\"" },
          peg$c27 = function peg$c27(value, frequency) {
        return { modifier: "not", value: value, frequency: frequency };
      },
          peg$c28 = "CASE",
          peg$c29 = { type: "literal", value: "CASE", description: "\"CASE\"" },
          peg$c30 = function peg$c30(value, frequency) {
        return { modifier: "exact", value: value, frequency: frequency };
      },
          peg$c31 = "~4",
          peg$c32 = { type: "literal", value: "~4", description: "\"~4\"" },
          peg$c33 = function peg$c33(value, frequency) {
        return { modifier: "similar", value: value, frequency: frequency };
      },
          peg$c34 = "=",
          peg$c35 = { type: "literal", value: "=", description: "\"=\"" },
          peg$c36 = function peg$c36(value) {
        return { modifier: "equal", value: value };
      },
          peg$c37 = ">=",
          peg$c38 = { type: "literal", value: ">=", description: "\">=\"" },
          peg$c39 = function peg$c39(value) {
        return { modifier: "gt", value: value };
      },
          peg$c40 = "<=",
          peg$c41 = { type: "literal", value: "<=", description: "\"<=\"" },
          peg$c42 = function peg$c42(value) {
        return { modifier: "lt", value: value };
      },
          peg$c43 = function peg$c43(value, frequency) {
        return { modifier: "exact-select", value: value, frequency: frequency };
      },
          peg$c44 = "==",
          peg$c45 = { type: "literal", value: "==", description: "\"==\"" },
          peg$c46 = function peg$c46(operator, frequency) {
        return { operator: operator, occurrences: frequency };
      },
          peg$c47 = "<",
          peg$c48 = { type: "literal", value: "<", description: "\"<\"" },
          peg$c49 = ">",
          peg$c50 = { type: "literal", value: ">", description: "\">\"" },
          peg$c51 = function peg$c51(name, item) {
        item.option = name;
        return item;
      },
          peg$c52 = function peg$c52(or) {
        return or;
      },
          peg$c53 = function peg$c53(left, right) {
        return [left, right];
      },
          peg$c54 = function peg$c54(left, right) {
        // left and right must be of the same option
        var parts = [left, right].flatten(),
            option = parts[0].option;

        var _iteratorNormalCompletion = true;
        var _didIteratorError = false;
        var _iteratorError = undefined;

        try {
          for (var _iterator = parts[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
            var part = _step.value;

            part.or = true;
            if (part.option !== option) {
              expected("all options to be the same in a or");
              return;
            }
          }
        } catch (err) {
          _didIteratorError = true;
          _iteratorError = err;
        } finally {
          try {
            if (!_iteratorNormalCompletion && _iterator.return) {
              _iterator.return();
            }
          } finally {
            if (_didIteratorError) {
              throw _iteratorError;
            }
          }
        }

        return parts;
      },
          peg$currPos = 0,
          peg$savedPos = 0,
          peg$posDetailsCache = [{ line: 1, column: 1, seenCR: false }],
          peg$maxFailPos = 0,
          peg$maxFailExpected = [],
          peg$silentFails = 0,
          peg$result;

      if ("startRule" in options) {
        if (!(options.startRule in peg$startRuleFunctions)) {
          throw new Error("Can't start parsing from rule \"" + options.startRule + "\".");
        }

        peg$startRuleFunction = peg$startRuleFunctions[options.startRule];
      }

      function text() {
        return input.substring(peg$savedPos, peg$currPos);
      }

      function location() {
        return peg$computeLocation(peg$savedPos, peg$currPos);
      }

      function expected(description) {
        throw peg$buildException(null, [{ type: "other", description: description }], input.substring(peg$savedPos, peg$currPos), peg$computeLocation(peg$savedPos, peg$currPos));
      }

      function error(message) {
        throw peg$buildException(message, null, input.substring(peg$savedPos, peg$currPos), peg$computeLocation(peg$savedPos, peg$currPos));
      }

      function peg$computePosDetails(pos) {
        var details = peg$posDetailsCache[pos],
            p,
            ch;

        if (details) {
          return details;
        } else {
          p = pos - 1;
          while (!peg$posDetailsCache[p]) {
            p--;
          }

          details = peg$posDetailsCache[p];
          details = {
            line: details.line,
            column: details.column,
            seenCR: details.seenCR
          };

          while (p < pos) {
            ch = input.charAt(p);
            if (ch === "\n") {
              if (!details.seenCR) {
                details.line++;
              }
              details.column = 1;
              details.seenCR = false;
            } else if (ch === "\r" || ch === "\u2028" || ch === "\u2029") {
              details.line++;
              details.column = 1;
              details.seenCR = true;
            } else {
              details.column++;
              details.seenCR = false;
            }

            p++;
          }

          peg$posDetailsCache[pos] = details;
          return details;
        }
      }

      function peg$computeLocation(startPos, endPos) {
        var startPosDetails = peg$computePosDetails(startPos),
            endPosDetails = peg$computePosDetails(endPos);

        return {
          start: {
            offset: startPos,
            line: startPosDetails.line,
            column: startPosDetails.column
          },
          end: {
            offset: endPos,
            line: endPosDetails.line,
            column: endPosDetails.column
          }
        };
      }

      function peg$fail(expected) {
        if (peg$currPos < peg$maxFailPos) {
          return;
        }

        if (peg$currPos > peg$maxFailPos) {
          peg$maxFailPos = peg$currPos;
          peg$maxFailExpected = [];
        }

        peg$maxFailExpected.push(expected);
      }

      function peg$buildException(message, expected, found, location) {
        function cleanupExpected(expected) {
          var i = 1;

          expected.sort(function (a, b) {
            if (a.description < b.description) {
              return -1;
            } else if (a.description > b.description) {
              return 1;
            } else {
              return 0;
            }
          });

          while (i < expected.length) {
            if (expected[i - 1] === expected[i]) {
              expected.splice(i, 1);
            } else {
              i++;
            }
          }
        }

        function buildMessage(expected, found) {
          function stringEscape(s) {
            function hex(ch) {
              return ch.charCodeAt(0).toString(16).toUpperCase();
            }

            return s.replace(/\\/g, '\\\\').replace(/"/g, '\\"').replace(/\x08/g, '\\b').replace(/\t/g, '\\t').replace(/\n/g, '\\n').replace(/\f/g, '\\f').replace(/\r/g, '\\r').replace(/[\x00-\x07\x0B\x0E\x0F]/g, function (ch) {
              return '\\x0' + hex(ch);
            }).replace(/[\x10-\x1F\x80-\xFF]/g, function (ch) {
              return '\\x' + hex(ch);
            }).replace(/[\u0100-\u0FFF]/g, function (ch) {
              return "\\u0" + hex(ch);
            }).replace(/[\u1000-\uFFFF]/g, function (ch) {
              return "\\u" + hex(ch);
            });
          }

          var expectedDescs = new Array(expected.length),
              expectedDesc,
              foundDesc,
              i;

          for (i = 0; i < expected.length; i++) {
            expectedDescs[i] = expected[i].description;
          }

          expectedDesc = expected.length > 1 ? expectedDescs.slice(0, -1).join(", ") + " or " + expectedDescs[expected.length - 1] : expectedDescs[0];

          foundDesc = found ? "\"" + stringEscape(found) + "\"" : "end of input";

          return "Expected " + expectedDesc + " but " + foundDesc + " found.";
        }

        if (expected !== null) {
          cleanupExpected(expected);
        }

        return new peg$SyntaxError(message !== null ? message : buildMessage(expected, found), expected, found, location);
      }

      function peg$parsestart() {
        var s0;

        s0 = peg$parseor_operation();

        return s0;
      }

      function peg$parsespace() {
        var s0, s1;

        s0 = [];
        if (peg$c0.test(input.charAt(peg$currPos))) {
          s1 = input.charAt(peg$currPos);
          peg$currPos++;
        } else {
          s1 = peg$FAILED;
          if (peg$silentFails === 0) {
            peg$fail(peg$c1);
          }
        }
        while (s1 !== peg$FAILED) {
          s0.push(s1);
          if (peg$c0.test(input.charAt(peg$currPos))) {
            s1 = input.charAt(peg$currPos);
            peg$currPos++;
          } else {
            s1 = peg$FAILED;
            if (peg$silentFails === 0) {
              peg$fail(peg$c1);
            }
          }
        }

        return s0;
      }

      function peg$parserparen() {
        var s0, s1, s2, s3;

        s0 = peg$currPos;
        s1 = peg$parsespace();
        if (s1 !== peg$FAILED) {
          if (input.charCodeAt(peg$currPos) === 41) {
            s2 = peg$c2;
            peg$currPos++;
          } else {
            s2 = peg$FAILED;
            if (peg$silentFails === 0) {
              peg$fail(peg$c3);
            }
          }
          if (s2 !== peg$FAILED) {
            s3 = peg$parsespace();
            if (s3 !== peg$FAILED) {
              s1 = [s1, s2, s3];
              s0 = s1;
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }

        return s0;
      }

      function peg$parselparen() {
        var s0, s1, s2, s3;

        s0 = peg$currPos;
        s1 = peg$parsespace();
        if (s1 !== peg$FAILED) {
          if (input.charCodeAt(peg$currPos) === 40) {
            s2 = peg$c4;
            peg$currPos++;
          } else {
            s2 = peg$FAILED;
            if (peg$silentFails === 0) {
              peg$fail(peg$c5);
            }
          }
          if (s2 !== peg$FAILED) {
            s3 = peg$parsespace();
            if (s3 !== peg$FAILED) {
              s1 = [s1, s2, s3];
              s0 = s1;
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }

        return s0;
      }

      function peg$parsequote() {
        var s0;

        if (peg$c6.test(input.charAt(peg$currPos))) {
          s0 = input.charAt(peg$currPos);
          peg$currPos++;
        } else {
          s0 = peg$FAILED;
          if (peg$silentFails === 0) {
            peg$fail(peg$c7);
          }
        }

        return s0;
      }

      function peg$parsefield() {
        var s0, s1, s2;

        s0 = peg$currPos;
        s1 = [];
        if (peg$c8.test(input.charAt(peg$currPos))) {
          s2 = input.charAt(peg$currPos);
          peg$currPos++;
        } else {
          s2 = peg$FAILED;
          if (peg$silentFails === 0) {
            peg$fail(peg$c9);
          }
        }
        if (s2 !== peg$FAILED) {
          while (s2 !== peg$FAILED) {
            s1.push(s2);
            if (peg$c8.test(input.charAt(peg$currPos))) {
              s2 = input.charAt(peg$currPos);
              peg$currPos++;
            } else {
              s2 = peg$FAILED;
              if (peg$silentFails === 0) {
                peg$fail(peg$c9);
              }
            }
          }
        } else {
          s1 = peg$FAILED;
        }
        if (s1 !== peg$FAILED) {
          if (input.charCodeAt(peg$currPos) === 58) {
            s2 = peg$c10;
            peg$currPos++;
          } else {
            s2 = peg$FAILED;
            if (peg$silentFails === 0) {
              peg$fail(peg$c11);
            }
          }
          if (s2 !== peg$FAILED) {
            peg$savedPos = s0;
            s1 = peg$c12(s1);
            s0 = s1;
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }

        return s0;
      }

      function peg$parsevalue() {
        var s0, s1, s2, s3;

        s0 = peg$currPos;
        s1 = peg$parsequote();
        if (s1 !== peg$FAILED) {
          s2 = [];
          if (peg$c13.test(input.charAt(peg$currPos))) {
            s3 = input.charAt(peg$currPos);
            peg$currPos++;
          } else {
            s3 = peg$FAILED;
            if (peg$silentFails === 0) {
              peg$fail(peg$c14);
            }
          }
          while (s3 !== peg$FAILED) {
            s2.push(s3);
            if (peg$c13.test(input.charAt(peg$currPos))) {
              s3 = input.charAt(peg$currPos);
              peg$currPos++;
            } else {
              s3 = peg$FAILED;
              if (peg$silentFails === 0) {
                peg$fail(peg$c14);
              }
            }
          }
          if (s2 !== peg$FAILED) {
            s3 = peg$parsequote();
            if (s3 !== peg$FAILED) {
              peg$savedPos = s0;
              s1 = peg$c15(s2);
              s0 = s1;
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
        if (s0 === peg$FAILED) {
          s0 = peg$currPos;
          s1 = [];
          if (peg$c16.test(input.charAt(peg$currPos))) {
            s2 = input.charAt(peg$currPos);
            peg$currPos++;
          } else {
            s2 = peg$FAILED;
            if (peg$silentFails === 0) {
              peg$fail(peg$c17);
            }
          }
          if (s2 !== peg$FAILED) {
            while (s2 !== peg$FAILED) {
              s1.push(s2);
              if (peg$c16.test(input.charAt(peg$currPos))) {
                s2 = input.charAt(peg$currPos);
                peg$currPos++;
              } else {
                s2 = peg$FAILED;
                if (peg$silentFails === 0) {
                  peg$fail(peg$c17);
                }
              }
            }
          } else {
            s1 = peg$FAILED;
          }
          if (s1 !== peg$FAILED) {
            peg$savedPos = s0;
            s1 = peg$c15(s1);
          }
          s0 = s1;
        }

        return s0;
      }

      function peg$parseinteger() {
        var s0, s1, s2;

        s0 = peg$currPos;
        s1 = [];
        if (peg$c18.test(input.charAt(peg$currPos))) {
          s2 = input.charAt(peg$currPos);
          peg$currPos++;
        } else {
          s2 = peg$FAILED;
          if (peg$silentFails === 0) {
            peg$fail(peg$c19);
          }
        }
        if (s2 !== peg$FAILED) {
          while (s2 !== peg$FAILED) {
            s1.push(s2);
            if (peg$c18.test(input.charAt(peg$currPos))) {
              s2 = input.charAt(peg$currPos);
              peg$currPos++;
            } else {
              s2 = peg$FAILED;
              if (peg$silentFails === 0) {
                peg$fail(peg$c19);
              }
            }
          }
        } else {
          s1 = peg$FAILED;
        }
        if (s1 !== peg$FAILED) {
          peg$savedPos = s0;
          s1 = peg$c20(s1);
        }
        s0 = s1;

        return s0;
      }

      function peg$parseand() {
        var s0, s1, s2, s3;

        s0 = peg$currPos;
        s1 = peg$parsespace();
        if (s1 !== peg$FAILED) {
          if (input.substr(peg$currPos, 3) === peg$c21) {
            s2 = peg$c21;
            peg$currPos += 3;
          } else {
            s2 = peg$FAILED;
            if (peg$silentFails === 0) {
              peg$fail(peg$c22);
            }
          }
          if (s2 !== peg$FAILED) {
            s3 = peg$parsespace();
            if (s3 !== peg$FAILED) {
              s1 = [s1, s2, s3];
              s0 = s1;
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }

        return s0;
      }

      function peg$parseor() {
        var s0, s1, s2, s3;

        s0 = peg$currPos;
        s1 = peg$parsespace();
        if (s1 !== peg$FAILED) {
          if (input.substr(peg$currPos, 2) === peg$c23) {
            s2 = peg$c23;
            peg$currPos += 2;
          } else {
            s2 = peg$FAILED;
            if (peg$silentFails === 0) {
              peg$fail(peg$c24);
            }
          }
          if (s2 !== peg$FAILED) {
            s3 = peg$parsespace();
            if (s3 !== peg$FAILED) {
              s1 = [s1, s2, s3];
              s0 = s1;
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }

        return s0;
      }

      function peg$parsemodifier() {
        var s0, s1, s2, s3, s4, s5;

        s0 = peg$currPos;
        s1 = peg$parsespace();
        if (s1 !== peg$FAILED) {
          if (input.substr(peg$currPos, 3) === peg$c25) {
            s2 = peg$c25;
            peg$currPos += 3;
          } else {
            s2 = peg$FAILED;
            if (peg$silentFails === 0) {
              peg$fail(peg$c26);
            }
          }
          if (s2 !== peg$FAILED) {
            s3 = peg$parsespace();
            if (s3 !== peg$FAILED) {
              s4 = peg$parsevalue();
              if (s4 !== peg$FAILED) {
                s5 = peg$parsefrequency();
                if (s5 === peg$FAILED) {
                  s5 = null;
                }
                if (s5 !== peg$FAILED) {
                  peg$savedPos = s0;
                  s1 = peg$c27(s4, s5);
                  s0 = s1;
                } else {
                  peg$currPos = s0;
                  s0 = peg$FAILED;
                }
              } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
              }
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
        if (s0 === peg$FAILED) {
          s0 = peg$currPos;
          s1 = peg$parsespace();
          if (s1 !== peg$FAILED) {
            if (input.substr(peg$currPos, 4) === peg$c28) {
              s2 = peg$c28;
              peg$currPos += 4;
            } else {
              s2 = peg$FAILED;
              if (peg$silentFails === 0) {
                peg$fail(peg$c29);
              }
            }
            if (s2 !== peg$FAILED) {
              s3 = peg$parsespace();
              if (s3 !== peg$FAILED) {
                s4 = peg$parsevalue();
                if (s4 !== peg$FAILED) {
                  s5 = peg$parsefrequency();
                  if (s5 === peg$FAILED) {
                    s5 = null;
                  }
                  if (s5 !== peg$FAILED) {
                    peg$savedPos = s0;
                    s1 = peg$c30(s4, s5);
                    s0 = s1;
                  } else {
                    peg$currPos = s0;
                    s0 = peg$FAILED;
                  }
                } else {
                  peg$currPos = s0;
                  s0 = peg$FAILED;
                }
              } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
              }
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
          if (s0 === peg$FAILED) {
            s0 = peg$currPos;
            s1 = peg$parsespace();
            if (s1 !== peg$FAILED) {
              s2 = peg$parsevalue();
              if (s2 !== peg$FAILED) {
                if (input.substr(peg$currPos, 2) === peg$c31) {
                  s3 = peg$c31;
                  peg$currPos += 2;
                } else {
                  s3 = peg$FAILED;
                  if (peg$silentFails === 0) {
                    peg$fail(peg$c32);
                  }
                }
                if (s3 !== peg$FAILED) {
                  s4 = peg$parsefrequency();
                  if (s4 === peg$FAILED) {
                    s4 = null;
                  }
                  if (s4 !== peg$FAILED) {
                    peg$savedPos = s0;
                    s1 = peg$c33(s2, s4);
                    s0 = s1;
                  } else {
                    peg$currPos = s0;
                    s0 = peg$FAILED;
                  }
                } else {
                  peg$currPos = s0;
                  s0 = peg$FAILED;
                }
              } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
              }
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
            if (s0 === peg$FAILED) {
              s0 = peg$currPos;
              s1 = peg$parsespace();
              if (s1 !== peg$FAILED) {
                if (input.charCodeAt(peg$currPos) === 61) {
                  s2 = peg$c34;
                  peg$currPos++;
                } else {
                  s2 = peg$FAILED;
                  if (peg$silentFails === 0) {
                    peg$fail(peg$c35);
                  }
                }
                if (s2 !== peg$FAILED) {
                  s3 = peg$parsespace();
                  if (s3 !== peg$FAILED) {
                    s4 = peg$parsevalue();
                    if (s4 !== peg$FAILED) {
                      peg$savedPos = s0;
                      s1 = peg$c36(s4);
                      s0 = s1;
                    } else {
                      peg$currPos = s0;
                      s0 = peg$FAILED;
                    }
                  } else {
                    peg$currPos = s0;
                    s0 = peg$FAILED;
                  }
                } else {
                  peg$currPos = s0;
                  s0 = peg$FAILED;
                }
              } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
              }
              if (s0 === peg$FAILED) {
                s0 = peg$currPos;
                s1 = peg$parsespace();
                if (s1 !== peg$FAILED) {
                  if (input.substr(peg$currPos, 2) === peg$c37) {
                    s2 = peg$c37;
                    peg$currPos += 2;
                  } else {
                    s2 = peg$FAILED;
                    if (peg$silentFails === 0) {
                      peg$fail(peg$c38);
                    }
                  }
                  if (s2 !== peg$FAILED) {
                    s3 = peg$parsespace();
                    if (s3 !== peg$FAILED) {
                      s4 = peg$parsevalue();
                      if (s4 !== peg$FAILED) {
                        peg$savedPos = s0;
                        s1 = peg$c39(s4);
                        s0 = s1;
                      } else {
                        peg$currPos = s0;
                        s0 = peg$FAILED;
                      }
                    } else {
                      peg$currPos = s0;
                      s0 = peg$FAILED;
                    }
                  } else {
                    peg$currPos = s0;
                    s0 = peg$FAILED;
                  }
                } else {
                  peg$currPos = s0;
                  s0 = peg$FAILED;
                }
                if (s0 === peg$FAILED) {
                  s0 = peg$currPos;
                  s1 = peg$parsespace();
                  if (s1 !== peg$FAILED) {
                    if (input.substr(peg$currPos, 2) === peg$c40) {
                      s2 = peg$c40;
                      peg$currPos += 2;
                    } else {
                      s2 = peg$FAILED;
                      if (peg$silentFails === 0) {
                        peg$fail(peg$c41);
                      }
                    }
                    if (s2 !== peg$FAILED) {
                      s3 = peg$parsespace();
                      if (s3 !== peg$FAILED) {
                        s4 = peg$parsevalue();
                        if (s4 !== peg$FAILED) {
                          peg$savedPos = s0;
                          s1 = peg$c42(s4);
                          s0 = s1;
                        } else {
                          peg$currPos = s0;
                          s0 = peg$FAILED;
                        }
                      } else {
                        peg$currPos = s0;
                        s0 = peg$FAILED;
                      }
                    } else {
                      peg$currPos = s0;
                      s0 = peg$FAILED;
                    }
                  } else {
                    peg$currPos = s0;
                    s0 = peg$FAILED;
                  }
                  if (s0 === peg$FAILED) {
                    s0 = peg$currPos;
                    s1 = peg$parsespace();
                    if (s1 !== peg$FAILED) {
                      s2 = peg$parsevalue();
                      if (s2 !== peg$FAILED) {
                        s3 = peg$parsefrequency();
                        if (s3 === peg$FAILED) {
                          s3 = null;
                        }
                        if (s3 !== peg$FAILED) {
                          peg$savedPos = s0;
                          s1 = peg$c43(s2, s3);
                          s0 = s1;
                        } else {
                          peg$currPos = s0;
                          s0 = peg$FAILED;
                        }
                      } else {
                        peg$currPos = s0;
                        s0 = peg$FAILED;
                      }
                    } else {
                      peg$currPos = s0;
                      s0 = peg$FAILED;
                    }
                  }
                }
              }
            }
          }
        }

        return s0;
      }

      function peg$parsefrequency() {
        var s0, s1, s2, s3, s4;

        s0 = peg$currPos;
        s1 = peg$parsespace();
        if (s1 !== peg$FAILED) {
          if (input.substr(peg$currPos, 2) === peg$c44) {
            s2 = peg$c44;
            peg$currPos += 2;
          } else {
            s2 = peg$FAILED;
            if (peg$silentFails === 0) {
              peg$fail(peg$c45);
            }
          }
          if (s2 !== peg$FAILED) {
            s3 = peg$parsespace();
            if (s3 !== peg$FAILED) {
              s4 = peg$parseinteger();
              if (s4 !== peg$FAILED) {
                peg$savedPos = s0;
                s1 = peg$c46(s2, s4);
                s0 = s1;
              } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
              }
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
        if (s0 === peg$FAILED) {
          s0 = peg$currPos;
          s1 = peg$parsespace();
          if (s1 !== peg$FAILED) {
            if (input.charCodeAt(peg$currPos) === 60) {
              s2 = peg$c47;
              peg$currPos++;
            } else {
              s2 = peg$FAILED;
              if (peg$silentFails === 0) {
                peg$fail(peg$c48);
              }
            }
            if (s2 !== peg$FAILED) {
              s3 = peg$parsespace();
              if (s3 !== peg$FAILED) {
                s4 = peg$parseinteger();
                if (s4 !== peg$FAILED) {
                  peg$savedPos = s0;
                  s1 = peg$c46(s2, s4);
                  s0 = s1;
                } else {
                  peg$currPos = s0;
                  s0 = peg$FAILED;
                }
              } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
              }
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
          if (s0 === peg$FAILED) {
            s0 = peg$currPos;
            s1 = peg$parsespace();
            if (s1 !== peg$FAILED) {
              if (input.charCodeAt(peg$currPos) === 62) {
                s2 = peg$c49;
                peg$currPos++;
              } else {
                s2 = peg$FAILED;
                if (peg$silentFails === 0) {
                  peg$fail(peg$c50);
                }
              }
              if (s2 !== peg$FAILED) {
                s3 = peg$parsespace();
                if (s3 !== peg$FAILED) {
                  s4 = peg$parseinteger();
                  if (s4 !== peg$FAILED) {
                    peg$savedPos = s0;
                    s1 = peg$c46(s2, s4);
                    s0 = s1;
                  } else {
                    peg$currPos = s0;
                    s0 = peg$FAILED;
                  }
                } else {
                  peg$currPos = s0;
                  s0 = peg$FAILED;
                }
              } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
              }
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
            if (s0 === peg$FAILED) {
              s0 = peg$currPos;
              s1 = peg$parsespace();
              if (s1 !== peg$FAILED) {
                if (input.substr(peg$currPos, 2) === peg$c40) {
                  s2 = peg$c40;
                  peg$currPos += 2;
                } else {
                  s2 = peg$FAILED;
                  if (peg$silentFails === 0) {
                    peg$fail(peg$c41);
                  }
                }
                if (s2 !== peg$FAILED) {
                  s3 = peg$parsespace();
                  if (s3 !== peg$FAILED) {
                    s4 = peg$parseinteger();
                    if (s4 !== peg$FAILED) {
                      peg$savedPos = s0;
                      s1 = peg$c46(s2, s4);
                      s0 = s1;
                    } else {
                      peg$currPos = s0;
                      s0 = peg$FAILED;
                    }
                  } else {
                    peg$currPos = s0;
                    s0 = peg$FAILED;
                  }
                } else {
                  peg$currPos = s0;
                  s0 = peg$FAILED;
                }
              } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
              }
              if (s0 === peg$FAILED) {
                s0 = peg$currPos;
                s1 = peg$parsespace();
                if (s1 !== peg$FAILED) {
                  if (input.substr(peg$currPos, 2) === peg$c37) {
                    s2 = peg$c37;
                    peg$currPos += 2;
                  } else {
                    s2 = peg$FAILED;
                    if (peg$silentFails === 0) {
                      peg$fail(peg$c38);
                    }
                  }
                  if (s2 !== peg$FAILED) {
                    s3 = peg$parsespace();
                    if (s3 !== peg$FAILED) {
                      s4 = peg$parseinteger();
                      if (s4 !== peg$FAILED) {
                        peg$savedPos = s0;
                        s1 = peg$c46(s2, s4);
                        s0 = s1;
                      } else {
                        peg$currPos = s0;
                        s0 = peg$FAILED;
                      }
                    } else {
                      peg$currPos = s0;
                      s0 = peg$FAILED;
                    }
                  } else {
                    peg$currPos = s0;
                    s0 = peg$FAILED;
                  }
                } else {
                  peg$currPos = s0;
                  s0 = peg$FAILED;
                }
              }
            }
          }
        }

        return s0;
      }

      function peg$parsequery() {
        var s0, s1, s2, s3, s4;

        s0 = peg$currPos;
        s1 = peg$parselparen();
        if (s1 !== peg$FAILED) {
          s2 = peg$parsefield();
          if (s2 !== peg$FAILED) {
            s3 = peg$parsemodifier();
            if (s3 !== peg$FAILED) {
              s4 = peg$parserparen();
              if (s4 !== peg$FAILED) {
                peg$savedPos = s0;
                s1 = peg$c51(s2, s3);
                s0 = s1;
              } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
              }
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
        if (s0 === peg$FAILED) {
          s0 = peg$currPos;
          s1 = peg$parsefield();
          if (s1 !== peg$FAILED) {
            s2 = peg$parsemodifier();
            if (s2 !== peg$FAILED) {
              peg$savedPos = s0;
              s1 = peg$c51(s1, s2);
              s0 = s1;
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        }

        return s0;
      }

      function peg$parseprimary() {
        var s0, s1, s2, s3;

        s0 = peg$parsequery();
        if (s0 === peg$FAILED) {
          s0 = peg$currPos;
          s1 = peg$parselparen();
          if (s1 !== peg$FAILED) {
            s2 = peg$parseor_operation();
            if (s2 !== peg$FAILED) {
              s3 = peg$parserparen();
              if (s3 !== peg$FAILED) {
                peg$savedPos = s0;
                s1 = peg$c52(s2);
                s0 = s1;
              } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
              }
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        }

        return s0;
      }

      function peg$parseand_operation() {
        var s0, s1, s2, s3;

        s0 = peg$currPos;
        s1 = peg$parseprimary();
        if (s1 !== peg$FAILED) {
          s2 = peg$parseand();
          if (s2 !== peg$FAILED) {
            s3 = peg$parseand_operation();
            if (s3 !== peg$FAILED) {
              peg$savedPos = s0;
              s1 = peg$c53(s1, s3);
              s0 = s1;
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
        if (s0 === peg$FAILED) {
          s0 = peg$parseprimary();
        }

        return s0;
      }

      function peg$parseor_operation() {
        var s0, s1, s2, s3;

        s0 = peg$currPos;
        s1 = peg$parsequery();
        if (s1 !== peg$FAILED) {
          s2 = peg$parseor();
          if (s2 !== peg$FAILED) {
            s3 = peg$parseor_operation();
            if (s3 !== peg$FAILED) {
              peg$savedPos = s0;
              s1 = peg$c54(s1, s3);
              s0 = s1;
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
        if (s0 === peg$FAILED) {
          s0 = peg$parseand_operation();
        }

        return s0;
      }

      peg$result = peg$startRuleFunction();

      if (peg$result !== peg$FAILED && peg$currPos === input.length) {
        return peg$result;
      } else {
        if (peg$result !== peg$FAILED && peg$currPos < input.length) {
          peg$fail({ type: "end", description: "end of input" });
        }

        throw peg$buildException(null, peg$maxFailExpected, peg$maxFailPos < input.length ? input.charAt(peg$maxFailPos) : null, peg$maxFailPos < input.length ? peg$computeLocation(peg$maxFailPos, peg$maxFailPos + 1) : peg$computeLocation(peg$maxFailPos, peg$maxFailPos));
      }
    }

    return {
      SyntaxError: peg$SyntaxError,
      parse: peg$parse
    };
  }();
});
define('client/components/common/ui-fullbody', ['exports', 'client/mixins/resize'], function (exports, _resize) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_resize.default, {
    classNames: ["ui-fullbody"],
    attributeBindings: ["style"],

    style: Ember.computed(function () {

      if (this._state !== "inDOM") {
        return;
      }
      var viewportHeight = Ember.$(window).height(),
          elTop = this.$().offset().top;

      return ('height: ' + (viewportHeight - elTop) + 'px;').htmlSafe();
    }),

    didInsertElement: function didInsertElement() {
      this.handleResize();
    },


    onResize: Ember.on("resize", function (promise) {
      this.notifyPropertyChange("style");
      Ember.run.later(this, function () {
        promise();
      }, 100);
    })
  });
});
define('client/components/reports/edit-tile', ['exports', 'client/components/reports/edit-base'], function (exports, _editBase) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _editBase.default.extend({
    defaultLocationFilters: Ember.computed.alias('item.profile.locations')

  });
});
define('client/helpers/format-filter-countries', ['exports', 'client/utils/locations-by-region'], function (exports, _locationsByRegion) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.formatCountries = formatCountries;

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
        arr2[i] = arr[i];
      }

      return arr2;
    } else {
      return Array.from(arr);
    }
  }

  function formatCountries(filter) {
    var location = _locationsByRegion.default.ALL.filterBy('iso', filter.get('name'))[0];
    return location ? location.name : 'Unknown';
  }

  exports.default = Ember.Helper.helper(function (params) {
    return formatCountries.apply(undefined, _toConsumableArray(params));
  });
});
define('client/routes/settings/notifications/index', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    titleToken: 'Email Digest',
    modal: Ember.inject.service(),
    resetController: function resetController(controller, isExiting, transition) {
      if (isExiting) {
        controller.fullyRollbackSelectedEmail();
        controller.setProperties({
          selectedEmail: null
        });
      }
    },


    actions: {
      sendEmailDigest: function sendEmailDigest(emailDigest) {
        var _this = this;

        this.get('modal').open('common/ui-confirm', {
          prompt: "Are you sure you want to send this email digest?",
          ok: 'OK',
          okType: "danger"
        }).then(function () {
          var url = _this._buildSendUrl(emailDigest);
          var routerCtx = _this;
          Ember.$.post(url).then(function () {
            routerCtx.get('modal').open('common/ui-confirm', {
              prompt: "Email sent! You should receive it within a few minutes!",
              showCancel: false,
              ok: 'OK',
              okType: "info"
            });
          }, function (_ref) {
            var e = _ref.responseJSON.errors.error;

            if (e) {
              routerCtx.get('modal').open('common/ui-confirm', {
                prompt: 'Could not send email',
                messages: e,
                showCancel: false,
                ok: "OK",
                okType: "danger"
              });
            }
          });
        });
      }
    },

    _buildSendUrl: function _buildSendUrl(emailDigest) {
      return '/v2/api/email_digests/' + emailDigest.get('id') + '/send';
    }
  });
});
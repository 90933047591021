define('client/components/impact-score-target-media-list', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var ImpactScoreTargetMediaListComponent = Ember.Component.extend({
    store: Ember.inject.service(),
    modal: Ember.inject.service(),

    formClasses: "",
    wrapperClasses: "",
    buttonClasses: "",

    options: [],
    whitelistTags: [],
    targetMediaLists: [],
    selectedOption: null,

    getOptions: function getOptions() {

      var a = this.targetMediaLists.map(function (_ref) {
        var name = _ref.name;
        return name;
      });
      var b = this.whitelistTags.map(function (_ref2) {
        var name = _ref2.name;
        return name;
      });

      var ab = a.concat(b);
      ab.sort();

      // Inspired by: https://stackoverflow.com/a/39599465
      var options = [];
      var map = new Map();
      ab.forEach(function (a) {
        return map.set(a, (map.get(a) || 0) + 1);
      });
      options = ab.filter(function (a) {
        return map.get(a) === 1;
      });

      return options;
    },

    impactScoreSetting: Ember.computed('profile', function () {
      return this.get("profile.profileImpactScoreSetting");
    }),

    currentOptions: Ember.computed('targetMediaLists.[]', function () {
      this.set('options', this.getOptions());
      this.set('selectedOption', this.options[0]);
      return this.options;
    }),

    optionsButtonDisabled: Ember.computed('options.[]', function () {
      var returnVal = false;
      if (this.options.length < 1) {
        returnVal = true;
      }
      return returnVal;
    }),

    actions: {
      updateOption: function updateOption(item) {
        this.set('selectedOption', item);
      },
      addTargetMediaList: function addTargetMediaList() {
        var _this = this;

        var currentlySelectedOption = this.get('selectedOption');

        this.whitelistTags.forEach(function (whitelistTag) {
          if (whitelistTag.name === currentlySelectedOption) {

            var whitelistTagItem = whitelistTag;
            var profile_iss = _this.impactScoreSetting;
            var targetMediaListings = _this.targetMediaLists;

            var targetMediaListItem = _this.store.createRecord('impact-score-target-media-list', {
              name: whitelistTagItem.name,
              whitelistTagId: whitelistTagItem.id,
              profileImpactScoreSetting: profile_iss
            });

            function success(targetMediaListItem) {
              targetMediaListings.addObject(targetMediaListItem);
            }

            function failure(reason) {
              console.error(reason);
              alert("Sorry! An error just happened and your Target Media List was not added. Please reload the browser window and try again.");
            }

            targetMediaListItem.save().then(success).catch(failure);
          }
        });
      },
      removeTargetMediaList: function removeTargetMediaList(targetMediaListItem) {
        var _this2 = this;

        this.get('modal').open('common/ui-confirm', {
          prompt: 'Are you sure you want to remove this? If so, your changes will be saved and we will be processing your request.',
          ok: "Remove",
          okType: "danger"
        }).then(function () {
          function success(targetMediaListItem) {
            targetMediaListings.removeObject(targetMediaListItem);
          }

          function failure(reason) {
            console.error(reason);
            alert("Sorry! An error just happened and your Target Media List was not removed. Please reload the browser window and try again.");
          }

          var targetMediaListings = _this2.targetMediaLists;
          targetMediaListItem.deleteRecord();
          targetMediaListItem.save().then(success).catch(failure);
        });
      }
    }
  });

  exports.default = ImpactScoreTargetMediaListComponent;
});
define('client/utils/filters/profile-options', ['exports', 'client/models/report-items/chart-profile-options', 'client/models/report-items/profile-config'], function (exports, _chartProfileOptions, _profileConfig) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var FilterProfileOptions = Ember.Object.extend(_chartProfileOptions.default, {
    profileFilterList: Ember.computed("profileOptions.@each.id", {
      get: function get() {
        return this.get("profileOptions").getEach("nonUniqueIdentifier");
      },
      set: function set(k, v) {
        return v;
      }
    }),

    // autoAddNewProfileToList(){},

    profileNameOverrides: Ember.computed(function () {
      return {};
    }),

    otherProfiles: Ember.computed("searchTerm", "profile.hasCompetitorSearchTerm", "competitorSearchTerms.@each.{searchTerm,profileId}", "_otherProfiles.@each.{profileId,store}", {
      get: function get() {
        var _this = this;

        var profiles = this.get("_otherProfiles") || [],
            mainProfile = this.get("profile"),
            competitorSearchTerms = (mainProfile.get("hasCompetitorSearchTerm") ? this.get("competitorSearchTerms") : null) || [];

        return profiles.map(function (profile) {
          if (!profile) {
            return null;
          }

          var attrs = profile.getProperties("profileId", "store");
          attrs.searchTerm = _this.get("searchTerm");

          // check if a competitor is present
          var competitorSearchTerm = competitorSearchTerms.findBy("profileId", attrs.profileId);
          if (competitorSearchTerm) {
            attrs.searchTerm = competitorSearchTerm.get("searchTerm");
          }
          if (attrs.searchTerm) {
            attrs.useCustomSearchTerm = true;
          }

          return _profileConfig.default.create(attrs);
        }).compact();
      },
      set: function set(k, v) {
        return v;
      }
    }),

    getCampaignAndProfileId: function getCampaignAndProfileId(profileWrapper) {
      var profileId = void 0,
          campaignId = void 0,
          searchTerm = void 0;
      if (profileWrapper) {
        profileId = Ember.get(profileWrapper, 'profileId');
        campaignId = Ember.get(profileWrapper, 'campaignId');
      } else {
        profileId = this.get('profile.profile_id') || this.get('profile.id');
        campaignId = this.get('campaign.id');
      }
      var id = { profileId: profileId };
      if (campaignId !== undefined) {
        id.campaignId = campaignId;
      }
      return JSON.stringify(id);
    },
    getProfileNameWithSearch: function getProfileNameWithSearch(profileWrapper) {
      var name;
      if (profileWrapper) {
        name = Ember.get(profileWrapper, 'name');
      } else {
        name = this.get('campaign.name') || this.get('profile.name');
      }
      return name || '';
    }
  });

  exports.default = Ember.Mixin.create({
    filterProfileOptions: Ember.computed("campaign", "profile", "filterSet.otherProfiles.@each.id", "filterSet.competitorSearchTerms.@each.profileId", "searchTerm", function () {
      var profiles = {
        profile: this.get("profile"),
        campaign: this.get("campaign"),
        _otherProfiles: this.get("filterSet.otherProfiles"),
        searchTerm: this.get("searchTerm"),
        competitorSearchTerms: this.get("filterSet.competitorSearchTerms")
      };
      return FilterProfileOptions.create(profiles);
    }),

    profileFilterList: Ember.computed.alias("filterProfileOptions.profileFilterList"),

    getProfileIDFromUIName: function getProfileIDFromUIName(name) {
      return this.get("filterProfileOptions").getProfileIDFromUIName(name);
    },
    getProfileUIName: function getProfileUIName(profileWrapper) {
      return this.get("filterProfileOptions").getProfileUIName(profileWrapper);
    },
    getCampaignAndProfileId: function getCampaignAndProfileId(profileWrapper) {
      return this.get("filterProfileOptions").getCampaignAndProfileId(profileWrapper);
    }
  });
});
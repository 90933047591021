define('client/components/profile-color-swatch', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var DEFAULT_COLOR = '#bbb';

  exports.default = Ember.Component.extend({
    tagName: 'span',

    color: Ember.computed('colors', 'profile', function () {
      var color = this.get('colors').findBy('profileId', this.get('profile.id'));
      return color ? color.get('color') : DEFAULT_COLOR;
    })
  });
});
define('client/components/common/ui-page-title', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ["ui-page-title", "no-gutters", "d-flex"],

    items: null,
    selectedSort: null,
    sortedItems: Ember.computed.sort("items", "selectedSort"),

    selectedItem: Ember.computed("items.@each.selected", function () {
      return this.get("items").findBy("selected", true);
    }),

    init: function init() {
      this._super.apply(this, arguments);
      this.set('items', this.get('items') || []);
      this.set('selectedSort', this.get('selectedSort') || ["selected:desc"]);
    },
    addItem: function addItem(item) {
      this.get("items").addObject(item);
    },
    removeItem: function removeItem(item) {
      this.get("items").removeObject(item);
    }
  });
});
define('client/models/insight', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  //TODO: consolidate this with models/graph.js
  var GRAPH_TYPE_MAP = {
    line: 'graph-line',
    bar: 'graph-row',
    pie: 'graph-pie',
    donut: 'graph-pie',
    column: 'graph-column',
    grouped_column: 'graph-column',
    stacked_column: 'graph-column-stacked'
  };

  var RECUR_TYPE_TO_NOUN = {
    weekly: 'Week',
    quarterly: 'Quarter',
    monthly: 'Month'
  };

  var Insight = _emberData.default.Model.extend({
    profile: _emberData.default.belongsTo('profile'),
    competitor: _emberData.default.belongsTo('competitor'),
    insightRule: _emberData.default.belongsTo('insight-rule'),

    authorRecommendations: _emberData.default.hasMany('author-recommendation'),
    domainRecommendations: _emberData.default.hasMany('domain-recommendation'),
    perRecommendations: _emberData.default.hasMany('per-recommendation'),
    messageRecommendations: _emberData.default.hasMany('message-recommendation'),
    comentionRecommendations: _emberData.default.hasMany('comention-recommendation'),

    chartAmplificationKey: _emberData.default.attr('string'),
    metricName: _emberData.default.attr('string'),
    metric: _emberData.default.attr('string'),
    secondaryMetric: _emberData.default.attr('string'),
    secondaryMetricName: _emberData.default.attr('string'),
    topic: _emberData.default.attr('string'),
    recurType: _emberData.default.attr('string'),
    resultType: _emberData.default.attr('string'),
    ruleType: _emberData.default.attr('string'),

    question: _emberData.default.attr('string'),
    highlightedQuestion: _emberData.default.attr('string'),
    userStatus: _emberData.default.attr('string'),

    results: _emberData.default.attr("object"),

    createdAt: _emberData.default.attr('isodate'),
    startDate: _emberData.default.attr('isodate'),
    endDate: _emberData.default.attr('isodate'),

    isTopRule: Ember.computed('resultType', function () {
      return Ember.isPresent(this.get('resultType'));
    }),

    isTopArticles: Ember.computed.equal('resultType', 'pers'),
    isTopMessages: Ember.computed.equal('resultType', 'messages'),
    isTopAuthors: Ember.computed.equal('resultType', 'authors'),
    isTopDomains: Ember.computed.equal('resultType', 'domains'),
    isTopEntities: Ember.computed.equal('resultType', 'entities'),

    chartData: Ember.computed('results', function () {
      if (this.get('results.details.base')) {
        return this.get('results.details.base').concat(this.get('results.details.compare'));
      } else {
        return this.get('results.details');
      }
    }),

    comparisonValues: Ember.computed('results.comp_values', function () {
      return this.get('results.comp_values');
    }),

    intervalValues: Ember.computed('results.interval_vals', function () {
      return this.get('results.interval_vals');
    }),

    recurTypeNoun: Ember.computed('recurType', function () {
      return RECUR_TYPE_TO_NOUN[this.get('recurType')];
    }),

    displayMetric: Ember.computed('metricName', 'secondaryMetric', 'secondaryMetricName', function () {
      var metricName = this.get('metricName');
      if (Ember.isPresent(this.get('secondaryMetric')) && this.get('secondaryMetric') !== 'all_amplification' && this.get('secondaryMetric') !== 'all_interaction') {
        //sometimes name will be null if the event/goal has been deleted
        var secondaryMetric = this.get('secondaryMetricName') || this.get('secondaryMetric');
        return secondaryMetric + ' ' + metricName;
      } else {
        return metricName;
      }
    }),

    summaryValues: Ember.computed.or('comparisonValues', 'intervalValues'),

    isInterval: Ember.computed('intervalValues', function () {
      return Ember.isPresent(this.get('intervalValues'));
    }),

    isPowerOfVoice: Ember.computed.equal('metric', 'power_of_voice'),

    isSavedForUser: Ember.computed('userStatus', function () {
      return Ember.isEqual(this.get('userStatus'), 'saved');
    }),

    isDeletedForUser: Ember.computed('userStatus', function () {
      return Ember.isEqual(this.get('userStatus'), 'deleted');
    }),

    getProfileName: function getProfileName(profileId) {
      return this.store.peekRecord('profile', profileId).get('companyName');
    }

  });

  exports.default = Insight;
});
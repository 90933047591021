define('client/components/reports/report-share', ['exports', 'client/components/reports/main-container'], function (exports, _mainContainer) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _mainContainer.default.extend({
    classNames: ["report-share"],
    report: null,

    interactions: true,
    arrows: false,
    onNextPage: function onNextPage() {},
    onPreviousPage: function onPreviousPage() {},
    wheel: function wheel(e) {
      if (this.get("arrows")) {
        return;
      }
      // check if the scroll is either 0 or scrollheight
      var oe = e.originalEvent,
          $container = Ember.$(".reports-container"),
          height = $container.height(),
          scrollTop = $container.scrollTop(),
          scrollHeight = $container[0].scrollHeight;

      if (scrollTop > 0 && scrollTop < scrollHeight - height || oe.deltaY < 30 && oe.deltaY > -30) {
        // still scrolling
        return;
      }

      e.preventDefault();
      e.stopImmediatePropagation();

      if (oe.deltaY < -30) {
        // going up
        Ember.run.cancel(this._downBounce);
        this._upBounce = Ember.run.debounce(this, "previousPage", 100, true);
      } else if (oe.deltaY > 30) {
        // going down
        Ember.run.cancel(this._upBounce);
        this._downBounce = Ember.run.debounce(this, "nextPage", 100, true);
      }
    },
    onKeyDown: function onKeyDown(e) {
      switch (e.keyCode) {
        case 38: //up
        case 37:
          //left
          this.previousPage();
          break;
        case 40: //down
        case 39:
          //right
          this.nextPage();
          break;
      }
    },

    totalPages: Ember.computed.oneWay('report.pages.length'),

    canPrevPage: Ember.computed("selectedPage", function () {
      return +this.get("selectedPage") > 0;
    }),

    canNextPage: Ember.computed("selectedPage", "totalPages", function () {
      return +this.get("selectedPage") < this.get("totalPages") - 1;
    }),

    previousPage: function previousPage() {
      if (this.get('reportService.chartApiHash')) {
        return;
      }
      this.onPreviousPage();
      Ember.$(".reports-container").scrollTop(0);
    },
    nextPage: function nextPage() {
      if (this.get('reportService.chartApiHash')) {
        return;
      }
      this.onNextPage();
      Ember.$(".reports-container").scrollTop(0);
    },
    didInsertElement: function didInsertElement() {
      var _this = this;

      this._super.apply(this, arguments);
      if (!this.get("arrows")) {
        this.$().on("mousewheel." + this.get("elementId"), function (e) {
          return _this.wheel(e);
        });
      }
      Ember.$("body").on('keydown.' + this.get("elementId"), function (event) {
        Ember.run.bind(_this, _this.onKeyDown(event));
      });
    },


    willDestroyElement: function willDestroyElement() {
      var _this2 = this;

      this._super.apply(this, arguments);
      if (!this.get("arrows")) {
        this.$().off("mousewheel." + this.get("elementId"));
      }
      Ember.$("body").on('keydown.' + this.get("elementId"), function (event) {
        Ember.run.bind(_this2, _this2.onKeyDown(event));
      });
    },

    actions: {
      nextPage: function nextPage() {
        this.nextPage();
      },
      previousPage: function previousPage() {
        this.previousPage();
      },
      selectPage: function selectPage(page) {
        this.set("selectedPage", page);
      }
    }
  });
});
define("client/utils/twitter-settings", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    COUNTS_END_DATE: new Date(2015, 10, 20), // Nov 20, 2015
    GNIP_START_DATE: new Date(2016, 9, 1) // Oct  1, 2016
  };
});
define('client/components/spokesperson-list', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: 'span',
    classNames: ['spokesperson-list'],

    addFromSearch: function addFromSearch() {},
    removeSpokesperson: function removeSpokesperson() {},
    init: function init() {
      this._super.apply(this, arguments);
      this.set('impactScoreSpokesperson', this.get('impactScoreSpokesperson') || []);
    }
  });
});
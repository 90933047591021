define('client/components/common/ui-tooltip', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var SimpleTooltipComponent = Ember.Component.extend({
    classNames: ['simple-tooltip'],

    "data-delay": 500,
    "data-placement": "right",
    "data-render-in-place": false,
    placement: Ember.computed.alias("data-placement"),
    delay: Ember.computed.alias("data-delay"),
    title: Ember.computed.alias("tooltip"),
    renderInPlace: Ember.computed.alias("data-render-in-place"),

    action: function action() {},


    click: function click() {
      this.action();
    }
  });

  exports.default = SimpleTooltipComponent;
});
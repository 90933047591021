define('client/helpers/format-neo-for-analyze', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.formatNeoForAnalyze = formatNeoForAnalyze;

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
        arr2[i] = arr[i];
      }

      return arr2;
    } else {
      return Array.from(arr);
    }
  }

  /**
   * Function that will format message for backend processing
   *
   * Rules:
   * no quotations:  case insensitive.
   * quotations:     case sensitive.
   *
   * PR Attribution = (content: "PR Attribution")
   * PR Attribution~number = (content: "PR Attribution"~number)
   * "PR Attribution" = (content: CASE "PR Attribution")
   * "PR Attribution"~number = (content: CASE "PR Attribution"~number)
   * (content: "PR Attribution") = (content: "PR Attribution")
   *
   * Note: ~number is slop:
   * https://www.elastic.co/guide/en/elasticsearch/guide/current/slop.html
   *
   * @param {string} message
   */
  function formatNeoForAnalyze(message) {
    // remove any trailing whitespace
    message = message.trim();

    var slop = '';
    var formattedMsg = message;

    // Extract slop
    var slopStartIndex = message.lastIndexOf('~');
    if (slopStartIndex != -1) {
      formattedMsg = message.substr(0, slopStartIndex).trim();
      slop = message.substr(slopStartIndex);
    }

    // A phrase is a string surrounded by quotes (eg. "I am a phrase" )
    var isPhrase = formattedMsg.indexOf("\"") > -1;

    if (isPhrase) {
      var startQuote = formattedMsg.indexOf("\"");
      var endQuote = formattedMsg.lastIndexOf("\"");
      var phrase = formattedMsg.substr(startQuote, endQuote);
      phrase = '"' + phrase.replace(/['"']+/g, '') + '"'; // Strip all quotes and re-apply at start and end
      formattedMsg = '(content: CASE ' + phrase;
    } else if (formattedMsg.indexOf('CASE') > -1) {
      var _phrase = formattedMsg.replace(/.*CASE/, '').trim();
      formattedMsg = '(content: CASE "' + _phrase + '"';
    } else if (formattedMsg.startsWith('content:')) {
      formattedMsg = '(' + formattedMsg;
    } else {
      formattedMsg = '(content: "' + formattedMsg + '"';
    }

    formattedMsg = '' + formattedMsg + slop + ')';

    return formattedMsg;
  }

  exports.default = Ember.Helper.helper(function (params) {
    return formatNeoForAnalyze.apply(undefined, _toConsumableArray(params));
  });
});
define('client/mixins/data-table-sort', ['exports', 'ember-parachute'], function (exports, _emberParachute) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var DataTableSortQP = new _emberParachute.default({
    type: {
      defaultValue: null,
      refresh: true
    }
  });

  exports.default = Ember.Mixin.create(DataTableSortQP, {
    user: Ember.computed.alias("currentUser.content"),

    perType: null,

    profile: Ember.computed.alias("profileService.profile"),

    parent_id: null,

    parent: Ember.computed('parent_id', function () {
      var id = this.get('parent_id');
      if (!id || id === 'null') {
        return false;
      }
      return this.store.findRecord('profile', id);
    }),

    _paramsDidChange: function _paramsDidChange() {
      var type = this.get("type"),
          profile = this.get("profile.id"),
          perType = this.get("perType"),
          otherParams = this.get("otherParams") || {},
          params = {
        sorts: {}
      };
      if (type && type != "null") {
        params.type = type;
      }

      if (perType && perType != "null") {
        params.per_type = perType;
      }

      if (profile) {
        params.profile_id = profile;
      }

      this.get("_sorts").forEach(function (sort) {
        var sortVal = this.get("sort" + sort);
        if (sortVal) {
          params.sorts[sort.toLowerCase()] = sortVal;
        }
      }, this);
      this.set("resources.search", Ember.merge(params, otherParams));
    },

    actions: {
      removeSorts: function removeSorts() {
        this.beginPropertyChanges();
        this.get("_sorts").forEach(function (sort) {
          this.set("sort" + sort, null);
        }, this);
        this.endPropertyChanges();
      }
    }

  });
});
define('client/controllers/research/authors/author', ['exports', 'ember-parachute', 'client/utils/locations-by-region'], function (exports, _emberParachute, _locationsByRegion) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var AuthorQP = new _emberParachute.default({
    openNotes: {
      as: 'notes',
      type: "boolean",
      defaultValue: false
    }
  });

  exports.default = Ember.Controller.extend(AuthorQP.Mixin, {

    authorsController: Ember.inject.controller("research.authors"),

    competitors: Ember.computed.oneWay("authorsController.competitors"),
    locationFiltersOptions: Ember.computed.oneWay("authorsController.locationFiltersOptions"),
    domains: Ember.computed.oneWay("authorsController.domains"),
    editorial_topics: Ember.computed.oneWay("authorsController.editorialTopics"),
    geo_info: Ember.computed.oneWay("authorsController.geo_info"),

    filters: Ember.computed(function () {
      var locations = Object.keys(this.get("locationFiltersOptions")).map(function (lcn) {
        return _locationsByRegion.default.ALL.findBy("id", +lcn.replace("lcn_", ""));
      });
      return {
        competitors: this.get("competitors").slice(),
        locations: locations,
        geo_info: this.get("geo_info").slice(),
        domains: this.get("domains").slice(),
        editorial_topics: this.get("editorial_topics").slice()
      };
    }),

    setup: function setup() {
      this._super();
      this.get('redirect');
      this.notifyPropertyChange("filters");
    },

    /**
     * Observer like property used to check profile permissions to view this feature.
     * Features are available once the profile model is loaded.
     * Observer is not used because property will not be triggered if profile data is available.
     */
    redirect: Ember.computed('profile.showAuthorIntelligence', function () {
      if (!this.get('profileService.profile.showAuthorIntelligence')) {
        this.transitionToRoute('research');
      }
    }),

    actions: {
      goToAuthors: function goToAuthors() {
        this.replaceRoute('research.authors');
      },
      removeFilter: function removeFilter(type, value) {
        if (type === 'locations') {
          this.set("filters.locations", []);
        } else {
          this.get("filters")[type].removeObject(value);
        }
      }
    }
  });
});
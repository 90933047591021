define('client/components/reports/chart-column-stack', ['exports', 'client/components/reports/chart-base-widget'], function (exports, _chartBaseWidget) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _chartBaseWidget.default.extend({
    classNameBindings: [':report-chart-item'],

    // Don't show checkbox ever
    showDataValuesCheckboxThreshold: -1,

    showAsToneChartCheckbox: Ember.computed('groupDimension', function () {
      return this.get('groupDimension.firstObject') === 'tone_label';
    }),

    showAsToneChart: Ember.computed.alias("item.showAsToneChart"),

    showGraphAsToneChart: Ember.computed('showAsToneChart', 'showAsToneChartCheckbox', function () {
      // We will only really show the tone chart if the user wanted to
      // and we still can
      return this.get('showAsToneChartCheckbox') && this.get('showAsToneChart');
    }),

    groupOptionsMenuName: Ember.computed.alias("item.groupOptionsMenuName"),
    groupDimension: Ember.computed.alias("item.groupDimension")
  });
});
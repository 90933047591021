define('client/components/expanded-article-translation', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var ExpandedArticleTranslationComponent = Ember.Component.extend({
    isExpanded: true,
    canReportIssue: Ember.computed.equal('type', 'Human'),
    showIssueBox: false,
    issueText: "",

    type: '',
    translation: null,
    translationTask: null,
    format: null,

    actions: {
      toggle: function toggle() {
        this.toggleProperty('isExpanded');
      },
      voteUp: function voteUp() {
        return null;
      },
      voteDown: function voteDown() {
        return null;
      }
    }
  });

  exports.default = ExpandedArticleTranslationComponent;
});
define('client/components/filters-notice', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    campaign: null,
    filterSet: null,
    showCampaignMessage: Ember.computed.bool('campaign.id'),
    onResetFilters: function onResetFilters() {},

    actions: {
      resetFilters: function resetFilters() {
        this.get('onResetFilters')();
      }
    }
  });
});
define('client/components/popover-trigger', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var PopoverTriggerComponent = Ember.Component.extend({
    isOpen: Ember.computed.alias('parentView.isOpen'),

    toggle: function toggle() {
      this.get('parentView').send('toggle');
    },

    didInsertElement: function didInsertElement() {
      this.get('parentView').registerTrigger(this);
    },

    willRemoveElement: function willRemoveElement() {
      this.get('parentView').unregisterTrigger();
    }
  });

  exports.default = PopoverTriggerComponent;
});
define('client/components/graph/location-options', ['exports', 'client/utils/locations-by-region'], function (exports, _locationsByRegion) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    filterSet: null,
    selectionSet: null,
    userModified: false,
    options: Ember.computed("selectionSet.locations", "defaultLocations", "userModified", function () {
      var locations = this.get("selectionSet.locations");
      var userModified = this.get("userModified");
      locations = Ember.isEmpty(locations) && !userModified ? this.get("defaultLocations") : locations;
      return locations.reduce(function (map, value) {
        var id = _locationsByRegion.default.ALL.findBy("iso", value).id;
        map['lcn_' + id] = true;
        return map;
      }, {});
    }),

    availableLocations: Ember.computed('filterSet.articleListAdapter.locationFilterList.[]', function () {
      var filterSet = this.get('filterSet');

      var lcns = filterSet.get('articleListAdapter.locationFilterList').filter(function (lcn) {
        return lcn !== "lcn_1";
      }).map(function (lcn) {
        var lcnId = parseInt(lcn.match(/\d+/)[0]);
        return _locationsByRegion.default.ALL.findBy("id", lcnId).iso;
      });
      return Ember.A(lcns);
    }),
    defaultLocations: Ember.computed.alias("filterSet.reportingAdapter.filterOptions.locationOptions"),
    getLocationsForRegion: function getLocationsForRegion(region, location) {
      var locations = this.get("availableLocations");
      return Ember.A(_locationsByRegion.default[region]).filter(function (location) {
        return locations.indexOf(location.iso) >= 0;
      });
    },

    northAmericaLocations: Ember.computed('availableLocations.[]', function () {
      return this.getLocationsForRegion('NA');
    }),

    southAmericaLocations: Ember.computed('availableLocations.[]', function () {
      return this.getLocationsForRegion('SA');
    }),

    africaLocations: Ember.computed('availableLocations.[]', function () {
      return this.getLocationsForRegion('AF');
    }),

    asiaLocations: Ember.computed('availableLocations.[]', function () {
      return this.getLocationsForRegion('AS');
    }),

    europeLocations: Ember.computed('availableLocations.[]', function () {
      return this.getLocationsForRegion('EU');
    }),

    oceaniaLocations: Ember.computed('availableLocations.[]', function () {
      return this.getLocationsForRegion('OC');
    }),

    antarcticaLocations: Ember.computed('availableLocations.[]', function () {
      return this.getLocationsForRegion('AN');
    }),

    otherLocations: Ember.computed('availableLocations.[]', function () {
      return this.getLocationsForRegion('XX');
    }),

    allLocations: Ember.computed.union('northAmericaLocations', 'southAmericaLocations', 'africaLocations', 'asiaLocations', 'europeLocations', 'oceaniaLocations', 'antarcticaLocations', 'otherLocations'),

    update: function update() /*locationIso, bool*/{},


    isDefaultSelection: Ember.computed("selectionSet.locations.length", "options", function () {
      return this.get("selectionSet.locations.length") !== Object.keys(this.get("options")).length;
    }),

    selectDefaultSelection: function selectDefaultSelection(doNotSelect) {
      var update = this.get("update");
      var values = [];
      var locationsToSelect = Object.keys(this.get("options")).map(function (lcn) {
        var iso = _locationsByRegion.default.ALL.findBy("id", parseInt(lcn.match(/\d+/)[0])).iso;
        if (doNotSelect.indexOf(iso) >= 0) {
          values.push(false);
        } else {
          values.push(true);
        }
        return iso;
      });

      update(locationsToSelect, values);
    },


    actions: {
      toggleLocation: function toggleLocation(locationId) {

        // find iso
        var iso = _locationsByRegion.default.ALL.findBy("id", locationId).iso,
            selected = this.get("selectionSet.locations").indexOf(iso) >= 0;

        if (this.get("isDefaultSelection")) {
          this.selectDefaultSelection([iso]);
        } else {
          this.get("update")(iso, !selected);
        }
        this.set("userModified", true);
      },
      uncheckRegion: function uncheckRegion(region) {
        var filterSet = this.get("filterSet.content"),
            update = this.get("update");

        var isos = filterSet.getLocationsForRegion(region).map(function (item) {
          return item.iso;
        });

        if (this.get("isDefaultSelection")) {
          return this.selectDefaultSelection(isos);
        } else {
          update(isos, new Array(isos.length).fill(false));
        }
        this.set("userModified", true);
      },
      checkRegion: function checkRegion(region) {
        this.set("userModified", true);
        var filterSet = this.get("filterSet.content"),
            update = this.get("update");
        var isos = filterSet.getLocationsForRegion(region).map(function (item) {
          return item.iso;
        });

        update(isos, new Array(isos.length).fill(true));
      }
    }
  });
});
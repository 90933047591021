define('client/components/dashboard/dashboard-card/top-five', ['exports', 'client/components/common/ui-drawer'], function (exports, _uiDrawer) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _uiDrawer.default.extend({
    classNames: ["article-list-card"],
    articleList: null,
    profileName: Ember.computed.alias("profileService.profile.company_name"),
    maxNameLength: 20

  });
});
define('client/components/data-table', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ["data-table"],

    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      this.$("tbody").on("scroll", Ember.$.proxy(this, "triggerNextPage"));
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);
      this.$("tbody").off("scroll");
    },


    tableRecords: Ember.computed("content.model.[]", function () {
      var model = this.get('content.model') || Ember.A();
      return model.mapBy('record').toArray();
    }),
    triggerNextPage: function triggerNextPage(e) {
      var _this = this;

      Ember.run.schedule('afterRender', function () {
        // this.triggerNextPage(e);
        var $scroll = Ember.$(e.currentTarget),
            top = $scroll.scrollTop(),
            height = $scroll[0].scrollHeight - $scroll.height();
        if (top > 0.6 * height || height === 0) {
          // trigger a load
          var content = _this.get("content");
          if (content && content.nextPage) {
            content.nextPage();
          }
        }
      });
    }
  });
});
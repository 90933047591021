define("client/models/spam", ["exports", "ember-data"], function (exports, _emberData) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var Spam = _emberData.default.Model.extend({

    profile: _emberData.default.belongsTo("profile"),
    entity_id: _emberData.default.attr("string"),
    url: _emberData.default.attr("string"),
    timestamp: _emberData.default.attr("isodate"),

    // ACTIONS

    markNotSpam: function markNotSpam() {
      $.post("/v2/api/pers/" + this.get("id") + "/mark_not_spam?profile_id=" + this.get("profile.id"));
    }

  });
  Spam.pathForType = "spam";

  exports.default = Spam;
});
define('client/components/insights/insight-graph-summary', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
        arr2[i] = arr[i];
      }

      return arr2;
    } else {
      return Array.from(arr);
    }
  }

  exports.default = Ember.Component.extend({
    classNames: ["insight-graph-summary"],

    insight: null,

    summaryPercentage: Ember.computed('insight.summaryValues', function () {
      var summaryValues = this.get('insight.summaryValues');
      var max = Math.max.apply(Math, _toConsumableArray(summaryValues));
      var min = Math.min.apply(Math, _toConsumableArray(summaryValues));
      if (summaryValues) {
        return Math.trunc((max - min) / max * 100);
      }
    })

  });
});
define('client/utils/round', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = round;
  function round(value) {
    var decimals = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 2;

    //handle undefined
    value |= 0;

    return parseFloat(Math.round(value + 'e' + decimals) + 'e-' + decimals);
  }
});
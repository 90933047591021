define('client/components/common/ui-tooltip-container', ['exports', 'client/mixins/tooltippable-container'], function (exports, _tooltippableContainer) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var TooltipContainerComponent = Ember.Component.extend(_tooltippableContainer.default, {
    tagName: 'span'

  });

  exports.default = TooltipContainerComponent;
});
define('client/utils/css-transitions/on-transition-end', ['exports', 'ember-modal-service/utils/css-transitions/on-transition-end'], function (exports, _onTransitionEnd) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _onTransitionEnd.default;
    }
  });
});
define('client/components/common/ui-calendar-day', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    mouseEnter: function mouseEnter() {
      this.set('isHovering', true);
      this.get('onHover')(this.get('date'));
    },
    mouseLeave: function mouseLeave() {
      this.set('isHovering', false);
    }
  });
});
define('client/serializers/article', ['exports', 'ember-data', 'active-model-adapter'], function (exports, _emberData, _activeModelAdapter) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  //
  // Article serializer
  // The JSON root is always per and not article and all related articles are embedded.
  //
  var ArticleSerializer = _activeModelAdapter.ActiveModelSerializer.extend(_emberData.default.EmbeddedRecordsMixin, {
    attrs: {
      entities: { embedded: 'always' }
    },
    rootForType: "per",
    typeForRoot: function typeForRoot() {
      return "article";
    },

    normalize: function normalize(type, hash) {
      if (!hash.links) {
        hash.links = {};
      }
      hash.links.translationTasks = '/v3/api/translation_tasks?profile_entity_relationship_id=' + hash.id;
      hash = this._super(type, hash);
      return hash;
    },
    //todo: remove in ember data 2
    isNewSerializerAPI: true
  });

  exports.default = ArticleSerializer;
});
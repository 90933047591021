define('client/components/common/ui-calendar-multiple', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    onHover: function onHover() {},
    onSelect: function onSelect() {}
  });
});
define('client/components/dashboard/widgets/impact-score-card', ['exports', 'client/components/dashboard/widgets/widget-card', 'client/utils/date-format'], function (exports, _widgetCard, _dateFormat2) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _widgetCard.default.extend({

    sort: "impact_score",

    infoText: Ember.computed(function () {
      return Ember.String.htmlSafe("<p></p>");
    }),

    impactScore: Ember.computed("data.impact_score", function () {
      return this.get('data.impact_score');
    })

  });
});
define('client/transforms/isodate', ['exports', 'ember-data', 'client/utils/-iso-format'], function (exports, _emberData, _isoFormat) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  //
  // Handle date JSON data from the backend
  //
  var IsodateTransform = _emberData.default.Transform.extend({
    serialize: function serialize(value) {
      return value && typeof value !== "string" ? (0, _isoFormat.isoFormat)(value) : value;
    },
    deserialize: function deserialize(value) {
      return value ? _isoFormat.isoFormat.parse(value.substring(0, 10)) : value;
    }
  });

  exports.default = IsodateTransform;
});
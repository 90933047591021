define('client/adapters/v3-application', ['exports', 'active-model-adapter'], function (exports, _activeModelAdapter) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var V3ApplicationAdapter = _activeModelAdapter.default.extend({
    // always append this to the ajax url
    namespace: 'v3/api'
  });

  exports.default = V3ApplicationAdapter;
});
define('client/components/research/author-sentiment', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var SENTIMENT_POSITIVE = 'Positive';
  var SENTIMENT_NEGATIVE = 'Negative';
  var SENTIMENT_NEUTRAL = 'Neutral';
  var SENTIMENT_NOT_SCORED = 'Not scored';

  var SENTIMENT_OPTIONS = {
    positive: SENTIMENT_POSITIVE,
    negative: SENTIMENT_NEGATIVE,
    neutral: SENTIMENT_NEUTRAL,
    not_scored: SENTIMENT_NOT_SCORED
  };

  exports.SENTIMENT_OPTIONS = SENTIMENT_OPTIONS;
  exports.default = Ember.Component.extend({
    classNames: ['author-sentiment'],
    title: "Social Sentiment",
    showTooltip: Ember.computed('sentiment', function () {
      return this.get("contentType") !== 'article' || this.get("sentiment") === SENTIMENT_NOT_SCORED;
    }),
    sentimentClass: Ember.computed('sentiment', function () {
      var type = SENTIMENT_OPTIONS.not_scored;

      var sentiment = this.get('sentiment');
      switch (sentiment) {
        case SENTIMENT_POSITIVE:
          type = SENTIMENT_OPTIONS.positive;break;
        case SENTIMENT_NEGATIVE:
          type = SENTIMENT_OPTIONS.negative;break;
        case SENTIMENT_NEUTRAL:
          type = SENTIMENT_OPTIONS.neutral;break;
      }
      return type.toLocaleLowerCase();
    }),
    sentimentTooltip: Ember.computed('sentiment', function () {
      var sentiment = this.get("sentiment");
      var contentType = this.get("contentType");
      if (contentType === "author" && sentiment) {
        return "Social sentiment is the author's general sentiment on social media based on Twitter";
      } else if (contentType === "article" && sentiment === SENTIMENT_NOT_SCORED) {
        return "This article was not able to be scored for sentiment. Click Reset Brand Sentiment to adjust as needed. For more info, click <a target='_blank' href='https://onclusive.helpscoutdocs.com/article/586-change-brand-sentiment-multiple-articles'>here.</a>";
      }
    })

  });
});
define('client/models/report-items/pie', ['exports', 'client/models/report-items/chart', 'client/models/report-item'], function (exports, _chart, _reportItem) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _chart.default.extend({
    labelDimension: [],
    labelOptionsMenuName: 'label',

    groupOptionsMenuName: 'Slices',
    groupDimension: (0, _reportItem.propWithDefaultValue)('groupDimension', function () {
      return ['profile'];
    }),
    showDataValuesAs: (0, _reportItem.propWithDefaultValue)('showDataValuesAs', function () {
      return this.get('showDataValuesAsOptions.firstObject.name');
    }),
    valueOptionsMenuName: 'PR Metric',

    showDataValuesAsOptions: Ember.computed('valueDimension', 'showAmplificationValue', 'showInteractionValue', function () {
      var standardOptions = [{
        name: 'actual',
        displayName: 'Actual'
      }, {
        name: 'percent',
        displayName: 'Percent'
      }, {
        name: 'actualAndPercent',
        displayName: 'Actual (%)'
      }];

      if (this.get('showAmplificationValue') || this.get('showInteractionValue')) {
        return standardOptions;
      } else if (this.get('valueDimension').includes('power_of_voice')) {
        this.set("showDataValuesAs", 'percent'); // eslint-disable-line
        return [{
          name: 'percent',
          displayName: 'Percent'
        }];
      }
      return standardOptions;
    })

  });
});
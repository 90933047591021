define('client/controllers/plan/neo/buckets/show', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    store: Ember.inject.service(),
    router: Ember.inject.service(),

    selectedBucketId: Ember.computed.alias("model"),
    profile: Ember.computed.alias("profileService.profile"),
    buckets: Ember.computed.alias("profile.profileMessageBuckets"),
    bucket: Ember.computed("buckets.@each.id", "selectedBucketId", function () {
      return (this.get("buckets") || []).findBy("id", this.get("selectedBucketId"));
    }),

    profileDidChange: function profileDidChange(profile) {

      if (this.get("target.currentRouteName").indexOf("plan.neo") >= 0 && profile.get("id") !== this.get("bucket.profile.id")) {
        this.get("router").transitionTo("plan.neo.buckets.index");
      }
    }
  });
});
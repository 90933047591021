define('client/initializers/find-paging-controller', ['exports', 'client/controllers/find-paging', 'client/mixins/page'], function (exports, _findPaging, _page) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.initialize = initialize;

  var PagingArrayController = Ember.Controller.extend(_page.default);

  function initialize(application) {
    application.register('controller:find-paging', _findPaging.default, { singleton: false });
    application.register('controller:paging-array', PagingArrayController, { singleton: false });
  }

  exports.default = {
    name: 'find-paging',
    initialize: initialize
  };
});
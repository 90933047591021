define("client/routes/sales/duplicate", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    titleToken: "Duplicate",

    model: function model(params) {
      return this.store.findRecord("sales/sale-profile", params.id);
    }
  });
});
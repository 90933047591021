define('client/models/sales/sale-profile', ['exports', 'ember-data', 'ember-api-actions'], function (exports, _emberData, _emberApiActions) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var SaleProfile = _emberData.default.Model.extend({

    createdBy: _emberData.default.attr("string"),
    createdAt: _emberData.default.attr("date"),

    modifiedBy: _emberData.default.attr("string"),
    updatedAt: _emberData.default.attr("date"),

    profile: _emberData.default.belongsTo("profile"),
    competitors: _emberData.default.hasMany("profile"),

    isCustomer: _emberData.default.attr("boolean"),

    estimate: (0, _emberApiActions.memberAction)({
      path: "estimate"
    }),

    duplicate: (0, _emberApiActions.memberAction)({
      path: "duplicate"
    })

  });

  SaleProfile.pathForType = "sale_profiles";

  exports.default = SaleProfile;
});
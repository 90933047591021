define("client/controllers/plan/pitch/new", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({

    actions: {
      onCancel: function onCancel() {
        this.get("model").deleteRecord();
        this.transitionToRoute("plan.pitch");
      },
      onSaved: function onSaved() {
        this.transitionToRoute("plan.pitch");
      }
    }

  });
});
define('client/components/common/ui-three-dots', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ["ui-three-dots"],
    currentProfile: null,
    renderInPlace: false,
    align: 'right'

  });
});
define('client/components/reports/label-options', ['exports', 'client/mixins/volume-filter'], function (exports, _volumeFilter) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var showBasedOnOtherSelection = function showBasedOnOtherSelection(types) {
    if (typeof types == 'string') {
      types = [types];
    }
    return Ember.computed("otherDimensionSelection.[]", function () {
      var otherDim = this.get("otherDimensionSelection");
      if (otherDim) {
        return types.every(function (dim) {
          return otherDim.indexOf(dim) < 0;
        });
      } else {
        return true;
      }
    });
  };

  exports.default = Ember.Component.extend(_volumeFilter.default, {

    showDate: showBasedOnOtherSelection("date"),
    showChannel: showBasedOnOtherSelection("channel_type"),
    showContent: showBasedOnOtherSelection("content_type"),
    showVolume: showBasedOnOtherSelection("target_media_outlets"),
    showTone: showBasedOnOtherSelection("tone_label"),
    showTag: showBasedOnOtherSelection("tag"),
    showLocation: showBasedOnOtherSelection("location"),
    showNeo: showBasedOnOtherSelection("neo"),
    showProfile: showBasedOnOtherSelection("profile"),
    showCampaign: showBasedOnOtherSelection("campaign"),
    showAmplification: showBasedOnOtherSelection(["amplification_label", "interaction_label"]),
    showInteraction: showBasedOnOtherSelection(["amplification_label", "interaction_label"]),

    whitelistTags: Ember.computed('item.whitelistTags.[]', 'item.whitelistTagIds.[]', function () {
      var ids = this.get('item.whitelistTagIds');
      return this.get('item.whitelistTags').filter(function (tag) {
        return ids.indexOf(tag.id) >= 0;
      });
    }),

    whitelistTagIds: Ember.computed.alias('item.labelWhitelistTagIds'),
    whitelistTagIdsDefault: Ember.computed('item.whitelistTagIds.[]', function () {
      return this.get('item.whitelistTagIds').copy();
    }),

    iptcTopicIds: Ember.computed.alias('item.chartIptcTopicIds'),

    filterSetData: Ember.computed(function () {
      var filterSetData = this.get('item.selectedFilterSet.data');
      var final = {};
      Object.keys(filterSetData).forEach(function (eachKey) {
        final[eachKey] = filterSetData[eachKey];
      });
      final['fromDate'] = this.get('item.fromDate');
      final['toDate'] = this.get('item.toDate');
      final['tagIds'] = this.get('item.tagFilterListFilterMenu');
      final['locations'] = this.get('item.locationFilterListFilterMenu');
      final['whitelistTagIds'] = this.get('item.whitelistTagIds');

      return final;
    }),

    actions: {
      locationFiltersChanged: function locationFiltersChanged(filters) {
        this.set("locationFilterList", Object.keys(filters));
      },
      setProfileNameOverride: function setProfileNameOverride(config, name) {
        if (config.get('isMainConfig')) {
          this.get('item.item').set('mainProfileNameOverride', name);
        }
        config.set('nameOverride', name);
      },
      iptcTopicAxesFilterChanged: function iptcTopicAxesFilterChanged(iptcTopicIds) {
        this.set('iptcTopicIds', iptcTopicIds);
      }
    }
  });
});
define('client/components/research/author-info-card', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ["author-info-card"],

    author: null,
    authorStats: null,
    showReason: false,

    nullStatDataMsg: 'N/A',

    actions: {
      sum: function sum(v1, v2) {
        return v1 + v2;
      }
    }
  });
});
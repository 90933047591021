define("client/models/profile-competitor-color", ["exports", "ember-data"], function (exports, _emberData) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var Model = _emberData.default.Model.extend({
    profileId: _emberData.default.attr("string"),
    competitorId: _emberData.default.attr("string"),
    color: _emberData.default.attr("string")
  });

  Model.pathForType = "profile_competitor_colors";
  exports.default = Model;
});
define('client/utils/observer', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });


    var debouncedObserver = function debouncedObserver(func, key, time) {
        return Ember.observer(key, function () {
            Ember.run.debounce(this, func, time);
        });
    };
    var throttledObserver = function throttledObserver(func, key, time) {
        return Ember.observer(key, function () {
            Ember.run.throttle(this, func, time);
        });
    };

    exports.throttledObserver = throttledObserver;
    exports.debouncedObserver = debouncedObserver;
});
define('client/mixins/graph-tooltip', ['exports', 'client/utils/twitter-settings', 'lodash'], function (exports, _twitterSettings, _lodash) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  // TODO(tony) - this should be a component
  var GraphTooltipMixin = Ember.Mixin.create({

    // Set to false to remove the tooltip from rendering
    hasTooltip: true,

    tooltipDuration: 200,

    tooltipOffset: { top: 0, left: 0 },
    pageSelectorWidth: 190,

    countsEndDate: _twitterSettings.default.COUNTS_END_DATE,
    gnipStartDate: _twitterSettings.default.GNIP_START_DATE,

    getTooltip: function getTooltip(type) {
      return d3.select(this.get$Tooltip(type)[0]);
    },
    get$Tooltip: function get$Tooltip(type) {
      var spec = '.graph-tooltip.' + type;
      return this.$(spec);
    },
    setTooltipPosition: function setTooltipPosition(type, position) {
      var tooltip = this.getTooltip(type);

      var _position$map = position.map(function (p) {
        return p + 'px';
      }),
          _position$map2 = _slicedToArray(_position$map, 2),
          left = _position$map2[0],
          top = _position$map2[1];

      tooltip.style({ left: left, top: top });
    },
    hideTooltip: function hideTooltip(type) {
      var tooltip = this.getTooltip(type);
      var obj = {
        opacity: 0
      };

      if (type === 'chart-info') {
        this.set("popInfoTip", false);
        return;
      }

      tooltip.transition().duration(this.get('tooltipDuration')).style(obj);
    },
    showTooltipIfTruncatedText: function showTooltipIfTruncatedText(text) {
      function isTextTruncated() {
        if (text === d3.event.target.textContent) {
          return false;
        }
        // Check join of tspans also
        var tspanText = [];
        d3.select(d3.event.target).selectAll('tspan').each(function () {
          tspanText.push(d3.select(this).text());
        });
        return text !== tspanText.join(' ');
      }
      if (isTextTruncated()) {
        this.showTooltip('truncated-text', text);
      }
    },
    showTooltip: function showTooltip(type, info) {
      var _this = this;

      var style = { opacity: 0.9 };

      if (type === 'chart-info') {
        this.set('infotipTexts', info);
        var update = this.get("updateInfotipText");
        if (update) {
          update(info);
        }
        if (!info.show) {
          return;
        }
        this.set("popInfoTip", true);

        Ember.run.schedule("afterRender", function () {
          // add handlers for the infotip
          Ember.$('.graph-tooltip.chart-info').mouseenter(function () {
            var id = _this.get('infotipLeaveTimeoutId');
            if (id) {
              clearTimeout(id);
              _this.set('infotipLeaveTimeoutId', null);
            }
          }).mouseleave(function () {
            _this.hideTooltip('chart-info');
          });
        });

        return;
      }

      if (type === 'datapoint') {
        this.set('tooltipInfo', {
          title: info.title,
          dataRows: info.rows.map(function (r) {
            if (r.color) {
              r.colorStyle = new Ember.String.htmlSafe('background-color: ' + r.color + ';');
            }
            r.label = _lodash.default.truncate(r.label, { length: 20 });
            return Ember.Object.create(r);
          })
        });
        this.setTooltipPositionFromMouse('datapoint');
      } else if (type === 'truncated-text') {
        this.set('tooltipText', info);
        this.setTooltipPositionFromTarget();
      }
      this.getTooltip(type).transition().duration(this.get('tooltipDuration')).style(style);
    },
    setTooltipPositionFromMouse: function setTooltipPositionFromMouse(type) {
      // We only use this function with datapoint
      var $container = Ember.$('#' + this.get('elementId'));
      var containerRect = $container[0].getBoundingClientRect();

      var _d3$mouse = d3.mouse($container[0]),
          _d3$mouse2 = _slicedToArray(_d3$mouse, 2),
          x = _d3$mouse2[0],
          y = _d3$mouse2[1];

      var scale = this.get('graphScale');
      var minH = this.get('pageSelectorWidth');
      var hOffset = containerRect.left - 320 > minH ? -320 : 20;
      // Really we should use the size of the tooltip, but
      // just using a hacky fixed number for now
      var tooltipWidth = this.get$Tooltip(type).width();
      var offset = null;
      if (type === 'datapoint') {
        offset = {
          left: -tooltipWidth - 22,
          top: -10
        };
        var realLeft = x / scale + offset.left;
        this.get$Tooltip(type).removeClass("arrow-left");
        if (realLeft < 0) {
          offset.left = 14;
          this.get$Tooltip(type).addClass("arrow-left");
        }
      } else if (type === 'chart-info') {
        offset = {
          left: hOffset,
          top: -275
        };
      }

      this.setTooltipPosition(type, [x / scale + offset.left, y / scale + offset.top]);
    },
    setTooltipPositionFromTarget: function setTooltipPositionFromTarget() {
      // This sets the position of the tooltip based on the screen position
      // of the target element and the screen position that the tooltip is
      // relative to. Note that more common is to just have the tooltip
      // absolutely positioned relative to the body and thus able to just
      // set using screen coords, but I wanted to contain the html of the
      // tooltip to this component.
      //
      // Get container screen coords
      var $graphContainer = Ember.$('#' + this.get('elementId'));
      var containerRect = $graphContainer[0].getBoundingClientRect();

      // Get target screen coords
      var target = d3.event.target;
      var matrix = target.getScreenCTM().translate(+target.getAttribute("x"), +target.getAttribute("y"));
      var targetPos = {
        left: window.pageXOffset + matrix.e,
        top: window.pageYOffset + matrix.f
      };
      // Add offset and set
      var offset = this.get('tooltipOffset');
      this.setTooltipPosition('truncated-text', [targetPos.left - containerRect.left + offset.left, targetPos.top - containerRect.top + offset.top]);
    }
  });

  exports.default = GraphTooltipMixin;
});
define('client/components/research/authors-manager', ['exports', 'client/mixins/models/query-consumer'], function (exports, _queryConsumer) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_queryConsumer.default, {
    store: Ember.inject.service(),
    modal: Ember.inject.service(),
    ajax: Ember.inject.service(),
    profile: Ember.computed.alias('profileService.profile'),
    showAddMulti: false,

    init: function init() {
      this._super.apply(this, arguments);
      this.set("editorialTagFilterTask", null);
      this.set("publicationFilterTask", null);
      this.set("locationFilterTask", null);
      this.set("stateFilterTask", null);
    },
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);
      this.notifyPropertyChange("_authors");
    },
    lastNameSortFn: function lastNameSortFn(a, b) {
      var aLastName = (a.get('name') || '').split(' ').pop().toLowerCase();
      var bLastName = (b.get('name') || '').split(' ').pop().toLowerCase();
      if (aLastName > bLastName) {
        return 1;
      } else if (bLastName > aLastName) {
        return -1;
      }
      return 0;
    },


    authorTotalCount: Ember.computed('_authors.totalCount', function () {
      return this.get('_authors.totalCount');
    }),

    authors: Ember.computed('_authors.partialAuthors.name', '_authors.partialAuthors.@each.score', function () {
      if (this.get("filters.sortBy") === "name") {
        return this.get('_authors.partialAuthors').sort(this.lastNameSortFn);
      }
      return this.get('_authors.partialAuthors').sortBy('score').reverse();
    }),

    authorsObserver: Ember.observer("authors.@each.id", "authors.@each.score", "isLoaded", function () {
      var _this = this;

      Ember.run.schedule("afterRender", function () {
        _this.$(".author-cards").scrollTop(0);

        // Check and do it again. if needed
        Ember.run.schedule("afterRender", function () {
          if (_this.$(".author-cards").scrollTop() != 0) {
            _this.authorsObserver();
          }
        });
      });
    }),

    isLoading: Ember.computed.alias("_authors.isPending"),
    isLoaded: Ember.computed.alias("_authors.isFulfilled"),

    _authors: Ember.computed("profile.id", function () {
      var query = this.queryByName("research/authors");
      return query.load();
    }),

    selectedAuthors: Ember.computed(function () {
      return [];
    }),

    availableEditorialTagFilters: Ember.computed('editorialTagFilterTask.value', function () {
      var filters = [];
      var editorialTagFilterHash = this.get('editorialTagFilterTask.value');
      if (editorialTagFilterHash) {
        Object.keys(editorialTagFilterHash).forEach(function (key) {
          filters.pushObject(Ember.Object.create({ name: key, isSelected: false, value: editorialTagFilterHash[key] }));
        });
      }
      return filters;
    }),

    availablePublicationFilters: Ember.computed('publicationFilterTask.value', function () {
      var filters = [];
      var publicationFilterHash = this.get('publicationFilterTask.value');
      if (publicationFilterHash) {
        Object.keys(publicationFilterHash).forEach(function (key) {
          filters.pushObject(Ember.Object.create({ name: key, isSelected: false, value: publicationFilterHash[key] }));
        });
      }
      return filters;
    }),

    availableLocationFilters: Ember.computed('locationFilterTask.value', function () {
      var filters = [];
      var locationFilterHash = this.get('locationFilterTask.value');
      if (locationFilterHash) {
        Object.keys(locationFilterHash).forEach(function (key) {
          filters.pushObject(Ember.Object.create({ name: key, isSelected: false, value: locationFilterHash[key] }));
        });
      }
      return filters;
    }),

    availableStateFilters: Ember.computed('stateFilterTask.value', function () {
      var filters = [];
      var stateFilterHash = this.get('stateFilterTask.value');
      if (stateFilterHash) {
        Object.keys(stateFilterHash).forEach(function (key) {
          filters.pushObject(Ember.Object.create({ name: key, isSelected: false, value: stateFilterHash[key] }));
        });
      }
      return filters;
    }),

    availableCompetitorFilters: Ember.computed('competitorFilterTask.value', function () {
      var filters = [];
      var competitorFilterHash = this.get('competitorFilterTask.value');
      if (competitorFilterHash) {
        Object.keys(competitorFilterHash).forEach(function (key) {
          filters.pushObject(Ember.Object.create({ name: key, isSelected: false, value: competitorFilterHash[key] }));
        });
      }
      return filters;
    }),

    /**
     * Modal to inform the user that they must select author(s).
     */
    noSelectedAuthorsModal: function noSelectedAuthorsModal(count) {
      var prompt = 'No authors selected. Please select at least 1 author.';
      if (count > 0) {
        prompt = 'Not enough authors selected. Please select at least ' + count + ' authors.';
      }
      this.get('modal').open('common/ui-confirm', {
        prompt: prompt,
        showCancel: false,
        ok: 'OK',
        okType: "danger"
      });
    },
    authorNames: function authorNames() {
      return '' + this.get("selectedAuthors").mapBy("name").join();
    },
    authorIds: function authorIds() {
      return this.get("selectedAuthors").mapBy("id");
    },


    actions: {
      selectAuthor: function selectAuthor(author) {
        this.get("selectedAuthors").addObject(author);
      },
      deselectAuthor: function deselectAuthor(author) {
        this.get("selectedAuthors").removeObject(author);
      },
      deselectAllAuthors: function deselectAllAuthors() {
        this.set("selectedAuthors", []);
      },
      selectAllAuthors: function selectAllAuthors() {
        this.set("selectedAuthors", this.get("authors"));
      },
      toggleAddMulti: function toggleAddMulti() {
        var _getProperties = this.getProperties("showAddMulti", "selectedAuthors"),
            showAddMulti = _getProperties.showAddMulti,
            selectedAuthors = _getProperties.selectedAuthors;

        if (showAddMulti) {
          // unselect
          this.set("selectedAuthors", []);
          this.set("showAddMulti", false);
        } else if (selectedAuthors.length === 0) {
          this.noSelectedAuthorsModal(0);
        } else {
          this.set("showAddMulti", true);
        }
      },
      deleteAuthors: function deleteAuthors() {
        var _this2 = this;

        var _getProperties2 = this.getProperties("selectedAuthors"),
            selectedAuthors = _getProperties2.selectedAuthors;

        if (selectedAuthors.length === 0) {
          this.noSelectedAuthorsModal(0);
        } else {
          this.get('modal').open('common/ui-confirm', {
            question: 'Are you sure you want to delete this list of authors?',
            messages: this.authorNames(),
            ok: "Delete Authors",
            okType: "danger"
          }).then(function () {
            _this2.get('ajax').post('/v3/api/edit_authors/delete', {
              data: { author_ids: _this2.authorIds() }
            });
          });
        }
      },
      spliceAuthors: function spliceAuthors() {
        var _this3 = this;

        var _getProperties3 = this.getProperties("selectedAuthors"),
            selectedAuthors = _getProperties3.selectedAuthors;

        if (selectedAuthors.length < 2) {
          this.noSelectedAuthorsModal(2);
        } else {
          this.get('modal').open('common/ui-confirm', {
            question: 'Are you sure you want to splice this list of authors? The first author will be the primary author.',
            messages: this.authorNames(),
            ok: "Splice Authors",
            okType: "danger"
          }).then(function () {
            _this3.get('ajax').post('/v3/api/edit_authors/splice', {
              data: { author_ids: _this3.authorIds() }
            });
          });
        }
      },
      loadEditorialTagFilters: function loadEditorialTagFilters() {
        var query = this.queryByName("research/authors");
        var task = query.loadEditorialTagFilters();
        this.set('editorialTagFilterTask', task);
      },
      loadPublicationFilters: function loadPublicationFilters() {
        var query = this.queryByName("research/authors");
        var task = query.loadPublicationFilters();
        this.set('publicationFilterTask', task);
      },
      loadLocationFilters: function loadLocationFilters() {
        var query = this.queryByName("research/authors");
        var task = query.loadLocationFilters();
        this.set('locationFilterTask', task);
      },
      loadStateFilters: function loadStateFilters() {
        var query = this.queryByName("research/authors");
        var task = query.loadStateFilters();
        this.set('stateFilterTask', task);
      },
      loadCompetitorFilters: function loadCompetitorFilters() {
        var query = this.queryByName("research/authors");
        var task = query.loadCompetitorFilters();
        this.set('competitorFilterTask', task);
      }
    }

  });
});
define('client/components/influentials-table-row', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var InfluentialsTableRowComponent = Ember.Component.extend({

    tagName: 'tr',

    columns: Ember.computed('model', 'headers', function () {
      var model = this.get('model');
      return this.get('headers').map(function (header) {
        var value = model[header.key];
        if (Ember.isEmpty(value)) {
          return null;
        }
        return {
          value: formatter(header.dataType)(value),
          isURL: header.dataType === 'url',
          isHandleWithInfluencers: header.dataType === 'handleWithInfluencers',
          isPlain: !['url', 'handleWithInfluencers'].includes(header.dataType)
        };
      }, this);
    })

  });
  var formatter = function formatter(dataType) {
    var f = function f(d) {
      return d;
    };
    if (dataType === 'rank') {
      f = function f(d) {
        return '#' + d3.format(',')(d);
      };
    } else if (dataType === 'number') {
      f = d3.format(',');
    } else if (dataType === 'percent') {
      f = d3.format('%');
    } else if (dataType === 'url') {
      f = function f(d) {
        return {
          title: d.title,
          url: d.url
        };
      };
    } else if (dataType === 'handleWithInfluencers') {
      f = function f(d) {
        return {
          title: d.title,
          url: d.url,
          influencers: d.influencers
        };
      };
    }
    return f;
  };

  exports.default = InfluentialsTableRowComponent;
});
define("client/components/research/author-publications/publication", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNameBindings: [":publication", "publication.syndicate:syndicate"],

    selectedPublication: function selectedPublication() {},
    click: function click(e) {
      e.stopImmediatePropagation();
      this.get("selectedPublication")(this.get("publication"));
    }
  });
});
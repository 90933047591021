define('client/helpers/format-suffix', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.formatSuffix = formatSuffix;

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
        arr2[i] = arr[i];
      }

      return arr2;
    } else {
      return Array.from(arr);
    }
  }

  function formatSuffix(number) {
    if (Ember.isNone(number) || isNaN(number)) {
      return "-";
    }
    if (number < 1 && number > -1) {
      return Math.round(number * 10) / 10;
    }
    var prefix = d3.formatPrefix(number, 2);
    var symbol = prefix.symbol;
    if (symbol == "G") {
      symbol = "B";
    }
    return d3.round(prefix.scale(number), 1) + symbol;
  }

  exports.default = Ember.Helper.helper(function (params) {
    return formatSuffix.apply(undefined, _toConsumableArray(params));
  });
});
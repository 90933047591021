define('client/components/plan/campaigns/campaign-duration', ['exports', 'client/utils/timezones', 'client/utils/plan/campaign'], function (exports, _timezones, _campaign) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({

    classNames: ['campaign-duration', 'campaign-creation-navigation'],

    prompt: 'When will this campaign be active?',
    timeZones: _campaign.timeZones,
    showTimeZoneSelection: _campaign.showTimeZoneSelection,
    onNext: function onNext() {},
    onPrevious: function onPrevious() {},
    onSelectedCampaignDuration: function onSelectedCampaignDuration() {},

    currentUser: Ember.inject.service(),
    onSelectedTimeZone: function onSelectedTimeZone() {},


    selectedDuration: Ember.computed('durationOptions', {
      get: function get(k) {
        return this.get('durationOptions').get('firstObject').get('value');
      },
      set: function set(k, option) {
        if (option) {
          if (typeof option === 'string') {
            return option;
          } else {
            // Show previously selected when user navigates between steps
            var durationOption = this.get('durationOptions').findBy('value', option.value);
            durationOption.set('startDate', option.startDate);
            durationOption.set('endDate', option.endDate);
            return option.value;
          }
        } else {
          // Default
          return this.get('durationOptions').get('firstObject').get('value');
        }
      }
    }),

    defaultTimezone: Ember.computed('timeZone', function () {
      var _this = this;

      var userMapZones = _timezones.TIMEZONE_MAP.filter(function (zone) {
        return _this.get("timeZone") === zone.rails_zone_name;
      });
      if (Ember.isPresent(this.get("timeZone"))) {
        return userMapZones[0].rails_zone_name;
      }
      return _timezones.TIMEZONE_MAP.filter(function (zone) {
        return _this.get('currentUser.timezone') === zone.iana_zone_name;
      })[0].rails_zone_name;
    }),

    durationOptions: Ember.computed('profileStartDate', function () {
      var profileStartDate = moment(this.get('profileStartDate')).toDate();
      var durationOptions = Ember.A([Ember.Object.create({ value: 'all', startDate: profileStartDate, endDate: null }), Ember.Object.create({ value: 'ongoing', startDate: new Date(), endDate: null }), Ember.Object.create({ value: 'fromTo', startDate: new Date(), endDate: new Date() })]);

      return durationOptions;
    }),

    isDateRangeError: Ember.computed('selectedDuration', 'durationOptions.@each.{startDate,endDate}', function () {
      var duration = this.get('selectedDuration');
      var isDateRangeError = false;
      if (duration === 'fromTo') {
        var startDate = this.get('durationOptions').findBy('value', duration).startDate;
        var endDate = this.get('durationOptions').findBy('value', duration).endDate;
        isDateRangeError = moment(endDate).isBefore(moment(startDate), 'day');
      }
      return isDateRangeError;
    }),

    setDuration: function setDuration() {
      var option = this.get('selectedDuration');
      var durationOption = this.get('durationOptions').findBy('value', option);
      this.onSelectedCampaignDuration(durationOption);
    },


    actions: {
      onSelectedStartDate: function onSelectedStartDate(date) {
        var duration = this.get('selectedDuration');
        var option = this.get('durationOptions').findBy('value', duration);
        option.set('startDate', date);
      },
      onSelectedEndDate: function onSelectedEndDate(date) {
        var duration = this.get('selectedDuration');
        var option = this.get('durationOptions').findBy('value', duration);
        option.set('endDate', date);
      },
      onNext: function onNext() {
        var _this2 = this;

        this.setDuration();
        this.onNext();
        var userMapZones = _timezones.TIMEZONE_MAP.filter(function (zone) {
          return _this2.get("timeZone") === zone.rails_zone_name;
        });
        if (Ember.isEmpty(this.get("timeZone"))) {
          var timeZone = _timezones.TIMEZONE_MAP.filter(function (zone) {
            return _this2.get('currentUser.timezone') === zone.iana_zone_name;
          })[0].rails_zone_name;
          this.onSelectedTimeZone(timeZone);
        }
      },
      onPrevious: function onPrevious() {
        var _this3 = this;

        this.setDuration();
        this.onPrevious();
        if (Ember.isEmpty(this.get("timeZone"))) {
          var timeZone = _timezones.TIMEZONE_MAP.filter(function (zone) {
            return _this3.get('currentUser.timezone') === zone.iana_zone_name;
          })[0].rails_zone_name;
          this.onSelectedTimeZone(timeZone);
        }
      }
    }
  });
});
define('client/components/article-list/tag-modal', ['exports', 'client/models/tag'], function (exports, _tag) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ["ui-confirm", 'tag-modal'],
    store: Ember.inject.service(),
    allTags: Ember.computed.alias("profile.alphabeticalTags"),

    isOpen: false,
    includeSyndicateChildrenFlag: false,

    total: 0,

    newTagDisabled: Ember.computed('newTagName', function () {
      return Ember.isEmpty(this.get('newTagName'));
    }),

    applyTagsDisabled: Ember.computed('selected', function () {
      return this.get('selected') == null || this.get('selected').length == 0;
    }),

    newTagName: null,

    error: null,

    visibility: _tag.TAG_VISIBILITY.public,

    selected: null,

    createTagFlag: false,

    tagOptions: Ember.computed('allTags.[]', function () {
      return this.get('allTags');
    }),

    _createTag: function _createTag() {
      var duplicate = this.get('allTags').isAny('name', this.get('newTagName').trim());
      var newTag = null;
      if (duplicate) {
        this.set('error', 'A tag with this name already exists. Please use another name.');
      } else {
        newTag = this.get('store').createRecord('tag', {
          profile: this.get('profile'),
          name: this.get('newTagName'),
          visibility: this.get('visibility')
        });
      }

      return newTag;
    },
    applyTags: function applyTags() {},


    actions: {
      onApply: function onApply() {
        this.set('isOpen', false);
        this.applyTags(this.get('selected'), this.get('includeSyndicateChildrenFlag'));
      },
      newTagCreate: function newTagCreate() {
        var _this = this;

        var newTag = this._createTag();
        if (newTag) {
          newTag.save().then(function (tag) {
            if (_this.get('selected') == null) {
              _this.set('selected', []);
            }
            _this.selected.push(tag);
            _this.set('createTagFlag', false);
          });
        }
      },
      newTagCancel: function newTagCancel() {
        this.set('createTagFlag', false);
      },
      toggleCreateNewFlag: function toggleCreateNewFlag() {
        this.set('createTagFlag', !this.get('createTagFlag'));
        this.set('newTagName', null);
        this.set('error', null);
      },
      onChecked: function onChecked(checked) {
        this.set('visibility', checked ? _tag.TAG_VISIBILITY.public : _tag.TAG_VISIBILITY.private);
      },
      onSyndicateChecked: function onSyndicateChecked(checked) {
        this.set('includeSyndicateChildrenFlag', checked);
      },
      cancel: function cancel() {
        this.set('isOpen', false);
      },
      onSelectedOption: function onSelectedOption(options) {
        this.set('selected', options);
      }
    }
  });
});
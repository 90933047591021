define('client/mixins/models/full-prompt', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    fullPrompt: Ember.computed("prompt", "profileService.profile", function () {
      var profile = this.get("profileService.profile"),
          prompt = this.get("prompt"),
          values = profile.getProperties("nameReach", "nameRevenue", "nameFormulaicRevenue", "nameInteraction", "nameTotalInteraction", "nameUseInteractionCount", "nameInteractionCount", "nameInteractionPercentage", "nameCustomer", "nameSentiment", "nameReadership");
      return Object.keys(values).reduce(function (string, key) {
        return string.replace('{{' + key + '}}', values[key]);
      }, prompt);
    })

  });
});
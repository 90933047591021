define('client/components/unique-select', ['exports', 'client/components/search-select'], function (exports, _searchSelect) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var UniqueSelect = _searchSelect.default.extend({
    classNameBindings: [':unique-select'],

    content: null,
    searchTermDidChange: null,

    filteredContent: Ember.computed('content', 'labelPath', 'searchTerm', function () {
      var path = this.get('labelPath'),
          term = $.trim(this.get('searchTerm')),
          content = this.get('content');

      return content && content.filter(function (c) {
        if (path) {
          return c.get(path) === term;
        } else {
          return c === term;
        }
      });
    }),

    actions: {
      enter: function enter() {
        if (this.get('filteredContent.length') === 0) {
          this.get('addFromSearch')(this.get('searchTerm').trim());
          this.set('searchTerm', '');
        }
      }
    }
  });

  exports.default = UniqueSelect;
});
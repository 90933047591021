define('client/components/plan/media/add-websites', ['exports', 'ember-data', 'client/models/profile-link-filter', 'client/utils/plan/validations'], function (exports, _emberData, _profileLinkFilter, _validations) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    store: Ember.inject.service(),
    profileLinks: null,

    websites: Ember.computed("profileLinks.@each{type,pattern}", function () {
      return this.get("profileLinks").filterBy("type", _profileLinkFilter.ProfileLinkFilterTypes.WHITELIST).mapBy("pattern").join('\n');
    }),

    updateWebsites: function updateWebsites(websitesStr) {
      var profileLinks = this.get("profileLinks").filterBy("type", _profileLinkFilter.ProfileLinkFilterTypes.WHITELIST),
          _getProperties = this.getProperties("store", "list"),
          store = _getProperties.store,
          list = _getProperties.list,
          patterns = (websitesStr || "").split("\n").uniq();

      // find or create links
      var newProfileLinks = patterns.map(function (pattern) {
        if (Ember.isEmpty(pattern)) {
          return null;
        }

        var link = profileLinks.findBy("pattern", pattern);
        if (!link) {
          // create the link
          return store.createRecord("profile-link-filter", {
            profile: list.get("profile"),
            whitelistTag: list,
            pattern: pattern,
            type: _profileLinkFilter.ProfileLinkFilterTypes.WHITELIST
          }).save();
        }
      }).compact();

      // remove the old links
      var removedLinks = profileLinks.filter(function (link) {
        return !patterns.find(function (pattern) {
          return link.get("pattern") === pattern;
        });
      }).map(function (linkToRemove) {
        return linkToRemove.destroyRecord();
      });

      return Ember.RSVP.all(newProfileLinks.concat(removedLinks));
    },
    onBack: function onBack() {},
    onNext: function onNext() {},


    actions: {
      save: function save() {
        var _this = this;

        var websites = this.get("websites"),
            profileLinks = this.get("profileLinks"),
            errors = (0, _validations.validateTML)(websites);

        if (errors && errors.length) {
          this.set("errors", errors.join(", "));
          return;
        }

        var promise = this.updateWebsites(websites).then(function () {
          // reload links
          return profileLinks.update();
        }, function (errors) {
          console.log(errors);
        }).then(function () {
          // move on to the next step
          _this.get("onNext")();
        });
        this.set("promise", _emberData.default.PromiseObject.create({ promise: promise }));
      }
    }
  });
});
define('client/initializers/inject-app-state', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.initialize = initialize;
  function initialize(application) {
    application.inject('route', 'appState', 'service:app-state');
    application.inject('controller', 'appState', 'service:app-state');
  }

  exports.default = {
    name: 'inject-app-state',
    initialize: initialize
  };
});
define('client/components/plan/neo/message-card', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ["message-card"],
    onDelete: function onDelete() {},
    onEdit: function onEdit() {},
    viewInAnalyze: function viewInAnalyze() {},

    planningData: null,

    visitorTooltip: Ember.computed.alias('profile.nameCustomer'),
    interactionTooltip: Ember.computed.alias('profile.nameTotalInteraction'),

    init: function init() {
      this._super.apply(this, arguments);
      this.set('planningData', this.get('planningData') || {});
    },


    actions: {
      onDelete: function onDelete(id) {
        this.get('onDelete')(id);
      }
    }
  });
});
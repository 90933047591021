define('client/components/expanded-article-iptc-topics', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });


    var ExpandedArticleIptcChildTopicsComponent = Ember.Component.extend({
        iptcTopicLabelsWithLevel: Ember.computed("iptcTopics", function () {
            return this.get("iptcTopics").map(function (innerArray) {
                return innerArray.map(function (item, idx) {
                    return 'Level ' + (idx + 1) + ' ' + item;
                });
            });
        })
    });

    exports.default = ExpandedArticleIptcChildTopicsComponent;
});
define('client/components/reports/header-subheader', ['exports', 'client/components/reports/rich-text-header-subheader', 'client/utils/rich-text-fonts', 'bigtext'], function (exports, _richTextHeaderSubheader, _richTextFonts, _bigtext) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _richTextHeaderSubheader.default.extend({
    layoutName: 'components/reports/rich-text',
    header: Ember.computed.alias("item.content.header"),
    subheader: Ember.computed.alias("item.content.subheader"),
    subsubheader: Ember.computed.alias("item.content.subsubheader"),
    text: Ember.computed.alias('item.content.text'),
    headerMax: 50,
    headerMin: 16,
    boldHeader: true,

    textOnlyHeader: Ember.computed('header', function () {
      return Ember.$(Ember.$.parseHTML(this.get('header'))).text();
    }),
    textOnlySubheader: Ember.computed('subheader', function () {
      return Ember.$(Ember.$.parseHTML(this.get('subheader'))).text();
    }),
    textOnlySubsubheader: Ember.computed('subsubheader', function () {
      return Ember.$(Ember.$.parseHTML(this.get('subsubheader'))).text();
    }),

    convertLegacyTextbox: function convertLegacyTextbox() {
      var editor = this.get('editor');
      var shouldSave = false;
      if (this.get('header')) {
        var header = this.get('textOnlyHeader');
        this.$().find('.legacy-header').bigtext({
          maxfontsize: 50,
          minfontsize: 9
        });
        var fontSize = this.$().find('.header-container').css('font-size');
        fontSize = parseFloat(fontSize) - 1 + 'px';
        this.set('header', null);
        this.$().find('.rich-text-editor').select();
        editor.setFontSize(fontSize).bold().setFontFace(_richTextFonts.default.HELVETICA.family);
        editor.insertHTML(header);
        editor.setFontSize('12px');
        editor.moveCursorToEnd();
        editor.insertHTML('<hr />');
        shouldSave = true;
      }
      if (this.get('subheader')) {
        var subheader = this.get('textOnlySubheader');
        this.$().find('.legacy-subheader').bigtext({
          maxfontsize: 24,
          minfontsize: 14
        });
        var _fontSize = this.$().find('.subheader-container').css('font-size');
        _fontSize = parseFloat(_fontSize) - 1 + 'px';
        editor.moveCursorToEnd();
        this.set('subheader', null);
        this.$().find('.rich-text-editor').select();
        editor.moveCursorToEnd();
        editor.setFontSize(_fontSize).bold().setFontFace(_richTextFonts.default.HELVETICA.family);
        editor.insertHTML(subheader);
        editor.setFontSize('9px');
        editor.insertHTML('<div class="hacky-break" style="display: none;"> </div>');
        editor.moveCursorToEnd();
        shouldSave = true;
      }
      if (this.get('subsubheader')) {
        var subsubheader = this.get('textOnlySubsubheader');
        this.$().find('.legacy-subsubheader').bigtext({
          maxfontsize: 24,
          minfontsize: 14
        });
        var _fontSize2 = this.$().find('.subsubheader-container').css('font-size');
        _fontSize2 = parseFloat(_fontSize2) - 1 + 'px';
        editor.moveCursorToEnd();
        this.set('subsubheader', null);
        this.$().find('.rich-text-editor').select();
        editor.moveCursorToEnd();
        editor.setFontSize(_fontSize2).bold().setFontFace(_richTextFonts.default.HELVETICA.family);
        editor.insertHTML(subsubheader);
        shouldSave = true;
      }
      return shouldSave;
    }

  });
});
define('client/controllers/promote', ['exports', 'client/utils/date-format', 'client/mixins/profile-service-mixin'], function (exports, _dateFormat2, _profileServiceMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend(_profileServiceMixin.default, {
    profileService: Ember.inject.service('profile'),
    profile: Ember.computed.alias('profileService.profile'),

    currentPage: null,

    showErrorMessage: Ember.computed('currentPage', function () {
      //Only show message if not part of the marketing message pages
      return this.currentPage !== "info";
    })

  });
});
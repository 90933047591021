define('client/components/graph/profile-options', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ["form-group", "row"],
    filterSet: null,
    selectionSet: null,
    competitors: null,

    sortKey: null,
    sortedCompetitors: Ember.computed.sort("competitors", "sortKey"),
    sortedSelection: Ember.computed.sort("selectionSet.otherProfiles", "sortKey"),

    update: function update() {},


    canAddAll: Ember.computed("sortedSelection.length", "sortedCompetitors.length", function () {
      return this.get("sortedSelection.length") !== this.get("sortedCompetitors.length");
    }),

    canRemoveAll: Ember.computed.gt("sortedSelection.length", 0),

    init: function init() {
      this._super.apply(this, arguments);
      this.set('sortKey', this.get('sortKey') || ["name:asc"]);
    },


    actions: {
      addAll: function addAll() {
        if (this.get("canAddAll")) {
          this.get("update")(this.get("competitors"));
        }
      },
      removeAll: function removeAll() {
        if (this.get("canRemoveAll")) {
          this.get("update")([]);
        }
      }
    }
  });
});
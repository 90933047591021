define('client/components/reports/report-text', ['exports', 'client/components/reports/rich-text', 'client/utils/rich-text-fonts'], function (exports, _richText, _richTextFonts) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _richText.default.extend({
    layoutName: 'components/reports/rich-text',
    header: Ember.computed.alias("item.header"),
    headerMax: 20,
    headerMin: 20,
    boldHeader: false,

    convertLegacyTextbox: function convertLegacyTextbox() {
      if (this.get('header')) {
        var header = this.get('header');
        var editor = this.get('editor');
        this.set('header', null);
        this.$().find('.rich-text-editor').select();
        editor.setFontSize('20px').setFontFace(_richTextFonts.default.HELVETICA.family);
        editor.insertHTML(header);
        editor.moveCursorToEnd();
        editor.setFontSize('15px');
        editor.insertHTML('<div class="hacky-break" style="display: none;"> </div>');
        editor.moveCursorToEnd();
        return true;
      }
      return false;
    }

  });
});
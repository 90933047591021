define("client/components/reports/create-box", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: "li",
    classNameBindings: [":report-box", ":report-create", "reportTemplate:report-create-template"],
    reportTemplate: null,
    create: function create() {},
    click: function click() {
      if (this.get("reportTemplate")) {
        return;
      }
      this.create();
    }
  });
});
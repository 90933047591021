define('client/models/profile-pov-metrics', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var NEUTRAL_THRESHOLD = 0.001;
  var DIGITS = 3;

  var roundValue = function roundValue(value) {
    if (!value) {
      return value;
    }

    // keep 3 digits
    var fixed = +value.toFixed(DIGITS);

    if (isNaN(fixed)) {
      return null;
    }

    // check if within threshold
    if (Math.abs(fixed) <= NEUTRAL_THRESHOLD) {
      return 0;
    }

    return fixed;
  };

  var calculatePercentage = function calculatePercentage(num, total) {
    if (!Ember.isPresent(num) || total === 0) {
      return null;
    }
    return roundValue(num / total * 100);
  };
  exports.default = Ember.Object.extend({
    coverage: null,
    profile: null,
    profileId: Ember.computed.alias('profile_id'),

    reputation: Ember.computed.alias('pov_base'),
    relevance: Ember.computed.alias('pov_rel'),
    reception: Ember.computed.alias('pov_rel_amp'),
    povSentiment: Ember.computed.alias('pov_rel_amp_sent'),
    positiveCoverage: Ember.computed.alias('positive_coverage'),

    previousReception: Ember.computed.alias('previous_pov_rel_amp'),
    currentReception: Ember.computed.alias('current_pov_rel_amp'),

    previousPovSentiment: Ember.computed.alias('previous_pov_rel_amp_sent'),
    currentPovSentiment: Ember.computed.alias('current_pov_rel_amp_sent'),

    previousCoverage: Ember.computed.alias('previous_coverage'),
    currentCoverage: Ember.computed.alias('current_coverage'),

    shareOfVoice: Ember.computed('coverage', function () {
      return calculatePercentage(this.get('coverage'), this.get('totals.coverage'));
    }),

    //percents

    reputationPercent: Ember.computed('reputation', function () {
      return calculatePercentage(this.get('reputation'), this.get('totals.reputation'));
    }),

    relevancePercent: Ember.computed('relevance', function () {
      return calculatePercentage(this.get('relevance'), this.get('totals.relevance'));
    }),

    receptionPercent: Ember.computed('reception', function () {
      return calculatePercentage(this.get('reception'), this.get('totals.reception'));
    }),

    povSentimentPercent: Ember.computed('pov_rel_amp_sent', function () {
      return calculatePercentage(this.get('povSentiment'), this.get('totals.povSentiment'));
    }),

    positiveCoveragePercent: Ember.computed('positiveCoverage', function () {
      return calculatePercentage(this.get('positiveCoverage'), this.get('coverage'));
    }),

    previousReceptionPercent: Ember.computed('previousReception', 'totals.previousReception', function () {
      return calculatePercentage(this.get('previousReception'), this.get('totals.previousReception'));
    }),

    currentReceptionPercent: Ember.computed('currentReception', 'totals.currentReception', function () {
      return calculatePercentage(this.get('currentReception'), this.get('totals.currentReception'));
    }),

    previousCoveragePercent: Ember.computed('previousCoverage', 'totals.previousCoverage', function () {
      return calculatePercentage(this.get('previousCoverage'), this.get('totals.previousCoverage'));
    }),

    currentCoveragePercent: Ember.computed('currentCoverage', 'totals.currentCoverage', function () {
      return calculatePercentage(this.get('currentCoverage'), this.get('totals.currentCoverage'));
    }),

    receptionTrend: Ember.computed('previousReceptionPercent', 'currentReceptionPercent', function () {
      var previous = this.get('previousReceptionPercent');
      var current = this.get('currentReceptionPercent');
      if (Ember.isPresent(previous) && Ember.isPresent(current)) {
        return current - previous;
      }
      return 0;
    }),

    shareOfVoiceTrend: Ember.computed('previousCoveragePercent', 'currentCoveragePercent', function () {
      var previous = this.get('previousCoveragePercent');
      var current = this.get('currentCoveragePercent');
      if (Ember.isPresent(previous) && Ember.isPresent(current)) {
        return current - previous;
      }
      return 0;
    }),

    previousPovSentimentPercent: Ember.computed('previousPovSentiment', 'totals.previousPovSentiment', function () {
      return calculatePercentage(this.get('previousPovSentiment'), this.get('totals.previousPovSentiment'));
    }),

    currentPovSentimentPercent: Ember.computed('currentPovSentiment', 'totals.currentPovSentiment', function () {
      return calculatePercentage(this.get('currentPovSentiment'), this.get('totals.currentPovSentiment'));
    }),

    povSentimentTrend: Ember.computed('previousPovSentimentPercent', 'currentPovSentimentPercent', function () {
      var previous = this.get('previousPovSentimentPercent');
      var current = this.get('currentPovSentimentPercent');
      if (Ember.isPresent(previous) && Ember.isPresent(current)) {
        return current - previous;
      }
      return 0;
    }),

    //deltas

    reputationDelta: Ember.computed('reputationPercent', 'shareOfVoice', function () {
      return this.get('reputationPercent') - this.get('shareOfVoice');
    }),

    relevanceDelta: Ember.computed('relevancePercent', 'reputationPercent', function () {
      return this.get('relevancePercent') - this.get('reputationPercent');
    }),

    receptionDelta: Ember.computed('receptionPercent', 'relevancePercent', function () {
      return this.get('receptionPercent') - this.get('relevancePercent');
    })

  });
});
define('client/components/panel-group', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNameBindings: [":panel-group"],

    activePanelItem: null,

    actions: {
      activatePanelItem: function activatePanelItem(panel) {
        this.set('activePanelItem', panel);
      }
    }
  });
});
define('client/controllers/plan/neo/soundbytes/edit', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({

    actions: {
      cancel: function cancel() {
        var bucketId = this.get('profileService.lastBucketId');

        if (bucketId) {
          this.transitionToRoute("plan.neo.buckets.show", bucketId);
        } else {
          this.transitionToRoute("plan.neo");
        }
      }
    }
  });
});
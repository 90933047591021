define('client/components/research/research-page-modal', ['exports', 'client/mixins/resize'], function (exports, _resize) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_resize.default, {
    classNames: ['research-page-modal'],
    onSelected: function onSelected() {},
    didInsertElement: function didInsertElement() {
      var _this = this;

      this.$(".research-page-modal-content").on("scroll", function () {
        Ember.run.once(_this, "spyScroll");
      });
      this.spyScroll();
      this.handleResize();
    },


    onResize: Ember.on("resize", function (promise) {
      this.notifyPropertyChange("bodyStyle");
    }),

    willRemoveElement: function willRemoveElement() {
      this.$(".research-page-modal-content").off("scroll");
    },


    bodyStyle: Ember.computed(function () {
      if (this._state !== "inDOM") {
        return;
      }
      var wHeight = this.$(".research-page-modal-content").height(),
          cHeight = this.$(".research-page-modal-body > .d-flex > div[id]:last").height();
      return Ember.String.htmlSafe('padding-bottom:' + (wHeight - cHeight) + 'px');
    }),

    spyScroll: function spyScroll() {
      var menuItems = this.$(".author-modal-header-anchors a"),
          scrollItems = menuItems.map(function () {
        var item = Ember.$(Ember.$(this).attr("href"));
        return item.length ? item : null;
      });
      if (scrollItems.length === 0) {
        return;
      }

      this.notifyPropertyChange("bodyStyle");

      // Get id of current scroll item
      var cur = scrollItems.map(function (i) {
        if (Ember.$(this).offset().top <= 140) {
          return this;
        }
      });
      // Get the id of the current element
      cur = cur[cur.length - 1];

      var id = cur && cur.length ? cur[0].id : scrollItems[0][0].id;

      if (this.lastId !== id) {
        this.lastId = id;
        // Set/remove active class
        menuItems.removeClass("active");
        menuItems.filter("[href='#" + id + "']").addClass("active");
      }
    },


    actions: {
      selected: function selected() {
        this.get('onSelected')();
      }
    }

  });
});
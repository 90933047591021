define("client/models/keyword", ["exports", "ember-data"], function (exports, _emberData) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var Keyword = _emberData.default.Model.extend({
    name: _emberData.default.attr("string"),
    // TODO(Tony): It would be great if this was actually hasMany Profile
    profile_ids: _emberData.default.attr("array")
  });
  Keyword.pathForType = "keywords";

  exports.default = Keyword;
});
define("client/components/common/ui-big-number", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ["ui-big-number"],
    number: null,
    title: null
  });
});
define('client/components/research/filter-publishers', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    onApplyPublisher: function onApplyPublisher() {},

    disabled: false,

    actions: {
      onApplyPublisher: function onApplyPublisher() {
        this.onApplyPublisher.apply(this, arguments);
      }
    }
  });
});
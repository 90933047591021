define('client/components/dashboard/dashboard-card/info', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNameBindings: [":card-info-hover", "showInfo:show"],

    card: null,
    showInfo: Ember.computed.alias("card.showInfo")
  });
});
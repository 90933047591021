define('client/models/tag', ['exports', 'ember-data', 'ember-cp-validations'], function (exports, _emberData, _emberCpValidations) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.TAG_VISIBILITY = undefined;


  var Validations = (0, _emberCpValidations.buildValidations)({
    name: (0, _emberCpValidations.validator)('presence', true)
  });

  var TAG_VISIBILITY = {
    private: 'private',
    public: 'public'
  };

  var Tag = _emberData.default.Model.extend(Validations, {
    ajax: Ember.inject.service(),

    name: _emberData.default.attr("string"),
    //visibility : DS.attr("number", {defaultValue: 0}), // 0 = private, 1 = public
    visibility: _emberData.default.attr("string", { defaultValue: TAG_VISIBILITY.private }),
    user_id: _emberData.default.attr("number"),
    user_name: _emberData.default.attr("string"),
    profile: _emberData.default.belongsTo("profile", { inverse: "tags" }),
    updated_at: _emberData.default.attr("date"),

    untagSelection: function untagSelection(articles) {
      var ignore_syndicate_children = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;

      var that = this;
      return this.get('ajax').post('/v2/api/tags/' + this.get("id") + '/untag', {
        data: { per_ids: articles.mapBy("id"), ignore_syndicate_children: ignore_syndicate_children }
      }).then(function () {
        // reload each articles
        articles.forEach(function (a) {
          a = a.get("content") || a;
          a.notifyPropertyChange("fullArticle");
        });
      });
    },

    tagSelection: function tagSelection(articles) {
      var ignore_syndicate_children = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;

      var that = this;
      return this.get('ajax').post('/v2/api/tags/' + this.get("id") + '/tag', {
        data: { per_ids: articles.mapBy("id"), ignore_syndicate_children: ignore_syndicate_children }
      }).then(function () {
        // reload each articles
        articles.forEach(function (article) {
          article.get("tags").addObject(that);
        });
      });
    }
  });

  Tag.pathForType = "tags";
  exports.TAG_VISIBILITY = TAG_VISIBILITY;
  exports.default = Tag;
});
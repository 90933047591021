define('client/components/common/ui-card', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNameBindings: [":ui-card", ":card", "draggable:draggable", "draggableItem.moved:moved", "dragging"],
    attributeBindings: ['draggable'],
    draggable: false,
    dragDidStart: function dragDidStart() {},
    dragDidEnd: function dragDidEnd() {},


    browser: Ember.inject.service('browser'),

    dragStart: function dragStart(event) {
      if (!this.get('draggable')) {
        return false;
      }
      var data = {
        id: this.get('draggableItem.id'),
        modelName: this.get('draggableItem').constructor.modelName
      };
      Ember.run.later(this, function () {
        //slightly delay as to not intefere with the dragging
        this.dragDidStart();
      }, 50);
      this.set("dragging", true);

      // ghost image
      var clone = this.$().clone();
      clone.addClass("ghost");
      clone.css({
        height: this.$().height(),
        width: this.$().width()
      });
      Ember.$("body").append(clone);
      if (!this.get('browser.isIE')) {
        //setDragImage not available in IE11
        event.dataTransfer.setDragImage(clone[0], event.originalEvent.offsetX, event.originalEvent.offsetY);
      }
      Ember.run.later(this, function () {
        clone.remove();
      }, 50);
      return event.dataTransfer.setData(this.get('browser.dragDataType'), JSON.stringify(data));
    },
    dragEnd: function dragEnd() {
      if (this.get('draggable')) {
        this.set("dragging", false);
        this.$("vertical-collection").css({
          height: null
        });
        this.dragDidEnd();
      }
    }
  });
});
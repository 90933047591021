define('client/components/reports/article-sort-dropdown', ['exports', 'client/components/reports/report-select', 'client/mixins/article-sort-dropdown'], function (exports, _reportSelect, _articleSortDropdown) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var ReportArticleSortDropdown = _reportSelect.default.extend(_articleSortDropdown.default, {
    layoutName: 'components/reports/report-select',
    profile: null,
    fromDate: null,
    sort: Ember.computed.alias("selection"),
    content: Ember.computed.alias('sorts')
  });

  exports.default = ReportArticleSortDropdown;
});
define('client/utils/reports/page', ['exports', 'client/utils/reports/item'], function (exports, _item) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var Page = Ember.Object.extend(Ember.Evented, {
    report: null,

    page: null,
    type: null,

    items: null,

    renderedHtml: Ember.computed({
      get: function get() {},
      set: function set(k, value) {
        if (value) {
          // Replace empty styles with empty
          value = value.replace(/<style>[\s\S]*?<\/style>/g, "");

          // Replace ember ids with empty
          value = value.replace(/id="ember\d*"/g, "");

          // Replace ember actions with empty
          value = value.replace(/data-ember-action="\d*"/g, "");
          value = value.replace(/data-ember-action-\d*="\d*"/g, "");

          // Replace ember databinds with empty
          value = value.replace(/data-bindattr-\d*="\d*"/g, "");

          // Replace ember specific clip selected ids with nil
          value = value.replace(/clip-selected-ember\d*/g, "");

          // Replace loading class with empty
          value = value.replace(/report-base_loading/g, "");

          // Replace dropdown menus with empty
          value = value.replace(/<ul class="dropdown-menu">.*<\/ul>/g, "");

          // Replace multiple spaces (including cover tabs, newlines, etc) with one space
          value = value.replace(/\s\s+/g, ' ');

          return value;
        }
      }
    }),

    renderedImage: null,

    html: Ember.computed("renderedHtml", function () {
      var html = this.get("renderedHtml") || "";
      return html.htmlSafe();
    }),

    touch: function touch() {
      this.get("report").touch();
    },
    rendered: function rendered() {
      // take a snapshot
      this.trigger("render-html", this);
    },
    serialize: function serialize() {
      return {
        page: this.get("page"),
        type: this.get("type"),
        html: this.get("renderedHtml"),
        image: this.get("renderedImage"),
        items: this.get("items").map(function (i) {
          return i.serialize();
        })
      };
    }
  });

  Page.reopenClass({
    deserialize: function deserialize(data) {
      var page = Page.create({
        page: data.page,
        type: data.type,
        renderedHtml: data.html,
        renderedImage: data.image,
        items: data.items.map(function (i) {
          return _item.default.deserialize(i);
        })
      });

      page.get("items").forEach(function (i) {
        i.set("page", page);
      });

      return page;
    }
  });

  exports.default = Page;
});
define('client/components/simple-widget', ['exports', 'client/utils/social-settings'], function (exports, _socialSettings) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var SimpleWidgetComponent = Ember.Component.extend({
    classNameBindings: [":db-widget", ":db-widget-v3", "displayOnly:db-widget-display", "expanded:db-widget_expanded", "validValue::db-widget_invalid"],

    content: null,
    icon: "",
    value: 0,

    format: Ember.computed(function () {
      return function (v) {
        var prefix = d3.formatPrefix(v, 2);
        if (prefix.symbol === 'G') {
          prefix.symbol = 'B';
        }
        return d3.round(prefix.scale(v), 1) + prefix.symbol;
      };
    }),

    validValue: Ember.computed("value", function () {
      var value = this.get("value");
      return typeof value === "number" && !isNaN(value);
    }),

    formattedContent: Ember.computed("value", "format", function () {
      return this.get("format")(this.get("value"));
    }),

    linkedinAvailable: Ember.computed("fromDate", function () {
      // cut off on february 15 2018
      return this.get("fromDate") <= _socialSettings.default.LINKEDIN_END;
    }),

    gplusAvailable: Ember.computed("fromDate", function () {
      // cut off on september 1st 2017
      return this.get("fromDate") <= _socialSettings.default.GOOGLEPLUS_END;
    }),

    pinterestAvailable: Ember.computed("toDate", function () {
      return this.get("toDate") >= _socialSettings.default.PINTEREST_START;
    })
  });

  SimpleWidgetComponent.reopenClass({
    configurable: false
  });

  exports.default = SimpleWidgetComponent;
});
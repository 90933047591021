define('client/components/reports/edit-base', ['exports', 'client/mixins/volume-filter', 'client/models/report-items/profile-config'], function (exports, _volumeFilter, _profileConfig) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_volumeFilter.default, {
    profileService: Ember.inject.service('profile'),
    item: null,
    profile: Ember.computed.alias('item.profile'),

    influential: Ember.computed.alias('item.influential'),
    uninfluential: Ember.computed.alias('item.uninfluential'),
    whitelistTagIds: Ember.computed.alias('item.whitelistTagIds'),
    whitelistTags: Ember.computed.alias('item.whitelistTags'),

    influentialDefault: Ember.computed.alias('item.influential'),
    uninfluentialDefault: Ember.computed.alias('item.uninfluential'),
    whitelistTagIdsDefault: Ember.computed.oneWay('item.whitelistTagIds'),

    hasComprehensiveCoverage: Ember.computed('profile', 'item.item.otherProfiles.[]', function () {
      //First check if current profile has comprehesive, then check competitors
      if (!this.get('profile.showStoryLevels')) {
        return false;
      }
      return Ember.isEmpty(this.get('item.item.otherProfiles').filterBy('profile.hideStoryLevels'));
    }),

    actions: {
      display: function display() {
        this.get("item").send("display");
      },
      saveItem: function saveItem() {
        this.get("item").send("saveItem");
      },
      appendColor: function appendColor() {
        this.get("item").send("appendColor");
      },
      selectColor: function selectColor(index) {
        this.get("item").send("selectColor", index);
      },
      deleteColor: function deleteColor(index) {
        this.get("item").send("deleteColor", index);
      },
      locationFiltersChanged: function locationFiltersChanged(filters) {
        this.get("item").send("locationFiltersChanged", filters);
      },
      tagFilterListFilterMenuChanged: function tagFilterListFilterMenuChanged(tagId) {
        this.get("item.tagFilterList").addObject(tagId);
      },
      profileAdded: function profileAdded() {
        var _this = this;

        var otherProfiles = this.get('item.item.otherProfiles');

        var uniqueCompetitors = this.get('item.item.mainProfile.competitors').filter(function (competitor) {
          return !_this.get("item.item.otherProfiles").findBy('profileId', competitor.get('id'));
        });

        var nextProfile = this.get('item.item.profile');

        if (!Ember.isEmpty(uniqueCompetitors)) {
          nextProfile = uniqueCompetitors.get('firstObject');
        }

        var newProfile = _profileConfig.default.create({
          store: Ember.getOwner(this).lookup("service:store"),
          profileId: nextProfile.get('id'),
          item: this.get('item'),
          articleRange: 0,
          socialRange: 0,
          searchTerm: "",
          filters: [],
          tagFilters: [],
          locationFilters: []
        });
        otherProfiles.pushObject(newProfile);

        newProfile.get('profile').then(function (profile) {
          _this.get('item.whitelistTagIds').addObjects(profile.get('whitelistTags').mapBy('id'));
          _this.get('item.labelWhitelistTagIds').addObjects(profile.get('whitelistTags').mapBy('id'));
          _this.send("saveItem");
        });
      }
    }
  });
});
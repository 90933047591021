define('client/routes/plan/neo/soundbytes/edit', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    profileService: Ember.inject.service('profile'),
    titleToken: "Edit Soundbyte",

    model: function model(params) {
      var bucket = this.get('profileService.lastBucketId') ? this.store.peekRecord('profile-message-bucket', this.get('profileService.lastBucketId')) : null;
      return Ember.RSVP.hash({
        message: this.store.createRecord("message", {
          profile: this.get("profileService.profile"),
          name: null,
          profileMessageBucket: bucket
        }),
        existingMessage: this.store.findRecord('message', params.id),
        bucket: this.modelFor('plan.neo.buckets')
      });
    },
    afterModel: function afterModel(model) {
      model.message.set('name', model.existingMessage.get('name'));
    }
  });
});
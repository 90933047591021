define('client/models/report-items/image', ['exports', 'client/models/report-item'], function (exports, _reportItem) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _reportItem.default.extend({
    image: (0, _reportItem.propWithDefaultValue)("image", null, "image"),

    imageTop: (0, _reportItem.propWithDefaultValue)("imageTop", 0),
    imageLeft: (0, _reportItem.propWithDefaultValue)("imageLeft", 0),
    imageScale: (0, _reportItem.propWithDefaultValue)("imageScale", null)
  });
});
define('client/components/article-list/publication-item', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNameBindings: [':article-item', ":publication-item", "expandable:selectable", "expanded", ":checkable", "content.isChecked:checked"],
    store: Ember.inject.service(),

    baseFilterSet: null,
    filterSet: null,

    articleList: null,
    pubArticles: null,
    perPage: 50,
    isPubLoading: null,

    query: Ember.computed.alias("articleList.dataQuery"),

    click: function click(e) {
      var $e = Ember.$(e.target);
      if (!$e.is('input') && !$e.parents('.manual-load-item').length) {
        this.send('select', this.get('content'));
      }
    },

    expanded: Ember.computed.alias('content.expanded'),

    expandable: false,
    init: function init() {

      this._super.apply(this, arguments);
      var base = this.get("baseFilterSet.content");
      var list = this.get('store').createRecord("article-list", {
        filterSet: base,
        sort: this.get("sort"),
        publication: this.get("content.name"),
        skipSyndicateGrouping: true
      });
      this.setProperties({
        pubArticles: [],
        articleList: list,
        expanded: false
      });
    },
    loadArticles: function loadArticles() {
      var _this = this;

      var offset = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 0;

      var list = this.get('articleList');
      list.setProperties({
        offset: offset,
        perPage: this.get("perPage")
      });

      this.set('isPubLoading', true);
      if (offset > 0) {
        this.set('isLoadingMore', true);
      }

      var query = this.get("query");

      query.then(function (search) {
        _this.set("errorMsg", null);
        var hits = _this.get("pubArticles");
        hits.addObjects(search.get("articles"));
        _this.set("totalHits", search.get("total_hits"));
      }, function (msg) {
        msg = msg && msg.errors && msg.errors[0] && msg.errors[0].detail || msg;
        console.warn(msg);
        _this.set("errorMsg", msg);
      }).finally(function () {
        _this.setProperties({
          isPubLoading: false,
          isLoadingMore: false
        });
      });
    },


    showInteractionCount: Ember.computed("sort", function () {
      var selectedSort = this.get('sort');
      return selectedSort === 'all_interaction' || selectedSort.match("^event_") || selectedSort.match("^goal_");
    }),

    actions: {
      select: function select() {
        if (this.get("expandable")) {
          this.toggleProperty("expanded");
          this.loadArticles();
        }
      },
      updateArticleStats: function updateArticleStats(article) {
        this.decrementProperty('content.docCount');
      },
      loadOffset: function loadOffset(offset) {
        this.loadArticles(offset);
      },
      onChecked: function onChecked(content) {
        content.toggleProperty('isChecked');
        this.onChecked(content);
      }
    }
  });
});
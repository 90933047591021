define('client/components/es-debug-button', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['es-debug'],
    session: Ember.inject.service(),

    click: function click() {
      this._super.apply(this, arguments);
      this.session.set("isDebugES", !this.session.get("isDebugES"));
    }
  });
});
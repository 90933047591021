define('client/models/report-items/tile-sentiment', ['exports', 'client/models/report-items/tile'], function (exports, _tile) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _tile.default.extend({
    endpoint: "sentiment",

    hasArticleSentiment: Ember.computed.alias("profile.hasArticleSentiment"),

    filters: Ember.computed("filterList.filters", "hasArticleSentiment", function () {
      var hasArticleSentiment = this.get('hasArticleSentiment'),
          filters = this.get("filterList.filters").concat([]);
      if (hasArticleSentiment !== undefined && !hasArticleSentiment) {
        filters.removeObjects(["news", "linkedin_pulse"]);
        filters.addObject("twitter");
      }
      return filters;
    })
  });
});
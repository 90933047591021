define('client/models/trend-social-count', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var SocialCount = _emberData.default.Model.extend({

    date: Ember.computed("id", function () {
      var date = this.get("id");
      if (date.split) {
        date = moment(date.split("T")[0]);
      } else {
        date = moment(date);
      }
      return date.toDate();
    }),
    fbTotal: _emberData.default.attr("number"),
    pinterestPins: _emberData.default.attr("number"),
    googlePlusones: _emberData.default.attr("number"),
    totalAmplification: _emberData.default.attr("number"),
    docCount: _emberData.default.attr("number")

  });

  SocialCount.pathForType = "research/trends/social_counts";

  exports.default = SocialCount;
});
define('client/components/research/research-page-modal/rpm-body', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['research-page-modal-body'],
    attributeBindings: ["style"]
  });
});
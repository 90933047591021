define("client/utils/clean-quotes", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = cleanQuotes;
  function cleanQuotes(string) {
    return string.replace(/[\u2018\u2019]/g, "'").replace(/[\u201C\u201D]/g, '"');
  }
});
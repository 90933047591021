define('client/app', ['exports', 'client/resolver', 'ember-load-initializers', 'client/config/environment', 'client/facebook-api-config', 'jquery-mousewheel', 'fixed-header-table', 'd3'], function (exports, _resolver, _emberLoadInitializers, _environment, _facebookApiConfig, _jqueryMousewheel, _fixedHeaderTable, _d) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  window.d3 = _d.default;

  var App = Ember.Application.extend({
    modulePrefix: _environment.default.modulePrefix,
    podModulePrefix: _environment.default.podModulePrefix,
    Resolver: _resolver.default
  });

  (0, _emberLoadInitializers.default)(App, _environment.default.modulePrefix);

  //
  // This is to avoid normal form submit when a user presses enter
  //
  Ember.$("body").on("submit", "form", function (e) {
    e.preventDefault();
    e.stopImmediatePropagation();
  });

  Ember.Object.reopen({
    forceSet: function forceSet(path, value) {
      try {
        this.set(path, value);
      } catch (e) {}
    }
  });

  exports.default = App;
});
define('client/components/insights/insight-manager', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    store: Ember.inject.service(),
    tagName: null,

    insightId: Ember.computed('insight', {
      get: function get() {
        return this.get('insight.id');
      },
      set: function set(key, value) {
        var _this = this;

        var insight = this.get('store').peekRecord('insight', value);
        if (insight) {
          this.set('insight', insight);
        } else {
          this.get('store').findRecord('insight', value).then(function (insight) {
            _this.set('insight', insight);
          });
        }
        return value;
      }
    }),

    actions: {
      restoreInsight: function restoreInsight() {
        var insight = this.get('insight');
        insight.set('userStatus', 'default');
        return insight.save();
      },
      deleteInsight: function deleteInsight() {
        var insight = this.get('insight');
        insight.set('userStatus', 'deleted');
        return insight.save();
      },
      saveInsight: function saveInsight() {
        var insight = this.get('insight');
        insight.set('userStatus', 'saved');
        return insight.save();
      }
    }
  });
});
define('client/initializers/feature-flags', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.initialize = initialize;
  function initialize(application) {

    application.inject('route', 'featureFlags', 'service:feature-flags');
    application.inject('controller', 'featureFlags', 'service:feature-flags');
    application.inject('view', 'featureFlags', 'service:feature-flags');
    application.inject('component', 'featureFlags', 'service:feature-flags');
    application.inject('model', 'featureFlags', 'service:feature-flags');
  }

  exports.default = {
    name: 'feature-flags',
    initialize: initialize
  };
});
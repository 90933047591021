define('client/components/popover-wormhole-content', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var PopoverWormholeContentComponent = Ember.Component.extend({
    classNameBindings: ['showArrow:bottom', 'name'],
    classNames: ['fade', 'in'],
    isOpen: Ember.computed.alias('popover.isOpen'),
    selector: Ember.computed.alias('elementId'),
    showArrow: true,
    target: 'popover-wormhole-target',
    top: null,
    left: null,
    autofocus: null,
    elementId: null,

    uniquePopoverClass: Ember.computed('elementId', function () {
      return this.get('elementId') + '-popover';
    }),

    uniquePopoverClassSelector: Ember.computed('elementId', function () {
      return '.' + this.get('elementId') + '-popover';
    }),

    didRender: function didRender() {
      this._super.apply(this, arguments);
      this.windowManagerService.on("window:resize", this, "resize");
      this.resize();
    },

    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      this.show();
    },


    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);
      this.windowManagerService.off("window:resize", this, "resize");
    },

    resize: function resize() {
      if (this.get('attachTo')) {
        var $attachTo = Ember.$('#' + this.get('attachTo'));
        var position = $attachTo.offset();
        this.setProperties({
          top: position.top + $attachTo.height() + 20,
          left: position.left + 10
        });
      }
    },

    positionDidChange: Ember.observer('top', 'left', function () {
      this._setPosition();
    }),

    show: function show() {
      Ember.run.schedule('afterRender', this, function () {
        this._setPosition();
        if (this.get('autofocusOn')) {
          var popover = Ember.$('#' + this.get('target')).find(this.get('uniquePopoverClassSelector'));
          popover.find(this.get('autofocusOn')).focus();
        }
      });
    },

    _setPosition: function _setPosition() {
      var trigger = this.get('popover.triggerView'),
          left = void 0,
          top = void 0;
      if (trigger) {
        var triggerPosition = trigger.$().offset();
        left = triggerPosition.left - 15;
        top = triggerPosition.top + 40;
      } else {
        left = this.get('left');
        top = this.get('top');
      }
      Ember.$('#' + this.get('target')).find('.popover').css({
        left: left,
        top: top
      });
    }
  });

  exports.default = PopoverWormholeContentComponent;
});
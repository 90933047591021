define('client/components/analyze/article-related-tab', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    store: Ember.inject.service(),
    article: null,
    showAllRelatedArticles: Ember.computed.alias('article.fullArticle.showAllRelatedArticles'),

    init: function init() {
      this._super.apply(this, arguments);
      this.resetRecentArticles();
    },
    didUpdateAttrs: function didUpdateAttrs() {
      this.resetRecentArticles();
    },
    resetRecentArticles: function resetRecentArticles() {
      this.set('showAllRelatedArticles', false);
      this.get('article.fullArticle').notifyPropertyChange('relatedQuery');
    },


    relatedArticleSort: Ember.computed({
      get: function get() {
        return this.get('article.fullArticle.sort') || 'date';
      },
      set: function set(key, value) {
        this.set('article.fullArticle.sort', value);
        return value;
      }
    }),
    showRelatedFollowOn: true,
    showRelatedSyndicates: true,
    relatedArticles: Ember.computed("article.fullArticle.relatedArticles.[]", "showRelatedFollowOn", "showRelatedSyndicates", function () {
      if (this.get("showRelatedSyndicates") && this.get('showRelatedFollowOn')) {
        return this.get('article.fullArticle.relatedArticles');
      } else if (this.get('showRelatedFollowOn')) {
        return this.get('article.fullArticle.followonArticles');
      } else if (this.get('showRelatedSyndicates')) {
        return this.get('article.fullArticle.syndicatedArticles');
      }
      return Ember.A();
    }),

    allRelatedArticles: Ember.computed.union("article.fullArticle.related", "article.fullArticle.syndicated", "article.fullArticle.followon"),

    actions: {
      filterRelatedResults: function filterRelatedResults() {
        this.toggleProperty('showAllRelatedArticles');
        this.get('article.fullArticle').notifyPropertyChange('relatedQuery');
      }
    }
  });
});
define('client/utils/filters/article-list-adapter', ['exports', 'client/utils/filters/base-adapter'], function (exports, _baseAdapter) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _baseAdapter.default.extend({
    filterSet: null,
    // We cannot use a `or` here as both actualProfile or profile are PromiseObjects
    // They are never null.
    profile: Ember.computed('filterSet.actualProfile.id', 'filterSet.profile', function () {
      return this.get("filterSet.actualProfile.id") ? this.get("filterSet.actualProfile") : this.get("filterSet.profile");
    }),
    otherProfiles: Ember.computed.alias("filterSet.otherProfiles"),

    filters: Ember.computed("filterSet.earned", "filterSet.owned", "filterSet.pressRelease", "filterSet.positive", "filterSet.neutral", "filterSet.negative", "profile.hasArticleSentiment", "filterSet.syndicated", "filterSet.followOn", "filterSet.excludeSyndicateArticles", "filterSet.article", "filterSet.linkedinPulse", "filterSet.twitter", "filterSet.googlePlus", "filterSet.television", "filterSet.radio", "filterSet.podcast", "filterSet.youtube_bc", "filterSet.printPublication", "filterSet.hideLongTail", function () {
      var filterSet = this.get("filterSet"),
          filters = [];
      // channel
      if (filterSet.get("earned")) {
        filters.push("media_earned");
      }
      if (filterSet.get("owned")) {
        filters.push("media_owned");
      }
      if (filterSet.get("pressRelease")) {
        filters.push("press_release");
      }

      if (filterSet.get("influential")) {
        filters.push("influential");
      }
      if (filterSet.get("uninfluential")) {
        filters.push("uninfluential");
      }

      // tone
      if (filterSet.get("positive")) {
        filters.push("tone_positive");
      }
      if (filterSet.get("neutral")) {
        filters.push("tone_neutral");
      }
      if (filterSet.get("negative")) {
        filters.push("tone_negative");
      }
      // content
      if (filterSet.get("article")) {
        filters.push("news");
      }
      if (filterSet.get("linkedinPulse")) {
        filters.push("linkedin_pulse");
      }
      if (filterSet.get("twitter")) {
        filters.push("twitter");
      }
      if (filterSet.get("googlePlus")) {
        filters.push("gplus");
      }
      if (filterSet.get("television")) {
        filters.push("television");
      }
      if (filterSet.get("radio")) {
        filters.push("radio");
      }
      if (filterSet.get("podcast")) {
        filters.push("podcast");
      }
      if (filterSet.get("youtube_bc")) {
        filters.push("youtube_bc");
      }
      if (filterSet.get("printPublication")) {
        filters.push("print_publication");
      }

      //syndicated and follow on
      if (filterSet.get("syndicated")) {
        filters.push("syndicated");
      }
      if (filterSet.get("followOn")) {
        filters.push("follow_on");
      }
      if (filterSet.get("excludeSyndicateArticles")) {
        filters.push("exclude_syndicate_articles");
      }

      //long tail
      if (filterSet.get("hideLongTail")) {
        filters.push("hide_long_tail");
      }
      return filters;
    }),

    filtersHash: Ember.computed('filters.[]', 'locationFilterList.[]', 'tagFilterList.[]', function () {
      var filters = {};

      var allFilters = this.get('filters');
      if (allFilters) {
        allFilters.forEach(function (c) {
          filters[c] = true;
        });
        var featureFlags = this.get('filterSet.actualProfile.featureFlags');
        if (featureFlags.get('earned_only')) {
          delete filters['media_owned'];
          delete filters['press_release'];
        }
      }

      var tagDefaults = this.get('filterSet.actualProfile.tags');
      if (!Ember.isEmpty(tagDefaults)) {
        tagDefaults = tagDefaults.mapBy("id");
        tagDefaults.forEach(function (t) {
          filters['tag_' + t] = false;
        });
        this.get("tagFilterList").forEach(function (f) {
          //to indicate tag negation, an array element comes
          //in the form 'tag_10:not'
          var not = f.split(':');
          if (not.length > 1 && tagDefaults.indexOf(not[0]) > -1) {
            filters['tag_' + not[0]] = "not";
          } else {
            filters['tag_' + f] = tagDefaults.indexOf(f) > -1;
          }
        });
      }
      var locationSelection = this.get("locationFilterList");
      locationSelection.forEach(function (l) {
        filters[l] = true;
      });

      // // Default Amplification Filters (all network)
      // const amplificationSelection = this.get("filterSet.amplificationFilters");
      // amplificationSelection.forEach(function(a){
      //   const name = get(a, "name");
      //   filters[name] = true;
      // });
      filters.languages = this.get('filterSet.languages');
      filters.gplus = this.get('filterSet.googlePlus');
      filters.whitelist_tag_ids = this.get('filterSet.whitelistTags').toArray().mapBy('id');
      filters.influential = this.get("filterSet.influential");
      filters.uninfluential = this.get("filterSet.uninfluential");
      filters.syndicated = this.get('filterSet.syndicated');
      filters.follow_on = this.get('filterSet.followOn');
      filters.exclude_syndicate_articles = this.get('filterSet.excludeSyndicateArticles');
      filters.relevance = this.get('filterSet.relevance');

      filters.radio = this.get('filterSet.radio');
      filters.television = this.get('filterSet.television');
      filters.podcast = this.get('filterSet.podcast');
      filters.youtube_bc = this.get('filterSet.youtube_bc');
      filters.print_publication = this.get('filterSet.printPublication');
      filters.twitter = this.get('filterSet.twitter');
      filters.relevance = this.get('filterSet.relevance');
      filters.company_website_link = this.get('filterSet.companyWebsiteLink');
      var articleLinksContain = this.get('filterSet.articleLinksContain');
      filters.article_links_contain = Ember.isPresent(articleLinksContain) ? articleLinksContain : null;

      return filters;
    })
  });
});
define('client/controllers/settings/export-management/index', ['exports', 'client/mixins/corporate', 'moment'], function (exports, _corporate, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var CorporateExportsController = Ember.Controller.extend(_corporate.default, {

    modal: Ember.inject.service(),
    profile: Ember.computed.alias('profileService.profile'),
    campaign: null,
    user: Ember.computed.alias('currentUser.content'),
    continuePolling: false,
    poller: null,

    to: Ember.computed({
      get: function get(k) {
        return (0, _moment.default)();
      },
      set: function set(k, v) {
        return v;
      }
    }),

    from: Ember.computed('profile.startDate', {
      get: function get(k) {
        return (0, _moment.default)().add(-1, 'week');
      },
      set: function set(k, v) {
        return v;
      }
    }),

    startPolling: function startPolling() {
      this.set('continuePolling', true);
      this.poll();
    },

    stopPolling: function stopPolling() {
      this.set('continuePolling', false);
    },

    poll: function poll() {
      var poller = this.get('poller');
      if (poller) {
        Ember.run.cancel(poller);
      }
      if (this.get('continuePolling') !== true) {
        return;
      }
      poller = Ember.run.throttle(this, function () {
        this.notifyPropertyChange("type"); // force reload of list
        this.poll();
      }, 15000, false);
      this.set('poller', poller);
    },

    exports: Ember.computed(function () {
      var owner = Ember.getOwner(this);
      var findPagingController = owner.lookup('controller:find-paging');

      findPagingController.setProperties({
        model: [],
        type: "export_request",
        store: this.store
      });
      Ember.run.later(this, "paramsDidChange");
      return findPagingController;
    }),

    resources: Ember.computed.alias("exports"),

    actions: {
      dateRangeChanged: function dateRangeChanged(from, to) {
        this.set('from', (0, _moment.default)(from));
        this.set('to', (0, _moment.default)(to));
      },
      addExport: function addExport() {
        var _this = this;

        var newExport = this.store.createRecord("export_request", {
          profile: this.get("profile"),
          campaign: this.get("campaign"),
          user: this.get("user"),
          date_start: this.get('from').toDate(),
          date_end: this.get('to').toDate()
        });
        if (newExport.isValid) {
          newExport.save().then(function () {
            _this.notifyPropertyChange("type"); // force reload of list
          }, function (e) {
            var modalObj = { showCancel: false, ok: 'OK', okType: 'danger' };
            if (e.errors && e.errors.length) {
              modalObj.prompt = e.errors[0];
            } else {
              modalObj.prompt = 'Could not request export at this time.';
            }
            _this.get('modal').open('common/ui-confirm', modalObj);
          });
        } else {
          this.get('modal').open('common/ui-confirm', {
            question: "The block is invalid.",
            ok: 'OK',
            okType: "danger"
          });
        }
      }
    }
  });

  exports.default = CorporateExportsController;
});
define('client/components/research/authors/filter-group', ['exports', 'client/computed/index'], function (exports, _index) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ["author-filters author-filter-group"],
    sort: null,

    filters: null,
    opened: false,
    selection: null,
    showAll: false,
    isLoading: false,
    allSelected: true,

    sortedFilters: Ember.computed.sort("filters", "sort"),
    topFilters: (0, _index.topItems)("sortedFilters", 10),

    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);
      this.checkAllSelected();
    },
    checkAllSelected: function checkAllSelected() {
      var selectedFilters = this.get('sortedFilters').findBy('isSelected');
      if (!selectedFilters) {
        this.set('allSelected', true);
      } else {
        this.set('allSelected', false);
      }
    },
    onToggleFilter: function onToggleFilter() {},
    onOpenFilter: function onOpenFilter() {},
    onToggleAll: function onToggleAll() {},
    init: function init() {
      this._super.apply(this, arguments);
      this.set('sort', this.get('sort') || ["value:desc"]);
    },


    actions: {
      showMore: function showMore() {
        this.toggleProperty("showAll", true);
      },
      toggleOpened: function toggleOpened() {
        this.toggleProperty("opened");
        this.onOpenFilter(this.get('type'), this.get('opened'));
      },
      toggleFromAll: function toggleFromAll() {
        this.toggleProperty('allSelected');
        if (this.get('allSelected')) {
          this.onToggleAll(this.get('type'), 'clearAll');
        }
      },
      selectFilter: function selectFilter(filter) {
        var _this = this;

        Ember.run.next(function () {
          _this.checkAllSelected();
        });

        this.onToggleFilter(filter);
      }
    }
  });
});
define('client/services/feature-flags', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var FF = Ember.ObjectProxy.extend({
    content: {}
  });

  FF.reopenClass({
    isServiceFactory: true
  });

  exports.default = FF;
});
define('client/components/articles-manager', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    store: Ember.inject.service(),
    articleIds: null,

    articles: Ember.computed('articleIds.[]', function () {
      if (this.get('articleIds.length')) {
        return this.get('store').query('article', { ids: this.get('articleIds') });
      }{
        return Ember.A();
      }
    })
  });
});
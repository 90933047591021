define('client/queries/tile', ['exports', 'client/queries/query', 'client/utils/-iso-format', 'ember-concurrency', 'client/components/location-filters', 'moment'], function (exports, _query, _isoFormat, _emberConcurrency, _locationFilters, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _query.default.extend({
    featureFlags: Ember.inject.service(),
    ajax: Ember.inject.service(),

    load: function load() {
      this.set('errors', null);
      var campaign_id = this.get("item.campaign_id");
      if (campaign_id === null) {
        campaign_id = undefined;
      }
      var postDataObj = {
        profile_id: this.get("item.profile_id"),
        campaign_id: campaign_id,
        from: (0, _isoFormat.isoTZFormat)((0, _moment.default)(this.get('item.fromDate')).startOf('day').toDate()),
        to: (0, _isoFormat.isoTZFormat)((0, _moment.default)(this.get('item.toDate')).endOf('day').toDate()),
        search_term: this.get('item.searchTerm'),
        time_zone: this.get('item.timezone') || null
      };

      Ember.$.extend(postDataObj, this.get('additionalPostData'));

      var queryParams = this.get("queryParams"),
          url = '/v3/api/dashboard/' + this.get("endpoint");

      if (queryParams) {
        url = url + '?' + queryParams;
      }

      var promise = this.get('ajax').post(url, { data: postDataObj });
      this.set("promise", promise);

      return this;
    },
    cancel: function cancel() {
      // NOOP
    },


    additionalPostData: Ember.computed(function () {
      var filters = {};

      var allFilters = this.get('item.allFilters');
      if (allFilters) {
        allFilters.forEach(function (c) {
          filters[c] = true;
        });
        var featureFlags = this.get('featureFlags');
        if (featureFlags.get('earned_only')) {
          delete filters['media_owned'];
          delete filters['press_release'];
        }
      }

      var languages = this.get('item.languages');
      if (languages && languages.length) {
        filters.languages = languages;
      }
      var tagSelection = this.get('item.tagFilters');
      tagSelection.forEach(function (tag) {
        //to indicate tag negation, an array element comes
        //in the form 'tag_10:not'
        var not = tag.split(':');
        var str = 'tag_' + not[0];
        if (not.length > 1) {
          filters[str] = "not";
        } else {
          filters[str] = true;
        }
      });

      var locationSelection = this.get("item.locationFilters");
      locationSelection.forEach(function (l) {
        filters[l] = true;
      });

      // Default Amplification Filters (all network)
      var amplificationSelection = this.get("item.amplificationFilters");
      amplificationSelection.forEach(function (a) {
        var name = Ember.get(a, "name");
        filters[name] = true;
      });

      filters.whitelist_tag_ids = this.get('item.whitelistTagIds');
      filters.influential = this.get("item.influential");
      filters.uninfluential = this.get("item.uninfluential");
      filters.syndicated = this.get('item.syndicated');
      filters.follow_on = this.get('item.followOn');
      filters.exclude_syndicate_articles = this.get('item.excludeSyndicateArticles');
      filters.relevance = this.get('item.relevance');
      filters.company_website_link = this.get('item.companyWebsiteLink');
      filters.article_links_contain = this.get('item.articleLinksContain');
      return {
        filters: filters
      };
    })

  });
});
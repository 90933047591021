define('client/queries/research/author-stats', ['exports', 'client/queries/query'], function (exports, _query) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  //
  // When a page load, many requests for th authors details can be done.
  // To reduce the number of requests made to the backend, we group all
  // requests done within a single run loop together and query them at
  // once.
  //

  var MAX_PER_REQUEST = 10,
      GLOBAL_PROMISE_DELAY = 10;

  // map of authorIds to promises
  var authorToPromise = {},
      globalPromise = null;

  exports.default = _query.default.extend({
    store: Ember.inject.service(),

    loadAuthor: function loadAuthor(authorId) {
      var _this = this;

      if (!globalPromise) {

        // this promise handles the coalescing of all author stats requests
        // within a single run loop. You can play with the delay too with
        // GLOBAL_PROMISE_DELAY
        globalPromise = new Ember.RSVP.Promise(function (resolve, reject) {
          Ember.run.later(function () {
            // reset the run loop
            var authors = Ember.merge({}, authorToPromise);
            authorToPromise = {};
            globalPromise = null;

            // get all ids

            var _getProperties = _this.getProperties("store", "item"),
                store = _getProperties.store,
                item = _getProperties.item,
                profileId = item.get("profileService.profile.id"),
                term = item.get("term") || "",
                authorIds = Object.keys(authors);

            // there is a max of X authors per query


            var groups = [];
            for (var i = 0, j = authorIds.length; i < j; i += MAX_PER_REQUEST) {
              groups.push(authorIds.slice(i, i + MAX_PER_REQUEST));
            }

            // Split queries by group
            var promises = groups.map(function (ids) {
              return store.query('author-stat', {
                ids: ids,
                profile_id: profileId,
                term: term
              }).then(function (response) {

                // resolve each author stat promise
                ids.forEach(function (id) {
                  authors[id].resolve(response.findBy("author.id", id));
                });
              }, reject);
            });

            Ember.RSVP.all(promises).then(resolve, reject);
          }, GLOBAL_PROMISE_DELAY);
        });
      }

      // attach the promise to the id
      return authorToPromise[authorId] || (authorToPromise[authorId] = Ember.RSVP.defer());
    },
    load: function load() {
      var _getProperties2 = this.getProperties("store", "item"),
          store = _getProperties2.store,
          item = _getProperties2.item,
          authorId = item.get("authorId"),
          term = item.get("term") || "",
          statId = authorId + '_' + term;

      // peek the store before doing the request.


      var stats = store.peekRecord("author-stat", statId);

      // got some stats, do not continue with the query
      if (stats) {
        this.set("promise", Ember.RSVP.resolve(stats));
        return this;
      }

      // use the old or create a new deferred promise
      var deferred = authorToPromise[authorId];
      if (!deferred) {
        deferred = this.loadAuthor(authorId);
      }

      this.set("promise", deferred.promise);

      return this;
    }
  });
});
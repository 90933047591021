define('client/models/neo-info', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var NeoInfo = _emberData.default.Model.extend({

    message_id: _emberData.default.attr("number"),
    profile_id: _emberData.default.attr("number"),
    campaign_id: _emberData.default.attr("number"),
    profile_message_bucket_id: _emberData.default.attr("number"),
    from: _emberData.default.attr("isodate"),
    to: _emberData.default.attr("isodate"),
    search_term: _emberData.default.attr("string"),
    search_type: _emberData.default.attr("string"),

    message_texts: _emberData.default.attr("array"),
    message_stats: _emberData.default.attr("array"),
    message_text: _emberData.default.attr("string"),
    messages_daily: _emberData.default.attr("array"),
    messages_overall: _emberData.default.attr("array"),
    amplification_daily: _emberData.default.attr("array"),
    amplification_overall: _emberData.default.attr("array"),

    messages: Ember.computed("messages_overall", function () {
      var overall = this.get("messages_overall"),
          amplification = this.get("amplification"),
          texts = this.get("message_texts");

      return overall.map(function (m) {
        var amp = amplification.findBy("message_id", m.get("message_id")),
            text = texts.findBy("message_id", m.get("message_id"));

        m = NeoInfo.Message.create({
          content: m,
          info: this,
          amplification: amp,
          text: text ? text.get("text") : ""
        });
        if (amp) {
          amp.set("message", m);
        }
        return m;
      }, this);
    }),

    amplification: Ember.computed("amplification_overall", function () {
      var overall = this.get("amplification_overall"),
          texts = this.get("message_texts");
      return overall.map(function (m) {
        var text = texts.findBy("message_id", m.get("message_id"));
        return NeoInfo.Message.create({
          content: m,
          info: this,
          text: text ? text.get("text") : ""
        });
      }, this);
    }),

    // TODO(tony) - get this from profile, but it doesn't matter until we start
    // binning these by either year or quarter and currently we don't
    customYearEndOffset: null,

    messageGraphData: Ember.computed("messages_daily", function () {
      return this._completeRange(this.get('messages_daily'), this.get("from"), this.get("to"), true, this.get('customYearEndOffset'));
    }),

    amplificationGraphData: Ember.computed("amplification_daily", function () {
      return this._completeRange(this.get('amplification_daily'), this.get("from"), this.get("to"), true, this.get('customYearEndOffset'));
    })

  });

  NeoInfo.pathForType = "neo";
  //
  // Some proxy to load the daily data for a message
  //
  NeoInfo.Message = Ember.ObjectProxy.extend({
    content: null,
    info: null,
    amplification: null,
    message: null,
    selected: false,

    _dailyInfo: Ember.computed("info.filters", "info.profile_id", "info.to", "info.from", function () {
      return this.get("info").store.query("neo-info", {
        profile_id: this.get("info.profile_id"),
        campaign_id: this.get("info.campaign_id"),
        from: this.get("info.from").toISOString(),
        to: this.get("info.to").toISOString(),
        message_id: this.get("message_id"),
        filters: this.get("info.filters"),
        search_term: this.get("info.search_term")
      });
    }),

    isLoading: Ember.computed.not("_dailyInfo.content.isLoaded"),

    messageGraph: Ember.computed("_dailyInfo.firstObject.messages_daily", function () {
      return this.get("_dailyInfo.firstObject.messageGraphData");
    }),

    amplificationGraph: Ember.computed("_dailyInfo.firstObject.amplification_daily", function () {
      return this.get("_dailyInfo.firstObject.amplificationGraphData");
    })
  });

  exports.default = NeoInfo;
});
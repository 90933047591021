define('client/services/session', ['exports', 'ember-simple-auth/services/session'], function (exports, _session) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _session.default.extend({
    store: Ember.inject.service(),

    activeProfile: Ember.inject.service('profile'),

    isDebugES: false,
    showingHelp: false,

    flatProfiles: function flatProfiles(checkCompetitors, checkCampaigns, parentProfile) {

      var topProfiles = this.get('activeProfile.currentUser.sortedCorporates');
      parentProfile = parentProfile || this.get("activeProfile");

      if (!topProfiles) {
        topProfiles = [parentProfile];
      }

      var profiles = Ember.A();
      var competitors = Ember.A();
      var campaigns = Ember.A();

      topProfiles.forEach(function (topProfile) {
        recursivelyFlattenProfilesChildren(topProfile, checkCompetitors, checkCampaigns, profiles);
      });

      if (checkCompetitors && !Ember.isEmpty(profiles)) {
        competitors = profiles.filterBy('isCompetitorOfCustomer');
        profiles = profiles.filterBy('isCompetitorOfCustomer', false);
      }

      if (checkCampaigns && !Ember.isEmpty(profiles)) {
        campaigns = profiles.filterBy('isCampaign');
        profiles = profiles.filterBy('isCampaign', false);
      }

      var flattenedProfiles = profiles.concat(competitors).concat(campaigns);
      var uniqueIds = {};
      var uniqueFlattenedProfiles = flattenedProfiles.reduce(function (uniqueProfiles, profile) {
        var id = profile.get('id');
        if (Ember.isPresent(uniqueIds[id])) {
          return uniqueProfiles;
        }
        uniqueIds[id] = profile;
        uniqueProfiles.pushObject(profile);
        return uniqueProfiles;
      }, Ember.A());

      return uniqueFlattenedProfiles.sortBy("company_name");
    }

  });


  function recursivelyFlattenProfilesChildren(profile, checkCompetitors, checkCampaigns) {
    var profiles = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : Ember.A();

    if (!profiles.findBy("id"), profile.get("id")) {
      profiles.pushObject(profile);
    }

    profile.get("children").forEach(function (child) {
      var skipFlattening = child.get("isCompetitor") && !checkCompetitors;
      skipFlattening = skipFlattening || child.get("isCampaign") && !checkCampaigns;
      if (skipFlattening) {
        return;
      }
      recursivelyFlattenProfilesChildren(child, checkCompetitors, checkCampaigns, profiles);
    });
    return profiles;
  }
});
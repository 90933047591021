define('client/components/common/ui-calendar-range', ['exports', 'moment-timezone', 'client/utils/date-format'], function (exports, _momentTimezone, _dateFormat2) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    init: function init() {
      this._super.apply(this, arguments);
      this.set('customFiscalYearOffset', this.get('customFiscalYearOffset') || {});
    },


    classNames: ["ui-calendar-range", "form-control"],
    classNameBindings: ["disabled:disabled", "active:active"],
    active: false,
    modal: Ember.inject.service("modal"),
    defaultText: "Pick a range",
    from: null,
    to: null,
    disabled: false,
    insideClick: false,
    isOpen: false,
    showUserDefault: false,
    showTimezone: false,
    timezone: null,
    fromInputError: false,
    toInputError: false,

    attachment: "top",
    targetAttachment: "bottom",
    placement: "right",

    rangeSelectionFrom: null,
    rangeSelectionTo: null,

    startOfTime: new Date(0),
    endOfTime: new Date(),
    customFiscalYearOffset: null,
    today: Ember.computed(function () {
      return (0, _momentTimezone.default)();
    }),

    calendars: Ember.computed("rangeCenter", "today", function () {

      // recalc only when changing the rangeCenter

      var rightMost = this.get("rangeCenter") || this.get("range.end") || this.get("today"),
          center = (0, _momentTimezone.default)(rightMost).subtract(1, "month"),
          leftMost = (0, _momentTimezone.default)(rightMost).subtract(2, "month");

      return [{
        center: leftMost,
        navLeft: true,
        navRight: false
      }, {
        center: center,
        navLeft: false,
        navRight: false
      }, {
        center: rightMost,
        navLeft: false,
        navRight: true
      }];
    }),

    didReceiveAttrs: function didReceiveAttrs() {
      this.set("rangeSelectionFrom", null);
      this.set("rangeSelectionTo", null);
    },


    currentTimezone: Ember.computed('currentUser.abbrTimezone', 'timezone', function () {
      if (this.get('timezone')) {
        return _momentTimezone.default.tz(this.get('timezone')).zoneAbbr();
      }
      return this.get('currentUser.abbrTimezone');
    }),

    range: Ember.computed("from", "to", "rangeSelectionFrom", "rangeSelectionTo", function () {
      var _getProperties = this.getProperties("rangeSelectionFrom", "rangeSelectionTo", "from", "to"),
          rangeSelectionFrom = _getProperties.rangeSelectionFrom,
          rangeSelectionTo = _getProperties.rangeSelectionTo,
          from = _getProperties.from,
          to = _getProperties.to;

      return {
        start: rangeSelectionFrom ? rangeSelectionFrom : from,
        // the condition is not a typo, it is really based on the from
        end: rangeSelectionFrom ? rangeSelectionTo : to
      };
    }),

    // event sent when the range changed.
    rangeChanged: function rangeChanged() {},
    change: function change(from, to) {
      // check against start of time
      var startOfTime = this.get("startOfTime");
      from = (0, _momentTimezone.default)(from);
      to = (0, _momentTimezone.default)(to);
      if (startOfTime) {
        if (from.isBefore(startOfTime)) {
          // notify the user
          this.get('modal').open('common/ui-confirm', {
            question: 'This date range is not available, please select a date after ' + (0, _dateFormat2.default)(startOfTime),
            ok: "OK",
            showCancel: false
          });
          return;
        }
      }
      this.resetRangeCenter();
      this.get("rangeChanged")(from, to);
    },
    debounceFrom: function debounceFrom(value) {
      var from = (0, _dateFormat2.parseDate)(value),
          to = (0, _momentTimezone.default)(this.get("to")),
          today = (0, _momentTimezone.default)();
      // still editing if the year is off
      if (from.isValid() && from.year() > 2010 && (from.isSame(to, "day") || from.isBefore(to, "day")) && !from.isAfter(today, "day") && (from.isSame(this.startOfTime, "day") || from.isAfter(this.startOfTime, "day"))) {
        this.change(from, this.get("to"));
        this.set("fromInputError", false);
      } else {
        this.set("fromInputError", true);
      }
    },
    debounceTo: function debounceTo(value) {
      var to = (0, _dateFormat2.parseDate)(value),
          from = (0, _momentTimezone.default)(this.get("from")),
          today = (0, _momentTimezone.default)();
      if (to.isValid() && to.year() > 2010 && (to.isSame(from, "day") || to.isAfter(from, "day")) && !to.isAfter(today, "day")) {
        this.change(from, to);
        this.set("toInputError", false);
      } else {
        this.set("toInputError", true);
      }
    },
    createNewEvent: function createNewEvent(eventName) {
      //Polyfill for IE11 'new Event'
      var event = void 0;
      if (typeof Event === 'function') {
        event = new Event(eventName);
      } else {
        event = document.createEvent('Event');
        event.initEvent(eventName, true, true);
      }
      return event;
    },
    resetRangeCenter: function resetRangeCenter() {
      this.set("fromInputError", false);
      this.set("toInputError", false);
      this.set("rangeCenter", null);
      this.notifyPropertyChange('calendars');
    },


    actions: {
      rangeChangedQuickSelect: function rangeChangedQuickSelect() {
        this.resetRangeCenter();
        this.get("rangeChanged").apply(undefined, arguments);
      },
      toggleOpen: function toggleOpen() {
        this.toggleProperty('isOpen');
        if (this.get('isOpen')) {
          this.resetRangeCenter();
        }
      },
      powerCalendarRangeChanged: function powerCalendarRangeChanged(_ref) {
        var moment = _ref.moment;
        var start = moment.start,
            end = moment.end;

        this.set("fromInputError", false);
        this.set("toInputError", false);

        if (end) {
          this.rangeChanged(start, end);
          this.set("rangeSelectionFrom", null);
          this.set("rangeSelectionTo", null);
        } else {
          this.set("rangeSelectionFrom", start);
          this.set("rangeSelectionTo", end);
        }
      },
      changeFromText: function changeFromText(value) {
        Ember.run.debounce(this, "debounceFrom", value, 500);
      },
      changeToText: function changeToText(value) {
        Ember.run.debounce(this, "debounceTo", value, 500);
      }
    }
  });
});
define('client/components/added-link', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var AddedLinkComponent = Ember.Component.extend({
    tagName: 'tr',
    classNameBindings: ['model.showAsValid::alert-danger'],

    date: Ember.computed("model.timestamp", function () {
      return moment(this.get("model.timestamp"));
    }),

    addedDate: Ember.computed("model.added_date", function () {
      return moment(this.get("model.added_date"));
    }),

    updateTimestamp: function updateTimestamp() {},
    removeLink: function removeLink() {},


    actions: {
      updateTimestamp: function updateTimestamp(newDate) {
        this.get('updateTimestamp')(this.get('model'), newDate);
      },
      removeLink: function removeLink() {
        this.get('removeLink')(this.get('model'));
      }
    }
  });

  exports.default = AddedLinkComponent;
});
define("client/routes/reports/show/index", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    beforeModel: function beforeModel() {
      var report = this.modelFor("reports.show"),
          page = report.get("pages.firstObject");

      this.transitionTo("reports.show.page", report, page);
    }
  });
});
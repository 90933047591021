define('client/components/sort-column', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({

    tagName: "th",
    classNameBindings: [":sort-column", "desc:sort-column_desc", "asc:sort-column_asc"],
    sortBy: "",
    removeSorts: function removeSorts() {},


    desc: Ember.computed("sortBy", function () {
      return this.get("sortBy") === "DESC";
    }),

    asc: Ember.computed("sortBy", function () {
      return this.get("sortBy") === "ASC";
    }),

    click: function click(e) {
      var desc = this.get("desc");
      var asc = this.get("asc");
      e.preventDefault();

      this.removeSorts();

      Ember.run.once(this, function () {
        if (desc) {
          this.set("sortBy", "ASC");
        } else if (asc) {
          this.set("sortBy", "");
        } else {
          this.set("sortBy", "DESC");
        }
      });
    }

  });
});
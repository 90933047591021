define('client/controllers/reports', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    needs: ["application"],
    linksDateCount: Ember.computed.oneWay("controllers.application.linksDateCount"),
    actions: {
      newReport: function newReport() {
        // create report
        // transition to show
        this.transitionToRoute("reports.show", {});
      }
    }
  });
});
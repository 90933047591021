define('client/components/impact-score-spokesperson', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var ImpactScoreSpokespersonnameComponent = Ember.Component.extend({
    store: Ember.inject.service(),
    modal: Ember.inject.service(),

    intialArray: Ember.computed.alias('impactScoreSpokesperson'),
    newArray: [],
    delArray: [],
    impactSpokesperson: Ember.computed('profile', function () {
      return this.get("profile.profileImpactScoreSetting");
    }),

    actions: {
      addFromSearch: function addFromSearch(product) {
        this.get("newArray").pushObject(product);
        this.get("impactScoreSpokesperson").pushObject(product);
      },
      removeSpokesperson: function removeSpokesperson(product) {
        if (this.get("intialArray").includes(product) == true) {
          this.get("delArray").pushObject(product);
        }
        this.get("newArray").removeObject(product);
        this.get("impactScoreSpokesperson").removeObject(product);
      },
      addSpokesperson: function addSpokesperson() {
        var s = this.store;
        var newAr = this.newArray;
        var profile_iss = this.impactSpokesperson;
        this.impactScoreSpokesperson.forEach(function (item) {
          if (newAr.includes(item) == true) {
            var a = s.createRecord("impact-score-spokesperson", {
              name: item,
              profileImpactScoreSetting: profile_iss
            });
            a.save();
          }
        });
        newAr.length = 0;
        var delAr = this.delArray;
        var spokespeople = this.store.peekAll('impact-score-spokesperson');
        spokespeople.forEach(function (item) {
          if (delAr.includes(item.name) == true) {
            item.destroyRecord();
          }
        });

        this.get('modal').open('common/ui-confirm', {
          prompt: 'Your changes are saved and we are processing your request.',
          ok: 'OK',
          okType: "danger",
          showCancel: false
        });
      }
    }
  });

  exports.default = ImpactScoreSpokespersonnameComponent;
});
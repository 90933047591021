define('client/components/trends/filter-container', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['trends-filter-container'],
    onToggleFilter: function onToggleFilter() {}
  });
});
define('client/models/campaign-analytics-event-relationship', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var CampaignAnalyticsEventRelationship = _emberData.default.Model.extend({
    analytics_event: _emberData.default.belongsTo("analytics_event"),
    campaign: _emberData.default.belongsTo("campaign"),
    created_at: _emberData.default.attr("date"),
    metric: Ember.computed.alias("analytics_event")
  });
  CampaignAnalyticsEventRelationship.pathForType = "campaign_analytics_event_relationships";

  exports.default = CampaignAnalyticsEventRelationship;
});
define('client/components/search-select', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var SearchSelect = Ember.Component.extend({
    classNameBindings: [":search-select", "searchShowed:search-select_opened"],

    placeholder: "Add",
    itemClass: null,
    content: null,
    _isDirty: false,

    search: function search() {
      return Ember.A();
    },
    searchTerm: null,
    searchShowed: Ember.computed.bool('searchTerm'),
    labelPath: null,
    addFromSearch: function addFromSearch() {},
    selectFromSearch: function selectFromSearch() {},


    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      var that = this;
      this.$("input").on("blur", function () {
        Ember.run.later(that, "blurInput", 500);
      });
    },

    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);
      this.$("input").off("blur");
    },

    _searchTermDidChange: function _searchTermDidChange() {

      var term = this.get("searchTerm");
      if (!term || term.length === 0) {
        // nothing to search for
        return;
      }
      this.set("content", this.get('search')(term));
      this.set('_isDirty', false);
    },

    blurInput: function blurInput() {
      if (this._state === "inDOM") {
        this.set("searchTerm", null);
      }
    },

    actions: {
      add: function add() {
        var term = this.get("searchTerm");
        if (term && term.length > 0) {
          this.addFromSearch(this.get("searchTerm"), this.get("itemClass"));
          this.blurInput();
        }
      },
      select: function select(item) {
        this.selectFromSearch(item, this.get("itemClass"));
        this.blurInput();
      },
      enter: function enter() {
        this.send("add");
      },
      updateSearchTerm: function updateSearchTerm(val) {
        this.setProperties({ searchTerm: val, _isDirty: true });
        Ember.run.debounce(this, "_searchTermDidChange", 500);
      }
    }
  });

  exports.default = SearchSelect;
});
define('client/adapters/report-template', ['exports', 'client/adapters/application'], function (exports, _application) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var ReportTemplateAdapter = _application.default.extend({
    namespace: '/v4/api'
  });
  exports.default = ReportTemplateAdapter;
});
define('client/models/influencer', ['exports', 'ember-data', 'ember-cp-validations'], function (exports, _emberData, _emberCpValidations) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var Validations = (0, _emberCpValidations.buildValidations)({
    name: (0, _emberCpValidations.validator)('presence', true)
  });

  var Influencer = _emberData.default.Model.extend(Validations, {

    name: _emberData.default.attr("string"),
    title: _emberData.default.attr("string"),
    facebook_name: _emberData.default.attr("string"),
    twitter_name: _emberData.default.attr("string"),
    linkedin_name: _emberData.default.attr("string"),
    gplus_name: _emberData.default.attr("string"),
    profile_id: _emberData.default.attr("number"),

    facebook_page: Ember.computed.alias('facebook_name'),
    twitter_page: Ember.computed.alias('twitter_name'),
    linkedin_page: Ember.computed.alias('linkedin_name'),
    gplus_page: Ember.computed.alias('gplus_name'),

    deauthorize: function deauthorize(type) {
      this.set(type + "_page", null);
    }

  });
  Influencer.pathForType = "influencers";
  Influencer.TITLES = ["Journalist", "Social Media Influencer", "Analyst", "Blog Influencer", "Other"];

  exports.default = Influencer;
});
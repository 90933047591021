define('client/models/message', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var Message = _emberData.default.Model.extend({
    name: _emberData.default.attr("string"),
    profileMessageBuckets: _emberData.default.hasMany("profile-message-bucket"),
    profile: _emberData.default.belongsTo("profile", { inverse: null }),

    //used when creating only
    profileMessageBucket: _emberData.default.belongsTo("profile-message-bucket", { inverse: "messages" }),

    text: Ember.computed.alias('name')
  });
  Message.pathForType = "messages";

  exports.default = Message;
});
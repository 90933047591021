define("client/routes/plan/pitch/new", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    titleToken: "New Pitch List",

    model: function model() {
      var profile = this.get("profileService.profile");
      return this.store.createRecord("pitch-list", { profile: profile });
    },
    deactivate: function deactivate() {
      if (this.controller.model.get('isNew')) {
        this.controller.model.destroyRecord();
      }
    }
  });
});
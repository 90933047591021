define('client/models/pdf-rendering', ['exports', 'client/models/search', 'ember-data'], function (exports, _search, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  // = require ./search

  var PdfRendering = _search.default.extend({
    // Includes all filters for the current article search

    // list all options for pdf rendering
    content: _emberData.default.attr("string")

  });
  PdfRendering.pathForType = "pdfs";

  exports.default = PdfRendering;
});
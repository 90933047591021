define('client/components/article-list-card', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    profileService: Ember.inject.service('profile'),
    classNames: ["article-list-card"],
    totalArticles: 0,
    totalSelected: 0,
    syndicateCount: 0,
    tooltipDisabledMessage: null,
    tooltipEnabledMessage: null,
    filteredHits: Ember.A(),
    defaultSort: "date",
    parentCount: Ember.computed('totalArticles', 'syndicateCount', function () {
      var syndicateCount = this.get('syndicateCount') || 0;
      return this.get('totalArticles') - syndicateCount;
    }),
    articleSelectionExceeds: Ember.computed('totalArticles', 'syndicateCount', 'totalSelected', function () {
      var syndicateCount = this.get('syndicateCount') || 0;
      var parentCount = this.get('totalArticles') - syndicateCount;
      if (parentCount == 0) {
        this.set("tooltipDisabledMessage", "No articles available to summarize.");
        return true;
      }
      if (this.totalSelected > 0) {
        if (this.totalSelected > 300) {
          this.set("tooltipDisabledMessage", "To ensure high-quality summaries, please narrow down your selection. Fewer, more relevant articles lead to better results.");
          return true;
        }
      } else {
        if (parentCount > 300) {
          this.set("tooltipDisabledMessage", "To ensure high-quality summaries, please narrow down your selection. Fewer, more relevant articles lead to better results. Use filters or manually select articles.");
          return true;
        }
      }
      if (this.totalSelected > 0) {
        this.set("tooltipEnabledMessage", "Click to generate a summary of my selected coverage (English-language articles only).");
      } else {
        this.set("tooltipEnabledMessage", "Click to generate a summary of my coverage (English-language articles only).");
      }
      return false;
    }),

    isLoading: false,
    articleListLoading: false,
    showMenu: true,
    competitors: null,
    showSortError: false,
    selectionExpanded: false,
    showEmailNewsletterIcon: false,

    onAddTag: function onAddTag() {},
    onHideContent: function onHideContent() {},
    onMarkSpam: function onMarkSpam() {},
    onMarkIrrelevant: function onMarkIrrelevant() {},
    onSetSentiment: function onSetSentiment() {},
    selectFirst100: function selectFirst100() {},
    deselectAll: function deselectAll() {},
    addToDashboard: function addToDashboard() {},
    hideFromCampaign: function hideFromCampaign() {},
    addToMediaList: function addToMediaList() {},
    editMediaList: function editMediaList() {},


    competitorsSortKey: null,
    sortedCompetitors: Ember.computed.sort("competitors", "competitorsSortKey"),

    /**
     * Show option to hide articles when campaign is selected.
     */
    showHideArticleOption: Ember.computed('profileService.campaign', {
      get: function get(k) {
        return !!this.get('profileService.campaign.id');
      },
      set: function set(k, v) {
        return v;
      }
    }),
    emptyMessage: Ember.computed('showSortError', function () {
      if (this.get('showSortError') && !this.get('inModal')) {
        return 'The sort value is not applicable, choose a different one.';
      }
      return 'No ' + (this.get('showPublications') ? "publications" : "articles") + ' match the current filters.';
    }),

    showTotalList: Ember.computed('parentCount', function () {
      return this.get('parentCount') > 0 || this.get('syndicateCount') > 0;
    }),
    toggleExpandedArticles: function toggleExpandedArticles() {},
    emailArticles: function emailArticles() {},
    init: function init() {
      this._super.apply(this, arguments);
      this.set('competitorsSortKey', this.get('competitorsSortKey') || ["name:asc"]);
    },


    actions: {
      reset: function reset() {
        this.set("totalArticles", 0);
      },
      loaded: function loaded(hits) {
        this.setProperties({
          filteredHits: hits.filtered,
          totalArticles: hits.totalHits,
          syndicateCount: hits.syndicateHits
        });
      },
      loading: function loading(load) {
        this.set("isLoading", load);
      },
      onAddTag: function onAddTag() {
        this.onAddTag();
      },
      onHideContent: function onHideContent() {
        this.onHideContent();
      },
      onMarkSpam: function onMarkSpam() {
        this.onMarkSpam();
      },
      onMarkIrrelevant: function onMarkIrrelevant() {
        this.onMarkIrrelevant();
      },
      selectFirst100: function selectFirst100() {
        this.selectFirst100(this.get('filteredHits'));
      },
      deselectAll: function deselectAll() {
        this.deselectAll();
      },
      addToDashboard: function addToDashboard() {
        this.addToDashboard();
      },
      hideFromCampaign: function hideFromCampaign() {
        this.hideFromCampaign();
      },
      addToMediaList: function addToMediaList() {
        this.addToMediaList();
      },
      editMediaList: function editMediaList() {
        this.editMediaList();
      },
      showSortError: function showSortError() {
        this.set('showSortError', true);
      },
      onSetSentiment: function onSetSentiment() {
        this.onSetSentiment();
      }
    }
  });
});
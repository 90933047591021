define('client/components/article-list/article-item-min', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    profileService: Ember.inject.service('profile'),
    classNames: ['article-item'],

    showSyndicates: true,
    showSortedValue: true,
    showAuthorInfo: false,

    showInteractionCount: Ember.computed("sort", function () {
      var selectedSort = this.get('sort');
      if (selectedSort) {
        return selectedSort === 'all_interaction' || selectedSort.match("^event_") || selectedSort.match("^goal_");
      }
    }),

    hasSyndicates: Ember.computed("showSyndicates", "content.syndicatesDomainUrls.length", function () {
      return this.get('showSyndicates') && this.get('content.syndicatesDomainUrls.length') > 0;
    }),

    hasFollowonParents: Ember.computed("content.followonParents", function () {
      return Ember.isPresent(this.get('content.followonParents'));
    })

  });
});
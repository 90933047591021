define('client/models/article-summary', ['exports', 'ember-data', 'client/utils/twitter-settings', 'client/utils/social-settings', 'client/mixins/models/has-endpoint-actions', 'client/mixins/models/broadcast', 'client/mixins/models/print_publication', 'client/computed/index', 'client/utils/parse-url'], function (exports, _emberData, _twitterSettings, _socialSettings, _hasEndpointActions, _broadcast, _print_publication, _index, _parseUrl) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
        arr2[i] = arr[i];
      }

      return arr2;
    } else {
      return Array.from(arr);
    }
  }

  var SENTIMENT_POSITIVE = 'Positive';
  var SENTIMENT_NEGATIVE = 'Negative';
  var SENTIMENT_NEUTRAL = 'Neutral';
  var SENTIMENT_NOT_SCORED = 'Not scored';

  function _formatCount(value) {
    if (isNaN(value)) {
      return;
    }
    var prefix = d3.formatPrefix(value, 2);
    var roundedValue = d3.round(prefix.scale(value), 1);
    return roundedValue + prefix.symbol;
  }

  function formattedCount(key) {
    return Ember.computed(key, function () {
      return _formatCount(this.get(key));
    });
  }

  var ArticleSummary = _emberData.default.Model.extend(_hasEndpointActions.default, _broadcast.default, _print_publication.default, {
    ajax: Ember.inject.service(),
    profileService: Ember.inject.service('profile'),
    IptcTopics: Ember.inject.service(),
    IptcData: Ember.inject.service(),

    spam: _emberData.default.attr("boolean", { defaultValue: false }),
    relevance: _emberData.default.attr("boolean", { defaultValue: true }),
    blacklisted: _emberData.default.attr("boolean", { defaultValue: false }),

    url: _emberData.default.attr("string"),
    logoUrl: _emberData.default.attr("string"),

    publication: _emberData.default.attr("string"),
    title: _emberData.default.attr("string"),
    summary: _emberData.default.attr("string"),
    language: _emberData.default.attr("string"),
    date: _emberData.default.attr("date"),
    type: _emberData.default.attr("string"),
    score: _emberData.default.attr("number"),
    reach: _emberData.default.attr("number"),
    impactScore: _emberData.default.attr("number"),
    desktopReach: _emberData.default.attr("number"),
    mobileReach: _emberData.default.attr("number"),
    owned_media_flag: _emberData.default.attr("boolean"),
    analytics_flag: _emberData.default.attr("boolean"),
    author_relevance_flag: _emberData.default.attr("boolean"),
    marketName: _emberData.default.attr('string'),

    tone: _emberData.default.attr("number"),
    toneLabel: _emberData.default.attr("string"),
    toneSentiment: Ember.computed("toneLabel", function () {
      var tone = this.get("toneLabel");
      return tone ? tone.capitalize() : SENTIMENT_NOT_SCORED;
    }),

    tags: _emberData.default.hasMany("tag"),

    facebook_count: _emberData.default.attr("number"),
    facebook_likes: _emberData.default.attr("number"),
    facebook_comments: _emberData.default.attr("number"),
    facebook_shares: _emberData.default.attr("number"),
    twitter_count: _emberData.default.attr("number"),
    twitter_retweets: _emberData.default.attr("number"),
    twitter_favorites: _emberData.default.attr("number"),
    gplus_count: _emberData.default.attr("number"),
    gplusAvailable: Ember.computed("date", function () {
      // cut off on september 1st 2017
      return this.get("date") < _socialSettings.default.GOOGLEPLUS_END;
    }),
    gplus_plusones: _emberData.default.attr("number"),
    gplus_reshares: _emberData.default.attr("number"),
    gplus_replies: _emberData.default.attr("number"),
    linkedin_count: _emberData.default.attr("number"),
    linkedinAvailable: Ember.computed("date", function () {
      // cut off on february 15 2018
      return this.get("date") < _socialSettings.default.LINKEDIN_END;
    }),
    pinterest_count: _emberData.default.attr("number"),
    pinterestAvailable: Ember.computed("date", function () {
      return this.get("date") > _socialSettings.default.PINTEREST_START;
    }),

    hiddenCampaigns: _emberData.default.hasMany('campaign', { inverse: null }),

    author: _emberData.default.attr("string"),
    airprAuthorId: _emberData.default.attr("string"),
    screen_name: _emberData.default.attr("string"),
    country: _emberData.default.attr("string"),

    inProfileGeo: _emberData.default.attr("boolean", { defaultValue: true }),
    inProfileDateRange: _emberData.default.attr("boolean", { defaultValue: true }),

    profile_id: _emberData.default.attr(),
    campaign_id: _emberData.default.attr(),

    to: _emberData.default.attr('string'),
    from: _emberData.default.attr('string'),

    searchTerm: null,

    useInteractionCount: _emberData.default.attr('boolean'),
    isSyndicatedParent: _emberData.default.attr("boolean", { defaultValue: true }),
    syndicatedParent: _emberData.default.attr("number"),
    syndicatesDomainUrls: _emberData.default.attr("array"),
    syndicatesAmplificationTotal: _emberData.default.attr("number", { defaultValue: 0 }),
    syndicatesVisitorsTotal: _emberData.default.attr("number", { defaultValue: 0 }),
    syndicatesInteractionsTotal: _emberData.default.attr("number", { defaultValue: 0 }),
    syndicatesReachTotal: _emberData.default.attr("number", { defaultValue: 0 }),
    syndicatesReadershipTotal: _emberData.default.attr("number", { defaultValue: 0 }),
    syndicatesRevenueTotal: _emberData.default.attr("number", { defaultValue: 0 }),
    syndicatesCount: _emberData.default.attr("number", { defaultValue: 0 }),
    syndicatesImpactScoreTotal: _emberData.default.attr("number", { defaultValue: 0 }),

    hasSyndicates: Ember.computed('syndicatesCount', function () {
      return this.get('syndicatesCount') > 0;
    }),

    syndicatesAverageImpactScore: Ember.computed('syndicatesImpactScoreTotal', 'syndicatesCount', function () {
      if (this.get('syndicatesCount') > 0) {
        return this.get('syndicatesImpactScoreTotal') / (this.get('syndicatesCount') + 1);
      } else {
        return this.get('impactScore');
      }
    }),

    syndicatesFacebookCountTotal: _emberData.default.attr("number", { defaultValue: 0 }),
    syndicatesTwitterCountTotal: _emberData.default.attr("number"),
    syndicatesLinkedinCountTotal: _emberData.default.attr("number", { defaultValue: 0 }),
    syndicatesGplusCountTotal: _emberData.default.attr("number", { defaultValue: 0 }),
    syndicatesPinterestCountTotal: _emberData.default.attr("number", { defaultValue: 0 }),

    pov2Hml: _emberData.default.attr("object"),
    emailNewsletterOrder: _emberData.default.attr('number'),

    syndicatesInteractionsPercent: Ember.computed("syndicatesInteractionsTotal", "syndicatesVisitorsTotal", function () {
      var iCount = this.get('syndicatesInteractionsTotal'),
          vCount = this.get('syndicatesVisitorsTotal');
      return !vCount ? 0 : iCount / vCount;
    }),
    followonParents: _emberData.default.attr("array"),
    followonIds: _emberData.default.attr("array"),
    iptcTopicsIds: _emberData.default.attr("array"),
    topLevelIptcTopicsIds: _emberData.default.attr("array"),

    isSelected: false,
    search_term: null,

    cachedFullArticle: null,
    fullArticleJSON: "",
    // base versions
    cachedBaseArticle: null,
    baseArticleJSON: "",

    _socialCountsUpdatedAt: 0,

    _twitterData: null,

    isTwitter: Ember.computed.equal('type', 'twitter'),
    isFacebook: Ember.computed.equal('type', 'facebook'),
    isGooglePlus: Ember.computed.equal('type', 'google_plus'),
    isYoutube: Ember.computed.equal('type', 'youtube'),
    isLinkedIn: Ember.computed.equal('type', 'linkedin'),
    isNotAvailableForProfile: Ember.computed.equal('type', 'unavailable_article'),

    notInProfileSearchSeeds: Ember.computed('isNotAvailableForProfile', 'inProfileGeo', 'inProfileDateRange', 'spam', 'relevance', 'blacklisted', function () {
      return this.get('isNotAvailableForProfile') && this.get('inProfileGeo') && this.get('inProfileDateRange') && !this.get('spam') && this.get('relevance') && !this.get('blacklisted');
    }),

    hiddenForCampaign: function hiddenForCampaign(campaignId) {
      return !!campaignId && this.hasMany('hiddenCampaigns').ids().includes(campaignId);
    },

    logo: Ember.computed.oneWay("logoUrl"),

    hasLogo: Ember.computed.bool("logoUrl"),

    outOfRange: Ember.computed("date", "from", function () {
      return this.get("date") < this.get("from");
    }),

    titleOrSummary: Ember.computed("title", "summary", function () {
      var title = this.get("title");
      // Limiting to 75 characters because that will definitely limit it to two rows...
      var result = ((title && title.length > 0 ? title : this.get("summary")) || "").substr(0, 75);
      return result.length < 75 ? result : result + "...";
    }),

    authorOrPublication: Ember.computed("author", "publication", function () {
      if (this.get("isSocial") || this.get("isVideo")) {
        var author = this.get("author");
        var screen_name = this.get("screen_name");

        if (this.get("isTwitter")) {
          return author && author.length > 0 ? author + ' (@' + screen_name + ')' : '@' + screen_name;
        } else {
          return author && author.length > 0 ? author : this.get("publication");
        }
      } else {
        return this.get("publication");
      }
    }),

    twitterID: Ember.computed('isTwitter', 'url', function () {
      var isTwitter = this.get('isTwitter'),
          url = this.get('url');
      if (!isTwitter || !url) {
        return null;
      }
      var tokens = url.split('/');
      return tokens[tokens.length - 1];
    }),

    isEmbeddedSocial: Ember.computed.or('isTwitter', 'isGooglePlus'),

    // Some social networks aren't supported in the context graph yet
    isNotSupportedSocial: Ember.computed("type", function () {
      return ['youtube', 'linkedin', 'facebook'].indexOf(this.get("type").toLowerCase()) >= 0;
    }),

    iptcTopicLabels: Ember.computed("topLevelIptcTopicsIds", function () {
      var _this = this;

      if (this.topLevelIptcTopicsIds == undefined) {
        return [];
      }
      var iptcTopics = "";
      if (this.get("IptcData").getIptcTopicLabelMapping() != null) {
        iptcTopics = this.get("IptcData").getIptcTopicLabelMapping();
      } else {
        this.get("IptcTopics").iptcTopicNames().then(function (data) {
          _this.get("IptcData").cacheData(data);
          iptcTopics = _this.get("IptcData").getIptcTopicLabelMapping();
        }).catch(function (error) {
          console.error(error);
          throw error;
        });
      }
      var labels = [];
      for (var i = 0; i < this.topLevelIptcTopicsIds.length; i++) {
        labels.push(iptcTopics[this.topLevelIptcTopicsIds[i]]);
      }
      return labels;
    }),

    iptcParentLabel: Ember.computed("topLevelIptcTopicsIds", function () {
      return this.iptcTopicLabels[0];
    }),

    iptcParentRemainingLabels: Ember.computed("topLevelIptcTopicsIds", function () {
      var topics = [].concat(_toConsumableArray(this.iptcTopicLabels));
      topics.shift();
      return topics.join(" | ");
    }),

    iptcParentRemainingcount: Ember.computed("topLevelIptcTopicsIds", function () {
      return "+" + String(this.iptcTopicLabels.length - 1);
    }),

    isIptcParentRemainingcountPresent: Ember.computed("topLevelIptcTopicsIds", function () {
      return this.iptcTopicLabels.length - 1 > 0;
    }),

    iptcAllTopicsLabels: Ember.computed("iptcTopicsIds", function () {
      var _this2 = this;

      if (this.iptcTopicsIds == undefined) {
        return [];
      }
      var iptcTopics = "";
      if (this.get("IptcData").getIptcTopicLabelMapping() != null) {
        iptcTopics = this.get("IptcData").getIptcTopicLabelMapping();
      } else {
        this.get("IptcTopics").iptcTopicNames().then(function (data) {
          _this2.get("IptcData").cacheData(data);
          iptcTopics = _this2.get("IptcData").getIptcTopicLabelMapping();
        }).catch(function (error) {
          console.error(error);
          throw error;
        });
      }
      var labels = [];

      var _loop = function _loop(i) {
        var topics = [];
        Object.keys(_this2.iptcTopicsIds[i]).forEach(function (e) {
          topics.push(iptcTopics[_this2.iptcTopicsIds[i][e]]);
        });
        labels.push(topics);
      };

      for (var i = 0; i < this.iptcTopicsIds.length; i++) {
        _loop(i);
      }
      return labels;
    }),

    iptcTopicsPresent: Ember.computed("topLevelIptcTopicsIds", function () {
      return this.iptcTopicLabels.length > 0;
    }),

    isArticle: Ember.computed("type", function () {
      return ['google_news', 'google_blogs', 'manual', 'crawler_articles', 'linkedin_pulse', 'medium'].indexOf(this.get("type").toLowerCase()) >= 0;
    }),

    isSocial: Ember.computed("type", function () {
      return ['facebook', 'twitter', 'linkedin', 'google_plus'].indexOf(this.get("type").toLowerCase()) >= 0;
    }),

    isVideo: Ember.computed("type", function () {
      return ['youtube'].indexOf(this.get("type").toLowerCase()) >= 0;
    }),

    shouldDisplay: Ember.computed("spam", "relevance", function () {
      return !this.get("spam") && this.get("relevance");
    }),

    facebookCountFormatted: formattedCount('facebook_count'),
    facebookLikesFormatted: formattedCount('facebook_likes'),
    facebook_comments_formatted: formattedCount('facebook_comments'),
    facebook_shares_formatted: formattedCount('facebook_shares'),

    twitter_count_formatted: Ember.computed("twitter_count", "showTwitterCounts", function () {
      if (Ember.isPresent(this.get("twitter_count")) && this.get("showTwitterCounts")) {
        return _formatCount(this.get("twitter_count"));
      }
    }),

    twitter_retweets_formatted: formattedCount('twitter_retweets'),
    twitter_favorites_formatted: formattedCount('twitter_favorites'),

    gplus_count_formatted: formattedCount('gplus_count'),
    gplus_plusones_formatted: formattedCount('gplus_plusones'),
    gplus_reshares_formatted: formattedCount('gplus_reshares'),
    gplus_replies_formatted: formattedCount('gplus_replies'),
    linkedin_count_formatted: formattedCount('linkedin_count'),
    pinterest_count_formatted: formattedCount('pinterest_count'),

    isTwitterGnip: Ember.computed.gte('date', _twitterSettings.default.GNIP_START_DATE),
    isTwitterLegacy: Ember.computed.lte('date', _twitterSettings.default.COUNTS_END_DATE),
    showTwitterCounts: Ember.computed.or('isTwitter', 'isTwitterLegacy', 'isTwitterGnip'),

    datetimeFormatForCurrentYear: _index.d3Format.time('date', "%b %e"),
    datetimeFormatForDifferentYear: _index.d3Format.time('date', "%m/%d/%y"),
    dateIsCurrentYear: Ember.computed("date", function () {
      // jshint eqeqeq: false
      return this.get("date").getYear() == new Date().getYear();
    }),

    dateFormatted: (0, _index.ternaryProperties)("dateIsCurrentYear", "datetimeFormatForCurrentYear", "datetimeFormatForDifferentYear"),

    dateFormattedLong: _index.d3Format.time('date', "%B %e, %Y"),

    refreshingSocialCounts: Ember.computed.notEmpty('pendingActionsByName.refreshSocialCounts'),

    shouldRefreshSocialCount: Ember.computed('_socialCountsUpdatedAt', function () {
      var updatedAt = this.get('_socialCountsUpdatedAt'),
          expireTime = updatedAt + MINUTE;
      return expireTime < Date.now();
    }),

    twitterData: Ember.computed("url", "isTwitter", "_twitterData", function () {
      var _this3 = this;

      var twitter = this.get("_twitterData"),
          url,
          id;

      if (!this.get("isTwitter")) {
        return;
      }

      if (!twitter) {
        url = this.get("url");
        id = url.match(/.*\/(\d*)/)[1];

        $.ajax({
          url: "https://api.twitter.com/1/statuses/oembed.json",
          dataType: "jsonp",
          data: {
            id: id,
            omit_script: true,
            align: "center",
            maxwidth: 550
          }
        }).then(function (data) {
          Ember.run.bind(_this3, function () {
            return _this3.set("_twitterData", data);
          }); // eslint-disable-line
        });
      }
      return twitter;
    }),

    fullArticle: Ember.computed("id", "profile_id", "campaign_id", 'searchTerm', "from", "to", function () {
      return this._fullArticleForProfile(this.get("profile_id"), this.get("campaign_id"), this.get('searchTerm'));
    }),

    fullArticleForProfile: function fullArticleForProfile(profileId, campaignId, searchTerm) {
      return this._fullArticleForProfile(profileId, campaignId, searchTerm);
    },
    baseArticleForProfile: function baseArticleForProfile(profileId, campaignId, searchTerm) {
      return this._fullArticleForProfile(profileId, campaignId, searchTerm, true);
    },

    isFinancialTimes: Ember.computed.equal('publication', 'Financial Times'),

    rootDomain: Ember.computed('url', function () {
      return (0, _parseUrl.extractRootDomain)(this.get('url'));
    }),

    markSpam: function markSpam() {
      this.set("spam", true);
      return this.save();
    },
    markIrrelevant: function markIrrelevant() {
      this.set("relevance", false);
      return this.save();
    },
    refreshSocialCounts: function refreshSocialCounts() {
      if (!this.get('shouldRefreshSocialCount')) {
        return Ember.RSVP.resolve(this);
      }
      this.set('_socialCountsUpdatedAt', Date.now());
      var updateCounts = this._updateSocialCounts.bind(this);

      return this.dispatchEndpointAction('refreshSocialCounts').then(updateCounts);
    },
    mergeWith: function mergeWith(article) {
      return this.get('ajax').post('/v3/api/pers/' + article.get('id') + '/merge', {
        data: {
          profile_id: this.get('profileService.profile.id'),
          source_per_id: this.get('id')
        }
      });
    },
    addLink: function addLink(link) {
      return this.get('ajax').post('/v3/api/pers/' + this.get('id') + '/add_link', {
        data: {
          profile_id: this.get('profileService.profile.id'),
          url: link
        }
      });
    },
    markHidden: function markHidden(campaign) {
      this.get('hiddenCampaigns').addObject(campaign);
      var url = '/v2/api/pers/' + this.get("id") + '/mark_hidden';
      return this.get('ajax').post(url, {
        data: {
          profile_id: this.get('profile_id'),
          campaign_id: campaign.get('id')
        }
      });
    },
    _updateSocialCounts: function _updateSocialCounts(countsData) {
      var currentCounts = this.getProperties('facebook_count', 'linkedin_count', 'gplus_count', 'pinterest_count');
      var countAttrs = Object.assign(currentCounts, countsData);
      this.setProperties(countAttrs);
      return this;
    },
    _fullArticleForProfile: function _fullArticleForProfile(profileId, campaignId, searchTerm) {
      var analyticsDataOnly = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : false;

      if (!profileId) {
        return [];
      }
      //GET URL limit is 5000 chars, some advanceds search terms go over that, so we need to trim them
      //This prevents a failure, but also the full highlight
      var validSearchTerms = searchTerm;
      if (searchTerm && searchTerm.length > 4900) {
        validSearchTerms = "";
      }
      var id = this.get("id"),
          criteria = {
        profile_id: profileId,
        campaign_id: campaignId,
        from: this.get("from"),
        to: this.get("to"),
        search_term: validSearchTerms
      };
      if (analyticsDataOnly) {
        criteria["analytics_data_only"] = true;
      }

      var json = JSON.stringify(criteria),
          newArticle,
          cache = analyticsDataOnly ? 'cachedBaseArticle' : 'cachedFullArticle',
          articleJSON = analyticsDataOnly ? 'baseArticleJSON' : 'fullArticleJSON';
      if (this.get(cache) && this.get(articleJSON) === json) {
        newArticle = this.get(cache);
      } else if (this.get('type') === 'unavailable_article') {
        newArticle = this;
      } else {
        newArticle = this.store.findRecord("article", id, { reload: true, adapterOptions: { query: criteria } });
        this.set(cache, newArticle);
        this.set(articleJSON, json);
      }
      return newArticle;
    },
    setHideFromCampaign: function setHideFromCampaign(campaign) {
      this.get('hiddenCampaigns').addObject(campaign);
    }
  });

  // times in milliseconds
  var SECOND = 1000,
      MINUTE = 60 * SECOND;

  ArticleSummary.pathForType = "pers";

  exports.default = ArticleSummary;
});
define('client/controllers/settings/crawler-search-terms/index', ['exports', 'client/mixins/data-table-sort'], function (exports, _dataTableSort) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend(_dataTableSort.default, {
    modal: Ember.inject.service(),
    ajax: Ember.inject.service(),

    profile: Ember.computed.alias('profileService.profile'),
    resources: Ember.computed.alias('keywords'),
    _sorts: ["Term"],

    paramsDidChange: Ember.observer("sortTerm", "profile", function () {
      Ember.run.scheduleOnce("afterRender", this, "_paramsDidChange");
    }),

    newKeyword: Ember.computed('profile.id', function () {
      var store = this.get('store');
      return store.createRecord("keyword", {
        profile_id: this.get('profile.id')
      });
    }),

    keywords: Ember.computed(function () {
      var owner = Ember.getOwner(this);
      var findPagingController = owner.lookup('controller:find-paging');

      findPagingController.setProperties({
        model: [],
        type: "keyword",
        store: this.get("store")
      });
      Ember.run.later(this, "paramsDidChange");
      return findPagingController;
    }),

    actions: {
      addTerm: function addTerm(newKeyword) {
        var _this = this;

        this.set('isAddingTerm', true);
        newKeyword.save().then(function (res) {
          _this.notifyPropertyChange("keywords");
          _this.notifyPropertyChange("newKeyword");
        }).finally(function () {
          _this.set('isAddingTerm', false);
        });
      },
      removeTerm: function removeTerm(keyword) {
        var _this2 = this;

        this.get('modal').open('common/ui-confirm', {
          prompt: 'Are you sure?',
          ok: "Delete",
          okType: "danger"
        }).then(function () {
          _this2.set('isRemovingTerm', true);
          var profileId = _this2.get('profile.id'),
              keywordId = keyword.get('id');
          _this2.get('ajax').del('/v2/api/keywords/' + keywordId + '?profile_id=' + profileId).then(function () {
            _this2.set('isRemovingTerm', false);
            _this2.get("keywords.model").removeObject(keyword);
            _this2.notifyPropertyChange("keywords");
          });
        });
      }
    }
  });
});
define('client/components/settings/ui-competitor-pov1', ['exports', 'ember-modal-service/components/modal'], function (exports, _modal) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _modal.default.extend({
    competitor: Ember.computed.alias("model.options.competitor"),
    profiles: Ember.computed.alias("model.options.profiles")
  });
});
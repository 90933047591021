define('client/utils/time-series-labeler', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.getRoundDomainRange = getRoundDomainRange;
  exports.numTimeBetween = numTimeBetween;
  exports.binsBetweenDates = binsBetweenDates;
  exports.fiscalYearDatesFromDate = fiscalYearDatesFromDate;
  exports.quarterFromDate = quarterFromDate;
  exports.formattedTime = formattedTime;
  exports.getDateRangeByInterval = getDateRangeByInterval;
  exports.nextBinForDate = nextBinForDate;
  exports.advanceMiddle = advanceMiddle;
  exports.getBinEdges = getBinEdges;
  exports.getLabelledTicks = getLabelledTicks;

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  // import Ember from 'ember';

  var MONTHS_IN_QUARTER = 3;
  var MAX_LABELS = 10;

  function getRoundDomainRange(_ref) {
    var from = _ref.from,
        to = _ref.to,
        binType = _ref.binType,
        customYearEndOffset = _ref.customYearEndOffset;


    // standardize input dates
    if (binType === 'Quarter') {
      from = quarterDatesForDate(from, customYearEndOffset)[0];
      to = quarterDatesForDate(to, customYearEndOffset)[1];
      // Don't have to check from and to, thats impossible because of the
      // quarter function
    } else if (binType === 'Year') {
      // This is fiscal year so we actually have to get the beginning of Q1 that
      // is in the same fiscal year as the from and the end of the Q4 in the year
      // that contains the to
      from = fiscalYearDatesFromDate(from, customYearEndOffset)[0];
      to = fiscalYearDatesFromDate(to, customYearEndOffset)[1];
    } else {
      // grouping is 'day', 'week', ... etc
      var grouping = void 0;
      switch (binType) {
        case 'Day':
        case 'Week':
        case 'Month':
          grouping = d3.time[binType.toLowerCase()];
          break;
        default:
          grouping = d3.time.day;
          break;
      }
      from = grouping(from);
      to = grouping.offset(grouping(to), 1);

      // To will be the end of the bin, since our finest resolution is days, this is equivalent
      // to getting the beginning of the next bin and subtracting one day
      if (binType !== 'Day') {
        to = d3.time.day.offset(to, -1);
      }
    }
    return { from: from, to: to };
  }

  function numTimeBetween(timeInterval, start, stop) {
    switch (timeInterval) {
      case 'Second':
        return (stop - start) / 1000;
      case 'Hour':
        return (stop - start) / 1000;
      case 'Day':
        return (stop - start) / 86400000;
      case 'Week':
        return d3.time.weeks(start, stop).length;
      case 'Month':
        return d3.time.months(start, stop).length;
      case 'Quarter':
        return d3.time.months(start, stop).length / MONTHS_IN_QUARTER;
      case 'Year':
        return d3.time.years(start, stop).length;
    }
  }

  function intervalToD3Time(interval) {
    return d3.time[interval.toLowerCase() + 's'];
  }

  function tickLabelerFn(timeInterval, maxNumberOfLabels, customYearEndOffset) {
    maxNumberOfLabels = maxNumberOfLabels || MAX_LABELS;
    return function (start, stop) {
      var timeBetween = numTimeBetween(timeInterval, start, stop);
      var ticks = void 0;
      if (timeInterval === 'Quarter') {
        if (timeBetween > maxNumberOfLabels) {
          ticks = d3.time.years(start, stop);
        } else {
          // Assume we were given a start date on the first day of quarter
          ticks = [start];
          while (d3.time.month.offset(ticks[ticks.length - 1], 3) < stop) {
            ticks.push(d3.time.month.offset(ticks[ticks.length - 1], 3));
          }
        }
      } else if (timeInterval === 'Year') {
        ticks = [start];
        var theNextYear = function theNextYear() {
          return d3.time.year.offset(ticks[ticks.length - 1], 1);
        };
        while (theNextYear() < stop) {
          ticks.push(theNextYear());
        }
      } else {
        var interval = timeBetween > maxNumberOfLabels ? Math.ceil(timeBetween / maxNumberOfLabels) : 1;
        ticks = intervalToD3Time(timeInterval)(start, stop).filter(function (d, i) {
          return i % interval === 0;
        });
      }
      return ticks;
    };
  }

  function binsBetweenDates(from, to, binType) {
    // for now ignore quarters because those we will always show
    var d3Bin = d3.time[binType.toLowerCase()];
    if (!d3Bin) {
      return null;
    }
    var bins = d3Bin.range(from, to, 1);
    return bins.length;
  }

  function quarterDatesForDate(d, customYearEndOffset) {
    return quarterFromDate(d, customYearEndOffset).dates;
  }

  function fiscalYearDatesFromDate(d, customYearEndOffset) {
    var quarters = quarterDatesForYear(d.getFullYear(), customYearEndOffset);
    if (d > quarters.ends[quarters.ends.length - 1]) {
      quarters = quarterDatesForYear(d.getFullYear() + 1, customYearEndOffset);
    }
    return [quarters.starts[0], quarters.ends[3]];
  }

  function quarterDatesForYear(year, customYearEndOffset) {
    var endMonth = customYearEndOffset.month;
    var endDay = customYearEndOffset.day;

    var endOfLastYear = new Date(year - 1, endMonth, endDay || 15);
    if (!endDay) {
      var startOfNextMonth = d3.time.month.offset(d3.time.month(endOfLastYear), 1);
      endOfLastYear = d3.time.day.offset(startOfNextMonth, -1);
    }

    // It is much easier to work with the start of the quarters
    var startOfThisYear = d3.time.day.offset(endOfLastYear, 1);
    var quarterStarts = [startOfThisYear, d3.time.month.offset(startOfThisYear, 3), d3.time.month.offset(startOfThisYear, 6), d3.time.month.offset(startOfThisYear, 9)];

    var quarterEnds = quarterStarts.slice(1).map(function (d) {
      return d3.time.day.offset(d, -1);
    });
    quarterEnds[3] = d3.time.year.offset(endOfLastYear, 1);
    return {
      starts: quarterStarts,
      ends: quarterEnds
    };
  }

  function quarterFromDate(d, customYearEndOffset) {
    // Figure out what fiscal year this maps to as the fiscal year can end in the middle
    // of the calendar
    var year = d.getFullYear();
    var quarters = quarterDatesForYear(year, customYearEndOffset);
    if (d > quarters.ends[quarters.ends.length - 1]) {
      // We actually need the next fiscal year
      year++;
      quarters = quarterDatesForYear(year, customYearEndOffset);
    }

    var quarter = quarters.ends.findIndex(function (dd) {
      return d <= dd;
    });
    var dates = [quarters.starts[quarter], quarters.ends[quarter]];
    return {
      id: quarter + 1,
      year: year,
      dates: dates
    };
  }

  function quarterFormat(d, customYearEndOffset) {
    var quarter = quarterFromDate(d, customYearEndOffset);
    return 'Q' + quarter.id + ' ' + quarter.year;
  }

  function weekFormat(d) {
    var start = d3.time.week(d);
    var end = d3.time.day.offset(start, 6);
    var formatMonth = d3.time.format('%0m');
    var startMonth = formatMonth(start);
    if (startMonth === formatMonth(end)) {
      var formatDay = d3.time.format('%0d');
      var formatYear = d3.time.format('%Y');
      return startMonth + '/' + formatDay(start) + '-' + formatDay(end) + ' ' + formatYear(start);
    } else {
      var format = d3.time.format('%0m/%0d/%Y');
      return format(start) + ' - ' + format(end);
    }
  }

  weekFormat.parse = function (d) {
    var format = d3.time.format('%0m/%0d/%Y');
    if (d.split('/').length === 2) {
      var _d$split = d.split('/'),
          _d$split2 = _slicedToArray(_d$split, 2),
          month = _d$split2[0],
          notMonth = _d$split2[1];

      var day = notMonth.split('-')[0];
      var year = notMonth.split(' ')[1];
      return format.parse([month, day, year].join('/'));
    } else {
      // Different months and year
      var start = d.split(' - ')[0];
      return format.parse(start);
    }
  };

  function oldDayFormat(d) {
    return d3.time.format.multi([['%b %d', function (d) {
      return d.getSeconds() !== 33;
    }], ['%Y', function () {
      return true;
    }]])(d);
  }

  oldDayFormat.parse = function (d) {
    var spec = d.split(' ') === 1 ? '%Y' : '%b %d';
    return d3.time.format(spec).parse(d);
  };

  var quarterFormatGenerator = function quarterFormatGenerator(customYearEndOffset) {
    var quarterFormatWrapper = function quarterFormatWrapper(d) {
      return quarterFormat(d, customYearEndOffset);
    };

    quarterFormatWrapper.parse = function (d) {
      var _d$split3 = d.split(' '),
          _d$split4 = _slicedToArray(_d$split3, 2),
          quarter = _d$split4[0],
          year = _d$split4[1];

      var f = d3.time.format('%Y');
      var begYear = f.parse(year);
      var quarters = quarterDatesForYear(begYear.getFullYear(), customYearEndOffset);
      return quarters.starts[+quarter.slice(1) - 1];
    };

    return quarterFormatWrapper;
  };

  var yearFormatGenerator = function yearFormatGenerator(customYearEndOffset) {
    var yearWrapper = function yearWrapper(d) {
      var lastDate = fiscalYearDatesFromDate(d, customYearEndOffset)[1];
      return d3.time.format('%Y')(lastDate);
    };

    yearWrapper.parse = function (d) {
      var date = d3.time.format('%Y').parse(d);
      // convert to non fiscal year
      return fiscalYearDatesFromDate(date, customYearEndOffset)[0];
    };

    return yearWrapper;
  };

  function formattedTime(timeInterval, customYearEndOffset) {
    switch (timeInterval) {
      case 'Year':
      case 'years':
      case 'Y':
        return yearFormatGenerator(customYearEndOffset);
      case 'Quarter':
      case 'quarters':
      case 'Q':
        return quarterFormatGenerator(customYearEndOffset);
      case 'Month':
      case 'months':
      case 'M':
        return d3.time.format('%b \'%y');
      case 'Week':
      case 'weeks':
      case 'W':
        return weekFormat;
      case 'Day':
      case 'days':
      case 'D':
        return d3.time.format('%b %d, %Y');
      default:
        return d3.time.format('%Y');
    }
  }

  function getDateRangeByInterval(timeInterval, date) {
    switch (timeInterval) {
      case 'Year':
      case 'years':
      case 'Y':
        var fullYear = date.getFullYear();
        return [new Date(fullYear, 0, 1), new Date(fullYear, 11, 31)];
      case 'Quarter':
      case 'quarters':
      case 'Q':
        //assuming given date is start of a quarter
        var oldDate = new Date(date);
        date.setMonth(date.getMonth() + 3);
        date.setDate(0); // goes one day back to the end of the previous month
        return [oldDate, date];
      case 'Month':
      case 'months':
      case 'M':
        return [new Date(date.getFullYear(), date.getMonth(), 1), new Date(date.getFullYear(), date.getMonth() + 1, 0)];
      case 'Week':
      case 'weeks':
      case 'W':
        return [new Date(date.getFullYear(), date.getMonth(), date.getDate() - date.getDay()), new Date(date.getFullYear(), date.getMonth(), date.getDate() + (6 - date.getDay()))];
      case 'Day':
      case 'days':
      case 'D':
        date.setHours(0, 0, 0, 0);
        return [date, date];
      default:
        return null;
    }
  }

  function nextBinForDate(date, interval, isForward) {
    var d3TimeOffset = void 0,
        count = void 0;
    if (interval === 'Quarter') {
      d3TimeOffset = d3.time.month.offset;
      count = isForward ? 3 : -3;
    } else {
      d3TimeOffset = d3.time[interval.toLowerCase()].offset;
      count = isForward ? 1 : -1;
    }
    return d3TimeOffset(date, count);
  }

  function advanceMiddle(date, interval, isForward) {
    var nextBinDate = nextBinForDate(date, interval, isForward);
    var newTime = date.getTime() / 2 + nextBinDate / 2;
    return new Date(newTime);
  }

  function getBinEdges(interval, domain, customYearEndOffset) {
    var labelFn = tickLabelerFn(interval, Infinity, customYearEndOffset);
    var ticks = labelFn(domain[0], domain[1]);
    // Move all ticks forward by one bin, leave the first one alone
    var rightEdges = ticks.map(function (d) {
      return nextBinForDate(d, interval, true);
    });
    return rightEdges;
  }

  // TODO(tony) - Remove from here?? Decide once year has been done as well, but
  // the labeler does not need the custom offset
  function getLabelledTicks(interval, domain, centerLabels, centerForward, maxNumberOfLabels, customYearEndOffset) {
    var labelFn = tickLabelerFn(interval, maxNumberOfLabels, customYearEndOffset);
    var ticks = labelFn(domain[0], domain[1]);
    if (!centerLabels) {
      return ticks;
    } else {
      return ticks.map(function (tick) {
        return advanceMiddle(tick, interval, true);
      });
    }
  }
});
define('client/components/dashboard/graph-question-block', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: null,
    store: Ember.inject.service(),
    profileService: Ember.inject.service("profile"),
    onDoubleClick: function onDoubleClick() {},


    graph: Ember.computed("draggableItem", "profileService.profile", "profileService.campaign", "profileService.to", "profileService.from", function () {
      var graphTemplate = this.get("draggableItem"),
          profileService = this.get("profileService");
      return _emberData.default.PromiseObject.create({
        promise: graphTemplate.toGraph(profileService.getProperties('profile', "campaign")).then(function (graph) {
          return graph;
        })
      });
    })
  });
});
define('client/models/article-search', ['exports', 'ember-data', 'client/models/search'], function (exports, _emberData, _search) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var ArticleSearch = _search.default.extend({
    //
    // POSTING attributes
    //

    // paging
    per_page: _emberData.default.attr("number"),
    offset: _emberData.default.attr("number"),

    //
    // RECEIVING attributes
    //

    // paging
    record_count: _emberData.default.attr("number"),
    omit_interaction_padding: _emberData.default.attr('boolean'),

    // articles
    articles: _emberData.default.hasMany("article_summary", { async: false }),

    // scores
    social_filters_by_day: _emberData.default.attr("array"),
    news_filters_by_day: _emberData.default.attr("array"),

    total_hits: _emberData.default.attr("number"),
    syndicate_hits: _emberData.default.attr("number"),

    in_crawler: _emberData.default.attr('boolean', { defaultValue: true }),
    outsideSearchCriteria: _emberData.default.attr('boolean', { defaultValue: false }),
    unique_article_ids: _emberData.default.attr('boolean', { defaultValue: false }),

    time_zone: _emberData.default.attr("string"),

    content: Ember.computed.alias('articles'),
    // TODO(Tony): Seems odd to be setting the from and to dates on the article summary
    // only when we are paging data in
    pagedContent: Ember.computed("content", function () {

      var profile = this.get("profile_id"),
          campaign = this.get("campaign_id"),
          from = this.get("from"),
          to = this.get("to"),
          search_term = this.get("search_term");

      return this.get("content").map(function (a) {
        a.setProperties({
          profile_id: profile,
          campaign_id: campaign,
          from: from,
          to: to,
          search_term: search_term
        });
        return a;
      });
    })
  });
  ArticleSearch.pathForType = "articles";

  exports.default = ArticleSearch;
});
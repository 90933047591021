define('client/models/analytics-profile', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Model = _emberData.default.Model,
      attr = _emberData.default.attr,
      belongsTo = _emberData.default.belongsTo;


  var PROVIDERS = {
    GOOGLE: "GoogleAnalyticsProfile",
    OMNITURE: "OmnitureAnalyticsProfile"
  };

  var AnalyticsProfile = Model.extend({
    analytics_provider: attr("string"),
    use_caching_layer: attr("boolean"),
    analytics_account: attr("string"),
    ga_wpid: attr("string"),
    event_type_cd: attr("number"),

    remote_analytic: belongsTo('remote-analytic', { async: true }),

    remote_metrics: Ember.computed.alias('remote_analytic.remote_metrics'),
    remote_analytics_goals: Ember.computed.alias('remote_analytic.remote_analytics_goals'),
    remote_analytics_events: Ember.computed.alias('remote_analytic.remote_analytics_events'),

    isGA: Ember.computed.equal("analytics_provider", PROVIDERS.GOOGLE),
    isGoogle: Ember.computed.alias('isGA'),

    isOmniture: Ember.computed.equal("analytics_provider", PROVIDERS.OMNITURE),
    isAdobe: Ember.computed.alias("isOmniture"),

    analytics_goals: _emberData.default.hasMany('analytics_goal', { async: false }),

    analytics_events: _emberData.default.hasMany('analytics_event', { async: false }),

    _metrics: Ember.computed.union("analytics_goals", "analytics_events"),
    metrics: Ember.computed.sort("_metrics", function (a, b) {
      var aDate = a && a.get("created_at") ? a.get("created_at") : new Date();
      var bDate = b && b.get("created_at") ? b.get("created_at") : new Date();
      return aDate.getTime() - bDate.getTime();
    }),

    availableRemoteMetrics: Ember.computed("metrics.@each.name", "remote_analytic.remote_metrics.@each.name", function () {
      var metrics = this.get("metrics"),
          remote = this.get("remote_metrics");

      if (metrics && remote) {
        return remote.reject(function (m) {
          return !!metrics.findBy("name", m.name);
        });
      }
    })
  });

  AnalyticsProfile.pathForType = "analytics_profiles";

  exports.default = AnalyticsProfile;
});
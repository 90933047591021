define('client/models/article-batch', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    articleIds: _emberData.default.attr('array'),
    spam: _emberData.default.attr('boolean', { defaultValue: false }), // Default: Article is not spam
    relevance: _emberData.default.attr('boolean', { defaultValue: true }), // Default: Article is relevant
    profileId: _emberData.default.attr('number'),
    campaignId: _emberData.default.attr('number'),
    articleSummaries: _emberData.default.hasMany('article-summary')
  });
});
define('client/controllers/research/publications', ['exports', 'ember-parachute', 'client/utils/locations-by-region', 'lodash'], function (exports, _emberParachute, _locationsByRegion, _lodash) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var PublicationsQP = new _emberParachute.default({
    sort: {
      as: 'sort',
      defaultValue: 'asc'
    },
    articles: {
      as: 'articles',
      defaultValue: []
    },
    reach: {
      as: 'reach',
      defaultValue: []
    },
    totalShares: {
      as: 'totalShares',
      defaultValue: []
    },
    averageShares: {
      as: 'averageShares',
      type: 'array',
      defaultValue: []
    },
    searchTerm: {
      as: 'searchTerm',
      type: 'string',
      defaultValue: ''
    },
    competitors: {
      as: 'competitors',
      type: 'array',
      defaultValue: []
    },
    locations: {
      as: 'locations',
      type: 'array',
      defaultValue: []
    }
  });

  exports.default = Ember.Controller.extend(PublicationsQP.Mixin, {

    LocationsByRegion: _locationsByRegion.default,

    profile: Ember.computed.alias('profileService.profile'),

    setup: function setup() {
      this._super();
      var qp = this.get('allQueryParams');
      // Setup publisher filter list to contain existing publishers from QP
      this.set('publisherFilterList', Ember.A([]).concat(this.get('allQueryParams.publishers')));
      this.get('redirect');
    },


    /**
     * Observer like property used to check profile permissions to view this feature.
     * Features are available once the profile model is loaded.
     * Observer is not used because property will not be triggered if profile data is available.
     */
    redirect: Ember.computed('profile.showAuthorIntelligence', function () {
      if (!this.get('profileService.profile.showAuthorIntelligence')) {
        this.transitionToRoute('research');
      }
    }),

    competitorsFilters: Ember.computed('profile.competitors', function () {
      var filterType = 'competitors';
      var qpCompetitors = this.get('allQueryParams')[filterType];
      var profileCompetiors = this.get('profile.competitors').mapBy('name');
      if (_lodash.default.difference(profileCompetiors, qpCompetitors).length !== 0) {
        qpCompetitors.clear();
      }
      if (Ember.isEmpty(profileCompetiors)) {
        qpCompetitors.clear();
      }
      return profileCompetiors || Ember.A();
    }),

    reachMin: Ember.computed('allQueryParams.reach', {
      get: function get(k) {
        var minFilter = this.get('allQueryParams.reach').find(function (filter) {
          return filter.startsWith('min');
        });
        return minFilter ? minFilter.replace('min:', '') : null;
      },
      set: function set(k, v) {
        return v;
      }
    }),

    reachMax: Ember.computed('allQueryParams.reach', {
      get: function get(k) {
        var maxFilter = this.get('allQueryParams.reach').find(function (filter) {
          return filter.startsWith('max');
        });
        return maxFilter ? maxFilter.replace('max:', '') : null;
      },
      set: function set(k, v) {
        return v;
      }
    }),

    totalSharesMin: Ember.computed('allQueryParams.totalShares', {
      get: function get(k) {
        var minFilter = this.get('allQueryParams.totalShares').find(function (filter) {
          return filter.startsWith('min');
        });
        return minFilter ? minFilter.replace('min:', '') : null;
      },
      set: function set(k, v) {
        return v;
      }
    }),

    totalSharesMax: Ember.computed('allQueryParams.totalShares', {
      get: function get(k) {
        var maxFilter = this.get('allQueryParams.totalShares').find(function (filter) {
          return filter.startsWith('max');
        });
        return maxFilter ? maxFilter.replace('max:', '') : null;
      },
      set: function set(k, v) {
        return v;
      }
    }),

    avgSharesMin: Ember.computed('allQueryParams.averageShares', {
      get: function get(k) {
        var minFilter = this.get('allQueryParams.averageShares').find(function (filter) {
          return filter.startsWith('min');
        });
        return minFilter ? minFilter.replace('min:', '') : null;
      },
      set: function set(k, v) {
        return v;
      }
    }),

    avgSharesMax: Ember.computed('allQueryParams.averageShares', {
      get: function get(k) {
        var maxFilter = this.get('allQueryParams.averageShares').find(function (filter) {
          return filter.startsWith('max');
        });
        return maxFilter ? maxFilter.replace('max:', '') : null;
      },
      set: function set(k, v) {
        return v;
      }
    }),

    publisherFilters: Ember.computed('allQueryParams.publishers', 'publisherFilterList', function () {

      var values = Ember.A([]);
      if (Ember.isPresent(this.get('publisherFilterList'))) {
        values = values.concat(this.get('publisherFilterList')).uniq();
      }
      if (Ember.isPresent(this.get('allQueryParams.publishers'))) {
        values = values.concat(this.get('allQueryParams.publishers')).uniq();
      }

      return values.sort();
    }),

    selectedAuthor: null,

    openNotes: false,

    modal: Ember.inject.service(),

    sortOptions: [{ name: 'Alphabetical A-Z', type: 'asc' }, { name: 'Alphabetical Z-A', type: 'desc' }],

    selectedSort: Ember.computed('allQueryParams.sort', function () {
      return this.get('sortOptions').findBy('type', this.get('allQueryParams.sort'));
    }),

    /**
     * Constructs an object that will be used to display selected locations.
     */
    locationFiltersOptions: Ember.computed('allQueryParams.locations', function () {
      var selectedLocations = {};
      if (this.get('allQueryParams.locations')) {
        selectedLocations = this.get('allQueryParams.locations').reduce(function (o, value) {
          return (o["lcn_" + value] = true) && o;
        }, {});
      }
      return selectedLocations;
    }),

    actions: {

      // Note: Deals with array query params
      onApplyFilter: function onApplyFilter(filterType, selectedValue) {
        var filter = this.get('allQueryParams')[filterType];
        if (filter.join('').includes('min') || filter.join('').includes('max')) {
          this.get(filterType).clear();
        }
        if (Ember.isEmpty(filter)) {
          this.get(filterType).pushObject(selectedValue);
        } else {
          if (this.get(filterType).includes(selectedValue)) {
            var filters = this.get(filterType).without(selectedValue);
            this.set(filterType, filters);
          } else {
            this.get(filterType).pushObject(selectedValue);
          }
        }
      },
      onApplyMinMaxFilter: function onApplyMinMaxFilter(filterType, min, max) {
        var filter = this.get('allQueryParams')[filterType];
        this.get(filterType).clear();
        if (min) {
          this.get(filterType).pushObject('min:' + min);
        }
        if (max) {
          this.get(filterType).pushObject('max:' + max);
        }
      },
      onApplyPublisher: function onApplyPublisher(publisher) {
        this.get('publisherFilterList').pushObject(publisher);
        this.send('onApplyFilter', 'publishers', publisher);
      },
      sortBy: function sortBy(sortOption) {
        this.set('sort', sortOption.type);
      },
      checkRegion: function checkRegion(region) {
        var ids = _locationsByRegion.default[region].mapBy('id');
        this.get('allQueryParams.locations').addObjects(ids);
      },
      uncheckRegion: function uncheckRegion(region) {
        var ids = _locationsByRegion.default[region].mapBy('id');
        this.get('allQueryParams.locations').removeObjects(ids);
      },
      toggleLocation: function toggleLocation(locationId) {
        var locations = this.get('allQueryParams.locations');
        if (locations.includes(locationId)) {
          locations.removeObject(locationId);
        } else {
          locations.addObject(locationId);
        }
      }
    }

  });
});
define('client/routes/settings/export-management/index', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    titleToken: 'Exports',

    setupController: function setupController(controller) {
      this._super.apply(this, arguments);
      this.controller.startPolling();
    },

    exit: Ember.on('deactivate', function () {
      this.controller.stopPolling();
    })
  });
});
define('client/mixins/page', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var Record = Ember.ObjectProxy.extend({
    isLoading: true
  });
  //
  // This mixin assumes a ember array controller
  //
  // TODO use ember-listview or ember-cloaked or ember-table
  var PageMixin = Ember.Mixin.create({

    initialLength: 1,
    perPage: 20,
    content: Ember.computed.alias('model'),

    isLoadingPage: false,
    _endOfList: false,

    lastRangeLoaded: null,

    searchDidChange: Ember.observer("search", function () {
      // Since this is a new search, when the data comes back clear previous data instead of appending
      this.set("_emptyOnLoad", true);
      // The search parameters just changed so you can't possibly be finished fetching data
      this.set("_endOfList", false);
      // Go do the fetch
      this.nextPage();
    }),

    search: Ember.computed({
      get: function get() {
        return null;
      },
      set: function set(k, v, old) {
        // remove events
        if (old && typeof old.off === 'function') {
          old.off("didCreate");
          old.off("didUpdate");
        }

        return v;
      }
    }),

    nextPage: function nextPage() {
      // Fetch more data if:
      // 1. We are not at the end of a list
      // 2. And there is some parameters for our search
      // 3. And we have not alrealy fetched this range
      if (!this.get("_endOfList") && this.get("search")) {
        var range = this._rangeToLoad(this.get("model.length"));
        var lastRangeLoaded = this.get('lastRangeLoaded');
        if (!lastRangeLoaded || lastRangeLoaded[0] !== range[0] || lastRangeLoaded[1] !== range[1]) {
          this._loadPage(range);
          this.set('lastRangeLoaded', range);
        }
      }
    },

    _rangeToLoad: function _rangeToLoad(index) {
      var perPage = this.get('perPage'),
          content = this.get('model'),
          start = index,
          end = index + 1;

      if (this._emptyOnLoad) {
        // reset last loaded range as we have a new search
        this.set('lastRangeLoaded', null);
        return [0, perPage];
      }

      // end should either be:
      // 1. perPage length or
      // 2. start index to first index of content when less than perPage
      while (end - start < perPage && !content[end + 1]) {
        end += 1;
      }
      return [start, end];
    },

    _loadPage: function _loadPage(range) {
      var start = range[0],
          end = range[1],
          search = this.get("search"),
          that = this;

      // TODO: Isn't this redundant with _endOfList?
      if (start >= end) {
        return;
      }

      this.set("isLoadingPage", true);

      search.set("offset", start);
      search.set("per_page", end - start);

      // TODO not sure save is optimal here, query?
      search.save().catch(function () {
        search.trigger("becameError");
      });
      search.one(search.get("isNew") ? "didCreate" : "didUpdate", function () {

        // If the search has changed after a request was already in-flight, we simply ignore
        // the returning data of the old request
        if (that.get('search') === this) {
          that._materialize(start, this);
        }
      });
      search.one("becameError", function () {
        var model = that.get('model');
        if (model) {
          model.replace(0, that.get("length"), []);
        }
        that.set("isLoadingPage", false);
      });
    },

    _materialize: function _materialize(start, search) {
      var content = search.get("pagedContent") || search;
      var proxiedItems = content.map(function (record, index) {
        var proxy = null;
        if (!this.get('model').findBy("id", record["id"]) || this._emptyOnLoad) {
          proxy = this._proxyForIndex(index + start);
          proxy.set("content", record);
          proxy.set("isLoading", false);
          proxy.set("sortIndex", index + start);
        }
        return proxy;
      }, this).compact();

      if (content.length === 0) {
        this.set("_endOfList", true);
      }
      if (search.meta && search.meta.total) {
        this.set('totalCount', search.meta.total);
      }

      if (this._emptyOnLoad) {
        this._emptyOnLoad = false;
        var model = this.get('model');
        if (model) {
          model.replace(0, this.get("model.length"), proxiedItems);
        }
      } else {
        this.get('model').pushObjects(proxiedItems);
      }
      this.set("isLoadingPage", false);

      // if you have less than 20 entries, go to the next page, there might be some articles missing
      if (!this.get("_endOfList") && content.length < this.get('perPage')) {
        this.nextPage();
      }
    },

    _proxyForIndex: function _proxyForIndex(index) {
      var content = this.get("model"),
          proxy = content.objectAt(index);

      if (!proxy) {
        proxy = Record.create();
      }

      return proxy;
    }
  });
  //
  // Simple object proxy that is created while waiting for the server
  // to send back the real record.
  //


  exports.default = PageMixin;
});
define('client/routes/not-found', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    titleToken: 'Not Found',
    beforeModel: function beforeModel(transition) {
      if (transition.intent.url === '/login') {
        this.transitionTo('/'); // Redirect to the root route
      }
    }
  });
});
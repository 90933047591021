define('client/components/expanded-article-interactions-metric', ['exports', 'client/computed/index', 'client/components/article-metric'], function (exports, _index, _articleMetric) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var ExpandedArticleInteractionsMetricComponent = _articleMetric.default.extend({
    layoutName: 'components/expanded-article-metric',
    classNames: ["metric_interaction"],
    metricTitle: Ember.computed.alias("profile.nameTotalInteraction"),
    conversionsTitle: Ember.computed.alias("profile.nameInteraction"),
    iconClass: "metric-icon metric-interaction",
    visitorCounts: Ember.computed.alias("visitorMetric"),
    visitorCountsForDateRange: Ember.computed.alias("visitorMetricForDateRange"),

    tooltipTitle: Ember.computed('profile.nameCustomer', 'profile.nameInteraction', function () {
      var customers = this.get("profile.nameCustomer");
      var totalInteraction = 'Total ' + this.get('profile.nameInteraction');
      return totalInteraction + ' count is the total number of interactions taken by ' + customers + ' who came to your site as a result of PR activities.  ' + customers + ' may trigger multiple events in one session, but we only tally one instance per interaction type per session.\n' + totalInteraction + ' percentage is the ' + totalInteraction + ' count divided by the number of ' + customers + ' who came to your site as a result of this article.';
    }),

    formatter: function formatter(iCount, vCount) {
      iCount = iCount || 0;
      var percentage = vCount === undefined || vCount == 0 ? 0 : iCount / vCount;
      return iCount.toLocaleString() + " (" + _index.d3Format.percentage(percentage) + ")";
    },

    formattedMetricTotal: Ember.computed('expandedArticleShowsCurrentSelection', 'requestedMetric', 'requestedVisitorMetricTotal', function () {
      var iCount = this.get('requestedMetric');
      var vCount = this.get('requestedVisitorMetricTotal');
      return this.get('formatter')(iCount, vCount);
    }),

    formattedConversions: Ember.computed('expandedArticleShowsCurrentSelection', 'requestedConversions', 'requestedVisitorMetricTotal', function () {
      var conversions = this.get('requestedConversions');
      var vCount = this.get('requestedVisitorMetricTotal');
      var formatter = this.get('formatter');
      var formattedConversions = (conversions || []).map(function (d) {
        return {
          name: d.name,
          value: formatter(d.value, vCount)
        };
      });
      return formattedConversions;
    }),

    requestedConversions: Ember.computed('expandedArticleShowsCurrentSelection', 'conversions', 'conversionsForDateRange', function () {
      return this.get('expandedArticleShowsCurrentSelection') ? this.get('conversionsForDateRange') : this.get('conversions');
    }),

    requestedMetric: Ember.computed('expandedArticleShowsCurrentSelection', 'metric', 'metricForDateRange', function () {
      return this.get('expandedArticleShowsCurrentSelection') ? this.get('metricForDateRange') : this.get('metric');
    }),

    requestedVisitorMetricTotal: Ember.computed('expandedArticleShowsCurrentSelection', 'visitorMetric.total', 'visitorMetricForDateRange.total', function () {
      return this.get('expandedArticleShowsCurrentSelection') ? this.get('visitorMetricForDateRange.total') : this.get('visitorMetric.total');
    })
  });

  exports.default = ExpandedArticleInteractionsMetricComponent;
});
define('client/serializers/trend-social-count', ['exports', 'ember-data', 'active-model-adapter'], function (exports, _emberData, _activeModelAdapter) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var SocialCountSerializer = _activeModelAdapter.ActiveModelSerializer.extend({
    primaryKey: 'date'
  });

  exports.default = SocialCountSerializer;
});
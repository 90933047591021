define('client/queries/dashboard-tile', ['exports', 'client/queries/tile'], function (exports, _tile) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _tile.default.extend({

    additionalPostData: Ember.computed(function () {
      var arrayFilters = this.get("item.filters");
      var filters = {
        amp_twitter: true,
        amp_facebook: true,
        amp_gplus: true,
        amp_linkedin: true,
        amp_pinterest: true
      };

      arrayFilters.forEach(function (filter) {
        filters[filter] = true;
      });

      var languages = this.get('item.languages');
      if (languages && languages.length) {
        filters.languages = languages;
      }

      var tagSelection = this.get('item.tagFilterList');
      tagSelection.forEach(function (tag) {
        //to indicate tag negation, an array element comes
        //in the form 'tag_10:not'
        var not = tag.split(':');
        var str = 'tag_' + not[0];
        if (not.length > 1) {
          filters[str] = "not";
        } else {
          filters[str] = true;
        }
      });

      var locationSelection = this.get("item.locationFilterList");
      locationSelection.forEach(function (l) {
        filters[l] = true;
      });

      filters.whitelist_tag_ids = this.get("item.whitelistTagIds");
      filters.included_iptc_topics = this.get("item.profile.hasIptc") ? this.get("item.iptcTopicIds") : [];
      filters.influential = this.get("item.influential");
      filters.uninfluential = this.get("item.uninfluential");
      filters.relevance = this.get('item.relevance');
      filters.company_website_link = this.get('item.companyWebsiteLink');
      filters.article_links_contain = this.get('item.articleLinksContain');

      return {
        trend_grouping: this.get("item.trendGrouping"),
        filters: filters,
        include_visitors: true
      };
    })

  });
});
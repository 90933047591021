define("client/components/modal-panel", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNameBindings: [":modal-panel"],

    close: function close() {},


    actions: {
      close: function close() {
        this.close();
      }
    }
  });
});
define("client/components/sales/keyword-form", ["exports", "client/components/sales/search-seed-form"], function (exports, _searchSeedForm) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _searchSeedForm.default.extend({
    classNames: ["keyword-form"],

    createForBulk: function createForBulk(model, name) {
      var data = model.getProperties("profile");
      data.name = name;
      return this.store.createRecord("sales/keyword", data);
    }
  });
});
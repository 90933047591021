define("client/helpers/format-number", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.formatNumber = formatNumber;

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
        arr2[i] = arr[i];
      }

      return arr2;
    } else {
      return Array.from(arr);
    }
  }

  function formatNumber(_ref, number, format) {
    var _ref$undefinedStr = _ref.undefinedStr,
        undefinedStr = _ref$undefinedStr === undefined ? "-" : _ref$undefinedStr,
        _ref$suffix = _ref.suffix,
        suffix = _ref$suffix === undefined ? "" : _ref$suffix,
        _ref$prefix = _ref.prefix,
        prefix = _ref$prefix === undefined ? "" : _ref$prefix;

    if (number === null || number === undefined) {
      return undefinedStr;
    }
    var str = d3.format(format)(number);
    switch (str[str.length - 1]) {
      case "G":
        return str.slice(0, -1) + "B";
    }
    return "" + prefix + str + suffix;
  }

  exports.default = Ember.Helper.helper(function (params, hash) {
    return formatNumber.apply(undefined, [hash].concat(_toConsumableArray(params)));
  });
});
define('client/components/notification-bar', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['notification-bar'],
    classNameBindings: ['type', 'showMessage:has-message'],

    source: Ember.computed.oneWay('notifyService'),
    type: Ember.computed.alias('latestMessage.type'),

    messages: null,
    maxMessages: 1,
    numberOfMessages: Ember.computed.alias('messages.length'),
    messagesLeft: Ember.computed('numberOfMessages', function () {
      return this.get('numberOfMessages') - 1;
    }),

    messageDisplayTime: 10000,
    hasMessage: Ember.computed.gt('numberOfMessages', 0),
    showMessage: false,
    delay: 270,

    init: function init() {
      this._super.apply(this, arguments);

      this.set('messages', Ember.A());
      this.get('source').setTarget(this);
    },

    latestMessage: Ember.computed('numberOfMessages', function () {
      return this.get('messages').objectAt(this.get('messages').length - 1);
    }),

    removeCurrentMsg: function removeCurrentMsg() {
      this.set('showMessage', false);

      if (this.get('messages').length > 1) {
        Ember.run.later(this, function () {
          this.set('showMessage', true);
          Ember.run.later(this, function () {
            if (this.get('messages').length > 0) {
              this.get('messages').removeAt(this.get('messages').length - 1);
              this.deleteLater();
            }
          }, this.get('delay') / 2);
        }, this.get('delay'));
      } else {
        Ember.run.later(this, function () {
          if (this.get('messages').length > 0) {
            this.get('messages').removeAt(this.get('messages').length - 1);
          }
        }, this.get('delay'));
      }
    },

    deleteLater: function deleteLater() {
      Ember.run.debounce(this, this.removeCurrentMsg, this.get('messageDisplayTime'));
    },

    show: function show(message, type) {
      //if msg already shown hide that one
      //then display the new one
      if (this.get('showMessage')) {
        this.set('showMessage', false);
        Ember.run.later(this, function () {
          this.set('showMessage', true);
          Ember.run.later(this, function () {
            if (this.get('messages.length') === this.get('maxMessages')) {
              this.get('messages').removeAt(0);
            }
            this.get('messages').pushObject({ message: message, type: type });
          }, this.get('delay') / 2);
        }, this.get('delay'));
      } else {
        this.set('showMessage', true);
        Ember.run.later(this, function () {
          this.get('messages').pushObject({ message: message, type: type });
        }, this.get('delay'));
      }

      this.deleteLater();
    },

    actions: {
      close: function close() {
        this.removeCurrentMsg();
      }
    }
  });
});
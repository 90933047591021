define('client/models/impact-score-product-name', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var ImpactScoreProductName = _emberData.default.Model.extend({
    name: _emberData.default.attr("string"),
    profileImpactScoreSetting: _emberData.default.belongsTo('profile-impact-score-setting')
  });
  //ImpactScoreProductName.pathForType = "impact_score_product_names";

  exports.default = ImpactScoreProductName;
});
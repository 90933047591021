define('client/models/user', ['exports', 'ember-data', 'ember-api-actions', 'moment-timezone'], function (exports, _emberData, _emberApiActions, _momentTimezone) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var User = _emberData.default.Model.extend({
    first_name: Ember.computed('name', {
      get: function get() {
        var v,
            k = this.get("name");
        if (k) {
          v = k.split(" ")[0];
        }
        return v;
      },
      set: function set(k, v) {
        this.set("name", v + " " + this.get("last_name"));
        return v;
      }
    }),

    last_name: Ember.computed('name', {
      get: function get() {
        var v,
            k = this.get("name");
        if (k) {
          v = k.split(" ")[1];
        }
        return v;
      },
      set: function set(k, v) {
        this.set("name", this.get("first_name") + " " + v);
        return v;
      }
    }),

    name: _emberData.default.attr("string"),
    email: _emberData.default.attr("string"),
    role: _emberData.default.attr("string"),
    permissions: _emberData.default.hasMany("permission"),
    currentPassword: _emberData.default.attr("string"),
    password: _emberData.default.attr("string"),
    passwordConfirmation: _emberData.default.attr("string"),
    timezone: _emberData.default.attr("string"),
    isSuperAdmin: _emberData.default.attr("boolean"),
    isSalesUser: _emberData.default.attr("boolean"),
    created_at: _emberData.default.attr("number"),
    account_id: _emberData.default.attr("number"),
    account_name: _emberData.default.attr("string"),
    serveLatestClient: _emberData.default.attr("boolean"),
    betaUser: _emberData.default.attr('boolean'),
    isBetaUser: Ember.computed.alias('betaUser'),
    isEngineer: _emberData.default.attr('boolean'),
    dashToken: _emberData.default.attr("string"),
    defaultDateRange: _emberData.default.attr("string"),
    intercomHash: _emberData.default.attr("string"),

    isSuperAdminEngineer: Ember.computed.and('isAirprAdmin', 'isEngineer'),

    isAirprAdmin: Ember.computed.or('isSuperAdmin', 'isSalesUser'),

    filterSets: _emberData.default.hasMany("filter-set", { async: true, inverse: 'user' }),
    analyzeGraph: _emberData.default.belongsTo('graph'),
    analyzeArticleList: _emberData.default.belongsTo('article-list'),
    customFilterSet: _emberData.default.belongsTo("filter-set", { inverse: null }),
    currentProfile: _emberData.default.belongsTo("profile", { inverse: null }),
    currentCampaign: _emberData.default.belongsTo("campaign", { inverse: null }),
    corporates: _emberData.default.hasMany("profile"),
    defaultProfile: _emberData.default.belongsTo("profile", { async: true }),
    emailNewsletterCart: _emberData.default.belongsTo('email-newsletter-cart', { inverse: null }),

    setCurrentProfile: (0, _emberApiActions.memberAction)({ path: 'set_current_profile', type: 'POST' }),
    setCurrentCampaign: (0, _emberApiActions.memberAction)({ path: 'set_current_campaign', type: 'POST' }),

    tags: _emberData.default.hasMany("tag"),

    viewFullArticle: _emberData.default.attr('boolean'),
    aiArticleSummaryEnabled: _emberData.default.attr('boolean'),
    liteVersion: _emberData.default.attr('boolean'),
    fullVersion: Ember.computed.not('liteVersion'),
    socialListeningAccess: _emberData.default.attr('boolean'),

    sortedCorporates: Ember.computed("corporates.@each.company_name", function () {
      return this.get("corporates").sortBy("company_name");
    }),
    abbrTimezone: Ember.computed('currentUser.timezone', function () {
      return _momentTimezone.default.tz(this.get('timezone')).zoneAbbr();
    }),
    defaultProfileImage: Ember.computed('corporates.firstObject.image_url', 'defaultProfile.image_url', function () {
      return this.get('defaultProfile.image_url') || this.get('corporates.firstObject.image_url');
    })

  });
  User.pathForType = "users";

  exports.default = User;
});
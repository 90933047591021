define("client/utils/graph-dimensions", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var VALUE_DIMENSIONS = [{
    id: "coverage",
    name: "Article Count"
  }, {
    id: "score",
    name: "Onclusive Score"
  }, {
    id: "amplification",
    name: "Amplification"
  }];

  var LABEL_DIMENSIONS = [{
    id: "date",
    name: "Time"
  }, {
    id: "profile",
    name: "Competitors"
  }, {
    id: "amplification_label",
    name: "Amplification"
  }, {
    id: "channel_type",
    name: "Channels"
  }, {
    id: "content_type",
    name: "Publishers"
  }, {
    id: "target_media_outlets",
    name: "Target Media Lists"
  }, {
    id: "tag",
    name: "Tags"
  }, {
    id: "location",
    name: "Locations"
  }, {
    id: "neo",
    name: "NEO Messaging"
  }];

  // value dimension to article list sort map
  var GRAPH_ARTICLE_LIST_SORT_MAP = {
    amplification: "all_amplification",
    reach: "reach",
    readership: "readership",
    visitor: "visitors",
    interaction: "all_interaction",
    revenue: "revenue",
    formulaic_revenue: "formulaic_revenue",
    power_of_voice: "pov",
    share_of_voice: "date",
    power_of_voice_sent: "pov_rel_amp_sent",
    impact_score: "impact_score"
  };

  exports.VALUE_DIMENSIONS = VALUE_DIMENSIONS;
  exports.LABEL_DIMENSIONS = LABEL_DIMENSIONS;
  exports.GRAPH_ARTICLE_LIST_SORT_MAP = GRAPH_ARTICLE_LIST_SORT_MAP;
});
define('client/models/print-publication', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var Model = _emberData.default.Model.extend({

    publication: _emberData.default.attr("string"),
    country: _emberData.default.attr("string"),
    region: _emberData.default.attr("string"),
    subregion: _emberData.default.attr("string"),
    state: _emberData.default.attr("string"),
    mediaLogo: _emberData.default.attr("string"),

    displayRegion: Ember.computed("country", "state", function () {
      var _getProperties = this.getProperties("country", "state"),
          country = _getProperties.country,
          state = _getProperties.state;

      return [country, state].compact().join(", ");
    })
  });

  Model.pathForType = "print_publications";

  exports.default = Model;
});
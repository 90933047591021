define('client/components/reports/template-preview', ['exports', 'client/components/reports/report-share'], function (exports, _reportShare) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _reportShare.default.extend({
    useParent: false,

    previousPage: function previousPage() {
      if (this.get('reportService.chartApiHash')) {
        return;
      }

      var pages = this.get("report.sortedPages"),
          selection = this.get("selectedPage"),
          currentIndex = pages.indexOf(selection);
      var index = currentIndex - 1;
      if (index < 0) {
        index = pages.length - 1;
      }
      if (index >= 0) {
        this.send("selectPage", pages.objectAt(index));
      }
    },
    nextPage: function nextPage() {
      if (this.get('reportService.chartApiHash')) {
        return;
      }

      var pages = this.get("report.sortedPages"),
          selection = this.get("selectedPage"),
          currentIndex = pages.indexOf(selection);
      var index = currentIndex + 1;
      if (index >= pages.length) {
        index = 0;
      }
      if (index >= 0) {
        this.send("selectPage", pages.objectAt(index));
      }
    },


    selectedPage: Ember.computed("report.sortedPages.[]", {
      get: function get() {
        var report = this.get("report");
        var first = report.get("sortedPages.firstObject");
        if (first) {
          first.set("isSelected", true);
          first.set("report", report);
        }
        return first;
      },
      set: function set(k, v, old) {
        var report = this.get("report");
        if (old) {
          old.set("isSelected", false);
        }
        v.set("isSelected", true);
        v.set("report", report);
        return v;
      }
    })
  });
});
define('client/components/common/ui-search-box', ['exports', 'ember-click-outside/mixin'], function (exports, _mixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_mixin.default, {
    classNames: ['ui-search-box'],
    classNameBindings: ['disabled:u-opacity05'],
    placeholder: "",

    suggestions: null,
    recentSearches: null,
    displayProperty: null,

    showSuggestions: false,
    showEmptyInstructions: false,
    disabled: false,
    showRecentSearches: false,
    canClear: true,
    searchIcon: "left",

    onEnter: function onEnter() {},
    onBlur: function onBlur() {},
    onFocus: function onFocus() {},
    onChange: function onChange() {},
    onCancel: function onCancel() {},

    // For when you want to update only when a suggestion is chosen
    chooseOption: function chooseOption() {},
    clickOutside: function clickOutside(e) {
      this.closeOpenSuggestions();
    },
    closeOpenSuggestions: function closeOpenSuggestions() {
      if (!this.isDestroyed) {
        this.set('showRecentSearches', false);
        this.set("showSuggestions", false);
      }
    },
    debounceChange: function debounceChange() {
      this.get("onChange")();
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      Ember.run.next(this, this.addClickOutsideListener);
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);
      this.removeClickOutsideListener();
    },
    init: function init() {
      this._super.apply(this, arguments);
      this.set('suggestions', this.get('suggestions') || []);
    },


    actions: {
      onChange: function onChange(event, searchTerm) {
        this.get("onChange")(searchTerm);
      },
      onEnter: function onEnter(event, searchTerm) {
        this.get("onEnter")(searchTerm);
        this.closeOpenSuggestions();
      },
      onBlur: function onBlur() {
        this.get("onBlur")();
      },
      onFocus: function onFocus() {
        this.get("onFocus")();
        this.send('startEdit');
      },
      useSuggestion: function useSuggestion(searchTerm) {
        this.get('chooseOption')(searchTerm);
        this.closeOpenSuggestions();
      },
      clearSearch: function clearSearch() {
        this.get("onCancel")();
      },
      startEdit: function startEdit() {
        this.set("showSuggestions", true);
      },
      toggleRecentSearches: function toggleRecentSearches() {
        this.toggleProperty('showRecentSearches');
      }
    }
  });
});
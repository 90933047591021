define('client/mixins/settings-campaign', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var SettingsCampaignMixin = Ember.Mixin.create({
    needs: ['application', 'settings'],
    queryParams: ['type'],
    type: null,

    sortDate: "DESC",
    sortUrl: "",
    sortProfile: "",

    currentUserService: Ember.inject.service('current-user'),
    user: Ember.computed.alias('currentUserService.user'),

    profileService: Ember.inject.service('profile'),
    profile: Ember.computed.alias('profileService.profile'),

    campaigns: Ember.computed('profile', function () {
      var profile_id = this.get('profile.id');
      // currently all campaigns are loaded already from profiles api
      var campaigns = this.store.peekAll('campaign');
      return campaigns.filter(function (campaign) {
        return campaign.profile_id == profile_id;
      });
    }),

    campaign: Ember.computed('campaigns.[]', {
      get: function get() {
        return Ember.isPresent(this.get('campaigns')) ? this.get('campaigns.firstObject') : null;
      },
      set: function set(k, v) {
        return v;
      }
    }),

    _sorts: ["Date", "Url", "Profile"],

    _paramsDidChange: function _paramsDidChange() {
      if (!this.get('campaign') || !this.get('profile')) {
        return;
      }
      var type = this.get("type"),
          campaignId = this.get("campaign.id"),
          params = {
        sorts: {},
        profile_id: this.get('profile.id')
      };

      if (type !== null) {
        //0 is an acceptable query params
        params.type = +type;
      }

      if (campaignId) {
        params.campaign_id = campaignId;
      }
      this.get("_sorts").forEach(function (sort) {
        var sortVal = this.get("sort" + sort);
        if (sortVal) {
          params.sorts[sort.toLowerCase()] = sortVal;
        }
      }, this);

      this.set("resources.search", params);
    },

    paramsDidChange: Ember.observer("type", "profile", "campaign", "sortDate", "sortUrl", "sortProfile", function () {
      Ember.run.scheduleOnce("afterRender", this, "_paramsDidChange");
    }),

    actions: {
      removeSorts: function removeSorts() {
        this.beginPropertyChanges();
        this.get("_sorts").forEach(function (sort) {
          this.set("sort" + sort, "");
        }, this);
        this.endPropertyChanges();
      }
    }
  });

  exports.default = SettingsCampaignMixin;
});
define('client/components/editable-big-text', ['exports', 'bigtext', 'client/mixins/resize'], function (exports, _bigtext, _resize) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_resize.default, {
    classNameBindings: [":editable-big-text"],
    contentEditable: "true",
    max: 25,
    min: 9,
    value: "",
    done: function done() {},
    resizedText: function resizedText() {},


    bigText: Ember.on("resize", "didInsertElement", function (promise) {
      this.$().bigtext({
        maxfontsize: this.get("max"),
        minfontsize: this.get("min")
      });
      this.resizedText();
      if (promise) {
        promise();
      }
    }),

    actions: {
      done: function done() {
        this.done();
      },
      textChange: function textChange() {
        // needed for the text to show up first.
        Ember.run.later(this, function () {
          this.bigText();
        }, 10);
      }
    }
  });
});
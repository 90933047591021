define('client/mixins/rich-text-bold-default', ['exports', 'dompurify', 'squire-rte'], function (exports, _dompurify, _squireRte) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  window.DOMPurify = _dompurify.default;

  // this is all to allow having bold be default on Header and Header/Subheader RTEs
  var RichTextBoldDefaultMixin = Ember.Mixin.create({

    _createSquireEditor: function _createSquireEditor(body) {
      return new _squireRte.default(body, {
        blockAttributes: {
          style: 'font-family: ' + this.get('selectedFontFamily') + '; font-weight: bold;'
        },
        tagAttributes: {
          li: {
            style: 'font-family: ' + this.get('selectedFontFamily') + ';'
          }
        }
      });
    }
  });

  exports.default = RichTextBoldDefaultMixin;
});
define('client/models/article-entity', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    entityType: _emberData.default.attr('string'),
    frequency: _emberData.default.attr('number'),
    sentimentConfidence: _emberData.default.attr('number'),
    sentimentScore: _emberData.default.attr('number'),
    sentimentSource: _emberData.default.attr('string'),
    text: _emberData.default.attr('string')
  });
});
define('client/services/window-manager', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend(Ember.Evented, {
    init: function init() {
      this._super.apply(this, arguments);
      var that = this;
      Ember.$(window).on('resize', function () {
        Ember.run.debounce(that, "windowResized", 150);
      });
    },
    windowResized: function windowResized() {
      Ember.run.schedule("afterRender", this, function () {
        this.trigger("window:resize");
      });
    }
  });
});
define('client/mixins/resize', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    windowManagerService: Ember.inject.service("window-manager"),
    resizing: false,
    _resizeChildren: null,
    /**
     * Recursively traverse view tree looking for parent element that has a handler bound to
     */
    findResizableParentView: function findResizableParentView(parent) {
      if (Ember.isNone(parent)) {
        return null;
      }
      if (parent && !parent.has('resize')) {
        return this.findResizableParentView(parent.get('parentView'));
      }
      return parent;
    },
    /**
     * Bind resizeHandler either to the parent view or window
     */
    _setupResizeHandlers: Ember.on('didInsertElement', function () {
      var parent = this.findResizableParentView(this.get('parentView'));
      if (Ember.isNone(parent)) {
        // element doesn't have resizable views, so bind to the window
        this.get("windowManagerService").on("window:resize", this, "handleResize");
      } else {
        parent._resizeChildren = parent._resizeChildren || [];
        parent._resizeChildren.pushObject(this);
      }
    }),
    /**
     * Unbind from window if window binding was used
     */
    _removeResizeHandlers: Ember.on('willDestroyElement', function () {
      this.get("windowManagerService").off("window:resize", this, "handleResize");
      var parent = this.findResizableParentView(this.get('parentView'));
      if (parent) {
        parent._resizeChildren.removeObject(this);
      }
    }),

    /**
     * Triggers resize events
     * Promise allows to chain events so async operations happen in sequence
     * @param event
     * @param promise
     */
    handleResize: function handleResize(event, promise) {
      var _this = this;

      // if (!this.get('resizing')) {
      // this.set('resizing', true);
      // debugger
      // let the parent handle the first resize, then children.
      return new Ember.RSVP.Promise(function (done) {
        _this.trigger('resize', done);
      }).then(function () {

        if (_this._resizeChildren && _this._resizeChildren.length > 0) {

          var promises = _this._resizeChildren.map(function (child) {
            return new Ember.RSVP.Promise(function (done) {
              child.handleResize(event, done);
            });
          });

          Ember.RSVP.all(promises).then(function () {
            _this._endResize(event);
            if (promise) {
              promise();
            }
          });
        } else {
          _this._endResize(event);
          if (promise) {
            promise();
          }
        }
      });
      // }
    },

    /**
     * Ends resizing by triggering resizeEnd event
     * @param event
     * @private
     */
    _endResize: function _endResize(event) {
      if (this.get('isDestroyed')) {
        return;
      }
      this.set('resizing', false);
      if (this.has('resizeEnd')) {
        this.trigger('resizeEnd', event);
      }
    }
  });
});
define('client/components/common/ui-confirm', ['exports', 'ember-modal-service/components/modal', 'client/computed'], function (exports, _modal, _computed) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _modal.default.extend({
    classNames: ["ui-confirm"],

    size: (0, _computed.aliasWithDefault)("model.options.size", "sm"),
    prompt: Ember.computed.alias("model.options.prompt"),
    directHtmlContent: Ember.computed.alias("model.options.directHtmlContent"),

    question: Ember.computed.alias("model.options.question"),
    messages: Ember.computed.alias("model.options.messages"),

    ok: (0, _computed.aliasWithDefault)("model.options.ok", "yes"),
    okType: (0, _computed.aliasWithDefault)("model.options.okType", "primary"),

    showCancel: Ember.computed("model.options.showCancel", function () {
      return Ember.isPresent(this.get('model.options.showCancel')) ? this.get('model.options.showCancel') : true;
    }),

    cancel: (0, _computed.aliasWithDefault)("model.options.cancel", "Cancel")
  });
});
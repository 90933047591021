define('client/components/expanded-article-visitors-metric', ['exports', 'client/components/article-metric', 'client/computed/index'], function (exports, _articleMetric, _index) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var TOOLTIP_TEXT = 'The number of potential customers going directly from the\narticle to your site as well as potential customers that have reached your site\nindirectly as a result of this article.';

  var ExpandedArticleVisitorsMetricComponent = _articleMetric.default.extend({
    classNames: ["metric-visitors", "no-hover-metric", "metric"],

    iconClass: "metric-icon metric-visitors",

    tooltipTitle: TOOLTIP_TEXT,

    metricTitle: Ember.computed.alias("profile.nameCustomer"),

    visitorCounts: Ember.computed.alias("metric"),

    visitorCountsForDateRange: Ember.computed.alias("metricForDateRange"),

    conversionsTitle: Ember.computed.alias("profile.nameCustomer"),

    showVisitorCountBreakdown: Ember.computed.alias("profile.hasVisitorCountBreakdown"),

    directVisitorCount: (0, _index.ternaryProperties)('expandedArticleShowsCurrentSelection', 'visitorCountsForDateRange.direct', 'visitorCounts.direct'),

    indirectVisitorCount: (0, _index.ternaryProperties)('expandedArticleShowsCurrentSelection', 'visitorCountsForDateRange.indirect', 'visitorCounts.indirect'),

    hasVisitorCounts: Ember.computed.or('directVisitorCount', 'indirectVisitorCount'),

    showVisitorCountTable: Ember.computed.and('showVisitorCountBreakdown', 'hasVisitorCounts'),

    visitorSourceTitle: Ember.computed("profile.analytics_profile.isGA", function () {
      var isGA = this.get("profile.analytics_profile.isGA");
      return isGA ? VISITOR_SOURCE_TITLES.GOOGLE : VISITOR_SOURCE_TITLES.ADOBE;
    }),

    visitorDirectTooltip: Ember.computed("visitorSourceTitle", "metricTitle", function () {
      return this.get("visitorSourceTitle") + ' - Refers to\n      ' + this.get("metricTitle") + ' that are directly tied back to a PR tactic\n      because of someone clicking on a link.';
    }),

    visitorIndirectTooltip: Ember.computed("metricTitle", function () {
      return 'Onclusive Attributed - Refers to estimated ' + this.get("metricTitle") + '\n      captured by Onclusive Analyst due to our publisher relationships and data\n      science techniques.';
    }),

    formattedMetricTotal: Ember.computed('expandedArticleShowsCurrentSelection', 'visitorCounts.total', 'visitorCountsForDateRange.total', function () {
      var total;
      if (this.get('expandedArticleShowsCurrentSelection')) {
        total = this.get('visitorCountsForDateRange.total');
      } else {
        total = this.get('visitorCounts.total');
      }
      return this.get('formatter')(total);
    })
  });

  var VISITOR_SOURCE_TITLES = {
    GOOGLE: "Google Analytics Attributed",
    ADOBE: "Adobe Analytics Attributed"
  };

  exports.default = ExpandedArticleVisitorsMetricComponent;
});
define('client/models/email-digest', ['exports', 'ember-data', 'ember-cp-validations'], function (exports, _emberData, _emberCpValidations) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var Validations = (0, _emberCpValidations.buildValidations)({
    'recipients.[]': (0, _emberCpValidations.validator)('length', {
      min: 1,
      message: 'You must have at least one email recipient.'
    }),
    'emailDigestSections.[]': (0, _emberCpValidations.validator)('length', {
      min: 1,
      message: 'You must have at least one email digest section.'
    }),
    'days.[]': (0, _emberCpValidations.validator)('length', {
      min: 1,
      message: 'Email digest needs to be at least one day.'
    })

  });

  var SUNDAY = 'sunday';
  var MONDAY = 'monday';
  var TUESDAY = 'tuesday';
  var WEDNESDAY = 'wednesday';
  var THURSDAY = 'thursday';
  var FRIDAY = 'friday';
  var SATURDAY = 'saturday';

  var DAYS = [SUNDAY, MONDAY, TUESDAY, WEDNESDAY, THURSDAY, FRIDAY, SATURDAY];

  var _digestFrequency = [{ name: "Daily", value: 0 }, { name: "Weekly", value: 1 }, { name: "Monthly", value: 2 }, { name: "Quarterly", value: 3 }, { name: "Annually", value: 4 }, { name: "Every Weekday", value: 5 }];

  var _digestSort = [{ name: "Date", value: 0 }, { name: "Visitors", value: 1 }, { name: "AirPR Score", value: 2 }, { name: "Revenue", value: 3 }, { name: "Twitter", value: 10 }, { name: "Facebook", value: 11 }, { name: "Google+", value: 12 }, { name: "LinkedIn", value: 13 }];

  var EmailDigest = _emberData.default.Model.extend(Validations, {

    user: _emberData.default.belongsTo("user"),
    subject: _emberData.default.attr("string"),
    frequency_cd: _emberData.default.attr("number", { defaultValue: 1 }),
    recipients: _emberData.default.attr("array", { defaultValue: function defaultValue() {
        return [];
      } }),
    unsubscribedEmails: _emberData.default.attr("array", { defaultValue: function defaultValue() {
        return [];
      } }),
    sendEmpty: _emberData.default.attr('boolean', { defaultValue: false }),
    showOverview: _emberData.default.attr("boolean", { defaultValue: true }),
    showStatsLinks: _emberData.default.attr("boolean", { defaultValue: true }),
    days: _emberData.default.attr("array", { defaultValue: function defaultValue() {
        return Ember.A([MONDAY]);
      } }),
    sendAt: _emberData.default.attr('string', { defaultValue: '11:00 am' }),
    timeZone: _emberData.default.attr('string'),
    updatedAt: _emberData.default.attr("isodate"),
    hideDuplicateArticles: _emberData.default.attr('boolean'),
    emailDigestSections: _emberData.default.hasMany("email-digest-section", { async: true, inverse: "email_digest" }),

    sunday: Ember.computed('days.[]', function () {
      return this.get('days').indexOf(SUNDAY) > -1;
    }),
    monday: Ember.computed('days.[]', function () {
      return this.get('days').indexOf(MONDAY) > -1;
    }),
    tuesday: Ember.computed('days.[]', function () {
      return this.get('days').indexOf(TUESDAY) > -1;
    }),
    wednesday: Ember.computed('days.[]', function () {
      return this.get('days').indexOf(WEDNESDAY) > -1;
    }),
    thursday: Ember.computed('days.[]', function () {
      return this.get('days').indexOf(THURSDAY) > -1;
    }),
    friday: Ember.computed('days.[]', function () {
      return this.get('days').indexOf(FRIDAY) > -1;
    }),
    saturday: Ember.computed('days.[]', function () {
      return this.get('days').indexOf(SATURDAY) > -1;
    }),

    allViews: Ember.computed("emailDigestSections.@each.views", function () {
      return this.get("emailDigestSections").mapBy("views").flatten();
    }),

    profileNames: Ember.computed("allViews.@each.activeProfileName", function () {
      return this.get("allViews").mapBy("activeProfileName").uniq();
    }),

    humanRecipients: Ember.computed("recipients.[]", function () {
      return this.get("recipients").join(", ");
    }),

    isWeekly: Ember.computed('frequency', function () {
      return this.get('frequency') === 'Weekly';
    }),

    // frequency name
    frequency: Ember.computed("frequency_cd", function () {
      var freq = this.get("frequency_cd");

      return _digestFrequency.find(function (f) {
        return f.value === freq;
      }).name;
    }),

    toggleDay: function toggleDay(day) {
      var days = this.get('days');
      if (this.get(day)) {
        days.removeObject(day);
      } else {
        days.addObject(day);
      }
    }
  });

  EmailDigest.pathForType = "email_digests";

  exports.default = EmailDigest;
});
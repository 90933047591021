define('client/models/added-link', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var AddedLink = _emberData.default.Model.extend({
    ajax: Ember.inject.service(),

    profile: _emberData.default.belongsTo("profile"),
    entity_id: _emberData.default.attr("string"),
    url: _emberData.default.attr("string"),
    timestamp: _emberData.default.attr("date"),
    added_date: _emberData.default.attr("date"),
    date_reliable: _emberData.default.attr("boolean"),
    entity_processed: _emberData.default.attr("boolean"),

    showAsValid: Ember.computed("date_reliable", "entity_processed", function () {
      if (this.get("entity_processed")) {
        return this.get("date_reliable");
      }
      return true;
    }),

    // ACTIONS

    removeLink: function removeLink() {
      return this.get('ajax').del("/v2/api/profile_links/" + this.get("id"), {
        data: {
          profile_id: this.get("profile.id")
        }
      });
    }

  });
  AddedLink.pathForType = "added_links";

  exports.default = AddedLink;
});
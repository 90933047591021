define('client/components/horizontal-slider', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var HorizontalSliderComponent = Ember.Component.extend({
    classNameBindings: [":h-slider"],
    translateX: 0,
    showSliders: true,
    showLeftSlider: false,
    showRightSlider: true,
    content: null,

    didInsertElement: function didInsertElement() {
      var that = this;
      this.notifyPropertyChange("contentWidth");
      this.windowManagerService.on("window:resize", function () {
        that.notifyPropertyChange("contentWidth");
      });
    },

    contentLengthDidChange: Ember.observer("content.length", function () {
      var that = this,
          content = this.get("content");
      this.set("translateX", 0);
      this.notifyPropertyChange("contentWidth");

      //  listen for events on the content
      if (content) {
        content.forEach(function (c) {
          if (c.on) {
            c.on("show:view", that, "showContent");
          }
        });
      }
    }),

    rightSliderChange: Ember.observer("contentWidth", function () {
      this.set("showRightSlider", this.get("showSliders"));
    }),

    showContent: function showContent(content) {
      if (this.get("content") !== undefined) {
        var index = this.get("content").indexOf(content);
        if (index >= 0) {
          if (index === this.get("content.length") - 1) {
            this.notifyPropertyChange("contentWidth");
            this._showContent(index, true);
          } else {
            this._showContent(index, false);
          }
        }
      }
    },

    _showContent: function _showContent(index, last) {
      var sliderContainer = this.$(".h-slider-row > div");
      if (!sliderContainer) {
        return;
      }
      var view = Ember.$(sliderContainer[index]),
          pos = view.position().left,
          width = view.outerWidth() + parseInt(view.css("marginLeft")) + parseInt(view.css("marginRight")),
          elW = this.$(".h-slider-bd").width(),
          tx = this.get("translateX");

      if (pos === 0 && last) {
        // last one
        pos = this.get("contentWidth") - Math.floor(width / 2);
      }

      var rightViewSide = pos + width,
          rightSide = elW - tx;

      if (rightViewSide > rightSide) {
        var x = tx - (rightViewSide - rightSide);
        this.set("translateX", x);
        if (x === 0) {
          this.set("showLeftSlider", false);
        } else {
          this.set("showLeftSlider", this.get("showSliders"));
        }

        if (last) {
          this.set("showRightSlider", false);
        } else {
          this.set("showRightSlider", this.get("showSliders"));
        }
      }
    },

    //
    // calculate the content from the embedded elements
    //
    contentWidth: Ember.computed(function () {
      Ember.run.schedule("afterRender", this, function () {
        if (this._state !== "inDOM") {
          return 0;
        }
        var row = this.$(".h-slider-row"),
            width = 1,
            parentWidth = this.$(".h-slider-bd").width();
        row.children("div").each(function () {
          width = realWidth(Ember.$(this), width);
        });
        if (width <= parentWidth) {
          width = parentWidth + 2;
          this.set("showSliders", false); // eslint-disable-line
        } else {
          this.set("showSliders", true); // eslint-disable-line
        }
        if (width !== this._width) {
          this._width = width;
          this.notifyPropertyChange("contentWidth");
        }
        this.notifyPropertyChange("contentStyle");
      });
      return this._width;
    }),

    _width: 10000,

    //
    // Use a transform to mimic a scroll
    //
    contentStyle: Ember.computed("contentWidth", "translateX", function () {
      var width = "width:" + this.get("contentWidth") + "px;",
          translation = ["-ms-transform:translate(", "px,0);-webkit-transform:translate(", "px,0);transform:translate(", "px,0);"].join(this.get("translateX"));

      return new Ember.String.htmlSafe([width, translation].join(""));
    }),

    actions: {
      scrollLeft: function scrollLeft() {
        this.notifyPropertyChange("contentWidth");
        Ember.run.schedule("afterRender", this, function () {
          var isXS = Ember.$(window).width() <= 768,
              el = Ember.$(this.get("element")),
              w = el.width(),
              elW = isXS ? realWidth(el.find(".h-slider-row div").first()) : w,
              x = this.get("translateX"),
              newX = x + elW;
          if (newX > 0) {
            newX = 0;
          }
          // If the new X position is all the way to the left, set the left slider to off
          // and set the right slider to whether sliders are shown at all.
          if (newX === 0) {
            this.set("showLeftSlider", false);
          } else {
            this.set("showLeftSlider", this.get("showSliders"));
          }
          this.set("showRightSlider", this.get("showSliders"));
          this.set("translateX", newX);
        });
      },
      scrollRight: function scrollRight() {
        this.notifyPropertyChange("contentWidth");
        Ember.run.schedule("afterRender", this, function () {
          var isXS = Ember.$(window).width() <= 768,
              el = Ember.$(".h-slider-bd", this.get("element")),
              w = el.width(),
              elW = isXS ? realWidth(el.find(".h-slider-row div").first()) : w,
              cW = this.get("contentWidth"),
              x = -this.get("translateX"),
              max = cW - elW,
              newX = x + elW;
          if (newX >= max) {
            newX = max;
          }
          // If the new X position is all the way to the right, set the right slider to off
          // and set the left slider to whether sliders are shown at all.
          if (newX === max) {
            this.set("showRightSlider", false);
          } else {
            this.set("showRightSlider", this.get("showSliders"));
          }
          this.set("showLeftSlider", this.get("showSliders"));
          this.set("translateX", -newX);
        });
      }
    }

  });
  var realWidth = function realWidth(child, width) {
    if (!width) {
      width = 0;
    }
    width += child.outerWidth();
    width += parseInt(child.css("marginLeft"));
    width += parseInt(child.css("marginRight"));
    return width;
  };

  exports.default = HorizontalSliderComponent;
});
define('client/initializers/profile', ['exports', 'client/mixins/profile-service-mixin'], function (exports, _profileServiceMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.initialize = initialize;
  function initialize() /* container, application */{
    // TODO: remove this and only extend controllers on a need basis
    // (since this is unavailable during testing anyway)
    Ember.Controller.reopen(_profileServiceMixin.default);
  }

  exports.default = {
    name: 'profile',
    initialize: initialize
  };
});
define('client/helpers/add-number', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.addNumber = addNumber;

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
        arr2[i] = arr[i];
      }

      return arr2;
    } else {
      return Array.from(arr);
    }
  }

  function addNumber(number, addition) {
    return number + addition;
  }

  exports.default = Ember.Helper.helper(function (params) {
    return addNumber.apply(undefined, _toConsumableArray(params));
  });
});
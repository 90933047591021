define('client/routes/settings/index', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    beforeModel: function beforeModel() {
      if (this.get('currentUser.liteVersion')) {
        this.replaceWith("settings.user");
      } else {
        this.replaceWith("settings.notifications");
      }
    }
  });
});
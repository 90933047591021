define('client/components/dashboard/article-list-card', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: "",

    draggable: true,
    isHovering: false,

    onClick: function onClick() {},


    actions: {
      updateArticleListTitle: function updateArticleListTitle(title) {
        this.get('articleList').set('displayTitle', title);
        if (this.get('articleList.hasDirtyAttributes')) {
          return this.get('articleList').save().then(function () {}, function (errors) {
            alert(errors);
          });
        }
      }
    }
  });
});
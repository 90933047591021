define('client/components/insights/article-recommendations', ['exports', 'client/computed/top-items'], function (exports, _topItems) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var TOP_ARTICLES = 3;

  exports.default = Ember.Component.extend({
    classNames: ['article-recommendations', 'recommendations'],
    showTop: true,

    topArticles: (0, _topItems.default)("recommendations", TOP_ARTICLES),
    articleIds: Ember.computed.mapBy('recommendations', 'articleId')
  });
});
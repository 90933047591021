define("client/components/common/ui-select-all-none", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ["select-all-none"],

    all: function all() {},
    none: function none() {}
  });
});
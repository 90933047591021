define('client/components/expanded-article-reach-metric', ['exports', 'client/components/article-metric'], function (exports, _articleMetric) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var tooltipTitle = 'Reach signals the size of the unique\npotential audience who could have been exposed to an article,\nnews release, etc. in a specific digital outlet and differs\nfrom Impressions. Reach does not guarantee that an individual\nactually consumed content. Onclusive provides SimilarWeb monthly unique\nvisitors for a website domain as the metric for Reach.';

  var ExpandedArticleReachMetricComponent = _articleMetric.default.extend({
    layoutName: 'components/expanded-article-reach-metric',
    classNames: ["metric_reach"],
    tooltipTitle: tooltipTitle,
    metricTitle: Ember.computed.alias("profile.nameReach"),
    iconClass: "metric-icon metric-reach",
    conversionsTitle: Ember.computed.alias("profile.nameReach"),
    formatter: function formatter(d) {
      if (d === undefined) {
        d = 0;
      }

      var prefix = d3.formatPrefix(d, 2);
      if (prefix.symbol === 'G') {
        prefix.symbol = 'B';
      }
      return d3.round(prefix.scale(d), 1) + prefix.symbol;
    }
  });

  exports.default = ExpandedArticleReachMetricComponent;
});
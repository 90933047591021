define('client/report-settings', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  // named via http://www.color-hex.com/color/<hexcode>
  // or as best they could be named

  // TODO: name the rest of the colors
  var COLORS = {
    AIRPR_BLUE: '#75b5c8',
    GREEN: '#b8ca75',
    ORANGE: '#d7903c',
    DARK_BLUE: '#5e8b9f',
    RED: '#bc5b53',
    TURQUOISE: '#72c09a',
    PURPLE: '#9e98ae',
    YELLOW: '#cfbb4f',
    FORESTGREEN: '#759a72',
    PINK: '#cd81a4',
    GREY60: '#999999',
    GRAY80: '#cccccc',
    BLACK: '#000000'
  };

  var DEFAULT_COLOR_SWATCHES = Object.values(COLORS);

  exports.COLORS = COLORS;
  exports.DEFAULT_COLOR_SWATCHES = DEFAULT_COLOR_SWATCHES;
});
define('client/components/common/ui-appear', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNameBindings: [":appear", "show:in"],

    show: false,

    didInsertElement: function didInsertElement() {
      Ember.run.later(this, function () {
        this.set("show", true);
      }, 100);
    }
  });
});
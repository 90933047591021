define('client/components/reports/grid-subitem', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['report-sub-toolbar-container'],
    classNameBindings: ['isSelected:report-subitem-selected'],
    reportService: null,
    connectedHoverClass: null,
    onStopEdit: function onStopEdit() {},


    isSelected: Ember.computed('reportService.selectedItem', 'reportService.isEdit', function () {
      return Ember.isEqual(this, this.get('reportService.selectedItem')) && this.get('reportService.isEdit');
    }),

    toolbarText: Ember.computed('isSelected', function () {
      return this.get('isSelected') ? this.get('selectedText') : this.get('hoverText');
    }),

    click: function click(e) {
      var _this = this;

      if (this.get('reportService.isReadOnly')) {
        return;
      }
      if (this._state !== "inDOM") {
        return;
      }
      // close all other before showing this new one.
      this.get('reportService').startEditing(this);
      Ember.run.schedule('afterRender', this, function () {
        this.$().find('input').focus();
      });
      Ember.$("body").on("keyup." + this.get("elementId"), function (event) {
        if (event.keyCode === 27) {
          _this.send("stopEditing");
        }
      });
      e.stopPropagation();
    },
    mouseDown: function mouseDown(e) {
      e.stopImmediatePropagation();
    },
    mouseUp: function mouseUp(e) {
      e.stopImmediatePropagation();
    },
    mouseEnter: function mouseEnter(e) {
      if (!this.get('isSelected')) {
        this._addConnectedClass();
      }
    },
    mouseLeave: function mouseLeave(e) {
      if (!this.get('isSelected')) {
        this._removeConnectedClass();
      }
    },


    isSelectedDidChange: Ember.observer('isSelected', function () {
      if (this.get('isSelected')) {
        this._addConnectedClass();
      } else {
        this._removeConnectedClass();
      }
    }),

    _removeConnectedClass: function _removeConnectedClass() {
      Ember.$('.' + this.get('connectedHoverClass')).removeClass('connected-hover');
    },

    _addConnectedClass: function _addConnectedClass() {
      Ember.$('.' + this.get('connectedHoverClass')).addClass('connected-hover');
    },

    actions: {
      stopEditing: function stopEditing() {
        this.get('reportService').stopEditing();
        this.onStopEdit();
      }
    }
  });
});
define('client/components/onboard-tip', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var TipComponent = Ember.Component.extend({
    classNameBindings: [":well", ":onboard-tip", "opened:onboard-tip_opened"],

    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      Ember.$("body").on("mouseenter.tip", "[data-tip]", Ember.$.proxy(this, "tipEnter"));
      Ember.$("body").on("mouseleave.tip", "[data-tip]", Ember.$.proxy(this, "tipLeave"));
      this.get("changeStepNotifier").on("changeStep", this, function () {
        this.set("opened", false);
      });
    },

    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);
      Ember.$("body").off("mouseenter.tip", "[data-tip]");
      Ember.$("body").off("mouseleave.tip", "[data-tip]");
      this.get("changeStepNotifier").off("changeStep");
    },

    tipEnter: function tipEnter(e) {
      var target = Ember.$(e.currentTarget);

      this.set("_title", target.data("tip-title"));
      this.set("_tip", target.data("tip"));
      this.set("opened", true);
      clearTimeout(this.timeout);
    },

    tipLeave: function tipLeave() {},

    _title: "",
    title: Ember.computed("_title", function () {
      var tip = this.get("_title");
      return tip && tip.replace("!type!", "Corporation");
    }),

    _tip: "",
    tip: Ember.computed("_tip", function () {
      var tip = this.get("_tip");
      return tip && tip.replace("!type!", "Corporation");
    })

  });

  exports.default = TipComponent;
});
define('client/models/report-items/line', ['exports', 'client/models/report-items/chart', 'client/models/report-item'], function (exports, _chart, _reportItem) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _chart.default.extend({
    groupOptionsMenuName: 'Series',
    groupDimension: (0, _reportItem.propWithDefaultValue)('groupDimension', function () {
      return ['profile'];
    })
  });
});
define('client/mixins/channel-filter', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var FILTER_DEFAULTS = {
    media_earned: true,
    linkedin_pulse: true,
    media_owned: false,
    press_release: false
  };

  var ChannelFilterMixin = Ember.Mixin.create({
    //override this downstream if the filters are saved in a different way
    // eg. email-digest-view.js
    savedFilters: Ember.computed.alias('_savedFilterSelection'),

    mediaEarnedDefault: Ember.computed('savedFilters.filters.media_earned', function () {
      return this._getChannelFilterDefault('media_earned');
    }),

    mediaOwnedDefault: Ember.computed('savedFilters.filters.media_owned', function () {
      return this._getChannelFilterDefault('media_owned');
    }),

    pressReleaseDefault: Ember.computed('savedFilters.filters.press_release', function () {
      return this._getChannelFilterDefault('press_release');
    }),

    contentFiltersAreDefault: Ember.computed('savedFilters', 'media_earned', 'linkedin_pulse', 'media_owned', 'press_release', function () {
      return this.get('media_earned') === this.get('mediaEarnedDefault') && this.get('media_owned') === this.get('mediaOwnedDefault') && this.get('press_release') === this.get('pressReleaseDefault');
    }),

    setFiltersFromSavedSelection: function setFiltersFromSavedSelection() {
      if (this.get('savedFilters')) {
        this._setChannelFilters();
      }
      this._super.apply(this, arguments);
    },

    resetFilters: function resetFilters() {
      this._setChannelFilters();
      this._super.apply(this, arguments);
    },

    _setChannelFilters: function _setChannelFilters() {
      this.setProperties({
        media_earned: this.get('mediaEarnedDefault'),
        media_owned: this.get('mediaOwnedDefault'),
        press_release: this.get('pressReleaseDefault')
      });
    },

    _getChannelFilterDefault: function _getChannelFilterDefault(filterName) {
      var savedFilterValue = this.get('savedFilters.filters.' + filterName);
      return Ember.isPresent(savedFilterValue) ? savedFilterValue : FILTER_DEFAULTS[filterName];
    },

    actions: {

      toggleMediaEarned: function toggleMediaEarned() {
        this.toggleProperty('media_earned');
      },

      toggleMediaOwned: function toggleMediaOwned() {
        this.toggleProperty('media_owned');
      },

      togglePressRelease: function togglePressRelease() {
        this.toggleProperty('press_release');
      }
    }
  });

  exports.default = ChannelFilterMixin;
});
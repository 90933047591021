define('client/components/save-search', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNameBindings: [':save-search', 'canSave::hide'],
    modal: Ember.inject.service(),
    isEditing: false,
    save: function save() {},


    isPrivate: Ember.computed("search.user_id", {
      get: function get() {
        return !!this.get('search.user_id');
      },
      set: function set(k, v) {
        this.set('search.user_id', v);
        return v;
      }
    }),

    canSave: Ember.computed("search.term", function () {
      return this.get("search.term.length") > 0;
    }),

    inputClass: Ember.computed("search.errors.name.length", function () {
      var inError = this.get("search.errors.name.length") > 0;
      return "form-control " + (inError ? "has-error" : "");
    }),

    click: function click(e) {
      e.stopImmediatePropagation();
    },

    actions: {
      newSearch: function newSearch() {
        this.set("isEditing", true);
      },
      cancelSearch: function cancelSearch() {
        this.set("search.isEditing", false);
        this.set("isEditing", false);
      },
      saveSearch: function saveSearch() {
        var search = this.get("search"),
            searchText = search.get('name'),
            that = this;

        searchText = searchText.trim();
        search.set('name', searchText);

        if (!searchText.length) {
          this.get('modal').open('common/ui-confirm', {
            prompt: 'Please enter a name for the search.',
            showCancel: false,
            ok: "OK",
            okType: "danger"
          });
          return;
        }

        if (!search.get("isSaving")) {
          search.one(search.get("isNew") ? "didCreate" : "didUpdate", function () {
            search.set("isEditing", false);
            that.set("isEditing", false);
            that.set("search", null);
          });

          this.save(this.get("search"), this.get("isPrivate"));
        }
      }
    }
  });
});
define('client/initializers/array-extensions', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.arrayExtensions = arrayExtensions;


  function flatten() {
    var r = [];
    this.forEach(function (el) {
      r.push.apply(r, Ember.isArray(el) ? el.toArray().flatten() : [el]);
    });
    return r;
  }

  function arrayExtensions() {
    Ember.ArrayProxy.prototype.flatten = Array.prototype.flatten = flatten;
  }

  exports.default = {
    name: 'array-extensions',
    initialize: arrayExtensions
  };
});
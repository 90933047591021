define('client/transforms/isodatetime', ['exports', 'ember-data', 'client/utils/-iso-format'], function (exports, _emberData, _isoFormat) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  //
  // Handle date JSON data from the backend
  //
  var IsodatetimeTransform = _emberData.default.Transform.extend({
    serialize: function serialize(value) {
      return value && typeof value !== "string" ? (0, _isoFormat.isoTZFormat)(value) : value;
    },
    deserialize: function deserialize(value) {
      return value ? d3.time.format.iso.parse(value) : value;
    }
  });

  exports.default = IsodatetimeTransform;
});
define('client/components/insights/insight-graph', ['exports', 'client/utils/chart-data-transformer', 'client/utils/time-series-labeler'], function (exports, _chartDataTransformer, _timeSeriesLabeler) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var POWER_OF_VOICE = 'power_of_voice';

  var CHART_TYPES = {
    IntervalRule: 'graph-column',
    CompetitorIntervalRule: 'graph-column',
    VSCompetitorRule: 'graph-row',
    // no chart for these
    TopRule: null,
    CompetitorTopRule: null
  };

  var CHART_VALUE_SETTINGS = {
    IntervalRule: 'intervalValues',
    CompetitorIntervalRule: 'competitorIntervalValues',
    VSCompetitorRule: 'competitorValues',
    // no chart for these
    TopRule: null,
    CompetitorTopRule: null
  };

  exports.default = Ember.Component.extend({
    classNames: ["insight-graph", "ui-graph"],

    insight: null,

    chartType: Ember.computed('insight.ruleType', 'insight.metric', function () {
      if (Ember.isEqual(this.get('insight.metric'), POWER_OF_VOICE)) {
        return 'graph-pie';
      }
      return CHART_TYPES[this.get('insight.ruleType')];
    }),

    fromDate: Ember.computed('insight.isInterval', 'insight.startDate', 'insight.recurType', function () {
      var insight = this.get('insight');
      var fromDate = this.get('insight.startDate');

      if (insight.get('isInterval')) {
        switch (insight.get('recurType')) {
          case 'weekly':
            fromDate = moment(fromDate).subtract(1, 'weeks');
            break;
          case 'monthly':
            fromDate = moment(fromDate).subtract(1, 'months');
            break;
          case 'quarterly':
            fromDate = moment(fromDate).subtract(3, 'months');
        }
        fromDate = fromDate.toDate();
      }
      return fromDate;
    }),

    interval: Ember.computed('insight.recurTypeNoun', {
      get: function get() {
        return this.get('insight.recurTypeNoun').toLowerCase();
      },
      set: function set(k, v) {
        return v;
      }
    }),

    dataFormatter: Ember.computed('insight.results', 'interval', function () {
      var insight = this.get('insight'),
          data = insight.get('chartData'),
          filterList = [],
          dataType = insight.get('metric'),
          graphValues = {};

      var fromDate = this.get('fromDate');
      graphValues[dataType] = 'sum';

      if (dataType === 'amplification') {
        filterList = [this.get('insight.chartAmplificationKey')];
      }
      var parseDate = d3.time.format('%Y-%m-%d').parse;

      var params = {
        apiData: {
          chart: data
        },
        graphValues: graphValues,
        filterList: filterList,
        graphDateBinType: this.get('interval').capitalize(),
        // Remove this?  currently insights are not using customFiscal year,
        // but they might need to
        customFiscalYearOffset: insight.get('profile.customFiscalYearOffset'),
        // customFiscalYearOffset: {month: 1, day: 1},
        dataType: dataType,
        fromDate: fromDate,
        toDate: insight.get("endDate"),
        labelParseMap: {
          toLabel: {
            date: parseDate,
            profile: function profile(datapoint) {
              return insight.getProfileName(datapoint.profile);
            }
          },
          fromLabel: {
            date: this.get('fromDateLabel')
          }
        }
      };

      params = Ember.merge(params, this.get('chartValues'));

      return _chartDataTransformer.default.create(params);
    }),

    chartValues: Ember.computed('insight.ruleType', 'insight.metric', function () {
      if (this.get('insight.metric') == 'power_of_voice') {
        return this.get('pieChartValues');
      }
      return this.get(CHART_VALUE_SETTINGS[this.get('insight.ruleType')]);
    }),

    fromDateLabel: Ember.computed(function () {
      var insight = this.get('insight');
      return function (binType, d) {
        var maxDate = insight.get('endDate');
        var minDate = insight.get('startDate');
        // const customYearEndOffset = this.get('item.profile.customFiscalYearOffset');
        var customYearEndOffset = { month: 1, day: 1 };
        var formatter = (0, _timeSeriesLabeler.formattedTime)(binType, { month: 1, day: 1 });
        var from = formatter.parse(d);
        var res = (0, _timeSeriesLabeler.getRoundDomainRange)({
          from: from,
          to: from,
          binType: binType,
          customYearEndOffset: customYearEndOffset
        });
        // in cases where the data is binned by year/quarter,
        // the from/to dates generated by getRoundDomainRange()
        // may extend beyond the date range of the chart, meaning
        // the articles in the list won't match the graph, so we
        // this we clamp the dates here.
        if (res.to > maxDate) {
          res.to = maxDate;
        }
        if (res.from < minDate) {
          res.from = minDate;
        }
        return res;
      };
    }),

    intervalValues: Ember.computed(function () {
      return {
        graphLabelKeys: ['date', 'profile'],
        valueDimension: [this.get('insight.metric')],
        labelDimension: ['date'],
        groupDimension: []
      };
    }),

    competitorIntervalValues: Ember.computed(function () {
      return {
        graphLabelKeys: ['date', 'profile'],
        valueDimension: [this.get('insight.metric')],
        labelDimension: ['date'],
        groupDimension: ['profile']
      };
    }),

    competitorValues: Ember.computed(function () {
      return {
        graphLabelKeys: ['profile', 'date'],
        valueDimension: [this.get('insight.metric')],
        labelDimension: ['profile'],
        groupDimension: []
      };
    }),

    pieChartValues: Ember.computed(function () {
      return {
        graphLabelKeys: ['profile'],
        valueDimension: [this.get('insight.metric')],
        labelDimension: ['profile'],
        groupDimension: []
      };
    }),

    formattedData: Ember.computed.alias('dataFormatter.formattedData'),
    legendNameOrderMap: Ember.computed(function () {
      return {};
    }),
    actions: {
      changeInterval: function changeInterval(interval) {
        this.set('interval', interval);
      }
    }
  });
});
define('client/models/owned-medium', ['exports', 'ember-data', 'moment', 'client/utils/date-format', 'ember-inflector'], function (exports, _emberData, _moment, _dateFormat2, _emberInflector) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var OwnedMedium = _emberData.default.Model.extend({
    url: _emberData.default.attr("string"),
    profile_id: _emberData.default.attr("number"),
    created_at: _emberData.default.attr("isodate"),
    name: Ember.computed.alias('url'),
    profile: _emberData.default.belongsTo(),

    dateAdded: Ember.computed('created_at', function () {
      var options = {
        format: 'MMM DD, YYYY'
      };
      return (0, _dateFormat2.default)(this.get('created_at'), options);
    })

  });
  OwnedMedium.pathForType = "owned_media";
  _emberInflector.default.inflector.irregular("owned_medium", "owned_media");

  exports.default = OwnedMedium;
});
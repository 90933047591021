define('client/services/browser', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({

    currentDragItem: null,

    dragDataType: Ember.computed(function () {
      if (this.get('isIE')) {
        return 'Text';
      } else {
        return 'text/data';
      }
    }),

    isIE: Ember.computed(function () {
      return window.navigator.userAgent.indexOf('MSIE ');
    })

  });
});
define('client/models/remote-analytic', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var RemoteAnalytic = _emberData.default.Model.extend({

    remote_analytics_goals: _emberData.default.attr("array"),
    remote_analytics_events: _emberData.default.attr("array"),

    remote_metrics: Ember.computed("remote_analytics_goals.@each.name", "remote_analytics_events.@each.name", function () {
      var goals = this.get("remote_analytics_goals"),
          events = this.get("remote_analytics_events");
      if (!goals || !events) {
        return [];
      }

      goals = goals.filterBy("active", true).map(function (g) {
        return {
          id: g.id,
          name: g.name,
          display_name: g.display_name,
          type: "goal"
        };
      });
      events = events.map(function (e) {
        return {
          id: e.eventName,
          name: e.eventName,
          display_name: e.eventLabel,
          type: "event"
        };
      });

      return goals.concat(events);
    })
  });

  RemoteAnalytic.pathForType = "remote_analytics";

  exports.default = RemoteAnalytic;
});
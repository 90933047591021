define('client/services/host', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({

    appRoot: Ember.computed(function () {
      var host = window.Client.server_base_route || window.location.host;
      return window.location.protocol + '//' + host;
    }),

    adminEntityMonitoringPage: function adminEntityMonitoringPage(id) {
      return this.get('appRoot') + '/admin/entity_monitoring?per_id=' + id;
    }

  });
});
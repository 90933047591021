define('client/components/research/top-articles-card', ['exports', 'client/mixins/research/top-articles'], function (exports, _topArticles) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ["research-card", "top-articles-card"],

    profileService: Ember.inject.service('profile'),

    sortOptions: Ember.computed(function () {
      return _topArticles.SORT_BY;
    }),
    sortByName: Ember.computed("sortBy", "sortOptions", function () {
      return this.get("sortOptions")[this.get("sortBy")];
    }),

    profile: Ember.computed.alias('profileService.profile'),
    competitors: Ember.computed.alias('profileService.profile.competitors'),
    competitorsSortKey: null,
    sortedCompetitors: Ember.computed.sort("competitors", "competitorsSortKey"),
    selectedProfile: null,

    allowShowCompetitors: false,
    showCompetitorButton: Ember.computed.and('allowShowCompetitors', 'competitors.length'),
    showCompetitors: false,

    onProfileChange: function onProfileChange() {},
    init: function init() {
      this._super.apply(this, arguments);
      this.set('competitorsSortKey', this.get('competitorsSortKey') || ["name:asc"]);
    },


    actions: {
      toggleShowCompetitors: function toggleShowCompetitors() {
        this.toggleProperty('showCompetitors');
        if (this.get('showCompetitors')) {
          this.send('setSelectedProfile', this.get('profile'));
        } else {
          this.get('onProfileChange')(null);
        }
      },
      setSelectedProfile: function setSelectedProfile(profile) {
        this.set('selectedProfile', profile);
        this.get('onProfileChange')(profile);
      }
    }
  });
});
define('client/mixins/draggable', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var DraggableMixin, jQuery;
  jQuery = window.jQuery;

  window.Emberella = window.Emberella || {};

  /*
    `Emberella.DraggableMixin` adds drag event handling to a view class.
  
    This mixin is rough around the edges and is not verified to work
    across browsers.
  
    @class DraggableMixin
    @namespace Emberella
   */

  DraggableMixin = Ember.Mixin.create({

    /*
      @property isDraggable
      @type Boolean
      @default true
      @final
     */
    isDraggable: true,

    /*
      A list of element attributes to keep in sync with properties of this
      view instance.
       @property attributeBindings
      @type Array
      @default ['draggable']
     */
    attributeBindings: ['draggable'],

    /*
      Set draggable attribute value of host element.
       @property draggable
      @type String
      @default 'true'
     */
    draggable: 'true',

    /*
      The class name to apply to the element being dragged.
       @property draggingClass
      @type String
      @default 'dragging'
     */
    draggingClass: 'dragging',
    init: function init() {
      if (!Ember.get(window.Emberella, '_draggableView')) {
        Ember.set(window.Emberella, '_draggableView', null);
      }
      return this._super();
    },

    /*
      Handle the start of a drag interaction. (DOM Event)
       @event dragStart
     */
    dragStart: function dragStart(e) {
      var $target;
      $target = this.$();
      $target.addClass(Ember.get(this, 'draggingClass'));
      e.dataTransfer.effectAllowed = 'copy';
      e.dataTransfer.setData('Text', Ember.guidFor(this));
      Ember.set(window.Emberella, '_draggableView', this);
      var offset = this.$().offset();
      this.startPoint = {
        x: e.originalEvent.pageX,
        y: e.originalEvent.pageY,
        top: offset.top,
        left: offset.left
      };
      return this.trigger('didDragStart', e);
    },

    /*
      Handle the end of a drag interaction. (DOM Event)
       @event dragEnd
     */
    dragEnd: function dragEnd(e) {
      var dragOverClass, dragOverSelector, draggingClass, draggingSelector;
      Ember.set(window.Emberella, '_draggableView', null);
      draggingClass = Ember.get(this, 'draggingClass');
      dragOverClass = Ember.get(this, 'dragOverClass');
      if (draggingClass != null && jQuery.trim(draggingClass) !== '') {
        draggingSelector = ['.', draggingClass].join('');
        jQuery(draggingSelector).removeClass(draggingClass);
      }
      if (dragOverClass != null && jQuery.trim(dragOverClass) !== '') {
        dragOverSelector = ['.', dragOverClass].join('');
        jQuery(dragOverSelector).removeClass(dragOverSelector);
      }
      return this.trigger('didDragEnd', e);
    },

    didDragStart: function didDragStart() {},
    didDragEnd: function didDragEnd() {}
  });

  exports.default = DraggableMixin;
});
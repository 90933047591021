define("client/helpers/format-twitter-date", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.formatTwitterDate = formatTwitterDate;

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
        arr2[i] = arr[i];
      }

      return arr2;
    } else {
      return Array.from(arr);
    }
  }

  function formatTwitterDate(twitterDate) {
    if (!twitterDate) {
      return "";
    }
    return d3.time.format("%H:%M %p - %d %b %Y")(twitterDate);
  }

  exports.default = Ember.Helper.helper(function (params) {
    return formatTwitterDate.apply(undefined, _toConsumableArray(params));
  });
});
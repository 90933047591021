define('client/controllers/settings/excluded-domains/index', ['exports', 'client/mixins/corporate', 'client/mixins/profile-link-filter-controller', 'client/models/profile-link-filter'], function (exports, _corporate, _profileLinkFilterController, _profileLinkFilter) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var CorporateBlockedController = Ember.Controller.extend(_corporate.default, _profileLinkFilterController.default, {
    type: _profileLinkFilter.ProfileLinkFilterTypes.BLACKLIST
  });

  exports.default = CorporateBlockedController;
});
define('client/components/graph/iptc-topic-list', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    filterSet: null,
    selectionSet: null,
    childrenOpen: false,
    IptcData: Ember.inject.service(),
    iptcId: null,

    options: Ember.computed("filterSet.iptcTopicIds", "children", "selectionSet.iptcTopicIds", "childrenOpen", function () {
      if (Array.isArray(this.get("children"))) {
        return this.get("children");
      } else {
        return [this.get("children")];
      }
    }),

    update: function update() {},
    toggleChildrenOpen: function toggleChildrenOpen() {},
    didInsertElement: function didInsertElement() {
      this.addLabelToolTip();
    },
    getUpdateIptcTopicOptions: function getUpdateIptcTopicOptions(key, value) {
      return this.get("update")(key, value);
    },


    getOpenChildren: Ember.computed("iptcOpen", "iptcSearchTermSelection", function () {
      return this.get("iptcOpen")[this.get("iptcId")] || this.get("iptcSearchTermSelection").includes(this.get("iptcId"));
    }),

    iptcDisplay: Ember.computed("iptcCheck", "show", function () {
      return this.get("iptcCheck")[this.get("iptcId")] || this.get("show");
    }),

    addLabelToolTip: function addLabelToolTip() {
      d3.selectAll(".chart-children").attr("data-title", function (d, i) {
        var textTruncated = this.offsetWidth < this.scrollWidth;
        if (textTruncated) {
          this.style.setProperty('--data-title-left', '87px');
          return this.textContent;
        }
      });
    }
  });
});
define('client/components/dashboard/widgets/articles-card', ['exports', 'client/components/dashboard/widgets/widget-card'], function (exports, _widgetCard) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _widgetCard.default.extend({

    infoText: Ember.computed("profileService.profile.name", function () {
      return Ember.String.htmlSafe('<p>Total number of news and/or blog posts written by or about ' + this.get("profileService.profile.name") + '. </p> <p > This figure will reflect any filters applied or omitted. </p>');
    })
  });
});
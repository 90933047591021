define('client/models/executive', ['exports', 'client/models/base-profile', 'ember-data', 'ember-cp-validations'], function (exports, _baseProfile, _emberData, _emberCpValidations) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var Validations = (0, _emberCpValidations.buildValidations)({
    name: (0, _emberCpValidations.validator)('presence', true)
  });

  var Executive = _baseProfile.default.extend(Validations, {

    title: _emberData.default.attr("string"),
    isExecutive: true,

    profile_ids: Ember.computed("parent.id", function () {
      return [+this.get("parent.id")];
    })

  });
  Executive.pathForType = "executives";
  Executive.TITLES = ["Chief Executive Officer", "Chief Marketing Officer", "Chief Operating Officer", "Chief Information Officer", "Chief Technology Officer", "C-level Executive", "Spokesperson", "Executive", "Other"];

  exports.default = Executive;
});
define('client/components/article-entity', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: "li",
    entity: null,

    sentimentText: Ember.computed("entity.sentimentScore", function () {
      var score = this.get("entity.sentimentScore");
      if (score <= 0.25) {
        return "negative";
      }
      if (score > 0.25 && score < 0.75) {
        return "neutral";
      }
      if (score >= 0.75) {
        return "positive";
      }
    }),

    sentimentClass: Ember.computed("sentimentText", function () {
      return this.get("sentimentText").dasherize();
    })

  });
});
define('client/models/sales/keyword', ['exports', 'ember-data', 'ember-cp-validations'], function (exports, _emberData, _emberCpValidations) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var Validations = (0, _emberCpValidations.buildValidations)({
    name: (0, _emberCpValidations.validator)('presence', true)
  });

  var Keyword = _emberData.default.Model.extend(Validations, {

    profile: _emberData.default.belongsTo("profile"),
    name: _emberData.default.attr("string")

  });

  Keyword.pathForType = "sales/keywords";

  exports.default = Keyword;
});
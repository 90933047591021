define('client/mixins/graph-text', ['exports', 'client/helpers/format-number'], function (exports, _formatNumber) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  // TODO(tony) - consider moving this all to just using the same prefix
  // lookup that the axis is doing, more manual than d3.format, but with
  // all the special casing I am going now it might make more sense.
  function getFormatWithPrefix(spec, isInteger) {
    var floatSpec = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : '.2f';

    // Return a formatter that will use the d3 formatter you ask
    // for unless the number is low, this is because we don't like
    // SI units for low numbers...
    return function (d) {
      if (Math.abs(d) < 1000) {
        if (isInteger) {
          return d3.format('d')(d);
        } else {
          if (spec.indexOf('$') >= 0) {
            floatSpec = '$' + floatSpec;
          }
          return d3.format(floatSpec)(d);
        }
      }
      return (0, _formatNumber.formatNumber)({}, d, spec);
    };
  }

  function getTruncatedLineText(text, textLength, maxLength) {
    // No more lines so we truncate the text on this line
    if (!text) {
      return '';
    }
    var charWidth = textLength / text.length;
    var numCharsFit = Math.floor(maxLength / charWidth);
    if (numCharsFit < text.length) {
      return numCharsFit > 3 ? text.slice(0, numCharsFit - 2) + '...' : '...';
    }
    return text;
  }

  var TextMixin = Ember.Mixin.create({
    wordWrapper: function wordWrapper(text, width, height) {
      text.each(function () {
        var text = d3.select(this),
            words = text.text().split(/\s+/).reverse(),
            word,
            line = [],
            lineNumber = 0,
            lineHeight = 1.1,
            // ems
        y = text.attr('y'),
            dy = parseFloat(text.attr('dy')),
            x = 0,
            tspan = text.text(null).append('tspan').attr({ x: x, y: y, dy: dy + 'em' });

        for (word = words.pop(); word; word = words.pop()) {
          line.push(word);
          var lineText = line.join(' ');
          tspan.text(lineText);
          var textLength = tspan.node().getComputedTextLength();

          if (textLength > width) {
            var textHeight = text.node().getBoundingClientRect().height;
            var tspanHeight = tspan.node().getBoundingClientRect().height;
            // We can add a line if there is enough space and there is more
            // than one word on the current line, i.e., if there is only one
            // word than that word is simply too big and needs to be truncated
            var canAddLine = textHeight + tspanHeight < height && line.length > 1;
            if (canAddLine) {
              // Reset current line to before trying to add this word
              line.pop();
              lineText = line.join(' ');
              tspan.text(lineText);
              textLength = tspan.node().getComputedTextLength();
              tspan.text(getTruncatedLineText(lineText, textLength, width));

              // Add this word to a new line and append
              line = [word];
              tspan = text.append('tspan').attr({
                x: x,
                y: y,
                dy: ++lineNumber * lineHeight + dy + 'em'
              }).text(word);
              var newLineTextLength = tspan.node().getComputedTextLength();
              if (newLineTextLength > width) {
                tspan.text(getTruncatedLineText(word, newLineTextLength, width));
              }
            } else {
              tspan.text(getTruncatedLineText(lineText, textLength, width));
              // Stop processing the words for this line
              break;
            }
          }
        }
      });
    },


    labelTrimmer: function labelTrimmer(_ref) {
      var getLabelSize = _ref.getLabelSize,
          getLabelText = _ref.getLabelText;

      if (!getLabelSize) {
        getLabelSize = function getLabelSize() {
          return 100;
        };
      }
      if (!getLabelText) {
        getLabelText = function getLabelText(d) {
          return d.label;
        };
      }
      return function (selection) {
        selection.text(function (d) {
          var bbW = this.getBBox().width;
          var label = getLabelText(d, selection);
          var textLabelWidth = getLabelSize(d, selection);
          return getTruncatedLineText(label, bbW, textLabelWidth);
        });
      };
    },

    getValueAxisFormatter: function getValueAxisFormatter(axisScale) {
      var _axisScale$domain = axisScale.domain(),
          _axisScale$domain2 = _slicedToArray(_axisScale$domain, 2),
          minValue = _axisScale$domain2[0],
          maxValue = _axisScale$domain2[1];

      var magnitude = Math.max(Math.abs(minValue), Math.abs(maxValue));
      var prefix = d3.formatPrefix(magnitude);
      return function (d) {
        // TODO do we ever have negative numbers?
        if (d < 1000) {
          return '' + d;
        }
        return '' + prefix.scale(d) + prefix.symbol;
      };
    },


    valueFormatType: 'count',
    floatFormatOneDecimal: getFormatWithPrefix('.3s', false, '.1f'),
    countFormat: getFormatWithPrefix('.3s', true),
    moneyFormat: function moneyFormat(d) {
      return '$' + getFormatWithPrefix('.3s')(d);
    },
    scoreFormat: getFormatWithPrefix('.3s', true),
    floatFormat: getFormatWithPrefix('.3s'),
    percentFormat: function percentFormat(d) {
      // TODO(tony) - A better labeler would be to look at the range that we are
      // about to plot and then get the sigDigits from the log10 of that range.
      // E.g., Could be plotting near 90% but the range is actually small, like
      // between 90 - 90.1, then we would need to do sigDigits based on -log10(0.1)
      var sigDigits = 0;
      if (d < 1) {
        sigDigits = 2;
      } else if (d < 10) {
        sigDigits = 1;
      }
      return d3.round(d, sigDigits) + '%';
    },


    // Getters for formatting human-readable labels from provided data
    valueLabelFormatter: Ember.computed('valueFormatType', function () {
      var valueFormatType = this.get('valueFormatType');
      if (typeof this.graph !== 'undefined' && this.graph.valueDimension.includes('impact_score')) {
        return this.get('floatFormatOneDecimal');
      } else {
        return this.get(valueFormatType + 'Format');
      }
    })
  });

  exports.default = TextMixin;
});
define('client/components/research/publishers-modal', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['publishers-modal'],

    letters: Ember.A(['#', 'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z']),

    selectedLetter: null,

    filteredPublishers: Ember.computed('publishers', 'selectedLetter', function () {
      var _this = this;

      var filteredPublishers = this.get('publishers');
      if (this.get('selectedLetter')) {
        var the = 'THE ';
        filteredPublishers = filteredPublishers.filter(function (publication) {
          // Remove 'The, THE, the' before checking. Example: The NYT will be checked against NYT
          if (publication.toUpperCase().startsWith(the)) {
            return publication.toUpperCase().replace(the, '').startsWith(_this.get('selectedLetter'));
          } else {
            return publication.toUpperCase().startsWith(_this.get('selectedLetter'));
          }
        });
      }
      return filteredPublishers;
    }),

    onSelected: function onSelected() {},


    actions: {
      selected: function selected(publisher) {
        this.get('onSelected')(publisher);
        this.toggleProperty('isOpen');
      },
      filterByLetter: function filterByLetter(letter) {
        this.set('selectedLetter', letter);
      }
    }
  });
});
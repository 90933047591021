define('client/helpers/format-number-with-default', ['exports', 'client/helpers/format-number'], function (exports, _formatNumber) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.formatNumberWithDefault = formatNumberWithDefault;

  function _toArray(arr) {
    return Array.isArray(arr) ? arr : Array.from(arr);
  }

  function formatNumberWithDefault(_ref, _ref2) {
    var _ref3 = _toArray(_ref),
        number = _ref3[0],
        rest = _ref3.slice(1);

    var defaultValue = _ref2.defaultValue,
        _ref2$minRound = _ref2.minRound,
        minRound = _ref2$minRound === undefined ? 1000 : _ref2$minRound,
        format = _ref2.format;

    if (Ember.isNone(number)) {
      return defaultValue;
    } else if (number < 1) {
      return 0;
    } else if (number < minRound) {
      return Math.round(number);
    } else {
      var value = (0, _formatNumber.formatNumber)({}, number, format);
      return value;
    }
  }

  exports.default = Ember.Helper.helper(formatNumberWithDefault);
});
define('client/models/report', ['exports', 'ember-data', 'client/utils/reports/page', 'client/utils/reports/item', 'ember-api-actions', 'ember-concurrency', 'client/utils/reports/generate-id'], function (exports, _emberData, _page, _item, _emberApiActions, _emberConcurrency, _generateId) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var deprecatingAlias = Ember.computed.deprecatingAlias;


  var Report = _emberData.default.Model.extend({
    notify: Ember.inject.service(),
    campaignOrProfileDoesNotExist: _emberData.default.attr(),

    version: _emberData.default.attr('string'),

    // This is for sharing with external people
    isPublic: _emberData.default.attr('boolean', { defaultValue: false }),

    // This is to share with the team/profile for logged in users
    isUserPublic: Ember.computed("userId", {
      get: function get() {
        return !this.get("userId");
      },
      set: function set(k, v) {
        this.set("userId", v ? null : this.get("createdById"));
        return v;
      }
    }),

    hexUrl: _emberData.default.attr('string'),

    name: _emberData.default.attr('string'),

    timezone: _emberData.default.attr('string'),

    createdById: _emberData.default.attr(),

    lockedById: _emberData.default.attr(),

    lockedByName: _emberData.default.attr(),

    createdByName: _emberData.default.attr(),

    userId: _emberData.default.attr(),
    user_id: deprecatingAlias('userId', {
      id: 'airpr.report.user_id',
      until: '3.0'
    }),

    // TODO: why is this not a relation?
    // TODO: should be a string attribute to match Ember Relation expectations
    profileId: _emberData.default.attr('number'),
    profile_id: deprecatingAlias('profileId', {
      id: 'airpr.report.profile_id',
      until: '3.0'
    }),

    initialFilterSet: _emberData.default.belongsTo('filter-set'),
    filter_set_id: Ember.computed.alias("initialFilterSet.id"),

    // TODO: this should be a separate attribute, calling this profile is
    // misleading and causes false assumptions to be made
    // `this.store` should also be avoided inside of model instances
    profile: Ember.computed("campaignId", "profileId", function () {
      var campaign_id = this.get("campaignId"),
          profile_id = this.get("profileId");
      if (campaign_id) {
        // use the campaign as the profile
        return this.store.findRecord("campaign", campaign_id, { backgroundReload: true });
      } else {
        return this.store.findRecord("profile", profile_id);
      }
    }),

    // TODO: why is this not a relation?
    // TODO: should be a string attribute to match Ember Relation expectations
    campaignId: _emberData.default.attr('number'),
    campaign_id: deprecatingAlias('campaignId', {
      id: 'airpr.report.campaign_id',
      until: '3.0'
    }),

    startDate: _emberData.default.attr("isodate"),

    endDate: _emberData.default.attr("isodate"),

    startDateFormatted: Ember.computed("startDate", function () {
      return moment(this.get("startDate")).format("MM/DD/YYYY");
    }),

    endDateFormatted: Ember.computed("endDate", function () {
      return moment(this.get("endDate")).format("MM/DD/YYYY");
    }),

    cover_page_image: _emberData.default.belongsTo("image", { async: true }),

    pages: _emberData.default.hasMany("report-page", { async: false }),

    sortedPages: Ember.computed.filterBy("pages", "isDeleted", false),

    isPrivate: Ember.computed("userId", "createdById", function () {
      return this.get("userId") === this.get("createdById");
    }),

    copy: (0, _emberApiActions.memberAction)({ path: 'copy', type: 'POST' }),

    unlock: (0, _emberApiActions.memberAction)({ path: 'unlock', type: 'POST' }),

    resetReport: function resetReport() {
      this.get('pages').forEach(function (page) {
        page.get('items').forEach(function (item) {
          if (item.get('isNew')) {
            item.unloadRecord();
          } else if (item.get('hasDirtyAttributes')) {
            item.rollbackAttributes();
          }
        });
        if (page.get('isNew')) {
          page.unloadRecord();
        } else if (page.get('hasDirtyAttributes')) {
          page.rollbackAttributes();
        }
      });
      this.rollbackAttributes();
    },
    touch: function touch() {
      Ember.run.debounce(this, "debouncedTouch", 500);
    },
    debouncedTouch: function debouncedTouch() {
      if (Ember.getOwner(this).lookup("service:reports").get("isReadOnly")) {
        return;
      }
      this.notifyPropertyChange("pages");
      this.safeSave();
    },


    saveReportTask: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function _callee() {
      return regeneratorRuntime.wrap(function _callee$(_context) {
        while (1) {
          switch (_context.prev = _context.next) {
            case 0:
              _context.prev = 0;
              _context.next = 3;
              return this.save();

            case 3:
              return _context.abrupt('return', _context.sent);

            case 6:
              _context.prev = 6;
              _context.t0 = _context['catch'](0);

              this.get('notify').error('There was a problem saving the report');

            case 9:
            case 'end':
              return _context.stop();
          }
        }
      }, _callee, this, [[0, 6]]);
    })).maxConcurrency(1).keepLatest(),

    safeSave: function safeSave() {
      return this.get("saveReportTask").perform();
    },
    save: function save() {
      if (Ember.getOwner(this).lookup("service:reports").get("isReadOnly")) {
        return Ember.RSVP.resolve(this);
      }
      return this._super.apply(this, arguments);
    },
    getReportPreview: function getReportPreview(profile) {
      var _this = this;

      var props = this.getProperties("name", "cover_page_image", "startDate", "endDate");
      props.profile_id = profile.get("_profile_id");
      props.campaign_id = profile.get("campaign_id");

      var report = this.store.createRecord("report", props);

      this.get("pages").forEach(function (page) {
        var pageProperties = page.getProperties('type', 'html', 'image');
        pageProperties.report = report;
        pageProperties.id = (0, _generateId.default)(props.profile_id);

        var newPage = _this.store.createRecord('report-page', pageProperties);
        page.get('items').forEach(function (item) {
          var itemProperties = item.getProperties('row', 'col', 'colspan', 'rowspan', 'content', 'config');
          itemProperties.page = newPage;
          itemProperties.id = (0, _generateId.default)(props.profile_id);

          var newItem = _this.store.createRecord(item.constructor.modelName, itemProperties);
          newPage.get('items').addObject(newItem);
        });
        report.get('pages').addObject(newPage);
      });
      report.set("fromHexUrl", this.get("hexUrl"));
      report.resetProfileOnItems(this.get("profileId"));
      return report;
    },


    cover_page_image_url: Ember.computed('cover_page_image.original_url', 'profile.image_url', function () {
      if (this.get('cover_page_image.original_url')) {
        return this.get('cover_page_image.original_url');
      } else {
        return this.get('profile.image_url');
      }
    }),

    isDefaultLogo: Ember.computed('cover_page_image_url', 'profile.image_url', function () {
      return this.get("cover_page_image_url") === this.get("profile.image_url");
    }),

    // when a report's profile changes, update the image
    updateTitleImg: Ember.observer('profile', 'cover_page_image_url', function () {
      var _this2 = this;

      Ember.run(function () {
        var firstPage = _this2.get('pages.firstObject');
        if (firstPage) {
          var previousHtml = firstPage.get('renderedHtml').toString();
          if (!previousHtml) {
            return;
          }
          var newsrc = _this2.get('cover_page_image_url');
          if (!newsrc) {
            return;
          }
          var newHtml = previousHtml.replace(/<img class="company-logo-img" src=".*?" /i, '<img class="company-logo-img" src="' + newsrc + '" ');
          firstPage.set('renderedHtml', newHtml);
        }
      });
    }),

    updateTitleHtml: Ember.observer('name', function () {
      var _this3 = this;

      Ember.run(function () {
        var firstPage = _this3.get('pages.firstObject');
        if (firstPage) {
          var previousHtml = firstPage.get('renderedHtml').toString();
          if (!previousHtml) {
            return;
          }
          var newHtml = previousHtml.replace(/<div class="report-title-last-text">\n?[^<]*\n?<\/div>/i, '<div class="report-title-last-text">' + _this3.get('name') + '</div>');
          firstPage.set('renderedHtml', newHtml);
        }
      });
    }),

    resetDatesOnItems: function resetDatesOnItems() {
      // Have to reset legacy as well, since it is given preference if current dates don't exist.
      this.get("pages").mapBy("items").flatten().forEach(function (item) {
        item.setProperties({
          'content.startDate': null,
          'content.endDate': null,
          'content.start_date': null,
          'content.end_date': null
        });
      });
    },
    resetProfileOnItems: function resetProfileOnItems(oldProfileId) {
      //when user changes dates, it resets whitelistTagIds, so dont run
      //rest of this function unless profile has changed
      if (this.get('profile_id') == oldProfileId) {
        return;
      }
      this.get("pages").mapBy("items").flatten().forEach(function (item) {
        var content = item.get("content");
        // Checking for no profile_id to account for historical data
        // Checking for profile_id equal to report's profile_id
        // to account for manually touched charts

        if (!content.profile_id || content.profile_id == oldProfileId) {

          delete content.profile_id;
          delete content.campaign_id;
          delete content.isSetToCampaign;
          delete content.whitelistTagIds;

          // all properties depend on content
          // so this forces a redraw of the item with the new profile
          item.set('content', Ember.merge({}, content));
        }
      });
    },


    updateDatesHtml: Ember.observer('startDate', 'endDate', 'profile.name', 'timezone', function () {
      var _this4 = this;

      Ember.run(function () {
        _this4.get('pages').forEach(function (page, i) {
          var previousHtml = page.get('renderedHtml').toString();
          if (!previousHtml) {
            return;
          }

          var newHtml = null;
          if (i > 0) {
            newHtml = previousHtml.replace(/<div class="pull-left report-footer-dates">\n?[^<]*\n?<\/div>/i, '<div class="pull-left report-footer-dates">' + _this4.get('profile.name') + ':  ' + _this4.get('startDateFormatted') + '-' + _this4.get('endDateFormatted') + ' ' + _this4.get('timezone') + '</div>');
          } else {
            newHtml = previousHtml.replace(/<div class="date-range">\n?[^<]*\n?<\/div>/i, '<div class="date-range">' + _this4.get('startDateFormatted') + ' - ' + _this4.get('endDateFormatted') + '</div>');
          }

          page.set('renderedHtml', newHtml);
        });
      });
    }),
    resetCustomColors: function resetCustomColors() {
      // reset all the custom colors in the report so that
      // it can use the profile's custom colors
      this.get("pages").forEach(function (page) {
        page.get("items").forEach(function (item) {
          item.setProperties({ "content.customColors": undefined });
        });
      });
    },
    fixReportCoverPage: function fixReportCoverPage() {
      this.updateTitleImg();
      this.updateTitleHtml();
      this.updateDatesHtml();
      this.touch();
    },
    deletePage: function deletePage(pageNumberToDelete) {
      var pages = this.get('sortedPages'),
          pageToDelete = pages.objectAt(+pageNumberToDelete),
          deleteLastPage = pages.get("length") === pageNumberToDelete + 1;

      if (!pageToDelete) {
        return;
      }

      pageToDelete._report = this;
      pageToDelete.destroyRecord();
      return deleteLastPage ? pageNumberToDelete - 1 : pageNumberToDelete;
    },
    movePage: function movePage(pageGettingMoved, movePageAfter) {

      pageGettingMoved = +pageGettingMoved;
      movePageAfter = +movePageAfter;
      var isInvalidPages = isNaN(pageGettingMoved) || isNaN(movePageAfter);
      var maxPageNumber = this.get('sortedPages.length');
      var isMovingValidPage = pageGettingMoved > 0 && pageGettingMoved < maxPageNumber;
      var isValidPageNumberToMoveTo = movePageAfter >= 0 && movePageAfter < maxPageNumber;
      // If the page is moved to after the previous page or if the page is moved after itself,
      // we don't have to do anything
      var noOp = pageGettingMoved === movePageAfter + 1 || pageGettingMoved === movePageAfter;

      // is the page a valid number?
      if (isInvalidPages || !isMovingValidPage || !isValidPageNumberToMoveTo || noOp) {
        return;
      }
      this._movePage(pageGettingMoved, movePageAfter);

      this.touch();
    },
    createPage: function createPage() {
      var attrs = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

      return this.store.createRecord('report-page', Ember.merge({
        id: (0, _generateId.default)(this.get('id')),
        report: this
      }, attrs));
    },
    _movePage: function _movePage(currentPageNumber, movePageAfter) {
      // When moving a page from bottom to top, the moved to page is the next page after movePageAfter
      var pages = this.get('pages'),
          objectToMove = this.get('sortedPages').objectAt(currentPageNumber),

      // take index of sortedPages and find that object's index in pages (which may included deleted pages)
      objectToMoveAfter = this.get('sortedPages').objectAt(movePageAfter);

      pages.removeObject(objectToMove);

      var newIndex = pages.indexOf(objectToMoveAfter) + 1;

      pages.insertAt(newIndex, objectToMove);

      pages.forEach(function (page) {
        page.updatePageNumberHTML();
      });
    }
  });
  Report.pathForType = "reports";

  exports.default = Report;
});
define('client/facebook-api-config', ['exports', 'client/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  // TODO(Tony) Most likely this init function needs to be a global because of
  // the code below, we should probably insert this into the header in the
  // index.html
  window.fbAsyncInit = function () {
    window.FB.init({
      appId: _environment.default.APP.fb_api_key,
      xfbml: true,
      version: 'v2.0'
    });
  };

  (function (d, s, id) {
    var js,
        fjs = d.getElementsByTagName(s)[0];
    if (d.getElementById(id)) {
      return;
    }
    js = d.createElement(s);js.id = id;
    js.async = true;
    js.src = "//connect.facebook.net/en_US/sdk.js";
    fjs.parentNode.insertBefore(js, fjs);
  })(document, 'script', 'facebook-jssdk');

  exports.default = window.FB;
});
define('client/components/insights/insight-detail', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    modal: Ember.inject.service(),

    classNames: ["insight-detail"],

    actions: {
      onRestoreInsight: function onRestoreInsight() {
        var _this = this;

        return this.get('modal').open('common/ui-confirm', {
          question: "Are you sure you want to restore this insight?",
          ok: "Confirm"
        }).then(function () {
          _this.get('restoreInsight')();
        });
      },
      onSaveInsight: function onSaveInsight() {
        var modal = this.get('modal');
        return this.get('saveInsight')().then(function (insight) {
          modal.open('common/ui-confirm', {
            prompt: 'This insight has been saved. You can view it in the saved section.',
            ok: 'OK'
          });
        });
      },
      onDeleteInsight: function onDeleteInsight() {
        var _this2 = this;

        var modal = this.get('modal');
        return modal.open('common/ui-confirm', {
          question: "Are you sure you want to delete this insight?",
          ok: "Delete",
          okType: "danger"
        }).then(function () {
          _this2.get('deleteInsight')().then(function (insight) {
            modal.open('common/ui-confirm', {
              prompt: 'This insight has been deleted. You can restore it from you "Deleted" section.',
              ok: 'OK'
            });
          });
        });
      }
    }

  });
});
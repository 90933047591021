define('client/components/profile-link-filter-table', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: '',
    isBlacklist: false,
    profile: Ember.computed.alias('profileService.profile'),
    type: -1,
    currentUser: null,
    whitelistTag: null,
    links: null,
    sortDate: "",
    sortPattern: "",
    sortProfile: "",
    sortUser: "",
    showPropagateOptions: true,
    linkAdded: function linkAdded() {},
    removeSorts: function removeSorts() {},


    store: Ember.inject.service(),
    modal: Ember.inject.service(),

    // Property used to display loading wheel while URLs are retrieved or added
    isLoading: false,

    profileName: Ember.computed.alias('profileService.profile.name'),

    addText: Ember.computed('isBlacklist', function () {
      return this.get('isBlacklist') ? 'Add Domain' : 'Add Target Media Outlet';
    }),

    addByType: Ember.computed('isBlacklist', function () {
      return this.get('isBlacklist') ? 'Domain' : 'Publication';
    }),

    applyType: Ember.computed('isBlacklist', function () {
      return this.get('isBlacklist') ? 'Domain' : 'URL';
    }),

    newLink: Ember.computed(function () {
      return this._createUrlRecord();
    }),

    whitelistTagDidChange: Ember.observer('whitelistTag', function () {
      this.set('newLink.whitelistTag', this.get('whitelistTag'));
    }),

    // String containing user's input of one or more URLs
    bulkUrls: '',

    /**
     * Computed property that contains an array of bad URLs with a reason
     * @public
     */
    failedUrls: Ember.computed('newLink', {
      get: function get(key) {
        return [];
      },
      set: function set(key, value) {
        return value;
      }
    }),

    /**
     * Recursive function to handle bulk creation, validation, and saving of URLs while keeping
     * track of any URLs that failed to be added.
     * @param bulkUrls a String Array containing a list of URLs to be added
     * @param badUrls an Object Array containig a list of URLs that could not be added
     * @private
     */
    _checkAndAddUrls: function _checkAndAddUrls(bulkUrls, badUrls) {
      var _this = this;

      // Check if any URLs left to be added
      if (Ember.isPresent(bulkUrls)) {
        var url = bulkUrls.shift();
        url = url.trim();
        if (Ember.isEmpty(url)) {
          // Note: This can occur due bulk URL string parsing which could have generated an empty URL string.
          this._checkAndAddUrls(bulkUrls, badUrls);
        } else {
          var newUrlRecord = this._createUrlRecord();
          newUrlRecord.set('pattern', url);
          newUrlRecord.save().then(function () {
            _this._checkAndAddUrls(bulkUrls, badUrls);
          }).catch(function (failure) {
            var error = failure.errors[0].detail;
            var reason = 'Not a valid URL'; // default error
            if (error === 'has already been added.') {
              reason = 'This URL has already been added';
            }
            badUrls.pushObject({ url: url, reason: reason });
            _this._checkAndAddUrls(bulkUrls, badUrls);
          });
        }
      } else {
        // Remove loading wheel
        this.set('isLoading', false);
        // Display added links
        this.linkAdded();
        // Notify user
        if (Ember.isEmpty(badUrls)) {
          this.get('modal').open('common/ui-confirm', {
            prompt: 'Added all valid URLs.',
            ok: 'OK',
            okType: "info"
          });
        } else {
          this.get('modal').open('common/ui-confirm', {
            prompt: 'Failed to add one or more URLs. Please verify and re-submit.',
            ok: 'OK',
            okType: "danger"
          });
        }
        // Reset textarea to empty and display any URLs that were not added
        this.set('bulkUrls', '');
        this.set('failedUrls', badUrls);
      }
    },

    _createUrlRecord: function _createUrlRecord() {
      var store = this.get('store');
      return store.createRecord("profile-link-filter", {
        profile: this.get("profile"),
        type: this.get("type"),
        whitelistTag: this.get('whitelistTag'),
        propagate_down: true,
        propagate_competitors: true
      });
    },

    actions: {

      removeSorts: function removeSorts() {
        this.removeSorts();
      },

      removeLink: function removeLink(link) {
        var _this2 = this;

        this.get('modal').open('common/ui-confirm', {
          prompt: 'Are you sure you want to remove?',
          ok: "Remove",
          okType: "danger"
        }).then(function () {
          _this2.get('store').peekRecord('profile-link-filter', link.get('id')).destroyRecord();
          _this2.get("links.model").removeObject(link);
        });
      },


      switchToProfile: function switchToProfile(profile) {
        this.get("newLink").set("profile", profile);
      },

      addUrls: function addUrls() {
        var bulkUrls = this.get('bulkUrls');
        if (Ember.isEmpty(bulkUrls.trim())) {
          return;
        } else {
          // Show loading wheel
          this.set('isLoading', true);
          // Get all URLs that are separated by breaklines
          var urls = bulkUrls.split('\n').join(' ');
          // Get all URLs that are separated by commas
          urls = urls.split(',').join(' ').replace(',', '');
          // Create an array of urls
          var urlsArray = urls.split(' ');
          // Recursively add list of URLs while tracking any URLs that failed to add
          var badUrls = [];
          this._checkAndAddUrls(urlsArray, badUrls);
        }
      },
      addLink: function addLink() {
        var _this3 = this;

        this.set('isLoading', true);
        this.get("newLink").save().then(function () {
          _this3.set('isLoading', false);
          _this3.linkAdded();
          _this3.notifyPropertyChange("newLink");
        }).catch(function (failure) {
          _this3.set('isLoading', false);
          var error = failure.errors[0].detail;
          if (error === 'has already been added.') {
            _this3.get('modal').open('common/ui-confirm', {
              prompt: 'This URL has already been added',
              ok: 'OK',
              okType: "danger"
            });
          } else {
            _this3.get('modal').open('common/ui-confirm', {
              prompt: 'Not a valid URL',
              ok: 'OK',
              okType: "danger"
            });
          }
        });
      }
    }
  });
});
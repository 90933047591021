define('client/components/graph-column', ['exports', 'client/mixins/graph-legend', 'client/mixins/graph-viewport', 'client/mixins/graph-group-columns', 'client/mixins/graph-title'], function (exports, _graphLegend, _graphViewport, _graphGroupColumns, _graphTitle) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var ReportGroupColumnGraphComponent = Ember.Component.extend(_graphLegend.default, _graphViewport.default, _graphGroupColumns.default, _graphTitle.default, {
    classNames: ['graph-column'],

    // ----------------------------------------------------------------------------
    // Data Processing
    // ----------------------------------------------------------------------------
    _groupDataExample: Ember.computed(function () {
      return [{
        labels: ['Jan', 'Rackspace'],
        values: [273]
      }, {
        labels: ['Jan', 'Microsoft Azure'],
        values: [329]
      }, {
        labels: ['Feb', 'Rackspace'],
        values: [491]
      }, {
        labels: ['Feb', 'Microsoft Azure'],
        values: [372]
      }, {
        labels: ['Mar', 'Rackspace'],
        values: [558]
      }, {
        labels: ['Mar', 'Microsoft Azure'],
        values: [225]
      }, {
        labels: ['Apr', 'Rackspace'],
        values: [603]
      }, {
        labels: ['Apr', 'Microsoft Azure'],
        values: [391]
      }, {
        labels: ['May', 'Rackspace'],
        values: [663]
      }, {
        labels: ['May', 'Microsoft Azure'],
        values: [438]
      }];
    }),

    _dataExample: Ember.computed(function () {
      return [{
        labels: ['Jan', 'Rackspace'],
        values: [273]
      }, {
        labels: ['Feb', 'Rackspace'],
        values: [491]
      }, {
        labels: ['Mar', 'Rackspace'],
        values: [558]
      }, {
        labels: ['Apr', 'Rackspace'],
        values: [603]
      }, {
        labels: ['May', 'Rackspace'],
        values: [663]
      }];
    }),

    //data: Ember.computed.alias('_dataExample'),
    data: Ember.computed.alias('_groupDataExample'),

    stackColumns: false,

    // Take data API and format for this specific chart
    formattedData: Ember.computed('data', function () {
      var data = this.get('data');
      if (Ember.isEmpty(data)) {
        return [];
      }
      return data.map(function (d) {
        // when the label and group is the same only one is kept. So if label[1] is empty, use label[0]
        return {
          label: d.labels[1] || d.labels[0],
          color: d.color,
          group: d.labels[0],
          value: d.values[0]
        };
      });
    }),

    // ----------------------------------------------------------------------------
    // Vertical Bar Chart Options
    // ----------------------------------------------------------------------------

    // Space between bars, as fraction of bar size
    withinGroupPadding: 0,

    // Space between bar groups, as fraction of group size
    betweenGroupPadding: Ember.computed.alias('columnPadding'),

    // ----------------------------------------------------------------------------
    // Drawing Functions
    // ----------------------------------------------------------------------------
    updateColumns: function updateColumns() {
      this.updateBackgroundColumns();

      var groups = this.get('columnGroups').data(this.get('finishedData'));
      if (Ember.isEmpty(groups)) {
        return;
      }

      var subdata = this.get('stackColumns') ? function (d) {
        return d.stackedValues.concat(d.negStackedValues);
      } : function (d) {
        return d.values;
      };

      var bars = groups.selectAll('path').data(subdata);
      bars.enter().append('path');
      bars.exit().remove();

      var barAttrs = this.get('stackColumns') ? this.get('stackedPathAttrs') : this.get('groupedPathAttrs');
      bars.attr(barAttrs);

      if (this.get("useBrush")) {
        this.brush = null;
        this.updateBrush();
      }
    },

    updateBackgroundColumns: function updateBackgroundColumns() {
      var groups = this.get('backgroundColumnGroups').data(this.get('finishedData'));
      // Update the column attrs because they change depending on label stuff
      groups.attr(this.get('columnAttrs'));

      if (Ember.isEmpty(groups)) {
        return;
      }

      var subdata = this.get('stackColumns') ? function (d) {
        return d.stackedValues;
      } : function (d) {
        return d.values;
      };

      var bars = groups.selectAll('rect').data(subdata);
      bars.enter().append('rect');
      bars.exit().remove();

      var barAttrs = this.get('stackColumns') ? this.get('stackedBackgroundBarAttrs') : this.get('groupedBackgroundBarAttrs');
      bars.attr(barAttrs);
    },
    showHoverLine: function showHoverLine(x, y) {
      var data = this.getDataAtMouse(x, y);

      var groups = this.get('columnGroups').data(this.get('finishedData'));
      if (Ember.isEmpty(groups)) {
        return;
      }

      var bars = groups.selectAll('path');
      bars.filter(function (d, z, i) {
        d3.select(this).attr('opacity', d.group !== data.group ? 0.5 : null);
      });
    },
    hideHoverLine: function hideHoverLine() {
      var groups = this.get('columnGroups').data(this.get('finishedData'));
      if (Ember.isEmpty(groups)) {
        return;
      }

      var subdata = this.get('stackColumns') ? function (d) {
        return d.stackedValues.concat(d.negStackedValues);
      } : function (d) {
        return d.values;
      };

      var bars = groups.selectAll('path').data(subdata);
      bars.attr('opacity', null);
    },


    // ----------------------------------------------------------------------------
    // Styles
    // ----------------------------------------------------------------------------

    stackedBackgroundBarAttrs: Ember.computed(function () {
      var stackedBackgroundColor = '#aaa';
      return {
        'stroke-width': 0,
        width: this.get('groupWidth'),
        y: 0,
        height: this.get('graphicHeight'),
        fill: stackedBackgroundColor,
        opacity: function opacity(d, i) {
          return i === 0 ? 0.1 : 0;
        }
      };
    }).volatile(),

    groupedBackgroundBarAttrs: Ember.computed(function () {
      var _this = this;

      return {
        'stroke-width': 0,
        width: this.get('barWidth'),
        x: function x(d) {
          return _this.get('xWithinGroupScale')(d.label);
        },
        y: 0,
        height: this.get('graphicHeight'),
        fill: function fill(d) {
          return d.color;
        },
        opacity: 0.1
      };
    }).volatile(),

    stackedBarAttrs: Ember.computed(function () {
      // zeroDisplacement is the number of pixels to shift graphics away from
      // the origin line so that they do not overlap with it
      var zeroDisplacement = 1;
      var yScale = this.get('yScale');
      return {
        'stroke-width': 0,
        fill: function fill(d) {
          return d.color;
        },
        width: this.get('groupWidth'),
        x: null,
        y: function y(d) {
          return yScale(d.y1) + zeroDisplacement;
        },
        height: function height(d) {
          return yScale(d.y0) - yScale(d.y1);
        }
      };
    }).volatile(),

    groupedBarAttrs: Ember.computed(function () {
      var _this2 = this;

      var zeroDisplacement = 1;
      var yScale = this.get('yScale');
      return {
        'class': function _class(d, i) {
          return 'grouping-' + i;
        },
        'stroke-width': 0,
        fill: function fill(d) {
          return d.color;
        },
        width: this.get('barWidth'),
        x: function x(d) {
          return _this2.get('xWithinGroupScale')(d.label);
        },
        height: function height(d) {
          return Math.max(0, Math.abs(yScale(d.value) - yScale(0)) - zeroDisplacement);
        },
        y: function y(d) {
          return d.value > 0 ? yScale(d.value) : yScale(0) + zeroDisplacement;
        }
      };
    }).volatile(),

    stackedPathAttrs: Ember.computed(function () {
      var _this3 = this;

      // zeroDisplacement is the number of pixels to shift graphics away from
      // the origin line so that they do not overlap with it
      var zeroDisplacement = 1;
      var yScale = this.get('yScale');
      var strokeWidthScale = d3.scale.linear().domain([30, 50]).range([0.5, 3]).clamp(true);
      return {
        'stroke-width': strokeWidthScale(this.get('groupWidth')),
        stroke: 'white',
        d: function d(_d) {
          if (_d.y1 === _d.y0) {
            return null;
          }
          var width = _this3.get('groupWidth');
          var x = 0;
          var y = yScale(_d.y1) + zeroDisplacement;
          var height = yScale(_d.y0) - yScale(_d.y1);
          if (_d.y1 < 0) {
            return _this3.bottomRoundedRect(x, y, width, height);
          } else {
            return _this3.topRoundedRect(x, y, width, height);
          }
        },
        fill: function fill(d) {
          return d.color;
        }
      };
    }).volatile(),

    groupedPathAttrs: Ember.computed(function () {
      var _this4 = this;

      var zeroDisplacement = 1;
      var yScale = this.get('yScale');
      return {
        'class': function _class(d, i) {
          return 'grouping-' + i;
        },
        'stroke-width': 0,
        d: function d(_d2) {
          var width = _this4.get('barWidth');
          var height = Math.max(0, Math.abs(yScale(_d2.value) - yScale(0)) - zeroDisplacement);
          var x = _this4.get('xWithinGroupScale')(_d2.label);
          var y = _d2.value > 0 ? yScale(_d2.value) : yScale(0) + zeroDisplacement;
          return _this4.topRoundedRect(x, y, width, height);
        },
        fill: function fill(d) {
          return d.color;
        }
      };
    }).volatile(),

    topRoundedRect: function topRoundedRect(x, y, width, height) {
      return 'M' + x + ',' + y + ('v' + height) + ('h' + width) + ('v' + -height) + 'z';
    },
    bottomRoundedRect: function bottomRoundedRect(x, negY, width, negHeight) {
      return 'M' + x + ',' + -negY + ('v' + -negHeight) + ('h' + width) + ('v' + negHeight) + 'z';
    },


    // ----------------------------------------------------------------------------
    // Ticks and Scales
    // ----------------------------------------------------------------------------

    // The range of labels assigned within each group
    xWithinGroupDomain: Ember.computed.alias('labelsPerColumn'),

    // The space in pixels allocated to each group
    groupWidth: Ember.computed.alias('columnWidth'),

    // The space in pixels allocated to each bar
    barWidth: Ember.computed(function () {
      return this.get('xWithinGroupScale').rangeBand();
    }).volatile(),

    // The scale used to position bars within each group
    // If we do not have grouped data, use the withinGroupPadding around group
    // data since we will have constructed groups for each bar.
    xWithinGroupScale: Ember.computed(function () {
      if (this.get('isGrouped') || this.get('stackColumns')) {
        return d3.scale.ordinal().domain(this.get('xWithinGroupDomain')).rangeRoundBands([0, this.get('groupWidth')], this.get('withinGroupPadding') / 2, 0);
      } else {
        return d3.scale.ordinal().domain(this.get('xWithinGroupDomain')).rangeRoundBands([0, this.get('groupWidth')], this.get('betweenGroupPadding') / 2, this.get('betweenGroupPadding') / 2);
      }
    }).volatile()

  });

  exports.default = ReportGroupColumnGraphComponent;
});
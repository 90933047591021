define('client/models/search-seed-term', ['exports', 'ember-data', 'ember-api-actions'], function (exports, _emberData, _emberApiActions) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    profileId: _emberData.default.attr("string"),
    name: _emberData.default.attr("string"),
    slop: _emberData.default.attr("number"),
    preview: (0, _emberApiActions.memberAction)({ path: 'preview', type: 'GET' })
  });
});
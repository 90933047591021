define('client/models/translation-task', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var TranslationTask = _emberData.default.Model.extend({
    type: _emberData.default.attr(),
    price_amount: _emberData.default.attr(),
    due_at: _emberData.default.attr(),
    target_language: _emberData.default.attr('string', { defaultValue: 'en' }),
    article: _emberData.default.belongsTo('article'),
    translation: _emberData.default.attr(),
    status: _emberData.default.attr(),
    progress: _emberData.default.attr(),
    hasStatus: Ember.computed('progress', function () {
      return this.get('progress') !== undefined && this.get('progress') !== null;
    }),
    formattedDueAt: Ember.computed('due_at', function () {
      var due = this.get('due_at');
      return moment(due).format('dddd, MMMM Do YYYY');
    }),
    start: function start() {
      this.set('shouldStart', true);
      var model = this;
      return this.save().then(function () {
        model.set('shouldStart', false);
      });
    }
  });
  TranslationTask.pathForType = "translation_tasks";

  exports.default = TranslationTask;
});
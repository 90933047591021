define("client/components/research/authors/filter-item", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: "div",
    classNameBindings: [":author-filter-item", "isSelected:selected"],

    isSelected: null,

    type: null,

    select: function select() {},
    click: function click() {
      this.toggleProperty("isSelected");
      this.get("select")(this.get("filter"));
    }
  });
});